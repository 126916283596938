//==========================================================================================
//
//	Amplify Storage Manager (AmplifyStorageManager.js)
//
//		This module manages the interaction between the user and the AWS Amplify Auth
//		class. 
//------------------------------------------------------------------------------------------
//
//  Internal Data Organization:
//  --------------------------
//
//  Ref Numbering:
//    projectRef = projectId * 65536
//    designRef  = projectRef + designId;
//
//  Primary Project/Design Data:
//    smProjectList: array of {}
//      info: application object
//      designRefs: []
//      designData: [] (application object) ***
//      designDirty: [] (true or undefined)
//
//    ** As of 4/11/2022
//      designData: [] array of {}
//        design: (application object)
//        thumbnail: PNG
//        image: PNG or JPG image (optional)
//
//
//  File Organization per User:
//  --------------------------
//	Private bucket
//	  Settings/
//	    Settings.json
//	  Projects/
//		ProjectList.json
//	    PRxxxx/
//	      PRxxxx.json
//	      PRxxxx_DSyyyy.json
//
//
//  File Descriptions
//  -----------------
//
//  Settings/Settings.json
//    Description:
//      Tracks next project number
//      Stores user settings
//    Contents:
//      {"nextProjectNumber":1,"userSettings":{"lastProjRef":0}}
//    API:
//      _LoadSettings
//      _StoreSettings
//    Related:
//      _PrepareToClose
//      _StoreUserSettings
//      _ProjectList_CreateProject
//
//
//  Projects/ProjectList.json
//    Description:
//      Stores list of projects (as an array of projectRefs)
//    Contents:
//      ex1: {"projectRefs":[0]}
//      ex2: {"projectRefs":[0,1,2]}
//    API:
//      _ProjectList_Load
//      _ProjectList_Store
//    Related:
//      _Open .. _ProjectList_Load
//      _PrepareToClose .. _ProjectList_Store
//      _ProjectList_Rebuild
//      _ProjectList_CreateProject
//      _ProjectList_DeleteProject
//      
//
//  Projects/PRxxxx/ProjectInfo.json
//    Description:
//      Stores list of designs (as array of designRefs)
//      Tracks most-recently viewed design
//    Contents:
//      ex1: {"info":{"lastDesignRef":0},"designRefs":[0]}
//      ex2: {"info":{"lastDesignRef":131103},"designRefs":[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]}
//    API:
//      _Project_Rebuild (x2)
//      _Project_Load
//      _Project_Store
//    Related:
//      _ProjectList_Rebuild .. _Project_Rebuild
//      _ProjectList_CreateProject .. _Project_Store
//      _Project_CreateDesign
//      _Project_DeleteDesign
//    Public:
//      Project_Load
//      Project_Store
//
//
//  Projects/PRxxxx/PRxxxx_DS_yyyy.json
//    Description:
//      Design data, from the application
//    Contents:
//      design data objects as JSON
//    API:
//      _Project_DeleteDesign
//      _Design_Load
//      _Design_Store
//    Related:
//      _Project_CreateDesign .. _Design_Store
//    Public:
//      Project_DeleteDesign
//      Design_Load
//      Design_Store
//
//
//
//  Object Usage
//  -----------------
//
//  Design Data
//    _Project_IsDesignDataInProject (public)
//      
//    _Project_LookupDesignRef (public)
//      
//    _Project_CreateDesign (public)
//      
//    _Project_DeleteDesign (x2) (public)
//      
//    _Design_Load (x5) (public)
//      
//    _Design_Store (x3) (public)
//      
//    _Design_SetData (public)
//      
//    _Design_GetData (public)
//      
//
//
//==========================================================================================

import Storage from '@aws-amplify/storage';

var StorageManager = (function() {

	// Settings
	var smSettingsDirty = true;
	var smSettings = { nextProjectNumber: 0 };
	
	// Projects
	var smProjectListDirty = false;
	var smProjectList = undefined;
	
	// Constants
	//   "Folders" 
	const smDirSettings = "Settings/";
	const smDirProjects = "Projects/";
	//   "Files"
	const smKeySettings = "Settings.json";
	const smKeyProjectList = "ProjectList.json";
	const smKeyProjectInfo = "ProjectInfo.json";
	const smPrefixProject = "PR";
	const smPrefixDesign = "DS";
	const smPrefixThumbnail = "TH";
	const smPrefixImage = "IM";
	const smPrefixImageFile = "IF";
	const smZeroPrefix = "0000";
	//   "Assets" (2022.11.05: Added)
	const smAssets = [
		{ key: "Thumbnail",		prop: "thumbnail", 		prefix: smPrefixThumbnail,	extension: ".png",	contentType: 'image/png'	},
		{ key: "ImageFile",		prop: "imageURL", 		prefix: smPrefixImageFile,	extension: "",		contentType: undefined		}
	];

	//------------------------------------------------------------------------------
	//	Open (promise)
	//------------------------------------------------------------------------------
	var StorageManager_Open = function()
	{
		//console.log("StorageManager_Open");
		
		StorageManager_ProjectList_Init();
		
		return new Promise( (resolve, reject) =>
			{
				StorageManager_LoadSettings()
				.then ( () =>
				{
					//console.log("[then] StorageManager_Open");
					return StorageManager_ProjectList_Load();
				})
				.then ( () =>
				{
					//console.log("[resolve] StorageManager_Open");
					resolve();
				})
				.catch ( err =>
				{
					console.log("[catch] StorageManager_Open error: " + err);
					reject(err);
				});
			});
	}
	
	//------------------------------------------------------------------------------
	//	Close
	//------------------------------------------------------------------------------
	var StorageManager_Close = function()
	{
		//console.log("StorageManager_Close");
		StorageManager_ProjectList_Init();
	}
	
	//------------------------------------------------------------------------------
	//	Prepare to Close
	//------------------------------------------------------------------------------
	var StorageManager_PrepareToClose = async function()
	{
		var result = 1;
		
		//console.log("StorageManager_PrepareToClose");
		
		if (smSettingsDirty)
		{
			//console.log("StorageManager_PrepareToClose: settings need saving");
			result = await StorageManager_StoreSettings();
		}
		else
		{
			//console.log("StorageManager_PrepareToClose: settings are already saved");
		}

		
		if (smProjectListDirty)
		{
			//console.log("StorageManager_PrepareToClose: ProjectList need saving");
			await StorageManager_ProjectList_Store();
		}
		else
		{
			//console.log("StorageManager_PrepareToClose: ProjectList is already saved");
		}
		//console.log("...PrepareToClose result:"+ result);
		
		return result;
	}
	
	//------------------------------------------------------------------------------
	//	Get User Settings
	//------------------------------------------------------------------------------
	var StorageManager_GetUserSettings = function()
	{
		if (smSettings.userSettings == undefined)
			smSettings.userSettings = {};
			
		var userSettings = smSettings.userSettings;

		return userSettings;
	}
	
	//------------------------------------------------------------------------------
	//	Store Use Settings
	//------------------------------------------------------------------------------
	var StorageManager_StoreUserSettings = function()
	{
		StorageManager_StoreSettings();
	}

	//------------------------------------------------------------------------------
	//	Report Error
	//------------------------------------------------------------------------------
	var StorageManager_ReportError = function(err)
	{
		var msg = "<unknown>";
		if (err == undefined)
			msg = "Error undefined";
		else if (err.message == undefined)
			msg = "Unknown error";
		else
			msg = err.message;

		console.log("StorageManager Error: '" + msg + "'");
	}

	//------------------------------------------------------------------------------
	//	Retrieve JSON File By Key
	//------------------------------------------------------------------------------
	var StorageManager_RetrieveJSONFileByKey = function(fileKey)
	{
		//console.log("  retrieveJSON: " + fileKey);
		return new Promise((resolve, reject) => 
		{
			const storageOptions = {level: 'private'};
			
			Storage.list(fileKey, storageOptions)
			.then(result => {
								if (result.length == 0)
									throw new Error("NO_MATCHING_FILE");
								else
								{
									if (result.length > 1)
										console.log("StorageManager_RetrieveJSONFileByKey: Multiple (" + result.length + ") files returned. Only one expected.");
									return Storage.get(result[0].key, storageOptions);
								}
							} )
			.then(result => {
								//console.log("StorageManager fetching: " + result);
								return fetch(result);
							})
			.then(response => {
								if (response.status !== 200)
								{
									console.log('Looks like there was a problem. Status Code: ' + response.status);
									throw new Error('Looks like there was a problem. Status Code: ' + response.status);
									reject();
								}
								return response.json();
							})
			.then(json => {
								//console.log("  retrieveJSON resolving '" + fileKey + "' with json");
								resolve(json);
		  					})
			.catch(err => {
								if (err.message === 'NO_MATCHING_FILE')
								{
									//console.log("StorageManager_RetrieveJSONFileByKey catch...NO_MATCHING_FILE: " + fileKey);
									resolve();
								}
								else
								{
									console.log("StorageManager_RetrieveJSONFileByKey catch..." + JSON.stringify(err.message));
									reject(err);
								}
		  				   })
		});
	}
	
	//------------------------------------------------------------------------------
	//	Update File By Key
	//------------------------------------------------------------------------------
	var StorageManager_UpdateFileByKey = function(fileKey, fileContents)
	{ 
		const storageOptions = {level: 'private', contentType: 'application/json' };
		
		//console.log("UpdateFileByKey: '" + fileKey + "'");
		
		Storage.put(fileKey, fileContents)
		.then (result => console.log("UpdateFileByKey result: " + result))
		.catch(err => console.log("UpdateFileByKey err: " + err));

		/*
		return new Promise((resolve, reject) => 
		{
			const storageOptions = {level: 'private', contentType: 'application/json' };
			
			console.log("UpdateFileByKey: " + fileKey);
			
			return Storage.put(fileKey, fileContents);
			.then (result => console.log("UpdateFileByKey result: " + result))
			.catch(err => console.log("UpdateFileByKey err: " + err));
		});
		*/
	}

	
	//------------------------------------------------------------------------------
	//	Load Settings (promise)
	//------------------------------------------------------------------------------
	var StorageManager_LoadSettings = function()
	{
		var settingsInfoKey = StorageManager_FormatSettingsKey();
		
		return new Promise( (resolve, reject) =>
		{
			StorageManager_RetrieveJSONFileByKey(settingsInfoKey)
			.then( info => { 
								if (info == undefined)
								{
									// Using initial settings. They need to be saved
									smSettingsDirty = true;
								}
								else
								{
									// If we add new default settings, then we will have to determine
									// if the settings actually need to be written (are 'dirty') 
									smSettingsDirty = false;
									Object.assign(smSettings, info);
								}
								//console.log("StorageManager_LoadSettings: " + JSON.stringify(smSettings));
								resolve();
							 } )
			.catch( err => {
								// How to handle a failure here? 
								console.log("StorageManager_LoadSettings ERROR") 
								resolve();
							} ); 
		});
	}
	
	//------------------------------------------------------------------------------
	//	Store Settings (async, await)
	//------------------------------------------------------------------------------
	var StorageManager_StoreSettings = async function()
	{
		var settingsJsonStr = JSON.stringify(smSettings);
		var settingsKey = StorageManager_FormatSettingsKey();
		var status = 0;
		
		const storageOptions = { level: 'private', contentType: 'application/json' };
		
		try {		
			var result = await Storage.put(settingsKey, settingsJsonStr, storageOptions)
								.then( /*result => console.log("Storage.put result: " + JSON.stringify(result))*/ )
								.catch( err => console.log("Storage.put error: " + err != undefined ? err.message : "<no err>") );
			//console.log("StoreSettings result:" + JSON.stringify(result));
			status = 1;
		}
		catch (err) {
			console.log("StoreSettings err:" + err.message);
		}
		
		return status;
	}

	var StorageManager_NextProjectNumber = function()
	{
		var nextProj = smSettings.nextProjectNumber;
		
		smSettings.nextProjectNumber++;
		smSettingsDirty = true;
		
		return nextProj;
	}

	var StorageManager_SetNextProjectNumber = function(nextProject)
	{
		smSettings.nextProjectNumber = nextProject;
		smSettingsDirty = true;
	}

	var StorageManager_NextDesignNumber = function(smProjectRef)
	{
	}

	var StorageManager_ZeroPrefix = function(value)
	{
		// Value must be non-negative integer
		var str = value.toString();
		
		if (str.length < smZeroPrefix.length)
			str = (smZeroPrefix + str).slice(-smZeroPrefix.length);
			
		//console.log("Zero prefix: " + value + " --> '" + str + "'");
		
		return str;
	}
	
	var StorageManager_FormatSettingsKey = function() 		{ return smDirSettings + smKeySettings; }
	var StorageManager_FormatProjectListKey = function()	{ return smDirProjects + smKeyProjectList; }

	var StorageManager_FormatProjectListRebuildKey = function()
	{	// "Projects/PR"
		const key = smDirProjects +smPrefixProject;
		//console.log(key);
		return key;
	}

	var StorageManager_FormatProjectDir = function(projectNumber)
	{	// Projects/PRxxxx/
		const key = smDirProjects + smPrefixProject + StorageManager_ZeroPrefix(projectNumber) + "/";
		//console.log(key);
		return key;
	}

	var StorageManager_FormatProjectKey = function(projectNumber)
	{	// Projects/PRxxxx/ProjectInfo.json
		const key = StorageManager_FormatProjectDir(projectNumber) + smKeyProjectInfo;
		//console.log(key);
		return key;
	}

	var StorageManager_FormatDesignKey = function(projectNumber, designNumber)
	{	// Projects/PRxxxx/DSyyyy.json
		const key = StorageManager_FormatProjectDir(projectNumber) + smPrefixDesign + StorageManager_ZeroPrefix(designNumber) + ".json";
		//console.log(key);
		return key;
	}

	var StorageManager_FormatDesignImageKey = function(projectNumber, designNumber, prefix, extension)
	{	// Projects/PRxxxx/ZZyyyy.NNN
		// Generic function to format keys. Initially used for thumbnails (prefix=TH)
		// Extension includes "." (period); extension may be empty string
		const key = StorageManager_FormatProjectDir(projectNumber) + prefix + StorageManager_ZeroPrefix(designNumber) + extension;
		return key;
	}


	//------------------------------------------------------------------------------
	//	Integrity Report
	//------------------------------------------------------------------------------
	var StorageManager_IntegrityReport = function(descStr, info)
	{
		console.log("------------------------------------------------------------------");
		console.log("StorageManager_IntegrityDump --- " + descStr + ", info: " + JSON.stringify(info));
		console.log("  projectRefs:" + JSON.stringify(smProjectList.projectRefs));
		console.log("------------------------------------------------------------------");
	}
	
	//------------------------------------------------------------------------------
	//	DELETE ALL FILES MATCHING KEY (Promise)
	//	DELETE ALL FILES (Promise)
	//------------------------------------------------------------------------------
	var StorageManager_DeleteAllFiles = function()
	{
		return StorageManager_DeleteAllFilesMatchingKey("");
	}
	
	var StorageManager_DeleteAllFilesMatchingKey = function(deleteMatchingKey)
	{
		if (deleteMatchingKey == undefined)
		{
			return Promise.reject(Error('Undefined key for delete'));
		}
		else
		{
			return new Promise( (resolve, reject) => 
				{
					// Get a list of everything in the private storage area
					Storage.vault.list(deleteMatchingKey)
					.then(result => 
					{
						if (result.length > 0)
						{
							// For each item, request a removal and add the promise returned
							// to the promise list
							var promiseList = [];
							for (var i = 0; i < result.length; i++)
							{
								promiseList.push(Storage.vault.remove(result[i].key));
							}
		
							// Return a promise that will resolve when all of the promises resolve
							return Promise.all(promiseList);
						}
						else
						{
							return Promise.resolve(0);
						}
					})
					.then(() => 
					{
						resolve("StorageManager delete files done");
					})
					.catch(err => 
					{
						reject(err);
					});
				});
		}
	}
	

	//------------------------------------------------------------------------------
	//	ProjectList
	//		_Init
	//		_Load
	//		_Store
	//		_Get
	//		_CreateProject [returns projectRef]
	//		_DeleteProject (projectRef)
	//
	//	Project
	//		_Load (projectRef)
	//		_Store (projectRef)
	//		_GetInfo (projectRef)
	//		_SetInfo (projectRef, projectInfo)
	//		_GetDesignList (projectRef)
	//		_CreateDesign (projectRef)
	//		_DeleteDesign (designRef)
	//
	//	Design
	//		_Load (designRef)
	//		_Store (designRef)
	//		_GetData (designRef)
	//		_SetData (designRef, designData)
	//
	//------------------------------------------------------------------------------
	const ProjectIdShift = 65536;
	function makeProjectRef(projectId)          { return (projectId * ProjectIdShift); }
	function makeDesignRef(projectId, designID) { return (makeProjectRef(projectId) + designID); }
	function getProjectId(projectOrDesignRef)   { return Math.floor(projectOrDesignRef / ProjectIdShift); };
	function getDesignId(projectOrDesignRef)    { return (projectOrDesignRef % ProjectIdShift); };

	function  makeProjectObject()				{ return  {info:{}, designRefs:[], designDirty:[], designData:[]}; }

	//------------------------------------------------------------------------------
	//	Init Project List
	//
	//		projectRef = id * 65536
	//		designRef  = projectRef + id;
	//
	//		projectData: array of {}
	//			info: application object
	//			designRefs: []
	//			designData: [] (application object)	
	//			designDirty: [] (true or undefined)
	//
	//
	//	Project should have
	//		"New project" to create default project data
	//		"New design" to create default design data
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_Init = function()
	{
		smProjectListDirty = false;
		smProjectList = {};
		smProjectList.projectRefs = [];
		smProjectList.projectData = []; // { info:{}, designList:[{designs:[], designData[] } 
		smProjectList.projectDirty = [];
	}
	
	//------------------------------------------------------------------------------
	//	Load Project List (promise)
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_Load = function()
	{
		//console.log("StorageManager_ProjectList_Load");
		var projectListKey = StorageManager_FormatProjectListKey();
		
		return new Promise( (resolve, reject) => 
		{
			StorageManager_RetrieveJSONFileByKey(projectListKey)
			.then( info => { 
								//console.log("[then] StorageManager_ProjectList_Load ('" + projectListKey + "') : " + JSON.stringify(info));
								if (info == undefined)
								{
									// Should we call ProjectList init?
								}
								else
								{
									smProjectListDirty = false;
									Object.assign(smProjectList, info);
								}
								
								//console.log("[resolve] StorageManager_ProjectList_Load");
								resolve();
							 } )
			.catch( err => {
								console.log("No stored ProjectList (" + err + ")");
									// Should we call ProjectList init?
								resolve();

							} ); 
		});
	}
	
	//------------------------------------------------------------------------------
	//	Store Project List (async, internal promise)
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_Store = async function()
	{
		var projectListStore = { projectRefs:smProjectList.projectRefs };
		var projectListStoreJsonStr = JSON.stringify(projectListStore);
		var projectListKey = StorageManager_FormatProjectListKey();
		
		var status = 0;

		const storageOptions = {level: 'private', contentType: 'application/json' };
		
		try {		
			var result = await Storage.put(projectListKey, projectListStoreJsonStr, storageOptions)
								.then( /* nothing to do */ )
								.catch( err => console.log("Storage.put error: " + err != undefined ? err.message : "<no err>") );
			status = 1;
		}
		catch (err) {
			console.log("ProjectList_Store err:" + err.message);
		}
		
		return status;
	}
	
	//------------------------------------------------------------------------------
	//	Function to determine if two arrays of integers are the same
	//------------------------------------------------------------------------------
	function areScalarArraysEqual(array1, array2)
	{
		return array1.length === array2.length && array1.sort().every(function(value, index) { return value === array2.sort()[index]});
	}

	//------------------------------------------------------------------------------
	//	Project List Rebuild (async, internal promise)
	//		Rebuilds project list by getting list files that match "Projects/PR"
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_Rebuild = async function()
	{		
		const rebuildKey = StorageManager_FormatProjectListRebuildKey();
		
		await Storage.list(rebuildKey, {level: 'private'})
		.then(filesList => 
			{
				//console.log(JSON.stringify(filesList.map(a => a.key), null, 2));
				var prefixLen = rebuildKey.length;
				var projectList = filesList.filter(a => a.key.includes(smKeyProjectInfo)).map(a => parseInt(a.key.slice(prefixLen)));
				
				// Just to be aware if anything strange happens
				var errorList = projectList.filter(n => !Number.isInteger(n));
				if (errorList != undefined && errorList.length > 0)
					console.log("StorageManager_ProjectList_Rebuild: " + errorList.length + " project files failed to result to project numbers");
				
				// Insure that only integers are in the project list
				projectList = projectList.filter(n => Number.isInteger(n));
				
				// Does the project list we just generated match the project list we loaded (from the json)?
				if (!areScalarArraysEqual(projectList, smProjectList.projectRefs))
				{
					console.log("StorageManager_ProjectList_Rebuild: PROJECT LIST INVALID");
					console.log("  Found in storage: " + JSON.stringify(projectList));
					console.log("  Found in project: " + JSON.stringify(smProjectList.projectRefs));
					
					// Write the project back to storage
					smProjectList.projectRefs = projectList;
					StorageManager_ProjectList_Store();
				}
				
				// Create a list for the files in each project and use that to rebuild the project data.
				// The first step is to create a list of just the filenames  (i.e., the "keys")
				var filekeys = filesList.map(a => a.key);
				for (var i = 0; i < projectList.length; i++)
				{
					var projectId = projectList[i];
					// Generate a prefix that we can use to get a subset of files for one project
					var projectPrefix = StorageManager_FormatProjectDir(projectId) + smPrefixDesign;
					var designFilePrefixLen = projectPrefix.length;
					
					// Filter the filenames based on the project prefix and convert the names to a list of numbers
					var designFiles = filekeys.filter(a => a.includes(projectPrefix));
					var designIds = designFiles.map(a => parseInt(a.slice(designFilePrefixLen)));

					StorageManager_Project_Rebuild(projectId, designIds);
				}
			} )
		.catch(err => 
			{
				console.log("StorageManager_ProjectList_Rebuild err: " + err);
			});
	}


	//------------------------------------------------------------------------------
	//	Project: Rebuild
	//
	//		Compare the provided designId list for the given projectId with the
	//		data stored (in the cloud). If different, then update the stored data
	//------------------------------------------------------------------------------
	var StorageManager_Project_Rebuild = function(projectId, designIdList)
	{
		// First load the project data if we don't already have it
		// Then update the list of design numbers and write the project data
		
		// Create a promise that resolves to the project data
		new Promise((resolve, reject) =>
			{
				// If we don't have the data, then load it directly. This will
				// not store the data; only operate on it here.
				if (smProjectList.projectData[projectId] == undefined)
				{
					const projectKey = StorageManager_FormatProjectKey(projectId);
		
					StorageManager_RetrieveJSONFileByKey(projectKey)
					.then( info => 
						{ 
							var project = makeProjectObject();
							//console.log("_Rebuild Loaded Project: " + JSON.stringify(info));
							if (info == undefined)
							{
								// Should we call ProjectList init?
							}
							else
							{
								Object.assign(project, info);
								StorageManager_Project_ValidateProjectData(project);
								//console.log("StorageManager_Project_Load: ref:" + projectRef + " id:" + projectId + ", " + JSON.stringify(project));
							}
							resolve(project);
						} )
					.catch( err => 
						{
							console.log("StorageManager_Project_Rebuild: No stored Project for projectId: " + projectId + " (" + err + ")");
							reject(err);
						} ); 
				}
				// If we have the data, then use it. This will update the data if we find that it is invalid
				else
				{
					resolve(smProjectList.projectData[projectId]);
				}
			})
		// Analyze the project data
		.then( projectData =>
			{
				//console.log("StorageManager_Project_Rebuild [later then]: " + JSON.stringify(designIdList));
				if (areScalarArraysEqual(designIdList, projectData.designRefs))
				{
					// Design id lists are the same; nothing to do. Return 'undefined' from this to indicate to the
					// next 'then' that there is nothing to store
					return undefined;
				}
				else
				{
					// A report to the console
					console.log("StorageManager_Project_Rebuild: PROJECT DATA INVALID projectId: " + projectId);
					console.log("  Found in storage: " + JSON.stringify(designIdList));
					console.log("  Found in project: " + JSON.stringify(projectData.designRefs));

					// Update the design list in the project data and return it so the next 'then' knows to 
					// store it.
					projectData.designRefs = designIdList;
					return projectData;
				}
			} )
		// Store the project data
		.then( projectData =>
			{
				// If we receive project data here that means that we have to store it back to the cloud
				// NOTE!!! This was copied from StorageManager_Project_Store(). Make sure it does not get stale.
				if (projectData != undefined)
				{
					const projectKey = StorageManager_FormatProjectKey(projectId);

					// Store the project info and the design refs, but not the design data. We would only
					// design data if we are using a project that was already loaded.
					var projectStore = {info: projectData.info, designRefs: projectData.designRefs };
					var projectStoreJsonStr = JSON.stringify(projectStore);
		
					Storage.put(projectKey, projectStoreJsonStr, {level: 'private', contentType: 'application/json' })
					.catch( err => console.log(err) );
				}
			} )
		.catch( err =>
			{
				console.log(err);
			} );
	}
	
	//------------------------------------------------------------------------------
	//	Get Project List (immediate)
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_Get = function()
	{
		return Array.from(smProjectList.projectRefs, x => makeProjectRef(x));
	}
	
	//------------------------------------------------------------------------------
	//	Get Project Count (immediate)
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_GetCount = function()
	{
		return smProjectList.projectRefs.length;
	}
	
	//------------------------------------------------------------------------------
	//	Project List: Create Project (xx)
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_CreateProject = function()
	{
		var projectNumber = StorageManager_NextProjectNumber();
		var projectRef = makeProjectRef(projectNumber);
		
		// 2019.05.05: Insure that project number is not already in use in the projectRefs list
		// and not as a key in the projectData list
		// 2019.06.11: Major failure if projectRefs was empty. Added "-1" as an initializer
		var maxProjectNumber = smProjectList.projectRefs.reduce((acc, cur) => (acc > cur ? acc : cur), -1);
		var keysIterator = smProjectList.projectData.keys();
		var maxKey = -1;
		for (let key of keysIterator) 
			maxKey = (maxKey > key) ? maxKey : key;

		if (projectNumber <= maxProjectNumber || projectNumber <= maxKey)
		{
			console.log("StorageManager_ProjectList_CreateProject: ProjectNumber: " + projectNumber + ", maxKey: " + maxKey + ", maxProjectNumber: " + maxProjectNumber);

			// 2019.05.28: Use max of the two max numbers (and THEN increment the projectNumber)
			projectNumber = (maxProjectNumber > maxKey) ? maxProjectNumber : maxKey;
			projectNumber++;

			console.log("StorageManager_ProjectList_CreateProject: Using projectNumber: " + projectNumber);

			// There should be a better way to do this that makes more sense or is
			// more consistent with the architecture.
			StorageManager_SetNextProjectNumber(projectNumber + 1); 
		}

		var aProject = makeProjectObject(); 
		smProjectList.projectRefs.push(projectNumber);
		smProjectList.projectData[projectNumber] = aProject;
		
		// New project, so store settings, the project list, and the empty project
		StorageManager_StoreSettings();
		StorageManager_ProjectList_Store();
		StorageManager_Project_Store(projectRef);
		
		return projectRef;
	}

	//------------------------------------------------------------------------------
	//	Project List: Delete Project (interal promise)
	//------------------------------------------------------------------------------
	var StorageManager_ProjectList_DeleteProject = function(projectRef)
	{
		if (projectRef != undefined)
		{
			const projectId = getProjectId(projectRef);
			const projectKey = StorageManager_FormatProjectDir(projectId);
			//console.log("StorageManager_ProjectList_DeleteProject: ref:" + projectRef + ", id:" + projectId + ", projectKey:'" + projectKey + "'");
		
			// Delete the internal data for the project
			var idx = smProjectList.projectRefs.indexOf(projectId);
			if (idx != -1)
				smProjectList.projectRefs.splice(idx, 1);
			else
				console.log("StorageManager_ProjectList_DeleteProject: project not found in project list (ref:" + projectRef + ", id:" + projectId + ", projectKey:" + projectKey + ")");
			smProjectList.projectData[projectId] = undefined;
			smProjectList.projectDirty[projectId] = undefined;
			
			// Store the project
			StorageManager_ProjectList_Store();
		
			// Delete the project files
			StorageManager_DeleteAllFilesMatchingKey(projectKey)
				.then( () => {  } )
				.catch( err => { console.log("Delete project files error:" + err); });
		}
		else
		{
			console.log("StorageManager_ProjectList_DeleteProject: projectRef in undefined.");
		}
	}
	
	//------------------------------------------------------------------------------
	//	Project: Load (promise)
	//		Loads the project if not yet loaded, otherwise does nothing
	//------------------------------------------------------------------------------
	var StorageManager_Project_Load = function(projectRef)
	{
		const projectId = getProjectId(projectRef);
		//console.log("StorageManager_Project_Load: ref:" + projectRef + " id:" + projectId);
		
		if (smProjectList.projectData[projectId] == undefined)
		{
			smProjectList.projectData[projectId] = makeProjectObject();
			
			const project = smProjectList.projectData[projectId];
			const projectKey = StorageManager_FormatProjectKey(projectId);
			//console.log("StorageManager_Project_Load: '" + projectKey + "'");
		
			// Create the promise to load the project
			return new Promise((resolve, reject) => 
				{
					StorageManager_RetrieveJSONFileByKey(projectKey)
					.then( info => { 
										//console.log("Loaded Project: " + JSON.stringify(info));
										if (info == undefined)
										{
											// Should we call ProjectList init?
										}
										else
										{
											//?smProjectListDirty = false;
											//?smProjectList = info;
											Object.assign(project, info);
											StorageManager_Project_ValidateProjectData(project);
											//console.log("StorageManager_Project_Load: ref:" + projectRef + " id:" + projectId + ", " + JSON.stringify(project));

										}
										resolve(projectRef);
									 } )
					.catch( err => {
										console.log("No stored Project (" + err + ")");
										reject(err);
									} ); 
				});
		}
		else
		{
			// Return resolved promise
			return Promise.resolve(projectRef);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Project: Validate Project Data
	//------------------------------------------------------------------------------
	var StorageManager_Project_ValidateProjectData = function(project)
	{
		for (var i = project.designRefs.length - 1; i >= 0; i--)
		{
			if (project.designRefs[i] == null)
			{
				console.log("StorageManager_Project_ValidateProjectData: cleaning index: " + i + " of project");
				project.designRefs.splice(i, 1);
			}
		}
	}
	
	//------------------------------------------------------------------------------
	//	Project: Store (async, internal promise) <<<
	//------------------------------------------------------------------------------
	var StorageManager_Project_Store = async function(projectRef)
	{
		const projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];
		const projectKey = StorageManager_FormatProjectKey(projectId);

		// Store the project info and the design refs, but not the design data 
		var projectStore = {info: project.info, designRefs: project.designRefs };
		var projectStoreJsonStr = JSON.stringify(projectStore);

		//console.log("StorageManager_Project_Store: " + projectRef + " " + projectId + " " + projectStoreJsonStr);
		
		const storageOptions = {level: 'private', contentType: 'application/json' };
		
		await Storage.put(projectKey, projectStoreJsonStr, storageOptions)
							.then( result => { smProjectList.projectDirty[projectId] = undefined; } )
							.catch( err => console.log("Storage.put error: " + err != undefined ? err.message : "<no err>") );

	}
	
	//------------------------------------------------------------------------------
	//	Project: Is Loaded (xx)
	//------------------------------------------------------------------------------
	var StorageManager_Project_IsLoaded = function(projectRef)
	{
		var projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];
		var isLoaded = (project != undefined);
			
		return isLoaded;
	}
	
	//------------------------------------------------------------------------------
	//	Project: Get Info (xx)
	//------------------------------------------------------------------------------
	var StorageManager_Project_GetInfo = function(projectRef)
	{
		var projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];
		var projectInfo = (project != undefined) ? project.info : undefined;
			
		return projectInfo;
	}
	
	//------------------------------------------------------------------------------
	//	Project: Set Info (xx)
	//------------------------------------------------------------------------------
	var StorageManager_Project_SetInfo = function(projectRef, projectInfo)
	{
		const projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];
		
		console.log("StorageManager_Project_SetInfo: " + projectRef + ", id: " + projectId + ", project: " + project);

		project.info = projectInfo;
	}

	//------------------------------------------------------------------------------
	//	Project: Mark Dirty (xx)
	//------------------------------------------------------------------------------
	var StorageManager_Project_MarkDirty = function(projectRef)
	{
		const projectId = getProjectId(projectRef);
		smProjectList.projectDirty[projectId] = true;
	}

	//------------------------------------------------------------------------------
	//	Project: Is Dirty (xx)
	//------------------------------------------------------------------------------
	var StorageManager_Project_IsDirty = function(projectRef)
	{
		const projectId = getProjectId(projectRef);
		var isDirty = smProjectList.projectDirty[projectId];

		if (isDirty == undefined)
			isDirty = false;
			
		return isDirty;
	}

	//------------------------------------------------------------------------------
	//	Project: Get Design List (xx)
	//		Returns array of designRefs
	//------------------------------------------------------------------------------
	var StorageManager_Project_GetDesignList = function(projectRef)
	{
		const projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];
		var designRefList = [];
		
		if (project != undefined)
			designRefList = Array.from(project.designRefs, x => makeDesignRef(projectId, x));
		
		//console.log("StorageManager_Project_GetDesignList: " + JSON.stringify(designRefList));
		return designRefList;
	}
	
	//------------------------------------------------------------------------------
	//	Project: Get Design Count (xx)
	//		Returns count of designRefs
	//------------------------------------------------------------------------------
	var StorageManager_Project_GetDesignCount = function(projectRef)
	{
		const projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];
		var designCount = 0;
		
		if (project != undefined)
			designCount = project.designRefs.length;
		
		return designCount;
	}
	
	//------------------------------------------------------------------------------
	//	Project:
	//------------------------------------------------------------------------------
	var StorageManager_Project_IsDesignDataInProject = function(projectRef, designData)
	{
		var containsDesign = false;
		const projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];

		if (project != undefined)
		{
			// 2022.04.11: The designData object (input parameter) is now wrapped in an object instead of stored directly in the designData array
			let designId = project.designData.findIndex((d) => d != undefined && d.design == designData)
			containsDesign = (designId != -1);
		}
			
		return containsDesign;
	}

	//------------------------------------------------------------------------------
	//	Project:
	//------------------------------------------------------------------------------
	var StorageManager_Project_LookupDesignRef = function(projectRef, designData)
	{
		var designRef = undefined;
		const projectId = getProjectId(projectRef);
		const project = smProjectList.projectData[projectId];

		if (project != undefined)
		{
			// 2022.04.11: The designData object (input parameter) is now wrapped in an object instead of stored directly in the designData array
			let designId = project.designData.findIndex((d) => d != undefined && d.design == designData)
			if (designId != -1)
				designRef = makeDesignRef(projectId, designId);
		}
			
		return designRef;
	}

	//------------------------------------------------------------------------------
	//	Project: Create Design (xx)
	//------------------------------------------------------------------------------
	var StorageManager_Project_CreateDesign = function(projectRef)
	{
		const projectId = getProjectId(projectRef);
		//console.log("StorageManager_Project_CreateDesign: " + projectRef + " " + projectId);
		
		const project = smProjectList.projectData[projectId];
		
		//console.log(JSON.stringify(project));

		const designId = project.designRefs.reduce(( max, cur ) => Math.max( max, cur ), -1) + 1;

		//console.log("StorageManager_Project_CreateDesign: " + projectRef + " " + projectId + " " + JSON.stringify(project.designRefs));
		//console.log("StorageManager_Project_CreateDesign: designId: " + designId);

		// 2022.04.11: The designData object (input parameter) is now wrapped in an object instead of stored directly in the designData array
		project.designRefs.push(designId);
		project.designData[designId] = {design:{}};

		var designRef = makeDesignRef(projectId, designId);

		// New design, so store project and the empty design
		StorageManager_Project_Store(projectRef);
		StorageManager_Design_Store(designRef);
		
		return designRef;
	}
	
	
	//------------------------------------------------------------------------------
	//	Project: Delete Design (xx)
	//------------------------------------------------------------------------------
	var StorageManager_Project_DeleteDesign = function(designRef)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		console.log("StorageManager_Project_DeleteDesign: ref:" + designRef + " (" + projectId + ", " + designId + ")");

		const project = smProjectList.projectData[projectId];
		
		if (project.designData[designId] == undefined)
		{
			console.log("StorageManager_Project_DeleteDesign: design not found in project");
		}
		// 2022.04.12: Continue with delete even if the data is not listed in the designData array so that we can clean-up the
		// data for this project and have it written back to the server. The design data can be absent from the designData array
		// if the data fails to load or does not exist on the server at all, yet is listed as a design in the project settings file. 
		//else
		{
			const storageOptions = {level: 'private'};
			const filekey = StorageManager_FormatDesignKey(projectId, designId);

			console.log("Deleting....(" + storageOptions.level + ") " + filekey);

			// Design refs are stored indexed starting from 0
			var idx = project.designRefs.indexOf(designId);
			if (idx < 0)
			{
				console.log("StorageManager_Project_DeleteDesign: failed to find '" + designId + "' in designRefs list, " + JSON.stringify(project.designRefs));
			}
			else
			{
				project.designRefs.splice(idx, 1);
			
				// Data and the dirty flag are referenced by the designId
				// 2022.04.11: Although the statement has not changed, the data in the array is now a wrapper object that includes
				// the original design data plus a thumbnail and other optional files, which are all released here.
				project.designData[designId] = undefined;
				project.designDirty[designId] = undefined;
			
				Storage.remove(filekey, storageOptions)
					.then(result => { 
							console.log("...StorageManager_Project_DeleteDesign designRef: " + designRef + " ... Storage.remove '" + filekey + "', result: " + JSON.stringify(result)); 
							StorageManager_Project_Store(designRef);
						})
					.catch(err => { 
							console.log("...Error deleting '" + filekey + "', err: " + err + "  " + JSON.stringify(err)); 
						});

				// 2022.04.12: Write the project back to the server
				let projectRef = makeProjectRef(projectId);
				StorageManager_Project_Store(projectRef);
			}
		}
	}
	
	
	//------------------------------------------------------------------------------
	//	Design: Load (promise)
	//------------------------------------------------------------------------------
	var StorageManager_Design_Load = function(designRef)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		const dbgLog = false;
		//console.log("StorageManager_Design_Load: ref:" + designRef + " (" + projectId + ", " + designId + ")");
		
		// ASSERT: Project data must already be loaded
		
		const project = smProjectList.projectData[projectId];
		
		if (project.designData[designId] == undefined)
		{
			// 2022.04.11: The designData is now a property of a larger object, which we are calling 'designComponents' in
			// this function. This function creates the wrapper object and loads the design data. Other functions will load
			// the other components, such as thumbnail, as needed.
			var designComponents = {design:{}};
			
			project.designData[designId] = designComponents;
			const fileKey = StorageManager_FormatDesignKey(projectId, designId);
			dbgLog && console.log("StorageManager_Design_Load: " + fileKey);
		
			// Create the promise to load the project
			return new Promise((resolve, reject) => 
				{
					StorageManager_RetrieveJSONFileByKey(fileKey)
					.then( info => 
					{ 
						dbgLog && console.log("Loaded design: designRef:" + designRef);
						// -------------------------------------------------------------------
						// 2019.10.13: TESTING: Randomly fail to return data and randomly throw an error
						if (0)
						{
							if (Math.random() < 0.10)
							{
								console.log("StorageManager_Design_Load: TEST MODE: Failing to return data for " + fileKey);
								info = undefined;
							}
							else if (Math.random() < 0.10)
							{
								console.log("StorageManager_Design_Load: TEST MODE: Throwing error " + fileKey);
								throw Error("Design failed to load!");
							}
						}
						// -------------------------------------------------------------------

						if (info == undefined)
						{
							console.log("No data (info==undefined) returned for designRef: " + designRef);
							// Should we call ProjectList init?
							// 2019.10.13: If we did not get data, then clear the object in the project.designData array. This is necessary
							// so that when we call this again it will attempt to do a load.
							project.designData[designId] = undefined;
						}
						else
						{
							Object.assign(designComponents.design, info);
							delete project.designDirty[designId];
						}
						//console.log("StorageManager_Design_Load: project:" + projectId + " design:" + designId);
						//console.log(JSON.stringify(design));
						//console.log("");
						resolve({data:designComponents.design, ref:designRef});
					})
					.catch( err =>
					{
						// 2019.10.13: If we did not get data, then clear the object in the project.designData array. This is necessary
						// so that when we call this again it will attempt to do a load.
						project.designData[designId] = undefined;
						console.log("Error attempting to load design (" + err + ")");
						reject(err);
					});
				});
		}
		else
		{
			// Return resolved promise
			return Promise.resolve({data:project.designData[designId].design, ref:designRef});
		}
	}

	//------------------------------------------------------------------------------
	//	Design: Store (async, internal promise)
	//------------------------------------------------------------------------------
	var StorageManager_Design_Store = async function(designRef, forceStore = undefined)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		const project = smProjectList.projectData[projectId];
		
		if (project == undefined)
		{
			StorageManager_IntegrityReport("StorageManager_Design_Store: project undefined", {designRef: designRef, projectId: projectId});
		}
		else
		{
			//console.log("StorageManager_Design_Store: " + designRef + " (" + projectId + ", " + designId + ")  ");
		
			if (project.designDirty[designId] || forceStore)
			{
				const fileKey = StorageManager_FormatDesignKey(projectId, designId);

				// Store the design data 
				var fileContents;
				
				// 2020.11.13: Use the design's stringify function, if it has one
				// 2022.04.11: The designData array now contains objects that wrap the original design data
				if (typeof project.designData[designId].design.Stringify === 'function')
					fileContents = project.designData[designId].design.Stringify();
				else
					fileContents = JSON.stringify(project.designData[designId].design);

				//console.log("StorageManager_Design_Store: " + designRef + " (" + projectId + ", " + designId + ")  " + fileContents);
		
				const storageOptions = {level: 'private', contentType: 'application/json' };
				await Storage.put(fileKey, fileContents, storageOptions)
				.then( result =>
				{ 		
					project.designDirty[designId] = false;
					//console.log("Storage.put result: " + JSON.stringify(result));
				})
				.catch( err =>
				{
					console.log("Storage.put error: " + err);
				});
			}
		}
	}
	
	//------------------------------------------------------------------------------
	//	Design: Load Asset (promise)
	//		2022.04.11: Added
	//		2022.11.05: Refactored from original _Load_Thumbnail
	//
	//	Returns a Promise that returns
	//		{ref:designRef, assetKey, assetProp:asset } if asset exists, where
	//			"assetProp" is thumbnail or image, etc
	//		{ref:designRef, assetKey } if design or asset does not exist
	//		error if assetKey is not known
	//------------------------------------------------------------------------------
	var StorageManager_Design_Load_Asset = function(designRef, assetKey)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		// Get the asset info: prefix, extension, etc	
		const assetInfo = smAssets.find((a) => (a.key == assetKey));
		const project = smProjectList.projectData[projectId];

		// The asset key is not known
		if (assetInfo == undefined)
		{
			return Promise.reject(Error('Undefined assetKey'));
		}
		// The design does not exist in the project
		else if (project.designData[designId] == undefined)
		{
			// Return resolved promise without asset
			return Promise.resolve({ref:designRef, assetKey});
		}
		// The asset (e.g.,thumbnail) is already loaded
		else if (project.designData[designId][assetInfo.prop] != undefined)
		{
			// Return resolved promise with asset
			let results = {ref:designRef, assetKey};
			results[assetInfo.prop] = project.designData[designId][assetInfo.prop];
			return Promise.resolve(results);
		}
		else
		{
			const prefix = assetInfo.prefix;
			const extension = assetInfo.extension;
			const fileKey = StorageManager_FormatDesignImageKey(projectId, designId, prefix, extension);

			// Create the promise to load the asset
			return new Promise((resolve, reject) =>
				{
					// Note that we are storing the thumbnail as base64 encoded with stringify, so we are using the
					// 'retrieve JSON file' function. If we change the storage to an actual PNG file, we will need to
					// create a 'retrieve file' function that handles the PNG.
					StorageManager_RetrieveJSONFileByKey(fileKey)
					.then( info =>
					{
						// Note that we will be here if the file does not exist. info will be undefined,
						// so this becomes an 'asset missing' state
						if (info != undefined)
							project.designData[designId][assetInfo.prop] = info;

						let results = {ref:designRef, assetKey};
						results[assetInfo.prop] = project.designData[designId][assetInfo.prop];
						resolve(results);
					} )
					.catch( err =>
					{
						console.log("Error attempting to load design asset '" + assetKey + "': (" + err + ")");
						// We do not treat this as an error. reject(err);
						resolve({ref:designRef, assetKey});
					} );
				});
		}
	}
	
	//------------------------------------------------------------------------------
	//	Design: Store Item (promise)
	//		2022.11.10: Changed from _Store_File to _Store_Item
	//		2022.11.05: Refactored from _Store_Thumbnail
	//		2022.04.11: Added
	//
	//	Store the file contents using the "File" reference returned from a drag&drop
	//	or store the blob
	//------------------------------------------------------------------------------
	var StorageManager_Design_Store_Item = function(designRef, assetKey, item)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		// Get the asset info: prefix, extension, etc	
		const assetInfo = smAssets.find((a) => (a.key == assetKey));
		const project = smProjectList.projectData[projectId];

		// The assetKey is not known
		if (assetInfo == undefined)
		{
			console.log("StorageManager_Design_Store_Item: unknown assetKey: " + assetKey);
		}
		// The design does not exist in the project
		else if (project.designData[designId] == undefined)
		{
			console.log("StorageManager_Design_Store_Item: design not in project, designRef: " + designRef);
		}
		// 'item' has no data
		else if (item == undefined || (item.file == undefined && item.blob == undefined))
		{
			console.log("StorageManager_Design_Store_Item: no 'file' or 'blob' provided as 'item', designRef: " + designRef);
		}
		else
		{
			const extension = assetInfo.extension; // May be empty string
			const prefix = assetInfo.prefix;
			const fileKey = StorageManager_FormatDesignImageKey(projectId, designId, prefix, extension);

			const itemToStore = (item.file != undefined) ? item.file : item.blob;
			const contentType = (item.file != undefined) ? item.file.type : item.blob.type;
			const itemType    = (item.file != undefined) ? "file" : "blob";
			const itemName    = (item.file != undefined) ? item.file.name : "(none)";

			//console.log("_Store_Item (" + itemType + "), fileKey: " + fileKey + ", " + itemName + ", type:" + contentType );
			Storage.put(fileKey, itemToStore, {level: 'private', contentType })
			.then( result =>
			{
				//console.log("_Store_Item, success, designRef: " + designRef + ", " + file.name + ", type:" + contentType );
			} )
			.catch( err =>
			{
				console.log("StorageManager_Design_Store_Item error (" + itemType + "), fileKey: " + fileKey + ", " + itemName + ", type:" + contentType );
				console.log("StorageManager_Design_Store_Item, Storage.put error: " + err );
			});
		}
	}

	//------------------------------------------------------------------------------
	//	Design: Remove File (promise)
	//		2022.11.05: Refactored from _Store_Thumbnail
	//		2022.04.11: Added
	//
	//	Store the file contents using the "File" reference returned from a drag&drop
	//------------------------------------------------------------------------------
	var StorageManager_Design_Remove_File = function(designRef, assetKey)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		// Get the asset info: prefix, extension, etc	
		const assetInfo = smAssets.find((a) => (a.key == assetKey));
		const project = smProjectList.projectData[projectId];

		// The assetKey is not known
		if (assetInfo == undefined)
		{
			console.log("StorageManager_Design_Remove_File: unknown assetKey: " + assetKey);
		}
		// The design does not exist in the project
		else if (project.designData[designId] == undefined)
		{
			console.log("StorageManager_Design_Remove_File: design not in project, designRef: " + designRef);
		}
		else
		{
			const extension = assetInfo.extension;
			const prefix = assetInfo.prefix;
			const contentType = file.type;
			const fileKey = StorageManager_FormatDesignImageKey(projectId, designId, prefix, extension);
			const storageOptions = { level: 'private', contentType };

			//console.log("_Remove_File, fileKey: " + fileKey + ", " + file.name + ", type:" + file.type );
			Storage.remove(fileKey, file, storageOptions)
			.then( result =>
			{
				//console.log("_Remove_File, success, designRef: " + designRef + ", " + file.name + ", type:" + file.type );
			})
			.catch( err =>
			{
				console.log("StorageManager_Design_Remove_File, Storage.remove error: " + err);
			});
		}
	}

	//------------------------------------------------------------------------------
	//	Design: Get File URL (promise)
	//		2022.04.11: Added
	//		2022.11.05: Refactored from original _Load_Thumbnail
	//		2022.11.10: Add options to control download flag
	//
	//	Returns a Promise that returns
	//		{ref:designRef, assetKey, assetProp:asset } if asset exists, where
	//			"assetProp" is thumbnail or image, etc
	//		{ref:designRef, assetKey } if design or asset does not exist
	//		error if assetKey is not known
	//------------------------------------------------------------------------------
	var StorageManager_Design_Get_File_URL = function(designRef, assetKey)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		// Get the asset info: prefix, extension, etc	
		const assetInfo = smAssets.find((a) => (a.key == assetKey));
		const project = smProjectList.projectData[projectId];

		// The asset key is not known
		if (assetInfo == undefined)
		{
			return Promise.reject(Error('Undefined assetKey'));
		}
		// The design does not exist in the project
		else if (project.designData[designId] == undefined)
		{
			// Return resolved promise without asset
			return Promise.resolve({ref:designRef, assetKey});
		}
		// The URL is already available
		else if (project.designData[designId][assetInfo.prop] != undefined)
		{
			// Return resolved promise with URL
			const URL = project.designData[designId][assetInfo.prop];
			let results = {ref:designRef, assetKey, URL};
			return Promise.resolve(results);
		}
		else
		{
			const prefix = assetInfo.prefix;
			const extension = assetInfo.extension;
			const fileKey = StorageManager_FormatDesignImageKey(projectId, designId, prefix, extension);

			// Create the promise to load the thumbnail
			return new Promise((resolve, reject) =>
			{
				// Request the URL
				Storage.get(fileKey, { level: 'private', download:false })
				.then( info =>
				{
					// Note that we will be here if the file does not exist. info will be undefined,
					// so this becomes an 'asset missing' state
					if (info != undefined)
						project.designData[designId][assetInfo.prop] = info;

					const URL = project.designData[designId][assetInfo.prop];
					let results = {ref:designRef, assetKey, URL};
					resolve(results);
				} )
				.catch( err =>
				{
					console.log("Error attempting to get file URL '" + assetKey + "': (" + err + ")");
					// We do not treat this as an error. reject(err);
					resolve({ref:designRef, assetKey});
				} );
			});
		}
	}


	//------------------------------------------------------------------------------
	//	Design: Has File (promise)
	//		2022.11.11: Added
	//
	//	Returns a Promise that returns
	//		{ref:designRef, assetKey, hasAsset:true/false } 
	//		error if assetKey is not known
	//------------------------------------------------------------------------------
	var StorageManager_Design_Has_File = function(designRef, assetKey)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		// Get the asset info: prefix, extension, etc	
		const assetInfo = smAssets.find((a) => (a.key == assetKey));
		const project = smProjectList.projectData[projectId];
		// Object returned with resolve
		const status = {ref:designRef, assetKey, hasAsset:false};

		// The asset key is not known
		if (assetInfo == undefined)
		{
			return Promise.reject(Error('Undefined assetKey'));
		}
		// The design does not exist in the project
		else if (project.designData[designId] == undefined)
		{
			// Return resolved promise with hasAsset:false
			return Promise.resolve(status);
		}
		// Query the server
		else
		{
			const prefix = assetInfo.prefix;
			const extension = assetInfo.extension;
			const fileKey = StorageManager_FormatDesignImageKey(projectId, designId, prefix, extension);

			// Create the promise to load the thumbnail
			return new Promise((resolve, reject) =>
			{
				// List the files that match the key. Note that we expect only one.
				Storage.list(fileKey, {level: 'private', download:false})
				.then( fileList =>
				{
					if (fileList.length >= 1)
					{
						status.hasAsset = true;
						if (fileList.length > 1)
							console.log("StorageManager_Design_Has_File: Multiple (" + fileList.length + ") files returned for '" + fileKey + "'. Only one expected.");
					}
					resolve(status);
				} )
				.catch( err =>
				{
					console.log("Error attempting to list files '" + assetKey + "': (" + err + ")");
					// We do not treat this as an error. reject(err);
					resolve(status);
				} );
			});
		}
	}

	//------------------------------------------------------------------------------
	//	Design: Get File (promise)
	//		2022.04.11: Added
	//		2022.11.05: Refactored from original _Load_Thumbnail
	//		2022.11.10: Add options to control download flag
	//
	//	Returns a Promise that returns
	//		{ref:designRef, assetKey, blob } if the file exists
	//		{ref:designRef, assetKey } if design or asset does not exist
	//		error if assetKey is not known
	//------------------------------------------------------------------------------
	var StorageManager_Design_Get_File = function(designRef, assetKey)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		// Get the asset info: prefix, extension, etc	
		const assetInfo = smAssets.find((a) => (a.key == assetKey));
		const project = smProjectList.projectData[projectId];

		// The asset key is not known
		if (assetInfo == undefined)
		{
			return Promise.reject(Error('Undefined assetKey'));
		}
		// The design does not exist in the project
		else if (project.designData[designId] == undefined)
		{
			// Return resolved promise without asset
			return Promise.resolve({ref:designRef, assetKey});
		}
		else
		{
			const prefix = assetInfo.prefix;
			const extension = assetInfo.extension;
			const fileKey = StorageManager_FormatDesignImageKey(projectId, designId, prefix, extension);

			// Create the promise to get the file as a blob
			return new Promise((resolve, reject) =>
			{
				// Request the URL
				Storage.get(fileKey, { level: 'private' })
				.then( URL =>
				{
					// Use the URL to fetch the file, which is returned in a Response object
					return fetch(URL);
				} )
				.then(response =>
				{
					if (response.status !== 200)
					{
						console.log('StorageManager_Design_Get_File error. Status Code: ' + response.status);
						throw new Error('StorageManager_Design_Get_File error. Status Code: ' + response.status);
						reject();
					}
					// Get the blob from the response
					return response.blob();
				})
				.then(blob => 
				{
					// Resolve the promise with an object containing the design information and the blob
					resolve({ref:designRef, assetKey, blob});
				})
				.catch( err =>
				{
					console.log("StorageManager_Design_Get_File (blob) error '" + assetKey + "': (" + err + ")");
					// We do not treat this as an error. reject(err);
					resolve({ref:designRef, assetKey});
				} );
			});
		}
	}

	//------------------------------------------------------------------------------
	//	Design: Load Thumbnail (promise)
	//		2022.04.11: Added
	//
	//	Returns a Promise that returns
	//		{ref:designRef, imageData} if thumbnail exists
	//		{ref:designRef} if design or thumbnail does not exist
	//------------------------------------------------------------------------------
	var StorageManager_Design_Load_Thumbnail = function(designRef)
	{
		return StorageManager_Design_Load_Asset(designRef, "Thumbnail");
	}

	//------------------------------------------------------------------------------
	//	Design: Store Thumbnail (promise)
	//		2022.04.11: Added
	//
	//	Note that we are currently storing the base64 string.
	//------------------------------------------------------------------------------
	var StorageManager_Design_Store_Thumbnail = function(designRef, thumbnailImage)
	{
		const projectId = getProjectId(designRef);
		const designId = getDesignId(designRef);
		const project = smProjectList.projectData[projectId];

		// The design does not exist in the project
		if (project.designData[designId] == undefined)
		{
			console.log("StorageManager_Design_Store_Thumbnail: design not in project, designRef: " + designRef);
		}
		else
		{
			project.designData[designId].thumbnail = thumbnailImage;

			const fileKey = StorageManager_FormatDesignImageKey(projectId, designId, smPrefixThumbnail, ".png");
			const storageOptions = {level: 'private', contentType: 'image/png' };

			const thumbnailJson = JSON.stringify(thumbnailImage);
			Storage.put(fileKey, thumbnailJson, storageOptions)
			.then( result =>
			{
				//console.log("_Store_Thumbnail, designRef: " + designRef + ", " + (thumbnailImage != undefined ? JSON.stringify(thumbnailImage).slice(0, 30) : "no thumbnail") );
			} )
			.catch( err => console.log("StorageManager_Design_Store_Thumbnail, Storage.put error: " + err) );
		}
	}
	

	//------------------------------------------------------------------------------
	//	Save Image (async, internal promise)
	//------------------------------------------------------------------------------
	var StorageManager_SaveImage = async function(pngData, fileName)
	{
		var putResult = undefined;
		
		const fileKey = fileName;
		
		/*
		Storage.put('test.txt', 'My Content', {
			acl: 'public-read', // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
			cacheControl: 'no-cache', // (String) Specifies caching behavior along the request/reply chain
			contentDisposition: 'attachment', // (String) Specifies presentational information for the object
			expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
			metadata: { key: 'value' }, // (map<String>) A map of metadata to store with the object in S3.
		})
		*/
		
		const storageOptions = 
			{	
				level: 'public', 
				contentType: 'image/png',
				cacheControl: 'no-cache', // (String) Specifies caching behavior along the request/reply chain
				expires: (new Date()).getTime(), // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
			};
			
		await Storage.put(fileKey, pngData, storageOptions)
		.then( result => 	
		{ 		
			putResult = result;
			//console.log("Storage.put result: " + JSON.stringify(result));
		})
		.catch( err =>
		{
			console.log("Storage.put error: " + err);
		});
							
		return putResult;
	}
	
	//------------------------------------------------------------------------------
	//	Purge Images (async, internal promise)
	//------------------------------------------------------------------------------
	var StorageManager_PurgeImages = async function(config)
	{
		//console.log("StorageManager_PurgeImages", JSON.stringify(config, 0, 2));
		
		function removeFile(key)
		{
			if (key != undefined)
			{
				Storage.remove(key)
					//.then(result => console.log("file removed", key))
					.catch(err => console.log(err));
			}
			else
			{
				console.log("StorageManager_PurgeImages > removeFile: key is undefined");
			}
		}
		
		if (config != undefined && config.fileMatchKey != undefined && 
			typeof(config.fileMatchKey) === 'string' && config.fileMatchKey.length > 10)
		{
			Storage.list(config.fileMatchKey, { level: 'public' })
			.then(result => 
			{
				for (var i = 0; i < result.length; i++)
				{
					let key = result[i].key;
					setTimeout(removeFile, i * 500, key);
				}
			})
			.catch(err =>
			{
				console.log(err)
			});
		}
		else
		{
			console.log("StorageManager_PurgeImages: config failed fileMatchKey test: " + JSON.stringify(config, 0, 2));
		}
	}
	
	//------------------------------------------------------------------------------
	//	Design: Set Data (immediate)
	//------------------------------------------------------------------------------
	var StorageManager_Design_SetData = function(designRef, designData)
	{
		const projectId = getProjectId(designRef);
		const designId  = getDesignId(designRef);
		const project = smProjectList.projectData[projectId];
		// 2022.04.11: The designData array now contains objects that wrap the original designData
		if (project.designData[designId] != undefined)
		{
			project.designData[designId].design = designData;
		
			StorageManager_Design_MarkDirty(designRef);
		}
		else
		{
			console.log("StorageManager_Design_SetData: designComponents is undefined");
		}
	}
	
	//------------------------------------------------------------------------------
	//	Design: Mark Dirty (immediate)
	//------------------------------------------------------------------------------
	var StorageManager_Design_MarkDirty = function(designRef)
	{
		const projectId = getProjectId(designRef);
		const designId  = getDesignId(designRef);
		const project = smProjectList.projectData[projectId];
		
		//console.log("StorageManager_Design_MarkDirty: " + designRef + " (" + projectId + ", " + designId + ")  ");
		
		// Mark 'dirty' flag
		project.designDirty[designId] = true;
	}
	
	//------------------------------------------------------------------------------
	//	Design: Get Data (immediate)
	//------------------------------------------------------------------------------
	var StorageManager_Design_GetData = function(designRef)
	{
		const projectId = getProjectId(designRef);
		const designId  = getDesignId(designRef);
		const project = smProjectList.projectData[projectId];
		let designData = undefined;

		// 2022.04.11: The designData array now contains objects that wrap the original designData
		if (project.designData[designId] != undefined)
			designData = project.designData[designId].design;

		return designData;
	}
	
	//------------------------------------------------------------------------------
	//	Public API
	//
	//------------------------------------------------------------------------------
	//	ProjectList
	//		_Init
	//		_Load
	//		_Store
	//		_Get
	//		_CreateProject [returns projectRef]
	//		_DeleteProject (projectRef)
	//
	//	Project
	//		_Load (projectRef)
	//		_Store (projectRef)
	//		_GetInfo (projectRef)
	//		_SetInfo (projectRef, projectInfo)
	//		_GetDesignList (projectRef)
	//		_CreateDesign (projectRef)
	//		_DeleteDesign (designRef)
	//
	//	Design
	//		_Load (designRef)
	//		_Store (designRef)
	//		_GetData (designRef)
	//		_SetData (designRef, designData)

	return {
		Open:					StorageManager_Open,
		PrepareToClose:			StorageManager_PrepareToClose,
		Close:					StorageManager_Close,
		GetUserSettings:		StorageManager_GetUserSettings,
		StoreUserSettings:		StorageManager_StoreUserSettings,
		//	DELETE ALL
		DeleteAllFiles:			StorageManager_DeleteAllFiles,
		//	ProjectList
		GetProjectList:			StorageManager_ProjectList_Get,
		ProjectList_GetCount:	StorageManager_ProjectList_GetCount,
		CreateProject:			StorageManager_ProjectList_CreateProject,
		DeleteProject:			StorageManager_ProjectList_DeleteProject,
		Rebuild:				StorageManager_ProjectList_Rebuild,
		//	Project 
		Project_Load:			StorageManager_Project_Load,
		Project_Store:			StorageManager_Project_Store,
		Project_IsLoaded:		StorageManager_Project_IsLoaded,
		Project_GetInfo:		StorageManager_Project_GetInfo,
		Project_SetInfo:		StorageManager_Project_SetInfo,
		Project_MarkDirty:		StorageManager_Project_MarkDirty,
		Project_IsDirty:		StorageManager_Project_IsDirty,
		Project_GetDesignList:	StorageManager_Project_GetDesignList,
		Project_CreateDesign:	StorageManager_Project_CreateDesign,
		Project_DeleteDesign:	StorageManager_Project_DeleteDesign,
		Project_GetDesignCount:	StorageManager_Project_GetDesignCount,
		Project_IsDesignDataInProject:	StorageManager_Project_IsDesignDataInProject,
		Project_LookupDesignRef:		StorageManager_Project_LookupDesignRef,
		//	Design
		Design_GetData:			StorageManager_Design_GetData,
		Design_SetData:			StorageManager_Design_SetData,
		Design_MarkDirty:		StorageManager_Design_MarkDirty,
		Design_Load:			StorageManager_Design_Load,
		Design_Store:			StorageManager_Design_Store,
		Design_Load_Thumbnail:	StorageManager_Design_Load_Thumbnail, // 2022.04.11
		Design_Store_Thumbnail:	StorageManager_Design_Store_Thumbnail, // 2022.04.11
		Design_Store_Item:		StorageManager_Design_Store_Item, // 2022.11.10
		Design_Remove_File:		StorageManager_Design_Remove_File, // 2022.11.08
		Design_Get_File_URL:	StorageManager_Design_Get_File_URL, // 2022.11.06
		Design_Get_File:		StorageManager_Design_Get_File, // 2022.11.06
		Design_Has_File:		StorageManager_Design_Has_File, // 2022.11.08
		//	Images
		SaveImage:				StorageManager_SaveImage,
		PurgeImages:			StorageManager_PurgeImages
	}
	
}());

//------------------------------------------------------------------------------
//	Export for public access
//
//------------------------------------------------------------------------------
export { StorageManager };
