import { BasicUnits, MathUtil } from "./VectorUtilLib.js";
import { VectorCornerStyle } from "./VectorUtilLib.js";
import { GfxPath } from "../../GfxContainer/src/GfxDrawing.js";

/*-----------------------------------------------*
 * Architecture
 *
 * GenerateSVG
 * GenerateDXF
 *-----------------------------------------------*/



/*--------------------------------------------------------------------------------------*
 *	Generate SVG
 *
 *--------------------------------------------------------------------------------------*/
var GenerateSVG = (function() {

	var svgData = undefined;
	var svgOffset = undefined;
	var svgUnits = undefined;
	var svgFill = undefined;
	var svgStroke = undefined;
	var svgNamespace = undefined;
	var svgInvertVertical = true; // 2019.10.17: Added
	var svgHeight = undefined;
	var svgFilter = undefined; // 2022.03.10: Add support for filters
	
	//-----------------------------------------------------------------------------------
	//	Init
	//-----------------------------------------------------------------------------------
	var GenerateSVG_Init = function()
	{
		svgData = undefined;
		svgOffset = {x:0, y:0};
		svgUnits = BasicUnits.PIXELS;
		svgFill = { doFill:false, color:"white" };
		svgStroke = { doStroke:false, color:"black", width:1 };
		svgNamespace = { companyName:"default", appName:"default", appData:"default" };
		svgFilter = { apply:false, id:"none" };
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_SetInvertVertical = function(invertVertical)
	{
		svgInvertVertical = invertVertical;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_Start = function(width, height, units)
	{
		GenerateSVG_Init();
		
		svgData = "";
		svgOffset = {x:0, y:0};
		svgUnits = units;
		svgHeight = height; // 2019.10.17: Needed to invert vertical
		
		var unitsStr = "";
		var sp = "    ";
		var cr = "\n";
		
		if (units == BasicUnits.INCHES)
			unitsStr = "in";
		if (units == BasicUnits.MILLIMETERS)
			unitsStr = "mm";
		
					
		//var widthStr     = "width=\"" + width + unitsStr + "\" ";
		//var heightStr    = "height=\"" + height + unitsStr + "\" ";
		//var viewboxStr   = "viewBox=\"0 0 " + width + " " + height + "\" ";
		//var versionStr   = "version=\"1.1\" ";
		//var namespaceStr = "xmlns=\"http://www.w3.org/2000/svg\" ";
		//var namespaceSvg = "xmlns:svg=\"http://www.w3.org/2000/svg\" ";
		//var namespaceInk = "xmlns:inkscape=\"http://www.inkscape.org/namespaces/inkscape\"";
		
		svgData += "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\n";
		svgData += "<!-- Created with Polygonia (https://Polygonia.design) -->\n";
		svgData += "\n";
		svgData += "<svg \n";
		
		svgData += sp + "xmlns=\"http://www.w3.org/2000/svg\" " + cr;
		svgData += sp + "xmlns:svg=\"http://www.w3.org/2000/svg\" " + cr;
		svgData += sp + "xmlns:inkscape=\"http://www.inkscape.org/namespaces/inkscape\"" + cr;
		svgData += sp + "width=\"" + width + unitsStr + "\" " + cr;
		svgData += sp + "height=\"" + height + unitsStr + "\" " + cr;
		svgData += sp + "viewBox=\"0 0 " + width + " " + height + "\" " + cr;
		svgData += sp + "version=\"1.1\" " + cr;
		
		/*	Note included here, but found in Inkscape SVG files
			   xmlns:dc="http://purl.org/dc/elements/1.1/"
			   xmlns:cc="http://creativecommons.org/ns#"
			   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
			   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
			   id="svg8"
			   sodipodi:docname="Square_50mm_x_50mm.svg">
		*/
		
		
		svgData += ">\n";
		svgData += "\n";
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_SetOffset = function(offsetX, offsetY)
	{
		svgOffset.x = offsetX;
		svgOffset.y = offsetY;
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_SetFillInfo = function(doFill, fillColor)
	{
		svgFill.doFill = doFill;
		svgFill.color = fillColor;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_SetStrokeInfo = function(doStroke, strokeColor, strokeWidth)
	{
		svgStroke.doStroke = doStroke;
		svgStroke.color = strokeColor;
		svgStroke.width = strokeWidth;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_Finish = function()
	{
		svgData += "</svg>\n";

		var theData = svgData;
		svgData = undefined;
		
		return theData;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_MoveOrLine_priv = function(path, addMove, point)
	{
		// Move or Line to ptD
		var x = point.x + svgOffset.x;
		var y = point.y + svgOffset.y;
		// 2019.10.17
		if (svgInvertVertical && svgHeight != undefined)
			y = svgHeight - y;

		var c = (addMove) ? " M " : " L ";		
		return c + x + " " + y;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_GetColorInfoForPath_priv = function()
	{
		// Create style string:  style="fill:none;stroke:black;stroke-width:1"
		
		var stylePrefix = " style=\"";
		var styleSuffix = "\"";
		// Start with defaults		
		var fillColor = "none";
		var strokeColor = "black";
		var strokeWidth = 1;
		
		// Use defaults (above) if neither are specified
		if (svgFill.doFill || svgStroke.doStroke)
		{
			if (svgFill.doFill)
				fillColor = svgFill.color;
			else
				fillColor = "none";
			
			if (svgStroke.doStroke)
			{
				strokeColor = svgStroke.color;
				strokeWidth = svgStroke.width;
			}
			else
			{
				strokeColor = "none";
			}
		}

		var fillStr = "fill:" + fillColor + "; ";
		var lineStr = "stroke:" + strokeColor + "; ";
		var widthStr = "stroke-width:" + strokeWidth + "; ";

		// 2022.03.10: Add filter support, initially to provide offset shadow
		var filterStr = "";
		if (svgFilter.apply)
			filterStr = "filter:url(#" + svgFilter.id + ");";

		var colorInfo = stylePrefix + fillStr + lineStr + widthStr + filterStr + styleSuffix;
		
		
		return colorInfo;
	}
	
	//-----------------------------------------------------------------------------------
	//	Builds color style string:
	//	style="fill:none;stroke:black;stroke-width:1"
	//-----------------------------------------------------------------------------------
	var GenerateSVG_GetSpecifiedColorInfo = function(color, stroke, width)
	{
		// Create style string:  style="fill:none;stroke:black;stroke-width:1"
		var colorInfo;

		var stylePrefix = " style=\"";
		var styleSuffix = "\"";
		var fillStr = "fill:" + color + "; ";

		if (stroke != undefined)
		{
			var lineStr = "stroke:" + stroke + "; ";
			var widthStr = "stroke-width:" + width + "; ";
			colorInfo = stylePrefix + fillStr + lineStr + widthStr + styleSuffix;
		}
		else
		{
			colorInfo = stylePrefix + fillStr + styleSuffix;
		}

		return colorInfo;
	}


	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddPolygonList_Angles = function(polyList, tagMatch)
	{
		var path = "";
		var polyCount = 0; // 2020.11.07: Insure we matched at least one polygon
		
		path += "<path d=\""
	
		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			if (polyList.PolygonTagMatches(p, tagMatch))
			{
				polyCount++;
				var simplePoly = polyList.GetPolygonPoints(p);
			
				for (var i = 0; i < simplePoly.length; i++)
					path += GenerateSVG_MoveOrLine_priv(path, (i == 0), simplePoly[i]);
			
				path += " z";
			}
		}
		
		path += "\"";

		/* style */
		path += GenerateSVG_GetColorInfoForPath_priv();
	
		/* ending */
		path += " />\n";
		
		if (polyCount > 0)
			svgData += path;
	}
	
	//-----------------------------------------------------------------------------------
	//	2022.02.05: Added options to pass in vertex info
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddPolygonList_Angles_Colors = function(polyList, options, tagMatch)
	{
		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			let tagInfo = polyList.GetPolygonTagInfo(p);
			if (tagInfo != undefined && tagInfo.isFill /* == "area"*/) // 2021.04.12: Replace "area" with "isFill"
			{
				var path = "";
		
				path += "<path d=\""
	
				var simplePoly = polyList.GetPolygonPoints(p);
		
				//for (var i = 0; i < simplePoly.length; i++)
				//	path += GenerateSVG_MoveOrLine_priv(path, (i == 0), simplePoly[i]);
				//
				//path += " z";
				path += GenerateSVG_CreatePathFromPolygon(simplePoly, tagInfo, options)

				path += "\"";

				/* style */
				var colorInfo = GenerateSVG_GetSpecifiedColorInfo(tagInfo.color, tagInfo.stroke, svgStroke.width);
				path += colorInfo;
	
				/* ending */
				path += " />\n";
		
				svgData += path;
			}
		}
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddPolygonList_ArcCurves = function(polyList, cornerSize, tagMatch)
	{
		var path = "";
		var polyCount = 0; // 2020.11.07: Insure we matched at least one polygon
		
		path += "<path d=\""
	
		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			if (polyList.PolygonTagMatches(p, tagMatch))
			{
				polyCount++;
				var simplePoly = polyList.GetPolygonPoints(p);

				for (var i = 0; i < simplePoly.length; i++)
				{
					var ptA = simplePoly[i];
					var ptB = simplePoly[(i + 1) % simplePoly.length];
					var ptC = simplePoly[(i + 2) % simplePoly.length];
					var renderCurve = true;
					var arcParams = undefined;
			
					if (ptB.omitCurve != undefined)
						renderCurve = false;
				
					if (renderCurve)
						arcParams = MathUtil.CalcTangentArcParams(ptA, ptB, ptC, cornerSize);
				
					if (arcParams != undefined)
					{		
						// Add Move (to first point) or Line
						path += GenerateSVG_MoveOrLine_priv(path, (i == 0), arcParams.ptTangentAB);
					
						// Add Arc
						var r = arcParams.radius;
						var x = arcParams.ptTangentBC.x + svgOffset.x;
						var y = arcParams.ptTangentBC.y + svgOffset.y;
						var flag = arcParams.ccw ? "0" : "1";

						// 2019.10.17
						if (svgInvertVertical && svgHeight != undefined)
						{
							y = svgHeight - y;
							flag = (flag == "0") ? "1" : "0";
						}

						// A rx ry x-axis-rotation large-arc-flag sweep-flag x y
						path += " A " + r + " " + r  + " 0 " +  "0" + " " + flag + " " + x + " " + y;
					}
					else
					{
						// If we failed to calculate arc parameters, add Move (to first point) or Line
						path += GenerateSVG_MoveOrLine_priv(path, (i == 0), ptB);
					}
					
				}

				path += " z";
			}
		}
		
		path += "\"";

		/* style */
		path += GenerateSVG_GetColorInfoForPath_priv();
	
		/* ending */
		path += " />\n";
		
		if (polyCount > 0)
			svgData += path;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddPolygonList_QuadCurves = function(polyList, cornerSize, tagMatch)
	{
		var path = "";
		var polyCount = 0; // 2020.11.07: Insure we matched at least one polygon
		
		path += "<path d=\""
	
		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			if (polyList.PolygonTagMatches(p, tagMatch))
			{
				polyCount++;
				var simplePoly = polyList.GetPolygonPoints(p);

				for (var i = 0; i < simplePoly.length; i++)
				{
					// ABC describes a vertex in the polygon
					var ptA = simplePoly[i];
					var ptB = simplePoly[(i + 1) % simplePoly.length];
					var ptC = simplePoly[(i + 2) % simplePoly.length];
					var renderCurve = true;
					var quadParams = undefined;
			
					if (ptB.omitCurve != undefined)
						renderCurve = false;
				
					if (renderCurve)
						quadParams = MathUtil.CalcQuadBezierParams(ptA, ptB, ptC, cornerSize);
				
					if (quadParams != undefined)
					{
						// Move or Line to ptTangentAB
						path += GenerateSVG_MoveOrLine_priv(path, (i == 0), quadParams.ptTangentAB);

						// Quad Bezier
						var x = quadParams.ptTangentBC.x + svgOffset.x;
						var y = quadParams.ptTangentBC.y + svgOffset.y;
						var x1 = ptB.x + svgOffset.x;
						var y1 = ptB.y + svgOffset.y;
					
						// 2019.10.17
						if (svgInvertVertical && svgHeight != undefined)
						{
							y = svgHeight - y;
							y1 = svgHeight - y1;
						}

						// Q x1 y1, x y 
						path += " Q " + x1 + " " + y1  + " " +  x + " " + y;
					}
					else
					{
						// Move or Line to ptB
						path += GenerateSVG_MoveOrLine_priv(path, (i == 0), ptB);
					}
				}

				path += " z";
			}
		}
		
		path += "\"";

		/* style */
		path += GenerateSVG_GetColorInfoForPath_priv();
	
		/* ending */
		path += " />\n";
		
		if (polyCount > 0)
			svgData += path;
	}
	
	//-----------------------------------------------------------------------------------
	//	2022.02.05: Added
	//-----------------------------------------------------------------------------------
	var GenerateSVG_CreatePathFromPolygon = function(simplePoly, tagInfo, options)
	{
		let cornerStyle = (options.cornerStyle != undefined) ? options.cornerStyle : VectorCornerStyle.ANGLE;
		let cornerSize = (options.cornerSize != undefined) ? options.cornerSize : 0;
		let isClosed = (options.isClosed != undefined) ? options.isClosed : true;
		
		// 2022.03.09: Use 'isClosed' from tagInfo, if provided
		if (tagInfo != undefined && tagInfo.isClosed != undefined)
			isClosed = tagInfo.isClosed;

		var path = "";

		let len = simplePoly.length;
		for (var i = 0; i < len; i++)
		{
			var ptA = simplePoly[(i - 1 + len) % len];
			var ptB = simplePoly[i];
			var ptC = simplePoly[(i + 1) % len];
			var renderCurve = true;
			var renderHalfCurve = undefined;
			var renderFullCurve = false; // 2022.02.07
			var curveParams = undefined;

			if (ptB.omitCurve != undefined)
				renderCurve = false;

			// 2022.03.10: Do not render curves on the first and last point for open polygons
			if (!isClosed && (i == 0 || i == len -1))
				renderCurve = false;

			// 2022.02.02: Handle "half curves". See note above.
			if (renderCurve && ptB.halfCurvePt != undefined)
			{
				renderHalfCurve = ptB.halfCurvePt.which;
				renderFullCurve = ptB.halfCurvePt.renderFullCurve;
				if (ptB.halfCurvePt.which == "prev")
					ptA = ptB.halfCurvePt;
				else
					ptC = ptB.halfCurvePt;
			}

			if (renderCurve && cornerSize > 0)
			{
				// 2022.02.12: Add curveLimit
				if (cornerStyle == VectorCornerStyle.ARC)
					curveParams = MathUtil.CalcTangentArcParams(ptA, ptB, ptC, {distance:cornerSize, curveLimit:options.curveLimit});
				else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
					curveParams = MathUtil.CalcQuadBezierParams(ptA, ptB, ptC, {distance:cornerSize, curveLimit:options.curveLimit});
			}

			if (curveParams == undefined)
			{
				// If we failed to calculate arc parameters, add Move (to first point) or Line
				path += GenerateSVG_MoveOrLine_priv(path, (i == 0), ptB);
			}
			else if (cornerStyle == VectorCornerStyle.ARC)
			{
				// An SVG arc is described (as far as we are concerned) with a start point, an end point,
				// a radius, and a direction (clockwise or counter clockwise). 
				var arcFromPt = undefined;
				var arcToPt;

				// Render a full arc curve, as defined by the three points of the vertex
				if (renderHalfCurve == undefined || renderFullCurve)
				{
					// Start with the first point of the curve, and ..
					arcFromPt = curveParams.ptTangentAB;
					// .. end at the last point of the curve
					arcToPt = curveParams.ptTangentBC
				}
				// Render the second half of the arc curve
				else if (renderHalfCurve == "prev") 
				{
					// "prev": The previous offset vertex was provided to define the complete curve. Therefore, we are
					// rendering the second half of the arc, which means we are starting at the middle.
					
					// If this is the first vertex we are plotting, then we need to do a "move to"
					arcFromPt = curveParams.ptMiddle;
					// And render the second half of the arc
					arcToPt = curveParams.ptTangentBC
				}
				// Render the first half of the arc curve
				else // "next"
				{
					// "next": The next offset vertex was provided to define the complete curve. Therefore, we are
					// rendering the first half of the arc, which means we are ending at the middle.
					
					// Rendering the first half of the arc, so we have to move to the beginning of it ..
					arcFromPt = curveParams.ptTangentAB;
					// .. and end at the middle point
					arcToPt = curveParams.ptMiddle;
				}

				// Add Move (to first point) or Line
				path += GenerateSVG_MoveOrLine_priv(path, (i == 0), arcFromPt);

				// Add Arc
				var r = curveParams.radius;
				var x = arcToPt.x + svgOffset.x;
				var y = arcToPt.y + svgOffset.y;
				var flag = curveParams.ccw ? "0" : "1";

				// 2019.10.17
				if (svgInvertVertical && svgHeight != undefined)
				{
					y = svgHeight - y;
					flag = (flag == "0") ? "1" : "0";
				}

				// A rx ry x-axis-rotation large-arc-flag sweep-flag x y
				path += " A " + r + " " + r  + " 0 " +  "0" + " " + flag + " " + x + " " + y;
			}
			else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
			{
				// 2022.03.10: Add 'half curve'
				var startPt   = undefined;
				var endPt     = undefined;
				var controlPt = undefined;

				if (renderHalfCurve == undefined || renderFullCurve)
				{
					startPt    = curveParams.ptTangentAB;
					controlPt  = ptB;
					endPt      = curveParams.ptTangentBC;
				}
				else if (renderHalfCurve == "prev")
				{
					startPt    = curveParams.ptMiddle;
					controlPt  = curveParams.ptMidBC;
					endPt      = curveParams.ptTangentBC;
				}
				else // "next"
				{
					startPt    = curveParams.ptTangentAB;
					controlPt  = curveParams.ptMidAB;
					endPt      = curveParams.ptMiddle;
				}

				// Move or Line to ptTangentAB
				path += GenerateSVG_MoveOrLine_priv(path, (i == 0), startPt);

				// Quad Bezier
				var x = endPt.x + svgOffset.x;
				var y = endPt.y + svgOffset.y;
				var x1 = controlPt.x + svgOffset.x;
				var y1 = controlPt.y + svgOffset.y;

				// 2019.10.17
				if (svgInvertVertical && svgHeight != undefined)
				{
					y = svgHeight - y;
					y1 = svgHeight - y1;
				}

				// Q x1 y1, x y 
				path += " Q " + x1 + " " + y1  + " " +  x + " " + y;
			}

		}

		if (isClosed)
			path += " z";
		
		
		return path;
	}
	
	//-----------------------------------------------------------------------------------
	//	2022.02.04: Added
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddPolygonList_perVertexCurves = function(polyList, options, tagMatch)
	{
		var path = "";
		var polyCount = 0; // 2020.11.07: Insure we matched at least one polygon

		path += "<path d=\""

		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			if (polyList.PolygonTagMatches(p, tagMatch))
			{
				polyCount++;
				var simplePoly = polyList.GetPolygonPoints(p);
				var tagInfo = polyList.GetPolygonTagInfo(p);
				path += GenerateSVG_CreatePathFromPolygon(simplePoly, tagInfo, options)
			}
		}

		path += "\"";

		/* style */
		path += GenerateSVG_GetColorInfoForPath_priv();

		/* ending */
		path += " />\n";

		if (polyCount > 0)
			svgData += path;
	}

	//-----------------------------------------------------------------------------------
	//	Add Polygon List
	//
	//	2020.10.23: Support alternative polySettings parameter
	//	2021.04.13: Remove original cornerStyle and cornerSize parameters and only
	//	support polySettings parameter
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddPolygonList = function(polyList, polySettings = {})
	{
		// 2020.10.23: Use defaults
		// 2021.04.13: Only support polySettings parameter
		// 2022.02.04: Changed polySettings default from undefined to {} so I can condense the settings code here
		var cornerSize  = (polySettings.cornerSize  != undefined) ? polySettings.cornerSize : 0.0;
		var cornerStyle = (polySettings.cornerStyle != undefined) ? polySettings.cornerStyle : VectorCornerStyle.ANGLE;
		var isClosed    = (polySettings.isClosed    != undefined) ? polySettings.isClosed : true;
		var tagMatch    = (polySettings.tagMatch    != undefined) ? polySettings.tagMatch: {isStroke:true}; // 2021.04.12: Default is "isStroke"
		var curveLimit  = polySettings.curveLimit; // 2022.02.12

		GenerateSVG_AddPolygonList_perVertexCurves(polyList, {cornerSize, cornerStyle, isClosed, curveLimit}, tagMatch);

		/*
		// 2019.09.16: Render angles if the corner size is zero
		if (cornerStyle == VectorCornerStyle.ANGLE || cornerSize < 0.0001)
			GenerateSVG_AddPolygonList_Angles(polyList, tagMatch);
			
		else if (cornerStyle == VectorCornerStyle.ARC)
			GenerateSVG_AddPolygonList_ArcCurves(polyList, cornerSize, tagMatch);
			
		else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
			GenerateSVG_AddPolygonList_QuadCurves(polyList, cornerSize, tagMatch);
			
		else
			Debug_assert("GenerateSVG_AddPolygonList: unknown cornerStyle: " + cornerStyle);			
		*/
	}
	
	//-----------------------------------------------------------------------------------
	//	Add Polygon List
	//
	//	2020.10.23: Support alternative polySettings parameter
	//	2022.02.05: Replace multiple params with polySettings
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddPolygonListColors = function(polyList, polySettings = {} /*, cornerStyleOrPolySettings = undefined, cornerSizeParam = undefined*/)
	{
		// 2022.02.04: Replace multiple params with polySettings
		var cornerSize  = (polySettings.cornerSize  != undefined) ? polySettings.cornerSize : 0.0;
		var cornerStyle = (polySettings.cornerStyle != undefined) ? polySettings.cornerStyle : VectorCornerStyle.ANGLE;

		GenerateSVG_AddPolygonList_Angles_Colors(polyList, {cornerSize, cornerStyle});
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddCircleList = function(circleList)
	{
		// <circle cx="100" cy="100" r="100"/>
		for (var i = 0; i < circleList.length; i++)
		{
			var h = circleList[i];
			
			var x = h.x + svgOffset.x;
			var y = h.y + svgOffset.y;
			
			var circle = "<circle cx=\"" + x + "\" cy=\"" + y + "\" r=\"" + h.r + "\"/>\n";
			
			svgData += circle;
			
		}
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddNotchesList = function(notchesList)
	{
		var path = "";
		
		path += "<path d=\""
	
		for (var p = 0; p < notchesList.length; p++)
		{
			var notch = notchesList[p];
			
			for (var i = 0; i < notch.length; i++)
				path += GenerateSVG_MoveOrLine_priv(path, (i == 0), notch[i]);
			
			path += " z";
		}
		
		path += "\"";

		/* Using default style of black */
	
		/* ending */
		path += " />\n";
		
		svgData += path;
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddGfxPath = function(gfxPath)
	{
		var path = "";
		
		path += "<path d=\""

		for (var i = 0; gfxPath != undefined && i < gfxPath.GetOpCount(); i++)
		{
			let op = gfxPath.GetOp(i);
			
			if (op.op == GfxPath.Op.NOOP)
			{
			}
			else if (op.op == GfxPath.Op.MOVETO)
			{
				path += GenerateSVG_MoveOrLine_priv(path, 1, op.pt);
			}
			else if (op.op == GfxPath.Op.LINETO)
			{
				path += GenerateSVG_MoveOrLine_priv(path, 0, op.pt);
			}
			else if (op.op == GfxPath.Op.ARC)
			{
				// Add Arc
				var x = op.pt.x + svgOffset.x;
				var y = op.pt.y + svgOffset.y;
				var flag = op.ccw ? "0" : "1";
				
				var xd = op.r * Math.cos(op.end);
				var yd = op.r * Math.sin(op.end);
				
				x += xd;
				y += yd;

				// 2019.10.17
				if (svgInvertVertical && svgHeight != undefined)
				{
					y = svgHeight - y;
					flag = (flag == "0") ? "1" : "0";
				}

				// A rx ry x-axis-rotation large-arc-flag sweep-flag x y
				path += " A " + op.r + " " + op.r  + " 0 " +  "0" + " " + flag + " " + x + " " + y;
			}
			else if (op.op == GfxPath.Op.CLOSE)
			{
				path += " z";
			}
			else if (op.op == GfxPath.Op.CIRCLE)
			{
				// Add a circle to the path by using two arcs, each a semi-circle.
				var x = op.pt.x + svgOffset.x;
				var y = op.pt.y + svgOffset.y;

				// For SVG, we need points on the arc, so compute the left and right edges
				var xLeft  = x - op.r;
				var xRight = x + op.r;

				if (svgInvertVertical && svgHeight != undefined)
					y = svgHeight - y;

				// MoveTo the left edge of the circle
				path += " M " + xLeft + " " + y
				// Two arcs. Since we are doing semi-circles, everything simplifies to
				// a radius, a vertical value, and a left and a right value. We can use
				// '0' for the remaining values
				// Arc: A rx ry x-axis-rotation large-arc-flag sweep-flag x y
				path += " A " + op.r + " " + op.r + " 0 0 0 " + xRight + " " + y;
				path += " A " + op.r + " " + op.r + " 0 0 0 " + xLeft  + " " + y;
			}
		}

		path += "\"";

		/* style */
		path += GenerateSVG_GetColorInfoForPath_priv();
	
		/* ending */
		path += " />\n";
		
		svgData += path;
		
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_SetPrivateNamespace = function(companyName, appName, dataName)
	{
		svgNamespace.companyName = companyName;
		svgNamespace.appName     = appName;
		svgNamespace.appData     = dataName;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_EmbedPrivateData = function(dataId, data)
	{
	/*
		https://www.w3.org/TR/SVG/extend.html
		
			<?xml version="1.0" standalone="yes"?>
			<svg width="4in" height="3in" version="1.1" xmlns = 'http://www.w3.org/2000/svg'>
			  <defs>
				<myapp:piechart xmlns:myapp="http://example.org/myapp" title="Sales by Region">
				  <myapp:pieslice label="Northern Region" value="1.23"/>
				  <myapp:pieslice label="Eastern Region" value="2.53"/>
				  <myapp:pieslice label="Southern Region" value="3.89"/>
				  <myapp:pieslice label="Western Region" value="2.04"/>
				  <!-- Other private data goes here -->
				</myapp:piechart>
			  </defs>
			  <desc>This chart includes private data in another namespace
			  </desc>
			  <!-- In here would be the actual SVG graphics elements which
				   draw the pie chart -->
			</svg>
	*/
		
		svgData += "<defs>\n";
		svgData += "  <" + svgNamespace.companyName + ":" + svgNamespace.appName + " xmlns:thevaportrail=\"http://thevaportrail.com\">\n"
		svgData += "    <" + svgNamespace.companyName + ":" + svgNamespace.appData + " id=\"" + dataId + "\">\n";
		svgData += "      " + data + "\n";
		svgData += "    </" + svgNamespace.companyName + ":" + svgNamespace.appData + ">\n";
		svgData += "  </" + svgNamespace.companyName + ":" + svgNamespace.appName + ">\n";
		svgData += "</defs>\n";
	}
	
	//-----------------------------------------------------------------------------------
	//	2021.05.30: Added groupmode
	//-----------------------------------------------------------------------------------
	var GenerateSVG_StartGroup = function(groupParams)
	{
		var groupStr = "";
		
		groupStr += "<g \n";

		if (groupParams != undefined && groupParams.label != undefined)
		{
			// 2021.05.30: Added ability to pass group mode, to select "group" or "layer"
			var groupMode = (groupParams.groupMode != undefined) ? groupParams.groupMode : "layer";
			
			groupStr += "    inkscape:label=\"" + groupParams.label.name + "\"\n";
			groupStr += "    inkscape:groupmode=\"" + groupMode + "\"\n";
			groupStr += "    id=\"" + groupParams.label.id + "\"\n";
		}
		// 2022.03.09: Removing 'transform=' line since it prevents Cricut from calculating the correct size
		// and because it is not in the latest Inkscape SVG files
		//groupStr += "    transform=\"translate(0,0)\"\n";
		
		groupStr += ">\n";		
		svgData += groupStr;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_EndGroup = function()
	{
		svgData += "</g>\n";
		
	}

	//-----------------------------------------------------------------------------------
	//	GenerateSVG: Add Blur Filter
	//
	//	2022.03.10: Adds an offset shadow filter to the SVG in a defs tag.
	//	The initial implementation uses a hard-coded filter id, so only a single filter
	//	is supported.
	//
	//   <defs>
	//     <filter id="f1">
	//       <feGaussianBlur in="SourceGraphic" stdDeviation="0.1" result="blur"/>
	//       <feOffset in="blur" dx="0.5" dy="0" result="offsetBlur"/>
	//     </filter>
	//   </defs>
	//
	//-----------------------------------------------------------------------------------
	var GenerateSVG_AddBlurFilter = function(filterSettings)
	{
		let filterId = "offsetBlurFilter";
		let offsetX = filterSettings.offsetX;
		let offsetY = filterSettings.offsetY;
		let stdDev = filterSettings.stdDev;

		let defs = "";
		defs += "<defs>\n";
		defs += "  <filter id=\"" + filterId + "\">\n";
		defs += "    <feGaussianBlur in=\"SourceGraphic\" stdDeviation=\"" + stdDev + "\" result=\"blur\"/>\n"
		defs += "    <feOffset in=\"blur\" dx=\"" + offsetX + "\" dy=\"" + offsetY + "\" result=\"offsetBlur\"/>\n";
		defs += "  </filter>\n";
		defs += "</defs>\n";

		svgData += defs;
	}
	
	//-----------------------------------------------------------------------------------
	//	GenerateSVG: Enable Filter
	//
	//	2022.03.10: Added to enable or disable offset shadow filter. Presently only the
	//	hard-coded filter id is supported, but this could easily be extended to accept
	//	the filter id.
	//-----------------------------------------------------------------------------------
	var GenerateSVG_EnableFilter = function(options)
	{
		svgFilter.apply = options.applyFilter;
		svgFilter.id = "offsetBlurFilter";
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	return {
		Start:					GenerateSVG_Start,
		SetOffset:				GenerateSVG_SetOffset,
		Finish:					GenerateSVG_Finish,
		SetPrivateNamespace:	GenerateSVG_SetPrivateNamespace,
		EmbedPrivateData:		GenerateSVG_EmbedPrivateData,
		SetInvertVertical:		GenerateSVG_SetInvertVertical,
		AddPolygonList:			GenerateSVG_AddPolygonList,
		AddPolygonListColors:	GenerateSVG_AddPolygonListColors, // 2021.03.27
		AddCircleList:			GenerateSVG_AddCircleList,
		AddNotchesList:			GenerateSVG_AddNotchesList,
		AddGfxPath:				GenerateSVG_AddGfxPath,
		SetFillInfo:			GenerateSVG_SetFillInfo,
		SetStrokeInfo:			GenerateSVG_SetStrokeInfo,
		StartGroup:				GenerateSVG_StartGroup,
		EndGroup:				GenerateSVG_EndGroup,
		AddBlurFilter:			GenerateSVG_AddBlurFilter,
		EnableFilter:			GenerateSVG_EnableFilter,
	};
}());


/*--------------------------------------------------------------------------------------*
 *	Generate DXF
 *
 *--------------------------------------------------------------------------------------*/
var GenerateDXF = (function() {

	var dxfData = undefined;
	var dxfOffset = undefined;
	var dxfNewLine = undefined;
	var dxfUnits = undefined;
	var dxfViewSize = undefined;
	var dxfPrefix = "   ";
	var dxfNextHandle = 4096; // 0x1000
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_Init = function()
	{
		dxfData = undefined;
		dxfOffset = {x:0, y:0};
		dxfNewLine = "\n";
		dxfUnits = BasicUnits.PIXELS;
		dxfViewSize = {x:0, y:0};
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_Start = function(width, height, units)
	{
		GenerateDXF_Init();
		
		// This will write everything except the polygon data
		dxfData = "";
		dxfViewSize = {x:width, y:height};
		dxfOffset = {x:0, y:0};
		dxfUnits = units;

		GenerateDXF_Comments();
		GenerateDXF_Header();
		GenerateDXF_Tables();
		GenerateDXF_Blocks();
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_SetOffset = function(offsetX, offsetY)
	{
		// Set the offset for the polygon data
		dxfOffset.x = offsetX;
		dxfOffset.y = offsetY;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_Finish = function()
	{
		// Write "EOF"
		GenerateDXF_WriteFileTrailer();
		
		// Return the data and clear the internal storage
		var theData = dxfData;
		dxfData = undefined;
		
		return theData;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_PushRecord = function(data, a, b)
	{
		data.push(dxfPrefix + a);
		data.push(b);
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_NextHandle = function()
	{
		var h = dxfNextHandle;
		dxfNextHandle++;
		
		return h.toString(16);
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AddPolygonList_Angles = function(polyList, tagMatch)
	{
		/*
			Lwpolyline group codes
			Group codes	Description
			100		Subclass marker (AcDbPolyline)
			90		Number of vertices
			70		Polyline flag (bit-coded); default is 0: 1 = Closed; 128 = Plinegen
			43		Constant width (optional; default = 0). Not used if variable width (codes 40 and/or 41) is set
			38		Elevation (optional; default = 0)
			39		Thickness (optional; default = 0)
			10		Vertex coordinates (in OCS), multiple entries; one entry for each vertex;DXF: X value; APP: 2D point
			20		DXF: Y value of vertex coordinates (in OCS), multiple entries; one entry for each vertex
		*/
		
		var data = [];
		
		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			if (polyList.PolygonTagMatches(p, tagMatch))
			{
				var simplePoly = polyList.GetPolygonPoints(p);
			
				if (simplePoly.length > 0)
				{
					var simpleData = [];
					GenerateDXF_PushRecord(simpleData, "0",   "LWPOLYLINE");	
					GenerateDXF_PushRecord(simpleData, "5",   GenerateDXF_NextHandle());// Entity handle
					GenerateDXF_PushRecord(simpleData, "100", "AcDbEntity"); 		// Subclass marker
					GenerateDXF_PushRecord(simpleData, "8",   "Layer_1");			// Layer name
					GenerateDXF_PushRecord(simpleData, "62",  "0");					// Color number
					GenerateDXF_PushRecord(simpleData, "100", "AcDbPolyline"); 		// Subclass marker
					GenerateDXF_PushRecord(simpleData, "90",  simplePoly.length);	// Number of vertices
					GenerateDXF_PushRecord(simpleData, "70",  "1"); 				// Polyline flag
					//GenerateDXF_PushRecord(simpleData, "43",  "1"); 				// Constant width
			
					for (var i = 0; i < simplePoly.length; i++)
					{
						GenerateDXF_PushRecord(simpleData, "10", simplePoly[i].x + dxfOffset.x);
						GenerateDXF_PushRecord(simpleData, "20", simplePoly[i].y + dxfOffset.y);
						//GenerateDXF_PushRecord(simpleData, "30", 0);
					}
				
					data.push(simpleData);
				}
			}
		}
		
 		GenerateDXF_WriteSectionRecords(data);
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AppendLine_priv = function(data, startPt, endPt)
	{
		/*
			Line group codes
			Group codes	Description
			100		Subclass marker (AcDbLine)
			39		Thickness (optional; default = 0)
			10		Start point (in WCS); DXF: X value; APP: 3D point
			20, 30	DXF: Y and Z values of start point (in WCS)
			11		End point (in WCS); DXF: X value; APP: 3D point
			21, 31	DXF: Y and Z values of end point (in WCS)
		*/
		GenerateDXF_PushRecord(data, "0",   "LINE");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity");		// Subclass marker
		GenerateDXF_PushRecord(data, "8",   "Layer_1");			// Layer name
		GenerateDXF_PushRecord(data, "62",  "0");				// Color number
		GenerateDXF_PushRecord(data, "5",   GenerateDXF_NextHandle());	// Entity handle		
		GenerateDXF_PushRecord(data, "100", "AcDbLine"); 		// Subclass marker
		//GenerateDXF_PushRecord(data, "330", "99");			// Soft-pointer ID/handle to owner BLOCK_RECORD object
		//GenerateDXF_PushRecord(data, "6",   "0");				// Linetype name
		//GenerateDXF_PushRecord(data, "39",  "1");				// Thickness (optional; default = 0)
		GenerateDXF_PushRecord(data, "10", startPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20", startPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "11", endPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "21", endPt.y + dxfOffset.y);
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AppendArc_priv = function(data, centerPt, radius, startAngle, endAngle)
	{
		/*
			Arc group codes
			Group codes	Description
			100		Subclass marker (AcDbCircle)
			39		Thickness (optional; default = 0)
			10		Center point (in OCS);DXF: X value; APP: 3D point
			20, 30	DXF: Y and Z values of center point (in OCS)
			40		Radius
			100		Subclass marker (AcDbArc)
			50		Start angle
			51		End angle
		*/
		GenerateDXF_PushRecord(data, "0",   "ARC");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "8",   "Layer_1");			// Layer name
		GenerateDXF_PushRecord(data, "62",  "0");				// Color number
		GenerateDXF_PushRecord(data, "5",   GenerateDXF_NextHandle());	// Entity handle
		GenerateDXF_PushRecord(data, "100", "AcDbCircle"); 		// Subclass marker
		//GenerateDXF_PushRecord(simpleData, "43",  "1"); 		// ??? Constant width
		GenerateDXF_PushRecord(data, "10",  centerPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",  centerPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "40",  radius);
		GenerateDXF_PushRecord(data, "100", "AcDbArc"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "50",  startAngle);
		GenerateDXF_PushRecord(data, "51",  endAngle);
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AppendQuadBezier_v1_priv = function(data, startPt, endPt, controlPt)
	{
		/*
			Polyline group codes
			Group codes	Description
			100		Subclass marker (AcDb2dPolyline or AcDb3dPolyline)
			10		DXF: always 0
			20		DXF: always 0
			30		DXF: polyline's elevation (in OCS when 2D, WCS when 3D)
			39		Thickness (optional; default = 0)
			66		vertices follow
			70		Polyline flag (bit-coded); default is 0:
						1 = This is a closed polyline (or a polygon mesh closed in the M direction).
						2 = Curve-fit vertices have been added.
						4 = Spline-fit vertices have been added.
						8 = This is a 3D polyline.
						16 = This is a 3D polygon mesh.
						32 = The polygon mesh is closed in the N direction.
						64 = The polyline is a polyface mesh.
						128 = The linetype pattern is generated continuously around the vertices of this polyline.
			40		Default start width (optional; default = 0)
			41		Default end width (optional; default = 0)
			75		Curves and smooth surface type (optional; default = 0); integer codes, not bit-coded:
						0 = No smooth surface fitted
						5 = Quadratic B-spline surface
						6 = Cubic B-spline surface
						8 = Bezier surface

			Vertex group codes
			Group codes	Description
			100		Subclass marker (AcDbVertex)
			100		Subclass marker (AcDb2dVertex or AcDb3dPolylineVertex)
			10		Location point (in OCS when 2D, and WCS when 3D); DXF: X value; APP: 3D point
			20, 30	DXF: Y and Z values of location point (in OCS when 2D, and WCS when 3D)
			40		Starting width (optional; default is 0)
			41		Ending width (optional; default is 0)
			42		Bulge (optional; default is 0). The bulge is the tangent of one fourth the included angle for an arc segment, made negative if the arc goes clockwise from the start point to the endpoint. A bulge of 0 indicates a straight segment, and a bulge of 1 is a semicircle.
			70		Vertex flags:
						1 = Extra vertex created by curve-fitting
						2 = Curve-fit tangent defined for this vertex. A curve-fit tangent direction of 0 may be omitted from DXF output but is significant if this bit is set.
						4 = Not used
						8 = Spline vertex created by spline-fitting
						16 = Spline frame control point
						32 = 3D polyline vertex
						64 = 3D polygon mesh
						128 = Polyface mesh vertex
			50		Curve fit tangent direction
		*/
		
		// Polyline
		GenerateDXF_PushRecord(data, "0",   "POLYLINE");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "8",   "Layer_1");			// Layer name
		GenerateDXF_PushRecord(data, "62",  "0");				// Color number
		GenerateDXF_PushRecord(data, "5",   GenerateDXF_NextHandle());				// Entity handle
		GenerateDXF_PushRecord(data, "100", "AcDb2dPolyline"); 	// Subclass marker
		GenerateDXF_PushRecord(data, "10",   "0");
		GenerateDXF_PushRecord(data, "20",   "0");
		GenerateDXF_PushRecord(data, "30",   "0");
		GenerateDXF_PushRecord(data, "66",   1);				// Vertices Follow flag (2017.09.11)
		GenerateDXF_PushRecord(data, "70",   4);				// Polyline flags
		GenerateDXF_PushRecord(data, "75",   "5");				// Curves and smooth surface type

		// Vertices
		GenerateDXF_PushRecord(data, "0",   "VERTEX");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity"); 		// Subclass marker
		//GenerateDXF_PushRecord(data, "5",   "100");				// Entity handle
		GenerateDXF_PushRecord(data, "100", "AcDbVertex"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "100", "AcDb2dVertex"); 	// Subclass marker
		GenerateDXF_PushRecord(data, "10",   startPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",   startPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "70",   "0");				// Vertex flags

		GenerateDXF_PushRecord(data, "0",   "VERTEX");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity"); 		// Subclass marker
		//GenerateDXF_PushRecord(data, "5",   "100");				// Entity handle
		GenerateDXF_PushRecord(data, "100", "AcDbVertex"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "100", "AcDb2dVertex"); 	// Subclass marker
		GenerateDXF_PushRecord(data, "10",   endPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",   endPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "70",   "0");				// Vertex flags

		GenerateDXF_PushRecord(data, "0",   "VERTEX");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity"); 		// Subclass marker
		//GenerateDXF_PushRecord(data, "5",   "100");				// Entity handle
		GenerateDXF_PushRecord(data, "100", "AcDbVertex"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "100", "AcDb2dVertex"); 	// Subclass marker
		GenerateDXF_PushRecord(data, "10",   controlPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",   controlPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "70",   16 + 8 + 2 + 1);	// Vertex flags
		
		// End vertex list
		GenerateDXF_PushRecord(data, "0",   "SEQEND");	

	}
	

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AppendDimension = function(data, dimensionName, dimensions)
	{
		/*
			Dimension group codes
			Group codes	Description
			0		Entity type ('DIMENSION')
			8		Layer name
			5		Handle
			100		Subclass marker (AcDbEntity)
			62		Color number (present if not BYLAYER); 
						zero indicates the BYBLOCK (floating) color; 
						256 indicates BYLAYER; 
						a negative value indicates that the layer is turned off (optional)
					(openscad_dxf.py: 256)
			370		Lineweight enum value. Stored and moved around as a 16-bit integer. (value: -1)
			6		Linetype name (present if not BYLAYER). 
						The special name BYBLOCK in- dicates a floating linetype (optional)
					(openscad_dxf.py:'ByLayer')
			1		Dimension text explicitly entered by the user. Optional; default is the measurement. 
						If null or “<>”, the dimension measurement is drawn as the text, 
						if ““ (one blank space), the text is suppressed. 
						Anything else is drawn as the text
					(param: name)
			3		Dimension style name (value:'Standard')
			70		Dimension type
						0 = Rotated, horizontal, or vertical; 
						1 = Aligned
						2 = Angular; 
						3 = Diameter; 
						4 = Radius
						5 = Angular 3 point; 
						6 = Ordinate
						32 = Indicates that the block reference (group code 2) is referenced by this dimension only
						64 = Ordinate type. This is a bit value (bit 7) used only with integer value 6. If set, ordinate is X-type; if not set, ordinate is Y-type
						128 = This is a bit value (bit 8) added to the other group 70 values if the dimension text has been positioned at a user-defined location rather than at the default location			
					(value: 0)
			71		Attachment point:
						1 = Top left; 
						2 = Top center; 
						3 = Top right
						4 = Middle left; 
						5 = Middle center; 
						6 = Middle right 
						7 = Bottom left; 
						8 = Bottom center; 
						9 = Bottom right
					(value: 5)
			72		Dimension text line-spacing style (optional):
						1 (or missing) = At least (taller characters will override) 
						2 = Exact (taller characters will not override)
					(value: 1)
			41		Dimension text-line spacing factor (optional):
						Percentage of default (3-on-5) line spacing to be applied. 
						Valid values range from 0.25 to 4.00
					(value: 1.0)
			100		Subclass marker (AcDbAlignedDimension)
			13		Definition point for linear and angular dimensions (in WCS) DXF: X value 		'%f' % (x[0] if x is not None and x[0] is not None else 0.0)
			23		DXF: Y values of definition point for linear and angular dimensions (in WCS)	'%f' % (y[0] if y is not None and y[0] is not None else 0.0)
			33		DXF: Z values of definition point for linear and angular dimensions (in WCS)	0.0
			14		Definition point for linear and angular dimensions (in WCS) DXF: X value		'%f' % (x[1] if x is not None and x[1] is not None else 0.0)
			24		DXF: Y values of definition point for linear and angular dimensions (in WCS)	'%f' % (y[1] if y is not None and y[1] is not None else 0.0)
			34		DXF: Z values of definition point for linear and angular dimensions (in WCS)	0.0
			50		Angle of rotated, horizontal, or vertical dimensions							'%f' % (90.0 if y is not None else 0.0)
			100		Subclass marker (AcDbRotatedDimension)
			*/
			
			// All dimensions will go into the new "Dimensions" layer
			let layerName = "Dimensions";
			
			var minX = (dimensions.minX != undefined) ? dimensions.minX : 0.0;
			var maxX = (dimensions.maxX != undefined) ? dimensions.maxX : 0.0;
			var minY = (dimensions.minY != undefined) ? dimensions.minY : 0.0;
			var maxY = (dimensions.maxY != undefined) ? dimensions.maxY : 0.0;
			var angle = 0.0;
			
			if (dimensions.minY != undefined || dimensions.maxY != undefined || dimensions.height != undefined)
				angle = 90.0;
				
			if (dimensions.width != undefined)
			{
				minX = 0;
				maxX = dimensions.width;
			}
			
			if (dimensions.height != undefined)
			{
				minY = 0;
				maxY = dimensions.height;
			}
			
			
			GenerateDXF_PushRecord(data, "0",	"DIMENSION");				// Entity type
			GenerateDXF_PushRecord(data, "8",	layerName);					// Layer name
			GenerateDXF_PushRecord(data, "5",	GenerateDXF_NextHandle());	// Handle
			GenerateDXF_PushRecord(data, "100", "AcDbEntity");				// Subclass marker
			GenerateDXF_PushRecord(data, "62",	"0");						// Color number 
			GenerateDXF_PushRecord(data, "370", "-1");						// Lineweight
			GenerateDXF_PushRecord(data, "6",	"ByLayer");					// Linetype
			GenerateDXF_PushRecord(data, "1",	dimensionName);				// Dimension text
			GenerateDXF_PushRecord(data, "3",	"Standard");				// Dimension style name
			GenerateDXF_PushRecord(data, "70",	"0");						// Dimension type
			GenerateDXF_PushRecord(data, "71",	"5");						// Attachment point
			//GenerateDXF_PushRecord(data, "72",	"1");					// Dimension text line-spacing style
			//GenerateDXF_PushRecord(data, "41",	"1.0");					// Dimension text-line spacing factor
			GenerateDXF_PushRecord(data, "100",	"AcDbAlignedDimension");	// Subclass marker
			GenerateDXF_PushRecord(data, "13", 	minX);						// Definition point for linear and angular dimensions
			GenerateDXF_PushRecord(data, "23", 	minY);
			GenerateDXF_PushRecord(data, "33",	"0.0");
			GenerateDXF_PushRecord(data, "14",	maxX);						// Definition point for linear and angular dimensions
			GenerateDXF_PushRecord(data, "24",	maxY);
			GenerateDXF_PushRecord(data, "34",	"0.0");
			GenerateDXF_PushRecord(data, "50",	angle);						// Angle of rotated, horizontal, or vertical dimensions
			GenerateDXF_PushRecord(data, "100",	"AcDbRotatedDimension");	// Subclass marker (AcDbRotatedDimension)
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AppendQuadBezier_priv = function(data, startPt, endPt, controlPt)
	{
		/*
			Spline group codes
			Group codes	Description
			100		Subclass marker (AcDbSpline)
			210		Normal vector (omitted if the spline is nonplanar) DXF: X value; APP: 3D vector
			220, 230	DXF: Y and Z values of normal vector (optional)
			70		Spline flag (bit coded):
						1 = Closed spline
						2 = Periodic spline
						4 = Rational spline
						8 = Planar
						16 = Linear (planar bit is also set)
			71		Degree of the spline curve
			72		Number of knots
			73		Number of control points
			74		Number of fit points (if any)
			42		Knot tolerance (default = 0.0000001)
			43		Control-point tolerance (default = 0.0000001)
			44		Fit tolerance (default = 0.0000000001)
			12		Start tangent-may be omitted (in WCS), DXF: X value; APP: 3D point
			22, 32	DXF: Y and Z values of start tangent-may be omitted (in WCS)
			13		End tangent-may be omitted (in WCS), DXF: X value; APP: 3D point
			23, 33	DXF: Y and Z values of end tangent-may be omitted (in WCS)
			40		Knot value (one entry per knot)
			41		Weight (if not 1); with multiple group pairs, are present if all are not 1
			10		Control points (in WCS), one entry per control point, DXF: X value; APP: 3D point
			20, 30	DXF: Y and Z values of control points (in WCS), one entry per control point
			11		Fit points (in WCS), one entry per fit point, DXF: X value; APP: 3D point
			21, 31	DXF: Y and Z values of fit points (in WCS), one entry per fit point		*/
		
		// Spline
		GenerateDXF_PushRecord(data, "0",   "SPLINE");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "8",   "Layer_1");			// Layer name
		GenerateDXF_PushRecord(data, "62",  "0");				// Color number
		GenerateDXF_PushRecord(data, "5",   GenerateDXF_NextHandle());// Entity handle
		
		GenerateDXF_PushRecord(data, "100", "AcDbSpline"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "210",   "0");				// Normal vector
		GenerateDXF_PushRecord(data, "220",   "0");				// .. Y
		GenerateDXF_PushRecord(data, "230",   "0");				// .. Z
		GenerateDXF_PushRecord(data, "70",   8);				// Spline flag (8: planar)
		GenerateDXF_PushRecord(data, "71",   2);				// Degree of the spline curve
		GenerateDXF_PushRecord(data, "72",   6);				// Number of knots
		GenerateDXF_PushRecord(data, "73",   3);				// Number of control points
		GenerateDXF_PushRecord(data, "74",   0);				// Number of fit points (if any)
		GenerateDXF_PushRecord(data, "42",	"1e-07");			// Knot tolerance (default = 0.0000001)
		GenerateDXF_PushRecord(data, "43",	"1e-07");			// Control-point tolerance (default = 0.0000001)

		GenerateDXF_PushRecord(data, "40",   "0");				// Knots
		GenerateDXF_PushRecord(data, "40",   "0");				// Knots
		GenerateDXF_PushRecord(data, "40",   "0");				// Knots
		//GenerateDXF_PushRecord(data, "40",   "0.5");
		GenerateDXF_PushRecord(data, "40",   "1");
		GenerateDXF_PushRecord(data, "40",   "1");
		GenerateDXF_PushRecord(data, "40",   "1");

		GenerateDXF_PushRecord(data, "10",   startPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",   startPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "30",   "0");
		
		GenerateDXF_PushRecord(data, "10",   controlPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",   controlPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "30",   "0");

		GenerateDXF_PushRecord(data, "10",   endPt.x + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",   endPt.y + dxfOffset.y);
		GenerateDXF_PushRecord(data, "30",   "0");
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AddPolygonList_ArcCurves = function(polyList, cornerSize, tagMatch)
	{
		var data = [];
			
		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			if (polyList.PolygonTagMatches(p, tagMatch))
			{
				var simplePoly = polyList.GetPolygonPoints(p);
			
				if (simplePoly.length > 0)
				{
					var simpleData = [];
					var firstPt;
					var prevPt;
				
					for (var i = 0; i < simplePoly.length; i++)
					{
						var ptA = simplePoly[i];
						var ptB = simplePoly[(i + 1) % simplePoly.length];
						var ptC = simplePoly[(i + 2) % simplePoly.length];
						var renderCurve = true;
						var arcParams = undefined;
			
						if (ptB.omitCurve != undefined)
							renderCurve = false;
				
						if (renderCurve)
							arcParams = MathUtil.CalcTangentArcParams(ptA, ptB, ptC, cornerSize);
				
						if (arcParams != undefined)
						{
							if (i == 0)
								firstPt = arcParams.ptTangentAB;
							else
								GenerateDXF_AppendLine_priv(simpleData, prevPt, arcParams.ptTangentAB);
					
							// Add Arc
							var startAngle = (arcParams.ccw ? arcParams.endAngle : arcParams.startAngle) * 180 / Math.PI;
							var endAngle   = (arcParams.ccw ? arcParams.startAngle : arcParams.endAngle) * 180 / Math.PI;
							GenerateDXF_AppendArc_priv(simpleData, arcParams.center, arcParams.radius, startAngle, endAngle);
							prevPt = arcParams.ptTangentBC;
						}
						else
						{
							// If we failed to calculate arc parameters, add Move (to first point) or Line
							if (i == 0)
								firstPt = ptB;
							else
								GenerateDXF_AppendLine_priv(simpleData, prevPt, ptB);
							prevPt = ptB;
						}
					}
					GenerateDXF_AppendLine_priv(simpleData, prevPt, firstPt);

					data.push(simpleData);
				}
			}
		}
		
		
 		GenerateDXF_WriteSectionRecords(data);
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AddPolygonList_QuadCurves = function(polyList, cornerSize, tagMatch)
	{
		var data = [];
			
		for (var p = 0; p < polyList.GetPolygonCount(); p++)
		{
			if (polyList.PolygonTagMatches(p, tagMatch))
			{
				var simplePoly = polyList.GetPolygonPoints(p);

				if (simplePoly.length > 0)
				{
					var simpleData = [];
					var firstPt;
					var prevPt;
				
					for (var i = 0; i < simplePoly.length; i++)
					{
						// ABC describes a vertex in the polygon
						var ptA = simplePoly[i];
						var ptB = simplePoly[(i + 1) % simplePoly.length];
						var ptC = simplePoly[(i + 2) % simplePoly.length];
						var renderCurve = true;
						var quadParams = undefined;
			
						if (ptB.omitCurve != undefined)
							renderCurve = false;
				
						if (renderCurve)
							quadParams = MathUtil.CalcQuadBezierParams(ptA, ptB, ptC, cornerSize);
				
						// I'm not sure if it is possible for the Quad Bezier to have failure
						// conditions. For not, we always use it. If we discover problems, I can
						// add the condition here.
						if (quadParams != undefined)
						{
							if (i == 0)
								firstPt = quadParams.ptTangentAB;
							else
								GenerateDXF_AppendLine_priv(simpleData, prevPt, quadParams.ptTangentAB);

							// Quad Bezier
							GenerateDXF_AppendQuadBezier_priv(simpleData, quadParams.ptTangentAB, quadParams.ptTangentBC, ptB);
							prevPt = quadParams.ptTangentBC;
						}
						else
						{
							if (i == 0)
								firstPt = ptB;
							else
								GenerateDXF_AppendLine_priv(simpleData, prevPt, ptB);
							prevPt = ptB;
						}
					}
					GenerateDXF_AppendLine_priv(simpleData, prevPt, firstPt);

					data.push(simpleData);
				}
			}
		}
		
 		GenerateDXF_WriteSectionRecords(data);
	}
	
	//-----------------------------------------------------------------------------------
	//
	//	2020.11.07: Support alternative polySettings parameter
	//	2021.04.13: Remove support for cornerStyle and cornerSize params and only
	//	support polySettings param
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AddPolygonList = function(polyList, polySettings = undefined)
	{
		// 2020.11.07: Defaults
		var cornerSize = 0.0;
		var cornerStyle = VectorCornerStyle.ANGLE;
		var tagMatch = {isStroke:true}; // 2021.04.13: Was "undefined"
		
		if (polySettings != undefined)
		{
			if (polySettings.cornerStyle != undefined)
				cornerStyle = polySettings.cornerStyle;

			if (polySettings.cornerSize != undefined)
				cornerSize = polySettings.cornerSize;

			if (polySettings.tagMatch != undefined)
				tagMatch = polySettings.tagMatch;
		}
		
		// 2019.09.16: Render angles if the corner size is zero
		if (cornerStyle == VectorCornerStyle.ANGLE || cornerSize < 0.0001)
			GenerateDXF_AddPolygonList_Angles(polyList, tagMatch);
			
		else if (cornerStyle == VectorCornerStyle.ARC)
			GenerateDXF_AddPolygonList_ArcCurves(polyList, cornerSize, tagMatch);
			
		else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
			GenerateDXF_AddPolygonList_QuadCurves(polyList, cornerSize, tagMatch);
		
		else
			GenerateDXF_AddPolygonList_Angles(polyList, tagMatch);
	}


	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AppendCircle_prix = function(data, centerX, centerY, radius)
	{
		/*
			100			Subclass marker (AcDbCircle)
			39			Thickness (optional; default = 0)
			10			Center point (in OCS), DXF: X value; APP: 3D point
			20, 30		DXF: Y and Z values of center point (in OCS)
			40			Radius
			210			Extrusion direction (optional; default = 0, 0, 1), DXF: X value; APP: 3D vector
			220, 230	DXF: Y and Z values of extrusion direction  (optional)
		*/
		GenerateDXF_PushRecord(data, "0",   "CIRCLE");	
		GenerateDXF_PushRecord(data, "100", "AcDbEntity"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "8",   "Layer_1");			// Layer name
		GenerateDXF_PushRecord(data, "62",  "0");				// Color number
		GenerateDXF_PushRecord(data, "5",   GenerateDXF_NextHandle());// Entity handle
		GenerateDXF_PushRecord(data, "100", "AcDbCircle"); 		// Subclass marker
		GenerateDXF_PushRecord(data, "10",  centerX + dxfOffset.x);
		GenerateDXF_PushRecord(data, "20",  centerY + dxfOffset.y);
		GenerateDXF_PushRecord(data, "40",  radius);
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AddCircleList = function(circleList)
	{
		var data = [];
			
		for (var i = 0; i < circleList.length; i++)
		{
			var circleData = [];
			var h = circleList[i];
			GenerateDXF_AppendCircle_prix(circleData, h.x, h.y, h.r);
			
			data.push(circleData);
		}		

 		GenerateDXF_WriteSectionRecords(data);
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_AddNotchesList = function(notchesList)
	{
		var data = [];
	
		for (var p = 0; p < notchesList.length; p++)
		{
			var notch = notchesList[p];
			
			if (notch.length > 0)
			{
				var simpleData = [];
				GenerateDXF_PushRecord(simpleData, "0",   "LWPOLYLINE");	
				GenerateDXF_PushRecord(simpleData, "100", "AcDbEntity"); 		// Subclass marker
				GenerateDXF_PushRecord(simpleData, "8",   "Layer_1");			// Layer name
				GenerateDXF_PushRecord(simpleData, "62",  "0");					// Color number
				GenerateDXF_PushRecord(simpleData, "5",   GenerateDXF_NextHandle()); // Entity handle
				GenerateDXF_PushRecord(simpleData, "100", "AcDbPolyline"); 		// Subclass marker
				GenerateDXF_PushRecord(simpleData, "90",  notch.length);		// Number of vertices
				GenerateDXF_PushRecord(simpleData, "70",  "1"); 				// Polyline flag (default is 0; 1 = Closed; 128 = Plinegen)
				//GenerateDXF_PushRecord(simpleData, "43",  "1"); 				// Constant width
			
				for (var i = 0; i < notch.length; i++)
				{
					GenerateDXF_PushRecord(simpleData, "10", notch[i].x + dxfOffset.x);
					GenerateDXF_PushRecord(simpleData, "20", notch[i].y + dxfOffset.y);
				}
				
				data.push(simpleData);
			}			
		}
		
 		GenerateDXF_WriteSectionRecords(data);
	}
	
	//------------------------------------------------------------------------------------
	//	Add Gfx Path
	//		2020.06.17: Added
	//------------------------------------------------------------------------------------
	var GenerateDXF_AddGfxPath = function(gfxPath)
	{
		var data = [];
		
		var prevPt = undefined;
		for (var i = 0; gfxPath != undefined && i < gfxPath.GetOpCount(); i++)
		{
			let op = gfxPath.GetOp(i);
			let opData = [];
			
			if (op.op == GfxPath.Op.NOOP)
			{
			}
			else if (op.op == GfxPath.Op.MOVETO)
			{
				prevPt = op.pt;
			}
			else if (op.op == GfxPath.Op.LINETO)
			{
				if (prevPt != undefined)
				{
					GenerateDXF_AppendLine_priv(opData, prevPt, op.pt);
				}
				prevPt = op.pt;
			}
			else if (op.op == GfxPath.Op.ARC)
			{
				// Add Arc
				// In what dicretion should the arc go?
				GenerateDXF_AppendArc_priv(opData, op.pt.x, op.pt.y, op.r, op.start, op.end);
			}
			else if (op.op == GfxPath.Op.CLOSE)
			{
				// At the moment (2020.06.16), I don't know if this is supported in DXF
			}
			else if (op.op == GfxPath.Op.CIRCLE)
			{
				GenerateDXF_AppendCircle_prix(opData, op.pt.x, op.pt.y, op.r);
			}

			// Some ops do not generate entries			
			if (opData.length > 0)
				data.push(opData);
		}

		// Only write the data if our path had ops that generated dxf graphics
		if (data.length > 0)
	 		GenerateDXF_WriteSectionRecords(data);

	}
	
	//------------------------------------------------------------------------------------
	//	Add Dimensions
	//		2020.06.16: Added
	//------------------------------------------------------------------------------------
	var GenerateDXF_AddDimensions = function()
	{
		var data = [];
		
		var widthDimData = [];
		GenerateDXF_AppendDimension(widthDimData, "width",  {width:dxfViewSize.x});
		data.push(widthDimData);
		
		var heightDimData = [];
		GenerateDXF_AppendDimension(heightDimData, "height", {height:dxfViewSize.y});
		data.push(heightDimData);

 		GenerateDXF_WriteSectionRecords(data);
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_WriteDictionary = function()
	{
		var dictionaryList = [];
		
		var dictionary = [];
		GenerateDXF_PushRecord(dictionary, "0",		"DICTIONARY");			// Object name (DICTIONARY)
		GenerateDXF_PushRecord(dictionary, "5",		"C");					// Handle
		GenerateDXF_PushRecord(dictionary, "330",	"0");					// Soft-pointer ID/handle to owner object
		GenerateDXF_PushRecord(dictionary, "100",	"AcDbDictionary");		// Subclass marker (AcDbDictionary)
		//GenerateDXF_PushRecord(dictionary, "281", "1");					// Duplicate record cloning flag (determines how to merge duplicate entries):
		GenerateDXF_PushRecord(dictionary, "3",		"ACAD_GROUP");			// Entry name (one for each entry) (optional)
		GenerateDXF_PushRecord(dictionary, "350",	"D");					// Soft-owner ID/handle to entry object (one for each entry) (optional)
		GenerateDXF_PushRecord(dictionary, "3",		"ACAD_MLINESTYLE");		// Entry name (one for each entry) (optional)
		GenerateDXF_PushRecord(dictionary, "350",	"17");					// Soft-owner ID/handle to entry object (one for each entry) (optional)
		
		GenerateDXF_PushRecord(dictionary, "0",		"DICTIONARY");
		GenerateDXF_PushRecord(dictionary, "5",		"D");
		GenerateDXF_PushRecord(dictionary, "330",	"C");
		GenerateDXF_PushRecord(dictionary, "100",	"AcDbDictionary");
		//GenerateDXF_PushRecord(dictionary, "281",	"1");					// Duplicate record cloning flag (determines how to merge duplicate entries):
		
		GenerateDXF_PushRecord(dictionary, "0",		"DICTIONARY");
		GenerateDXF_PushRecord(dictionary, "5",		"1A");
		GenerateDXF_PushRecord(dictionary, "330",	"C");
		GenerateDXF_PushRecord(dictionary, "100",	"AcDbDictionary");
		
		GenerateDXF_PushRecord(dictionary, "0",		"DICTIONARY");
		GenerateDXF_PushRecord(dictionary, "5",		"17");
		GenerateDXF_PushRecord(dictionary, "330",	"C");
		GenerateDXF_PushRecord(dictionary, "100",	"AcDbDictionary");
		GenerateDXF_PushRecord(dictionary, "3",		"STANDARD");			// Entry name (one for each entry) (optional)
		GenerateDXF_PushRecord(dictionary, "350",	"18");
		
		GenerateDXF_PushRecord(dictionary, "0",		"DICTIONARY");
		GenerateDXF_PushRecord(dictionary, "5",		"19");
		GenerateDXF_PushRecord(dictionary, "330",	"C");
		GenerateDXF_PushRecord(dictionary, "100",	"AcDbDictionary");
		
		dictionaryList.push(dictionary);
		GenerateDXF_WriteSectionRecords(dictionaryList);
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_EmbedPrivateData = function(dataId, data)
	{
		/*
			Common object group codes
			Group codes	Description
			0		Object type
			5		Handle
			102		Start of application defined group "{application_name" (optional)
			[application-defined codes]: Codes and values within the 102 groups are application-defined (optional)
			102		End of group, "}" (optional)
			102		"{ACAD_REACTORS" indicates the start of the AutoCAD persistent reactors group. This group exists only if persistent reactors have been attached to this object (optional).
			330		Soft pointer ID/handle to owner dictionary (optional)
			102		End of group, "}" (optional)
			102		"{ACAD_XDICTIONARY" indicates the start of an extension dictionary group. This group exists only if persistent reactors have been attached to this object (optional).
			360		Hard owner ID/handle to owner dictionary (optional)
			102		End of group, "}" (optional)
			330		Soft-pointer ID/handle to owner object

			Xrecord group codes
			Group codes	Description
			100		Subclass marker (AcDbXrecord)
			280		Duplicate record cloning flag (DDK: Use only "0")
			[1-369 (except 5 and 105)] These values can be used by an application in any way.
		*/
		
		var objectList = [];
		
		var object = [];
		
		GenerateDXF_PushRecord(object, "0",  	"XRECORD");
		GenerateDXF_PushRecord(object, "5",		"FF");
		//GenerateDXF_PushRecord(object, "330",	"???");

		GenerateDXF_PushRecord(object, "100",	"AcDbXrecord");	
		GenerateDXF_PushRecord(object, "280",	"0");	

		var MAX_STRING_LEN = 100;
		
		for (var i = 0; i < data.length; i += MAX_STRING_LEN)
		{
			var l = ((i + MAX_STRING_LEN) < data.length) ? MAX_STRING_LEN : (data.length - MAX_STRING_LEN);
			GenerateDXF_PushRecord(object, "1", dataId + data.substr(i, l));	
		}
	
		objectList.push(object);

		GenerateDXF_WriteSectionRecords(objectList);
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_WriteFileTrailer = function()
	{
		dxfData += dxfPrefix + "0" + dxfNewLine + "EOF" + dxfNewLine;
	}	

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_Comments = function()
	{
		dxfData += dxfPrefix + "999" + dxfNewLine;
		dxfData += "DXF created at https://polygonia.design" + dxfNewLine;
	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_WriteSectionHeader = function(sectionName)
	{
		dxfData += dxfPrefix + "0" + dxfNewLine + "SECTION" + dxfNewLine;
		dxfData += dxfPrefix + "2" + dxfNewLine + sectionName + dxfNewLine;
	}
	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_WriteSectionTrailer = function()
	{
		dxfData += dxfPrefix + "0" + dxfNewLine + "ENDSEC" + dxfNewLine;
	}	

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_WriteSectionRecords = function(recordLists)
	{
		for (var i = 0; i < recordLists.length; i++)
		{
			var list = recordLists[i];
			
			for (var j = 0; j < list.length; j++)
				dxfData += list[j] + dxfNewLine;
		}
	}	


	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_WriteSection = function(sectionName, recordLists)
 	{
 		GenerateDXF_WriteSectionHeader(sectionName);
		
		GenerateDXF_WriteSectionRecords(recordLists);

		GenerateDXF_WriteSectionTrailer();
 	}

	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	var GenerateDXF_Header = function()
	{
		var hdr = [];
		
		GenerateDXF_PushRecord(hdr, "9",   "$ACADVER");				// Variable name: 
		GenerateDXF_PushRecord(hdr, "1",   "AC1014");				//   Version

		GenerateDXF_PushRecord(hdr, "9",   "$HANDSEED");			// Variable name: 
		GenerateDXF_PushRecord(hdr, "5",   "FFFF");					//   Handle seed 

		GenerateDXF_PushRecord(hdr, "9",   "$MEASUREMENT");			// Variable name: 
		GenerateDXF_PushRecord(hdr, "70",  "1");					//   $MEASUREMENT: Sets drawing units: 0 = English; 1 = Metric

		//GenerateDXF_PushRecord(hdr, "9",   "$EXTMIN");			// Variable name: 
		//GenerateDXF_PushRecord(hdr, "10",  "-10.0");				//   X mininum Extent
		//GenerateDXF_PushRecord(hdr, "20",  "-10.0");				//   Y mininum Extent
		
		//GenerateDXF_PushRecord(hdr, "9",   "$EXTMAX");			// Variable name: 
		//GenerateDXF_PushRecord(hdr, "10",  "20.0");				//   X maximum Extent
		//GenerateDXF_PushRecord(hdr, "20",  "20.0");				//   Y mininum Extent

		var unitsStr = "0";
		if (dxfUnits == BasicUnits.INCHES)
			unitsStr = "1";
		if (dxfUnits == BasicUnits.MILLIMETERS)
			unitsStr = "4";

		GenerateDXF_PushRecord(hdr, "9",   "$INSUNITS");			// Variable name: 
		GenerateDXF_PushRecord(hdr, "70",  unitsStr);				//   Units
		
		var hdrList = [hdr];
		
		GenerateDXF_WriteSection("HEADER", hdrList);
	}
 
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_Tables = function()
 	{
 		var viewCenterPoint = {x:(dxfViewSize.x/2).toFixed(3), y:(dxfViewSize.y/2).toFixed(3)};
 		var viewHeight = dxfViewSize.y;
 		var viewAspectRatio = (dxfViewSize.y/dxfViewSize.x).toFixed(3);
 		
 		var vportTable = [];
  		GenerateDXF_PushRecord(vportTable, "0",   "TABLE");						// Object type
 		GenerateDXF_PushRecord(vportTable, "2",   "VPORT");						// Table name
 		GenerateDXF_PushRecord(vportTable, "5",   "8");							// Handle
 		GenerateDXF_PushRecord(vportTable, "330", "0");							// Soft-pointer ID/handle to owner object
 		GenerateDXF_PushRecord(vportTable, "100", "AcDbSymbolTable");			// Subclass marker
  		GenerateDXF_PushRecord(vportTable, "70",  "4");							// Maximum number of entries
		
 		GenerateDXF_PushRecord(vportTable, "0",   "VPORT");						// 
 		GenerateDXF_PushRecord(vportTable, "5",   "2E");						// Handle
 		GenerateDXF_PushRecord(vportTable, "330", "8");							// Soft-pointer ID/handle to owner object
 		GenerateDXF_PushRecord(vportTable, "100", "AcDbSymbolTableRecord");		// Subclass marker

 		GenerateDXF_PushRecord(vportTable, "100", "AcDbViewportTableRecord");	// Subclass marker
 		GenerateDXF_PushRecord(vportTable, "2",   "*ACTIVE");					// Viewport name
 		GenerateDXF_PushRecord(vportTable, "70",  "0");							// Standard flag values
 		GenerateDXF_PushRecord(vportTable, "10",  "0.0");						// Lower-left corner of viewport
 		GenerateDXF_PushRecord(vportTable, "20",  "0.0");
 		GenerateDXF_PushRecord(vportTable, "11",  "1.0");						// Upper-right corner of viewport
 		GenerateDXF_PushRecord(vportTable, "21",  "1.0");
 		GenerateDXF_PushRecord(vportTable, "12",  viewCenterPoint.x);			// View center point (in DCS)
 		GenerateDXF_PushRecord(vportTable, "22",  viewCenterPoint.y);
 		GenerateDXF_PushRecord(vportTable, "13",  "0.0");						// Snap base point
 		GenerateDXF_PushRecord(vportTable, "23",  "0.0");
 		GenerateDXF_PushRecord(vportTable, "14",  "10.0");						// Snap spacing X and Y
 		GenerateDXF_PushRecord(vportTable, "24",  "10.0");
 		GenerateDXF_PushRecord(vportTable, "15",  "10.0");						// Grid spacing X and Y
 		GenerateDXF_PushRecord(vportTable, "25",  "10.0");
 		GenerateDXF_PushRecord(vportTable, "16",  "0.0");						// View direction from target point
 		GenerateDXF_PushRecord(vportTable, "26",  "0.0");
 		GenerateDXF_PushRecord(vportTable, "36",  "1.0");
 		GenerateDXF_PushRecord(vportTable, "17",  "0.0");						// View target point
 		GenerateDXF_PushRecord(vportTable, "27",  "0.0");
 		GenerateDXF_PushRecord(vportTable, "37",  "0.0");
 		GenerateDXF_PushRecord(vportTable, "40",  viewHeight);					// View height
 		GenerateDXF_PushRecord(vportTable, "41",  viewAspectRatio);				// View aspect ratio
 		GenerateDXF_PushRecord(vportTable, "42",  "50.0");						// Lens length
 		GenerateDXF_PushRecord(vportTable, "43",  "0.0");						// Front clipping plane
 		GenerateDXF_PushRecord(vportTable, "44",  "0.0");						// Back clipping plane
 		GenerateDXF_PushRecord(vportTable, "50",  "0.0");						// Snap rotation angle
 		GenerateDXF_PushRecord(vportTable, "51",  "0.0");						// View twist angle
 		GenerateDXF_PushRecord(vportTable, "71",  "0");							// View mode (see VIEWMODE system variable)
 		GenerateDXF_PushRecord(vportTable, "72",  "100");						// Circle zoom percent
 		GenerateDXF_PushRecord(vportTable, "73",  "1");							// Fast zoom setting
 		GenerateDXF_PushRecord(vportTable, "74",  "3");							// UCSICON setting
 		GenerateDXF_PushRecord(vportTable, "75",  "0");							// Snap on/off
 		GenerateDXF_PushRecord(vportTable, "76",  "0");							// Grid on/off
 		GenerateDXF_PushRecord(vportTable, "77",  "0");							// Snap style
 		GenerateDXF_PushRecord(vportTable, "78",  "0");							// Snap isopair

 		GenerateDXF_PushRecord(vportTable, "0",   "ENDTAB");
		
 		var ltypeTable = [];
 		GenerateDXF_PushRecord(ltypeTable, "0",		"TABLE");					// Object type (TABLE)
		GenerateDXF_PushRecord(ltypeTable, "2",		"LTYPE");					// Table name
		GenerateDXF_PushRecord(ltypeTable, "5",		"5");						// Handle
		GenerateDXF_PushRecord(ltypeTable, "330",	"0");						// Soft-pointer ID/handle to owner object
		GenerateDXF_PushRecord(ltypeTable, "100",	"AcDbSymbolTable");			// Subclass marker (AcDbSymbolTable)
		GenerateDXF_PushRecord(ltypeTable, "70",	"1");						// Maximum number of entries in table
		
		GenerateDXF_PushRecord(ltypeTable, "0",		"LTYPE");					// Entity type (table name)
		GenerateDXF_PushRecord(ltypeTable, "5",		"14");						// Handle (all except DIMSTYLE)
		GenerateDXF_PushRecord(ltypeTable, "330",	"5");						// Soft pointer ID/handle to owner dictionary (optional)
		GenerateDXF_PushRecord(ltypeTable, "100",	"AcDbSymbolTableRecord");	// Subclass marker (AcDbSymbolTableRecord)
		GenerateDXF_PushRecord(ltypeTable, "100",	"AcDbLinetypeTableRecord");	// Subclass marker (AcDbLinetypeTableRecord)
		GenerateDXF_PushRecord(ltypeTable, "2",		"BYBLOCK");					// Linetype name
		GenerateDXF_PushRecord(ltypeTable, "70",	"0");						// Standard flag values (bit-coded values)
		GenerateDXF_PushRecord(ltypeTable, "3",		"");						// Descriptive text for linetype
		GenerateDXF_PushRecord(ltypeTable, "72",	"65");						// Alignment code; value is always 65, the ASCII code for A
		GenerateDXF_PushRecord(ltypeTable, "73",	"0");						// The number of linetype elements
		GenerateDXF_PushRecord(ltypeTable, "40",	"0.0");						// Total pattern length

		GenerateDXF_PushRecord(ltypeTable, "0",		"LTYPE");					// Entity type (table name)
		GenerateDXF_PushRecord(ltypeTable, "5",		"15");						// Handle (all except DIMSTYLE)
		GenerateDXF_PushRecord(ltypeTable, "330",	"5");						// Soft pointer ID/handle to owner dictionary (optional)
		GenerateDXF_PushRecord(ltypeTable, "100",	"AcDbSymbolTableRecord");	// Subclass marker (AcDbSymbolTableRecord)
		GenerateDXF_PushRecord(ltypeTable, "100",	"AcDbLinetypeTableRecord");	// Subclass marker (AcDbLinetypeTableRecord)
		GenerateDXF_PushRecord(ltypeTable, "2",		"BYLAYER");					// Linetype name
		GenerateDXF_PushRecord(ltypeTable, "70",	"0");						// Standard flag values (bit-coded values)
		GenerateDXF_PushRecord(ltypeTable, "3",		"");						// Descriptive text for linetype
		GenerateDXF_PushRecord(ltypeTable, "72",	"65");						// Alignment code; value is always 65, the ASCII code for A
		GenerateDXF_PushRecord(ltypeTable, "73",	"0");						// The number of linetype elements
		GenerateDXF_PushRecord(ltypeTable, "40",	"0.0");						// Total pattern length

		GenerateDXF_PushRecord(ltypeTable, "0",		"LTYPE");					// Entity type (table name)
		GenerateDXF_PushRecord(ltypeTable, "5",		"16");						// Handle (all except DIMSTYLE)
		GenerateDXF_PushRecord(ltypeTable, "330",	"5");						// Soft pointer ID/handle to owner dictionary (optional)
		GenerateDXF_PushRecord(ltypeTable, "100",	"AcDbSymbolTableRecord");	// Subclass marker (AcDbSymbolTableRecord)
		GenerateDXF_PushRecord(ltypeTable, "100",	"AcDbLinetypeTableRecord");	// Subclass marker (AcDbLinetypeTableRecord)
		GenerateDXF_PushRecord(ltypeTable, "2",		"CONTINUOUS");				// Linetype name
		GenerateDXF_PushRecord(ltypeTable, "70",	"0");						// Standard flag values (bit-coded values)
		GenerateDXF_PushRecord(ltypeTable, "3",		"Solid line");				// Descriptive text for linetype
		GenerateDXF_PushRecord(ltypeTable, "72",	"65");						// Alignment code; value is always 65, the ASCII code for A
		GenerateDXF_PushRecord(ltypeTable, "73",	"0");						// The number of linetype elements
		GenerateDXF_PushRecord(ltypeTable, "40",	"0.0");						// Total pattern length
		 
		GenerateDXF_PushRecord(ltypeTable, "0",		"ENDTAB");					// 
 		
 		var styleTable = [];
 		GenerateDXF_PushRecord(styleTable, "0",		"TABLE");
 		GenerateDXF_PushRecord(styleTable, "2",		"STYLE");
 		GenerateDXF_PushRecord(styleTable, "5",		"3");
 		GenerateDXF_PushRecord(styleTable, "330",	"0");
 		GenerateDXF_PushRecord(styleTable, "100",	"AcDbSymbolTable");
 		GenerateDXF_PushRecord(styleTable, "70",	"1");
 		GenerateDXF_PushRecord(styleTable, "0",		"STYLE");
 		GenerateDXF_PushRecord(styleTable, "5",		"11");
 		GenerateDXF_PushRecord(styleTable, "330",	"3");
 		GenerateDXF_PushRecord(styleTable, "100",	"AcDbSymbolTableRecord");
 		GenerateDXF_PushRecord(styleTable, "100",	"AcDbTextStyleTableRecord");
 		GenerateDXF_PushRecord(styleTable, "2",		"STANDARD");
 		GenerateDXF_PushRecord(styleTable, "70",	"0");
 		GenerateDXF_PushRecord(styleTable, "40",	"0.0");
 		GenerateDXF_PushRecord(styleTable, "41",	"1.0");
 		GenerateDXF_PushRecord(styleTable, "50",	"0.0");
 		GenerateDXF_PushRecord(styleTable, "71",	"0");
 		GenerateDXF_PushRecord(styleTable, "42",	"2.5");
 		GenerateDXF_PushRecord(styleTable, "3",		"txt");
 		GenerateDXF_PushRecord(styleTable, "4",		"");
		GenerateDXF_PushRecord(styleTable, "0",		"ENDTAB");
 		
 		var viewTable = [];
 		GenerateDXF_PushRecord(viewTable, "0",   "TABLE");				// Object type
 		GenerateDXF_PushRecord(viewTable, "2",   "VIEW");				// Table name
 		GenerateDXF_PushRecord(viewTable, "5",   "6");					// Handle
 		GenerateDXF_PushRecord(viewTable, "330", "0");					// Soft-pointer ID/handle to owner object
 		GenerateDXF_PushRecord(viewTable, "100", "AcDbSymbolTable");	// Subclass marker
 		GenerateDXF_PushRecord(viewTable, "70",  "0");					// Maximum number of entries
 		GenerateDXF_PushRecord(viewTable, "0",   "ENDTAB");
 		
 		var ucsTable = [];
 		GenerateDXF_PushRecord(ucsTable, "0",   "TABLE");				// Object type
 		GenerateDXF_PushRecord(ucsTable, "2",   "UCS");					// Table name
 		GenerateDXF_PushRecord(ucsTable, "5",   "7");					// Handle
 		GenerateDXF_PushRecord(ucsTable, "330", "0");					// Soft-pointer ID/handle to owner object
 		GenerateDXF_PushRecord(ucsTable, "100", "AcDbSymbolTable");		// Subclass marker
 		GenerateDXF_PushRecord(ucsTable, "70",  "0");					// Maximum number of entries
 		GenerateDXF_PushRecord(ucsTable, "0",   "ENDTAB");
 		
 		var appidTable = [];
 		GenerateDXF_PushRecord(appidTable, "0",   "TABLE");				// Object type
 		GenerateDXF_PushRecord(appidTable, "2",   "APPID");				// Table name
 		GenerateDXF_PushRecord(appidTable, "5",   "9");					// Handle
 		GenerateDXF_PushRecord(appidTable, "330", "0");					// Soft-pointer ID/handle to owner object
 		GenerateDXF_PushRecord(appidTable, "100", "AcDbSymbolTable");	// Subclass marker
 		GenerateDXF_PushRecord(appidTable, "70",  "2");					// Maximum number of entries
 		
 		GenerateDXF_PushRecord(appidTable, "0",   "APPID");
 		GenerateDXF_PushRecord(appidTable, "5",   "12");					// Handle
 		GenerateDXF_PushRecord(appidTable, "330", "9");						// Soft-pointer ID/handle to owner object
 		GenerateDXF_PushRecord(appidTable, "100", "AcDbSymbolTableRecord");	// Subclass marker
	 	GenerateDXF_PushRecord(appidTable, "100", "AcDbRegAppTableRecord");	// Subclass marker
 		GenerateDXF_PushRecord(appidTable, "2",   "ACAD");					// Name
 		GenerateDXF_PushRecord(appidTable, "70",  "0");						// Maximum number of entries
 		GenerateDXF_PushRecord(appidTable, "0",   "ENDTAB");

 		var dimstyleTable = [];
		GenerateDXF_PushRecord(dimstyleTable, "0", "TABLE"); 
		GenerateDXF_PushRecord(dimstyleTable, "2", "DIMSTYLE"); 
		GenerateDXF_PushRecord(dimstyleTable, "5", "A"); 
		GenerateDXF_PushRecord(dimstyleTable, "330", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "100", "AcDbSymbolTable"); 
		GenerateDXF_PushRecord(dimstyleTable, "70", "1"); 
		GenerateDXF_PushRecord(dimstyleTable, "0", "DIMSTYLE"); 
		GenerateDXF_PushRecord(dimstyleTable, "105", "27"); 
		GenerateDXF_PushRecord(dimstyleTable, "330", "A"); 
		GenerateDXF_PushRecord(dimstyleTable, "100", "AcDbSymbolTableRecord"); 
		GenerateDXF_PushRecord(dimstyleTable, "100", "AcDbDimStyleTableRecord"); 
		GenerateDXF_PushRecord(dimstyleTable, "2", "ISO-25"); 
		GenerateDXF_PushRecord(dimstyleTable, "70", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "3", ""); 
		GenerateDXF_PushRecord(dimstyleTable, "4", ""); 
		GenerateDXF_PushRecord(dimstyleTable, "5", ""); 
		GenerateDXF_PushRecord(dimstyleTable, "6", ""); 
		GenerateDXF_PushRecord(dimstyleTable, "7", ""); 
		GenerateDXF_PushRecord(dimstyleTable, "40", "1.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "41", "2.5"); 
		GenerateDXF_PushRecord(dimstyleTable, "42", "0.625"); 
		GenerateDXF_PushRecord(dimstyleTable, "43", "3.75"); 
		GenerateDXF_PushRecord(dimstyleTable, "44", "1.25"); 
		GenerateDXF_PushRecord(dimstyleTable, "45", "0.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "46", "0.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "47", "0.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "48", "0.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "140", "2.5"); 
		GenerateDXF_PushRecord(dimstyleTable, "141", "2.5"); 
		GenerateDXF_PushRecord(dimstyleTable, "142", "0.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "143", "0.03937007874016"); 
		GenerateDXF_PushRecord(dimstyleTable, "144", "1.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "145", "0.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "146", "1.0"); 
		GenerateDXF_PushRecord(dimstyleTable, "147", "0.625"); 
		GenerateDXF_PushRecord(dimstyleTable, "71", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "72", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "73", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "74", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "75", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "76", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "77", "1"); 
		GenerateDXF_PushRecord(dimstyleTable, "78", "8"); 
		GenerateDXF_PushRecord(dimstyleTable, "170", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "171", "3"); 
		GenerateDXF_PushRecord(dimstyleTable, "172", "1"); 
		GenerateDXF_PushRecord(dimstyleTable, "173", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "174", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "175", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "176", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "177", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "178", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "270", "2"); 
		GenerateDXF_PushRecord(dimstyleTable, "271", "2"); 
		GenerateDXF_PushRecord(dimstyleTable, "272", "2"); 
		GenerateDXF_PushRecord(dimstyleTable, "273", "2"); 
		GenerateDXF_PushRecord(dimstyleTable, "274", "3"); 
		GenerateDXF_PushRecord(dimstyleTable, "340", "11"); 
		GenerateDXF_PushRecord(dimstyleTable, "275", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "280", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "281", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "282", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "283", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "284", "8"); 
		GenerateDXF_PushRecord(dimstyleTable, "285", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "286", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "287", "3"); 
		GenerateDXF_PushRecord(dimstyleTable, "288", "0"); 
		GenerateDXF_PushRecord(dimstyleTable, "0", "ENDTAB");
 		
 		var blockrecordTable = [];
		GenerateDXF_PushRecord(blockrecordTable, "0",	"TABLE");
		GenerateDXF_PushRecord(blockrecordTable, "2",	"BLOCK_RECORD");
		GenerateDXF_PushRecord(blockrecordTable, "5",	"1");
		GenerateDXF_PushRecord(blockrecordTable, "330",	"0");
		GenerateDXF_PushRecord(blockrecordTable, "100",	"AcDbSymbolTable");
		GenerateDXF_PushRecord(blockrecordTable, "70",	"1");
		GenerateDXF_PushRecord(blockrecordTable, "0",	"BLOCK_RECORD");
		GenerateDXF_PushRecord(blockrecordTable, "5",	"1F");
		GenerateDXF_PushRecord(blockrecordTable, "330",	"1");
		GenerateDXF_PushRecord(blockrecordTable, "100",	"AcDbSymbolTableRecord");
		GenerateDXF_PushRecord(blockrecordTable, "100",	"AcDbBlockTableRecord");
		GenerateDXF_PushRecord(blockrecordTable, "2",	"*MODEL_SPACE");
		GenerateDXF_PushRecord(blockrecordTable, "0",	"BLOCK_RECORD");
		GenerateDXF_PushRecord(blockrecordTable, "5",	"1B");
		GenerateDXF_PushRecord(blockrecordTable, "330",	"1");
		GenerateDXF_PushRecord(blockrecordTable, "100",	"AcDbSymbolTableRecord");
		GenerateDXF_PushRecord(blockrecordTable, "100",	"AcDbBlockTableRecord");
		GenerateDXF_PushRecord(blockrecordTable, "2",	"*PAPER_SPACE");
		GenerateDXF_PushRecord(blockrecordTable, "0",	"ENDTAB");
 		
 		var layerTable = [];
 		GenerateDXF_PushRecord(layerTable, "0",		"TABLE");					// Object type (TABLE)
 		GenerateDXF_PushRecord(layerTable, "2",		"LAYER");					// Table name
 		GenerateDXF_PushRecord(layerTable, "5",		"2");						// Handle
 		//GenerateDXF_PushRecord(layerTable, "330", "0");						// Soft-pointer ID/handle to owner object
 		GenerateDXF_PushRecord(layerTable, "100",	"AcDbSymbolTable");			// Subclass marker (AcDbSymbolTable)
 		GenerateDXF_PushRecord(layerTable, "70",	"3");						// Maximum number of entries in table
 																				//	2020.06.16: Increased from '2' to '3'
 		
 		GenerateDXF_PushRecord(layerTable, "0",		"LAYER");					// Table type
 		GenerateDXF_PushRecord(layerTable, "5",		"50");						// Handle
 		//GenerateDXF_PushRecord(layerTable, "330", "2"); 
 		GenerateDXF_PushRecord(layerTable, "100",	"AcDbSymbolTableRecord");	// (table data follows)

 		GenerateDXF_PushRecord(layerTable, "100",	"AcDbLayerTableRecord");	// Subclass marker (AcDbLayerTableRecord)
 		GenerateDXF_PushRecord(layerTable, "2",		"0");						// Layer name
 		GenerateDXF_PushRecord(layerTable, "70",	"0");						// Standard flags (bit-coded values) 
 		GenerateDXF_PushRecord(layerTable, "6",		"CONTINUOUS");				// Linetype name
 		
  		GenerateDXF_PushRecord(layerTable, "0",		"LAYER");					// Table type
 		GenerateDXF_PushRecord(layerTable, "5",		"51");						// Handle
 		GenerateDXF_PushRecord(layerTable, "100",	"AcDbSymbolTableRecord");	// (table data follows)

 		GenerateDXF_PushRecord(layerTable, "100",	"AcDbLayerTableRecord");	// Subclass marker (AcDbLayerTableRecord)
 		GenerateDXF_PushRecord(layerTable, "2",		"Layer_1");					// Layer name
 		GenerateDXF_PushRecord(layerTable, "70",	"0");						// Standard flags (bit-coded values) 
 		//GenerateDXF_PushRecord(layerTable, "62",	"7");						// Color number (if negative, layer is off)
 		GenerateDXF_PushRecord(layerTable, "6",		"CONTINUOUS");				// Linetype name
 		
 		// 2020.06.16: Added "Dimensions" table
  		GenerateDXF_PushRecord(layerTable, "0",		"LAYER");					// Table type
 		GenerateDXF_PushRecord(layerTable, "5",		"52");						// Handle
 		GenerateDXF_PushRecord(layerTable, "100",	"AcDbSymbolTableRecord");	// (table data follows)

 		GenerateDXF_PushRecord(layerTable, "100",	"AcDbLayerTableRecord");	// Subclass marker (AcDbLayerTableRecord)
 		GenerateDXF_PushRecord(layerTable, "2",		"Dimensions");				// Layer name
 		GenerateDXF_PushRecord(layerTable, "70",	"0");						// Standard flags (bit-coded values) 
 		//GenerateDXF_PushRecord(layerTable, "62",	"7");						// Color number (if negative, layer is off)
 		GenerateDXF_PushRecord(layerTable, "6",		"CONTINUOUS");				// Linetype name
 		
 		GenerateDXF_PushRecord(layerTable, "0",		"ENDTAB");


 		var tableList = []
 	 	tableList.push(vportTable);
 		tableList.push(ltypeTable);
 		tableList.push(layerTable);
 		tableList.push(styleTable);
 		tableList.push(viewTable);
 		tableList.push(ucsTable);
 		tableList.push(appidTable);
 		tableList.push(dimstyleTable);
 		tableList.push(blockrecordTable);

 		GenerateDXF_WriteSection("TABLES", tableList);

 	}
 	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_Blocks = function()
 	{
 		var modelSpaceBlock = [];
 		GenerateDXF_PushRecord(modelSpaceBlock, "0", "BLOCK");
 		GenerateDXF_PushRecord(modelSpaceBlock, "5", "20");
 		GenerateDXF_PushRecord(modelSpaceBlock, "330", "1F");
 		GenerateDXF_PushRecord(modelSpaceBlock, "100", "AcDbEntity");
 		GenerateDXF_PushRecord(modelSpaceBlock, "8", "0");
 		GenerateDXF_PushRecord(modelSpaceBlock, "100", "AcDbBlockBegin");
 		GenerateDXF_PushRecord(modelSpaceBlock, "2", "*MODEL_SPACE");
 		GenerateDXF_PushRecord(modelSpaceBlock, "70", "0");
 		GenerateDXF_PushRecord(modelSpaceBlock, "10", "0.0");
 		GenerateDXF_PushRecord(modelSpaceBlock, "20", "0.0");
 		GenerateDXF_PushRecord(modelSpaceBlock, "30", "0.0");
 		GenerateDXF_PushRecord(modelSpaceBlock, "3", "*MODEL_SPACE");
 		GenerateDXF_PushRecord(modelSpaceBlock, "1", "");
 		GenerateDXF_PushRecord(modelSpaceBlock, "0", "ENDBLK");
 		GenerateDXF_PushRecord(modelSpaceBlock, "5", "21");
 		GenerateDXF_PushRecord(modelSpaceBlock, "330", "1F");
 		GenerateDXF_PushRecord(modelSpaceBlock, "100", "AcDbEntity");
 		GenerateDXF_PushRecord(modelSpaceBlock, "8", "0");
 		GenerateDXF_PushRecord(modelSpaceBlock, "100", "AcDbBlockEnd");
 		
 			
 		var paperSpaceBlock = [];
		GenerateDXF_PushRecord(paperSpaceBlock, "0", "BLOCK");
		GenerateDXF_PushRecord(paperSpaceBlock, "5", "1C");
		GenerateDXF_PushRecord(paperSpaceBlock, "330", "1B");
		GenerateDXF_PushRecord(paperSpaceBlock, "100", "AcDbEntity");
		GenerateDXF_PushRecord(paperSpaceBlock, "67", "1");
		GenerateDXF_PushRecord(paperSpaceBlock, "8", "0");
		GenerateDXF_PushRecord(paperSpaceBlock, "100", "AcDbBlockBegin");
		GenerateDXF_PushRecord(paperSpaceBlock, "2", "*PAPER_SPACE");
		GenerateDXF_PushRecord(paperSpaceBlock, "1", "");
		GenerateDXF_PushRecord(paperSpaceBlock, "0", "ENDBLK");
		GenerateDXF_PushRecord(paperSpaceBlock, "5", "1D");
		GenerateDXF_PushRecord(paperSpaceBlock, "330", "1B");
		GenerateDXF_PushRecord(paperSpaceBlock, "100", "AcDbEntity");
		GenerateDXF_PushRecord(paperSpaceBlock, "67", "1");
		GenerateDXF_PushRecord(paperSpaceBlock, "8", "0");
		GenerateDXF_PushRecord(paperSpaceBlock, "100", "AcDbBlockEnd");
 		
 		var blockList = [];
 		blockList.push(modelSpaceBlock);
 		blockList.push(paperSpaceBlock);
 		
 		GenerateDXF_WriteSection("BLOCKS", blockList);
 	}
 	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_StartEntities = function()
 	{
 		GenerateDXF_WriteSectionHeader("ENTITIES");
 	}
 	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_FinishEntities = function()
 	{
 		GenerateDXF_WriteSectionTrailer();
 	}
 	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_StartObjects = function()
 	{
 		GenerateDXF_WriteSectionHeader("OBJECTS");
 	}
 	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
 	var GenerateDXF_FinishObjects = function()
 	{
 		GenerateDXF_WriteSectionTrailer();
 	}
 	
 	
	//-----------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------
	return {
		Start:					GenerateDXF_Start,
		SetOffset:				GenerateDXF_SetOffset,
		Finish:					GenerateDXF_Finish,
		EmbedPrivateData:		GenerateDXF_EmbedPrivateData,
		StartEntities:			GenerateDXF_StartEntities,
		FinishEntities:			GenerateDXF_FinishEntities,
		StartObjects:			GenerateDXF_StartObjects,
		FinishObjects:			GenerateDXF_FinishObjects,
		WriteDictionary:		GenerateDXF_WriteDictionary,
		AddPolygonList:			GenerateDXF_AddPolygonList,
		AddCircleList:			GenerateDXF_AddCircleList,
		AddNotchesList:			GenerateDXF_AddNotchesList,
		AddDimensions:			GenerateDXF_AddDimensions,
		AddGfxPath:				GenerateDXF_AddGfxPath
	};
}());

export { GenerateDXF, GenerateSVG };