//==========================================================================================
//
//	Help Manager
//
//------------------------------------------------------------------------------------------
//
//==========================================================================================

//------------------------------------------------------------------------------
//	HelpMgr Elements
//
//	This list is describes the elements to add to the DOM
//------------------------------------------------------------------------------
const HelpElements =
	{ tag:"div", className:"CL_HelpBackground", tabIndex:"0", children:[
			{ tag:"div", id:"ID_PolygoniaHelp", innerHTML:"Getting Started..."  },
			{ tag:"div", id:"ID_MainHelpText", innerHTML:"help text goes here..."  },
			{ tag:"div", className:"CL_HelpButtons", children:[
					{ tag:"button", id:"ID_SkipHelp", className:"CL_HelpButton", innerHTML:"Done" },
					{ tag:"button", id:"ID_PrevHelp", className:"CL_HelpButton", innerHTML:"Prev" },	
					{ tag:"button", id:"ID_NextHelp", className:"CL_HelpButton", innerHTML:"Next" }
				]
			},	
			{ tag:"div", id:"ID_HelpHighlighter"  },
		]
	};
	
//------------------------------------------------------------------------------
//	HelpMgr Constructor
//
//------------------------------------------------------------------------------
function HelpMgr(callback)
{
	this.helpBackground = undefined;
	this.helpButtons = undefined;
	this.helpCallback = callback;
	this.helpPageNumber = 0;
	this.helpData = {};
	this.helpHighlighter = undefined;
}

/*
HelpMgr.prototype.XXXX = function()
{
}
*/

//------------------------------------------------------------------------------
//	Create Elements
//
//	Creates the DOM element (and any children) in the elementDesc parameter.
//	This is called recursively to add the children elements.
//------------------------------------------------------------------------------
HelpMgr.prototype.CreateElements = function(elementDesc, parentElement = undefined)
{
	var element = document.createElement(elementDesc.tag); 
	
	// Set the element attributes of all predetermined attributes
	const elementPropList = ["id", "className", "tabIndex", "innerHTML"];
	for (var i = 0; i < elementPropList.length; i++)
		if (elementDesc[elementPropList[i]] != undefined)
			element[elementPropList[i]] = elementDesc[elementPropList[i]];

	// If the element has any children, then handle those recursively
	if (elementDesc.children != undefined)
		for (var i = 0; i < elementDesc.children.length; i++)
			this.CreateElements(elementDesc.children[i], element)
	
	// Attached handlers as needed
	if (elementDesc.tag == "button")
		element.addEventListener("click", (evt) => this.HandleButton(evt), false);
		
	// If we have a parent, then append the element
	if (parentElement != undefined)
		parentElement.appendChild(element);
	
	return element;
}

//------------------------------------------------------------------------------
//	Add Elements
//
//	Add the elements to the DOM
//------------------------------------------------------------------------------
HelpMgr.prototype.AddElements = function()
{

	if (this.helpBackground != undefined) // Should be an assert
	{
		console.log("Calling HelpMgr.prototype.AddElements when elements already defined!!");
		return;
	}
	
	this.helpBackground = this.CreateElements(HelpElements, undefined);
	this.helpBackground.addEventListener("keydown", (evt) => this.HandleKeypress(evt), false);
	this.helpBackground.addEventListener("mousedown", (evt) => this.HandleMousedown(evt), false);
	
	var bodies = document.getElementsByTagName("body");
	if (bodies.length > 0)
		bodies[0].appendChild(this.helpBackground);

	this.helpHighlighter = document.getElementById("ID_HelpHighlighter");
}

//------------------------------------------------------------------------------
//	Show Help
//
//------------------------------------------------------------------------------
HelpMgr.prototype.ShowHelp = function(helpPageNumber)
{
	if (this.helpBackground == undefined)
		this.AddElements();
		
	if (helpPageNumber != undefined)
		this.helpPageNumber = helpPageNumber;
		
	this.helpCallback(this.helpPageNumber, this.helpData);
	this.PopulateHelpData();

	this.helpBackground.style.removeProperty("display");
	this.helpBackground.focus();
	
}

//------------------------------------------------------------------------------
//	Hide Help
//
//------------------------------------------------------------------------------
HelpMgr.prototype.HideHelp = function()
{
	this.helpBackground.style.display = "none";
	
	//this.helpBackground.parentNode.focus();
}

//------------------------------------------------------------------------------
//	Show Highlight
//
//	This is called from the mouseover on the "CL_HelpHighlight" elements.
//	These elements should also have a "data-id-help" attribute that refers
//	to another element on the page. 
//------------------------------------------------------------------------------
HelpMgr.prototype.ShowHighlight = function(evt)
{
	var idHelp = undefined; 
	var elHelp = undefined;
	
	if (evt.target.dataset != undefined)
		idHelp = evt.target.dataset.idHelp;
		
	//console.log("ShowHighlight: " + idHelp);
	
	if (idHelp != undefined)
		elHelp = document.getElementById(idHelp);
	
	if (elHelp != undefined)
	{
		var ex = 3;
		var r = elHelp.getBoundingClientRect();
		var st = this.helpHighlighter.style;
		
		st.top    = r.top    - ex + "px";
		st.left   = r.left   - ex + "px";
		st.bottom = r.bottom + ex + "px";
		st.right  = r.right  + ex + "px";
		st.width  = r.width  + 2 * ex + "px";
		st.height = r.height + 2 * ex  + "px";
		
		st.display = "block";
	}
}

//------------------------------------------------------------------------------
//	Remove Highlight
//
//	Removes the highlight div
//------------------------------------------------------------------------------
HelpMgr.prototype.RemoveHighlight = function(evt)
{
	this.helpHighlighter.style.removeProperty("display");
}

//------------------------------------------------------------------------------
//	Populate Help Data
//
//	Copy the help data provided by the application into the elements.
//	Also search the subelements for any "CL_HelpHighlight" classes and add
//	listeners to handle the highlight feature
//------------------------------------------------------------------------------
HelpMgr.prototype.PopulateHelpData = function()
{
	if (this.helpBackground != undefined)
	{
		var e = document.getElementById("ID_MainHelpText");
		e.innerHTML = this.helpData.mainText;
		
		var hilist = e.getElementsByClassName("CL_HelpHighlight");
		for (var i = 0; i < hilist.length; i++)
		{
			hilist[i].addEventListener("mouseover", (evt) => this.ShowHighlight(evt), false);
			hilist[i].addEventListener("mouseout", (evt) => this.RemoveHighlight(evt), false);
		}
	}
	else
	{
		console.log("HelpMgr.prototype.PopulateHelpData: this.helpBackground undefined!");
	}
}


//------------------------------------------------------------------------------
//	Advance Help
//
//	Manage previous or next page requests. 
//	Note that "next page" from the last page will dismiss help
//------------------------------------------------------------------------------
HelpMgr.prototype.AdvanceHelp = function(direction)
{
	this.RemoveHighlight();
	
	if (direction == 1 && this.helpData.lastPage)
	{
		this.HideHelp();
	}
	else
	{
		if (direction == -1 && this.helpPageNumber > 0)
			this.helpPageNumber--;
		else if (direction == 1 && !this.helpData.lastPage)
			this.helpPageNumber++
		
		this.helpCallback(this.helpPageNumber, this.helpData);
		this.PopulateHelpData();
	}
}


//------------------------------------------------------------------------------
//	Handle Keypress
//
//------------------------------------------------------------------------------
HelpMgr.prototype.HandleKeypress = function(evt)
{
	if (evt.code == "Escape")
		this.HideHelp();
	else if (evt.code == "ArrowLeft")
		this.AdvanceHelp(-1);
	else if (evt.code == "ArrowRight")
		this.AdvanceHelp(1);
	
	else if (evt.key == "Alt" || evt.key == "Shift" || evt.key == "Control" || evt.key == "Meta")
	{ }
	else
	{
		console.log("code: " + evt.code + ", key: " + evt.key);
		this.HighlightButtons();
	}
}

//------------------------------------------------------------------------------
//	Handle Mousedown
//
//	The only purpose of this is to draw attention to the buttons if the user 
//	clicks anywhere else when help is up.
//------------------------------------------------------------------------------
HelpMgr.prototype.HandleMousedown = function(evt)
{
	var id = evt.target.id;
	
	if (id == "ID_SkipHelp" || id == "ID_PrevHelp" || id == "ID_NextHelp")
	{
	}
	else
		this.HighlightButtons();
}

//------------------------------------------------------------------------------
//	Highlight buttons
//
//	Add a class to do perform an animation around the buttons
//------------------------------------------------------------------------------
HelpMgr.prototype.HighlightButtons = function()
{
	if (this.helpButtons == undefined)
	{
		var elements = this.helpBackground.getElementsByClassName("CL_HelpButtons");
		if (elements.length > 0)
		{
			this.helpButtons = elements[0];
			this.helpButtons.addEventListener("animationend", (evt) => this.HighlightAnimationButtonsDone(evt), false);
		}
	}
	
	if (this.helpButtons != undefined)
		this.helpButtons.classList.add("CL_HelpButtonsAttention");
}

//------------------------------------------------------------------------------
//	Highlight Buttons Done
//
//	When the animation is completed, remove the animation class
//------------------------------------------------------------------------------
HelpMgr.prototype.HighlightAnimationButtonsDone = function()
{
	if (this.helpButtons != undefined)
		this.helpButtons.classList.remove("CL_HelpButtonsAttention");
}

//------------------------------------------------------------------------------
//	Handle Button
//
//------------------------------------------------------------------------------
HelpMgr.prototype.HandleButton = function(evt)
{
	var id = evt.target.id;
	
	//evt.stopPropagation();
	//evt.preventDefault();
	
	if (id == "ID_SkipHelp")
		this.HideHelp();
	else if (id == "ID_PrevHelp")
		this.AdvanceHelp(-1);
	else if (id == "ID_NextHelp")
		this.AdvanceHelp(1);
}

/*
var EnumXXX = Object.freeze({
	FRONT_BACK		: 0,
	LEFT_RIGHT		: 1,
	FOUR_SIDES		: 2
});
*/

//------------------------------------------------------------------------------
//	Make publicly available
//
//------------------------------------------------------------------------------
export { HelpMgr };
