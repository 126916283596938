//-------------------------------------------------------------------------------------
//	Gradient (API)
//
//
//-------------------------------------------------------------------------------------
var Gradient = (function() {

	var GradientStyle = Object.freeze({
		LINEAR		: 0,
		CIRCULAR	: 1,
		RADIAL		: 2
		});

	var GradientRamp = Object.freeze({
		LINEAR		: 0,
		SINE		: 1,
		CUBIC		: 2 
		});

	var GradientProperties = Object.freeze([
		{prop:"enable",			type:"boolean"		},
		{prop:"referenceX",		type:"number"		},
		{prop:"referenceY",		type:"number"		},
		{prop:"style",			type:"enum"			},
		{prop:"ramp",			type:"enum"			},
		{prop:"size",			type:"number"		},
		{prop:"angle",			type:"number"		},
		{prop:"count",			type:"number"		},
		{prop:"cycleBefore",	type:"boolean"		},
		{prop:"cycleAfter",		type:"boolean"		},
		{prop:"colorA",			type:"color"		},
		{prop:"colorB",			type:"color"		}
		]);

	function GradientCreate() 
	{
		var g = {};
		
		g.enable = false;
		g.referenceX = 0;
		g.referenceY = 0;
		g.style = GradientStyle.LINEAR;
		g.ramp  = GradientRamp.LINEAR;
		g.size  = 100;
		g.angle = 0;
		g.count = 3;
		g.cycleBefore = true;
		g.cycleAfter = true;
		
		g.colorA = "#eeffff";
		g.colorB = "#11ffff";
		
		return g;
	}
	
	function cubic(t)
	{
		var s;
		
		if (t < 0.5)
		{
			t = 2 * t;
			s = t * t * t;
			s = s / 2;
		}
		else
		{
			t = 2 * t - 2;
			s = t * t * t + 2;
			s = s / 2;
		}
		return s;
    }

	var MakeRGBHexStr = function(r,g,b)
	{
		var c = (r * 256 * 256) + (g * 256) + b;
		var h = "#" + ("000000" + c.toString(16)).slice(-6)
			
		return h;
	}
	
	var ExpandRGB = function(rgb)
	{
		var eRGB = {};
		
		eRGB.rStr = rgb.substr(1, 2);
		eRGB.gStr = rgb.substr(3, 2);
		eRGB.bStr = rgb.substr(5, 2);
		
		eRGB.r = parseInt(eRGB.rStr, 16);
		eRGB.g = parseInt(eRGB.gStr, 16);
		eRGB.b = parseInt(eRGB.bStr, 16);

		//console.log(JSON.stringify(eRGB));
		return eRGB;
	}
	
	var CalcGradientDistance = function(gradientSettings, point)
	{
		var distance;
		// Vector from origin to point
		let v = {x:point.x - gradientSettings.referenceX, y:point.y - gradientSettings.referenceY };
		
		if (gradientSettings.style == GradientStyle.LINEAR)
		{
			let angle = gradientSettings.angle * Math.PI / 180.0
			// Unit vector in the direction of the gradient angle
			let u = {x:Math.cos(angle), y:Math.sin(angle) };
			// Dot product between unit vector and vector from origin to point will give a
			// scalar that represents the project of v onto u
			distance = u.x * v.x + u.y * v.y;
		}
		else if (gradientSettings.style == GradientStyle.CIRCULAR)
		{
			distance = Math.sqrt(v.x * v.x + v.y * v.y);
		}
		else if (gradientSettings.style == GradientStyle.RADIAL)
		{
			// "Wedges"
			var sections = gradientSettings.count;
			if (sections <= 0)
				sections = 1;
				
			// Angle of the vector from the origin to the point
			let a = Math.atan2(v.y, v.x);
			// Shift by the gradient angle
			a -= gradientSettings.angle * Math.PI / 180.0;
			// Multiply by the number of wedges			
			a *= sections;
			// Move to 0..2Pi
			while (a < 0)
				a += 2 * Math.PI;
			while (a > 2 * Math.PI)
				a -= 2 * Math.PI;

			// For smooth gradients from wedge to wedge, we need to return a value 
			// from zero to Pi to zero as we sweep across one wedge.
			// Actually the midpoint, Pi, could be any constant, as long as the caller
			// knows what it is so it can divide by it and get a value from 0 to 1 to 0
			if (a > Math.PI)
				a = 2 * Math.PI - a;
				
			distance = a;
		}
		else
		{
			console.log("CalcGradientDistance: Unknown GradientStyle: " + gradientSettings.style);
		}
		
		return distance
	}
	
	var CalcGradientValueFromPoint = function(gradientSettings, point)
	{
		let distance = CalcGradientDistance(gradientSettings, point);
		let t = 0;
		let size = gradientSettings.size;
		
		if (size < 1)
			size = 1;
			
		// Convert the distance to a "t" value. When t is zero
		// we are at the beginning of the gradient and at 1 we are at the end.
		// The t computed here will not be limited yet.
		if (gradientSettings.style == GradientStyle.LINEAR)
		{
			// First scale according to the size
			t = (distance / size);
			// Since we are relative to the origin of the gradient, 
			// we have to shift everything by 1/2
			t += 0.5;
		}
		else if (gradientSettings.style == GradientStyle.CIRCULAR)
		{
			// Use the gradient size as the radius
			t = distance / size;
		}
		else if (gradientSettings.style == GradientStyle.RADIAL)
		{
			// For radial gradients, the distance is an angle from 0 to 2Pi
			t = distance / Math.PI;
		}
		else
		{
			console.log("CalcGradientValueFromPoint: Unknown GradientStyle: " + gradientSettings.style);
		}
		
		// Limit "t" according to the settings
		if (t < 0)
		{
			if (gradientSettings.cycleBefore)
			{
				while (t < 0)
					t += 2;
					
				if (t > 1)
					t = 2.0 - t;
			}
			else
			{
				t = 0;
			}
		}
		
		if (t > 1)
		{
			if (gradientSettings.cycleAfter)
			{
				while (t > 1)
					t -= 2;
				if (t < 0)
					t = -t
			}
			else
			{
				t = 1.0;
			}
		}
		
		// Convert "t" according to the ramp style
		if (gradientSettings.ramp == GradientRamp.LINEAR)
		{ /* no changes needed */ }
		else if (gradientSettings.ramp == GradientRamp.SINE)
			t = (1.0-Math.cos(Math.PI * t))/2.0
		else if (gradientSettings.ramp == GradientRamp.CUBIC)
			t = cubic(t);
		else
			console.log("CalcGradientValueFromPoint: Unknown GradientRamp: " + gradientSettings.ramp);

		return t;
	}

	var CalcGradientColor = function(colorA, colorB, t)
	{
		let startClr = ExpandRGB(colorA);
		let endClr = ExpandRGB(colorB);

		if (t < 0)
			t = 0;
		else if (t > 1)
			t = 0;
			
		var r = Math.floor(startClr.r * (1 - t) + endClr.r * t);
		var g = Math.floor(startClr.g * (1 - t) + endClr.g * t);
		var b = Math.floor(startClr.b * (1 - t) + endClr.b * t);
		
		if (r < 0)
			r = 0;
		else if (r > 255)
			r = 255;

		if (g < 0)
			g = 0;
		else if (g > 255)
			g = 255;

		if (b < 0)
			b = 0;
		else if (b > 255)
			b = 255;
			
		
		return MakeRGBHexStr(r, g, b);
	}


	function GradientColor(gradientSettings, point)
	{
		let t = CalcGradientValueFromPoint(gradientSettings, point);
		let clrA = gradientSettings.colorA;
		let clrB = gradientSettings.colorB;
		
		if (clrA == undefined || typeof clrA != 'string')
			clrA = "#ff0000";

		if (clrB == undefined || typeof clrB != 'string')
			clrB = "#0000ff";
			
		
		let clr = CalcGradientColor(gradientSettings.colorA, gradientSettings.colorB, t);
		
		return clr;
	}
	
    
	return {
		// Enums
		Style:			GradientStyle,
		Ramp:			GradientRamp,
		Properties:		GradientProperties,
		// Functions
		Create:			GradientCreate,
		CalcColor:		GradientColor
	};
}());

export { Gradient };
