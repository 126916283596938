//==========================================================================================
//
//	Screen Image Canvas
//
//------------------------------------------------------------------------------------------
//
//==========================================================================================
import { ScreenDesigner } from "./ScreenDesigner.js";


function getMousePos(aCanvas, evt) {

	var rect = aCanvas.getBoundingClientRect();
	var mouseX = (evt.changedTouches ? evt.changedTouches[0].clientX : evt.clientX) - rect.left; //this.offsetLeft;
	var mouseY = (evt.changedTouches ? evt.changedTouches[0].clientY : evt.clientY) - rect.top; //this.offsetTop;
	var insetX = 1; // These match the border on the canvas. Hardcoding is less
	var insetY = 1; // expensive than computing the style
	return {
		x: mouseX - insetX,
		y: mouseY - insetY
	};
}

//------------------------------------------------------------------------------
//
//------------------------------------------------------------------------------
var ScreenImageCanvas = (function() {

	//------------------------------------------------------------------------------
	//	State
	//------------------------------------------------------------------------------
	var siImageCanvas = undefined;
	var siImageContext = undefined;
	var siProcessCanvas = undefined;
	var siProcessContext = undefined;
	var siOrigFileImage = undefined;
	var siFileImage = undefined;
	var siImageSettings = {scale: 1.0, offset:{x:0, y:0}, imgCrop:{x:undefined, y:undefined}, ctxOffset:{x:0, y:0}};
	var siPendingSettings = undefined; // Set when image data is specified and applied after image load is complete
	var siMouseTracking = { tracking: false, prevPt: {x: undefined, y:undefined}};

	//------------------------------------------------------------------------------
	//	Init
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_Init = function(canvasID)
	{
		try {
			siImageCanvas = document.getElementById(canvasID);
			siImageContext = siImageCanvas.getContext("2d");

			siProcessCanvas = document.getElementById("ID_ProcessCanvas");
			siProcessContext = siProcessCanvas.getContext("2d");
			
			// 2021.03.12: Don't put in a default item. ScreenImageCanvas_LoadImageURL("rsrcs/logo.png");
		
			ScreenImageCanvas_ConnectUI();
		}
		catch (err) {
			console.log("ScreenImageCanvas_Init failed");
			console.log(err);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Connect UI
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_ConnectUI = function()
	{
		let mouseEvents = ["mousedown", "mousemove", "mouseup", "mouseleave", "mousewheel"];
		mouseEvents.forEach(evtName => siImageCanvas.addEventListener(evtName,  evt => ScreenImageCanvas_HandleMouse(evt, evtName)));

		let dragEvents = ["dragover", "dragenter", "dragleave", "drop"];
		dragEvents.forEach(evtName => siImageCanvas.addEventListener(evtName,  evt => ScreenImageCanvas_HandleDrag(evt, evtName)));
	}
	
	//------------------------------------------------------------------------------------
	//	Adjust Scale AroundBy
	//------------------------------------------------------------------------------------
	var AdjustScaleAroundBy = function(zoom, aroundPt, byPercent, scaleLimit)
	{
		var scaleChanged = false;
	
		var newScale = zoom.scale + zoom.scale * byPercent;

		if (scaleLimit == undefined || (Math.abs(newScale) > scaleLimit))
		{
			var prevOffsetDelta = {x:(zoom.offset.x - aroundPt.x), y:(zoom.offset.y - aroundPt.y)};
			var prevScale = zoom.scale;

			// 2021.03.29: Round zoom to three decimal points
			newScale = Math.round(newScale * 1000)/1000;

			zoom.scale = newScale;
		
// 			if (scaleLimit != undefined)
// 			{
// 				if (Math.abs(this.scale) < scaleLimit)
// 					this.scale = scaleLimit * Math.abs(this.scale);
// 			}
		
			var newOffSetDeltaX = prevOffsetDelta.x * zoom.scale/prevScale;
			var newOffSetDeltaY = prevOffsetDelta.y * zoom.scale/prevScale;
	
			zoom.offset.x = aroundPt.x + newOffSetDeltaX;
			zoom.offset.y = aroundPt.y + newOffSetDeltaY;
		
			/*if (zoom.offset.x > 0)
				zoom.offset.x = 0;
		
			if (zoom.offset.y > 0)
				zoom.offset.y = 0;
			*/
			scaleChanged = true;
		}
	
		return scaleChanged;
	}

	//------------------------------------------------------------------------------------
	//	Handle Mouse
	//------------------------------------------------------------------------------------
	function ScreenImageCanvas_HandleDrag(evt, evtName)
	{
		//console.log(evtName);
		
		if (evtName == "dragover")
		{
			//ScreenImageCanvas_DisplayDragFeedback(true);
			evt.preventDefault();
		}
		else if (evtName == "dragenter")
		{
			ScreenImageCanvas_DisplayDragFeedback(true);
			evt.preventDefault();
		}
		else if (evtName == "dragleave")
		{
			ScreenImageCanvas_DisplayDragFeedback(false);
			evt.preventDefault();
		}
		else if (evtName == "drop")
		{
			ScreenImageCanvas_DisplayDragFeedback(false);
			evt.preventDefault();
			
			var data = evt.dataTransfer.items;
			var done = false;
			
			for (var i = 0; i < data.length && !done; i += 1)
			{
				if ((data[i].kind == 'string') && (data[i].type.match('^text/plain')))
				{
					// data[i].getAsString(s => console.log("... Drop: string: " + s));
				} 
				else if ((data[i].kind == 'string') && (data[i].type.match('^text/html')))
				{
					// Drag data item is HTML
					// console.log("... Drop: HTML");
				}
				else if ((data[i].kind == 'string') && (data[i].type.match('^text/uri-list')))
				{
					// Drag data item is URI
					//data[i].getAsString(s => ScreenImageCanvas_HandledDroppedImage(s, false /* URI */));
					//done = true;
				} 
				else if ((data[i].kind == 'file') && (data[i].type.match('^image/')))
				{
					// Drag data item is an image file
					var f = data[i].getAsFile();
					ScreenImageCanvas_HandledDroppedImage(f, true /* file */)
					done = true;
				}
			}
		}
		else
		{
			console.log("ScreenImageCanvas_HandleDrag: not handled: " + evtName);
		}
	}
	
	//------------------------------------------------------------------------------------
	//	ignore Meta Key
	//		2021.03.16: This is a copy of the same function in ScreenDesignerCanvas.js.
	//------------------------------------------------------------------------------------
	var ignoreMetaKeyFlag = undefined;
	function ignoreMetaKey()
	{
		if (ignoreMetaKeyFlag == undefined)
		{
			var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
			ignoreMetaKeyFlag = (macosPlatforms.indexOf(window.navigator.platform) === -1);
		}

		return ignoreMetaKeyFlag;
	}

	//------------------------------------------------------------------------------------
	//	Handle Mouse
	//------------------------------------------------------------------------------------
	function ScreenImageCanvas_HandleMouse(evt, evtName)
	{
		var mousePt = getMousePos(siImageCanvas, evt);

		if (siFileImage == undefined)
		{
			// No image, do nothing
		}
		else if (evtName == "mousedown")
		{
			siMouseTracking.tracking = true;
			siMouseTracking.prevPt = mousePt;
		}
		else if (evtName == "mousemove")
		{
			if (siMouseTracking.tracking)
			{
				let delta = {x:mousePt.x - siMouseTracking.prevPt.x, y:mousePt.y - siMouseTracking.prevPt.y};
			
				if (delta.x != 0 || delta.y != 0)
				{
					siImageSettings.offset.x += delta.x;
					siImageSettings.offset.y += delta.y;
			
					ScreenImageCanvas_DrawImage();
				
					ScreenDesigner.UpdateImage(siImageSettings);
				}

				siMouseTracking.prevPt = mousePt;
			}
		}
		else if (evtName == "mouseup")
		{
			if (siMouseTracking.tracking)
			{
				//ScreenDesigner.UpdateImage();
			}
			siMouseTracking.tracking = false;
		}
		else if (evtName == "mouseleave")
		{
			siMouseTracking.tracking = false;
		}
		else if (evtName == "mousewheel")
		{
			if (evt.metaKey || ignoreMetaKey()) // 2021.03.16
			{
				var changePercent = 0.05;
				var lowerScaleLimit = siImageSettings.minScale; 
				var scaleChanged = AdjustScaleAroundBy(siImageSettings, mousePt, (evt.deltaY > 0) ? changePercent : -changePercent, lowerScaleLimit);
		
				if (scaleChanged)
				{
					ScreenImageCanvas_DrawImage();
					ScreenDesigner.UpdateImage(siImageSettings);
				}
		
				evt.stopPropagation();
				evt.preventDefault();
			}
			else
				ScreenDesigner.DisplayHoldCommandKeyBanner(siImageCanvas.id);
		}
	}

	//------------------------------------------------------------------------------------
	//	Handled Dropped Image
	//		2021.03.12: Added. Passes the image reference to ScreenDesigner
	//------------------------------------------------------------------------------------
	var ScreenImageCanvas_HandledDroppedImage = function(imageURL, isFile)
	{
		var url = isFile ? URL.createObjectURL(imageURL) : imageURL;
		
		ScreenImageCanvas_LoadImageURL(url);
	}
	
	//------------------------------------------------------------------------------------
	//	Get Scaled JPEG Image URL
	//
	//	Returns JPEG data for an image no larger than 'pixelLimit' pixels
	//------------------------------------------------------------------------------------
	var ScreenImageCanvas_GetScaledJPEGImageURL = function(fileImage, pixelLimit)
	{
		// Calculate pixels in original image
		let origPixels = fileImage.naturalWidth * fileImage.naturalHeight;
		
		// Calculate scale to reduce total pixels below the pixelLimiti
		let scale = (pixelLimit > origPixels) ? 1.0 : Math.sqrt(pixelLimit / origPixels);
		
		// Scale the original size
		let scaledWidth  = Math.floor(scale * fileImage.naturalWidth);
		let scaledHeight = Math.floor(scale * fileImage.naturalHeight);
	
		// Create a canvas and set it to the scaled size
		let canvas = document.createElement("canvas");
		canvas.width = scaledWidth;
		canvas.height = scaledHeight;
		
		// Draw the image into the conteext
		let context = canvas.getContext("2d");
		context.drawImage(fileImage, 0, 0, scaledWidth, scaledHeight);
		
		// Get the JPEG data from the canvas
		var jpegDataURL = canvas.toDataURL('image/jpeg', 0.1);
	
		return jpegDataURL;
	}

	//------------------------------------------------------------------------------
	//	Load Image
	//
	//	Called with the image URL for the original (unscaled) image.
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_LoadImageURL = function(imageSrc)
	{
		siOrigFileImage = new Image();
		siOrigFileImage.addEventListener("load", ScreenImageCanvas_OriginalImageLoaded);
		siOrigFileImage.src = imageSrc;
	}

	//------------------------------------------------------------------------------
	//	Original Image Loaded
	//
	//	Callback when the original image is loaded.
	//	Scales the image to a reasonable size and starts a second image load
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_OriginalImageLoaded = function(evt)
	{
		// Get JPEG data for a scaled-down version of the image
		let jpegData = ScreenImageCanvas_GetScaledJPEGImageURL(siOrigFileImage, 1000*1000);
		siOrigFileImage = undefined;

		// Second-state load, to use the scaled image
		ScreenImageCanvas_LoadDesignImage(jpegData);
		
		// Store the image JPEG URL in the design
		ScreenDesigner.SetImageData(jpegData);
	}
	
	//------------------------------------------------------------------------------
	//	Load Design Image
	//
	//	Called to load an the image to use. This is called either when the image data
	//	is provided by the ScreenDesigner or by the image scale function (_OriginalImageLoaded)
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_LoadDesignImage = function(imageSrc)
	{
		// Create a new image to hold the scaled data
		siFileImage = new Image();
		siFileImage.addEventListener("load", ScreenImageCanvas_ScaledImageLoaded);
		siFileImage.src = imageSrc;

		ScreenImageCanvas_DisplayImageLoadInstructions(false);
	}
	
	//------------------------------------------------------------------------------
	//	Scaled Image Loaded
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_ScaledImageLoaded = function(evt)
	{
		//console.log("Scaled image loaded: " + siFileImage.naturalWidth + " x " + siFileImage.naturalHeight);
		ScreenImageCanvas_ResetImageZoom();
		
		// Apply pending image settings, if available.
		if (siPendingSettings != undefined)
		{
			Object.assign(siImageSettings, siPendingSettings);
			siPendingSettings = undefined;
		}
		else
		{
			// 2021.03.17: If we weren't provided settings, then give the newly
			// calculated settings to app to store
			ScreenDesigner.UpdateImage(siImageSettings);
		}
		
		ScreenImageCanvas_DrawImage();
		
		ScreenDesigner.ImageLoadComplete();
	}

	//------------------------------------------------------------------------------------
	//	Reset Image Zoom
	//------------------------------------------------------------------------------------
	var ScreenImageCanvas_ResetImageZoom = function()
	{
		let scale = CalcImageFitScale();
	
		siImageSettings.scale = scale;
		siImageSettings.offset = {x:0, y:0};
		siImageSettings.minScale = scale/2;
		siImageSettings.imgCrop = {x:undefined, y:undefined};
		siImageSettings.ctxOffset = {x:0, y:0};
	}

	//------------------------------------------------------------------------------------
	//	Recalc Image MinScale
	//------------------------------------------------------------------------------------
	function RecalcImageMinScale()
	{
		siImageSettings.minScale = CalcImageFitScale()/2;
	}

	//------------------------------------------------------------------------------------
	//	Calc Image Fit Scale
	//------------------------------------------------------------------------------------
	function CalcImageFitScale()
	{
		let scale = 1.0;
		
		// 2020.03.12: Handle case where image is not available
		if (siFileImage != undefined)
		{
			let nWidth = siFileImage.naturalWidth;
			let nHeight = siFileImage.naturalHeight;
	
			let cWidth = siImageContext.canvas.width;
			let cHeight = siImageContext.canvas.height;
	
			let xScale = cWidth/nWidth;
			let yScale = cHeight/nHeight;
	
			scale = (xScale < yScale) ? xScale : yScale;
		}
		
		return scale;
	}

	
	//------------------------------------------------------------------------------
	//	Draw Image
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_DrawImage = function()
	{
		let nWidth  = siFileImage.naturalWidth;
		let nHeight = siFileImage.naturalHeight;
		
		let width = nWidth * siImageSettings.scale;
		let height = nHeight * siImageSettings.scale;

		if (siImageCanvas != undefined)
		{
			//let cWidth  = siImageCanvas.width;
			//let cHeight = siImageCanvas.height;
			//let scale = (cWidth/nWidth < cHeight/nHeight) ? cWidth/nWidth : cHeight/nHeight;
			
			//console.log("ScreenImageCanvas_DrawImage: " + siImageSettings.scale.toFixed(2) +", " + siImageSettings.offset.x.toFixed(2) + ", " + siImageSettings.offset.y.toFixed(2));
			
			siImageContext.clearRect(0, 0, siImageContext.canvas.width, siImageContext.canvas.height);
			siImageContext.drawImage(siFileImage, siImageSettings.offset.x, siImageSettings.offset.y, width, height);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Get Image Settings
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_GetImageSettings = function()
	{
		return siImageSettings;
	}

	//------------------------------------------------------------------------------
	//	Get Image Data
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_GetImageData = function()
	{
		var imageData = undefined;

		try {
			if (siImageContext != undefined)
				imageData = siImageContext.getImageData(0, 0, siImageContext.canvas.width, siImageContext.canvas.height);
		}
		catch (err) {
			console.log("ScreenImageCanvas_GetImageData failed");
			console.log(err);
		}
		
		return imageData;
	}
	
	//------------------------------------------------------------------------------
	//	Get Processed Data
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_GetProcessedData = function()
	{
		var processedData = undefined;

		try {
			processedData = siProcessContext.getImageData(0, 0, siProcessContext.canvas.width, siProcessContext.canvas.height);
		}
		catch (err) {
			console.log("ScreenImageCanvas_GetProcessedData failed");
			console.log(err);
		}
		
		return processedData;
	}

	//------------------------------------------------------------------------------
	//	Render Processed
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_RenderProcessed = function(processedData)
	{
		if (processedData != undefined)
			siProcessContext.putImageData(processedData, 0, 0);
	}
	
	//------------------------------------------------------------------------------
	//	Clear Processed
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_ClearProcessed = function(processedData)
	{
		siProcessContext.clearRect(0, 0, siProcessContext.canvas.width, siProcessContext.canvas.height);
	}
	
	//------------------------------------------------------------------------------
	//	Load Image File
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_LoadImageFile = function(evt)
	{
		var fileInfo = evt.target.files[0];
		
		ScreenImageCanvas_LoadImageURL(URL.createObjectURL(fileInfo));
	}
	
	//------------------------------------------------------------------------------
	//	Set Image Data
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_SetImageData = function(imageData, imageSettings = undefined)
	{
		ScreenImageCanvas_ClearProcessed(); // 2021.03.23

		ScreenImageCanvas_LoadDesignImage(imageData);
		
		siPendingSettings = undefined;
		
		if (imageSettings != undefined)
		{
			siPendingSettings = {};
			siPendingSettings.scale = imageSettings.zoom;
			siPendingSettings.offset = {x:imageSettings.offsetX, y: imageSettings.offsetY};
		}
		
	}

	//------------------------------------------------------------------------------
	//	Set Property
	//		2021.03.29: Called from Screen Designer when input are used to change
	//		image drawing settings
	//		2021.03.30: Overload value (ie, support "reset" as a value)
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_SetProperty = function(property, value)
	{
		// The zoom (scale) can be set from an input field
		if (property == "imgZoom")
		{
			if (value == "reset")
				ScreenImageCanvas_ResetImageZoom();
			else
				siImageSettings.scale = value;

			ScreenImageCanvas_DrawImage();

			// Tell the app to get the new image data.
			ScreenDesigner.ReloadImageData();
		}
	}
	 
	//------------------------------------------------------------------------------
	//	Clear Image Data
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_ClearImageData = function()
	{
		siFileImage = undefined;
		ScreenImageCanvas_ResetImageZoom();
		siImageContext.clearRect(0, 0, siImageContext.canvas.width, siImageContext.canvas.height);
		
		ScreenImageCanvas_DisplayImageLoadInstructions(true);
		
		ScreenImageCanvas_ClearProcessed(); // 2021.03.23
	}

	//------------------------------------------------------------------------------
	//	Display Image Load Instructions
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_DisplayImageLoadInstructions = function(showInstructions = false)
	{
		let e = document.getElementById("ID_NoImageMsgDiv");
		
		if (e != undefined)
		{
			// Can't use "CL_HideElement" since CSS element is already using flex
			e.style.display = (showInstructions) ? "flex" : "none";
		}
	}

	//------------------------------------------------------------------------------
	//	Display Image Load Instructions
	//------------------------------------------------------------------------------
	var ScreenImageCanvas_DisplayDragFeedback = function(showFeedback = false)
	{
		let e = document.getElementById("ID_DragHandlerDiv");
		
		if (e != undefined)
		{
			// Can't use "CL_HideElement" since CSS element is already using flex
			if (showFeedback)
				e.classList.add("CL_DragoverFeedback");
			else
				e.classList.remove("CL_DragoverFeedback");
		}
	}

	//------------------------------------------------------------------------------
	//	Public API
	//------------------------------------------------------------------------------
	return {
		Init:					ScreenImageCanvas_Init,
		GetImageSettings:		ScreenImageCanvas_GetImageSettings,
		GetImageData:			ScreenImageCanvas_GetImageData,
		GetProcessedData:		ScreenImageCanvas_GetProcessedData,
		LoadImageFile:			ScreenImageCanvas_LoadImageFile,
		SetImageData:			ScreenImageCanvas_SetImageData,
		SetProperty:			ScreenImageCanvas_SetProperty,
		ClearImageData:			ScreenImageCanvas_ClearImageData,
		RenderProcessed:		ScreenImageCanvas_RenderProcessed
	}
}());


//------------------------------------------------------------------------------
//	Public API
//
//------------------------------------------------------------------------------
export { ScreenImageCanvas };
