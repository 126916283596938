// LightBox

/*
	<div class="lightbox">
		<!-- Lightbox Gallery -->
		<div class="lightbox-thumbnail-gallery"></div>
		<!-- The Modal/Lightbox -->
		<div id="myModal" class="lightbox-modal">
			<span class="lightbox-close">&times;</span>
			<a class="lightbox-prev">&#10094;</a>
			<a class="lightbox-next">&#10095;</a>
			<div class="lightbox-modal-content">
				<img src="gallery/img001.JPG" >
			</div>
		</div>
	</div>

*/
var LightBox = (function() {

	var slideIndex = 0;

	var LightBox_Init = function()
	{
		LightBox_ConnectUI();
	}
	
	var LightBox_ConnectUI = function()
	{
		document.querySelector(".lightbox-close").onclick = () => LightBox_Show(false);
		document.querySelector(".lightbox-prev").onclick = () => LightBox_AdvanceSlide(-1);
		document.querySelector(".lightbox-next").onclick = () => LightBox_AdvanceSlide( 1);
	}
		
	var LightBox_Show = function(showIt)
	{
		var bg = document.querySelector('.lightbox-modal');
		var lb = document.querySelector('.lightbox')
		
		if (showIt)
		{
			bg.style.display = "flex";
			lb.focus();
			lb.addEventListener("keydown", LightBox_HandleKeydown, true);
		}
		else
		{
			bg.style.display = "none";
			lb.blur();
			lb.removeEventListener("keydown", LightBox_HandleKeydown, true);
		}
	}
	
	var LightBox_CreateGallery = function(images)
	{
		LightBox_BuildGallery(images);
	}

	var LightBox_BuildGallery = function(images)
	{
		var gallery = document.querySelector(".lightbox-thumbnail-gallery");
		
		for (var i = 0; i < images.length; i++)
		{
			var thumbDiv = document.createElement("div");
			thumbDiv.classList.add("lightbox-thumbnail");
		
			var thumbImg = document.createElement("img"); 
			thumbImg.classList.add("lightbox-hover-shadow");
			thumbImg.src = images[i].thumb;
			thumbImg.dataset.index = i;
			thumbImg.dataset.image = images[i].image;
			thumbImg.onclick = LightBox_ShowThisSlide;
			thumbDiv.appendChild(thumbImg);  

			gallery.appendChild(thumbDiv); 
		}
	}

	var LightBox_ShowThisSlide = function(evt)
	{
		LightBox_ShowSlide(parseInt(evt.target.dataset.index));
		LightBox_Show(true);
	}

	var LightBox_ShowSlide = function(n)
	{
		var gallery = document.querySelector(".lightbox-thumbnail-gallery");
		var count = gallery.childElementCount;
		
		if (n < 0)
			slideIndex = count - 1;
		else if (n >= count)
			slideIndex = 0;
		else
			slideIndex = n;
			
		var thumbDiv = gallery.childNodes[slideIndex];
		var thumbImg = thumbDiv.getElementsByTagName("IMG")[0];
				
		var photo = document.querySelector(".lightbox-modal-content > img");
		photo.src = thumbImg.dataset.image;
	}
	
	var LightBox_AdvanceSlide = function(dir)
	{
  		LightBox_ShowSlide(slideIndex + dir);
	}
	
	var LightBox_HandleKeydown = function(evt)
	{
		if (evt.keyCode == 37 || evt.keyCode == 39 /* || evt.keyCode == 38 || evt.keyCode == 40 */ )
		{
			//if (evt.keyCode == 38) // up
			//	byAmountCode = 10;
			//if (evt.keyCode == 40) // down
			//	byAmountCode = -10;
			if (evt.keyCode == 37) // left
				LightBox_AdvanceSlide(-1);
			if (evt.keyCode == 39) //right
				LightBox_AdvanceSlide(1);
		
			evt.preventDefault();
		}
		else if (evt.keyCode == 27 /* esc */)
		{
			LightBox_Show(false);
			evt.preventDefault();
		}
	}

	
	return	{
		Init:			LightBox_Init,
		CreateGallery:	LightBox_CreateGallery
	};
}());

//export { LightBox };
