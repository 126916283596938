import { BasicUnits, PolygonMiterType, PolygonList, MathUtil, SegmentList, Polygon_FindBounds } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { SegmentIntersectionHandling } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { Gradient } from "../../VectorUtilsJS/src/GradientLib.js"; // 2021.07.02

import { SquareTesselation, SquareOffsetTesselation, TrigridTesselation, HexgridTesselation, MandalaTesselation, BrickTesselation, RhombusTesselation, RectangleTesselation} from "./ScreenDesignerTessellations.js";

/*-----------------------------------------------*
 * Architecture
 *
 *-----------------------------------------------*/
var DesignType = Object.freeze({
	TYPE_REGULAR_TILING		: 0, /* Original triangle, square, and hexagon */
	TYPE_IMAGE				: 1,
	TYPE_ISLAMIC_TILING		: 2,
	TYPE_GRID_SKETCH		: 3
});

var FrameShape = Object.freeze({
	FRAME_RECTANGLE			: 0,
	FRAME_TRIANGLE			: 1,
	FRAME_PENTAGON			: 2,
	FRAME_HEXAGON			: 3,
	FRAME_OCTAGON			: 4,
	FRAME_SIDES_12			: 5,
	FRAME_SIDES_36			: 6,
	FRAME_NORMAN_WINDOW		: 7,
	FRAME_DIAMOND			: 8,
	FRAME_ISO_TRIANGLE		: 9, 
	FRAME_SINGLE_TILE		:10
//	FRAME_TILES				:11,
//	FRAME_POLY_LIST			:12		// User-drawn or imported frames
});

var FrameMeasure = Object.freeze({
	FRAME_INSIDE_EDGE		: 0,
	FRAME_CENTERLINE		: 1,
	FRAME_OUTSIDE_EDGE		: 2
});

var FrameRender = Object.freeze({
	FRAME_RENDER_ALL		: 0,
	FRAME_RENDER_INSIDE		: 1,
	FRAME_RENDER_NONE		: 2,
	FRAME_RENDER_OUTSIDE	: 3,
	FRAME_FULL_TILES		: 4
});

var Tiling = Object.freeze({
	SQUARES			: 0,
	SQUARES_OFFSET	: 1,	
	TRIGRID			: 2,	
	HEXGRID			: 3,
	MANDALA			: 4,
	BRICK			: 5,
	RHOMBUS			: 6,
	RECTANGLE		: 7,		// Also Slots (107)

	SLOTS			: 107
});

var ScreenElementTag = Object.freeze({
	ELEMENT_FRAME_OUTSIDE	: 0,
	ELEMENT_FRAME_INSIDE	: 1,
	ELEMENT_BASE			: 2,
	ELEMENT_SKETCH_OUTSIDE	: 3,
	ELEMENT_SKETCH_INSIDE	: 4
});

var ScreenDesignerDataType = Object.freeze({
	DESIGN_DATA		: 0,
	WORKBOOK_DATA	: 1
});

var ScreenDesignLimits = Object.freeze({
	MINIMUM_TILE_SIZE		: 0.1,
	MINIMUM_TILE_SIZE_IMAGE	: 0.01,
	MINIMUM_ASPECT_RATIO	: 0.01,
	MAXIMUM_ASPECT_RATIO	: 100
});


/*-----------------------------------------------*
 * ScreenDesignerData
 *
 *	general:
 *		units: (enum)
 *		renderLine: (bool)
 *		renderFill: (bool)
 *	frame:
 *		shape: (enum)
 *		width: (units)
 *		height: (units)
 *		radius: (units)
 *		rotation: (radians)
 *		border: (units)
 *		measure: (enum)
 *		render: (enum)
 *	tiling:
 *		shape: (enum)
 *		size: (units) size of one tile
 *	diags:
 * 		showCenterPoly: (bool)
 *		showSegments: (bool)
 *	elements: array
 *		
 *	
 *-----------------------------------------------*/

function ScreenDesignerData (theDesignType = undefined) {
	var general = {
		units: BasicUnits.MILLIMETERS,
		renderLine: true,
		renderFill: true,
		showBounds: false,
		drillHoleSize: 0.1, // never used
		fillColor: "#e0e0ff",
		lineColor: "#808080",
		backColor: "#ffffff",
		renderFill: true,
		renderLine: true,
		renderBack: false,
		cornerSize: 5,
		cornerStyle: 0,
		curveFrameOutsideCorner: true,
		curveFrameInsideCorner: true,
		curveFrameToSegmentCorner: true,
		curveSegmentCorners: true,
		lineWidth: 0.25,
		dpi: 72.0,
		showMinBounds: false,
		renderShadowLine: false, // 2020.09.09: Added shadow
		renderShadowFill: false,
		shadowLineAboveColor: false, // 2022.02.08: Added
		shadowColor: "#808080",
		shadowX: 0,
		shadowY: 0,
		shadowWidth: 0.25,
		shadowBlur: 0.5,
		renderCenter: false, // 2020.10.13: Added
		centerColor: "#808080", // 2022.02.03: Added center line color and width
		centerWidth: 0.25,
		renderLattice: true, // 2022.02.03: Added lattice line settings
		latticeColor: "#808080",
		latticeWidth: 0.25,
		latticeOffset: 0.0, // 2022.02.16
		sketchLineWidth: 2.0, // 2021.05.11: Added
		outputBackground: false, // 2022.02.14: Added
		clipCenterToLattice: true, // 2022.03.03: Added
		clipMidLineToLattice: true, // 2022.03.04: Added
		renderMidLine: false, // 2022.03.04
		midLineColor: "#808080", // 2022.02.03: Added center line color and width
		midLineWidth: 0.25,
		midLineLocScale: 0.5,
		midLineLocOffset: 0.0,
		};
		
	var frame = {
		shape: FrameShape.FRAME_RECTANGLE,
		width: 200,
		height: 200,
		radius: 100,
		aspect: 1.0,
		lockAspect: false,
		rotation: 0,
		border: 2,
		measure: FrameMeasure.FRAME_OUTSIDE_EDGE,
		render: FrameRender.FRAME_RENDER_ALL,
		margin: 0,
		fixedBounds: false,
		docWidth: 200,
		docHeight: 200
		};
		
	var tiling = {
		shape: Tiling.TRIGRID,
		size: 55,
		centered: false,
		orthocentered: false,
		segwidth: 2.0,
		showTiles: false,
		mirrorSeg: true,
		rotateSeg: 1,
		reflectSeg: 0, // 2020.09.01
		endCaps: PolygonMiterType.BUTT,
		offsetX: 0, // 2018.06.06: Added offset
		offsetY: 0,
		rotation: 0, // 2018.06.06: Added rotation
		symmetrySides: 8, 				// 2020.08.17: Mandala support
		arrangement: Tiling.HEXGRID,	// 2020.08.18: Used for Mandala only
		symmPointsInside: false, 		// 2020.08.18: Used for Mandala only
		symmetryRotate: false, 			// 2020.08.18: Used for Mandala only
		clipToTile: false, 				// 2020.08.18
		useLineColors: false, 			// 2020.08.25
		enableLattice: false,			// 2022.02.01
		enableLatticeCurves: false,		// 2022.02.12
		sizeB: 5,						// 2022.10.24: Secondary size param, for rectangles
		adjacencyShift: 0				// 2022.10.24: Adjacent row shift distance, for rectangles
		};
		
	var drillholes = { // 2017.12.04: Added 
		dhFrameVertex: false,
		dhFrameVertexSize: 0.5, // mm
		dhFrameVertexOffset: 0.0
	};
	
	var image = { // 2020.11.11: Added
		tileShape: 1, /* triangle */
		tileSize: 20,
		imgOffsetX: 0,
		imgOffsetY: 0,
		imgZoom: 1.0,
		monochromeStyle: "L", /* 0:"Light Polygons on Dark" or 1:"Dark Polygons on Light" */
		contrast: 0,
		brightness: 0,
		grayscaleStyle: 0, /* equal rgb or green-sensitive */
		posterize: false,
		posterizeLevels: 8,
		imgGrayscale: true,
		imgInvert: false,
		imgAction: 0, /* 0:none, 1:tilize, 2:stripize */
		imgLineSpacing: 0.1,
		minEdgeDist: 0.0,
		minPolySize: 0.0,
		includeClipped: false,
		slotAlign: "C", /* "T": top, "C": Center, "B": bottom */
		slotStyle: "S" /* "S": sloped, "C": corner */
	}
		
	var diags = {
		showCenterPoly: false,
		showSegments: false,
		showHairlinePoly: false,
		showOffsetPoly: false
		};
	
	// 2020.11.11: Added
	this.designType = (theDesignType != undefined) ? theDesignType : DesignType.TYPE_REGULAR_TILING;
	
	// Identify this object type in objects reconstructed from JSON strings
	this.reserved = ScreenDesignerDataType.DESIGN_DATA;
	
	// Settings groups
    this.frame = frame;
    this.tiling = tiling;
    this.general = general;
    this.image = image;
    this.diags = diags;
    this.drillholes = drillholes;
    
    // Line data
    this.elements = [];
    
    // Sketch data (2021.05.12)
    this.sketchData = [];

    // Gradients (2021.07.02)
    this.gradients = [];
    this.gradients.push(Gradient.Create());

    // Color Palette (2020.10.15)
    // { colorId, color }; color may be undefined
    this.colors = [];

    // Misc
    this.nextElementId = 0;
    this.dirty = false;
    
    this.ResetNextElementId();
    
    // Temporary: These items should not be stored (see Stringify, below)
    this.imageData = undefined;
    this.processedData = undefined;
    
    // Optional: Items that may be added for some designs
    // imageDataURL is a low-resolution JPEG image copy that is stored in the design JSON
    this.imageDataURL = undefined;
}
 
ScreenDesignerData.prototype.xxx = function() 
{};

/*-----------------------------------------------------------------------*
 *	Temporary Keys
 * 		2021.03.23: List of keys that will not be cloned or stringified
 *-----------------------------------------------------------------------*/
let ScreenDesignerData_TemporaryKeys = ["imageData", "processedData"];


/*-----------------------------------------------------------------------*
 *	Stringify
 * 		2020.11.13: Added so that temporary data is not stored on server
 *-----------------------------------------------------------------------*/
ScreenDesignerData.prototype.Stringify = function() 
{
	let imageData = undefined;
	let temporaryHold = {};
	
	// Don't stringify imageData or anything else listed in 'temporary keys' if they exist
	ScreenDesignerData_TemporaryKeys.forEach(key => 
		{
			if (this[key] != undefined)
			{
				temporaryHold[key] = this[key];
				this[key] = undefined;
			}
		});
	
	let str = JSON.stringify(this);

	// Restore the items removed before stringify
	Object.assign(this, temporaryHold);
	
	if (str.length > 100000)
		console.log("Data stringify length:", str.length);
		
	return str;
};

/*-----------------------------------------------------------------------*
 *	Ready To Render
 * 		2020.12.08: Added to delay render until additional data is available.
 *		Used by the IMAGE design type to wait for the image data to be loaded
 *-----------------------------------------------------------------------*/
ScreenDesignerData.prototype.ReadyToRender = function() 
{
	var ready;
	
	if (this.designType == DesignType.TYPE_REGULAR_TILING)
	{
		ready = true;
	}
	else if (this.designType == DesignType.TYPE_IMAGE)
	{
		ready = (this.imageData != undefined);
		
		// 2021.03.12: Allow rendering always, even without image data
		ready = true;
	}
	else if (this.designType == DesignType.TYPE_GRID_SKETCH)
	{
		ready = true;
	}
	else
	{
		console.log("ScreenDesignerData.prototype.ReadyToRender: Unknown designType: " + this.designType);
		ready = true;
	}
	
	return ready;
}

ScreenDesignerData.prototype.ResetNextElementId = function()
{
	// Set the next element id to be larger then greatest element id
	var maxId = 0;

	// Find greatest element id
	for (var i = 0; i < this.elements.length; i++)
	{
		if (this.elements[i].id == undefined)
			console.log("ScreenDesignerData.prototype.ResetNextElementId: element id at " + i + " is undefined");
		else if (this.elements[i].id > maxId)
			maxId = this.elements[i].id;
	}

	this.nextElementId = maxId + 1;
}

ScreenDesignerData.prototype.ValidateElementList = function()
{
	// 2018.07.18: If "elements" is missing, then add
	if (this.elements == undefined)
	{
		console.log("ScreenDesignerData.prototype.ValidateElementList: 'elements' list missing; initializing to []");
		this.elements = [];
	}

	// 2019.02.19: Convert rotate from boolean to number
	for (var i = 0; i < this.elements.length; i++)
	{
		if (typeof(this.elements[i].rotate) == "boolean")
			this.elements[i].rotate = (this.elements[i].rotate ? 1 : 0);
	}
	
	for (var j = 0; j < this.elements.length; j++)
	{
		for (var i = j + 1; i < this.elements.length; i++)
		{
			if (i != j)
			{
				if (this.elements[j].id == this.elements[i].id)
					console.log("ScreenDesignerData.prototype.ValidateElementList failed: elements at " + j + " and " + i + " has the same id (" + this.elements[i].id + ")");
			}
		}
	}

	// 2020.10.22: If the element list has any colors, then move them to the palette entry and
	// replace the color with the palette entry id (colorId)
	if (this.elements.some(e => e.color != undefined))
	{
		// Insure that there is an colors array
		if (this.colors == undefined)
			this.colors = [];

		// If we are adding entries to the colors list, then we need to know the next colorId to use, 
		// which is the max colorId plus one
		let nextColorId = 1 + this.colors.reduce((maxColorId, colorInfo) => Math.max(maxColorId, colorInfo.colorId), 0);

		// Go through the lines and replace the colors with colorIds
		for (var i = 0; i < this.elements.length; i++)
		{
			let e = this.elements[i];
			if (e.color != undefined)
			{
				var colorId;
				let color = e.color;
				delete e.color;
				
				let colorInfo = this.colors.find(ci => ci.color == color);
				if (colorInfo != undefined)
				{
					colorId = colorInfo.colorId;
				}
				else
				{
					let colorInfo = {colorId: nextColorId, color: color}
					colorId = colorInfo.colorId;
					this.colors.push(colorInfo);
					nextColorId += 1;
				}
				
				e.colorId = colorId;
			}
		}
	} // if-has-color (vs. colorId)
}

ScreenDesignerData.prototype.Validate = function()
{
	this.ValidateElementList();
	
	// 2020.11.11: Add design type
	if (this.designType == undefined)
		this.designType = DesignType.TYPE_REGULAR_TILING;

	// 2021.03.26: Limit are different for Image designs
	if (this.designType == DesignType.TYPE_IMAGE)
	{
		if (this.tiling.size < ScreenDesignLimits.MINIMUM_TILE_SIZE_IMAGE)
			this.tiling.size = ScreenDesignLimits.MINIMUM_TILE_SIZE_IMAGE;
	}
	else
	{
		if (this.tiling.size < ScreenDesignLimits.MINIMUM_TILE_SIZE)
			this.tiling.size = ScreenDesignLimits.MINIMUM_TILE_SIZE;
	}
		
	if (typeof(this.general.lineWidth) != "number")
		this.general.lineWidth = Number(this.general.lineWidth);
		
	if (typeof(this.tiling.rotateSeg) == "boolean")
		this.tiling.rotateSeg = (this.tiling.rotateSeg ? 1 : 0);
		
	// 2022.04.05: midLineLocScale: Only three legal values: "0", "0.5", and "1"
	if (this.general.midLineLocScale < 0.25)
		this.general.midLineLocScale = 0;
	else if (this.general.midLineLocScale < 0.75)
		this.general.midLineLocScale = 0.5;
	else
		this.general.midLineLocScale = 1;
}

ScreenDesignerData.prototype.Clone = function()
{
	// 2021.03.10: Don't use stringify/parse to copy imageData
	let temporaryHold = {};
	
	// Don't stringify imageData if it exists
	ScreenDesignerData_TemporaryKeys.forEach(key => {
			if (this[key] != undefined)
			{
				temporaryHold[key] = this[key];
				this[key] = undefined;
			}
		});

	// Quick and dirty copy of the object
	var copy = JSON.parse(JSON.stringify(this));
	Object.setPrototypeOf(copy, ScreenDesignerData.prototype);

	// Restore the items removed before stringify/parse
	Object.assign(this, temporaryHold);

	// Also add the same items to the copy.
	// Note that we are not making a copy of the image data.
	// I don't know if this is an issue
	Object.assign(copy, temporaryHold);

	return copy;
}

ScreenDesignerData.prototype.Import = function(designData)
{
	// 2019.01.28: Added to support undo/redo
	
	// 2021.03.10: Don't use stringify/parse to copy imageData
	let temporaryHold = {};
	
	// Don't stringify imageData if it exists
	ScreenDesignerData_TemporaryKeys.forEach(key => {
			if (designData[key] != undefined)
			{
				temporaryHold[key] = designData[key];
				designData[key] = undefined;
			}
		});

	// Copy the design
	var copy = JSON.parse(JSON.stringify(designData));
	
	// Restore the items removed before stringify/parse
	Object.assign(designData, temporaryHold);

	// Assign the copied items
	this.tiling  	= copy.tiling;
	this.frame   	= copy.frame;
	this.general 	= copy.general;
	this.image	 	= copy.image;
	this.diags   	= copy.diags;
	this.drillholes = copy.drillholes;

    // Line data
    this.elements = copy.elements;

    // Color palette
    this.colors = copy.colors;
    
    // Sketch data, 2021.07.04
    this.sketchData = copy.sketchData;
    
    // Gradients, 2021.07.04
    this.gradients = copy.gradients;

    // Misc
    this.nextElementId = 0;
    this.dirty = true;
    
    this.ResetNextElementId();
	
}

ScreenDesignerData.prototype.RestoreDefaults = function()
{
	var defaults = new ScreenDesignerData(this.designType);

	// Data to preserve (also the elements data, 
	// but we are not touching that here)
	var shape    = this.tiling.shape;
	var size     = this.tiling.size;
	
	// Copy the defaults
	this.tiling  	= defaults.tiling;
	this.frame   	= defaults.frame;
	this.general 	= defaults.general;
	this.image 		= defaults.image;
	this.diags   	= defaults.diags;
	this.drillholes = defaults.drillholes;
	
	// Copy the important data
	this.tiling.shape = shape;
	this.tiling.size = size;	
}

ScreenDesignerData.prototype.ClearLines = function()
{
	this.elements = [];
	this.ResetNextElementId();
}

ScreenDesignerData.prototype.AddMissingProperties = function()
{
	// Add any missing properties to a ScreenDesignerData object. 
	// This is called when files are opened/loaded to insure that all of the 
	// properties are present, especially newer properties that were created
	// after the file was saved.
	var ref = new ScreenDesignerData(this.designType);
	var settingsGroups = ["frame", "tiling", "general", "image", "diags", "drillholes"];
	var addingAspectRatio = false; // 2018.08.07: Temporary
	
	for (var i = 0; i < settingsGroups.length; i++)
	{
		var group = settingsGroups[i];
		
		if (this[group] == undefined)
		{
// 			console.log("ScreenDesignerData: Adding group: '" + group + "'");
			this[group] = {};
		}
		
		for (var property in ref[group])
		{
			if (ref[group].hasOwnProperty(property) && this[group][property] == undefined)
			{
				/* console.log("ScreenDesignerData: Adding setting: '" + group + "." + property + "'"); */
				// 2022.02.03: Copy the edge line settings for to missing center line and lattice line settings
				if (group == "general" && (property == "centerWidth" || property == "latticeWidth" || property == "midLineWidth"))
					this[group][property] = this[group]["lineWidth"];
				else if (group == "general" && (property == "centerColor" || property == "latticeColor" || property == "midLineColor"))
					this[group][property] = this[group]["lineColor"];
				else
					this[group][property] = ref[group][property];
				
				if (group == "frame" && property == "aspect")
					addingAspectRatio = true;
			}
		}		
	}
	
	// 2018.08.07: (Temporary) Convert "golden rhombus" frame to "diamond" frame with locked aspect ratio of 1.618034 
	if (this.frame.shape == FrameShape.FRAME_DIAMOND && addingAspectRatio)
	{
		this.frame.aspect = 1.618034;
		this.frame.lockAspect = true;
		this.frame.height = this.frame.radius;
		this.frame.width  = this.frame.radius * 1.618034;
	}
	
	// 2021.05.12: Sketches
	if (this.sketchData == undefined)
		this.sketchData = [];
		
	// 2021.07.02: Gradients
	if (this.gradients == undefined)
		this.gradients = [];
		
	if (this.gradients.length == 0)
		this.gradients.push(Gradient.Create());
				
	// 2021.07.02: TEMPORARY
	if (this.general.gradientEnable)
	{
		this.gradients[0].enable = true;

		this.gradients[0].colorA = this.general.gradientColorA;
		this.gradients[0].colorB  = this.general.gradientColorB
		
		if (this.general.gradientStyle == "topToBottom")
		{
			this.gradients[0].style = Gradient.Style.LINEAR;
			this.gradients[0].angle = 90;
		}
		else if (this.general.gradientStyle == "leftToRight")
		{
			this.gradients[0].style = Gradient.Style.LINEAR;
		}
		else if (this.general.gradientStyle == "circular")
		{
			this.gradients[0].style = Gradient.Style.CIRCULAR;
		}
		
		if (this.general.gradientRamp == "linear")
			this.gradients[0].ramp = Gradient.Ramp.LINEAR;
		else if (this.general.gradientRamp == "sine")
			this.gradients[0].ramp = Gradient.Ramp.SINE;
	}

	delete this.general.gradientEnable;
	delete this.general.gradientColorA;
	delete this.general.gradientColorB;
	delete this.general.gradientStyle;
	delete this.general.gradientRamp;
};


//---------------------------------------------------------------------------
//	 Screen Designer Data: Remove Deprecated Properties
//		2022.04.13: Added after finding out that some designs were over 10Meg
//---------------------------------------------------------------------------
ScreenDesignerData.prototype.RemoveDeprecatedProperties = function()
{
	let modified = false;
	const deprecatedProperties = ["imageData", "processedData"];

	deprecatedProperties.forEach(prop => {
		if (this[prop] != undefined)
		{
			console.log("RemoveDeprecatedProperties: " + prop);
			delete this[prop];
			modified = true;
		}
	});

	//let jsonLength = JSON.stringify(this).length;
	//if (jsonLength > 100*1000)
	//{
	//	console.log("length:" + jsonLength);
	//	let keys = Object.keys(this);
	//	for (var i = 0; i < keys.length; i++)
	//	{
	//		let key = keys[i];
	//		let propertyLength = JSON.stringify(this[key]).length;
	//		if (propertyLength > 100*1000)
	//			console.log(key, propertyLength);
	//	}
	//}

	return modified
}

/*-----------------------------------------------*
 * ScreenDesignerWorkbook
 *
 *		
 *	
 *-----------------------------------------------*/
function ScreenDesignerWorkbook () {
	this.designs = [];
	this.nextID = 1;
	this.dirty = false;

	// Identify this object type in objects reconstructed from JSON strings
	this.reserved = ScreenDesignerDataType.WORKBOOK_DATA;
	
}


/*-----------------------------------------------*
 * ScreenGenerator
 *
 *		
 *	
 *-----------------------------------------------*/
 
var ScreenGenerationType = Object.freeze({
	SINGLE_TILE			: 0,
	MINIMAL_DESIGN		: 1,
	COMPLETE_DESIGN		: 2,
	NULL_DESIGN			: 3 // 2018.01.11: Added. Used to bypass all work, but still use APIs
});


	// Steps for Image:
	//   Initialize
	//   Calc Frame
	//   Calc Tile Locations
	//   Create Tile Outlines
	//   Calc Scan Lines
	//   Scan Image (Image adjustments and repositioning should start here)
	//   Finalize


var ScreenGenOp = Object.freeze({
 	INITIALIZE				:  0, // Create initial objects
	CALC_FRAME				:  1, // Compute points for the frame
	CALC_TILE_LOCATIONS		:  2, // Create the list of tiles
	CREATE_TILE_OUTLINES	:  3, // Calculate all of the tile outlines
	ADD_FRAME_CLIP_POLY		:  4, // Add the frame as the clip polygon
	ADD_FRAME_LINES			:  5, // Add the lines of the frame to the line list
	CALC_DESIGN_LINES		:  6, // Add the lines of the design
	CALC_SEGMENT_LIST		:  7, // Move lines from the line list to the segment list
	CALC_POLYLIST			:  8,
	CALC_OFFSET_POLYLIST	:  9,
	POST_PROCESS_POLYLIST	: 10,
	
	GET_IMAGE_DATA			: 11, // Convert JPEG or PNG to image pixels (raw RGB data)
	CALC_SCAN_LINES			: 12, // Calc horizontal pixel lines in each polygon
	SCAN_IMAGE				: 13, // Read image pixels and average

	CALC_SKETCH_LINES		: 15, // Add the lines for a sketch
	CALC_SKETCH_SEG_LIST	: 16,
		
	UPDATE_CALCS			: 20, // Calcs performed after phase 1; 2021.03.20

	FINALIZE				: 29
});

var ScreenDesignDataChangeType = Object.freeze({
	FRAME		: 0,
	LINES		: 1,
	CURVES		: 2,
	LINE_WIDTH	: 3
});

var SGDirection = Object.freeze({
	FORWARD		: 0,
	REVERSE		: 1
});



var ScreenGeneratorState = Object.freeze({
	INITIAL					:  0,
	NEED_TILE_LOCATIONS		:  1,
	NEED_TILE_POLYLIST		:  2,
	NEED_TO_ADD_FRAME		:  3,
	NEED_TO_CALC_DESIGN		:  4,
	PHASE_1_DONE			:  5,	// 2021.03.12: All states less than this are part of phase 1
	NEED_TO_CALC_SEGMENTS	:  6,
	NEED_TO_CALC_CTRPOLY	:  7,
	NEED_TO_CALC_OFFPOLY	:  8,
	NEED_TO_POSTPROCESS		:  9,
	NEED_TO_FINALIZE		:  10,
	
	NEED_SCAN_LINES			:  11,
	NEED_IMAGE_DATA			:  12,
	PENDING_IMAGE_DATA		:  13,
	NEED_TO_SCAN_IMAGE		:  14,
	
	COMPLETE				: 99
});

var sgScreenGenerationId = 1; // 2021.03.11

function ScreenGeneration () {
	this.state = 0;							// ScreenGeneratorState value
	this.nextIdx = 0;						// The next index to process
	this.designData = undefined; 			// ScreenDesignerData object
	this.genType = ScreenGenerationType.COMPLETE_DESIGN;
	this.bounds = undefined;				// Bounds of the design: {min:{x:x, y:y}, max:{x:x, y:y}}
	this.approxBounds = undefined;			// Approximate bounds
	this.minimalRepeatBounds = undefined;	// Bounds of the smallest rectangle enclosing the repeating pattern 
	this.framePoints = []; 					// Array of points: {x:x, y:y}
	this.outerFramePoints = [];				// Outer boundary of the frame, Array of points
	this.innerFramePoints = [];				// Inner boundary of the frame, Array of points
	this.tileLocations = [];				// Array of locations: {x:x, y:y}
	this.tilePolylist = undefined;			// Tile outlines as polygonlist
	this.lineList = [];						// Array of lines: {ptA:{x:x, y:y}, ptB:{x:x, y:y}, offset, tag}
	this.segList = undefined;				// SegmentList
	this.centerPolyList = undefined;		// PolygonList
	this.offsetOrigPolyList = undefined;	// PolygonList; 2020.10.05: This appears to be only used for diags rendering
	this.offsetPolyList = undefined;		// PolygonList
	this.drillHoles = [];					// Array of circles {x:x, y:y, r:radius}, added 2017.12.04
	this.notches = [];						// Array of rectangles, given as four points [{x:x, y:y},...,{x:x, y:y}], added 2018.08.08
	this.imageMisc = {};					// Calculated values to support Image designs; added 2021.03.20
	
	this.sgid = sgScreenGenerationId;  // 2021.03.11
	sgScreenGenerationId++;
}

var ScreenGenerator = (function() {
	
	var ScreenGenerator_Create = function(designData, genType)
	{
		// Create a generator data object (a "generation")
		// 
		var generation = new ScreenGeneration();
// 		if (designData.designType == DesignType.TYPE_IMAGE)
// 			console.log(generation.sgid + ": new ScreenGeneration");

		// 2018.12.12: Adjust the design when rendering for the edit canvas
		if (genType == ScreenGenerationType.MINIMAL_DESIGN)
			designData = ScreenGenerator_CloneDesignForEditRender(designData);
			
		generation.designData = designData;
		generation.state = ScreenGeneratorState.INITIAL;
		generation.genType = genType;

		ScreenGenerate_DoInitialCalcs(generation);
		
		return generation;
	}

	var ScreenGenerator_CloneDesignForEditRender = function(designData)
	{
		// 2018.12.12: Clear the rotation and translation before rendering for
		// the edit canvas. Also adjust the centering.
		var renderData = designData.Clone();
		renderData.tiling.rotation = 0;
		renderData.tiling.offsetX = 0;
		renderData.tiling.offsetY = 0;
		renderData.tiling.centered = (renderData.tiling.shape == Tiling.HEXGRID || renderData.tiling.shape == Tiling.MANDALA);
		
		return renderData;
	}
	
	
	var ScreenGenerator_DesignDataChanged = function(generation, changeType)
	{
		// Change the state of the "generation" object in response to a "data changed"
		// event. The type of data that changed will determine how much needs to be
		// recalculated
		// 2020.11.30: Differentiating between Tiling and Image
		 
		if (generation.designData.designType == DesignType.TYPE_REGULAR_TILING)
		{
			if (1 /* changeType == 0 */)
			{
				generation.state = ScreenGeneratorState.INITIAL;
				ScreenGenerate_DoInitialCalcs(generation);
			}
			else
			{
				console.log("ScreenGenerator_DesignDataChanged: unknown changeType:" + changeType);
			}
		}	
		else if (generation.designData.designType == DesignType.TYPE_IMAGE)
		{
			if (changeType == 2 && generation.state < ScreenGeneratorState.PHASE_1_DONE)
				console.log("changeType == 2 && generation.state < ScreenGeneratorState.PHASE_1_DONE");
				
			// 2021.03.12: We may be called with changeType 2 when we have started or completed the first phase
			if (changeType == 1 || generation.state < ScreenGeneratorState.PHASE_1_DONE)
			{
				generation.state = ScreenGeneratorState.INITIAL;
				ScreenGenerate_DoInitialCalcs(generation);
			}
			else if (changeType == 2)
			{
				generation.state = ScreenGeneratorState.PHASE_1_DONE;
			}
			else
			{
				console.log("ScreenGenerator_DesignDataChanged: unknown changeType:" + changeType);
			}
		}
		else if (generation.designData.designType == DesignType.TYPE_GRID_SKETCH)
		{
			if (1 /* changeType == 0 */)
			{
				generation.state = ScreenGeneratorState.INITIAL;
				ScreenGenerate_DoInitialCalcs(generation);
			}
		}
	}

	var ScreenGenerator_IsComplete = function(generation)
	{
		// Returns true if the design is completely generated
		//
		return (generation.state == ScreenGeneratorState.COMPLETE);
	}

	var ScreenGenerator_EndOfPhase = function(generation)
	{
		// Returns true if the design has completed a "phase" of the rendering.
		// A "phase" is any collection of states after which a pause is reasonable
		//
		return (generation.state == ScreenGeneratorState.PHASE_1_DONE) ||
				 (generation.state == ScreenGeneratorState.COMPLETE);
	}

	var ScreenGenerator_PhaseAdvance = function(generation)
	{
		// If at the end of a phase, then advance the state
		//
		if (ScreenGenerator_EndOfPhase(generation))
			ScreenGenerator_Advance(generation, 0);
		else
			console.log("ScreenGenerator_PhaseAdvance: not at end of phase. State:" + generation.state);
	}

	var ScreenGenerator_Generate = function(generation)
	{
		// Generate a complete design and return when done.
		// Calling this is not recommended since it may lock up the browser
		// for an overly complicated or large design
		//
		while (!ScreenGenerator_IsComplete(generation))
			ScreenGenerator_Advance(generation, 10);
	}

	var ScreenGenerator_Advance = function(generation, stepCount)
	{
		if (generation.designData.designType == DesignType.TYPE_REGULAR_TILING)
			ScreenGenerator_Tiling_Advance(generation, stepCount);
			
		else if (generation.designData.designType == DesignType.TYPE_IMAGE)
		{
			ScreenGenerator_Image_Advance(generation, stepCount);
			/*
			//console.log("in state", generation.state, "step count", stepCount);
			stepCount = 10;
			var count = 0;
			var limit = 500;
			while (generation.state != ScreenGeneratorState.COMPLETE && count < limit)
			{
				count++;
				ScreenGenerator_Image_Advance(generation, stepCount);
			}
			*/
		}
		else if (generation.designData.designType == DesignType.TYPE_GRID_SKETCH)
			ScreenGenerator_Sketch_Advance(generation, stepCount);
			
		else
			ScreenGenerator_Tiling_Advance(generation, stepCount);
	}
	
	var ScreenGenerator_Tiling_Advance = function(generation, stepCount)
	{
		// Advance the design by "stepCount". The state will advance 
		// as needed. This will always return when transitioning to
		// a new state.
		//
		var startState = generation.state;
		var nextState = undefined;
		var done = false;
		
		if (generation == undefined)
		{
			console.log("ScreenGenerator_Advance: generation is undefined");
		}
		else if (generation.genType == ScreenGenerationType.NULL_DESIGN) // 2018.01.11: Added
		{
			nextState = ScreenGeneratorState.COMPLETE;
		}
		else if (generation.state == ScreenGeneratorState.INITIAL)
		{
			nextState = ScreenGeneratorState.NEED_TILE_LOCATIONS;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TILE_LOCATIONS)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_TILE_LOCATIONS);
			if (done)
				nextState = ScreenGeneratorState.NEED_TILE_POLYLIST;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TILE_POLYLIST)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CREATE_TILE_OUTLINES);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_ADD_FRAME;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_ADD_FRAME)
		{
			// Add the clip polygon for everything except "full tiles", ie, non-clipped tiles
			if (generation.designData.frame.render != FrameRender.FRAME_FULL_TILES)
				ScreenGenerator_Do(generation, stepCount, ScreenGenOp.ADD_FRAME_CLIP_POLY);
				
			ScreenGenerator_Do(generation, stepCount, ScreenGenOp.ADD_FRAME_LINES);
			generation.state = ScreenGeneratorState.NEED_TO_CALC_DESIGN;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_DESIGN)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_DESIGN_LINES);
			if (done)
				nextState = ScreenGeneratorState.PHASE_1_DONE;
		}
		else if (generation.state == ScreenGeneratorState.PHASE_1_DONE)
		{
			nextState = ScreenGeneratorState.NEED_TO_CALC_SEGMENTS;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_SEGMENTS)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_SEGMENT_LIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_CALC_CTRPOLY;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_CTRPOLY)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_POLYLIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_CALC_OFFPOLY;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_OFFPOLY)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_OFFSET_POLYLIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_POSTPROCESS;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_POSTPROCESS)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.POST_PROCESS_POLYLIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_FINALIZE;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_FINALIZE)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.FINALIZE);
			if (done)
				nextState = ScreenGeneratorState.COMPLETE;
		}
		else
		{
			console.log("ScreenGenerator_Advance: unknown state:" + generation.state);
		}
		
		// Transition state if necessary
		if (nextState != undefined)
		{
			if (nextState == startState)
				console.log("ScreenGenerator_Advance: transition state is start state:" + nextState);
			
			generation.state = nextState;
			generation.nextIdx = 0;
		}
	}
	
	var ScreenGenerator_Image_Advance = function(generation, stepCount)
	{
		// INITIAL					>>
		// NEED_TILE_LOCATIONS		>>
		// NEED_TILE_POLYLIST		>>
		// NEED_TO_ADD_FRAME		>>
		// NEED_SCAN_LINES			>>
		// PHASE_1_DONE				>>
		// NEED_IMAGE_DATA			>> 2021.03.11
		// PENDING_IMAGE_DATA		>> 2021.03.11
		// NEED_TO_SCAN_IMAGE		>>
		// NEED_TO_FINALIZE			>>


		// Advance the design by "stepCount". The state will advance 
		// as needed. This will always return when transitioning to
		// a new state.
		//
		var startState = generation.state;
		var nextState = undefined;
		var done = false;
		
		if (generation == undefined)
		{
			console.log("ScreenGenerator_Advance: generation is undefined");
		}
		else if (generation.genType == ScreenGenerationType.NULL_DESIGN) // 2018.01.11: Added
		{
			nextState = ScreenGeneratorState.COMPLETE;
		}
		else if (generation.state == ScreenGeneratorState.INITIAL)
		{
			nextState = ScreenGeneratorState.NEED_TILE_LOCATIONS;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TILE_LOCATIONS)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_TILE_LOCATIONS);
			if (done)
				nextState = ScreenGeneratorState.NEED_TILE_POLYLIST;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TILE_POLYLIST)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CREATE_TILE_OUTLINES);
			if (done)
				nextState = ScreenGeneratorState.NEED_SCAN_LINES;
		}
		else if (generation.state == ScreenGeneratorState.NEED_SCAN_LINES)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_SCAN_LINES);
			if (done)
				nextState = ScreenGeneratorState.PHASE_1_DONE;
		}
		else if (generation.state == ScreenGeneratorState.PHASE_1_DONE)
		{
			ScreenGenerator_Do(generation, 0, ScreenGenOp.UPDATE_CALCS); // 2021.03.20
			
			//console.log(generation.sgid + ": PHASE_1_DONE");
			// If we already have imageData, then we go to scan image
			if (generation.designData.imageData != undefined)
				nextState = ScreenGeneratorState.NEED_TO_SCAN_IMAGE;
			// If we don't have an image URL, then we can't get image data
			else if (generation.designData.imageDataURL == undefined)
				nextState = ScreenGeneratorState.NEED_TO_SCAN_IMAGE;
			// Otherwise we have to get the image data
			else
				nextState = ScreenGeneratorState.NEED_IMAGE_DATA;
		}
		else if (generation.state == ScreenGeneratorState.NEED_IMAGE_DATA)
		{
			//console.log(generation.sgid + ": NEED_IMAGE_DATA");
			ScreenGenerator_Do(generation, stepCount, ScreenGenOp.GET_IMAGE_DATA);
			nextState = ScreenGeneratorState.PENDING_IMAGE_DATA;
		}
		else if (generation.state == ScreenGeneratorState.PENDING_IMAGE_DATA)
		{
			// 2021.03.11. Wait for the image to load in another thread
			// I am worried that this might not be a good way to solve this. We
			// are relying on the other thread to complete
			if (generation.designData.imageData != undefined)
			{
				//console.log(generation.sgid + ": Pending image data ... resolved");
				nextState = ScreenGeneratorState.NEED_TO_SCAN_IMAGE;
			}
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_SCAN_IMAGE)
		{
			//if (generation.nextIdx == 0) console.log(generation.sgid + ": NEED_TO_SCAN_IMAGE");
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.SCAN_IMAGE);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_FINALIZE;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_FINALIZE)
		{
			//console.log(generation.sgid + ": NEED_TO_FINALIZE");
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.FINALIZE);
			if (done)
				nextState = ScreenGeneratorState.COMPLETE;
		}
		else
		{
			console.log("ScreenGenerator_Advance: unknown state:" + generation.state);
		}
		
		// Transition state if necessary
		if (nextState != undefined)
		{
			if (nextState == startState)
				console.log("ScreenGenerator_Advance: transition state is start state:" + nextState);
			
			generation.state = nextState;
			generation.nextIdx = 0;
		}
	}
	
	var ScreenGenerator_Sketch_Advance = function(generation, stepCount)
	{
		// Advance the design by "stepCount". The state will advance 
		// as needed. This will always return when transitioning to
		// a new state.
		//
		var startState = generation.state;
		var nextState = undefined;
		var done = false;
		
		if (generation == undefined)
		{
			console.log("ScreenGenerator_Advance: generation is undefined");
		}
		else if (generation.genType == ScreenGenerationType.NULL_DESIGN) // 2018.01.11: Added
		{
			nextState = ScreenGeneratorState.COMPLETE;
		}
		else if (generation.state == ScreenGeneratorState.INITIAL)
		{
			nextState = ScreenGeneratorState.NEED_TILE_LOCATIONS;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TILE_LOCATIONS)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_TILE_LOCATIONS);
			if (done)
				nextState = ScreenGeneratorState.NEED_TILE_POLYLIST;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TILE_POLYLIST)
		{
			// 2021.08.30: No longer 'NEED_TO_ADD_FRAME'. Going directly to 'NEED_TO_CALC_DESIGN'
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CREATE_TILE_OUTLINES);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_CALC_DESIGN;
		}
// 		else if (generation.state == ScreenGeneratorState.NEED_TO_ADD_FRAME)
// 		{
// 			// Add the clip polygon for everything except "full tiles", ie, non-clipped tiles
// 			if (generation.designData.frame.render != FrameRender.FRAME_FULL_TILES)
// 				ScreenGenerator_Do(generation, stepCount, ScreenGenOp.ADD_FRAME_CLIP_POLY);
// 				
// 			ScreenGenerator_Do(generation, stepCount, ScreenGenOp.ADD_FRAME_LINES);
// 			generation.state = ScreenGeneratorState.NEED_TO_CALC_DESIGN;
// 		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_DESIGN)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_SKETCH_LINES);
			if (done)
				nextState = ScreenGeneratorState.PHASE_1_DONE;
		}
		else if (generation.state == ScreenGeneratorState.PHASE_1_DONE)
		{
			nextState = ScreenGeneratorState.NEED_TO_CALC_SEGMENTS;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_SEGMENTS)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_SKETCH_SEG_LIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_CALC_CTRPOLY;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_CTRPOLY)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_POLYLIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_CALC_OFFPOLY;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_CALC_OFFPOLY)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.CALC_OFFSET_POLYLIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_POSTPROCESS;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_POSTPROCESS)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.POST_PROCESS_POLYLIST);
			if (done)
				nextState = ScreenGeneratorState.NEED_TO_FINALIZE;
		}
		else if (generation.state == ScreenGeneratorState.NEED_TO_FINALIZE)
		{
			done = ScreenGenerator_Do(generation, stepCount, ScreenGenOp.FINALIZE);
			if (done)
				nextState = ScreenGeneratorState.COMPLETE;
		}
		else
		{
			console.log("ScreenGenerator_Advance: unknown state:" + generation.state);
		}
		
		// Transition state if necessary
		if (nextState != undefined)
		{
			if (nextState == startState)
				console.log("ScreenGenerator_Advance: transition state is start state:" + nextState);
			
			generation.state = nextState;
			generation.nextIdx = 0;
		}
	}

	var ScreenGenerator_Do = function(generation, stepCount, genOp)
	{
		// Dispatch routine for the generator operations. This is probably
		// overkill, but it does allow for a consistent interface for the routine
		// that manages the state changes
		//
		var done = true;

		if (genOp == ScreenGenOp.INITIALIZE)
		{
			ScreenGenerate_DoInitialCalcs(generation);
		}
		else if (genOp == ScreenGenOp.UPDATE_CALCS) // 2021.03.20
		{
			ScreenGenerate_DoUpdateCalcs(generation);
		}
		else if (genOp == ScreenGenOp.CALC_FRAME)
		{
		}
		else if (genOp == ScreenGenOp.CALC_TILE_LOCATIONS)
		{
			if (generation.genType == ScreenGenerationType.COMPLETE_DESIGN)
			{
				// 2017.11.13: Changed from 'framePoints' to 'innerFramePoints'
				// 2021.07.15: Changed parameters so stepCount can be provided to prevent lock-up
				// 2021.07.15: Also use a larger stepCount so in most cases we only call this once.
				let getTileStepCount = stepCount * 1000;
				done = ScreenGenerator_GenerateTileLocationList(generation, generation.innerFramePoints, generation.nextIdx, getTileStepCount);
				generation.nextIdx += getTileStepCount;
			}
			else
				generation.tileLocations = ScreenGenerator_GenerateMinimalTileLocationList(generation);

		}
		else if (genOp == ScreenGenOp.CREATE_TILE_OUTLINES)
		{
			done = ScreenGenerator_GenerateTilePolygons(generation, generation.nextIdx, stepCount);
			generation.nextIdx += stepCount;
		}
		else if (genOp == ScreenGenOp.ADD_FRAME_CLIP_POLY)
		{
			if (generation.genType == ScreenGenerationType.COMPLETE_DESIGN)
				SegmentList.AddClipPolygon(generation.segList, generation.framePoints); // 2017.11.13: was framePoints
		}
		else if (genOp == ScreenGenOp.ADD_FRAME_LINES)
		{
			if (generation.genType == ScreenGenerationType.COMPLETE_DESIGN)
				ScreenGenerator_LineList_AddFrame(generation);
		}
		else if (genOp == ScreenGenOp.CALC_DESIGN_LINES)
		{
			done = ScreenGenerator_LineList_AddTileLines(generation, generation.nextIdx, stepCount);
			generation.nextIdx += stepCount;
		}
		else if (genOp == ScreenGenOp.CALC_SKETCH_LINES)
		{
			done = ScreenGenerator_LineList_AddSketchLines(generation, generation.nextIdx, stepCount);
			generation.nextIdx += stepCount;
		}
		else if (genOp == ScreenGenOp.CALC_SEGMENT_LIST)
		{
			done = ScreenGenerator_SegList_AddLineList(generation, generation.nextIdx, stepCount);
			generation.nextIdx += stepCount;
		}
		else if (genOp == ScreenGenOp.CALC_SKETCH_SEG_LIST)
		{
			done = ScreenGenerator_SegList_AddSketchLineList(generation, generation.nextIdx, stepCount);
			generation.nextIdx += stepCount;
		}
		else if (genOp == ScreenGenOp.CALC_POLYLIST)
		{
			generation.centerPolyList = SegmentList.GeneratePolygonList(generation.segList);
			generation.centerPolyList.CombineParallelAdjacentLines();
		}
		else if (genOp == ScreenGenOp.CALC_OFFSET_POLYLIST)
		{
			var options = {endCapStyle: generation.designData.tiling.endCaps, cleanUpReversals:true};

			if (generation.designData.tiling.useLineColors)
				options.buildAreaPolygons = true;

			if (generation.designData.tiling.enableLattice) // 2022.02.01: Added
			{
				options.constructLattice = true;
				options.latticeOffset = generation.designData.general.latticeOffset;
				options.centerLineClipToLattice = generation.designData.general.clipCenterToLattice; // 2022.03.03
				options.midLineClipToLattice = generation.designData.general.clipMidLineToLattice; // 2022.03.03
			}
			// 2022.03.04
			options.renderMidLine = generation.designData.general.renderMidLine; 
			options.midLineLocScale = generation.designData.general.midLineLocScale;
			options.midLineLocOffset = generation.designData.general.midLineLocOffset;

			generation.offsetPolyList = generation.centerPolyList.CalcOffsetPolygon(generation.designData.frame.border/2, options);

			// 2020.10.05: This appeared to be only used for diags rendering. I'm not sure why it was needed instead of the offset
			// polygon computed in the previous line.
			//var options = {endCapStyle: generation.designData.tiling.endCaps};
			//generation.offsetOrigPolyList = generation.centerPolyList.CalcOffsetPolygon(generation.designData.frame.border/2, options);
		}
		else if (genOp == ScreenGenOp.POST_PROCESS_POLYLIST)
		{
			ScreenGenerator_PostProcessPolygonList(generation.designData, generation.offsetPolyList);
		}
		else if (genOp == ScreenGenOp.GET_IMAGE_DATA)
		{
			// If we already have image data, then we do not have to do anything
			if (generation.designData.imageData != undefined)
			{
				done = true;
			}
			// If we do not have a URL to get data, then there is nothing we can do
			else if (generation.designData.imageDataURL == undefined)
			{
				done = true;
			}
			// Initiate loading the URL
			else
			{
				ScreenGenerator_GetImageData_Initiate(generation);
				done = false;
			}
		}
		else if (genOp == ScreenGenOp.CALC_SCAN_LINES)
		{
			done = ScreenGenerator_CalcScanLines(generation, generation.nextIdx, stepCount);
			generation.nextIdx += stepCount;
		}
		else if (genOp == ScreenGenOp.SCAN_IMAGE)
		{
			done = ScreenGenerator_ScanImage(generation, generation.nextIdx, stepCount);
			generation.nextIdx += stepCount;
		}
		else if (genOp == ScreenGenOp.FINALIZE)
		{
			generation.bounds = ScreenGenerator_CalcFinalBounds(generation);
		}
		else
		{
			console.log("ScreenGenerator_Do: unknown genOp:" + genOp);
		}
		
		return done;
	}
	
	//------------------------------------------------------------------------------
	//	ScreenGenerator: Get Image Data: Initiate
	//------------------------------------------------------------------------------
	var ScreenGenerator_GetImageData_Initiate = function(generation)
	{
		// Create a new image to hold the scaled data
		generation.fileImage = new Image();
		generation.fileImage.addEventListener("load", evt => ScreenGenerator_GetImageData_Complete(generation));
		generation.fileImage.src = generation.designData.imageDataURL;
	}
	
	//------------------------------------------------------------------------------
	//	ScreenGenerator: Get Image Data: Complete
	//------------------------------------------------------------------------------
	var ScreenGenerator_GetImageData_Complete = function(generation)
	{
		let nWidth  = generation.fileImage.naturalWidth;
		let nHeight = generation.fileImage.naturalHeight;
		
		let scale = generation.designData.image.imgZoom;
		let width  = nWidth  * scale;
		let height = nHeight * scale;
		let offsetX = generation.designData.image.imgOffsetX;
		let offsetY = generation.designData.image.imgOffsetY;
		
		// These values are based on the size of the canvas in the DOM.
		// These should be replaced with something centrally defined
		let cWidth = 350;
		let cHeight = 350;
		
		//console.log("Screen generator: " + scale.toFixed(2) +", " + offsetX.toFixed(2) + ", " + offsetY.toFixed(2));
		
		generation.imageCanvas = document.createElement("canvas");
		generation.imageCanvas.width = cWidth;
		generation.imageCanvas.height = cHeight;
		generation.imageContext = generation.imageCanvas.getContext("2d");
		
		generation.imageContext.clearRect(0, 0, width, height);
		generation.imageContext.drawImage(generation.fileImage, offsetX, offsetY, width, height);
		
		generation.designData.imageData = generation.imageContext.getImageData(0, 0, cWidth, cHeight);
		
		// Clear the object we no longer need
		generation.fileImage = undefined;
		generation.imageCanvas = undefined;
		generation.imageContext = undefined;
	}
	
	//------------------------------------------------------------------------------
	//	ScreenGenerator: Do Initial Calcs
	//------------------------------------------------------------------------------
	var ScreenGenerate_DoInitialCalcs = function(generation)
	{
		// 2021.03.26: Prevent out-of-range values, specifically tile sizes that are too small
		generation.designData.Validate();
		
		// Certain information needs to always be available. For the most part, this
		// is the bounds. Since the bounds is computed from the frame, we have that also.
		//
		generation.framePoints = ScreenGenerator_CalcFrameMeasureLinePointList(generation.designData);
		ScreenGenerator_CalcInnerOuterFramePointList(generation);
		generation.approxBounds = ScreenGenerator_CalcApproxOuterBounds(generation.designData);
		generation.bounds = ScreenGenerator_CalcPreliminaryBounds(generation);
		generation.minimalRepeatBounds = ScreenGenerator_GetMinimalRepeatBounds(generation.designData);
		
		generation.tileLocations = [];
		generation.tilePolylist = undefined;
		generation.centerPolyList = undefined;
		generation.offsetPolyList = undefined;
		generation.drillHoles = [];
		generation.notches = [];

		// 2020.11.12: Initializing according to designType
		if (generation.designData.designType == DesignType.TYPE_REGULAR_TILING || generation.designData.designType == undefined)
		{
			generation.lineList = [];
			generation.segList = SegmentList.Create(SegmentIntersectionHandling.SUBDIVIDE_SEGMENTS);
			
		}
		else if (generation.designData.designType == DesignType.TYPE_IMAGE)
		{
			//generation.offsetPolyList = new PolygonList();
			//generation.offsetPolyList.AddPolygonPoints(generation.outerFramePoints);
		}
		else if (generation.designData.designType == DesignType.TYPE_GRID_SKETCH)
		{
			generation.lineList = [];
			generation.segList = SegmentList.Create(SegmentIntersectionHandling.SUBDIVIDE_SEGMENTS);
		}
		else
		{
			console.log("ScreenGenerate_DoInitialCalcs: designType not handled");
		}
		
		// 2017.12.04: Added
		ScreenGenerator_CalcDrillHoles(generation);
		
		// 2018.08.08: Added
		ScreenGenerator_CalcNotches(generation);
		
	}
	
	//------------------------------------------------------------------------------
	//	ScreenGenerator: Do Update Calcs
	//		2021.03.20: Added
	//------------------------------------------------------------------------------
	var ScreenGenerate_DoUpdateCalcs = function(generation)
	{
		if (generation.designData.designType == DesignType.TYPE_IMAGE)
		{
			var tf = ScreenGenerator_GetTesselationObject(generation.designData);
			var tesselationlayout = ScreenGenerator_GetLayout(tf, generation.designData);
			
			let size = generation.designData.tiling.size;
			let minOffset = generation.designData.image.minEdgeDist;
			
			// 2021.04.09: The minOffset needs to be adjusted for some tilings, in
			// particular the triangle and rhombus
			if (tesselationlayout.minOffsetFactor != undefined)
				minOffset *= tesselationlayout.minOffsetFactor;
				
			let minPolySize =  generation.designData.image.minPolySize;			
			let upper = (size - minOffset)/size;
			let lower = minPolySize/size;
			generation.imageMisc.upperClamp = upper;
			generation.imageMisc.lowerClamp = lower;
		}
	}
	
	//function formatPt(p)
	//{
	//	function ns(n) { return ((n <= 0) ? "" : " " ); }
	//	function fn(n) {
	//		let s = ns(n) + n.toFixed(2);
	//		if (Math.abs(n) < 10)
	//			s = " " + s;
	//		return s;
	//	}
	//
	//	let s = fn(p.x) + "," + fn(p.y);
	//	if (p.z != undefined)
	//		s += "," + ns(p.z) + p.z;
	//	else
	//		s += "   "
	//
	//	return s;
	//}
	//
	//function logPts(pA, pB, forward)
	//{
	//	let a = formatPt(pA);
	//	let b = formatPt(pB);
	//	let black = "color:black";
	//	let ac = (pA.z == undefined) ? black : "color:red";
	//	let bc = (pB.z == undefined) ? black : "color:red";
	//	let dir = "";
	//	if (forward != undefined)
	//		dir = (forward ? "F" : "R");
	//	console.log("A: %c" + a + "%c, B: %c" + b + "%c  " + dir, ac, black, bc, black);
	//}

	var ScreenGenerator_LineList_AddPointList = function(lineList, pointList, direction, offset, tag, clipPoly, id = undefined)
	{
		// Add a list of points, with offset and tag, to the line list. Optionally clip the lines to the clipPoly
		//
		var isForward = true;
		if (direction == SGDirection.FORWARD)
			isForward = true;
		else if (direction == SGDirection.REVERSE)
			isForward = false;
		else
			console.log("ScreenGenerator_LineList_AddPointList: invalid direction:" + direction);
			
		// ASSUMPTION!!!
		// MORE THAN TWO POINTS IS A CLOSED POLYGON!!!
		// If the point list only has two points then we treat this as a single line. To do this we
		// use a length of one, indicating that only one segment will be added.
		var len = pointList.length;
		if (len == 2)
			len = 1;
			
		// Add lines to the line list
		for (var i = 0; i < len; i++)
		{
			var ptA = pointList[i];
			var ptB = pointList[(i + 1) % pointList.length];
			var addLine = true;
			
			if (clipPoly != undefined)
			{
				var clipResult = MathUtil.ClipSegmentAgainstPolygon(ptA, ptB, clipPoly);
				if (clipResult != undefined)
				{
					if (clipResult.ignoreSegment)
					{
						addLine = false;
					}
					else
					{
						ptA = clipResult.ptA;
						ptB = clipResult.ptB;
					}
				}
			}
		
			if (addLine)
			{
				var ln = {ptA:(isForward?ptA:ptB), ptB:(isForward?ptB:ptA), offset:offset, tag:tag};

				if (id != undefined)
					ln.id = id;
				lineList.push(ln);
			}
		}
	}
	
	var ScreenGenerator_SegList_AddLineList = function(generation, startIdx, stepCount)
	{
		// Move lines from the lineList to the segment list
		//

		while (generation.lineList.length > 0 && stepCount > 0)
		{
			var ln = generation.lineList.pop();
			SegmentList.AddSegment(generation.segList, ln.ptA, ln.ptB, ln.offset, ln.tag);

			stepCount--;
		}
		
		return (generation.lineList.length == 0);
	}
	
	var ScreenGenerator_SegList_AddSketchLineList = function(generation, startIdx, stepCount)
	{
		// Move lines from the lineList to the segment list
		//

		while (generation.lineList.length > 0 && stepCount > 0)
		{
			var ln = generation.lineList.pop();
			var options = {};
			if (ln.tag.seTag == ScreenElementTag.ELEMENT_SKETCH_OUTSIDE)
				options.removeWithReverse = true;
			else
				options.removeWithReverse = false;			
				
			SegmentList.AddSegment(generation.segList, ln.ptA, ln.ptB, ln.offset, ln.tag, options);
			
			stepCount--;
		}
		
		return (generation.lineList.length == 0);
	}
	
	var ScreenGenerator_AddBoundsToTileInfo = function(tileInfo)
	{
		var minX = tileInfo.points[0].x;
		var minY = tileInfo.points[0].y;
		var maxX = minX;
		var maxY = minY;
		
		for (var i = 1; i < tileInfo.points.length; i++)
		{
			var pt = tileInfo.points[i];
			
			if (pt.x > maxX)
				maxX = pt.x;
			if (pt.x < minX)
				minX = pt.x;
			if (pt.y > maxY)
				maxY = pt.y;
			if (pt.y < minY)
				minY = pt.y;
		}
		
		tileInfo.bounds = {min:{x:minX, y:minY}, max:{x:maxX, y:maxY}, size:{x:(maxX - minX), y:(maxY - minY)}};
	}
	
	/*---------------------------------------------------------------------------------*
	 *	Get Layout
	 *		Retrieves layout and modifies according to frame type
	 *	2020.08.05: Added
	 *---------------------------------------------------------------------------------*/
	var ScreenGenerator_GetLayout = function(tesselation, designData)
	{
		var layout = tesselation.CalcLayout(designData);

		if (designData.frame.shape == FrameShape.FRAME_SINGLE_TILE)
		{
			layout.rotation = 0;
			layout.offsetX = 0;
			layout.offsetY = 0;

			// Hexgrid tiles are centered at the origin, while triangle and square tiles have 
			// the bottom-left corner at the origin.
			layout.centered = (designData.tiling.shape == Tiling.HEXGRID || designData.tiling.shape == Tiling.MANDALA);

		}
		
		return layout;
	}
	
	var ScreenGenerator_LineList_AddSketchLines = function(generation, startIdx, stepCount, options = undefined)
	{
		// Calculate the lines in the design and add them to the line list
		//
		var tesselation = ScreenGenerator_GetTesselationObject(generation.designData);
		var tesselationlayout = ScreenGenerator_GetLayout(tesselation, generation.designData);
		
		let sketchData = generation.designData.sketchData;
		
		// 2021.08.30: Use sketch line width instead of constant
		let lineWidth = generation.designData.general.sketchLineWidth
		
		for (var i = 0; sketchData != undefined && i < sketchData.length; i++)
		{
			let sk = sketchData[i];
			var tileInfo = tesselation.CalcTileInfo(tesselationlayout, sk.x, sk.y);
		
			for (var j = 0; j < tileInfo.points.length; j++)
			{
				var pts = [];
				pts.push(tileInfo.points[j]);
				pts.push(tileInfo.points[(j+1) % tileInfo.points.length]);
			
				let offsetAB = lineWidth/2;
				let offsetBA = lineWidth/2;
				var tagAB = {seTag:ScreenElementTag.ELEMENT_SKETCH_OUTSIDE};
				/*
				if (tileSegment.color != undefined) // 2020.09.02: Color
					tagAB.color = tileSegment.color;
				if (tileSegment.colorId != undefined) // 2020.10.15: Color
					tagAB.colorId = tileSegment.colorId;
				*/
				
				var tagBA = {seTag:ScreenElementTag.ELEMENT_SKETCH_INSIDE};

				if ((sk.draw == 0 || sk.draw == undefined) || ((sk.draw & (1 << j)) != 0))
				{
					ScreenGenerator_LineList_AddPointList(generation.lineList, pts, SGDirection.FORWARD, offsetAB, tagAB, undefined /*clipPoly*/, 0 /* id */);
					if (sk.draw != 0)
						ScreenGenerator_LineList_AddPointList(generation.lineList, pts, SGDirection.REVERSE, offsetBA, tagBA, undefined /*clipPoly*/, 0 /* id */);
				}
			}
		}
		
		// Return true if done		
		return true;
	}

	var ScreenGenerator_LineList_AddTileLines = function(generation, startIdx, stepCount, options = undefined)
	{
		// Calculate the lines in the design and add them to the line list
		//
		var tesselation = ScreenGenerator_GetTesselationObject(generation.designData);
		// 2020.08.05: Use new function that can modify layout
		var tesselationlayout = ScreenGenerator_GetLayout(tesselation, generation.designData);

		var sList = generation.designData.elements;
		
		var baseTile = ScreenGenerator_GetUnitTileInfo(generation.designData);
		
		var addBiDirectional = (options == undefined) ? true : options.addBiDirectional;

		//XXXXX
		//let frameBounds = ScreenGenerator_CalcApproxOuterBounds(generation.designData);
		//let ramp = y => (1.0 - (y - frameBounds.min.y)/(frameBounds.max.y - frameBounds.min.y));
		//XXXXX

		// Iterate over all of the tiles. This look takes the pattern (in the 'elements') list
		// and copies it throughout the render
		var locations = generation.tileLocations;
		for (var k = startIdx; k < locations.length && k < startIdx + stepCount; k++)
		{
			var x = locations[k].x;
			var y = locations[k].y;
			var tileInfo = tesselation.CalcTileInfo(tesselationlayout, x, y);
			ScreenGenerator_AddBoundsToTileInfo(tileInfo);
			
			// 2020.08.20: 
			tileInfo.clipToTile = generation.designData.tiling.clipToTile;
			
			// If the lines from this tile should be clipped, then specified a clip polygon
			// 2017.11.13: Changed from framePoints to innerFramePoints
			/*
			// 2020.10.15: Use framePoints for one case
			var clipFramePoints = generation.innerFramePoints;
			if (generation.designData.frame.measure == FrameMeasure.FRAME_CENTERLINE && generation.designData.general.renderCenter)
				clipFramePoints = generation.framePoints;
			*/
			var clipPoly = (locations[k].clip ? generation.innerFramePoints : undefined);
			
			if (sList != undefined)
			{
				for (var i = 0; i < sList.length; i++)
				{
					var s = sList[i];
					
					// 2019.10.10: Ignore zero-length lines
					var distSq = MathUtil.DistanceSquaredBetween(s.ptA, s.ptB);
					
					if (s.visible && distSq > 0.000001)
					{
						var tileSegment = {};
						
						tileSegment.ptA = {
							x:s.ptA.x * baseTile.square.size + baseTile.square.offset.x - baseTile.center.x, 
							y:s.ptA.y * baseTile.square.size + baseTile.square.offset.y - baseTile.center.y};
							
						tileSegment.ptB = {
							x:s.ptB.x * baseTile.square.size + baseTile.square.offset.x - baseTile.center.x , 
							y:s.ptB.y * baseTile.square.size + baseTile.square.offset.y - baseTile.center.y};
							
						tileSegment.width = s.width;
						
						// 2022.01.26: Copy lattice properties (z-values and behaviors) from design element to tile segment
						let latticeProps = ["wzA", "wzB", "wzL", "wbA", "wbB", "wbL"];
						latticeProps.forEach(p => { if (s[p] != undefined) tileSegment[p] = s[p]; });

						// 2020.09.02: Color
						if (s.color != undefined)
							tileSegment.color = s.color;

						// 2020.10.15: Palette entry
						if (s.colorId != undefined)
							tileSegment.colorId = s.colorId;

						// 2020.08.31: Use new options param and pass new "reflect" setting
						var options = {}
						if (s.reflect != undefined)
							options.reflect = s.reflect;

						var appendList = ScreenGenerator_CalcMirroredRotatedTileSegmentList(tileInfo, tileSegment, s.mirror, s.rotate, options);
		
						for (var j = 0; j < appendList.length; j++)
						{
							var sgm = appendList[j];
							// 2018.06.08: Rotate segments
							if (tesselationlayout.rotation != 0)
								for (var kk = 0; kk < sgm.pts.length; kk++)
									sgm.pts[kk] = Object.assign(sgm.pts[kk], MathUtil.RotatePointAroundOrigin(sgm.pts[kk], tesselationlayout.rotation));

							//XXXXX
							//for (var kk = 0; kk < sgm.pts.length; kk++)
							//{
							//	let r = ramp(sgm.pts[kk].y);
							//	sgm.offsetAB *= (1 + r);
							//	sgm.offsetBA *= (1 + r);
							//}
							//XXXXX

							ScreenGenerator_LineList_AddPointList(generation.lineList, sgm.pts, SGDirection.FORWARD, sgm.offsetAB, sgm.tagAB, clipPoly, s.id);
							if (addBiDirectional)
								ScreenGenerator_LineList_AddPointList(generation.lineList, sgm.pts, SGDirection.REVERSE, sgm.offsetBA, sgm.tagBA, clipPoly, s.id);
						}
					}
				}
			}
		}

		// Return true if done		
		return (startIdx + stepCount >= generation.tileLocations.length);
	}
	
	var ScreenGenerator_GetTesselationObject = function(screenData)
	{
		var tf = HexgridTesselation;
		
		if (screenData.tiling.shape == Tiling.TRIGRID)
			tf = TrigridTesselation;
		else if (screenData.tiling.shape == Tiling.HEXGRID)
			tf = HexgridTesselation;
		else if (screenData.tiling.shape == Tiling.SQUARES)
			tf = SquareTesselation;
		else if (screenData.tiling.shape == Tiling.SQUARES_OFFSET)
			tf = SquareOffsetTesselation;
		else if (screenData.tiling.shape == Tiling.MANDALA)
			tf = MandalaTesselation;
		else if (screenData.tiling.shape == Tiling.BRICK)
			tf = BrickTesselation;
		else if (screenData.tiling.shape == Tiling.RHOMBUS)
			tf = RhombusTesselation;
		else if (screenData.tiling.shape == Tiling.RECTANGLE || (screenData.tiling.shape % 100) == Tiling.RECTANGLE)
			tf = RectangleTesselation;
		else
			tf = SquareTesselation;
			
		return tf;
	}
	
	var ScreenGenerator_CalcApproxOuterBounds = function(screenData)
	{
		// Find the bounds that enclose everything. This is primarily
		// used to determine tiles to possibly include in the render
		//
		var bounds = {min:{x:0, y:0}, max:{x:0, y:0}};

		// 2016.06.07: Use the actual frame points to find the bounds
		var framePoints = ScreenGenerator_CalcFrameMeasureLinePointList(screenData);
		
		var minCB = (min, cur) => Math.min(min, cur);
		var maxCB = (min, cur) => Math.max(min, cur);
		
		bounds.min.x = framePoints.map( pt => pt.x ).reduce(minCB);
		bounds.min.y = framePoints.map( pt => pt.y ).reduce(minCB);
		bounds.max.x = framePoints.map( pt => pt.x ).reduce(maxCB);
		bounds.max.y = framePoints.map( pt => pt.y ).reduce(maxCB);
				
		return bounds;
	}
	
	var ScreenGenerator_CalcInnerOuterFramePointList = function(generation)
	{
		// Calculate the points for the outer frame edge. This is used
		// for calculating the bounds and for showing the frame edge while
		// the data is being rendered.
		//
		// Organize the frame points so that we have a "polygon list"
		// This data will not be modified by the call below to CalcOffsetPolygon
		var framePolylist = new PolygonList();
		
		framePolylist.AddPolygonPoints(generation.framePoints);
		
		var offsets = ScreenGenerator_GetInnerOuterFrameOffsets(generation.designData);
		
		// Increase the size of the frame polylist according to the frame settings
		var outerFramePolylist = framePolylist.CalcOffsetPolygon(offsets.outer);
		generation.outerFramePoints = outerFramePolylist.GetPolygonPoints(0);

		var innerFramePolylist = framePolylist.CalcOffsetPolygon(-offsets.inner);
		generation.innerFramePoints = innerFramePolylist.GetPolygonPoints(0);
	}
	
	var ScreenGenerator_CalcPreliminaryBounds = function(generation)
	{
		// Calculate the bounds of the image.
		//
		var b = {min:{x:0, y:0}, max:{x:0, y:0}};
		var lw = 0;
		var m = 0;
		
		if (generation.designData.frame.fixedBounds)
		{
			b.min.x = -generation.designData.frame.docWidth/2;
			b.min.y = -generation.designData.frame.docHeight/2;
			b.max.x =  generation.designData.frame.docWidth/2;
			b.max.y =  generation.designData.frame.docHeight/2;
		}
		else
		{
			b = Polygon_FindBounds(generation.outerFramePoints);
			
			// Prevent the line from being clipped
			if (generation.designData.frame.margin < generation.designData.general.lineWidth)
				lw = generation.designData.general.lineWidth;
				
			// Add in the margin
			m = generation.designData.frame.margin;
			
			if (typeof generation.designData.frame.margin != 'number')
				console.log("ScreenGenerator_CalcPreliminaryBounds: generation.designData.frame.margin '" + generation.designData.frame.margin + "' is " + typeof generation.designData.frame.margin);
		
			if (typeof generation.designData.general.lineWidth != 'number')
				console.log("ScreenGenerator_CalcPreliminaryBounds: generation.designData.general.lineWidth '" + m + "' is " + typeof generation.designData.general.lineWidth);
		}
		
		b.min.x -= (m + lw);
		b.min.y -= (m + lw);
		b.max.x += (m + lw);
		b.max.y += (m + lw);
		
		return b;
	}
	
	var ScreenGenerator_CalcFinalBounds = function(generation)
	{
		// Calculate the bounds of the image.
		//
		var b;
		
		if (generation.designData.frame.fixedBounds)
		{
			// No change from preliminary bounds
			b = generation.bounds;
		}
		else
		{
			var lw = 0;
			
			b = generation.offsetPolyList.FindBounds();
			
			// 2018.01.24: offsetPolyList might be empty. In that case use the frame bounds
			if (b == undefined)
				b = Polygon_FindBounds(generation.outerFramePoints);
				
			// Prevent the line from being clipped
			if (generation.designData.frame.margin < generation.designData.general.lineWidth)
				lw = generation.designData.general.lineWidth;
				
			var m = generation.designData.frame.margin;

			if (typeof generation.designData.frame.margin != 'number')
				console.log("ScreenGenerator_CalcPreliminaryBounds: generation.designData.frame.margin '" + generation.designData.frame.margin + "' is " + typeof generation.designData.frame.margin);
		
			if (typeof generation.designData.general.lineWidth != 'number')
				console.log("ScreenGenerator_CalcPreliminaryBounds: generation.designData.general.lineWidth '" + m + "' is " + typeof generation.designData.general.lineWidth);
		
			b.min.x -= (m + lw);
			b.min.y -= (m + lw);
			b.max.x += (m + lw);
			b.max.y += (m + lw);
		}
		
		
		return b;
	}
	
	/*----------------------------------------------------------------------------------------------*
	 *	Get Size
	 *		Returns an object with either the radius or the width and height.
	 *		Added so that the "set origin" function for gradients does not have to know
	 *		about the frame
	 *
	 *	2021.07.06: Added
	 *----------------------------------------------------------------------------------------------*/
	var ScreenGenerator_GetSize = function(screenData)
	{
		let size = {};
		
		if (screenData.frame.shape == FrameShape.FRAME_NORMAN_WINDOW)
		{
			size.width = screenData.frame.width;
			size.height = screenData.frame.height;
		}
		else if (screenData.frame.shape == FrameShape.FRAME_RECTANGLE)
		{
			size.width = screenData.frame.width;
			size.height = screenData.frame.height;
		}
		else if (screenData.frame.shape == FrameShape.FRAME_DIAMOND)
		{
			size.width = screenData.frame.width;
			size.height = screenData.frame.height;
		}
		else if (screenData.frame.shape == FrameShape.FRAME_ISO_TRIANGLE)
		{
			size.width = screenData.frame.width;
			size.height = screenData.frame.height;
		}
		else if (screenData.frame.shape == FrameShape.FRAME_SINGLE_TILE)
		{
			size.radius = screenData.tiling.size;
		}
		else
		{
			size.radius = screenData.frame.radius;
		}

		return size;
	}
	
	var ScreenGenerator_CalcFrameMeasureLinePointList = function(screenData)
	{
		// Calculate the points for the frame. These are the points as described by
		// the size (radius or width&height) and the rotation. 
		//
		var pointList = [];
		
		// 2020.08.05: Rotate is disabled for Single Tile (and possibly others in the future)
		var rotateFrame = true;
		
		// List of points, unscaled, not rotated, centered around (0,0), that are the
		// "measured" dimensions of the frame
		if (screenData.frame.shape == FrameShape.FRAME_NORMAN_WINDOW)
		{
			var w = screenData.frame.width;
			var h = screenData.frame.height;
			pointList.push({x:-w/2, y: -h/2});
			pointList.push({x: w/2, y: -h/2});
			pointList.push({x: w/2, y:  h/2});
						
			var sides = 36; 
			var r = w/2;
			for (var i = 1; i < sides; i++)
			{
				var a = i * Math.PI/sides;
				var x = r * Math.cos(a);
				var y = h/2 + r * Math.sin(a);
				pointList.push({x:x, y:y});
			}

			pointList.push({x:-w/2, y:  h/2});
		}
		else if (screenData.frame.shape == FrameShape.FRAME_RECTANGLE)
		{
			var w = screenData.frame.width;
			var h = screenData.frame.height;
			pointList.push({x:-w/2, y: -h/2});
			pointList.push({x: w/2, y: -h/2});
			pointList.push({x: w/2, y:  h/2});
			pointList.push({x:-w/2, y:  h/2});
		}
		else if (screenData.frame.shape == FrameShape.FRAME_DIAMOND)
		{
			var h = screenData.frame.height;
			var w = screenData.frame.width;
			pointList.push({x:-w/2, y:    0});
			pointList.push({x:   0, y: -h/2});
			pointList.push({x: w/2, y:    0});
			pointList.push({x:   0, y:  h/2});
		}
		else if (screenData.frame.shape == FrameShape.FRAME_ISO_TRIANGLE)
		{
			var h = screenData.frame.height;
			var w = screenData.frame.width;
			pointList.push({x:-w/2, y:  0});
			pointList.push({x: w/2, y:  0});
			pointList.push({x:   0, y:  h});
		}
		else if (screenData.frame.shape == FrameShape.FRAME_SINGLE_TILE)
		{
			var unitTile = ScreenGenerator_GetUnitTileInfo(screenData);
			pointList = unitTile.points;

			rotateFrame = false;
		}
		else
		{
			var sides = 4;
			var r = screenData.frame.radius;
			
			if (screenData.frame.shape == FrameShape.FRAME_TRIANGLE)
				sides = 3;
			else if (screenData.frame.shape == FrameShape.FRAME_PENTAGON)
				sides = 5;
			else if (screenData.frame.shape == FrameShape.FRAME_HEXAGON)
				sides = 6;
			else if (screenData.frame.shape == FrameShape.FRAME_OCTAGON)
				sides = 8;
			else if (screenData.frame.shape == FrameShape.FRAME_SIDES_12)
				sides = 12;
			else if (screenData.frame.shape == FrameShape.FRAME_SIDES_36)
				sides = 36;
				
			for (var i = 0; i < sides; i++)
			{
				var a = i * 2 * Math.PI/sides;
				var x = r * Math.cos(a);
				var y = r * Math.sin(a);
				pointList.push({x:x, y:y});
			}
		}
		
		// Apply rotation
		// 2020.08.05: Added flag to disable rotation for some frames
		if (rotateFrame)
		{
			var a = screenData.frame.rotation * Math.PI / 180.0;
			var sA = Math.sin(a);
			var cA = Math.cos(a);
			for (var i = 0; i < pointList.length; i++)
			{
				var pt = pointList[i];
				var x = cA * pt.x - sA * pt.y;
				var y = cA * pt.y + sA * pt.x;
				pt.x = x;
				pt.y = y;
			}
		}

		return pointList;
	}	
	
	var ScreenGenerator_GetInnerOuterFrameOffsets = function(designData)
	{
		// Return the inner and outer offsets for the frame.
		//
		var insideOffset = 0;
		var outsideOffset = 0;
		
		// Determine the offsets for the inside and outside of the frame		
		if (designData.frame.measure == FrameMeasure.FRAME_OUTSIDE_EDGE)
		{
			insideOffset = designData.frame.border;
		}
		else if (designData.frame.measure == FrameMeasure.FRAME_INSIDE_EDGE)
		{
			outsideOffset = designData.frame.border;
		}
		else if (designData.frame.measure == FrameMeasure.FRAME_CENTERLINE)
		{
			outsideOffset = designData.frame.border/2;
			insideOffset = designData.frame.border/2;
		}
		
		return {inner:insideOffset, outer:outsideOffset};
	}

	var ScreenGenerator_GetMinimalRepeatBounds = function(designData)
	{
		var tf = ScreenGenerator_GetTesselationObject(designData);
		//var tesselationlayout = tf.CalcLayout(designData);
		// 2020.08.05: Use new function that can modify layout
		var tesselationlayout = ScreenGenerator_GetLayout(tf, designData);

		var minimalRepeatBounds = tf.GetMinimalRepeatBounds(tesselationlayout);
		
		//console.log("minimalRepeatBounds: " + JSON.stringify(minimalRepeatBounds));

		// 2019.03.25: Center around origin
		var xShift = (minimalRepeatBounds.max.x - minimalRepeatBounds.min.x)/2;
		var yShift = (minimalRepeatBounds.max.y - minimalRepeatBounds.min.y)/2;

		minimalRepeatBounds.max.x -= xShift;
		minimalRepeatBounds.min.x -= xShift;
		minimalRepeatBounds.max.y -= yShift;
		minimalRepeatBounds.min.y -= yShift;
		
		return minimalRepeatBounds;
	}
	
	var ScreenGenerator_LineList_AddFrame = function(generation)
	{
		// Add the frame polygon point list to the line list
		//
		
		// Determine the offsets for the inside and outside of the frame		
		var offsets = ScreenGenerator_GetInnerOuterFrameOffsets(generation.designData);
		
		// Add the points lists in the necessary directions depending on how the frame should
		// be rendered, if at all
		if (generation.designData.frame.render == FrameRender.FRAME_RENDER_ALL)
		{
			if (0 /* Use original frame point list and offsets */)
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.framePoints, SGDirection.FORWARD, 
													offsets.outer, {seTag:ScreenElementTag.ELEMENT_FRAME_OUTSIDE});
												
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.framePoints, SGDirection.REVERSE,  
													offsets.inner, {seTag:ScreenElementTag.ELEMENT_FRAME_INSIDE});
			}
			else if (0 /* Use inner and outer frame point lists and offset = 0 */)
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.outerFramePoints, SGDirection.FORWARD, 
													0, {seTag:ScreenElementTag.ELEMENT_FRAME_OUTSIDE});
												
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.innerFramePoints, SGDirection.REVERSE,  
													0, {seTag:ScreenElementTag.ELEMENT_FRAME_INSIDE});
			}
			/*
			// This should let the center line, when rendered, be displayed as expected for the frame 
			// But the clipping needs to change elsewhere
			else if (generation.designData.frame.measure == FrameMeasure.FRAME_CENTERLINE && generation.designData.general.renderCenter)
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.framePoints, SGDirection.FORWARD, 
													offsets.outer, {seTag:ScreenElementTag.ELEMENT_FRAME_OUTSIDE});
												
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.framePoints, SGDirection.REVERSE,  
													offsets.inner, {seTag:ScreenElementTag.ELEMENT_FRAME_INSIDE});
			}
			*/
			else /* Use the innerFramePoints list and the outer offset with the framePoints list */
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.framePoints, SGDirection.FORWARD, 
													offsets.outer, {seTag:ScreenElementTag.ELEMENT_FRAME_OUTSIDE});
													
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.innerFramePoints, SGDirection.REVERSE,  
													0, {seTag:ScreenElementTag.ELEMENT_FRAME_INSIDE});
			}
		}
		else if (generation.designData.frame.render == FrameRender.FRAME_RENDER_INSIDE)
		{
			if (0)
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.framePoints, SGDirection.REVERSE,  
													offsets.inner, {seTag:ScreenElementTag.ELEMENT_FRAME_INSIDE});
			}
			else
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.innerFramePoints, SGDirection.REVERSE,  
													0, {seTag:ScreenElementTag.ELEMENT_FRAME_INSIDE});
			}
		}
		else if (generation.designData.frame.render == FrameRender.FRAME_RENDER_OUTSIDE)
		{
			if (1)
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.framePoints, SGDirection.REVERSE,  
													-offsets.outer, {seTag:ScreenElementTag.ELEMENT_FRAME_OUTSIDE});
			}
			else
			{
			ScreenGenerator_LineList_AddPointList(generation.lineList, generation.outerFramePoints, SGDirection.REVERSE,  
													0, {seTag:ScreenElementTag.ELEMENT_FRAME_OUTSIDE});
			}
		}
		else if (generation.designData.frame.render == FrameRender.FRAME_RENDER_NONE)
			{ /* do nothing */}
		else if (generation.designData.frame.render == FrameRender.FRAME_FULL_TILES)
			{ /* do nothing */}
		else
			console.log("ScreenGenerator_LineList_AddFrame: Unknown FrameRender type: " + screenData.frame.render);
	}	
		
	var ScreenGenerator_PostProcessPolygonList = function(screenData, offsetPolyList)
	{
		// Identify the corners in the polygon list and mark the ones which should not
		// be rendered as a curve
		//
		for (var j = 0; j < offsetPolyList.GetPolygonCount(); j++)
		{
			var simplePoly = offsetPolyList.GetPolygonPoints(j);
			var isOutsideFrame = false; // 2021.04.08
			
			for (var i = 0; i < simplePoly.length; i++)
			{
				var ptA = simplePoly[i];
				var ptB = simplePoly[(i + 1) % simplePoly.length];

				var isOutsideFrameCorner = (ptB.tag) && 
					(ptB.tag.seTag == ScreenElementTag.ELEMENT_FRAME_OUTSIDE);

				var isInsideFrameCorner = (ptB.tag && ptA.tag) && 
					((ptB.tag.seTag == ScreenElementTag.ELEMENT_FRAME_INSIDE) &&
					 (ptA.tag.seTag == ScreenElementTag.ELEMENT_FRAME_INSIDE));

				var isTileToFrameCorner = (ptB.tag && ptA.tag) && 
				   (((ptB.tag.seTag == ScreenElementTag.ELEMENT_FRAME_INSIDE) &&
					 (ptA.tag.seTag != ScreenElementTag.ELEMENT_FRAME_INSIDE)) ||
					((ptB.tag.seTag != ScreenElementTag.ELEMENT_FRAME_INSIDE) &&
					 (ptA.tag.seTag == ScreenElementTag.ELEMENT_FRAME_INSIDE)));

				var isTileToTileCorner = (ptB.tag && ptA.tag) && 
					((ptB.tag.seTag == ScreenElementTag.ELEMENT_BASE) &&
					 (ptA.tag.seTag == ScreenElementTag.ELEMENT_BASE));

				if (!screenData.general.curveFrameOutsideCorner && isOutsideFrameCorner)
					ptB.omitCurve = true;

				if (!screenData.general.curveFrameInsideCorner && isInsideFrameCorner)
					ptB.omitCurve = true;

				if (!screenData.general.curveFrameToSegmentCorner && isTileToFrameCorner)
					ptB.omitCurve = true;

				if (!screenData.general.curveSegmentCorners && isTileToTileCorner)
					ptB.omitCurve = true;

				isOutsideFrame = (isOutsideFrame || isOutsideFrameCorner); // 2021.04.08
			}
			
			// 2021.04.08: Identify this polygon as the (outside) frame. This info is
			// included in the JSON export
			if (isOutsideFrame)
			{
				if (offsetPolyList.GetPolygonTagInfo(j) == undefined)
					offsetPolyList.UpdatePolygonTagInfo(j, {tag:"info", isFrame:true});
				else
					offsetPolyList.UpdatePolygonTagInfo(j, {isFrame:true});
			}
		}
	}
	
	var ScreenGenerator_CalcDrillHoles = function(generation)
	{
		// Calc the drill holes, which are added to the "drillHoles" property.
		// 
		generation.drillHoles = [];

		// Vertex drill holes are always in the middle between the outer
		// frame vertex and the inner frame vertex.
		if (generation.designData.drillholes.dhFrameVertex)
		{
			var offset = generation.designData.drillholes.dhFrameVertexOffset;
			
			for (var i = 0; i < generation.outerFramePoints.length; i++)
			{
				var op = generation.outerFramePoints[i];
				var ip = generation.innerFramePoints[i];
				var unit = MathUtil.CalcUnitVector(op, ip);
				if (unit == undefined)
					unit = {x:0, y:0};
				var x = (op.x + ip.x)/2 + offset * unit.x;
				var y = (op.y + ip.y)/2 + offset * unit.y;
				var circle = {x:x, y:y, r:generation.designData.drillholes.dhFrameVertexSize};
				
				generation.drillHoles.push(circle);
			}
		}
	}

	var ScreenGenerator_CalcNotches = function(generation)
	{
		var addNotches = false;
		var note = generation.designData.general.note;
		var matchStr = "notches(";

		// Length: the size of the notch along the edge			
		var notchLength = 2.0;

		// Center Offset List: specify the location of the notches along the edge. 
		// The offset if from the center of the edge
		// (Consider how to support distances from the vertices.)
		var notchCenterOffsetList = [-20, 0.0, 20];

		// Notch Set List: list of offsets from the edge and depths
		var notchSetList = [ [0, 1.0], [3.0, 2.0] ];
				
		if (note != undefined && typeof note == "string" && note.indexOf(matchStr) != -1)
		{
			var startPos = note.indexOf(matchStr) + matchStr.length;
			var endPos = note.indexOf(")", startPos);
			
			if (endPos != -1)
			{
				var notchStr = note.substring(startPos, endPos);
				
				try {
					var notchInfo = JSON.parse(notchStr);
					
					if (notchInfo.length >= 3)
					{
						// Length: the size of the notch along the edge			
						notchLength = notchInfo[0];
		
						// Center Offset List: specify the location of the notches along the edge. 
						// The offset if from the center of the edge
						notchCenterOffsetList = notchInfo[1];

						// Notch Set List: list of offsets from the edge and depths, [[offset,depth],..]
						notchSetList = notchInfo[2];
		
						addNotches = true;
					}
					else
					{
						// Insufficient info for notches
					}
				}
				catch (error) {
					console.log("ScreenGenerator_CalcNotches: error '" + error + "' trying to parse '" + notchStr + "'");
				}
			}
		}
		
		
		if (addNotches)
		{
			generation.notches = [];
			
			// This might fail if we get bad data, so add an exception handler. If it fails, then clear all created notches
			try {
				var vertices = generation.outerFramePoints.length;
				for (var i = 0; i < vertices; i++)
				{
					for (var j = 0; j < notchSetList.length; j++)
					{
						var notchEdgeOffset = notchSetList[j][0];
						var notchDepth      = notchSetList[j][1];
					
						for (var k = 0; k < notchCenterOffsetList.length; k++)
						{
							var ctrOffset = notchCenterOffsetList[k];
					
							var pA = generation.outerFramePoints[i];
							var pB = generation.outerFramePoints[(i + 1) % vertices];

							var unit = MathUtil.CalcUnitVector(pA, pB);
							if (unit == undefined)
								unit = {x:0, y:0};
					
							var unitP = {x:-unit.y, y:unit.x};
					
							var x = (pA.x + pB.x)/2;
							var y = (pA.y + pB.y)/2;
				
					
							var x0 = x + (ctrOffset - notchLength/2) * unit.x + notchEdgeOffset * unitP.x;
							var y0 = y + (ctrOffset - notchLength/2) * unit.y + notchEdgeOffset * unitP.y;

							var x1 = x + (ctrOffset + notchLength/2) * unit.x + notchEdgeOffset * unitP.x;
							var y1 = y + (ctrOffset + notchLength/2) * unit.y + notchEdgeOffset * unitP.y;

							var x2 = x + (ctrOffset + notchLength/2) * unit.x + (notchEdgeOffset + notchDepth) * unitP.x;
							var y2 = y + (ctrOffset + notchLength/2) * unit.y + (notchEdgeOffset + notchDepth) * unitP.y;

							var x3 = x + (ctrOffset - notchLength/2) * unit.x + (notchEdgeOffset + notchDepth) * unitP.x;
							var y3 = y + (ctrOffset - notchLength/2) * unit.y + (notchEdgeOffset + notchDepth) * unitP.y;
				
							var notch = [{x:x0, y:y0}, {x:x1, y:y1}, {x:x2, y:y2}, {x:x3, y:y3}];
							generation.notches.push(notch);
						}
					}
				}
			}
			catch (error) {
				generation.notches = [];
			}
			
		}
	}

	//------------------------------------------------------------------------------------
	//	Calc Scan Intersect
	//
	//	Calculate the x value for a horizontal line at y that intersects ptA -> ptB
	//------------------------------------------------------------------------------------
	function CalcScanIntersect(yValue, ptA, ptB)
	{
		let xIntersect = undefined;
	
		if (ptA.y > ptB.y)
		{
			let t = ptA;
			ptA = ptB;
			ptB = t;
		}
	
		if (ptA.y == ptB.y)
		{
			// Ignore horizontal lines for now
		}
		else if (ptA.y <= yValue && yValue <= ptB.y)
		{
			xIntersect = ptA.x + (ptB.x - ptA.x) * (yValue - ptA.y) / (ptB.y - ptA.y);
		}
	
		return xIntersect;
	}

	//------------------------------------------------------------------------------------
	//	Calc Polygon Scan Lines
	//
	//	Return an array of {y, startX, endX} entries
	//------------------------------------------------------------------------------------
	function ScreenGenerator_CalcPolygonScanLines(polygon)
	{
		let minY = polygon.reduce((min, pt) => Math.min(min, pt.y), polygon[0].y);
		let maxY = polygon.reduce((max, pt) => Math.max(max, pt.y), polygon[0].y);

		let minX = polygon.reduce((min, pt) => Math.min(min, pt.x));
		let maxX = polygon.reduce((max, pt) => Math.max(max, pt.x));
	
		minY = Math.floor(minY);
		maxY = Math.ceil(maxY);
	
		minX -= 1;
		maxX += 1;
	
		let scanlist = [];
	
		for (var y = minY; y <= maxY; y++)
		{
			var xList = [];
			for (var i = 0; i < polygon.length; i++)
			{
				let ptA = polygon[i];
				let ptB = polygon[(i + 1) % polygon.length];
				let x = CalcScanIntersect(y, ptA, ptB);
			
				if (x != undefined)
				{
					x = Math.floor(x);
					xList.push(x);
				}
			}
		
			xList.sort((a, b) => (a - b));
		
			let scanline = {y, xList};
		
			scanlist.push(scanline);
		}
	
		return scanlist;
	}

	var ScreenGenerator_CalcScanLines = function(generation, startIdx, stepCount)
	{
		var tilePolylist = generation.tilePolylist;
		var tilePolyCount = tilePolylist.GetPolygonCount();
		
		var scale = 1.0;
		var offsetX = 0.0;
		var offsetY = 0.0;
		
		// 2021.03.27: Use the frame bounds instead of the bounds from the list of tiles. This
		// keeps the tiles in the same place relative to the pixels when the clipped tiles
		// are included in the tile list.
		//var tilesBounds = tilePolylist.FindBounds();
		var tilesBounds = MathUtil.FindPolygonBounds(generation.innerFramePoints); 
		let tilesWidth  = tilesBounds.max.x - tilesBounds.min.x;
		let tilesHeight = tilesBounds.max.y - tilesBounds.min.y;

		offsetX = -tilesBounds.min.x;
		offsetY =  tilesBounds.min.y;
		
		// These values are based on the size of the canvas in the DOM.
		// These should be replaced with something centrally defined
		var imageWidth = 350;
		var imageHeight = 350;

		if (generation.designData.imageData != undefined)
		{
			let imageData = generation.designData.imageData;
			imageWidth  = imageData.width;
			imageHeight = imageData.height;
		}

		scale = (imageWidth/tilesWidth < imageHeight/tilesHeight) ? imageWidth/tilesWidth : imageHeight/tilesHeight;

		for (var k = startIdx; k < tilePolyCount && k < startIdx + stepCount; k++)
		{
			let tilePoly = tilePolylist.GetPolygonPoints(k);
			let xform = { scale:{x:scale, y:-scale}, offset:{x:offsetX, y:offsetY} }
			let scaledTilePoly = tilePoly.map(pt => { return {x:((pt.x  + xform.offset.x) * xform.scale.x), y:((pt.y + xform.offset.y) * xform.scale.y)} });
			let scanLines = ScreenGenerator_CalcPolygonScanLines(scaledTilePoly);
			tilePolylist.UpdatePolygonTagInfo(k, {scanLines});
		}
		
		// Return true if done		
		return (startIdx + stepCount >= tilePolyCount);
	}
	
	//------------------------------------------------------------------------------------
	//	Apply Image Transform
	//		contrast range: -255 to 255, zero indicates no contrast adjustment
	//		brightness range: -255 to 255, zero indicates no brightness adjustment
	//------------------------------------------------------------------------------------
	var applyImageTransform = function(imageChannelValue, imageTransformSettings)
	{
		let ContrastMax = 20;
		
		var value = imageChannelValue;
		let contrast = imageTransformSettings.contrast;
		let brightness = imageTransformSettings.brightness;
		let posterize = imageTransformSettings.posterize;
		let posterizeLevels = imageTransformSettings.posterizeLevels;
		
		let alpha = 1.0;
		let beta = brightness;
		
		if (contrast < 0.0)
		{
			alpha = 1 + contrast/255;
		}
		else if (contrast > 0.0)
		{
			alpha = 20*(contrast*contrast)/(255*255) + 1;
		}

		value = (value - 128) * alpha + 128 + beta;

		if (value < 0)
			value = 0;
		else if (value > 255)
			value = 255;

		if (posterize)
		{
			let steps = 256/(posterizeLevels - 1);
			value = Math.round(value/steps) * steps;

			if (value > 255)
				value = 255;
		}
		
		// 2021.03.29: Just in case...
		if (isNaN(value))
			value = 0;
		
		return Math.floor(value);
	}
	
	//------------------------------------------------------------------------------------
	//	Scale Polygon
	//------------------------------------------------------------------------------------
	function ScalePolygon(polygon, scale)
	{
		var oX, oY;
		var poly;
		var scalePoly = [];
	
		if (Array.isArray(polygon))
		{
			let minY = polygon.reduce((min, pt) => Math.min(min, pt.y), polygon[0].y);
			let maxY = polygon.reduce((max, pt) => Math.max(max, pt.y), polygon[0].y);

			let minX = polygon.reduce((min, pt) => Math.min(min, pt.x), polygon[0].x);
			let maxX = polygon.reduce((max, pt) => Math.max(max, pt.x), polygon[0].x);

			oX = (maxX + minX)/2;
			oY = (maxY + minY)/2;
			poly = polygon;
		}
		else
		{
			oX = polygon.ctr.x;
			oY = polygon.ctr.y;
			poly = polygon.poly;
		}

		for (var i = 0; i < poly.length; i++)
		{
			let pt = poly[i];
			let scalePt = {x: (pt.x - oX) * scale + oX, y: (pt.y - oY) * scale + oY};
			scalePoly.push(scalePt);
		}
		
		return scalePoly;
	}

	//------------------------------------------------------------------------------------
	//	Scale Polygon For Slots
	//		Only accepts polygon point array. Must be four points.
	//		Moves top and bottom edges towards each other.
	//		First and second point describe top edge.
	//		Third and fourth point describe bottom edge.
	//		2022.10.31: Created.
	//------------------------------------------------------------------------------------
	function ScalePolygonForSlots(polygon, scale, alignment)
	{
		var oX, oY;
		var poly;
		var scalePoly = [];
		var ctrPoly = [];
		var ctrA, ctrB;

		// Find midpoints of left and right edges. The points of the top and bottom
		// edges are moved towards these points.
		if (alignment == "T") /* top */
		{
			ctrA = {x:polygon[0].x, y:polygon[0].y};
			ctrB = {x:polygon[1].x, y:polygon[1].y};
		}
		else if (alignment == "B") /* bottom */
		{
			ctrA = {x:polygon[3].x, y:polygon[3].y};
			ctrB = {x:polygon[2].x, y:polygon[2].y};
		}
		else /* center */
		{
			ctrA = {x:(polygon[0].x + polygon[3].x)/2, y:(polygon[0].y + polygon[3].y)/2};
			ctrB = {x:(polygon[1].x + polygon[2].x)/2, y:(polygon[1].y + polygon[2].y)/2};
		}

		// Each point in the input polygon is moved towards its corresponding center point
		poly = polygon;
		ctrPoly.push(ctrA, ctrB, ctrB, ctrA);

		for (var i = 0; i < poly.length; i++)
		{
			let pt = poly[i];
			let ctr = ctrPoly[i]
			let scalePt = {x: (pt.x - ctr.x) * scale + ctr.x, y: (pt.y - ctr.y) * scale + ctr.y};
			scalePoly.push(scalePt);
		}

		return scalePoly;
	}

	//------------------------------------------------------------------------------------
	//	Histogram: Reset
	//		2021.03.30: Added
	//------------------------------------------------------------------------------------
	var ScreenGenerator_Histogram_Reset = function(generation)
	{
		if (generation.imageMisc != undefined)
		{
			generation.imageMisc.histogram = [];

			for (var i = 0; i < 256; i++)
				generation.imageMisc.histogram[i] = 0;
		}
	}
	
	
	//------------------------------------------------------------------------------------
	//	Histogram: Add
	//		2021.03.30: Added
	//------------------------------------------------------------------------------------
	var ScreenGenerator_Histogram_Add = function(generation, gray)
	{
		if (generation.imageMisc != undefined)
		{
			generation.imageMisc.histogram[gray]++;
		}
	}
	
	
	//------------------------------------------------------------------------------------
	//	Histogram: Normalize
	//		2021.03.30: Added
	//------------------------------------------------------------------------------------
	var ScreenGenerator_Histogram_Normalize = function(generation)
	{
		if (generation.imageMisc != undefined)
		{
			var count = 0;
			for (var i = 0; i < 256; i++)
				count += generation.imageMisc.histogram[i];
		
			for (var i = 0; i < 256; i++)
				generation.imageMisc.histogram[i] /= count;
		}
	}
	
	
	//------------------------------------------------------------------------------------
	//	Scan Image
	//
	//	
	//------------------------------------------------------------------------------------
	// (imageData, polygonList, style = gray)
	/*
		imageData
		histogram
		imageStates
		lightOverDark or darkOverLight
		
		upperClamp
		lowerClamp
		honorAlphaChannel
	*/
	
	var ScreenGenerator_ScanImage = function(generation, startIdx, stepCount)
	{
		var tilePolylist = generation.tilePolylist;
		var tilePolyCount = 0; // 2021.03.12: We may get here missing the tilePolylist. Zero will fall through,
		var processingSlots = (generation.designData.tiling.shape == Tiling.SLOTS); // 2022.11.03

		if (tilePolylist != undefined)
			 tilePolyCount = tilePolylist.GetPolygonCount();

		// Create the output ("offset") polygon list the first time we are called
		// Also clear the processed data image, reset the image stats and the histogram
		if (startIdx == 0)
		{
			generation.offsetPolyList = new PolygonList();
			// 2021.04.07: Identify this polygon as the frame
			// 2021.04.12: Replace "info" with "isStroke"
			// 2022.03.01: Identify as "designEdge"
			let tag = {isStroke:true, /*tag:"info",*/ component:"designEdge", isFrame:true};
			generation.offsetPolyList.AddPolygonPoints(generation.outerFramePoints, tag);
			
			// 2021.03.23: Clear the processed data pixels if it is present
			// 2021.03.26: Also need to check that the array exists and that fill is a function
			if (generation.designData.processedData != undefined && 
				generation.designData.processedData.data != undefined &&
				typeof(generation.designData.processedData.data.fill) === "function")
				generation.designData.processedData.data.fill(0);

			generation.imageMisc.imageStats = { 
					totalR:0, totalG:0, totalB:0,
					avgGray: 0, avgR:0, avgB:0, avgG:0, 
					pixelCount:0, tileCount:0, tileOutput:0,
					lowClamp:0, highClamp:0,
					maxScaleCalc:0.0,
					 };

			ScreenGenerator_Histogram_Reset(generation);

			// 2022.11.04: Information for slots
			// polygonRow: Accumulates slot polygons until the end of the row
			generation.slots = {polygonRow:[], rowEnd:false};
		}

		var backgroundColor = {r:0, g:0, b:0};
		var imageData = undefined;
		var processedData = generation.designData.processedData;
		var imagePixels = undefined;
		var imageWidth = 0;
		var imageHeight = 0;
		var stride = 0;

		// Convenience object
		var imageStats = generation.imageMisc.imageStats;
		
		// Convenience variables for the input data
		if (generation.designData.imageData != undefined)
		{
			imageData = generation.designData.imageData;
			imagePixels = imageData.data;
			imageWidth  = imageData.width;
			imageHeight = imageData.height;
			stride = imageWidth * 4;
		}
		
		
		// Map designData settings to algorithm settings
		var imgProcess = generation.designData.image.monochromeStyle; // 2022.04.06: Convenience since it is being used a lot
		// Determine if RGB channels will be kept independent or averaged together
		var convertToGray = (imgProcess != "C");
		// Determine the output format
		var createScaledPolygons = (imgProcess == "D" || imgProcess == "L");
		var createConcentricPolygons = (imgProcess == "N" || imgProcess == "M");
		var createColorPolygons = (imgProcess == "C" || imgProcess == "G");
		// Indicate if we need an area calculation
		var calcProportionalArea = (createScaledPolygons || createConcentricPolygons);
		// Indicate if we need a color string
		var calcColorStr = createColorPolygons;
		// Indicate if we are calculating proportional area based on the lightness or darkness
		let lightBackgroundOverDarkHoles = ((imgProcess == "D") || (imgProcess == "M"));
	
		let upperClamp = generation.imageMisc.upperClamp ? generation.imageMisc.upperClamp : 1.0;
		let lowerClamp = generation.imageMisc.lowerClamp ? generation.imageMisc.lowerClamp : 0.0;
		let contrast =  generation.designData.image.contrast;
		let brightness = generation.designData.image.brightness;
		let posterize =  generation.designData.image.posterize;
		let posterizeLevels =  generation.designData.image.posterizeLevels;
		let grayscaleConversionStyle = (generation.designData.image.grayscaleStyle != 0) ? "greenweighted": "equal";
		let edgeColor = generation.designData.general.renderLine ? generation.designData.general.lineColor : undefined;
		
		let honorAlphaChannel = true;

		// Color transformation settings
		let transformSettings = { contrast, brightness, posterize, posterizeLevels };

		// Iterate over the list of polygons
		for (var polygonIdx = startIdx; imagePixels != undefined && polygonIdx < tilePolyCount && polygonIdx < startIdx + stepCount; polygonIdx++)
		{
			// 2022.11.03: Allow for remapping
			let mappedPolygonIdx = polygonIdx;

			// Remap
			if (processingSlots)
			{
				let tileId = generation.tileLocations[polygonIdx].tileId;
				let remap = tilePolylist.FindIndex({tileId});
				generation.slots.rowEnd = generation.tileLocations[polygonIdx].rowEnd;
				if (remap != undefined && remap != -1)
					mappedPolygonIdx = remap;
			}

			// Get the scanlines info For each polygon in the list...
			let tilePolyTag = tilePolylist.GetPolygonTagInfo(mappedPolygonIdx);
			let tilePolyPoints = tilePolylist.GetPolygonPoints(mappedPolygonIdx);
			let scanLines = tilePolyTag.scanLines;
		
			let sumR = 0;
			let sumG = 0;
			let sumB = 0;
			let area = 0;
		
			let polyClipped = false;
			
			// Iterate of over the scan lines of one polygon and Sum the pixel values
			for (var scanIdx = 0; scanIdx < scanLines.length; scanIdx++)
			{
				let scan = scanLines[scanIdx];
				let y = scan.y;
			
				for (var xIdx = 0; xIdx < scan.xList.length; xIdx += 2)
				{
					let xStart = scan.xList[xIdx];
					let xEnd   = scan.xList[xIdx + 1];
				
					for (var x = xStart; x < xEnd; x++)
					{
						if (x >= 0 && x < imageData.width && y >= 0 && y < imageData.height)
						{
							let pixelIdx = y * stride + x * 4;
							let alpha = imagePixels[pixelIdx + 3];
				
							if (honorAlphaChannel && alpha < 255)
							{
								sumR += (alpha * imagePixels[pixelIdx]     + (255 - alpha) * backgroundColor.r)/255;
								sumG += (alpha * imagePixels[pixelIdx + 1] + (255 - alpha) * backgroundColor.g)/255;
								sumB += (alpha * imagePixels[pixelIdx + 2] + (255 - alpha) * backgroundColor.b)/255;
							}
							else
							{
								sumR += imagePixels[pixelIdx];
								sumG += imagePixels[pixelIdx + 1];
								sumB += imagePixels[pixelIdx + 2];
							}
							area++;
						}
						else
						{
							polyClipped = true;
						}
					}
				}
			}
			
			// 2021.03.29: Area might be zero if no pixels were scanned
			if (area == 0)
				area = 1
			
			// Compute averages
			let avgR = sumR / area;
			let avgG = sumG / area;
			let avgB = sumB / area;

			var avg;
			if (grayscaleConversionStyle == "equal")
				avg = (sumR + sumG + sumB) / (area * 3);
			else
				avg = (0.2126 * sumR + 0.7152 * sumG + 0.0722 * sumB)/area;
		
			avg  = applyImageTransform(avg,  transformSettings);
			avgR = applyImageTransform(avgR, transformSettings);
			avgG = applyImageTransform(avgG, transformSettings);
			avgB = applyImageTransform(avgB, transformSettings);
		
			ScreenGenerator_Histogram_Add(generation, avg);

			// Totals
			imageStats.totalR += sumR;
			imageStats.totalG += sumG;
			imageStats.totalB += sumB;
			imageStats.pixelCount += area;
			imageStats.tileCount++;
		
			// Convert to gray
			if (convertToGray)
			{
				avgR = avg;
				avgG = avg;
				avgB = avg;
			}
			
			// Write the averaged color to the processed image; 2021.03.23
			if (processedData != undefined)
			{
				let processedPixels = processedData.data;
				let processedWidth  = processedData.width;
				let processedHeight = processedData.height;
				let processedStride = processedWidth * 4;
				
				// Sum pixel values
				for (var scanIdx = 0; scanIdx < scanLines.length; scanIdx++)
				{
					let scan = scanLines[scanIdx];
					let y = scan.y;
			
					for (var xIdx = 0; xIdx < scan.xList.length; xIdx += 2)
					{
						let xStart = scan.xList[xIdx];
						let xEnd   = scan.xList[xIdx + 1];
				
						for (var x = xStart; x < xEnd; x++)
						{
							if (x >= 0 && x < processedData.width && y >= 0 && y < processedData.height) // <<< Optimize this
							{
								let pixelIdx = y * processedStride + x * 4;
				
								processedPixels[pixelIdx + 0] = avgR;
								processedPixels[pixelIdx + 1] = avgG;
								processedPixels[pixelIdx + 2] = avgB;
								processedPixels[pixelIdx + 3] = 255;
							}
						}
					}
				}
			} // (processedData != undefined)


			// Create the output polygon
			var areaScale = 1.0;
			var linearScale = 1.0; // 2022.10.31
			var colorStr = "";

			if (calcProportionalArea)
			{
				if (lightBackgroundOverDarkHoles)
				{
					linearScale = (1.0 - avg / 255.0);
					areaScale = (Math.sqrt(1.0 - avg / 255.0));
				}
				else
				{
					linearScale = (avg / 255.0);
					areaScale = (Math.sqrt(avg / 255.0));
				}
			
				// Keep track of the largest scale
				if (imageStats.maxScaleCalc < areaScale)
					imageStats.maxScaleCalc = areaScale;
				
				if (areaScale > upperClamp)
				{
					areaScale = upperClamp;
					imageStats.highClamp++;
				}
			}

			if (calcColorStr)
			{
				// Construct a color string
				let rStr = ((avgR < 16) ? "0" : "") + avgR.toString(16);
				let gStr = ((avgG < 16) ? "0" : "") + avgG.toString(16);
				let bStr = ((avgB < 16) ? "0" : "") + avgB.toString(16);
				colorStr = "#" + rStr + gStr + bStr;
			}
		

			// 2022.11.03: Accumulate slots, but only if creating scaled polygons
			if (processingSlots && createScaledPolygons)
			{
				generation.slots.polygonRow.push({tilePolyPoints, linearScale, tilePolyTag});
			}
			// Include polygon only if it is above the lower limit
			else if (areaScale > lowerClamp)
			{
				if (createScaledPolygons)
				{
					// 2021.03.15: Wrapped polygon in object and added ctr
					var scaledPoly = ScalePolygon({poly:tilePolyPoints, ctr:tilePolyTag.ctr}, areaScale);

					// 2022.10.31: Handle slots
					if (processingSlots)
						scaledPoly = ScalePolygonForSlots(tilePolyPoints, linearScale);

					// If the polygon intersect the frame, then clip the polygon with the frame. If this
					// fails for some reason, then ignore the polygon
					try {
						if (tilePolyTag.clipsFrame)
							scaledPoly = MathUtil.ClipPolygonToPolygon(scaledPoly, generation.innerFramePoints);
					}
					catch (err) {
						scaledPoly = undefined;
					}
				
					// 2021.03.26: We might not have a polygon if we clipped it to the frame
					if (scaledPoly != undefined)
					{
						scaledPoly.reverse();

						// 2021.04.07: Add tag with gray and center, but do not call it "area", since that triggers different
						// handling elsewhere
						// 2021.04.12: Replace "info" with "isStroke"
						// 2022.03.01: Identify as "designEdge"
						let tag = {isStroke:true, /*tag:"info",*/ component:"designEdge", gray:avg, ctr:tilePolyTag.ctr}
						generation.offsetPolyList.AddPolygonPoints(scaledPoly, tag);
			
						imageStats.tileOutput++;
					}
				}
				else if (createColorPolygons) /* Non-scaled (full-size) Color or grayscale polygons */
				{
					let polyPoints = tilePolyPoints;
					
					// If an upper limit is set, then use that to scale all of the polygons.
					if (upperClamp < 1.0)
						polyPoints = ScalePolygon({poly:tilePolyPoints, ctr:tilePolyTag.ctr}, upperClamp);
					
					// If the polygon intersect the frame, then clip the polygon with the frame. If this
					// fails for some reason, then ignore the polygon
					try {
						if (tilePolyTag.clipsFrame)
							polyPoints = MathUtil.ClipPolygonToPolygon(polyPoints, generation.innerFramePoints);
					}
					catch (err) {
						polyPoints = undefined;
					}

					if (polyPoints != undefined)
					{
						// 2021.04.07: Add center
						// 2021.04.12: Replace "area" with "isFill"
						// 2022.03.01: Identify as "colorFill"
						let tag = {isFill:true, component:"colorFill", color:colorStr, stroke:edgeColor, gray:avg, ctr:tilePolyTag.ctr}
						generation.offsetPolyList.AddPolygonPoints(polyPoints, tag);
					}
				}
				else if (createConcentricPolygons) // 2022.04.06: Added
				{
					// Get line width and image line spacing from design
					let lineWidth = generation.designData.general.lineWidth;
					let lineSpacing = generation.designData.image.imgLineSpacing;
					// Get the area, perimeter, and "scale to offset" conversion factor from the tile
					let area = tilePolyTag.area;
					let perimeter = tilePolyTag.perimeter;
					let factor = tilePolyTag.scaleOffsetConversion;
					// Set the scale ranges
					let maxScale = upperClamp - lineWidth / (factor * 2);
					let minScale = lineWidth / (factor * 2);
					// Compute the spacing. Note that 'lineSpacing' is the gap between the lines and must be non-negative
					let scaleSpacing = (lineWidth + ((lineSpacing < 0) ? 0 : lineSpacing)) / factor;
					// Compute the total line length needed to meet the area we need to cover
					let lenNeeded = areaScale * area / lineWidth;
					// Convert this to the number of polygon perimeters needed
					let perimetersNeeded = lenNeeded / perimeter;
					let perimetersCount = 0; 

					var scaleList = [];
					while (perimetersNeeded > 0.0 && maxScale - minScale > scaleSpacing && perimetersCount < 20)
					{
						let scaleToAdd = (perimetersNeeded >= maxScale) ? maxScale : perimetersNeeded;
						//if (perimetersNeeded >= maxScale)
						{
							scaleList.push(scaleToAdd);
							perimetersNeeded -= scaleToAdd;
							maxScale -= scaleSpacing;
							perimetersCount++;
						}
					}

					// Add scaled polygons for all of the sizes in the list
					for (var sIdx = 0; sIdx < scaleList.length; sIdx++)
					{
						var scaledPoly = ScalePolygon({poly:tilePolyPoints, ctr:tilePolyTag.ctr}, scaleList[sIdx]);

						// If the polygon intersect the frame, then clip the polygon with the frame. If this
						// fails for some reason, then ignore the polygon
						try {
							if (tilePolyTag.clipsFrame)
								scaledPoly = MathUtil.ClipPolygonToPolygon(scaledPoly, generation.innerFramePoints);
						}
						catch (err) {
							scaledPoly = undefined;
						}
				
						// 2021.03.26: We might not have a polygon if we clipped it to the frame
						if (scaledPoly != undefined)
						{
							let tag = {isStroke:true, component:"imageLine", gray:avg, ctr:tilePolyTag.ctr}
							generation.offsetPolyList.AddPolygonPoints(scaledPoly, tag);
			
							imageStats.tileOutput++;
						}
					}
				}
			}
			else
			{
				imageStats.lowClamp++;
			}

			// 2022.11.03: Create one long slot at the end of the row
			if (processingSlots && generation.slots.rowEnd)
			{
				const alignment = generation.designData.image.slotAlign;
				const slotStyle = generation.designData.image.slotStyle;
				const closeSlotsAtZeroScale = true;
				const scaleMinimum = 0.0001;
				const pr = generation.slots.polygonRow;
				let slotPoly = [];
				for (var prIdx = 0; prIdx < pr.length; prIdx++)
				{
					const polyPoints = pr[prIdx].tilePolyPoints;
					const scale = pr[prIdx].linearScale;
					const tag = pr[prIdx].tilePolyTag;
					let scaledPoly = ScalePolygonForSlots(polyPoints, scale, alignment);
					let includePoly = (!closeSlotsAtZeroScale) || (scale >= scaleMinimum);

					if (includePoly)
					{
						// For the first polygon added to the slot or for "corner" style,
						// add the top left and bottom left points.
						if (slotPoly.length == 0 || slotStyle == "C")
						{
							slotPoly.unshift(scaledPoly[1]);
							slotPoly.push(scaledPoly[2]);
						}

						// For all polygons, at the top right to the beginning and the bottom
						// right to the end of the polygon.
						slotPoly.unshift(scaledPoly[0]);
						slotPoly.push(scaledPoly[3]);
					}

					// If we are the end of the row or we reach an empty (scale==0) polygon, then
					// add the slot to the design
					if (slotPoly.length > 0 && (!includePoly || prIdx == pr.length - 1))
					{
						// If the polygon intersect the frame, then clip the polygon with the frame. If this
						// fails for some reason, then ignore the polygon
						try {
							slotPoly = MathUtil.ClipPolygonToPolygon(slotPoly, generation.innerFramePoints);
						}
						catch (err) {
							//scaledPoly = undefined;
							console.log("ClipPolygonToPolygon failed"); 
						}

						// We might not have a polygon if we clipped it to the frame
						if (slotPoly != undefined)
						{
							slotPoly.reverse();

							let tag = {isStroke:true, component:"designEdge", gray:avg, ctr:tilePolyTag.ctr}
							generation.offsetPolyList.AddPolygonPoints(slotPoly, tag);

							imageStats.tileOutput++; // ??? Still needed?
						}

						slotPoly = [];
					}
				}

				generation.slots.polygonRow = [];
				generation.slots.rowEnd = false;
			}
		}
	
		// Stats
		imageStats.avgR = imageStats.totalR / imageStats.pixelCount;
		imageStats.avgG = imageStats.totalG / imageStats.pixelCount;
		imageStats.avgB = imageStats.totalB / imageStats.pixelCount;
		imageStats.avgGray = (imageStats.totalR + imageStats.totalG + imageStats.totalB) / (3 * imageStats.pixelCount);

		// We are done when all the tiles are processed
		var done = (startIdx + stepCount >= tilePolyCount);

		if (done)
		{
			ScreenGenerator_Histogram_Normalize(generation);
		}

		return done;
	}
	
	var ScreenGenerator_GenerateScreen = function(designData)
	{
		// Generate the complete design. This will not return until the rendering is 
		// complete. Is it not recommended.
		//
		var generation = ScreenGenerator_Create(designData, ScreenGenerationType.COMPLETE_DESIGN);
		
		ScreenGenerator_Generate(generation);

		return generation;
	}

	var ScreenGenerator_GenerateScreenAroundCenter = function(designData)
	{		
		var generation = ScreenGenerator_Create(designData, ScreenGenerationType.MINIMAL_DESIGN);
		
		ScreenGenerator_Generate(generation);

		return generation;
	}
	
	var ScreenGenerator_GenerateMinimalTileLocationList = function(generation)
	{
		var tf = ScreenGenerator_GetTesselationObject(generation.designData);
		var tileLocations = tf.CreateTileListAround(0, 0);
		
		return tileLocations;
	}
	
	//------------------------------------------------------------------------------------
	//	Calc Sketch Bounds
	//
	//	2021.08.30: Calculate the sketch bounds
	//------------------------------------------------------------------------------------
	var ScreenGenerator_CalcSketchBounds = function(generation)
	{
		var tf = ScreenGenerator_GetTesselationObject(generation.designData);
		var tesselationlayout = ScreenGenerator_GetLayout(tf, generation.designData);
		var sketchData = generation.designData.sketchData;
		var sketchBounds = undefined;

		// We have to calculate the location of each tile as the layout maybe rotated
		for (var i = 0; i < sketchData.length; i++)
		{
			var tileInfo = tf.CalcTileInfo(tesselationlayout, sketchData[i].x, sketchData[i].y);
			sketchBounds = Polygon_FindBounds(tileInfo.points, sketchBounds);
		}

		return sketchBounds;
	}

	
	//------------------------------------------------------------------------------------
	//	Generate Tile Location List
	//
	//	2020.11.19: Add options to ignore clipped polygons and to use inner frame
	//	2021.07.15: Changed parameters so stepCount can be provided to prevent lock-up
	//	2021.08.30: For Sketch designs, extend bounds to include all sketch tiles
	//------------------------------------------------------------------------------------
	var ScreenGenerator_GenerateTileLocationList = function(generation, framePoints, startIdx, stepCount)
	{
		var screenData = generation.designData;
		var done = true;
		var useFrame = "outer";
		var includeClipped = true;
		
		// 2021.07.15: Initialize the tileLocations list the first time called
		if (startIdx == 0)
			generation.tileLocations = [];

		if (screenData.designType == DesignType.TYPE_IMAGE)
		{
			useFrame = "inner";
			includeClipped = screenData.image.includeClipped;
		}

		var tf = ScreenGenerator_GetTesselationObject(screenData);

		var frameBounds = ScreenGenerator_CalcApproxOuterBounds(screenData);

		// 2020.08.05: Use new function that can modify layout
		var tesselationlayout = ScreenGenerator_GetLayout(tf, screenData);

		// 2021.08.30: Extend frameBounds to include sketch tiles outside of frame
		if (screenData.designType == DesignType.TYPE_GRID_SKETCH)
		{
			// Find the min and max tile locations
			let sketchBounds = ScreenGenerator_CalcSketchBounds(generation);
			
			if (sketchBounds != undefined)
			{
				frameBounds.min.x = Math.min(frameBounds.min.x, sketchBounds.min.x);
				frameBounds.min.y = Math.min(frameBounds.min.y, sketchBounds.min.y);
				frameBounds.max.x = Math.max(frameBounds.max.x, sketchBounds.max.x);
				frameBounds.max.y = Math.max(frameBounds.max.y, sketchBounds.max.y);
			}
		}

		var iterBounds = tf.CalcIteratorBounds(tesselationlayout, frameBounds);

		var tileBounds;

		// 2021.07.15: Effectively "unwrap" what was two nested for-loops
		// so we can easily use startIdx and stepCount
		//
		// Calculate the total number of tile locations to consider.
		let ySpan = iterBounds.max.y - iterBounds.min.y;
		let xSpan = iterBounds.max.x - iterBounds.min.x;
		let yxSpan = ySpan * xSpan;
		var yxIdx;

		for (yxIdx = startIdx; yxIdx < yxSpan && yxIdx < (startIdx + stepCount); yxIdx++)
		{
			// Convert the yxIdx into y and x values with integer division and modulo
			let y = iterBounds.min.y + Math.floor(yxIdx / xSpan);
			let x = iterBounds.min.x + (yxIdx % xSpan);

			var tileInfo = tf.CalcTileInfo(tesselationlayout, x, y);
			var result = MathUtil.DoPolygonsIntersect(tileInfo.points, framePoints);
			
			// 2021.08.30: For sketches, always accept tiles
			if (screenData.designType == DesignType.TYPE_GRID_SKETCH)
				result = MathUtil.PolygonIntersectionResult.CONTAINED;
				
			//For debugging: result = true;
			if (result)
			{
				var clipToFrame = (result == MathUtil.PolygonIntersectionResult.PARTIAL);
				//For debugging: clipToFrame = false;
				
				if (!clipToFrame || includeClipped)
				{
					// 2022.11.03: Mark the location at end of the array (before adding
					// the new location) as the end of the row if it has a different y-value.
					// This is used in the slots algorithm to accumulate polygons into a
					// list that will then be used to create one long polygon (a "slot")
					let len = generation.tileLocations.length;
					if (len > 0 && generation.tileLocations[len - 1].y != y)
						generation.tileLocations[len - 1].rowEnd = true;
					// 2022.11.03: Add a "tileId", which will be transferred to the tile
					// polygon taginfo, so that we can find the tile polygon by id when
					// processing slots. This allows for the possibility that the tile
					// polygons are not in the same order as the tile location list
					let locationInfo = { x, y, clip:clipToFrame, tileId:yxIdx };
					generation.tileLocations.push(locationInfo);
				}
			}
		}

		// 2021.07.15: We are done when the "unwrapped" index reaches the end of the list
		done = (yxIdx >= yxSpan);

		// For tessellations with a center tile it is possible to get an empty list.
		// In that case, always add the center tile
		if (done && generation.tileLocations.length == 0)
			generation.tileLocations.push({x:0, y:0, clip:true});
			
		if (done)
			generation.tileLocations[generation.tileLocations.length - 1].rowEnd = true;

		return done;
	}
	
	var ScreenGenerator_GenerateTilePolygons = function(generation, startIdx, stepCount)
	{	
		var tf = ScreenGenerator_GetTesselationObject(generation.designData);
		// 2020.08.05: Use new function that can modify layout
		var tesselationlayout = ScreenGenerator_GetLayout(tf, generation.designData);

		var tl = generation.tileLocations;
		
		if (generation.tilePolylist == undefined)
			generation.tilePolylist = new PolygonList();
		
		for (var i = startIdx; i < tl.length && i < startIdx + stepCount; i++)
		{
			var tileInfo = tf.CalcTileInfo(tesselationlayout, tl[i].x, tl[i].y);				
			// 2021.03.15: Added rotCenter for ScalePoly, when creating image designs
			// 2021.03.26: Added clipsFrame
			// 2021.05.12: Added tile location
			let tagInfo = {ctr: tileInfo.rotCenter, clipsFrame:tl[i].clip, location:tl[i]};
			// 2022.04.08: Add perimeter, area, scaleOffsetConversion
			tagInfo.perimeter = tileInfo.perimeter;
			tagInfo.area = tileInfo.area;
			tagInfo.scaleOffsetConversion = tileInfo.scaleOffsetConversion;
			tagInfo.tileId = tl[i].tileId; // 2022.11.03: Used for SLOTS
			generation.tilePolylist.AddPolygonPoints(tileInfo.points, tagInfo); 
		}

		// Return true if done		
		return (startIdx + stepCount >= generation.tileLocations.length);
	}
	
	var ScreenGenerator_GetUnitTileInfo = function(screenData)
	{
		// 2018.12.12: Adjust the design for the edit canvas
		screenData = ScreenGenerator_CloneDesignForEditRender(screenData);
			
		var tf = ScreenGenerator_GetTesselationObject(screenData);
		// 2020.08.05: Use new function that can modify layout
		var tesselationlayout = ScreenGenerator_GetLayout(tf, screenData);
		var tileInfo = tf.CalcUnitTileInfo(tesselationlayout);	
		tileInfo.subtile = tesselationlayout.subtile;	
		ScreenGenerator_AddBoundsToTileInfo(tileInfo);		
		
		// Define a square that encloses the tile points. This will be used to scale
		// the relative points (that come from the edit canvas) to the actual values
		// for rendering
		var sz = (tileInfo.bounds.size.x > tileInfo.bounds.size.y) ? tileInfo.bounds.size.x : tileInfo.bounds.size.y;
		var sqOffsetX = tileInfo.bounds.min.x + (tileInfo.bounds.size.x - sz)/2;
		var sqOffsetY = tileInfo.bounds.min.y + (tileInfo.bounds.size.y - sz)/2;
		tileInfo.square = {size:sz, offset:{x:sqOffsetX, y:sqOffsetY}};
		
		return tileInfo
	}
	
	//	Calc Mirrored Rotated Tile Segment List
	//		2020.08.31: Moved alternativeCenter param into new options param
	//
	var ScreenGenerator_CalcMirroredRotatedTileSegmentList = function(tileInfo, tileSegment, mirror, rotate, options)
	{
		// tileSegment: One segment to optionally mirror and rotate
		// tileInfo:    Tile center, start angle, side count

		var alternativeCenter = (options != undefined && options.alternativeCenter != undefined) ? options.alternativeCenter : undefined;
		var reflectLine = (options != undefined && options.reflect != undefined) ? options.reflect : 0;
		
		var mirroredRotatedList = [];
		var offsetAB = (tileSegment.width != undefined) ? tileSegment.width/2.0 : 1.0;
		var offsetBA = (tileSegment.width != undefined) ? tileSegment.width/2.0 : 1.0;
		
		var rotateCount = tileInfo.symmetrySides;

		// 2022.01.29: Get the flag from the tile that says if the lattice flags should be inverted for this tile.
		// This value is used as the initial value for the reflection and rotation flags only if the onTranslation
		// flag is set.
		let latticeTranslationFlag = (tileInfo.latticeFlag != undefined) ? tileInfo.latticeFlag : false;

		// 2022.01.26: Lattice (z-value) behaviors. This function will extract the behaviors for inverting
		// the lattice height according the reflection, rotation, and translation of the segment. It also
		// initializes the flags that toggle on reflection and rotation.
		let extractBehaviors = function(b) {
			if (b == undefined)
				b = 0;
			let onTranslation = ((b & 0x4) != 0);
			return  {	onReflection: ((b & 0x1) != 0), reflection: (onTranslation & latticeTranslationFlag), 
						onRotation: ((b & 0x2) != 0), rotation: (onTranslation && latticeTranslationFlag), 
						onTranslation };
		};

		let invertA = extractBehaviors(tileSegment.wbA);
		let invertB = extractBehaviors(tileSegment.wbB);
		let invertL = extractBehaviors(tileSegment.wbL);

		// 2019.02.19: Allow half as many rotations (for squares and hexagons)
		// 2020.08.31: Support arbitrary rotation value, extending "every one" and "every other" to
		// include "every third", etc
		var deltaAngle = Math.PI * 2.0 / rotateCount;
		if (rotate > 1 && tileInfo.symmetrySides > 3)
		{
			deltaAngle *= rotate;
			rotateCount = Math.floor(rotateCount/rotate);
		}

		var center = tileInfo.center;
		var startAngle = tileInfo.startAngle;
		var ptA = {x:tileSegment.ptA.x, y:tileSegment.ptA.y};
		var ptB = {x:tileSegment.ptB.x, y:tileSegment.ptB.y};
		
		// If an alternative center is provided, then both use that as the center of the points
		// AND center the points around it.
		// This is necessary because of differing definitions of the segment points w.r.t. the center
		if (alternativeCenter != undefined)
		{
			center = alternativeCenter;
			startAngle = 0;
			ptA.x -= center.x;
			ptA.y -= center.y;
			ptB.x -= center.x;
			ptB.y -= center.y;

			// This fixes an issue with the snap lines. I think it it required because the 
			// editor is flipped vertically
			reflectLine = -reflectLine;
		}

		// 2020.08.31: Points can now be reflected over any symmetry line
		// Compute "Reflected" points
		var mirrorAngle = reflectLine * Math.PI / tileInfo.symmetrySides;
		var ptAr = MathUtil.ReflectPointOverAngle(ptA, mirrorAngle);
		var ptBr = MathUtil.ReflectPointOverAngle(ptB, mirrorAngle);

		// 2022.01.26: Lattice z-values
		// 2022.03.02: Use 0 instead of undefined for ptA.z, ptB.z, and linez
		// (resolves issue I could not track down in ClipPolygonToLatticeEdges)
		ptA.z = (tileSegment.wzA == undefined) ? 0 : tileSegment.wzA;
		ptB.z = (tileSegment.wzB == undefined) ? 0 : tileSegment.wzB;
		ptAr.z = (ptA.z == undefined) ? undefined : (invertA.onReflection ? -ptA.z : ptA.z);
		ptBr.z = (ptB.z == undefined) ? undefined : (invertB.onReflection ? -ptB.z : ptB.z);
		let linez = (tileSegment.wzL == undefined) ? 0 : tileSegment.wzL;
		let linezr = (linez == undefined) ? undefined : (invertL.onReflection ? -linez : linez);

		// Loop (at least one) to add the original and then the rotated and mirrored lines
		var count = (rotate ? rotateCount : 1);
		for (var i = 0; i < count; i++)
		{
			var a = startAngle + i * deltaAngle;
			var cA = Math.cos(a);
			var sA = Math.sin(a);
			
			// Original
			var ptAo = {};
			ptAo.x = cA *  ptA.x  - sA *  ptA.y  + center.x;
			ptAo.y = cA *  ptA.y  + sA *  ptA.x  + center.y;
			var ptBo = {};
			ptBo.x = cA *  ptB.x  - sA *  ptB.y  + center.x;
			ptBo.y = cA *  ptB.y  + sA *  ptB.x  + center.y;
			// 2022.01.26: Lattice z-values
			ptAo.z = (ptA.z == undefined) ? undefined : (invertA.rotation ? ptA.z : -ptA.z);
			ptBo.z = (ptB.z == undefined) ? undefined : (invertB.rotation ? ptB.z : -ptB.z);
			
			var pts = [];
			pts.push({x:ptAo.x, y:ptAo.y, z:ptAo.z});
			pts.push({x:ptBo.x, y:ptBo.y, z:ptBo.z});
			
			var seg = {};
			seg.pts = pts;
			seg.offsetAB = offsetAB;
			seg.tagAB = {seTag:ScreenElementTag.ELEMENT_BASE}; // 2020.09.02: Use an object
			if (tileSegment.color != undefined) // 2020.09.02: Color
				seg.tagAB.color = tileSegment.color;
			if (tileSegment.colorId != undefined) // 2020.10.15: Color
				seg.tagAB.colorId = tileSegment.colorId;
			seg.offsetBA = offsetBA;
			seg.tagBA = {seTag:ScreenElementTag.ELEMENT_BASE};
			if (tileSegment.color != undefined) // 2020.09.02: Color
				seg.tagBA.color = tileSegment.color;
			if (tileSegment.colorId != undefined) // 2020.10.15: Color
				seg.tagBA.colorId = tileSegment.colorId;

			if (linez != undefined) // 2022.01.28: Lattice support: line z-value
			{
				seg.tagAB.linez = (invertL.rotation ? linez : -linez);
				seg.tagBA.linez = (invertL.rotation ? linez : -linez);
			}
			mirroredRotatedList.push(seg);

			if (mirror)
			{			
				// Mirror
				// 2020.08.31: Use the reflected points computed above
				var ptAm = {};
				ptAm.x = cA * ptAr.x  - sA * ptAr.y + center.x;
				ptAm.y = cA * ptAr.y  + sA * ptAr.x + center.y;
				var ptBm = {};
				ptBm.x = cA * ptBr.x  - sA * ptBr.y + center.x;
				ptBm.y = cA * ptBr.y  + sA * ptBr.x + center.y;
				// 2022.01.26: Lattice z-values
				ptAm.z = (ptAr.z == undefined) ? undefined : (invertA.rotation ? ptAr.z : -ptAr.z);
				ptBm.z = (ptBr.z == undefined) ? undefined : (invertB.rotation ? ptBr.z : -ptBr.z);

				var pts = [];
				pts.push({x:ptAm.x, y:ptAm.y, z:ptAm.z});
				pts.push({x:ptBm.x, y:ptBm.y, z:ptBm.z});
				
				var seg = {};
				seg.pts = pts;
				seg.offsetAB = offsetAB;
				seg.tagAB = {seTag:ScreenElementTag.ELEMENT_BASE};
				if (tileSegment.color != undefined) // 2020.09.02: Color
					seg.tagAB.color = tileSegment.color;
				if (tileSegment.colorId != undefined) // 2020.10.15: Color
					seg.tagAB.colorId = tileSegment.colorId;
				seg.offsetBA = offsetBA;
				seg.tagBA = {seTag:ScreenElementTag.ELEMENT_BASE};
				if (tileSegment.color != undefined) // 2020.09.02: Color
					seg.tagBA.color = tileSegment.color;
				if (tileSegment.colorId != undefined) // 2020.10.15: Color
					seg.tagBA.colorId = tileSegment.colorId;

				if (linez != undefined) // 2022.01.28: Lattice support: line z-value (for reflected line)
				{
					seg.tagAB.linez = (invertL.rotation ? linezr : -linezr);
					seg.tagBA.linez = (invertL.rotation ? linezr : -linezr);
				}

				mirroredRotatedList.push(seg);
			}

			if (invertA.onRotation)
				invertA.rotation = !invertA.rotation;

			if (invertB.onRotation)
				invertB.rotation = !invertB.rotation;

			if (invertL.onRotation)
				invertL.rotation = !invertL.rotation;
		} 
		
		// 2020.08.20: Added
		if (tileInfo.clipToTile)
		{
			// Add lines to the line list
			for (var i = mirroredRotatedList.length - 1; i >= 0; i--)
			{
				var seg = mirroredRotatedList[i];
				var clipToPoly = tileInfo.clippingPoints != undefined ? tileInfo.clippingPoints : tileInfo.points;
				var clipResult = MathUtil.ClipSegmentAgainstPolygon(seg.pts[0], seg.pts[1], clipToPoly);
				if (clipResult != undefined)
				{
					if (clipResult.ignoreSegment)
					{
						mirroredRotatedList.splice(i, 1);
					}
					else
					{
						seg.pts[0] = clipResult.ptA;
						seg.pts[1] = clipResult.ptB;
					}
				}
			}
		}

		return mirroredRotatedList;
	}


	/*----------------------------------------------------------------------------------------------*
	 * Calculate points and lines for snapping-to for the design editor
	 *----------------------------------------------------------------------------------------------*/
	var ScreenGenerator_CalcSnapData = function(designData, includeSurroundingTiles)
	{
		// Create a generator data object (a "generation")
		// 
		var generation = new ScreenGeneration();
			
		generation.designData = ScreenGenerator_CloneDesignForEditRender(designData);
		generation.state = ScreenGeneratorState.INITIAL;
		generation.genType = ScreenGenerationType.MINIMAL_DESIGN;

		ScreenGenerate_DoInitialCalcs(generation);

		if (includeSurroundingTiles)
			generation.tileLocations = ScreenGenerator_GenerateMinimalTileLocationList(generation);
		else
			generation.tileLocations = [{x:0, y:0}];
		
		
		ScreenGenerator_LineList_AddTileLines(generation, 0, generation.tileLocations.length, {addBiDirectional:false});
		
		return generation.lineList;
	}
	
	var ScreenGenerator_IsDataRenderable = function(screenData)
	{
		var isRenderable = true;
		
		// 2021.03.11: Images can have small tiles
		if (screenData.designType == DesignType.TYPE_IMAGE)
		{
			if (screenData.tiling.size < ScreenDesignLimits.MINIMUM_TILE_SIZE_IMAGE)
				isRenderable = false;
		}
		else 
		{	
			if (screenData.tiling.size < 10)
				isRenderable = false;
		}
			
		return isRenderable;
	}
	
	var ScreenGenerator_SegListAdd_ScaleCenterPointList_XXX = function(segList, pointList, outsideOffset, forwardTag, insideOffset, reverseTag)
	{
		var scale = 1.0; 
		
		// ASSUMPTION!!!
		// MORE THAN TWO POINTS IS A CLOSED POLYGON!!!
		
		var len = pointList.length;
		if (len == 2)
			len = 1;
			
		// Create segment list
		for (var i = 0; i < len; i++)
		{
			var ptA = pointList[i];
			var ptB = pointList[(i + 1) % pointList.length];
			if (outsideOffset != undefined)
				SegmentList.AddSegment(segList, ptA, ptB, outsideOffset, forwardTag);
			SegmentList.AddSegment(segList, ptB, ptA, insideOffset, reverseTag);
		}
	}
	
	var ScreenGenerator_SegListAdd_RotateTileSegments_XXX = function(segList, tileInfo, tileSegment, mirror, rotate)
	{
		// tileSegment: One segment to optionally mirror and rotate
		// tileInfo:    Tile center, start angle, side count
		
		var appendList = ScreenGenerator_CalcMirroredRotatedTileSegmentList(tileInfo, tileSegment, mirror, rotate);
		
		for (var i = 0; i < appendList.length; i++)
		{
			var s = appendList[i];
			ScreenGenerator_SegListAdd_ScaleCenterPointList(segList, s.pts, s.offsetAB, s.tagAB, s.offsetBA, s.tagBA);
		}
	}

	var ScreenGenerator_SegListAdd_TileSegments_XXX = function(segList, screenData, tesselation, tileLocations)
	{
		var locations;
		
		if (tileLocations == undefined)
			locations = ScreenGenerator_GenerateTileLocationList(screenData);
		else
			locations = tileLocations;
		
		// 2020.08.05: Use new function that can modify layout
		var tesselationlayout = ScreenGenerator_GetLayout(tesselation, screenData);
		
		var sList = screenData.elements;
		
		var baseTile = ScreenGenerator_GetUnitTileInfo(screenData);

		for (var k = 0; k < locations.length; k++)
		{
			var x = locations[k].x;
			var y = locations[k].y;
			var tileInfo = tesselation.CalcTileInfo(tesselationlayout, x, y);
			ScreenGenerator_AddBoundsToTileInfo(tileInfo);
			
			if (sList != undefined)
			{
				for (var i = 0; i < sList.length; i++)
				{
					var s = sList[i];
					
					if (s.visible)
					{
						var tileSegment = {};
						
						tileSegment.ptA = {
							x:s.ptA.x * baseTile.square.size + baseTile.square.offset.x - baseTile.center.x, 
							y:s.ptA.y * baseTile.square.size + baseTile.square.offset.y - baseTile.center.y};
							
						tileSegment.ptB = {
							x:s.ptB.x * baseTile.square.size + baseTile.square.offset.x - baseTile.center.x , 
							y:s.ptB.y * baseTile.square.size + baseTile.square.offset.y - baseTile.center.y};
							
						tileSegment.width = s.width;
												
						ScreenGenerator_SegListAdd_RotateTileSegments(segList, tileInfo, tileSegment, s.mirror, s.rotate);
					}
				}
			}
		}
	}
	
	var ScreenGenerator_SegListAdd_Tesselation_XXX = function(segList, screenData, tileLocations /* optional */)
	{
		var tf = ScreenGenerator_GetTesselationObject(screenData);
		
		ScreenGenerator_SegListAdd_TileSegments_XXX(segList, screenData, tf, tileLocations);
	}
	
	var ScreenGenerator_BoundsIntersectsFrame_XXX = function(tileBounds, framePoints)
	{
		var intersects = false;
		
		var tilePoints = [];
		tilePoints.push({x:tileBounds.min.x, y:tileBounds.min.y});
		tilePoints.push({x:tileBounds.min.x, y:tileBounds.max.y});
		tilePoints.push({x:tileBounds.max.x, y:tileBounds.max.y});
		tilePoints.push({x:tileBounds.max.x, y:tileBounds.min.y});
		
		for (var i = 0; i < framePoints.length && !intersects; i++)
		{
			var ptA = framePoints[i];
			var ptB = framePoints[(i+1) % framePoints.length];
			
			for (var j = 0; j < tilePoints.length && !intersects; j++)
			{
				var info = MathUtil.CalcSegmentIntersection(ptA, ptB, tilePoints[j], tilePoints[(j + 1) % tilePoints.length]);
				if (info != undefined)
					intersects = (info.c1 >= -0.1 && info.c1 <= 1.01 && info.c2 >= -0.1 && info.c2 <= 1.01);
			}
		}
		
		return intersects;
	}
	
	/*----------------------------------------------------------------------------------------------*
	 * Show Symmetry Options
	 *----------------------------------------------------------------------------------------------*/
	var ScreenGenerator_ShowSymmetryOptions = function(designData)
	{
		return designData.tiling.shape == Tiling.MANDALA;
	}
	
	/*----------------------------------------------------------------------------------------------*
	 * Show Symmetry Options
	 *		2021.08.30: Added
	 *	tile:
	 *		x, y, edge
	 *----------------------------------------------------------------------------------------------*/
	var ScreenGenerator_AdjacentTile = function(designData, tile)
	{
		var adjacentTile = undefined;
		var tf = ScreenGenerator_GetTesselationObject(designData);

		if (tf.AdjacentTile != undefined)
			adjacentTile = tf.AdjacentTile(tile);

		return adjacentTile;
	}


	/*----------------------------------------------------------------------------------------------*
	 * Public API
	 *----------------------------------------------------------------------------------------------*/
	return {
		Create:								ScreenGenerator_Create,
		IsComplete:							ScreenGenerator_IsComplete,
		EndOfPhase:							ScreenGenerator_EndOfPhase,
		PhaseAdvance:						ScreenGenerator_PhaseAdvance,
		Advance:							ScreenGenerator_Advance,
		CalcMirroredRotatedTileSegmentList:	ScreenGenerator_CalcMirroredRotatedTileSegmentList,
		GenerateScreen:						ScreenGenerator_GenerateScreen,
		DesignDataChanged:					ScreenGenerator_DesignDataChanged,
		Generate:							ScreenGenerator_Generate,
		GenerateScreenAroundCenter:			ScreenGenerator_GenerateScreenAroundCenter,
		GetEditTileInfo:					ScreenGenerator_GetUnitTileInfo,
		IsDataRenderable:					ScreenGenerator_IsDataRenderable,
		CalcSnapData:						ScreenGenerator_CalcSnapData,
		ShowSymmetryOptions:				ScreenGenerator_ShowSymmetryOptions,
		AdjacentTile:						ScreenGenerator_AdjacentTile,	// 2021.08.30
		GetSize:							ScreenGenerator_GetSize // 2021.07.06
	};
}());



/*-----------------------------------------------*
 * Exports
 *-----------------------------------------------*/
export { ScreenDesignerData };
export { ScreenDesignLimits, ScreenDesignerDataType };
export { ScreenGenerator, ScreenGenerationType };
export { FrameShape, FrameRender };
export { DesignType };
export { ScreenDesignerWorkbook };
