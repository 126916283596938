// GfxDrawing.js


//------------------------------------------------------------
//
//	GfxPath (object/API)
//
//------------------------------------------------------------
/*-----------------------------------------------*
 * GfxPath: list of operations (move, line, arc, quadbez, close)
 * describing a path
 *
 * [
 *    {op, x, y, p1, p2, p3 }
 *    ...
 * ] 
 *
 * Functions:
 *
 * 
 *-----------------------------------------------*/

function GfxPath()
{
	this.gfxpath = [];
}

GfxPath.Op = Object.freeze({
		NOOP:	0,
		MOVETO:	1,
		LINETO:	2,
		ARC:	3,
		QUADBEZ:4,
		CLOSE:  5,
		CIRCLE: 6,
		TEXT:   7
	});

GfxPath.OpStr = Object.freeze(["NoOp", "MoveTo", "LineTo", "Arc", "QuadBez", "Close", "Circle", "Text"]);

GfxPath.prototype.Is2D = function()
{
	return true;
}

GfxPath.prototype.GetOpCount = function()
{
	return this.gfxpath.length;
}

GfxPath.prototype.GetOp = function(opIdx)
{
	var op = undefined;

	if (opIdx >= 0 && opIdx < this.gfxpath.length)
		op = this.gfxpath[opIdx];

	return op;
}

GfxPath.prototype.Close = function(pt)
{
	let op = {op: GfxPath.Op.CLOSE };
	this.gfxpath.push(op);
}

GfxPath.prototype.MoveTo = function(pt)
{
	let op = {op: GfxPath.Op.MOVETO, pt:{x:pt.x, y:pt.y} };
	this.gfxpath.push(op);
}

GfxPath.prototype.LineTo = function(pt)
{
	let op = {op: GfxPath.Op.LINETO, pt:{x:pt.x, y:pt.y} };
	this.gfxpath.push(op);
}

GfxPath.prototype.Arc = function(pt, radius, startAngle, endAngle, ccw)
{
	let op = {op: GfxPath.Op.ARC, pt:{x:pt.x, y:pt.y}, r:radius, start:startAngle, end:endAngle, ccw:ccw };
	this.gfxpath.push(op);
}

GfxPath.prototype.Circle = function(pt, radius)
{
	let op = {op: GfxPath.Op.CIRCLE, pt:{x:pt.x, y:pt.y}, r:radius };
	this.gfxpath.push(op);
}

GfxPath.prototype.Text = function(pt, str)
{
	let op = {op: GfxPath.Op.TEXT, pt:{x:pt.x, y:pt.y}, str:str };
	this.gfxpath.push(op);
}


//-------------------------------------------------------------------------------------
//	Add Perforation
//-------------------------------------------------------------------------------------
GfxPath.prototype.AddPerforation = function(ptA, ptB, spacing, radius, config = undefined)
{
	let len = Math.sqrt((ptA.x - ptB.x) * (ptA.x - ptB.x) + (ptA.y - ptB.y) * (ptA.y - ptB.y));
	var uv = {x: (ptB.x - ptA.x)/len, y: (ptB.y - ptA.y)/len};
	var count = Math.floor(len/spacing);
	var pt = {};
	var distribute = (config != undefined) ? config.distribute : undefined;
	var circles = (config != undefined && config.style == "circles") ? true : false;
	
	if (distribute)
	{
		// Distribute the perforation evenly. The spacing will not be exactly 'spacing', 
		// but the spacing between the dots and between the first/last dot and the start/end
		// of the line will be the same.
		// Note that we start at 1 and end at count-1 because we know that 0 and count will
		// be exactly on the start and end of the line, which is unnecessary
		for (var kk = 1; kk < count; kk++)
		{
			pt.x = kk * (ptB.x - ptA.x)/count + ptA.x;
			pt.y = kk * (ptB.y - ptA.y)/count + ptA.y;
			
			if (circles)
				this.Circle(pt, radius);
			else
			{
				this.MoveTo({x:pt.x - uv.x * radius, y:pt.y - uv.y * radius});
				this.LineTo({x:pt.x + uv.x * radius, y:pt.y + uv.y * radius});
			}
		}
	}
	else
	{
		// Keep the spacing exactly at 'spacing'
		// If the offset is zero, then the line is a multiple of 'spacing', otherwise
		// offset indicates how much to shift the first dot. Also, if offset is zero,
		// then we don't do the first and last dot, since these will be on the start 
		// and end of the line.
		var offset = (len - count * spacing)/2;
		var adjust = (offset == 0) ? 1 : 0;
		var start;
		
		if (len == count * spacing)
		{
			offset = 0;
			start = 1;
		}
		else
		{
			offset = (len - count * spacing)/2 + spacing/2;
			start = 0;
		}
		
		for (var kk = start; kk < count; kk++)
		{
			pt.x = (offset + kk * spacing) * uv.x + ptA.x;
			pt.y = (offset + kk * spacing) * uv.y + ptA.y;
			if (circles)
				this.Circle(pt, radius);
			else
			{
				this.MoveTo({x:pt.x - uv.x * radius, y:pt.y - uv.y * radius});
				this.LineTo({x:pt.x + uv.x * radius, y:pt.y + uv.y * radius});
			}
		}
	}
}


//-------------------------------------------------------------------------------------
//	Find Bounds
//-------------------------------------------------------------------------------------
GfxPath.prototype.FindBounds = function(bounds = undefined, margin = 0)
{
	for (var i = 0; i < this.gfxpath.length; i++)
	{
		var minx = undefined;
		var miny = undefined;
		var maxx = undefined;
		var maxy = undefined;
		
		let op = this.gfxpath[i];
		
		if (op.op == GfxPath.Op.MOVETO || op.op == GfxPath.Op.LINETO)
		{
			minx = op.pt.x;
			miny = op.pt.y;
			maxx = op.pt.x;
			maxy = op.pt.y;
		}
		else if (op.op == GfxPath.Op.ARC || op.op == GfxPath.Op.CIRCLE)
		{
			minx = op.pt.x - op.r;
			miny = op.pt.y - op.r;
			maxx = op.pt.x + op.r;
			maxy = op.pt.y + op.r;
		}
		
		// Adjust the min and max of this op by the provided margin
		// (This is used by the caller to take the line width into account for stroked paths)
		minx -= margin;
		miny -= margin;
		maxx += margin;
		maxy += margin;
		
		if (bounds == undefined && minx != undefined)
		{
			bounds = {min:{x:minx, y:miny}, max:{x:maxx, y:maxy}}
		}
		else
		{
			if (bounds.min.x > minx)
				bounds.min.x = minx;
				
			if (bounds.min.y > miny)
				bounds.min.y = miny;
				
			if (bounds.max.x < maxx)
				bounds.max.x = maxx;
				
			if (bounds.max.y < maxy)
				bounds.max.y = maxy;
		}
	}
	
	return bounds;
}

//-------------------------------------------------------------------------------------
//	Append
//-------------------------------------------------------------------------------------
GfxPath.prototype.Append = function(pathToAppend)
{
	this.gfxpath = this.gfxpath.concat(pathToAppend.gfxpath);
}

//-------------------------------------------------------------------------------------
//	Reverse
//		Reverse the order of operations in a path. The path should render the same, 
//		just in the opposite order. 
//-------------------------------------------------------------------------------------
GfxPath.prototype.Reverse = function()
{
	let reversed = [];
	let idx = this.gfxpath.length - 1;
	let addClosePath = false;
	let startedSubPath = false;
	
	while (idx >= 0)
	{
		let op = this.gfxpath[idx];
		
		if (op.op == GfxPath.Op.CLOSE)
		{
			addClosePath = true;
		}
		else if (op.op == GfxPath.Op.TEXT)
		{
			reversed.push(op);
		}
		else if (op.op == GfxPath.Op.NOOP)
		{
			reversed.push(op);
		}
		else if (op.op == GfxPath.Op.CIRCLE)
		{
			reversed.push(op);
		}
		else if (op.op == GfxPath.Op.LINETO)
		{
			// If we haven't started a subpath, then this is the last draw
			// operation in a subpath. Change it to a moveTo and indicate
			// a subpath has started
			if (!startedSubPath)
			{
				op.op = GfxPath.Op.MOVETO;
				reversed.push(op);
				startedSubPath = true;
			}
			// If we are already in a subpath, then keep it as a lineTo
			else
			{
				reversed.push(op);
			}
		}
		else if (op.op == GfxPath.Op.MOVETO)
		{
			// If we haven't started a subpath, then we really don't expect to see
			// a stand-alone moveTo. Add it as is.
			if (!startedSubPath)
			{
				reversed.push(op);
			}
			// If are already in a subpath, then this is the last (because it
			// was the first) op, so change it to a lineTo and indicate we
			// are no longer in a subpath
			else
			{
				op.op = GfxPath.Op.LINETO;
				reversed.push(op);
				startedSubPath = false;
			}
			
			// If the path was closed in the forward direction, then we need to close it here
			if (addClosePath)
			{
				reversed.push({op: GfxPath.Op.CLOSE });
				addClosePath = false;
			}
		}
		else if (op.op == GfxPath.Op.ARC)
		{
			// We need to do a moveTo or lineTo to the start of the reversed arc,
			// which is end of the original arc. This is required for SVG arcs to
			// be work correctly.
			let x = op.pt.x + op.r * Math.cos(op.end);
			let y = op.pt.y + op.r * Math.sin(op.end);
			
			// If we haven't started a subpath, then this is the last draw
			// operation in a subpath. Add a moveTo to what will be the start
			// of the arc and indicate a subpath has started
			if (!startedSubPath)
			{
				reversed.push({op: GfxPath.Op.MOVETO, pt:{x, y} });
				startedSubPath = true;
			}
			// Otherwise we have a subpath and add a lineTo
			{
				reversed.push( {op: GfxPath.Op.LINETO, pt:{x, y} });
			}

			// Reverse the arc direction
			let end = op.end;
			op.end = op.start;
			op.start = end;
			op.ccw = !op.ccw
			reversed.push(op);
		}
		else if (op.op == GfxPath.Op.QUADBEZ)
		{
			// TODO: Reverse the order of the curve
			// TODO: Test for startedSubPath
			reversed.push(op);
		}
		else
		{
			console.log("GfxPath.prototype.Reverse: Unknown op: " + JSON.stringify(op));
		}
		
		idx--;
	}

	// If the path was closed in the forward direction, then we need to close it here
	if (addClosePath)
	{
		reversed.push({op: GfxPath.Op.CLOSE });
		addClosePath = false;
	}
	
	
	this.gfxpath = reversed;
	
	return this;
}


//-------------------------------------------------------------------------------------
//	Log
//-------------------------------------------------------------------------------------
GfxPath.prototype.Log = function()
{
	console.log("Op count: " + this.gfxpath.length);
	
	let str = "";
	this.gfxpath.forEach(op => { str += "  " + GfxPath.OpStr[op.op] + "\n" });
	
	console.log(str);
}

//------------------------------------------------------------
//
//	GfxDrawing (object/API)
//
//------------------------------------------------------------
//
function GfxDrawing()
{
	this.pathList = [];
}

//	Default path properties
GfxDrawing.defaultPathSettings = 
	{
	//	id:				undefined,
		fillPath:		false,
		fillColor:		"#000000",
		strokePath:		true,
		strokeColor:	"#000000",
		strokeWidth:	1
	}

GfxDrawing.prototype.GetPathCount = function()
{
	return this.pathList.length;
}

GfxDrawing.prototype.AddPath = function(gfxPath, settings = undefined, pathId = undefined)
{
	var path = {gfxPath: gfxPath, settings: {}};
	
	if (settings != undefined)
		Object.assign(path.settings, settings);
	
	if (pathId != undefined)
		Object.assign(path.settings, {id: pathId});
		
	this.pathList.push(path);	
}

//	2021.05.30: Added
//	groupInfo: {name:"someName", id:"someId"}
GfxDrawing.prototype.StartGroup = function(groupInfo = undefined)
{
	var emptyGfxPath = new GfxPath();
	
	var group = {action:"startGroup"};
	
	group.name = (groupInfo != undefined && groupInfo.name != undefined) ? groupInfo.name : "aGrpName";
	group.id = (groupInfo != undefined && groupInfo.id != undefined) ? groupInfo.id : "aGrpId";
	
	var path = {gfxPath: emptyGfxPath, settings:{}, group:group};
			
	this.pathList.push(path);	
}

//	2021.05.30: Added
GfxDrawing.prototype.EndGroup = function()
{
	var emptyGfxPath = new GfxPath();
	var path = {gfxPath: emptyGfxPath, settings:{}, group:{action:"endGroup"}};
	this.pathList.push(path);	
}


GfxDrawing.prototype.GetGfxPath = function(pathIdx)
{
	var path = undefined;

	if (pathIdx >= 0 && pathIdx < this.pathList.length)
		path = this.pathList[pathIdx];

	return path.gfxPath;
}

GfxDrawing.prototype.GetGfxPathById = function(pathId)
{
	var path = undefined;
	
	for (var pathIdx = 0; pathIdx < this.pathList.length && path == undefined; pathIdx++)
		if (this.pathList[pathIdx].settings.id == pathId)
			path = this.pathList[pathIdx];

	return path.gfxPath;
}


//	2021.05.30: Added "key" param to provide means to return other properties
GfxDrawing.prototype.GetPathSettings = function(pathIdx, key = undefined)
{
	var path = undefined;

	if (pathIdx >= 0 && pathIdx < this.pathList.length)
		path = this.pathList[pathIdx];

	if (key == undefined)
		key = "settings";
		
	return path[key];
}

GfxDrawing.prototype.SetPathSettings = function(pathIdx, settings)
{
	var path = undefined;

	if (pathIdx >= 0 && pathIdx < this.pathList.length)
		path = this.pathList[pathIdx];

	Object.assign(path.settings, settings);
}


GfxDrawing.prototype.FindBounds = function(bounds = undefined)
{
	for (var pathIdx = 0; pathIdx < this.pathList.length; pathIdx++)
	{
		var path = this.pathList[pathIdx];
		let margin = (path.settings.strokePath) ? path.settings.strokeWidth : 0;
		bounds = path.gfxPath.FindBounds(bounds, margin)
	}
			
	return bounds
}



export { GfxPath, GfxDrawing };
