
import { ScreenGenerator, FrameRender, DesignType } from "./ScreenDesignGenerator.js";
import { BasicUnitsMgr, Polygon_CalcCentroid, MathUtil, SegmentList, VectorCornerStyle } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { Transform } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { Gradient } from "../../VectorUtilsJS/src/GradientLib.js";

// Renderer
//
// renderRef: (ultimately a private reference)
//   theContext
// renderSettings: (specific to how the shapes should be drawn)
//   cornerStyle
//   cornerSize
//   outerDocumentRect (for 'inside only' frame)
//   pointSize (for renderPolylistPoints)
//   renderFill
//   renderLine
// renderConfig: (configuration for the render, but not settings)
//   theTransform
// polygonList: (the data)
//

var GetPropertyList = function(obj, props)
{
	let list = [];
	props.forEach(p => list[p]=obj[p]);
	return list;
}

var SetPropertyList = function(obj, list)
{
	let old = [];
	Object.keys(list).forEach(p => {old[p]=obj[p];obj[p]=list[p]});
	return old;
}


var PolygonListRenderer = (function() {

	var moveOrLineToPt = function(theContext, thePoint, isMoveToFlag, theTransform)
	{
		if (theTransform != undefined)
			thePoint = theTransform.xfrm(thePoint);
			
		if (isMoveToFlag)
			theContext.moveTo(thePoint.x, thePoint.y);
		else
			theContext.lineTo(thePoint.x, thePoint.y);
	}

	// 2022.01.27: Added closePolygon param
	var addSimplePolygonToPath = function(theContext, simplePoly, theTransform, closePolygon = true)
	{
		for (var i = 0; i < simplePoly.length; i++)
			moveOrLineToPt(theContext, simplePoly[i], (i==0), theTransform);

		if (closePolygon)
			theContext.closePath();
	}

	var addSimplePolygonToPath_QuadCurves = function(theContext, simplePoly, cornerSize, theTransform)
	{
		if (theTransform != undefined)
			cornerSize = theTransform.scaleNum(cornerSize);
			
		var len = simplePoly.length;
		for (var i = 0; i < simplePoly.length; i++)
		{
			var ptA = simplePoly[i];
			var ptB = simplePoly[(i + 1) % len];
			var ptC = simplePoly[(i + 2) % len];
			var renderCurve = true;
			var quadParams = undefined;
			
			if (ptB.omitCurve != undefined)
				renderCurve = false;
			
			if (theTransform != undefined)
			{
				ptA = theTransform.xfrm(ptA);
				ptB = theTransform.xfrm(ptB);
				ptC = theTransform.xfrm(ptC);
			}
			
			if (renderCurve)
				quadParams = MathUtil.CalcQuadBezierParams(ptA, ptB, ptC, cornerSize);
			
			if (quadParams != undefined)
			{
				moveOrLineToPt(theContext, quadParams.ptTangentAB, (i==0));

				theContext.quadraticCurveTo(ptB.x, ptB.y, quadParams.ptTangentBC.x, quadParams.ptTangentBC.y);
			}
			else
			{
				moveOrLineToPt(theContext, ptB, (i==0));
			}
				
		}
		theContext.closePath();
	}


	var addSimplePolygonToPath_ArcCurves = function(theContext, simplePoly, cornerSize, theTransform)
	{
		if (theTransform != undefined)
			cornerSize = theTransform.scaleNum(cornerSize);
		
		var prevPtTangentBC = undefined; // 2021.07.17: Don't draw zero-length lines between curves
			
		var len = simplePoly.length;
		for (var i = 0; i < simplePoly.length; i++)
		{
			var ptA = simplePoly[i];
			var ptB = simplePoly[(i + 1) % len];
			var ptC = simplePoly[(i + 2) % len];
			var renderCurve = true;
			var arcParams = undefined;
			
			if (ptB.omitCurve != undefined)
				renderCurve = false;
			
			if (theTransform != undefined)
			{
				ptA = theTransform.xfrm(ptA);
				ptB = theTransform.xfrm(ptB);
				ptC = theTransform.xfrm(ptC);
			}
			
			if (renderCurve)
				arcParams = MathUtil.CalcTangentArcParams(ptA, ptB, ptC, cornerSize);
			
			if (arcParams != undefined)
			{
				if (prevPtTangentBC == undefined || MathUtil.DistanceBetween(arcParams.ptTangentAB, prevPtTangentBC) > 0.1)
					moveOrLineToPt(theContext, arcParams.ptTangentAB, (i==0));
				theContext.arc(arcParams.center.x, arcParams.center.y, arcParams.radius, arcParams.startAngle, arcParams.endAngle, arcParams.ccw);
				prevPtTangentBC = arcParams.ptTangentBC; // 2021.07.17
			}
			else
			{
				moveOrLineToPt(theContext, ptB, (i==0));
				prevPtTangentBC = undefined; // 2021.07.17
			}
				
		}
		theContext.closePath();
	}

	//----------------------------------------------------------------------------------------------------
	//	Add Simple Polygon To Path: Per Vertex Curves
	//		Add the polygon to the path where vertex can have a different curve setting or use the
	//		setting in the options parameter
	//
	//	2022.02.01: Added
	//	2022.02.02: Implement "half curves" for arcs. A "half curve" is the curve at the vertex of a
	//	polygon that is half of the vertex of a larger polygon. The half curve needs an extra point,
	//	which is stored with the vertex and is used as the half curve flag. These values are set in
	//	ConstructAreaPolygons in VectorUtilLib.js
	//	2022.02.07: "Half curves" now have a "render full curve" option, which is used when there is an
	//	endcap and the endcap type is BUTT. This is the one case where the 'area' (color) polygon corner
	//	needs to render the full curve (and also needs the extra data in halfCurvePt)
	//----------------------------------------------------------------------------------------------------
	var addSimplePolygonToPath_perVertexCurves = function(theContext, simplePoly, options, theTransform)
	{
		let cornerStyle = (options.cornerStyle != undefined) ? options.cornerStyle : VectorCornerStyle.ANGLE;
		let cornerSize = (options.cornerSize != undefined) ? options.cornerSize : 0;
		let isClosed = (options.isClosed != undefined) ? options.isClosed : true;

		if (theTransform != undefined)
			cornerSize = theTransform.scaleNum(cornerSize);

		// 2022.02.05: Renamed
		var prevEndPt = undefined; // 2021.07.17: Don't draw zero-length lines between curves

		var len = simplePoly.length;
		for (var i = 0; i < simplePoly.length; i++)
		{
			// 2022.02.11: Start with vertex 0 instead of vertex 1
			var ptA = simplePoly[(i - 1 + len) % len];
			var ptB = simplePoly[i];
			var ptC = simplePoly[(i + 1) % len];

			var renderCurve = true;
			var renderHalfCurve = undefined; // 2022.02.02
			var renderFullCurve = false; // 2022.02.07
			var curveParams = undefined;

			if (ptB.omitCurve != undefined)
				renderCurve = false;

			// 2022.02.11: Don't attempt curves on the first and last points of an open polygon
			if (!isClosed && (i == 0 || i == len -1))
				renderCurve = false;

			// 2022.02.02: Handle "half curves". See note above.
			if (renderCurve && ptB.halfCurvePt != undefined)
			{
				renderHalfCurve = ptB.halfCurvePt.which;
				renderFullCurve = ptB.halfCurvePt.renderFullCurve;
				if (ptB.halfCurvePt.which == "prev")
					ptA = ptB.halfCurvePt;
				else
					ptC = ptB.halfCurvePt;
			}

			if (theTransform != undefined)
			{
				ptA = theTransform.xfrm(ptA);
				ptB = theTransform.xfrm(ptB);
				ptC = theTransform.xfrm(ptC);
			}

			if (renderCurve && cornerSize > 0)
			{
				if (cornerStyle == VectorCornerStyle.ARC)
					curveParams = MathUtil.CalcTangentArcParams(ptA, ptB, ptC, {distance:cornerSize, curveLimit:options.curveLimit});
				else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
					curveParams = MathUtil.CalcQuadBezierParams(ptA, ptB, ptC, {distance:cornerSize, curveLimit:options.curveLimit});
			}

			if (curveParams == undefined)
			{
				moveOrLineToPt(theContext, ptB, (i==0));
				prevEndPt = ptB; // 2021.07.17
			}
			else if (cornerStyle == VectorCornerStyle.ARC)
			{
				var startPt = undefined;
				var endPt = undefined;
				var startAngle;
				var endAngle;

				if (renderHalfCurve == undefined || renderFullCurve)
				{
					startAngle = curveParams.startAngle;
					startPt    = curveParams.ptTangentAB;

					endAngle   = curveParams.endAngle;
					endPt      = curveParams.ptTangentBC;
				}
				// 2022.02.02: Handle "half curves". Since we are doing half the curve, we need to use the middle
				// angle, between the start and end angle, and update the start or end angle appropriately.
				// 2022.02.05: The meaning of "prev" and "next" are a little confusing. I will describe how they are
				// interpreted here, which will be the reference definition.
				//   "prev": The previous offset vertex was provided to define the complete curve. Therefore, we are
				//           rendering the second half of the arc, so we are starting at the middle.
				//   "next": The next offset vertex was provided to define the complete curve. Therefore, we are
				//           rendering the first half of the arc, so we are ending at the middle.
				else if (renderHalfCurve == "prev")
				{
					startAngle = curveParams.middleAngle;
					startPt    = curveParams.ptMiddle;

					endAngle   = curveParams.endAngle;
					endPt      = curveParams.ptTangentBC;
				}
				else // "next"
				{
					startAngle = curveParams.startAngle;
					startPt    = curveParams.ptTangentAB;

					endAngle   = curveParams.middleAngle;
					endPt      = curveParams.ptMiddle;
				}

				if (prevEndPt == undefined || MathUtil.DistanceBetween(prevEndPt, startPt) > 0.1)
					moveOrLineToPt(theContext, startPt, (i==0));

				theContext.arc(curveParams.center.x, curveParams.center.y, curveParams.radius, startAngle, endAngle, curveParams.ccw);
				prevEndPt = endPt; // 2021.07.17
			}
			else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
			{
				// 2022.03.10: Add 'half curve'
				var startPt   = undefined;
				var endPt     = undefined;
				var controlPt = undefined;

				if (renderHalfCurve == undefined || renderFullCurve)
				{
					startPt    = curveParams.ptTangentAB;
					controlPt  = ptB;
					endPt      = curveParams.ptTangentBC;
				}
				else if (renderHalfCurve == "prev")
				{
					startPt    = curveParams.ptMiddle;
					controlPt  = curveParams.ptMidBC;
					endPt      = curveParams.ptTangentBC;
				}
				else // "next"
				{
					startPt    = curveParams.ptTangentAB;
					controlPt  = curveParams.ptMidAB;
					endPt      = curveParams.ptMiddle;
				}

				if (prevEndPt == undefined || MathUtil.DistanceBetween(prevEndPt, startPt) > 0.1)
					moveOrLineToPt(theContext, startPt, (i==0));

				theContext.quadraticCurveTo(controlPt.x, controlPt.y, endPt.x, endPt.y);

				prevEndPt = endPt; // 2022.02.01
			}
		}

		if (isClosed)
			theContext.closePath();
	}

	//var polyListStroke = function(theContext, polyList, cornerStyle, cornerSize, theTransform)
	//{
	//	for (var p = 0; p < polyList.GetPolygonCount(); p++)
	//	{
	//		theContext.beginPath();
	//		
	//		if (cornerStyle == VectorCornerStyle.ANGLE)
	//			addSimplePolygonToPath(theContext, polyList.GetPolygonPoints(p), theTransform);
	//
	//		else if (cornerStyle == VectorCornerStyle.ARC)
	//			addSimplePolygonToPath_ArcCurves(theContext, polyList.GetPolygonPoints(p), cornerSize, theTransform);
	//
	//		else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
	//			addSimplePolygonToPath_QuadCurves(theContext, polyList.GetPolygonPoints(p), cornerSize, theTransform);
	//
	//		theContext.stroke();
	//	}
	//}

	var polyListRender = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		var cornerStyle = renderSettings.cornerStyle;
		var cornerSize = renderSettings.cornerSize;
		var addOuterDocumentPath = (renderSettings.outerDocumentRect != undefined);
		var outerDocumentRect = renderSettings.outerDocumentRect;
		var renderFill = renderSettings.renderFill;
		var renderLine = renderSettings.renderLine;
		var renderOptions = {cornerStyle, cornerSize, isClosed:true}; // 2022.02.01: Options for new _perVertexCurves function
		var components = (renderSettings.components != undefined) ? renderSettings.components : ["designEdge"]; // 2022.03.01

		theContext.beginPath();
		
		// If the design does not have an outside frame, then we need to add something to enclose the 
		// the render, otherwise the inside and outside areas are reversed.
		// DDK 2018.01.19: We only need to add the rect to enclose the render if we are
		// going to do a fill. It is not necessary with just a stroke. This removes the 
		// unintended rect on exported PNGs when "inside frame only" is set
		if (addOuterDocumentPath && renderFill)
		{
			theContext.moveTo(outerDocumentRect.xMin, outerDocumentRect.yMin);
			theContext.lineTo(outerDocumentRect.xMin, outerDocumentRect.yMax);
			theContext.lineTo(outerDocumentRect.xMax, outerDocumentRect.yMax);
			theContext.lineTo(outerDocumentRect.xMax, outerDocumentRect.yMin);
			theContext.closePath();
		}
			
		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			let tagInfo = polygonList.GetPolygonTagInfo(p); // 2020.08.28
			let component = (tagInfo != undefined && tagInfo.component != undefined) ? tagInfo.component : ""; // 2022.03.01
			let closedPoly = (tagInfo != undefined && tagInfo.isClosed != undefined) ? tagInfo.isClosed : true; // 2022.03.02

			// 2022.02.02: Use open or closed flag from polygon tag
			renderOptions.isClosed = closedPoly;
			
			// 2022.03.02: Use component name instead of series of if-else statements
			if (components.includes(component))
				addSimplePolygonToPath_perVertexCurves(theContext, polygonList.GetPolygonPoints(p), renderOptions, theTransform);
		}
				
		if (renderFill)
			theContext.fill();
			
		if (renderLine)
			theContext.stroke();
	}
	
	//----------------------------------------------------------------------------------------------------
	//	polyListRenderGradient
	//		2021.06.22: Added
	//----------------------------------------------------------------------------------------------------
	var polyListRenderGradient = function(polygonList, renderRef, renderSettings, renderConfig, gradientSettings)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		var cornerStyle = renderSettings.cornerStyle;
		var cornerSize = renderSettings.cornerSize;
		var addOuterDocumentPath = (renderSettings.outerDocumentRect != undefined);
		var outerDocumentRect = renderSettings.outerDocumentRect;
		var renderFill = renderSettings.renderFill;
		var renderLine = renderSettings.renderLine;
		var renderOptions = {cornerStyle, cornerSize}; // 2022.02.10: Options new _perVertexCurves function

		let bounds = polygonList.FindBounds();
		let radius = Math.sqrt((bounds.max.x - bounds.min.x) * (bounds.max.x - bounds.min.x) + (bounds.max.y - bounds.min.y) * (bounds.max.y - bounds.min.y))/2;
		
		let restoreContextSettings = ["fillStyle", "strokeStyle", "lineWidth", "lineJoin"];
		let restoreContext = {};
		restoreContextSettings.forEach(s => restoreContext[s] = theContext[s]);
		
		theContext.lineWidth = 0.25;
		theContext.lineJoin = "bevel";
		
		
		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			let tagInfo = polygonList.GetPolygonTagInfo(p);
			
// 			if (tagInfo != undefined && (tagInfo.isFill || tagInfo.isFrame))
// 			{ /* do nothing */ }
// 			else if (tagInfo != undefined && tagInfo.isClosed != undefined && !tagInfo.isClosed)
// 			{ /* 2022.02.10: don't render lattice edges */ }
// 			else
			if (tagInfo != undefined && tagInfo.component == "designEdge" && (tagInfo.isFrame == undefined || !tagInfo.isFrame))
			{
				var polygon = polygonList.GetPolygonPoints(p);
				var centroid = Polygon_CalcCentroid(polygon);
				
				var clr = Gradient.CalcColor(gradientSettings, centroid);
				
				theContext.fillStyle = clr;
				theContext.strokeStyle = clr;
				theContext.beginPath();
				
				// 2022.02.10: Use combined function instead of three separate calls
				addSimplePolygonToPath_perVertexCurves(theContext, polygonList.GetPolygonPoints(p), renderOptions, theTransform);

				theContext.fill();
				theContext.stroke();
			}
		}
		
		restoreContextSettings.forEach(s => theContext[s] = restoreContext[s]);
	}
	
	//----------------------------------------------------------------------------------------------------
	//	PolyList Render Shadow
	//----------------------------------------------------------------------------------------------------
	var polyListRenderShadow = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		var cornerStyle = renderSettings.cornerStyle;
		var cornerSize = renderSettings.cornerSize;
		var renderOptions = {cornerStyle, cornerSize, isClosed:true}; // 2022.02.10: Options new _perVertexCurves function
		var components = (renderSettings.components != undefined) ? renderSettings.components : ["designEdge"]; // 2022.03.01

		// 2020.09.09
		let filter =  "blur(" + renderSettings.shadowBlur + "px)";
		let oldSettings = SetPropertyList(theContext, {strokeStyle:renderSettings.shadowColor, filter, lineWidth:renderSettings.shadowWidth, fillStyle:renderSettings.shadowColor});
		
		// 2022.03.10: Render solid areas first (i.e., not lattice edges)
		theContext.beginPath();
		
		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			let tagInfo = polygonList.GetPolygonTagInfo(p); // 2020.08.28
			let component = (tagInfo != undefined && tagInfo.component != undefined) ? tagInfo.component : ""; // 2022.03.01
			let closedPoly = (tagInfo != undefined && tagInfo.isClosed != undefined) ? tagInfo.isClosed : true; // 2022.03.02

			// 2022.02.02: Use open or closed flag from polygon tag
			renderOptions.isClosed = closedPoly;

			// 2022.03.02: Use component name instead of series of if-else statements
			if (components.includes(component))
				addSimplePolygonToPath_perVertexCurves(theContext, polygonList.GetPolygonPoints(p), renderOptions, theTransform);
		}
		
		if (renderSettings.renderShadowFill)
			theContext.fill();
			
		if (renderSettings.renderShadowLine)
			theContext.stroke();

		// 2022.03.10: Render lattice edges and any other lines
		if (renderSettings.renderShadowLine && components.includes("latticeEdge"))
		{
			let count = 0;
			theContext.beginPath();

			for (var p = 0; p < polygonList.GetPolygonCount(); p++)
			{
				let tagInfo = polygonList.GetPolygonTagInfo(p);

// 				if (tagInfo != undefined && tagInfo.isFill)
// 				{ /* do nothing */ }
// 				else if (tagInfo != undefined && tagInfo.isClosed != undefined && !tagInfo.isClosed)
				if (tagInfo != undefined && tagInfo.component == "latticeEdge")
				{
					//addSimplePolygonToPath(theContext, polygonList.GetPolygonPoints(p), theTransform, false /* isClosed */);
					renderOptions.isClosed = false;
					if (!renderSettings.enableLatticeCurves)
						renderOptions.cornerStyle = VectorCornerStyle.ANGLE;
					addSimplePolygonToPath_perVertexCurves(theContext, polygonList.GetPolygonPoints(p), renderOptions, theTransform);
					count++;
				}
// 				else
// 				{ /* do nothing */ }
			}

			if (count > 0)
				theContext.stroke();
		}

		SetPropertyList(theContext, oldSettings);
	}

	//----------------------------------------------------------------------------------------------------
	//	PolyList Render Area (color)
	//	2022.02.16: Render by colorId to eliminate the alignment issue at the edges of the color polygons of
	//	different colors
	//----------------------------------------------------------------------------------------------------
	var polyListRenderArea = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		// 2022.02.02: Options for new _perVertexCurves function
		var cornerStyle = renderSettings.cornerStyle;
		var cornerSize = renderSettings.cornerSize;
		var renderOptions = {cornerStyle, cornerSize, isClosed:true};

		let oldSettings = GetPropertyList(theContext, ["lineWidth", "strokeStyle", "lineJoin"]); // 2022.02.08

		let defaultLineWidth = 1.5;

		theContext.lineWidth = defaultLineWidth;

		// The 'bevel' lineJoin prevents the tiny color "spikes" from appearing under the edge
		theContext.lineJoin = "bevel";

		// 2022.02.16: Loop count is one more than the number of colors in the color list
		let loopCount = 1 + ((renderSettings.colors != undefined) ? renderSettings.colors.length : 0);

		// 2022.02.16: Go through each color in the color list and than one more for everything with a color not in the color list
		for (var colorIdx = 0; colorIdx < loopCount; colorIdx++)
		{
			// 2020.08.28: Render any "area" (color) polygons
			for (var p = 0; p < polygonList.GetPolygonCount(); p++)
			{
				let tagInfo = polygonList.GetPolygonTagInfo(p);

				if (tagInfo != undefined && tagInfo.component != undefined && tagInfo.component == "colorFill") // 2021.04.12: Replace "area" with "isFill"; 2022.03.01: replace "isFill" with "colorFill"
				{
					let currSettings = GetPropertyList(theContext, ["fillStyle", "strokeStyle", "lineWidth"]); // 2022.02.08
					let color = undefined;
					let stroke = undefined; // 2021.03.30

					if (colorIdx < loopCount - 1) // Check color list
					{
						// If the item has a colorId and it matches the colorId at the colors list for this pass, then
						// get the color, which is a flag to render the polygon
						// let c = (renderSettings.colors != undefined) ? renderSettings.colors.find(c => c.colorId == tagInfo.colorId) : undefined;
						if (tagInfo.colorId != undefined && tagInfo.colorId == renderSettings.colors[colorIdx].colorId )
							color = renderSettings.colors[colorIdx].color;
					}
					else // Last pass; check all other polygons which might have a color
					{
						if (tagInfo.color != undefined)
							color = tagInfo.color;

						// 2021.03.30: Add stroke for color polygons
						if (tagInfo.stroke != undefined)
							stroke = tagInfo.stroke;
					}

					if (color != undefined)
						SetPropertyList(theContext, {fillStyle:color, strokeStyle:color});

					if (stroke != undefined)
						SetPropertyList(theContext, {strokeStyle:stroke, lineWidth:oldSettings.lineWidth});

					if (color != undefined /* || show-missing-colors */)
					{
						theContext.beginPath();
						addSimplePolygonToPath_perVertexCurves(theContext, polygonList.GetPolygonPoints(p), renderOptions, theTransform);
						theContext.fill();
						theContext.stroke();
					}

					SetPropertyList(theContext, currSettings);
				}
			}
		}

		SetPropertyList(theContext, oldSettings);
	}

	//----------------------------------------------------------------------------------------------------
	//	PolyList Render Points
	//----------------------------------------------------------------------------------------------------
	var polyListRenderPoints = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		var size = renderSettings.pointSize;
		
		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			var simplePoly = polygonList.GetPolygonPoints(p);
			
			for (var s = 0; s < simplePoly.length; s++)
			{
				var pt = (theTransform != undefined) ? theTransform.xfrm(simplePoly[s]) : simplePoly[s];
				theContext.fillRect(pt.x - 1, pt.y - 1, 3, 3);
			}
		}
	}

	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Highlight
	//
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderHighlight = function(polygonList, renderRef, renderSettings, renderConfig, highlight)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		var cornerStyle = renderSettings.cornerStyle;
		var cornerSize = renderSettings.cornerSize;
		var outerDocumentRect = renderSettings.outerDocumentRect;

		// Highlight the polygons
		theContext.lineWidth = 3;
		theContext.strokeStyle = "rgba(0, 0, 255, 0.5)";
		theContext.fillStyle = "rgba(0, 0, 255, 0.5)";
		theContext.beginPath();
			
		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			// If the index in the highlight list?
			let hIdx = highlight.findIndex(h => h.polyIdx == p);
			
			if (hIdx != -1)
			{
				let tagInfo = polygonList.GetPolygonTagInfo(p); // 2020.08.28
			
				if (tagInfo != undefined && tagInfo.isFill /* == "area"*/) // 2021.04.12: Replace "area" with "isFill"
				{ /* do nothing */ }
			
				else if (cornerStyle == VectorCornerStyle.ANGLE)
					addSimplePolygonToPath(theContext, polygonList.GetPolygonPoints(p), theTransform);

				else if (cornerStyle == VectorCornerStyle.ARC)
					addSimplePolygonToPath_ArcCurves(theContext, polygonList.GetPolygonPoints(p), cornerSize, theTransform);

				else if (cornerStyle == VectorCornerStyle.QUAD_BEZ)
					addSimplePolygonToPath_QuadCurves(theContext, polygonList.GetPolygonPoints(p), cornerSize, theTransform);
			}
		}
				
		theContext.fill();


		if (0)
		{
			// Highlight any edges
			theContext.lineWidth = 5;
			theContext.strokeStyle = "rgba(0, 0, 255, 0.5)";
			theContext.beginPath();
		
			
			for (var p = 0; p < polygonList.GetPolygonCount(); p++)
			{
				// If the index in the highlight list?
				let hIdx = highlight.findIndex(h => h.polyIdx == p);
			
				if (hIdx != -1)
				{
					let tagInfo = polygonList.GetPolygonTagInfo(p); // 2020.08.28
			
					if (tagInfo != undefined && tagInfo.isFill /* == "area"*/) // 2021.04.12: Replace "area" with "isFill"
					{ /* do nothing */ }
			
					else
					{
						let h = highlight[hIdx];
						let points = polygonList.GetPolygonPoints(p);
						for (var j = 0; j < h.ptIdcs.length; j++)
						{
							let idx = h.ptIdcs[j];
							let ptA = points[idx];
							let ptB = points[(idx + 1) % points.length];
						
							moveOrLineToPt(theContext, ptA, true, theTransform);
							moveOrLineToPt(theContext, ptB, false, theTransform);
						
						}
					}
				}
			}
				
			theContext.stroke();
		}

		theContext.lineWidth = 1;
		theContext.strokeStyle = "black";
	}


	//------------------------------------------------------------------------------------
	//	Calc Approximate Center (of a polygon)
	//		Find the approximate center of a polygon by averaging all of the points
	//------------------------------------------------------------------------------------
	var CalcApproximateCenter = function(points)
	{
		let ctr = {x:0, y:0};
		
		points.forEach(pt => { ctr.x += pt.x; ctr.y += pt.y });
		ctr.x /= points.length;
		ctr.y /= points.length;
		
		return ctr;
	}
	
	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Tile Highlight
	//
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderTileHighlight = function(polygonList, renderRef, renderSettings, renderConfig, highlight)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		var cornerStyle = renderSettings.cornerStyle;
		var cornerSize = renderSettings.cornerSize;
		var outerDocumentRect = renderSettings.outerDocumentRect;

		var drawColor = "rgba(0, 0, 255, 0.5)";
		var eraseColor = "rgba(255, 0, 0, 0.5)";
		
		
		// Highlight the polygons
		theContext.beginPath();
		
		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			// If the index in the highlight list?
			let hIdx = highlight.tiles.findIndex(h => h.polyIdx == p);
		
			if (hIdx != -1)
			{
				addSimplePolygonToPath(theContext, polygonList.GetPolygonPoints(p), theTransform);
			}
		}
		
		// Lighten the tiles in the highlight
		theContext.fillStyle =  "rgba(255, 255, 255, 0.75)";
		theContext.fill();
		
		theContext.fillStyle = highlight.draw ? drawColor : eraseColor;
		
		// If highlighting full tiles, then draw the highlight color
		if (highlight.fullTile)
			theContext.fill();


		if (!highlight.fullTile || highlight.allEdges)
		{
			theContext.strokeStyle = highlight.draw ? drawColor : eraseColor;

			// Highlight any edges
			let highlightWidth = 10;
			theContext.lineWidth = highlightWidth;
			theContext.beginPath();
		
			
			for (var p = 0; p < polygonList.GetPolygonCount(); p++)
			{
				// If the index in the highlight list?
				let hIdx = highlight.tiles.findIndex(h => h.polyIdx == p);
			
				if (hIdx != -1 && highlight.tiles[hIdx].edge != undefined)
				{
					let points = polygonList.GetPolygonPoints(p);
					let edge = highlight.tiles[hIdx].edge;
					
					// Either render a single edge of all of the edges
					let startEdge = highlight.allEdges ? 0 : edge;
					let endEdge = highlight.allEdges ? (points.length - 1) : edge;
					
					// We want to show which tile's edge is currently highlighted. We can do 
					// that by offsetting the polygon inward. This might return undefined if we
					// offset too much. In that case, we use the tile center.
					let offset = renderConfig.theTransform.reverse_scaleNum(-highlightWidth/2);
					let insetPoints = MathUtil.CalcOffsetPolygon(points, offset);
					let tagInfo = polygonList.GetPolygonTagInfo(p);
					let ctr = (tagInfo != undefined && tagInfo.ctr != undefined) ? tagInfo.ctr : CalcApproximateCenter(points);
					
					for (var eIdx = startEdge; eIdx <= endEdge; eIdx++)
					{
						let ptA = points[eIdx];
						let ptB = points[(eIdx + 1) % points.length];
						// Use either the inset polygon or the center
						var ptC = (insetPoints != undefined) ? insetPoints[(eIdx + 1) % points.length] : ctr;
						var ptD = (insetPoints != undefined) ? insetPoints[eIdx] : undefined;
				
						moveOrLineToPt(theContext, ptA, true, theTransform);
						moveOrLineToPt(theContext, ptB, false, theTransform);
						moveOrLineToPt(theContext, ptC, false, theTransform);
						if (ptD != undefined)
							moveOrLineToPt(theContext, ptD, false, theTransform);
						theContext.closePath();
					}
					
				}
			}
				
			theContext.fill();
		}

		theContext.lineWidth = 1;
		theContext.strokeStyle = "black";
	}


	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Lattice Edge
	//
	//	2022.02.12: Added support curves for the lattice edges, but it does not work when colors are used,
	//	because the color polygon vertices the correspond to the lattice vertices are not curved, so I added
	//	a checkbox
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderLatticeEdge = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		// 2022.02.12: Use 'enable lattice curve' setting
		var enableCurve = renderSettings.enableLatticeCurves;
		var cornerStyle = enableCurve ? renderSettings.cornerStyle : VectorCornerStyle.ANGLE;
		var cornerSize = renderSettings.cornerSize;
		var renderOptions = {cornerStyle, cornerSize, isClosed:false, curveLimit:1.0};
		
		theContext.beginPath();

		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			let tagInfo = polygonList.GetPolygonTagInfo(p);

			// Only render open polygons
			// 2022.03.01: Use component name instead of deducing from isClosed flag. Note that for now lattice edges are always open polygons
			if (tagInfo != undefined && tagInfo.component != undefined && tagInfo.component == "latticeEdge")
				addSimplePolygonToPath_perVertexCurves(theContext, polygonList.GetPolygonPoints(p), renderOptions, theTransform);
		}

		theContext.stroke();
	}

	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Points
	//
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderPoints = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		polyListRenderPoints(polygonList, renderRef, renderSettings, renderConfig);
	}
	
	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render
	//
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_Render = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		polyListRender(polygonList, renderRef, renderSettings, renderConfig);
	}

	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Gradient
	//
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderGradient = function(polygonList, renderRef, renderSettings, renderConfig, gradientSettings)
	{
		polyListRenderGradient(polygonList, renderRef, renderSettings, renderConfig, gradientSettings);
	}

	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Area
	//		Render the color polygons
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderArea = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		polyListRenderArea(polygonList, renderRef, renderSettings, renderConfig);
	}

	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Area Diags
	//		Show the edges of the color polygons
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderAreaDiags = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		var theContext = renderRef.theContext;
		var theTransform = renderConfig.theTransform;
		// 2022.02.02: Options for new _perVertexCurves function
		var cornerStyle = renderSettings.cornerStyle;
		var cornerSize = renderSettings.cornerSize;
		var renderOptions = {cornerStyle, cornerSize, isClosed:true};

		// The 'bevel' lineJoin prevents the tiny color "spikes" from appearing under the edge
		let oldSettings = SetPropertyList(theContext, {strokeStyle:"black", lineWidth:1, lineJoin:"bevel"});

		// 2020.08.28: Render any "area" (color) polygons
		for (var p = 0; p < polygonList.GetPolygonCount(); p++)
		{
			let tagInfo = polygonList.GetPolygonTagInfo(p);

			if (tagInfo != undefined && tagInfo.isFill)
			{
				let color = undefined;

				if (tagInfo.colorId != undefined)
				{
					let c = (renderSettings.colors != undefined) ? renderSettings.colors.find(c => c.colorId == tagInfo.colorId) : undefined;
					if (c != undefined)
						color = c.color;
				}
				else if (tagInfo.color != undefined)
				{
					color = tagInfo.color;
				}

				if (color != undefined /* || show-missing-colors */)
				{
					theContext.beginPath();
					addSimplePolygonToPath_perVertexCurves(theContext, polygonList.GetPolygonPoints(p), renderOptions, theTransform);
					theContext.stroke();
				}
			}
		}

		SetPropertyList(theContext, oldSettings);
	}

	//----------------------------------------------------------------------------------------------------
	//	Polygon List Renderer: Render Shadow
	//
	//----------------------------------------------------------------------------------------------------
	var PolygonListRenderer_RenderShadow = function(polygonList, renderRef, renderSettings, renderConfig)
	{
		polyListRenderShadow(polygonList, renderRef, renderSettings, renderConfig);
	}

	/*-----------------------------------------------*
	 * Public API
	 *-----------------------------------------------*/
	return {
		Render:				PolygonListRenderer_Render,
		RenderGradient:		PolygonListRenderer_RenderGradient, // 2021.06.22
		RenderLatticeEdge:	PolygonListRenderer_RenderLatticeEdge, // 2022.01.27
		RenderArea:			PolygonListRenderer_RenderArea,
		RenderAreaDiags:	PolygonListRenderer_RenderAreaDiags, // 2022.02.08
		RenderPoints:		PolygonListRenderer_RenderPoints,
		RenderShadow:		PolygonListRenderer_RenderShadow,
		RenderHighlight:	PolygonListRenderer_RenderHighlight,
		RenderTileHighlight:	PolygonListRenderer_RenderTileHighlight
	};
}());

// xx (singleton)
var ScreenRenderer = (function() {

	
	var moveOrLineToPt = function(theContext, thePoint, isMoveToFlag, theTransform)
	{
		if (theTransform != undefined)
			thePoint = theTransform.xfrm(thePoint);
			
		if (isMoveToFlag)
			theContext.moveTo(thePoint.x, thePoint.y);
		else
			theContext.lineTo(thePoint.x, thePoint.y);
	}

	var ScreenRenderer_GetOuterDocumentRect = function(designRender, theContext, theTransform)
	{
		var xMin = 0;
		var xMax = theContext.canvas.width;
		var yMin = 0;
		var yMax = theContext.canvas.height;
		
		if (designRender.designData.general.fixedBounds)
		{
			var docW = designRender.designData.frame.docWidth;
			var docH = designRender.designData.frame.docHeight
			var pt = {x:-docW/2,  y:-docH/2};
			var pt = theTransform.xfrm(pt);
			var w = theTransform.scaleNum(docW);
			var h = theTransform.scaleNum(docH);
			
			xMin = pt.x;
			yMin = pt.y - h;
			xMax = pt.x + w;
			yMax = pt.y;
		}
			
		return {xMin:xMin, yMin:yMin, xMax:xMax, yMax:yMax};
	}
	
	var ScreenRenderer_RenderSegmentDiags = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		var minEdgeLenForSegmentDisplay = 45;		
		
		var emSegmentList = designRender.segList;
		var countBadSegs = 0;
		
		if (emSegmentList != undefined)
		{
			var count = SegmentList.GetSegmentCount(emSegmentList);
			var offsetDistance = 5;
			var shorten = offsetDistance * 3;
			var arrowL = 7;
			var arrowW = 3;
	
			theContext.strokeStyle = "#800080";
			theContext.lineWidth = 1.0;

			for (var i = 0; i < count; i++)
			{
				var segment = SegmentList.GetSegment(emSegmentList, i);
				var offsetVtx;
				var A = theZoom.xfrm(segment.ptA);
				var B = theZoom.xfrm(segment.ptB);
	
				var unitAB = MathUtil.CalcUnitVector(A, B);
				var len = MathUtil.DistanceBetween(A, B);
				
				if (unitAB != undefined)
				{
					//console.log(len);
					if (len > shorten * 3)
					{
						var E = {x:(A.x + shorten * unitAB.x + offsetDistance * unitAB.y), y:(A.y + shorten * unitAB.y - offsetDistance * unitAB.x)};
						var F = {x:(B.x - shorten * unitAB.x + offsetDistance * unitAB.y), y:(B.y - shorten * unitAB.y - offsetDistance * unitAB.x)};
						var G = {x:(F.x - arrowL  * unitAB.x + arrowW         * unitAB.y), y:(F.y - arrowL  * unitAB.y - arrowW         * unitAB.x)};


						theContext.beginPath();
						theContext.moveTo(E.x, E.y);
						theContext.lineTo(F.x, F.y);
						theContext.lineTo(G.x, G.y);
						theContext.stroke();
					}
				}
				else
				{
					countBadSegs += 1;
					console.log("bad seg: " + JSON.stringify(A) + ", " + JSON.stringify(B));
				}

			}
		}
		
		if (countBadSegs > 0)
			console.log("bad segs: " + countBadSegs);
	}

	var ScreenRenderer_RenderSegmentList = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		var emSegmentList = designRender.segList;
		
		theContext.lineWidth = 1.0;
		if (emSegmentList != undefined)
		{
			var count = SegmentList.GetSegmentCount(emSegmentList);
	
			theContext.strokeStyle = designRender.designData.general.fillColor;

			if (1 /* render fast */)
			{
				theContext.beginPath();
				for (var i = 0; i < count; i++)
				{
					var segment = SegmentList.GetSegment(emSegmentList, i);

					//width = (segment.offset != undefined && segment.offset > 0) ? segment.offset * 2.0 : 1.0;
					//var scaledWidth = theZoom.scaleNum(width);
					//theContext.lineWidth = scaledWidth; 
					moveOrLineToPt(theContext, segment.ptA, true, theZoom);
					moveOrLineToPt(theContext, segment.ptB, false, theZoom);
				}
				theContext.stroke();
			}
			else
			{
				// Render accurate widths
				for (var i = 0; i < count; i++)
				{
					var segment = SegmentList.GetSegment(emSegmentList, i);

					width = (segment.offset != undefined && segment.offset > 0) ? segment.offset * 2.0 : 1.0;
					var scaledWidth = theZoom.scaleNum(width);

					theContext.lineWidth = scaledWidth; 
					theContext.beginPath();
					moveOrLineToPt(theContext, segment.ptA, true, theZoom);
					moveOrLineToPt(theContext, segment.ptB, false, theZoom);
					theContext.stroke();
				}
			}
		}
	}

	var ScreenRenderer_RenderPolyLists = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		var dd = designRender.designData;
		
		if (designRender.offsetPolyList != undefined)
		{
			// 2021.06.25: Add shadow and gradient; 2022.02.08: Add colors; 2022.03.10: Add center & mid lines
			var forceRenderLine = !(dd.general.renderFill || dd.general.renderLine || 
									dd.general.renderShadowLine || dd.general.renderShadowFill || dd.gradients[0].enable ||
									dd.general.renderCenter || dd.general.renderMidLine ||
									(dd.designType == DesignType.TYPE_REGULAR_TILING && dd.tiling.useLineColors));
				
			theContext.lineWidth = theZoom.scaleNum(dd.general.lineWidth);
			theContext.strokeStyle = forceRenderLine ? "#e0e0e0" : dd.general.lineColor;
			theContext.fillStyle = dd.general.fillColor;
			
			var renderRef = {theContext:theContext};
			var renderConfig = {theTransform:theZoom};
			var renderSettings = {};

			renderSettings.cornerStyle = dd.general.cornerStyle;
			renderSettings.cornerSize = dd.general.cornerSize;
			renderSettings.renderFill = dd.general.renderFill;
			renderSettings.renderLine = forceRenderLine || dd.general.renderLine;
			renderSettings.colors = dd.colors; // 2020.10.15: Color palette
			renderSettings.enableLatticeCurves = dd.tiling.enableLatticeCurves; // 2022.02.12
			renderSettings.components = ["designEdge"]; // 2022.03.01

			// 2020.09.09: Added Shadow
			renderSettings.renderShadowLineBelow = dd.general.renderShadowLine && !dd.general.shadowLineAboveColor;
			renderSettings.renderShadowLineAbove = dd.general.renderShadowLine && dd.general.shadowLineAboveColor;
			renderSettings.renderShadowFill = dd.general.renderShadowFill;
			renderSettings.shadowColor  = dd.general.shadowColor;
			renderSettings.shadowWidth  = theZoom.scaleNum(dd.general.shadowWidth);
			renderSettings.shadowBlur   = theZoom.scaleNum(dd.general.shadowBlur);
			renderSettings.shadowX      = dd.general.shadowX;
			renderSettings.shadowY      = dd.general.shadowY;

			var shadowZoom = theZoom.Clone();
			shadowZoom.offset.x += theZoom.scaleNum(dd.general.shadowX);
			shadowZoom.offset.y += theZoom.scaleNum(dd.general.shadowY);
	
			if (dd.frame.render == FrameRender.FRAME_RENDER_INSIDE)
				renderSettings.outerDocumentRect = ScreenRenderer_GetOuterDocumentRect(designRender, theContext, theZoom);
				
			// 2021.06.22: Add gradient for the background inside the design
			if (dd.gradients[0].enable)
			{
				PolygonListRenderer.RenderGradient(designRender.offsetPolyList, renderRef, renderSettings, renderConfig, dd.gradients[0]);
			}

			// 2022.02.08: Option to render shadow line later
			if (renderSettings.renderShadowLineBelow || renderSettings.renderShadowFill)
			{
				renderSettings.renderShadowLine = renderSettings.renderShadowLineBelow;
				renderSettings.components = ["designEdge", "latticeEdge"];
				PolygonListRenderer.RenderShadow(designRender.offsetPolyList, renderRef, renderSettings, {theTransform:shadowZoom});

				// Render the center line for the shadow, but only if rendering shadow lines and not the fill
				if (dd.general.renderCenter && renderSettings.renderShadowLine && !renderSettings.renderShadowFill)
				{
					renderSettings.components = ["centerLine"];
					PolygonListRenderer.RenderShadow(designRender.offsetPolyList, renderRef, renderSettings, {theTransform:shadowZoom});
				}
				renderSettings.components = ["designEdge"];
			}
			
			// 2021.03.27: Handle more options for when color polygons need to be rendered
			let renderColorPolygons = false;
			
			if (dd.designType == DesignType.TYPE_REGULAR_TILING && dd.tiling.useLineColors)
				renderColorPolygons = true;
			else if (dd.designType == DesignType.TYPE_IMAGE && (dd.image.monochromeStyle == "G" || dd.image.monochromeStyle == "C"))
				renderColorPolygons = true;

			// 2022.04.07: Image Lines
			let renderImageLines = (dd.designType == DesignType.TYPE_IMAGE && (dd.image.monochromeStyle == "N" || dd.image.monochromeStyle == "M"));

			if (renderImageLines) // 2022.04.07: Image Lines are rendered as their own type
			{
				let oldRenderSettings = SetPropertyList(renderSettings, {renderFill:false, renderLine:true, components:["imageLine"]});
				PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
				SetPropertyList(renderSettings, oldRenderSettings);
			}
			else if (!renderColorPolygons && !renderSettings.renderShadowLineAbove)
			{
				// If not using line color (that is, color polygons), then render both the fill and stroke in one call
				PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
			}
			else
			{
				// With line colors (that is, color polygons), we need to render the fill first, then the colors ("area"), then the stroke
				if (renderSettings.renderFill)
				{
					let tmpRenderLine = renderSettings.renderLine;
					renderSettings.renderLine = false;
					PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
					renderSettings.renderLine = tmpRenderLine;
				}
				
				PolygonListRenderer.RenderArea(designRender.offsetPolyList, renderRef, renderSettings, renderConfig); // colors

				if (renderSettings.renderShadowLineAbove)
				{
					renderSettings.renderShadowLine = true;
					renderSettings.renderShadowFill = false;
					
					renderSettings.components = ["designEdge", "latticeEdge"];
					PolygonListRenderer.RenderShadow(designRender.offsetPolyList, renderRef, renderSettings, {theTransform:shadowZoom});

					// Render the center line for the shadow, but only if rendering shadow lines and not the fill
					if (dd.general.renderCenter && renderSettings.renderShadowLine /* && !renderSettings.renderShadowFill*/)
					{
						renderSettings.components = ["centerLine"];
						PolygonListRenderer.RenderShadow(designRender.offsetPolyList, renderRef, renderSettings, {theTransform:shadowZoom});
					}
					renderSettings.components = ["designEdge"];
				}

				if (renderSettings.renderLine)
				{
					let tmpRenderFill = renderSettings.renderFill;
					renderSettings.renderFill = false;
					PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
					renderSettings.renderFill = tmpRenderFill;
				}
			}

			if (dd.general.renderLattice) // 2022.02.04: Add lattice enable, color and width settings
			{
				let oldSettings = SetPropertyList(theContext, {strokeStyle:dd.general.latticeColor, lineWidth: theZoom.scaleNum(dd.general.latticeWidth)});
				PolygonListRenderer.RenderLatticeEdge(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
				SetPropertyList(theContext, oldSettings);
			}

			if (dd.general.renderCenter) // 2022.02.04: Use center line color and width
			{
				let oldSettings = SetPropertyList(theContext, {strokeStyle:dd.general.centerColor, lineWidth: theZoom.scaleNum(dd.general.centerWidth)});
				let oldRenderSettings = SetPropertyList(renderSettings, {renderFill:false, renderLine:true, components:["centerLine"]});
				PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
				SetPropertyList(renderSettings, oldRenderSettings);
				SetPropertyList(theContext, oldSettings);
			}

			if (dd.general.renderMidLine) // 2022.03.06: Added
			{
				let oldSettings = SetPropertyList(theContext, {strokeStyle:dd.general.midLineColor, lineWidth: theZoom.scaleNum(dd.general.midLineWidth)});
				let oldRenderSettings = SetPropertyList(renderSettings, {renderFill:false, renderLine:true, components:["midLine"]});
				PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
				SetPropertyList(renderSettings, oldRenderSettings);
				SetPropertyList(theContext, oldSettings);
			}
		}
	}

	var ScreenRenderer_RenderPolyListsDiags = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		var dd = designRender.designData;

		var renderRef = {theContext:theContext};
		var renderConfig = {theTransform:theZoom};
		var renderSettings = {};

		renderSettings.cornerStyle = VectorCornerStyle.ANGLE; //dd.general.cornerStyle;
		renderSettings.renderFill = false;
		renderSettings.renderLine = true;

		theContext.fillStyle = "black";
		theContext.lineWidth = 1;

		if (dd.diags.showCenterPoly && designRender.centerPolyList != undefined) // 2021.03.22: added centerPolyList test
		{
			PolygonListRenderer.RenderPoints(designRender.centerPolyList, renderRef, renderSettings, renderConfig);

			theContext.strokeStyle = "rgba(0, 0, 128, 0.25)";
			PolygonListRenderer.Render(designRender.centerPolyList, renderRef, renderSettings, renderConfig);
		}

		if (dd.diags.showOffsetPoly && designRender.offsetPolyList != undefined)
		{
			//theContext.fillStyle = "rgba(255, 0, 0, 0.25)";
			PolygonListRenderer.RenderPoints(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);

			theContext.strokeStyle = "rgba(255, 0, 0, 0.25)";
			PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
		}

		if (dd.diags.showHairlinePoly && designRender.offsetPolyList != undefined)
		{
			renderSettings.cornerStyle = dd.general.cornerStyle;
			renderSettings.cornerSize = dd.general.cornerSize;
			renderSettings.cornerSize = dd.general.cornerSize;

			theContext.strokeStyle = "rgba(0, 0, 0, 0.25)";
			PolygonListRenderer.Render(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
		}
	}

	var ScreenRenderer_RenderPolyListsAreaDiags = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		var dd = designRender.designData;

		var renderRef = {theContext:theContext};
		var renderConfig = {theTransform:theZoom};
		var renderSettings = {};

		renderSettings.cornerStyle = dd.general.cornerStyle;
		renderSettings.cornerSize = dd.general.cornerSize;
		renderSettings.colors = dd.colors;

		PolygonListRenderer.RenderAreaDiags(designRender.offsetPolyList, renderRef, renderSettings, renderConfig);
	}

	var ScreenRenderer_RenderPolyListsHighlight = function(designRender, theCanvas, theZoom, highlight)
	{
		var theContext = theCanvas.getContext("2d");
		var dd = designRender.designData;

		var renderRef = {theContext:theContext};
		var renderConfig = {theTransform:theZoom};
		var renderSettings = {};

		renderSettings.cornerStyle = dd.general.cornerStyle;
		renderSettings.cornerSize = dd.general.cornerSize;

		theContext.fillStyle = "black";
		theContext.lineWidth = 1;

		if (highlight.centerHighlight != undefined && highlight.centerHighlight.length > 0 && dd.diags.showCenterPoly)
		{
			renderSettings.cornerStyle = VectorCornerStyle.ANGLE;
			PolygonListRenderer.RenderHighlight(designRender.centerPolyList, renderRef, renderSettings, renderConfig, highlight.centerHighlight);
		}

		if (highlight.offsetHighlight != undefined && highlight.offsetHighlight.length > 0 && designRender.offsetPolyList != undefined)
		{
			renderSettings.cornerStyle = dd.general.cornerStyle;
			PolygonListRenderer.RenderHighlight(designRender.offsetPolyList, renderRef, renderSettings, renderConfig, highlight.offsetHighlight);
		}

	}

	var ScreenRenderer_RenderTileHighlight = function(designRender, theCanvas, theZoom, tileHighlight)
	{
		var theContext = theCanvas.getContext("2d");
		var dd = designRender.designData;

		var renderRef = {theContext:theContext};
		var renderConfig = {theTransform:theZoom};
		var renderSettings = {};

		renderSettings.cornerStyle = dd.general.cornerStyle;
		renderSettings.cornerSize = dd.general.cornerSize;

		theContext.fillStyle = "black";
		theContext.lineWidth = 1;

		if (tileHighlight.tiles.length > 0)
		{
			renderSettings.cornerStyle = VectorCornerStyle.ANGLE;
			PolygonListRenderer.RenderTileHighlight(designRender.tilePolylist, renderRef, renderSettings, renderConfig, tileHighlight);
		}


	}

	var ScreenRenderer_RenderTiles = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		theContext.lineWidth = 1;
		theContext.strokeStyle = "#e0e0e0";
	
		theContext.beginPath();
		for (var k = 0; designRender.tilePolylist != undefined && k < designRender.tilePolylist.GetPolygonCount(); k++)
		{
			var poly = designRender.tilePolylist.GetPolygonPoints(k);
		
			for (var i = 0; i < poly.length; i++)
				moveOrLineToPt(theContext, poly[i], (i == 0), theZoom);

			theContext.closePath();
		}
		theContext.stroke();
	}
	
	var ScreenRenderer_RenderLines = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		theContext.lineWidth = 1;
		theContext.strokeStyle = "rgba(0, 0, 128, 0.25)";
	
		theContext.beginPath();
		for (var k = 0; designRender != undefined && k < designRender.lineList.length; k++)
		{
			moveOrLineToPt(theContext, designRender.lineList[k].ptA, true, theZoom);
			moveOrLineToPt(theContext, designRender.lineList[k].ptB, false, theZoom);
		}
		theContext.stroke();
	}
	
	var ScreenRenderer_RenderDrillHolesAndNotches = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");

		theContext.fillStyle = "rgba(0, 0, 0, 0.25)";
	
		if (designRender.drillHoles.length > 0)
		{
			theContext.beginPath();
			for (var k = 0; k < designRender.drillHoles.length; k++)
			{
				var h = designRender.drillHoles[k];
				var pt = theZoom.xfrm(h);
				var r = theZoom.scaleNum(h.r);
			
				theContext.arc(pt.x, pt.y, r, 0, 2 * Math.PI);
				theContext.closePath();
			}
			theContext.fill();
		}

		if (designRender.notches.length > 0)
		{
			theContext.beginPath();
			for (var k = 0; k < designRender.notches.length; k++)
			{
				var notch = designRender.notches[k];
				
				for (var j = 0; j < notch.length; j++)
					moveOrLineToPt(theContext, notch[j], (j == 0), theZoom);

				theContext.closePath();
			}
			theContext.fill();
		}
	}
	
	var ScreenRenderer_RenderFrame = function(designRender, theCanvas, theZoom)
	{
		var theContext = theCanvas.getContext("2d");
		theContext.lineWidth = 1;
		theContext.strokeStyle = "rgba(0, 0, 128, 0.25)";
	
		theContext.beginPath();
		for (var k = 0; k < designRender.framePoints.length; k++)
			moveOrLineToPt(theContext, designRender.framePoints[k], (k == 0), theZoom);
		theContext.closePath();
		theContext.stroke();

		theContext.beginPath();
		for (var k = 0; k < designRender.outerFramePoints.length; k++)
			moveOrLineToPt(theContext, designRender.outerFramePoints[k], (k == 0), theZoom);
		theContext.closePath();
		theContext.stroke();
	}
	
	var ScreenRenderer_RenderBounds = function(designRender, theCanvas, theZoom)
	{
		if (designRender.bounds != undefined)
		{
			var theContext = theCanvas.getContext("2d");
			var b = designRender.bounds;
			var pt = {x:b.min.x,  y:b.min.y};

			var w = b.max.x - b.min.x;
			var h = b.max.y - b.min.y;
			
			var pt = theZoom.xfrm(pt);
			var w = theZoom.scaleNum(w);
			var h = theZoom.scaleNum(h);
			
			theContext.lineWidth = 1;
			theContext.strokeStyle = "#e0e0e0";
			theContext.beginPath();
			theContext.rect(pt.x, pt.y, w, -h);
			theContext.stroke();
		}
	}

	var ScreenRenderer_RenderMinimumTileBounds = function(designRender, theCanvas, theZoom)
	{
		if (designRender.minimalRepeatBounds != undefined)
		{
			var theContext = theCanvas.getContext("2d");
			var b = designRender.minimalRepeatBounds;
			var pt = {x:b.min.x,  y:b.min.y};

			var w = b.max.x - b.min.x;
			var h = b.max.y - b.min.y;

			var pt = theZoom.xfrm(pt);
			var w = theZoom.scaleNum(w);
			var h = theZoom.scaleNum(h);

			theContext.lineWidth = 1;
			theContext.strokeStyle = "rgba(0, 0, 0, 0.5)";
			theContext.beginPath();
			theContext.rect(pt.x, pt.y, w, -h);
			theContext.stroke();
		}
	}

	var ScreenRenderer_RenderFixedBounds = function(theCanvas, theZoom)
	{
		if (sdFixedBoundsSize != undefined)
		{
			var theContext = theCanvas.getContext("2d");

			var pt = {x:-sdFixedBoundsSize.x/2,  y:-sdFixedBoundsSize.y/2};
			var pt = theZoom.xfrm(pt);
			var w = theZoom.scaleNum(sdFixedBoundsSize.x);
			var h = theZoom.scaleNum(sdFixedBoundsSize.y);
			
			theContext.lineWidth = 1;
			theContext.strokeStyle = "darkgray";
			theContext.beginPath();
			theContext.rect(pt.x, pt.y, w, -h);
			theContext.stroke();
		}
	}

	var ScreenRenderer_RenderBackground = function(designRender, theCanvas, theZoom, forceWhiteBackground)
	{
		var theContext = theCanvas.getContext("2d");

		// 2018.01.09: Added 'forceWhiteBackground' to remove transparency of thumbnails
		theContext.fillStyle = forceWhiteBackground ? "white" : designRender.designData.general.backColor;
		theContext.fillRect(0, 0, theCanvas.width, theCanvas.height);
	}

	
	
	//----------------------------------------------------------------------------------------------
	//	Render To Canvas
	//
	//	2020.10.06: Added options to pass params that are not in the designRender object
	//	2021.08.05: Add options.backgroundColor
	//----------------------------------------------------------------------------------------------
	var ScreenRenderer_RenderToCanvas = function(designRender, renderType, theCanvas, theZoom, options = undefined)
	{
		var startTime = Date.now();
		var onScreen = (renderType == ScreenRenderType.ON_SCREEN);
		var dd = designRender.designData;
		var diags = (options != undefined && options.diags != undefined) ? options.diags : {}; // 2022.02.08

		// If a zoom object was not provide, then create a default 1:1 zoom object
		if (theZoom == undefined)
			theZoom = new Transform();
			
		var theContext = theCanvas.getContext("2d", { alpha: false });
		theContext.clearRect(0, 0, theCanvas.width, theCanvas.height);
		
		// 2021.08.05: Use background color if provided
		if (options != undefined && options.backgroundColor != undefined)
		{
			let fillStyle = theContext.fillStyle;
			theContext.fillStyle = options.backgroundColor;
			theContext.fillRect(0, 0, theCanvas.width, theCanvas.height);
			theContext.fillStyle = fillStyle
		}
		
		// Background
		// 2020.10.05: Don't render background if new "show hairline" is set
		if ((dd.general.renderBack || renderType == ScreenRenderType.THUMBNAIL) && !dd.diags.showHairlinePoly)
		{
			var forceWhiteBackground = (!dd.general.renderBack && renderType == ScreenRenderType.THUMBNAIL);
			ScreenRenderer_RenderBackground(designRender, theCanvas, theZoom, forceWhiteBackground);
		}
		
		// Design bounds
		if (dd.general.showBounds && onScreen)
			ScreenRenderer_RenderBounds(designRender, theCanvas, theZoom);

		// Tile outlines
		if (dd.tiling.showTiles && onScreen)
			ScreenRenderer_RenderTiles(designRender, theCanvas, theZoom);
			
		// If the design is completely rendered, then show only the final info
		if (ScreenGenerator.IsComplete(designRender))
		{
			// 2020.10.05: Don't render the design if new "show hairline" is set
			// 2022.04.06: This logic (!onScreen || !dd.diags.showHairlinePoly) is very confusing. Basically
			// there is one case where we don't want to render: onScreen && showHairlinePoly. It would be much 
			// clearer with
			//   if (onScreen && showHairlinePoly)
			//     ; // Don't render design since we are showing hairline
			//   else
			//     ScreenRenderer_RenderPolyLists(designRender, theCanvas, theZoom);
			//
			if (!onScreen || !dd.diags.showHairlinePoly)
				ScreenRenderer_RenderPolyLists(designRender, theCanvas, theZoom);

			// 2020.10.05: Factored out diags rendering from above call so that it is
			// only called for the "ON_SCREEN" render and not for the thumbnail render
			if (onScreen)
				ScreenRenderer_RenderPolyListsDiags(designRender, theCanvas, theZoom);

			if (onScreen && diags.showColorPolygons)
				ScreenRenderer_RenderPolyListsAreaDiags(designRender, theCanvas, theZoom);

			if (options != undefined && options.highlight != undefined)
				ScreenRenderer_RenderPolyListsHighlight(designRender, theCanvas, theZoom, options.highlight);

			if (options != undefined && options.tileHighlight != undefined)
				ScreenRenderer_RenderTileHighlight(designRender, theCanvas, theZoom, options.tileHighlight);
		}
		else // Otherwise, show the intermediate info
		{
			ScreenRenderer_RenderFrame(designRender, theCanvas, theZoom);
			
			ScreenRenderer_RenderLines(designRender, theCanvas, theZoom);

			if (designRender.offsetPolyList == undefined)
				 ScreenRenderer_RenderSegmentList(designRender, theCanvas, theZoom);
		}
			 
		if (dd.diags.showSegments && onScreen)
			ScreenRenderer_RenderSegmentDiags(designRender, theCanvas, theZoom);
			
		if (dd.general.showBounds && dd.general.fixedBounds && onScreen)
			ScreenRenderer_RenderFixedBounds(designRender, theCanvas, theZoom);
			
		if (dd.general.showMinBounds && onScreen)
			ScreenRenderer_RenderMinimumTileBounds(designRender, theCanvas, theZoom);
			
		ScreenRenderer_RenderDrillHolesAndNotches(designRender, theCanvas, theZoom);

		var renderTime = Date.now() - startTime;
		
		//console.log("Render time (ms): " + renderTime);
	}
	
	var ScreenRenderer_CreateCanvasAndRender = function(designRender, renderType, options = undefined)
	{
		// 2018.01.07: Refactored from ScreenDesigner_ExportAsPNG
		//
		// Generate a PNG image of the data
		// This will not perform a final render before 
		// creating the PNG.
		
		// 2019.09.25: Changed 'minimalRepeatingPattern = false' to options aprameters
		var minimalRepeatingPattern = (options != undefined && options.minimalRepeatingPattern != undefined) ? options.minimalRepeatingPattern : false;
		
		// Determine the dimensions to use
		var renderBounds = designRender.bounds;
		
		if (minimalRepeatingPattern && designRender.minimalRepeatBounds != undefined)
			renderBounds = designRender.minimalRepeatBounds;
		
		// Determine the size
		var renderWidth  = renderBounds.max.x - renderBounds.min.x;
		var renderHeight = renderBounds.max.y - renderBounds.min.y;

		if (Number.isNaN(renderWidth) || Number.isNaN(renderHeight))
			console.log("ScreenRenderer_RenderPNG: NaN detected after designRender.bounds: " + JSON.stringify(designRender.bounds));

		// Calculate a scale factor that attempts to accurately show the size of the design
		// on-screen
		var scale = BasicUnitsMgr.CalcScale(designRender.designData.general.units, designRender.designData.general.dpi);

		// Limit render size for thumbnails
		if (renderType == ScreenRenderType.THUMBNAIL)
		{
			var thumbnailSize = 150;
			if (renderWidth * scale > thumbnailSize || renderHeight * scale > thumbnailSize)
			{
				var maxDim = (renderWidth > renderHeight) ? renderWidth : renderHeight;
				scale = thumbnailSize / maxDim;
			}
		}
		
		// 2021.08.27: Use the fitToBounds, if given, to compute a scale so that the rendered design
		// fits within the given width and height
		if (options != undefined && options.fitToBounds != undefined)
		{
			let scaleWidth  = options.fitToBounds.width  / renderWidth;
			let scaleHeight = options.fitToBounds.height / renderHeight;
			
			scale = (scaleWidth < scaleHeight) ? scaleWidth : scaleHeight;
		}

		// Create a transform to scale and position the design
		var zoom = new Transform();
		
		// Note that the offset uses -minX and +maxY
		// The +maxY is used because the image needs to be rendered vertically inverted
		zoom.setOffset(-renderBounds.min.x, renderBounds.max.y);
		zoom.scaleBy(scale, scale);
		zoom.invertVertical();
		

		// Calc canvas size
		var canvasWidth  = renderWidth  * scale;
		var canvasHeight = renderHeight * scale;
		
		// Create a temporary canvas and sets it size
		var tmpCanvas = document.createElement('canvas');
		tmpCanvas.width  = canvasWidth;
		tmpCanvas.height = canvasHeight;
	
		// 2021.08.05: Added renderOptions to pass along backgroundColor
		var renderOptions = {};
		if (options != undefined & options.backgroundColor != undefined)
			renderOptions.backgroundColor = options.backgroundColor;

		// Draw into the canvas
		ScreenRenderer.RenderToCanvas(designRender, renderType, tmpCanvas, zoom, renderOptions);
		
		return tmpCanvas;
	}

	var ScreenRenderer_RenderPNG = function(designRender, renderType, minimalRepeatingPattern = false)
	{
		var canvas = ScreenRenderer_CreateCanvasAndRender(designRender, renderType, {minimalRepeatingPattern});

		// Get data from the canvas
		var pngData = canvas.toDataURL("image/png");

		// Return the PNG data as well as the width and height
		var pngInfo = {data:pngData, width:canvas.width, height:canvas.height};

		return pngInfo;
	}

	//----------------------------------------------------------------------------------------------
	//	Render RNG Blog
	//		2021.08.05: Added options
	//----------------------------------------------------------------------------------------------
	var ScreenRenderer_RenderPNGBlob = function(designRender, blobCallback, options = undefined)
	{
		var renderOptions = {minimalRepeatingPattern:false};

		if (options != undefined)
			Object.assign(renderOptions, options);

		var canvas = ScreenRenderer_CreateCanvasAndRender(designRender, ScreenRenderType.FOR_EXPORT, renderOptions);

		// Create blob
		canvas.toBlob(blobCallback);

		// 2021.08.12: Return info about the PNG: width and height
		var pngInfo = {width:canvas.width, height:canvas.height};

		return pngInfo;
	}
	
	//----------------------------------------------------------------------------------------------
	//	Render Histogram
	//		2021.03.30: Added
	//----------------------------------------------------------------------------------------------
	var ScreenRenderer_RenderHistogram = function(designRender, canvas)
	{
		let ctx = canvas.getContext("2d");
		let histogram = [0,0]; 
		
		if (designRender.imageMisc != undefined && designRender.imageMisc.histogram != undefined)
			histogram = designRender.imageMisc.histogram;
		
		let width = ctx.canvas.width;
		let height = ctx.canvas.height;

		
		var margin = (width < 100) ? 0 : 3;
		let w = width - 2 * margin;
		var sz = (w < 256) ? (w/256) : 1;
	
		let bottom = height - margin;
		let range = 256;
		let h = height - 2 * margin;
		var vertScale = h;
		var vertScaleAdj = 1.0;
	
	
	
		// Find maximum histogram value, not counting zero and 255
		var maxHist = 0;
		for (var i = 1; i < histogram.length - 1; i++)
			if (maxHist < histogram[i])
				maxHist = histogram[i];
			
		// Calculate a vertical scaling to best show the data
		let scaleOptions = [20, 15, 10, 5, 2];
		for (var i = 0; i < scaleOptions.length && vertScaleAdj == 1.0; i++)
			if (maxHist < 1/scaleOptions[i])
				vertScaleAdj = scaleOptions[i];
		
		
		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		ctx.fillStyle = "white";
		ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
	
		ctx.strokeStyle = "#eeeeee";
		ctx.beginPath();
		ctx.rect(margin, bottom- h, range * sz, h);
		ctx.stroke();
		ctx.strokeStyle = "black";


		ctx.strokeStyle = "#eeeeee";
		ctx.beginPath();
		ctx.lineWidth = 2;
		let bars = 20;
		for (var k = 0; k <= bars; k++)
		{
			let v = k/bars;
			let y = bottom - v * vertScaleAdj * vertScale;
					
			ctx.moveTo(margin, y);
			ctx.lineTo(margin + range * sz, y);
		}
		ctx.stroke();

		ctx.strokeStyle = "#000000";
		ctx.fillStyle = "black";
		ctx.lineWidth = sz;
		ctx.beginPath();
		for (var i = 0; i < histogram.length; i++)
		{
			let x = margin + i * sz;
			let height = histogram[i] * vertScaleAdj * vertScale;
		
			if (height > h)
				height = h;

			ctx.moveTo(x, bottom);
			ctx.lineTo(x, bottom - height);
		}
		ctx.stroke();

	}
	

	/*-----------------------------------------------*
	 * Screen Render Type
	 *-----------------------------------------------*/
	var ScreenRenderType = Object.freeze({ 
		ON_SCREEN: 0, 
		FOR_EXPORT: 1,
		THUMBNAIL: 2 });
	
	/*-----------------------------------------------*
	 * Public API
	 *-----------------------------------------------*/
	return {
		RenderToCanvas:			ScreenRenderer_RenderToCanvas,
		RenderPNG:				ScreenRenderer_RenderPNG,
		RenderPNGBlob:			ScreenRenderer_RenderPNGBlob,
		RenderHistogram:		ScreenRenderer_RenderHistogram,
		RenderType:				ScreenRenderType
	};
}());

/*-----------------------------------------------*
 * Exports
 *-----------------------------------------------*/
export { ScreenRenderer, PolygonListRenderer };

