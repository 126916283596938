//----------------------------------------------------------------------------------------
//	Color Picker
//
//	Usage
//		<colorpicker>#804000</colorpicker>
//----------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------
// Color Picker
//----------------------------------------------------------------------------------------
var ColorPicker = (function() {

	var cp_Popup = undefined;
	//var cp_Title = undefined;
	var cp_Target = undefined;
	var cp_Canvas = undefined;
	var cp_Name = undefined;
	
	var cp_Plugin = undefined;
	var cp_Tracking = false;
	var cp_PluginList = [];
	var cp_PluginIdx = 0;
	
	//------------------------------------------------------------------------------------
	// Init
	//------------------------------------------------------------------------------------
	var ColorPicker_Init = function()
	{
		ColorPicker_ConnectUI();
		ColorPicker_AddPopup();
		
		cp_PluginList.push(new ColorPickerPlugin_HSLSlider());
		cp_PluginList.push(new ColorPickerPlugin_RGBSlider());
		cp_PluginList.push(new ColorPickerPlugin_RGBv1());
		cp_PluginList.push(new ColorPickerPlugin_HSL());
		cp_PluginList.push(new ColorPickerPlugin_Grayscale());
		cp_PluginList.push(new ColorPickerPlugin_RGBv2());

		
		cp_PluginIdx = 0;
		cp_Plugin = cp_PluginList[cp_PluginIdx];
		
		ColorPicker_Configure();
	}
	
	//------------------------------------------------------------------------------------
	//	Connect UI
	//------------------------------------------------------------------------------------
	var ColorPicker_ConnectUI = function()
	{
		var cpList = document.getElementsByTagName("colorpicker");
		for (var i = 0; i < cpList.length; i++)
		{
			var e = cpList[i];
			ColorPicker_Connect(e);
		}
	}

	//------------------------------------------------------------------------------------
	//	Connect one color picker element
	//		2020.08.25: Factored out of _ConnectUI to handle colorpickers 
	//		added programmatically
	//------------------------------------------------------------------------------------
	var ColorPicker_Connect = function(colorPickerElement)
	{
		colorPickerElement.addEventListener("click", ColorPicker_OnClick);
		
		ColorPicker_AddValueChangeObserver(colorPickerElement);
		ColorPicker_HandleRGBValueChange(colorPickerElement);

		//e.style.color = ColorPicker_CalcTextRGBForRGB(rgb);
		//e.innerHTML = "";
	}

	var ColorPicker_AddValueChangeObserver = function(element)
	{
		var config = { attributes: true };
		
		var callback = function(mutationsList, observer) {
			for (var mutation of mutationsList)
			{
				//console.log(mutation.type, mutation.attributeName, mutation.target.id);
				
				if (mutation.type == "attributes" && mutation.attributeName == "value" && (cp_Target == undefined || cp_Target.id != mutation.target.id))
					ColorPicker_HandleRGBValueChange(mutation.target);
			}
		}
		
		var observer = new MutationObserver(callback);
		
		observer.observe(element, config);
	}
	

	var ColorPicker_HandleRGBValueChange = function(element)
	{
		var rgb = element.getAttribute("value");
		
		// 2020.10.15: Changed default from black to gray
		if (rgb == "" || rgb == undefined)
			rgb = "#808080";
		
		rgb = ColorPicker_Validate(rgb);
		
		element.style.backgroundColor = rgb;
		element.dataset.rgb = rgb;
	}
	
	var ColorPicker_OnChange = function(evt)
	{
		console.log("ColorPicker_OnChange", evt.target.id);
	}

	//------------------------------------------------------------------------------------
	//	Expand RGB
	//------------------------------------------------------------------------------------
	var ColorPicker_ExpandRGB = function(rgb)
	{
		var eRGB = {};
		
		eRGB.rStr = rgb.substr(1, 2);
		eRGB.gStr = rgb.substr(3, 2);
		eRGB.bStr = rgb.substr(5, 2);
		
		eRGB.r = parseInt(eRGB.rStr, 16);
		eRGB.g = parseInt(eRGB.gStr, 16);
		eRGB.b = parseInt(eRGB.bStr, 16);

		//console.log(JSON.stringify(eRGB));
		return eRGB;
	}
	
	
	//------------------------------------------------------------------------------------
	//	Validate
	//------------------------------------------------------------------------------------
	var ColorPicker_Validate = function(rgb)
	{
		return rgb;
	}
	
	//------------------------------------------------------------------------------------
	//	Add Popup
	//------------------------------------------------------------------------------------
	var ColorPicker_AddPopup = function()
	{
		// The div that contains the entire color picker
		var cpui = document.createElement("colorpickerui");
		cpui.classList.add("colorpickerui-hide");
		
		// Div that contains the the left, right, and name
		var navDiv = document.createElement("div");
		navDiv.classList.add("colorpicker-nav");
		cpui.appendChild(navDiv);  
		
		var leftNav = document.createElement("span");
		leftNav.classList.add("colorpicker-button");
		leftNav.innerHTML = "<";
		leftNav.addEventListener("click", evt => ColorPicker_SelectPicker(evt, "prev"));
		navDiv.appendChild(leftNav); 

		var rightNav = document.createElement("span");
		rightNav.classList.add("colorpicker-button");
		rightNav.innerHTML = ">";
		rightNav.addEventListener("click", evt => ColorPicker_SelectPicker(evt, "next"));
		navDiv.appendChild(rightNav); 
		 
		var pickerName = document.createElement("span");
		pickerName.innerHTML = "name";
		navDiv.appendChild(pickerName); 

		// The color info
		var infodiv = document.createElement("div");
		infodiv.classList.add("colorpicker-info");
		cpui.appendChild(infodiv);
		
		var a = ["R:", "xx", "G:", "xx", "B:", "xx"];
		var n = ["", "rValue", "", "gValue", "", "bValue"];
		
		for (var i = 0; i < a.length; i++)
		{
			var e = document.createElement("span");
			if (a[i] != "")
				e.innerHTML = a[i];
			if (n[i] != "")
				e.classList.add(n[i], "colorpicker-value");
			
			infodiv.appendChild(e);
		}
		
		var e = document.createElement("input");
		e.classList.add("colorpicker-input");
		e.addEventListener("input",   evt => ColorPicker_InputHandler("input",   evt));
		e.addEventListener("keydown", evt => ColorPicker_InputHandler("keydown", evt));
		infodiv.appendChild(e);
		
		// The render area
		var colors = document.createElement("canvas");
		colors.classList.add("colorpicker-colors");
		
		cpui.appendChild(colors);  

		document.body.appendChild(cpui); 
		
		cp_Popup = cpui;
		//cp_Title = title;
		cp_Canvas = colors;
		cp_Name = pickerName;


		cp_Canvas.addEventListener("mousedown", evt => ColorPicker_Mouse(evt, "mousedown"));
		cp_Canvas.addEventListener("mouseup", evt => ColorPicker_Mouse(evt, "mouseup"));
		cp_Canvas.addEventListener("mousemove", evt => ColorPicker_Mouse(evt, "mousemove"));
		cp_Canvas.addEventListener("mouseleave", evt => ColorPicker_Mouse(evt, "mouseleave"));
	}
	
	//------------------------------------------------------------------------------------
	//	Configure
	//------------------------------------------------------------------------------------
	var ColorPicker_Configure = function()
	{
		var config = cp_Plugin.GetConfig();
		
		if (config.sizeX != undefined)
		{
			cp_Canvas.width = config.sizeX;
			cp_Canvas.style.width = config.sizeX;
		}
		
		if (config.sizeY != undefined)
		{
			cp_Canvas.height = config.sizeY;
			cp_Canvas.style.height = config.sizeY;
		}
		
		if (config.name != undefined)
		{
			cp_Name.innerHTML = (config.name != undefined) ? config.name : "";
		}
		
	}
	
	//------------------------------------------------------------------------------------
	//	Set Plugin RGB
	//------------------------------------------------------------------------------------
	var ColorPicker_SetPluginRGB = function(target)
	{
		var rgb = target.dataset.rgb;
		
		if (rgb != undefined)
		{
			var eRGB = ColorPicker_ExpandRGB(rgb);
			cp_Plugin.SetRGB(eRGB.r, eRGB.g, eRGB.b);
		}	
	}
	
	//------------------------------------------------------------------------------------
	//	Select Picker
	//------------------------------------------------------------------------------------
	var ColorPicker_SelectPicker = function(evt, action)
	{
		//cp_Plugin.CancelTracking();
		
		if (action == "next")
			cp_PluginIdx = (cp_PluginIdx + 1) % cp_PluginList.length;
		else
			cp_PluginIdx = (cp_PluginIdx + cp_PluginList.length - 1) % cp_PluginList.length;
		
		cp_Plugin = cp_PluginList[cp_PluginIdx];
		ColorPicker_Configure();
		ColorPicker_SetPluginRGB(cp_Target);
		ColorPicker_Render(cp_Canvas);
		ColorPicker_KeepInWindow();
	}
	
	//------------------------------------------------------------------------------------
	//	Keep In Window
	//		Position the popup so that it is completely inside the window, if possible
	//------------------------------------------------------------------------------------
	var ColorPicker_KeepInWindow = function()
	{
		let left = parseInt(cp_Popup.style.left);
		if (cp_Popup.clientWidth + left > document.documentElement.clientWidth)
		{
			var newLeft = (document.documentElement.clientWidth - cp_Popup.clientWidth);
			if (newLeft < 0)
				newLeft = 0;
			cp_Popup.style.left = newLeft + "px";
		}
	}
	
	//------------------------------------------------------------------------------------
	//	Show
	//		Shows/hides the popup and adds/removes a listener to the document to 
	//		detect mousedowns outside of the popup.
	//------------------------------------------------------------------------------------
	var ColorPicker_Show = function(show)
	{
		if (show)
		{
			cp_Popup.classList.remove("colorpickerui-hide");
			// Add a listener to handle the case when the user clicks outside of the color picker
			document.addEventListener("mousedown", ColorPicker_Hide)
		}
		else
		{
			cp_Popup.classList.add("colorpickerui-hide");
			// Remove the document listener
			document.removeEventListener("mousedown", ColorPicker_Hide)
		}
	}

	//------------------------------------------------------------------------------------
	//	Hide
	//		Called only from a mousedown in the document.
	//------------------------------------------------------------------------------------
	var ColorPicker_Hide = function(evt)
	{
		// If the mouse down is not in the popup and it is not
		// in a color picker element and the color picker popup is shown,
		// then hide the popup
		if (!cp_Popup.contains(evt.target) &&
			 evt.target.tagName != "COLORPICKER" && 
			!cp_Popup.classList.contains("colorpickerui-hide"))
		{
			cp_Plugin.CancelTracking();
			cp_Target = undefined;
			ColorPicker_Show(false);
			evt.stopPropagation();
			evt.preventDefault();
		}
	}
	
	//------------------------------------------------------------------------------------
	//	On Click
	//------------------------------------------------------------------------------------
	var ColorPicker_OnClick = function(evt)
	{
		cp_Tracking = false;

		// If it the picker is already displayed and if the click was on the same
		// control, then hide the color picker
		if (cp_Target != undefined && cp_Target == evt.target)
		{
			cp_Target = undefined;
			ColorPicker_Show(false);
		}
		else
		{
			cp_Target = evt.target;

			// Position the color picker below the control
			var rect = this.getBoundingClientRect();
			cp_Popup.style.top = (rect.bottom + 5) + "px";
			cp_Popup.style.left = rect.left + "px";
		
			// If it is not displayed, then show it
			if (cp_Popup.classList.contains("colorpickerui-hide"))
			{
				ColorPicker_Show(true);
				ColorPicker_Configure(); // set the size
			}
			
			// Show the current color info
			ColorPicker_PopulateColorInfo(cp_Target.dataset.rgb);

			ColorPicker_SetPluginRGB(evt.target);
			ColorPicker_Render(cp_Canvas);

			// Keep it in the window, if possible
			ColorPicker_KeepInWindow();
		}

		evt.stopPropagation();
		evt.preventDefault();
	}
	
	//------------------------------------------------------------------------------------
	//	get mouse position
	//------------------------------------------------------------------------------------
	var ColorPicker_InputHandler = function(action, evt)
	{
		if (action == "keydown")
		{
			if (event.isComposing || event.keyCode === 229) {
				return;
		 	}
			//console.log(evt.keyCode);
		}
		else if (action == "input")
		{
			let val = evt.target.value;
			var val2 = "";
			let ok = "abcdefABCDEF0123456789";
			var start = (val[0] == "#") ? 1 : 0;
			
			for (var i = start; i < val.length && val2.length <= 6; i++)
			{
				if (ok.includes(val[i]))
					val2 += val[i];
			}
			
			if (val[0] == "#")
				val2 = "#" + val2;
				
			if (val != val2)
				evt.target.value = val2;
			
			// Attempt to use the color when there are 6 digits
			if (val2[0] != "#")
				val2 = "#" + val2;
				
			if (val2.length == 7)
			{
				ColorPicker_UpdateColor_RGB(val2);
				ColorPicker_SetPluginRGB(cp_Target);
				ColorPicker_Render(cp_Canvas);
			}
		}
	}
	
	//------------------------------------------------------------------------------------
	//	get mouse position
	//------------------------------------------------------------------------------------
	function getMousePos(aCanvas, evt)
	{
		var rect = aCanvas.getBoundingClientRect();
		var mouseX = (evt.changedTouches ? evt.changedTouches[0].clientX : evt.clientX) - rect.left; //this.offsetLeft;
		var mouseY = (evt.changedTouches ? evt.changedTouches[0].clientY : evt.clientY) - rect.top; //this.offsetTop;
		var insetX = 1; // These match the border on the canvas. Hardcoding is less
		var insetY = 1; // expensive than computing the style
		return {
			x: mouseX - insetX,
			y: mouseY - insetY
		};
	}

	//------------------------------------------------------------------------------------
	//	Render
	//------------------------------------------------------------------------------------
	var ColorPicker_Render = function(canvas)
	{
		var context = canvas.getContext("2d");
		cp_Plugin.Render(context, canvas.width, canvas.height);
	}

	//------------------------------------------------------------------------------------
	//	Make RGB Description (deprecated)
	//		Creates a string with the RGB information in decimal and hex
	//------------------------------------------------------------------------------------
	var ColorPicker_MakeRGBDescription = function(rgb)
	{
		var eRGB = ColorPicker_ExpandRGB(rgb)
		
		var r = ("..." + eRGB.r).slice(-3);
		var g = ("..." + eRGB.g).slice(-3);
		var b = ("..." + eRGB.b).slice(-3);
		
		var str = "R:" + r + " (#" + eRGB.rStr + "), G:" + g + " (#" + eRGB.gStr + "), B:" + b + " (#" + eRGB.bStr + ")";
		
		return str;
	}
	
	//------------------------------------------------------------------------------------
	//	Populate
	//		Show the RGB value
	//------------------------------------------------------------------------------------
	var ColorPicker_PopulateColorInfo = function(rgb)
	{
		var eRGB = ColorPicker_ExpandRGB(rgb)

		var re = cp_Popup.querySelector(".rValue");
		re.innerHTML = eRGB.r;

		var ge = cp_Popup.querySelector(".gValue");
		ge.innerHTML = eRGB.g;
		
		var be = cp_Popup.querySelector(".bValue");
		be.innerHTML = eRGB.b;

		var ie = cp_Popup.querySelector(".colorpicker-input");
		ie.value = rgb;

		//cp_Title.innerHTML = ColorPicker_MakeRGBDescription(rgb);
	}

	//------------------------------------------------------------------------------------
	//	Equal RGB
	//------------------------------------------------------------------------------------
	var ColorPicker_EqualRGB = function(rgb1, rgb2)
	{
		var eRGB1 = ColorPicker_ExpandRGB(rgb1);
		var eRGB2 = ColorPicker_ExpandRGB(rgb2);
		
		var eq = ((eRGB1.r == eRGB2.r) && (eRGB1.g == eRGB2.g) && (eRGB1.b == eRGB2.b));
		
		return eq;
	}
	
	
	//------------------------------------------------------------------------------------
	//	Dispatch Event
	//------------------------------------------------------------------------------------
	var ColorPicker_DispatchEvent = function()
	{
		//var event = document.createEvent('Event');
		//event.initEvent("change", true, true);
		
		var event = new Event("change", {bubbles: true, cancelable: true});

		cp_Target.dispatchEvent(event);
	}
	
	//------------------------------------------------------------------------------------
	//	Mouse
	//		Common dispatch routine for mousedown, mousemove, mouseup, etc
	//------------------------------------------------------------------------------------
	var ColorPicker_Mouse = function(evt, action)
	{
		var rgb = undefined;
		
		if (cp_Plugin.WantsMouseEvents())
		{
			rgb = cp_Plugin.HandleMouse(evt, action);
		}
		else
		{
			rgb = ColorPicker_HandleMouse(evt, action);
		}
		
		ColorPicker_UpdateColor_RGB(rgb);

		evt.stopPropagation();
		evt.preventDefault();

		return true;
	}
	
	var ColorPicker_UpdateColor_RGB = function(rgb)
	{
		// If we received a color and it differs from the current color...
		if (rgb != undefined && !ColorPicker_EqualRGB(rgb, cp_Target.dataset.rgb))
		{
			cp_Target.style.backgroundColor = rgb;
			cp_Target.dataset.rgb = rgb;
			cp_Target.value = rgb;
			
			// Use the below line if there is text in the colorpicker element
			//cp_Target.style.color = ColorPicker_CalcTextRGBForRGB(rgb);;

			ColorPicker_PopulateColorInfo(rgb);
			ColorPicker_DispatchEvent();
		}
	}

	//------------------------------------------------------------------------------------
	//	Handle Mouse
	//------------------------------------------------------------------------------------
	var ColorPicker_HandleMouse = function(evt, action)
	{
		var grabColor = false;
		var rgb = undefined;

		if (action == "mouseup")
		{
			cp_Tracking = false;
		}
		else if (action == "mousedown")
		{
			cp_Tracking = true;
			grabColor = true;
		}
		else if (action == "mousemove")
		{
			grabColor = cp_Tracking;
		}
	
		if (grabColor)
		{
			var canvas = evt.currentTarget;
			var mousePos = getMousePos(canvas, evt);
			var rgb = cp_Plugin.CalcRGB(mousePos);
		}

		return rgb;
	}
	
	//------------------------------------------------------------------------------------
	//	Calc Text RGB For RGB
	//		Calculate a text color so that it can be seen against the 
	//		specified color
	//------------------------------------------------------------------------------------
	var ColorPicker_CalcTextRGBForRGB = function(rgb)
	{
		var eRGB = ColorPicker_ExpandRGB(rgb);
		var avg = (eRGB.r + eRGB.g + eRGB.b)/3;
		var textRGB = (avg < 128) ? "#ffffff" : "#000000";
		
		return textRGB;
	}

	//------------------------------------------------------------------------------------
	//	....
	//------------------------------------------------------------------------------------
	return	{
		Init:			ColorPicker_Init,
		Connect:		ColorPicker_Connect,
		GetMousePos:	getMousePos,
		ExpandRGB:		ColorPicker_ExpandRGB
	};
}());


//------------------------------------------------------------------------------------
//	Utilities
//------------------------------------------------------------------------------------
var MakeRGBHexStr = function(r,g,b)
{
	var c = (r * 256 * 256) + (g * 256) + b;
	var h = "#" + ("000000" + c.toString(16)).slice(-6)
			
	return h;
}

var CalcHSLfromRGB = function(r, g, b, hMax, sMax, lMax)
{
	var rScaled = r/255;
	var gScaled = g/255;
	var bScaled = b/255;
	
	var max = Math.max(rScaled, gScaled, bScaled);
	var min = Math.min(rScaled, gScaled, bScaled);
	var h;
	var s;
	var l = (min + max)/2;
	
	if (min == max)
	{
		h = 0;
		s = 0;
	}
	else
	{
		var range = max - min;
		s = (l > 0.5) ? (range / (2 - max - min)) : (range / (max + min));
		
		if (max == rScaled)
			h = (gScaled - bScaled) / range + ((gScaled < bScaled) ? 6 : 0);
		else if (max == gScaled)
			h = (bScaled - rScaled) / range + 2;
		else
			h = (rScaled - gScaled) / range + 4;
			
		h /= 6;
		
	}
	
	var hsl = {h: h * hMax, s: s * sMax, l: l * lMax};
		
	return hsl;
}

var CalcRGBfromHSL = function(h, s, l, hMax, sMax, lMax)
{
	var range = hMax/6;
	var r = 0;
	var g = 0;
	var b = 0;
	var hue = h;
	
	var lScaled = l/lMax;
	var sScaled = s/sMax;
	var C = (1 - Math.abs(2 * lScaled - 1)) * sScaled;
	//var hScaled = h / range;
	//hScaled = hScaled - Math.floor(hScaled/2) * 2;
	//var X = C * (1 - Math.abs(hue/range % 2 - 1));
	var m = lScaled - C / 2;
	// 2019.10.07: This can become negative due to floating point errors
	if (m < 0)
		m = 0;
	
	if (s == 0)
	{
		r = 0;
		g = 0;
		b = 0;
	}
	else if (hue < range)
	{
		r = C;
		g = C * hue/range;
		b = 0;
	}
	else if (hue < 2 * range)
	{
		r = C * (2 * range - hue)/range;
		g = C;
		b = 0.0;
	}
	else if (hue < 3 * range)
	{
		r = 0.0;
		g = C;
		b = C* (hue - 2 * range)/range;
	}
	else if (hue < 4 * range)
	{
		r = 0.0;
		g = C* (4 * range - hue)/range;
		b = C;
	}
	else if (hue < 5 * range)
	{
		r = C * (hue - 4 * range)/range;
		g = 0;
		b = C;
	}
	else if (hue < 6 * range)
	{
		r = C;
		g = 0;
		b = C * (6 * range - hue)/range;
	}
	else
	{
		r = C;
		g = 0;
		b = 0;
	}
	
	r = Math.min(Math.floor((r + m) * 256), 255);
	g = Math.min(Math.floor((g + m) * 256), 255);
	b = Math.min(Math.floor((b + m) * 256), 255);
	
	var rgb = {r:r, g:g, b:b}
	
	return rgb;
	
} 


//----------------------------------------------------------------------------------------
// Color Picker Plugin: Base class
//----------------------------------------------------------------------------------------
function ColorPickerPlugin()
{
	this.valueR = 128;
	this.valueG = 128;
	this.valueB = 128;
}

ColorPickerPlugin.prototype.Init = function()
{
}

ColorPickerPlugin.prototype.Render = function(context, width, height) 
{
	context.clearRect(0, 0, width, height);
}

ColorPickerPlugin.prototype.CalcRGB = function(loc)
{ 
	return "#808080"; 
}

ColorPickerPlugin.prototype.SetRGB = function(r, g, b)
{ 
	this.valueR = r;
	this.valueG = g;
	this.valueB = b;
}

ColorPickerPlugin.prototype.GetConfig = function()
{ 
	let config = {
		wantsMouseEvents: false,
		name: "Base",
		sizeX: 100, 
		sizeY: 100
	};
	
	return config; 
}

ColorPickerPlugin.prototype.WantsMouseEvents = function()
{
	var config = this.GetConfig();
	return (config.wantsMouseEvents == undefined) ? false : config.wantsMouseEvents;
}

ColorPickerPlugin.prototype.HandleMouse = function(evt, action, mousePos)
{
}

ColorPickerPlugin.prototype.CancelTracking = function()
{
}


//----------------------------------------------------------------------------------------
// Color Picker Plugin: Grayscale
//----------------------------------------------------------------------------------------
function ColorPickerPlugin_Grayscale() {
	ColorPickerPlugin.call(this);
}

ColorPickerPlugin_Grayscale.prototype = Object.create(ColorPickerPlugin.prototype);
Object.defineProperty(ColorPickerPlugin_Grayscale.prototype, 'constructor', {value: ColorPickerPlugin_Grayscale, enumerable: false, writable: true });

ColorPickerPlugin_Grayscale.prototype.Render = function(context, width, height) 
{
		// Gray Scale. 
		for (var i = 0; i < 256; i++)
		{
			context.beginPath();
			context.strokeStyle = "rgba(" + i + "," +  i + " ," + i + ", 1.0)";
			context.moveTo(i, 0);
			context.lineTo(i, 205);
			context.stroke();
		}
}

ColorPickerPlugin_Grayscale.prototype.CalcRGB = function(loc)
{ 
	var h = undefined;
	var x = Math.floor(loc.x);
	
	if (x < 0)
		x = 0;
	else if (x > 255)
		x = 255;
		
	var n = x;

	var c = (n * 256) + (n * 256 * 256) + n;

	h = c.toString(16);
	h = "#" + ("000000" + h).slice(-6);
	
	return h;
}

ColorPickerPlugin_Grayscale.prototype.GetConfig = function()
{ 
	let config = {
		name: "Grayscale",
		sizeX: 256,
		sizeY: 20
	};
	
	return config; 
}

//----------------------------------------------------------------------------------------
// Color Picker Plugin: RGBv1
//----------------------------------------------------------------------------------------
function ColorPickerPlugin_RGBv1() {
	ColorPickerPlugin.call(this);
	
	this.size = 11;
	this.divisions = 5;
}

ColorPickerPlugin_RGBv1.prototype = Object.create(ColorPickerPlugin.prototype);
Object.defineProperty(ColorPickerPlugin_RGBv1.prototype, 'constructor', {value: ColorPickerPlugin_RGBv1, enumerable: false, writable: true });

ColorPickerPlugin_RGBv1.prototype.Render = function(context, width, height) 
{
	let steps = 15 / this.divisions;
	let m = steps * (16 + 1);
	
	for (var red = 0; red <= this.divisions; red++)
	{
		for (var green = 0; green <= this.divisions; green++)
		{
			for (var blue = 0; blue <= this.divisions; blue++)
			{
				var x = red * ((this.divisions + 1) * this.size) + blue * this.size;
				var y = green * (this.size);
				
				context.fillStyle = MakeRGBHexStr(red * m, green * m, blue * m);
				context.fillRect(x, y, this.size, this.size);
				
			}
		}
	}
	
	context.fillStyle = "#808080";

}

ColorPickerPlugin_RGBv1.prototype.CalcRGB = function(loc)
{ 
	var h = undefined;
	var x = Math.floor(loc.x);
	var y = Math.floor(loc.y);
	var maxY = this.size * (this.divisions + 1);
	var maxX = this.size * (this.divisions + 1) * (this.divisions + 1);
	var m = (15 / this.divisions) * (16 + 1);
	
	if (x < 0)
		x = undefined;
	else if (x >= maxX)
		x = undefined;

	if (y < 0)
		y = undefined;
	else if (y >= maxY)
		y = undefined;
		
	if (x != undefined && y != undefined)
	{
		var row = Math.floor(y / this.size);
		var col = Math.floor(x / this.size);
	
		var r = Math.floor(col / (this.divisions + 1));
		var g = row;
		var b = (col % (this.divisions + 1));

		//console.log("Loc", loc.x, loc.y, "Max", maxY, maxX, "Clamped", x, y, "r/c", row, col, "rgb", r, g, b);
	
		var c = (r * m * 256 * 256) + (g * m * 256) + b * m;

		h = c.toString(16);
		h = "#" + ("000000" + h).slice(-6)
	}
	return h;
}


ColorPickerPlugin_RGBv1.prototype.GetConfig = function()
{ 
	let config = {
		name: "RGB Grid",
		sizeX: this.size * (this.divisions + 1) * (this.divisions + 1),
		sizeY: this.size * (this.divisions + 1)
	};
	
	return config; 
}


//----------------------------------------------------------------------------------------
// Color Picker Plugin: RGBv2
//----------------------------------------------------------------------------------------
function ColorPickerPlugin_RGBv2() {
	ColorPickerPlugin.call(this);
	
	this.size = 5;
	this.divisions = 15;
}

ColorPickerPlugin_RGBv2.prototype = Object.create(ColorPickerPlugin.prototype);
Object.defineProperty(ColorPickerPlugin_RGBv2.prototype, 'constructor', {value: ColorPickerPlugin_RGBv2, enumerable: false, writable: true });

ColorPickerPlugin_RGBv2.prototype.Render = function(context, width, height) 
{
	let steps = 15 / this.divisions;
	let m = steps * (16 + 1);
	
	for (var red = 0; red <= this.divisions; red++)
	{
		for (var green = 0; green <= this.divisions; green++)
		{
			for (var blue = 0; blue <= this.divisions; blue++)
			{
				var redX = red % 4;
				var redY = Math.floor(red / 4)
				var x = redX * ((this.divisions + 1) * this.size) + blue * this.size;
				var y = redY * ((this.divisions + 1) * this.size) + green * (this.size);
				
				context.fillStyle = MakeRGBHexStr(red * m, green * m, blue * m);
				context.fillRect(x, y, this.size, this.size);
				
			}
		}
	}
	
	context.fillStyle = "#808080";

}

ColorPickerPlugin_RGBv2.prototype.CalcRGB = function(loc)
{ 
	var h = undefined;
	var x = Math.floor(loc.x);
	var y = Math.floor(loc.y);
	var maxY = this.size * (this.divisions + 1) * (this.divisions + 1);
	var maxX = this.size * (this.divisions + 1) * (this.divisions + 1);
	var m = (15 / this.divisions) * (16 + 1);
	
	if (x < 0)
		x = undefined;
	else if (x >= maxX)
		x = undefined;

	if (y < 0)
		y = undefined;
	else if (y >= maxY)
		y = undefined;
		
	if (x != undefined && y != undefined)
	{
		var row = Math.floor(y / this.size);
		var col = Math.floor(x / this.size);
	
		var r = Math.floor(col / (this.divisions + 1)) + 4 * Math.floor(row / (this.divisions + 1));
		var g = (row % (this.divisions + 1));
		var b = (col % (this.divisions + 1));

		//console.log("Loc", loc.x, loc.y, "Max", maxY, maxX, "Clamped", x, y, "r/c", row, col, "rgb", r, g, b);
	
		var c = (r * m * 256 * 256) + (g * m * 256) + b * m;

		h = c.toString(16);
		h = "#" + ("000000" + h).slice(-6)
	}
	return h;
}


ColorPickerPlugin_RGBv2.prototype.GetConfig = function()
{ 
	let config = {
		name: "RGB Fine Grid",
		sizeX: this.size * (this.divisions + 1) * 4, //* (this.divisions + 1),
		sizeY: this.size * (this.divisions + 1) * 4 //* (this.divisions + 1)
	};
	
	return config; 
}


//----------------------------------------------------------------------------------------
// Color Picker Plugin: HSL
//----------------------------------------------------------------------------------------
function ColorPickerPlugin_HSL() {
	ColorPickerPlugin.call(this);
	
	this.hueMax = 360;
}

ColorPickerPlugin_HSL.prototype = Object.create(ColorPickerPlugin.prototype);
Object.defineProperty(ColorPickerPlugin_HSL.prototype, 'constructor', {value: ColorPickerPlugin_HSL, enumerable: false, writable: true });

ColorPickerPlugin_HSL.prototype.CalcRGBfromHue = function(hue)
{
	var range = this.hueMax/6;
	var r = 0;
	var g = 0;
	var b = 0;
	
	if (hue < range)
	{
		r = 1.0;
		g = hue/range;
		b = 0;
	}
	else if (hue < 2 * range)
	{
		r = (2 * range - hue)/range;
		g = 1.0;
		b = 0.0;
	}
	else if (hue < 3 * range)
	{
		r = 0.0;
		g = 1.0;
		b = (hue - 2 * range)/range;
	}
	else if (hue < 4 * range)
	{
		r = 0.0;
		g = (4 * range - hue)/range;
		b = 1.0;
	}
	else if (hue < 5 * range)
	{
		r = (hue - 4 * range)/range;
		g = 0;
		b = 1.0;
	}
	else if (hue < 6 * range)
	{
		r = 1.0;
		g = 0;
		b = (6 * range - hue)/range;
	}
	else
	{
	
	}
	
	var rgb = {r:r, g:g, b:b}
	
	return rgb;
	
} 

ColorPickerPlugin_HSL.prototype.Render = function(context, width, height) 
{
	let steps = this.hueMax;
	for (var i = 0; i < steps; i++)
	{
		var rgb = this.CalcRGBfromHue(i);
		context.beginPath();
		context.strokeStyle = "rgba(" + Math.floor(rgb.r * 256) + "," +  Math.floor(rgb.g * 256) + " ," + Math.floor(rgb.b * 256) + ", 1.0)";
		context.moveTo(i, 0);
		context.lineTo(i, 205);
		context.stroke();
	}
}

ColorPickerPlugin_HSL.prototype.CalcRGB = function(loc)
{ 
	var h = undefined;
	var hue = Math.floor(loc.x);
	
	var rgb = this.CalcRGBfromHue(hue);
	
	if (rgb != undefined)
	{
		var r = Math.floor(rgb.r * 255);
		var g = Math.floor(rgb.g * 255);
		var b = Math.floor(rgb.b * 255);
		

		var c = (r * 256 * 256) + (g * 256) + b;

		h = c.toString(16);
		h = "#" + ("000000" + h).slice(-6);

	}
	return h;
}

	
ColorPickerPlugin_HSL.prototype.GetConfig = function()
{ 
	let config = {
		name: "Hue",
		sizeX: this.hueMax,
		sizeY: 30
	};
	
	return config; 
}

//----------------------------------------------------------------------------------------
// Color Slider
//----------------------------------------------------------------------------------------
/*
	Slider requirements
		Render bar
		Render value
		Tracking (mouse down, move, up)
		Display value
	Slider design
		Min
		Max
		Value
		Render function
		Bounds rect slider
		Bounds rect value
*/
function ColorSlider(config) {
	this.name = config.name;
	this.min = config.min;
	this.max = config.max;
	this.bounds = Object.assign({}, config.bounds);
	this.renderFunc = config.renderFunc;
	this.value = config.value;
	this.ref = config.ref;
	
	this.tracking = false;
	this.context = undefined;
	
	this.insetX = 3;
	this.insetY = 4;
	this.sliderBounds = Object.assign({}, config.bounds);
	this.sliderBounds.x += this.insetX;
	this.sliderBounds.y += this.insetY;
	this.sliderBounds.width  -= 2 * this.insetX;
	this.sliderBounds.height -= 2 * this.insetY;
}

ColorSlider.prototype.GetValue = function()
{
	return this.value;
} 

ColorSlider.prototype.Render = function(context) 
{
	// If we are called without a context, then use the cached one
	if (context == undefined)
		context = this.context;
	else
		this.context = context;
	
	if (context != undefined)
	{
		var x = this.bounds.x;
		var y = this.bounds.y;
		var width = this.bounds.width;
		var height = this.bounds.height;
		let labelOffsetX = -8;
		let labelOffsetY = 14;
	
		context.fillStyle = "#fff";
		context.fillRect(x, y, width, height);
		// 2019.10.07: Clear area for label
		context.fillRect(x + labelOffsetX, y + labelOffsetY - height, 12, height);
		

		if (this.renderFunc != undefined)
		{
			var r = Object.assign({}, this.sliderBounds);
			this.renderFunc(this.ref, r, context);
		}
		else
		{
			context.fillStyle = "#ccc";
			context.fillRect(this.sliderBounds.x, this.sliderBounds.y, this.sliderBounds.width, this.sliderBounds.height);
		}
	
		var outset = 2;
		context.strokeStyle = "#888";
		context.lineWidth = 0.5;
		context.beginPath();
		context.rect(this.sliderBounds.x - outset, this.sliderBounds.y - outset, this.sliderBounds.width + 2 * outset, this.sliderBounds.height + 2 * outset);
		context.stroke();
		context.lineWidth = 1.0;

		var v = Math.floor((this.value - this.min) / (this.max - this.min) * this.sliderBounds.width);
		context.strokeStyle = "#000";
		context.beginPath();
		context.rect(x + v + 1, y + 1, 4, height - 2);
		context.stroke();


		/*
		if (this.mouseX != undefined)
		{
			context.strokeStyle = "#f00";
			context.beginPath();
			context.rect(this.bounds.x + this.mouseX, this.bounds.y + this.mouseY, 2, 2);
			context.stroke();
		}
		*/
	
		// 2019.10.07: Add label
		if (this.name.length > 0)
		{
			let label = this.name[0].toUpperCase();
			context.font = "10px Helvetica";
			context.fillStyle = "#000";
			context.fillText(label, x + labelOffsetX, y + labelOffsetY);
		}

		context.strokeStyle = "#fff";
	}
}


ColorSlider.prototype.ContainsMouse = function(mousePos) 
{
	var x = mousePos.x;
	var y = mousePos.y
	return (this.bounds.x <= x && x < this.bounds.x + this.bounds.width && this.bounds.y <= y && y < this.bounds.y + this.bounds.height);
}

ColorSlider.prototype.IsTrackingMouse = function() 
{
	return this.tracking;
}

ColorSlider.prototype.CancelTracking = function() 
{
	this.tracking = false;
}

ColorSlider.prototype.HandleMouse = function(evt, action, mousePos) 
{
	var mouseX = mousePos.x - this.bounds.x;
	var mouseY = mousePos.y - this.bounds.y;
	var changed = false;
	this.mouseX = mouseX;
	this.mouseY = mouseY;
	
	
	if (action == "mouseup")
	{
		this.tracking = false;
	}
	else if (action == "mousedown")
	{
		this.tracking = true;
	}
	else if (action == "mousemove")
	{
	}


	if (this.tracking)
	{
		var value = Math.floor((mousePos.x - this.sliderBounds.x) / this.sliderBounds.width * (this.max - this.min)) + this.min;
		
		if (value < this.min)
			value = this.min;
		else if (value > this.max)
			value = this.max;
			
		if (value != this.value)
		{
			changed = true;
			this.value = value;
			
			this.Render();
		}
	}
	
	return changed;
}

//----------------------------------------------------------------------------------------
// Color Picker Plugin: RGB Slider
//----------------------------------------------------------------------------------------
function ColorPickerPlugin_RGBSlider() {
	ColorPickerPlugin.call(this);
	
	//this.valueR = 128;
	//this.valueG = 128;
	//this.valueB = 128;
	
	var config = 
	{
		name: "Red",
		min: 0,
		max: 255,
		value: this.valueR,
		renderFunc: ColorPickerPlugin_RGBSlider.prototype.RenderRedSlider,
		ref: this,
		bounds: {x: 10, y: 10, width: 261, height: 20 }
	};
	
	this.sliderRed = new ColorSlider(config);
	
	config.bounds.y += 30;
	config.value = this.valueG;
	config.name = "Green";
	config.renderFunc = ColorPickerPlugin_RGBSlider.prototype.RenderGreenSlider;
	
	this.sliderGreen = new ColorSlider(config);
	
	config.bounds.y += 30;
	config.value = this.valueB;
	config.name = "Blue";
	config.renderFunc = ColorPickerPlugin_RGBSlider.prototype.RenderBlueSlider;
	
	this.sliderBlue = new ColorSlider(config);
}

ColorPickerPlugin_RGBSlider.prototype = Object.create(ColorPickerPlugin.prototype);
Object.defineProperty(ColorPickerPlugin_RGBSlider.prototype, 'constructor', {value: ColorPickerPlugin_RGBSlider, enumerable: false, writable: true });

ColorPickerPlugin_RGBSlider.prototype.Render = function(context, width, height) 
{
	this.sliderRed.Render(context);
	this.sliderGreen.Render(context);
	this.sliderBlue.Render(context);
}

ColorPickerPlugin_RGBSlider.prototype.RenderSlider = function(thisRef, sliderBounds, context, colorName) 
{
	for (var c = 0; c < 255; c++)
	{
		var r = (colorName == "red")   ? c : thisRef.valueR;
		var g = (colorName == "green") ? c : thisRef.valueG;
		var b = (colorName == "blue")  ? c : thisRef.valueB;
		context.fillStyle = "rgba(" + r + ", " + g + ", " + b + ", 1.0)";
		
		var offset = c; //  / 255 * sliderBounds.width
		context.fillRect(sliderBounds.x + offset, sliderBounds.y, 1, sliderBounds.height);
	}
}


ColorPickerPlugin_RGBSlider.prototype.RenderRedSlider = function(thisRef, sliderBounds, context) 
{
	thisRef.RenderSlider(thisRef, sliderBounds, context, "red");
}

ColorPickerPlugin_RGBSlider.prototype.RenderGreenSlider = function(thisRef, sliderBounds, context) 
{
	thisRef.RenderSlider(thisRef, sliderBounds, context, "green");
}

ColorPickerPlugin_RGBSlider.prototype.RenderBlueSlider = function(thisRef, sliderBounds, context) 
{
	thisRef.RenderSlider(thisRef, sliderBounds, context, "blue");
}


ColorPickerPlugin_RGBSlider.prototype.SetRGB = function(r, g, b)
{ 
	this.valueR = r;
	this.valueG = g;
	this.valueB = b;
	
	this.sliderRed.value = r;
	this.sliderGreen.value = g;
	this.sliderBlue.value = b;
}

ColorPickerPlugin_RGBSlider.prototype.GetConfig = function()
{ 
	let config = {
		wantsMouseEvents: true,
		name: "RGB Slider",
		sizeX: 276,
		sizeY: 110
	};
	
	return config; 
}

ColorPickerPlugin_RGBSlider.prototype.HandleMouse = function(evt, action)
{
	var rgb = undefined;
	var changed = false;
	
	var canvas = evt.currentTarget;
	var mousePos = ColorPicker.GetMousePos(canvas, evt);
	
	if (this.sliderRed.ContainsMouse(mousePos) || this.sliderRed.IsTrackingMouse())
		changed = this.sliderRed.HandleMouse(evt, action, mousePos);
		
	else if (this.sliderGreen.ContainsMouse(mousePos) || this.sliderGreen.IsTrackingMouse())
		changed = this.sliderGreen.HandleMouse(evt, action, mousePos);
		
	else if (this.sliderBlue.ContainsMouse(mousePos) || this.sliderBlue.IsTrackingMouse())
		changed = this.sliderBlue.HandleMouse(evt, action, mousePos);
	
	if (changed)
	{
		this.valueR = this.sliderRed.GetValue();
		this.valueG = this.sliderGreen.GetValue();
		this.valueB = this.sliderBlue.GetValue();
		
		this.sliderRed.Render();
		this.sliderGreen.Render();
		this.sliderBlue.Render();
		
		rgb = MakeRGBHexStr(this.valueR, this.valueG, this.valueB);
	}
	
	if (action == "mouseup" || action == "mouseout" || action == "mouseleave")
	{
		this.CancelTracking();
	}
	
	return rgb;
	
}

ColorPickerPlugin_RGBSlider.prototype.CancelTracking = function()
{
	this.sliderRed.CancelTracking();
	this.sliderGreen.CancelTracking();
	this.sliderBlue.CancelTracking();
}

//----------------------------------------------------------------------------------------
// Color Picker Plugin: HSL Slider
//----------------------------------------------------------------------------------------
function ColorPickerPlugin_HSLSlider() {
	ColorPickerPlugin.call(this);
	
	this.component = "hsl";
	
	this.values = {"h":128, "s":128, "l":128};
	this.sliders = {};
	
	var config = 
	{
		name: "",
		min: 0,
		max: 255,
		ref: this,
		bounds: {x: 10, y: 10, width: 261, height: 20 }
	};
	
	config.value = this.values.h,
	config.name = "H";
	config.renderFunc =  ColorPickerPlugin_HSLSlider.prototype.RenderSliderH,
	this.sliders.h = new ColorSlider(config);
	
	config.bounds.y += 30;
	config.name = "S";
	config.value = this.values.s;
	config.renderFunc = ColorPickerPlugin_HSLSlider.prototype.RenderSliderS;
	this.sliders.s = new ColorSlider(config);
	
	config.bounds.y += 30;
	config.name = "L";
	config.value = this.values.l;
	config.renderFunc = ColorPickerPlugin_HSLSlider.prototype.RenderSliderL;
	this.sliders.l = new ColorSlider(config);
}

ColorPickerPlugin_HSLSlider.prototype = Object.create(ColorPickerPlugin.prototype);
Object.defineProperty(ColorPickerPlugin_HSLSlider.prototype, 'constructor', {value: ColorPickerPlugin_HSLSlider, enumerable: false, writable: true });

ColorPickerPlugin_HSLSlider.prototype.Render = function(context, width, height) 
{
	this.sliders.h.Render(context);
	this.sliders.s.Render(context);
	this.sliders.l.Render(context);
}

ColorPickerPlugin_HSLSlider.prototype.RenderSlider = function(thisRef, sliderBounds, context, component) 
{
	for (var c = 0; c < 255; c++)
	{
		var h = (component == "h") ? c : thisRef.values.h;
		var s = (component == "s") ? c : thisRef.values.s;
		var l = (component == "l") ? c : thisRef.values.l;

		var rgb = CalcRGBfromHSL(h, s, l, 255, 255, 255);
		
		context.fillStyle = "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 1.0)";
		
		var offset = c; //  / 255 * sliderBounds.width
		context.fillRect(sliderBounds.x + offset, sliderBounds.y, 1, sliderBounds.height);
	}
}


ColorPickerPlugin_HSLSlider.prototype.RenderSliderH = function(thisRef, sliderBounds, context) 
{
	thisRef.RenderSlider(thisRef, sliderBounds, context, "h");
}

ColorPickerPlugin_HSLSlider.prototype.RenderSliderS = function(thisRef, sliderBounds, context) 
{
	thisRef.RenderSlider(thisRef, sliderBounds, context, "s");
}

ColorPickerPlugin_HSLSlider.prototype.RenderSliderL = function(thisRef, sliderBounds, context) 
{
	thisRef.RenderSlider(thisRef, sliderBounds, context, "l");
}


ColorPickerPlugin_HSLSlider.prototype.SetRGB = function(r, g, b)
{ 
	this.valueR = r;
	this.valueG = g;
	this.valueB = b;
	
	var hsl = CalcHSLfromRGB(r, g, b, 255, 255, 255);
	
	
	var h = hsl.h;
	var s = hsl.s;
	var l = hsl.l;
	
	this.sliders.h.value = h;
	this.sliders.s.value = s;
	this.sliders.l.value = l;
	
	this.values.h = h;
	this.values.s = s;
	this.values.l = l;
}

ColorPickerPlugin_HSLSlider.prototype.GetConfig = function()
{ 
	let config = {
		wantsMouseEvents: true,
		name: "HSL Slider",
		sizeX: 276,
		sizeY: 110
	};
	
	return config; 
}

ColorPickerPlugin_HSLSlider.prototype.HandleMouse = function(evt, action)
{
	var rgb = undefined;
	var changed = false;
	var components = ["h", "s", "l"]; 
	
	var canvas = evt.currentTarget;
	var mousePos = ColorPicker.GetMousePos(canvas, evt);
	
	for (var i = 0, c = components[i]; i < 3; i++, c = components[i])
		if (this.sliders[c].ContainsMouse(mousePos) || this.sliders[c].IsTrackingMouse())
			changed = changed || this.sliders[c].HandleMouse(evt, action, mousePos);

	
	if (changed)
	{
		for (var i = 0, c = components[i]; i < 3; i++, c = components[i])
			this.values[c] = this.sliders[c].GetValue();

		for (var i = 0, c = components[i]; i < 3; i++, c = components[i])
			this.sliders[c].Render();
			
			
		var rgbobj = CalcRGBfromHSL(this.values.h, this.values.s, this.values.l, 255, 255, 255);
		rgb = MakeRGBHexStr(rgbobj.r, rgbobj.g, rgbobj.b);
		
	}
	
	if (action == "mouseup" || action == "mouseout" || action == "mouseleave")
	{
		this.CancelTracking();
	}
	
	return rgb;
	
}

ColorPickerPlugin_HSLSlider.prototype.CancelTracking = function()
{
	var components = ["h", "s", "l"]; 
	for (var i = 0, c = components[i]; i < 3; i++, c = components[i])
		this.sliders[c].CancelTracking();
}


export { ColorPicker };
