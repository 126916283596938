import { ScreenDesignerData, ScreenGenerator, ScreenGenerationType } from "./ScreenDesignGenerator.js";
import { ScreenDesignLimits, ScreenDesignerDataType } from "./ScreenDesignGenerator.js";
import { FrameShape, DesignType } from "./ScreenDesignGenerator.js";
import { BasicUnits, BasicUnitsMgr, MathUtil } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { Gradient } from "../../VectorUtilsJS/src/GradientLib.js";

import { ScreenDesignerCanvas, ScreenDesignerItemRenderer } from "./ScreenDesignerCanvas.js";
import { ScreenEditorCanvas } from "./ScreenEditor.js";
import { ScreenImageCanvas } from "./ScreenImageCanvas.js";
import { ScreenDesignerFileIO } from "./ScreenDesignerFileIO.js";
import { ScreenRenderer } from "./ScreenRenderer.js";
import { ScreenDesignerWorkbook } from "./ScreenDesignGenerator.js";
import { HelpMgr } from "./HelpMgr.js";
import { ScreenDesignerAccount } from "./ScreenDesignerAccount.js";

import { SystemAnalytics } from "./SystemAnalytics.js";

import { StorageManager } from "../../AmplifyStorageMgr/src/AmplifyStorageMgr.js";
import { ColorPicker } from "./ColorPicker.js"
import { PaletteMenu } from "./PaletteMenu.js"

let gDebugLogging = false;
/*-----------------------------------------------*
 * ScreenDesigner
 *
 *-----------------------------------------------*/
// Constants
var TaskPriority = Object.freeze({ 
	IMMEDIATE: 				0, 
	EDIT_CANVAS: 			1,
	FULL_SCREEN_CANVAS: 	2,
	WORKBOOK_EXPORT_ALL:	6,
	WORKBOOK_THUMBNAIL:		7,
	GALLERY_THUMBNAIL:		8,
	DEFAULT: 				9 });

// Application (singleton)
var ScreenDesigner = (function() {

	// Info passed from SystemMgr (2021.08.03)
	var appConfig = undefined;
	
	// Current on-screen data
	var appData = undefined;
	
	// Undo stack and info
	var undoStack = {};
	
	// Workbook
	var appWorkbookCommonEdit = false;
	
	// Segments and Vectors computed from appData
	var appDesignRender = undefined;

	// Slideshow/demo task
	//var appSlideshow = undefined;
	
	// System settings (not design-specific) 
	var systemSettings = 
	{
		selectPolygon: false,
		showColorPolygons: false
	}

	// List of controls on the page
	var editControlList = [
		// Frame
		{ id: "ID_FrameShape",	 	handler:"onchange", access:"value",		cat:"frame",	prop:"shape",						undo:"always",	regen:1	},
		{ id: "ID_FrameWidth",	 	handler:"oninput",  access:"value",		cat:"frame",	prop:"width",		type:"float", 	undo:"focus",	regen:1, dim:1,	wbCommonEdit:true	},
		{ id: "ID_FrameHeight",	 	handler:"oninput",  access:"value",		cat:"frame",	prop:"height",		type:"float", 	undo:"focus",	regen:1, dim:1,	wbCommonEdit:true	},
		{ id: "ID_AspectRatio",	 	handler:"oninput",  access:"value",		cat:"frame",	prop:"aspect",		type:"float", 	undo:"focus",	regen:1, 	wbCommonEdit:true	},
		{ id: "ID_LockAspectRatio",	handler:"onclick",  access:"checked",	cat:"frame",	prop:"lockAspect",	type:"float", 	undo:"always",				},
		{ id: "ID_FrameRadius",	 	handler:"oninput",  access:"value",		cat:"frame",	prop:"radius",		type:"float", 	undo:"focus",	regen:1, dim:1,	wbCommonEdit:true	},
		{ id: "ID_FrameRotation",	handler:"oninput",  access:"value",		cat:"frame",	prop:"rotation",	type:"float", 	undo:"focus",	regen:1, dim:1	},
		{ id: "ID_FrameBorder",	 	handler:"oninput",  access:"value",		cat:"frame",	prop:"border",		type:"float", 	undo:"focus",	regen:1, dim:1	},
		{ id: "ID_FrameMeasure",	handler:"onchange", access:"value",		cat:"frame",	prop:"measure",						undo:"always",	regen:1	},
		{ id: "ID_FrameRender",		handler:"onchange", access:"value",		cat:"frame",	prop:"render",						undo:"always",	regen:1	},
		{ id: "ID_FrameMargin",	 	handler:"oninput",  access:"value",		cat:"frame",	prop:"margin",		type:"float", 	undo:"focus",	regen:1, dim:1,		wbCommonEdit:true	},
		{ id: "ID_ToggleBounds",	handler:"onclick",	access:"checked",	cat:"general",	prop:"showBounds",					undo:"never",	redraw:true,	wbCommonEdit:true,	noChange:true	},
		{ id: "ID_FixedBounds",		handler:"onclick",	access:"checked",	cat:"frame",	prop:"fixedBounds",					undo:"always",	regen:1,		wbCommonEdit:true	},
		{ id: "ID_DocumentWidth",	handler:"oninput",  access:"value",		cat:"frame",	prop:"docWidth",	type:"float", 	undo:"focus",	regen:1, dim:1,	wbCommonEdit:true	}, //2021.07.06: Changed from 'redraw' to 'regen'
		{ id: "ID_DocumentHeight",	handler:"oninput",  access:"value",		cat:"frame",	prop:"docHeight",	type:"float", 	undo:"focus",	regen:1, dim:1,	wbCommonEdit:true	}, //2021.07.06: Changed from 'redraw' to 'regen'
		{ id: "ID_Note",			handler:"onchange",	access:"value",		cat:"general",	prop:"note",						undo:"always",	optional:true			}, // 2018.01.24: Added, also new 'optional' flag
		{ id: "ID_ToggleMinBounds",	handler:"onclick",	access:"checked",	cat:"general",	prop:"showMinBounds",				undo:"never",	redraw:true,	wbCommonEdit:false,	noChange:true	},

		// Tiling
		{ id: "ID_TileShape", 		handler:"onchange",	access:"value",		cat:"tiling", 	prop:"shape",						undo:"always",	regen:1, regenTile:1	}, 
		{ id: "ID_TileSize", 		handler:"oninput",	access:"value",		cat:"tiling", 	prop:"size",		type:"float", 	undo:"focus",	regen:1, dim:1, regenTile:1	},
		{ id: "ID_SymmetrySides", 	handler:"oninput",	access:"value",		cat:"tiling", 	prop:"symmetrySides",type:"float", 	undo:"focus",	regen:1, regenTile:1	},	// 2020.08.17
		{ id: "ID_Arrangement", 	handler:"onchange",	access:"value",		cat:"tiling", 	prop:"arrangement",					undo:"always",	regen:1, regenTile:1	},	// 2020.08.18
		{ id: "ID_SymmetryRotate",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"symmetryRotate",				undo:"never",			 regenTile:1	},	// 2020.08.18
		{ id: "ID_SymmetryInside",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"symmPointsInside",			undo:"never",			 regenTile:1	},	// 2020.08.18
		{ id: "ID_ClipLinesToTile",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"clipToTile",					undo:"always",	regen:1	},	// 2020.08.18
		{ id: "ID_UseLineColors",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"useLineColors",				undo:"always",	regen:1	},	// 2020.08.25
		{ id: "ID_EnableLattice",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"enableLattice",				undo:"always",	regen:1	},	// 2022.02.01
		{ id: "ID_EnLatticeCurves",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"enableLatticeCurves",			undo:"always",	redraw:true },	// 2022.02.12
		{ id: "ID_CenterAtOrigin",	handler:"onclick",	access:"checked",	cat:"tiling", 	prop:"centered",					undo:"always",	regen:1	},
		{ id: "ID_OrthoCenter",		handler:"onclick",	access:"checked",	cat:"tiling", 	prop:"orthocentered",				undo:"always",	regen:1	},
		{ id: "ID_SegmentWidth",	handler:"oninput",	access:"value",		cat:"tiling", 	prop:"segwidth",	type:"float", 	undo:"never",	editor:true, dim:1,	noChange:true	},
		{ id: "ID_ToggleTiles",		handler:"onclick",	access:"checked",	cat:"tiling",	prop:"showTiles",					undo:"never",	redraw:true,	noChange:true	},
		{ id: "ID_MirrorSegment",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"mirrorSeg",					undo:"never",	editor:true,	noChange:true	},
		{ id: "ID_RotateSegment",	handler:"oninput",	access:"value",		cat:"tiling",	prop:"rotateSeg",	type:"float",	undo:"never",	editor:true,	noChange:true	},
		{ id: "ID_ReflectSegment",	handler:"oninput",	access:"value",		cat:"tiling",	prop:"reflectSeg",	type:"float",	undo:"never",	editor:true,	noChange:true	},
		{ id: "ID_EndCaps",			handler:"onchange",	access:"value",		cat:"tiling",	prop:"endCaps",						undo:"always",	regen:1	},
		{ id: "ID_OffsetX",		 	handler:"oninput",  access:"value",		cat:"tiling",	prop:"offsetX",		type:"float", 	undo:"focus",	regen:1, dim:1,		wbCommonEdit:true	}, // 2018.06.07: Added
		{ id: "ID_OffsetY",		 	handler:"oninput",  access:"value",		cat:"tiling",	prop:"offsetY",		type:"float", 	undo:"focus",	regen:1, dim:1,		wbCommonEdit:true	}, // 2018.06.07: Added
		{ id: "ID_TileRotate",	 	handler:"oninput",  access:"value",		cat:"tiling",	prop:"rotation",	type:"float", 	undo:"focus",	regen:1,		wbCommonEdit:true	}, // 2018.06.08: Added

		// Image
		{ id: "ID_TileShapeIm", 	handler:"onchange",	access:"value",		cat:"tiling", 	prop:"shape",						undo:"always",	regen:1, regenTile:1	},	// 2021.03.11. Same as ID_TileShape
		{ id: "ID_TileSizeIm", 		handler:"oninput",	access:"value",		cat:"tiling", 	prop:"size",		type:"float", 	undo:"focus",	regen:1, dim:1, regenTile:1	},	// 2021.03.11. Same as ID_TileSize
		{ id: "ID_TileSizeBIm", 	handler:"oninput",	access:"value",		cat:"tiling", 	prop:"sizeB",		type:"float", 	undo:"focus",	regen:1, dim:1, regenTile:1	},	// 2021.03.11. Same as ID_TileSizeB
		{ id: "ID_TileRotateIm", 	handler:"oninput",  access:"value",		cat:"tiling",	prop:"rotation",	type:"float", 	undo:"focus",	regen:1					}, 	// 2021.03.13: Same as ID_TileRotate
		{ id: "ID_IncludeClipped",	handler:"onclick",	access:"checked",	cat:"image",	prop:"includeClipped",				undo:"always",	regen:1					},	// 2021.03.27
		{ id: "ID_ImgGrayEqual",	handler:"onclick",	access:"value",		cat:"image",	prop:"grayscaleStyle",				undo:"always",	regen:2, radioValue:"0"	},	// 2021.03.27
		{ id: "ID_ImgGrayGreen",	handler:"onclick",	access:"value",		cat:"image",	prop:"grayscaleStyle",				undo:"always",	regen:2, radioValue:"1"	},	// 2021.03.27
		{ id: "ID_ImgProcessLight",	handler:"onclick",	access:"value",		cat:"image",	prop:"monochromeStyle",				undo:"always",	regen:2, radioValue:"L"	},	// 2021.03.13
		{ id: "ID_ImgProcessDark",	handler:"onclick",	access:"value",		cat:"image",	prop:"monochromeStyle",				undo:"always",	regen:2, radioValue:"D"	},	// 2021.03.13
		{ id: "ID_ImgProcessGray",	handler:"onclick",	access:"value",		cat:"image",	prop:"monochromeStyle",				undo:"always",	regen:2, radioValue:"G"	},	// 2021.03.27
		{ id: "ID_ImgProcessColor",	handler:"onclick",	access:"value",		cat:"image",	prop:"monochromeStyle",				undo:"always",	regen:2, radioValue:"C"	},	// 2021.03.27
		{ id: "ID_ImgProcessLightLn",handler:"onclick",	access:"value",		cat:"image",	prop:"monochromeStyle",				undo:"always",	regen:2, radioValue:"N"	},	// 2022.04.06
		{ id: "ID_ImgProcessDarkLn",handler:"onclick",	access:"value",		cat:"image",	prop:"monochromeStyle",				undo:"always",	regen:2, radioValue:"M"	},	// 2022.04.06
		{ id: "ID_ImageLineSpacing",handler:"oninput",  access:"value",		cat:"image",	prop:"imgLineSpacing", type:"float",undo:"focus",	regen:2, dim:1					},	// 2022.04.08
		{ id: "ID_ImgMinEdgeDist",	handler:"oninput",  access:"value",		cat:"image",	prop:"minEdgeDist",	type:"float", 	undo:"focus",	regen:2, dim:1					},	// 2021.03.19
		{ id: "ID_ImgMinPolySize",	handler:"oninput",  access:"value",		cat:"image",	prop:"minPolySize",	type:"float", 	undo:"focus",	regen:2, dim:1					},	// 2021.03.21
		{ id: "ID_ImgContrast",		handler:"oninput",  access:"value",		cat:"image",	prop:"contrast",	type:"float", 	undo:"focus",	regen:2					},	// 2021.03.24
		{ id: "ID_ImgBrightness",	handler:"oninput",  access:"value",		cat:"image",	prop:"brightness",	type:"float", 	undo:"focus",	regen:2					},	// 2021.03.24
		{ id: "ID_ImgPosterize",	handler:"onclick",	access:"checked",	cat:"image",	prop:"posterize",					undo:"always",	regen:2					},	// 2021.03.29
		{ id: "ID_ImgPstLevels",	handler:"oninput",  access:"value",		cat:"image",	prop:"posterizeLevels",type:"float", undo:"focus",	regen:2					},	// 2021.03.29
		{ id: "ID_ImgZoom",			handler:"oninput",  access:"value",		cat:"image",	prop:"imgZoom",		type:"float",	undo:"focus",	regen:2					},	// 2021.03.29
		{ id: "ID_ImgAlignTop",		handler:"onclick",	access:"value",		cat:"image",	prop:"slotAlign",					undo:"always",	regen:2, radioValue:"T"	},	// 2022.11.04
		{ id: "ID_ImgAlignCenter",	handler:"onclick",	access:"value",		cat:"image",	prop:"slotAlign",					undo:"always",	regen:2, radioValue:"C"	},	// 2022.11.04
		{ id: "ID_ImgAlignBottom",	handler:"onclick",	access:"value",		cat:"image",	prop:"slotAlign",					undo:"always",	regen:2, radioValue:"B"	},	// 2022.11.04
		{ id: "ID_ImgStyleCorner",	handler:"onclick",	access:"value",		cat:"image",	prop:"slotStyle",					undo:"always",	regen:2, radioValue:"C"	},	// 2022.11.04
		{ id: "ID_ImgStyleSloped",	handler:"onclick",	access:"value",		cat:"image",	prop:"slotStyle",					undo:"always",	regen:2, radioValue:"S"	},	// 2022.11.04
		
		// Sketch
		{ id: "ID_TileShapeSk", 	handler:"onchange",	access:"value",		cat:"tiling", 	prop:"shape",						undo:"always",	regen:1, regenTile:1	},	// 2021.05.11. Same as ID_TileShape
		{ id: "ID_TileSizeSk", 		handler:"oninput",	access:"value",		cat:"tiling", 	prop:"size",		type:"float", 	undo:"focus",	regen:1, dim:1, regenTile:1	},	// 2021.05.11. Same as ID_TileSize
		{ id: "ID_TileRotateSk", 	handler:"oninput",  access:"value",		cat:"tiling",	prop:"rotation",	type:"float", 	undo:"focus",	regen:1					}, 	// 2021.05.13: Same as ID_TileRotate
		{ id: "ID_ToggleTilesSk",	handler:"onclick",	access:"checked",	cat:"tiling",	prop:"showTiles",					undo:"never",	redraw:true,	noChange:true	},
		{ id: "ID_SketchLineWidth",	handler:"oninput",	access:"value",		cat:"general", 	prop:"sketchLineWidth",type:"float", undo:"never",	regen:1	},

		// General
		{ id: "ID_ToggleFill",		handler:"onclick",	access:"checked",	cat:"general",	prop:"renderFill",					undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ToggleLine",		handler:"onclick",	access:"checked",	cat:"general",	prop:"renderLine",					undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ToggleBack",		handler:"onclick",	access:"checked",	cat:"general",	prop:"renderBack",					undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ToggleCenter",	handler:"onclick",	access:"checked",	cat:"general",	prop:"renderCenter",				undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ToggleLattice",	handler:"onclick",	access:"checked",	cat:"general",	prop:"renderLattice",				undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	}, // 2022.02.03
		{ id: "ID_ToggleMidLine",	handler:"onclick",	access:"checked",	cat:"general",	prop:"renderMidLine",				undo:"always",	regen:1	, 						wbCommonEdit:true	}, // 2022.02.03
		{ id: "ID_FillColor", 		handler:"onchange",	access:"value",		cat:"general",	prop:"fillColor",					undo:"focus",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_LineColor", 		handler:"onchange",	access:"value",		cat:"general",	prop:"lineColor",					undo:"focus",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_BackColor", 		handler:"onchange",	access:"value",		cat:"general",	prop:"backColor",					undo:"focus",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_LineWidth", 		handler:"oninput",	access:"value",		cat:"general",	prop:"lineWidth",	type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_CornerSize",		handler:"oninput",	access:"value",		cat:"general",	prop:"cornerSize",	type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true	},
		{ id: "ID_CornerStyle",		handler:"onchange",	access:"value",		cat:"general",	prop:"cornerStyle",					undo:"always",	redraw:true, 	editor:true	},
		{ id: "ID_CurvesFrameOut",	handler:"onclick",	access:"checked",	cat:"general",	prop:"curveFrameOutsideCorner",		undo:"always",	regen:1	},
		{ id: "ID_CurvesFrameIn",	handler:"onclick",	access:"checked",	cat:"general",	prop:"curveFrameInsideCorner",		undo:"always",	regen:1	},
		{ id: "ID_CurvesFrameTile",	handler:"onclick",	access:"checked",	cat:"general",	prop:"curveFrameToSegmentCorner",	undo:"always",	regen:1	},
		{ id: "ID_CurvesTile",		handler:"onclick",	access:"checked",	cat:"general",	prop:"curveSegmentCorners",			undo:"always",	regen:1, 	editor:true	},
		{ id: "ID_CenterColor", 	handler:"onchange",	access:"value",		cat:"general",	prop:"centerColor",					undo:"focus",	redraw:true, 			editor:true,	wbCommonEdit:true	}, // 2022.02.03
		{ id: "ID_CenterWidth",		handler:"oninput",	access:"value",		cat:"general",	prop:"centerWidth",	type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	}, // 2022.02.03
		{ id: "ID_LatticeColor", 	handler:"onchange",	access:"value",		cat:"general",	prop:"latticeColor",				undo:"focus",	redraw:true, 			editor:true,	wbCommonEdit:true	}, // 2022.02.03
		{ id: "ID_LatticeWidth",	handler:"oninput",	access:"value",		cat:"general",	prop:"latticeWidth",type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	}, // 2022.02.03
		{ id: "ID_LatticeOffset",	handler:"oninput",	access:"value",		cat:"general",	prop:"latticeOffset",type:"float", 	undo:"focus",	regen:1,     dim:1		}, // 2022.02.03
		{ id: "ID_CenterClipLattice",handler:"onclick",	access:"checked",	cat:"general",	prop:"clipCenterToLattice",			undo:"always",	regen:1 },	// 2022.03.03
		{ id: "ID_MidLineClipLattice",handler:"onclick",access:"checked",	cat:"general",	prop:"clipMidLineToLattice",		undo:"always",	regen:1 },	// 2022.03.04
		{ id: "ID_MidLineColor", 	handler:"onchange",	access:"value",		cat:"general",	prop:"midLineColor",					undo:"focus",	redraw:true, 			editor:true,	wbCommonEdit:true	}, // 2022.03.04
		{ id: "ID_MidLineWidth",	handler:"oninput",	access:"value",		cat:"general",	prop:"midLineWidth",	type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	}, // 2022.03.04
		{ id: "ID_MidLinePosition",	handler:"oninput",	access:"value",		cat:"general",	prop:"midLineLocScale",	type:"float", 	undo:"focus",	regen:1			}, // 2022.03.09
		{ id: "ID_MidLineOffset",	handler:"oninput",	access:"value",		cat:"general",	prop:"midLineLocOffset",type:"float", 	undo:"focus",	regen:1, dim:1	}, // 2022.03.09

		{ id: "ID_ToggleShadowLine",handler:"onclick",	access:"checked",	cat:"general",	prop:"renderShadowLine",			undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	}, // 2020.09.09: Added shadow
		{ id: "ID_ToggleShadowFill",handler:"onclick",	access:"checked",	cat:"general",	prop:"renderShadowFill",			undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ShadowLineAbove",	handler:"onclick",	access:"checked",	cat:"general",	prop:"shadowLineAboveColor",		undo:"always",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ShadowColor", 	handler:"onchange",	access:"value",		cat:"general",	prop:"shadowColor",					undo:"focus",	redraw:true, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ShadowX",		 	handler:"oninput",  access:"value",		cat:"general",	prop:"shadowX",		type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ShadowY",		 	handler:"oninput",  access:"value",		cat:"general",	prop:"shadowY",		type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ShadowWidth", 	handler:"oninput",	access:"value",		cat:"general",	prop:"shadowWidth",	type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	},
		{ id: "ID_ShadowBlur", 		handler:"oninput",	access:"value",		cat:"general",	prop:"shadowBlur",	type:"float", 	undo:"focus",	redraw:true, dim:1, 	editor:true,	wbCommonEdit:true	},

		{ id: "ID_OutputBack",		handler:"onclick",	access:"checked",	cat:"general",	prop:"outputBackground",			undo:"always"						}, // 2022.02.14

// 		{ id: "ID_GradientEnable",	handler:"onclick",	access:"checked",	cat:"general",	prop:"gradientEnable",				undo:"always",	redraw:true 		},	// 2021.06.24
// 		{ id: "ID_GradientColorA",	handler:"onchange",	access:"value",		cat:"general",	prop:"gradientColorA",				undo:"always",	redraw:true 		},	// 2021.06.24
// 		{ id: "ID_GradientColorB",	handler:"onchange",	access:"value",		cat:"general",	prop:"gradientColorB",				undo:"always",	redraw:true 		},	// 2021.06.24
// 		{ id: "ID_GradientStyle", 	handler:"onchange",	access:"value",		cat:"general", 	prop:"gradientStyle",				undo:"always",	redraw:true			},	// 2021.06.25
// 		{ id: "ID_GradientRamp", 	handler:"onchange",	access:"value",		cat:"general", 	prop:"gradientRamp",				undo:"always",	redraw:true			},	// 2021.06.25
		
		// Units
		{ id: "ID_Units", 		 	handler:"onchange", access:"value",		cat:"general",	prop:"units",						undo:"always",					wbCommonEdit:true	},
		{ id: "ID_DPI", 		 	handler:"oninput",  access:"value",		cat:"general",	prop:"dpi",			type:"float",	undo:"focus",					wbCommonEdit:true	},
		{ id: "ID_ScaleDimension",	handler:"onchange", access:"value",  	sys:"units?",	prop:"scaleDimension",											 	noChange:true	},
		
		// Drill Holes
		{ id: "ID_FrVertexHole",	handler:"onclick",  access:"checked",	cat:"drillholes", prop:"dhFrameVertex",							undo:"always",	regen:1	},
		{ id: "ID_FrVtxHoleSize",	handler:"oninput",  access:"value",		cat:"drillholes", prop:"dhFrameVertexSize",		type:"float",	undo:"focus", dim:1,	regen:1	},
		{ id: "ID_FrVtxHoleOffset",	handler:"oninput",  access:"value",		cat:"drillholes", prop:"dhFrameVertexOffset",	type:"float",	undo:"focus", dim:1,	regen:1	},
		
		// Diags
		{ id: "ID_ToggleSegments", 	handler:"onclick",  access:"checked",  cat:"diags",		prop:"showSegments",				undo:"never",	redraw:true, 	noChange:true	},
		{ id: "ID_ToggleHairline", 	handler:"onclick",  access:"checked",  cat:"diags",		prop:"showHairlinePoly",			undo:"never",	redraw:true, 	noChange:true	},
		{ id: "ID_ToggleCenterPoly",handler:"onclick",  access:"checked",  cat:"diags",		prop:"showCenterPoly",				undo:"never",	redraw:true, 	noChange:true	},
		{ id: "ID_ToggleOffsetPoly",handler:"onclick",  access:"checked",  cat:"diags",		prop:"showOffsetPoly",				undo:"never",	redraw:true, 	noChange:true	},
		
		// Systems settings (including diags); not stored with design
		{ id: "ID_DebugSelectPoly",	handler:"onclick",  access:"checked",  sys:"debug?",	prop:"selectPolygon",								redraw:true, 	noChange:true	},
		{ id: "ID_ShowColorPoly",	handler:"onclick",  access:"checked",  sys:"diags",		prop:"showColorPolygons",							redraw:true, 	noChange:true	},
	];
	
	var buttonControlList = [
	//	{ id: "ID_ZoomFullTile", 		call:"zoom"			},
	//	{ id: "ID_ZoomSmallestSubTile", call:"zoom"			},
		{ id: "ID_ZoomFrameFull", 		call:"zoom"			},
		{ id: "ID_ZoomFrameOne", 		call:"zoom"			},
		{ id: "ID_NewSimilarDesign", 	call:"new"			},
		{ id: "ID_NewDefaultDesign", 	call:"new"			},
		{ id: "ID_NewWorkbookDesign", 	call:"new"			},
		{ id: "ID_NewDesignCopy", 		call:"new"			},
		{ id: "ID_NewProjectDesign", 	call:"project"		},
		{ id: "ID_NewDesign_Regular", 	call:"project"		},
		{ id: "ID_NewDesign_Image",	 	call:"project"		},
		{ id: "ID_NewDesign_Sketch", 	call:"project"		},
		{ id: "ID_NewProjectDesign2", 	call:"project"		},
		{ id: "ID_AddProjectDesign", 	call:"project"		},
		{ id: "ID_AddProjectDesign2", 	call:"project"		},
		{ id: "ID_CloseProject",	 	call:"project"		},
		{ id: "ID_NewProject",		 	call:"project",		restricted:"createProject"	},
		{ id: "ID_DeleteProject",		call:"project"		},
		{ id: "ID_NewProject2",		 	call:"project",		restricted:"createProject"	},
		{ id: "ID_OpenProject",		 	call:"project"		},
		{ id: "ID_OpenProject2",	 	call:"project"		},
		{ id: "ID_HideProjectList",	 	call:"project"		},
		{ id: "ID_ProjectListBackground",call:"project"		},
		{ id: "ID_HideSelectDesignType",call:"project"		},
		{ id: "ID_SelectDesignTypeBackground",call:"project"},
		{ id: "ID_ClearLines", 			call:"modify"		},
		{ id: "ID_RestoreDefaults", 	call:"modify"		},
		{ id: "ID_CloseGaps", 			call:"modify"		},
		//{ id: "ID_SaveSVG", 			call:"save"			},
		//{ id: "ID_SaveTXT", 			call:"save"			},
		//{ id: "ID_SaveDXF", 			call:"save"			},
		//{ id: "ID_ExportPNG", 		call:"save"			},
		//{ id: "ID_WindowPNG", 		call:"save"			},
		//{ id: "ID_WindowSVG", 		call:"save"			},
		//{ id: "ID_WindowMinPNG", 		call:"save"			},
		//{ id: "ID_TestWndMinPNG", 	call:"save"			},
		//{ id: "ID_ExportMinPNG", 		call:"save"			},
		//{ id: "ID_ExportDXF", 		call:"save"			},
		{ id: "ID_SaveAsJSON", 			call:"save"			}, // 2021.04.05
		{ id: "ID_DesignExport_Zazzle", call:"export"		}, // 2021.08.03
		{ id: "ID_DownloadSelected", 	call:"save",		restricted:"downloadDesign"	},
		{ id: "ID_AddToBook", 			call:"book"			},
		{ id: "ID_AddCopyToBook", 		call:"book"			},
		{ id: "ID_NewBookFromProject",	call:"book"			},
		{ id: "ID_SaveBook", 			call:"book",		restricted:"saveWorkbook"	},
		{ id: "ID_CloseBook", 			call:"book"			},
		{ id: "ID_WorkbookEditAll",		call:"book"			}, // DDK 2018.01.19
		{ id: "ID_GalleryLabel",		call:"misc"			}, // DDK 2018.03.02, 2018.07.20: Changed to "misc"
		{ id: "ID_ShowHelp",			call:"misc"			}, // DDK 2018.06.05, 2018.07.20: Changed to "misc"
		{ id: "ID_OrgExpanderButton",	call:"misc"			}, // DDK 2018.06.25
		{ id: "ID_OpenDesign",			call:"open",		restricted:"uploadDesign"	}, // DDK 2018.10.03
		{ id: "ID_OpenWorkbook",		call:"open",		restricted:"saveWorkbook"	},
		{ id: "ID_ImportWorkbook",		call:"open",		restricted:"saveWorkbook"	},
		{ id: "ID_LoadImageBtn",		call:"open",		},	// DDK 2020.11.30, 2021.03.09
		{ id: "ID_RemoveImageBtn",		call:"open",		},	// DDK 2021.03.12
		{ id: "ID_SwapFgBgColorsBtn",	call:"misc",		},	// DDK 2021.03.12
		{ id: "ID_ResetImgAdj",			call:"misc",		},	// DDK 2021.03.12
		{ id: "ID_ResetImgZoom",		call:"misc",		},	// DDK 2021.03.30
		{ id: "ID_CopyJSON",			call:"misc",		},	// DDK 2021.04.05
		{ id: "ID_ConvertToPixels",		call:"modify",		},	// DDK 2021.06.28
		{ id: "ID_ConvertToInches",		call:"modify",		},	// DDK 2021.06.30
		{ id: "ID_ConvertToMM",			call:"modify",		},	// DDK 2021.06.30
		{ id: "ID_ScaleDesign",			call:"modify",		},	// DDK 2021.06.30
		{ id: "ID_ScaleTile",			call:"modify",		}	// DDK 2021.07.12

	];
	
	var downloadFormatCheckboxes = [
		{ id: "ID_SaveSVG", 		a:0		},
		{ id: "ID_SaveTXT", 		a:0		},
	//	{ id: "ID_SaveJSON", 		a:0		},
		{ id: "ID_SaveDXF", 		a:0		},
		{ id: "ID_ExportPNG", 		a:0		},
		{ id: "ID_WindowPNG", 		a:0		},
		{ id: "ID_WindowSVG", 		a:0		},
		{ id: "ID_WindowMinPNG", 	a:0		},
		{ id: "ID_TestWndMinPNG", 	a:0		},
		{ id: "ID_ExportMinPNG", 	a:0		},
		{ id: "ID_ExportDXF", 		a:0		},
		{ id: "ID_ExportSVG", 		a:0		}
	];

	//---------------------------------------------------------------------------
	//	Line Info Table constants
	//		Note: The ColType table is a duplicate of the same table in ScreenEditor.js
	//---------------------------------------------------------------------------
	var ColType = Object.freeze({
		SPACE		: 0,
		RADIO		: 1,	// Boolean value
		RIGHT_NUM	: 2,
		RIGHT_DEC	: 3,
		MULTI_VAL	: 4,	// Value list (clicking rotates through values)
		SELECTED	: 5,
		COLOR		: 6,
		BIT_FIELD	: 7		// 2022.03.10: Added for lattice behavior
	});
	
	// 2022.02.08: Moved from ScreenEditor; added group column
	var seAllLineInfoCols = Object.freeze([
		{width:15,	label:"m",		prop:"mirror",		selectable: true,	edit:true,  colType:ColType.RADIO, 		key:"m", desc: "Mirrored"			},
		{width:15,	label:"M",		prop:"reflect",		selectable: true,	edit:true,  colType:ColType.RIGHT_NUM,	key:"n", desc: "Mirror Line"		},
		{width:15,	label:"r",		prop:"rotate",		selectable: true,	edit:true,  colType:ColType.RIGHT_NUM, 	key:"r", desc: "Rotated"			},
		{width:30,	label:"w",		prop:"width",		selectable: true,	edit:true,  colType:ColType.RIGHT_DEC,			 desc: "Segment Width"		},
		{width:15,	label:"v",		prop:"visible",		selectable: true,	edit:true,  colType:ColType.RADIO, 		key:"v", desc: "Visible"			},
		{width:20,	label:"deg",	prop:"angle",		selectable: false,	edit:true,  colType:ColType.RIGHT_NUM, 			 desc: "Angle"				},
	/*	{width:20,	label:"len",	prop:"length",		selectable: false,	edit:true,  colType:ColType.RIGHT_NUM, 			 desc: "Length"				},*/
		{width:10,  									selectable: false,				colType:ColType.SPACE											},
		{width:15,	label:"",		prop:"ptAselected",	selectable: true,	edit:false, colType:ColType.SELECTED,	key:"a", desc: "Point A Selected",						hover:"ptA" },
		{width:15,	label:"",		prop:"ptBselected",	selectable: true,	edit:false, colType:ColType.SELECTED,	key:"b", desc: "Point B Selected",						hover:"ptB"	},
		{width:15,	label:"c",		prop:"colorId",		selectable: true,	edit:true,  colType:ColType.COLOR,		key:"c", desc: "Color",				group:"color"	},
		
		{width:15,	label:"Start",	prop:"wzA",			selectable: true,	edit:true,  colType:ColType.RIGHT_NUM, 			 desc: "Point A height",	group:"lattice",	hover:"ptA"	},
		{width:30,	label:"",		prop:"wbA",			selectable: true,	edit:true,  colType:ColType.BIT_FIELD, 			 desc: "Point A weave",		group:"lattice",	hover:"ptA"	},
		{width:15,	label:"Line",	prop:"wzL",			selectable: true,	edit:true,  colType:ColType.RIGHT_NUM, 			 desc: "Line height",		group:"lattice"	},
		{width:35,	label:"",		prop:"wbL",			selectable: true,	edit:true,  colType:ColType.BIT_FIELD, 			 desc: "Line weave",		group:"lattice"	},
		{width:15,	label:"End",	prop:"wzB",			selectable: true,	edit:true,  colType:ColType.RIGHT_NUM, 			 desc: "Point B height",	group:"lattice",	hover:"ptB"	},
		{width:30,	label:"",		prop:"wbB",			selectable: true,	edit:true,  colType:ColType.BIT_FIELD, 			 desc: "Point B weave",		group:"lattice",	hover:"ptB"	},
		]);
	
	// Namespace values for SVG (XML) export
	var Namespace_CompanyName = "thevaportrail";
	var Namespace_AppName = "screendesigner";
	var Namespace_DataName = "screendesignerdata";
	var Namespace_DataId   = "ID_ScreenDesignerData";
	
	// Local storage id and property list for persistent layout settings
	var LocalStorage_PersistentSettings = "ID_ScreenDesigner_PersistentLayoutSettings";
	var LocalStorage_PersistentSettings_Help = "ID_ScreenDesigner_PersistentHelpSettings";
	var appHelpVersion = 2;
	
	//---------------------------------------------------------------------------
	//	Initialize, Open, Close, and Revealed
	//		Primary Entry Points from the System Manager
	//		2021.08.03: Added appConfig
	//---------------------------------------------------------------------------
	var ScreenDesigner_Init = function(theAppConfig)
	{
		// Store appConfig
		appConfig = theAppConfig;
		
		// Start with default data
		appData = ScreenDesigner_NewScreenDesignerData(DesignType.TYPE_REGULAR_TILING);
		
		// Create a render object. We do this after _RetrieveSettings because
		// _RetrieveSettings can update appData
		appDesignRender = ScreenGenerator.Create(appData, ScreenGenerationType.COMPLETE_DESIGN);
		
		// Show the intro/help screen if the first time
		// 2019.09.24: Don't show help on small mobile phones
		if (window.screen.width >= 600)
			ScreenDesigner_OptionallyDisplayIntro();
		
		// Connect handlers to events and UI (2018.07.17: created routine and moved code from here)
		ScreenDesigner_ConnectHandlers();
		
		// Construct the UI for the color gradient
		ScreenDesigner_CreateColorGradientUI();
		
		// Undo support
		ScreenDesigner_UndoHandler_ResetStack();
		
		// Initialize the canvases
		ScreenDesignerCanvas.Init("ID_EditCanvas");
		ScreenEditorCanvas.Init("ID_TileCanvas");
		ScreenImageCanvas.Init("ID_ImageCanvas");

		// Resize the UI according to the window size
		ScreenDesigner_ResizeWindowHandler();

		// Update values in the UI
		ScreenDesigner_PopulateControlValues(appData)
		ScreenDesigner_UpdateUnits(appData.general.units);
		ScreenDesigner_UpdateEditorRenderOptions();
		ScreenDesigner_InitFloatingPanels();

		
		// The 'design render' is the object that has the information generated from
		// the design data. We give it to the ScreenDesignerCanvas so it can show the 
		// rendered design
		ScreenDesignerCanvas.SetDesignRender(appDesignRender);
		
		// The ScreenEditorCanvas is where the tile is edited. It needs data
		// to show the current design also
		var editTileInfo = ScreenGenerator.GetEditTileInfo(appData, undefined);
		ScreenEditorCanvas.SetEditTileInfo(editTileInfo);	
		if (appData.elements.length > 0)
				ScreenEditorCanvas.LoadLineData(appData.elements);
		ScreenEditorCanvas.Render();

		// Set the nameSpace of the fileIO module. This is used to embed info about
		// the app into the SVG and DXF files.
		var nameSpace = {
			companyName:Namespace_CompanyName, 
			appName:Namespace_AppName, 
			dataName:Namespace_DataName, 
			dataId:Namespace_DataId};

		ScreenDesignerFileIO.Init(nameSpace);

		// Load the gallery		
		ScreenDesigner_Gallery.Load();
				
		// Initiate a render of the design, and tell the large canvas to zoom to
		// show the entire design and then render the design
		ScreenDesigner_RenderRequest.RenderFullDesign(appDesignRender, false /* phase delay */);
		ScreenDesignerCanvas.ZoomToFrame();
		ScreenDesignerCanvas.Render();
		
		// Initial update of UI
		ScreenDesigner_Workbook.UI_UpdateDesignCount();
		
		// Show the 'solid tile' tool selected when the first sketch design is shown
		ScreenDesigner_SelectSketchTool("ID_SketchToolbar_SolidTile");
		
		ScreenDesigner_DesignExport.ImageCleanUp();
	}

	//---------------------------------------------------------------------------
	//	Open
	//		Called after user signs in
	//---------------------------------------------------------------------------
	var ScreenDesigner_Open = function()
	{
		// 2019.03.11: Load any stored settings
		ScreenDesigner_RetrieveSettings();

		// Load info from the cloud
		ScreenDesigner_StorageMgr.Open();

		// Set-up handler to warn of lost changes
		window.addEventListener("beforeunload", ScreenDesigner_BeforeUnloadHandler);
	}

	var ScreenDesigner_Close = function()
	{
		// Set-up handler to warn of lost changes
		window.removeEventListener("beforeunload", ScreenDesigner_BeforeUnloadHandler);
		
		// 2018.07.18: Upload any changes
		ScreenDesigner_StorageMgr.UploadChanges();

		// Close (reset) the storage manager
		ScreenDesigner_StorageMgr.Close();
		
		// Need to clear all customer data and reset to the default state
		
		// Hide the project list in case it is showing
		ScreenDesigner_StorageMgr.HideProjectList();
		
		// 2021.08.11: Hide the design export dialog, in case it is showing
		ScreenDesigner_DesignExport.HideDialog();
		
		// Close the workbook, which also clears the UI
		ScreenDesigner_Workbook.Close();
		
		// Reset design data
		ScreenDesigner_ClearDesignData();
		
		// 2021.03.26: Clear image
		ScreenImageCanvas.ClearImageData();
	}

	var ScreenDesigner_Revealed = function()
	{
		ScreenDesigner_ResizeWindowHandler();
		
		ScreenDesignerCanvas.ZoomToFrame();
		ScreenDesignerCanvas.Render();
		
		// 2019.09.24: For iPhone and other small devices, show the gallery
		if (window.screen.width < 400)
			ScreenDesigner.UI_SelectTab("ID_GalleryAreaR");
	}
	
	//---------------------------------------------------------------------------
	//	Unload Handler
	//---------------------------------------------------------------------------
	var ScreenDesigner_BeforeUnloadHandler = function(e)
	{
		var dirty = true;
		
		// 2018.07.18: Upload any changes
		ScreenDesigner_StorageMgr.UploadChanges();
		
		// 2019.03.11: Store settings
		ScreenDesigner_StoreSettings();
		
		// These messages will not be used in modern browsers (2017)
		if (ScreenDesigner_CurrentDesignNeedsSaving() && ScreenDesigner_Workbook.IsDirty())
			e.returnValue = "Changes to current design and current worbook will be lost.";
		else if (ScreenDesigner_Workbook.IsDirty())
			e.returnValue = "Changes to current worbook will be lost.";
		else if (ScreenDesigner_CurrentDesignNeedsSaving())
			e.returnValue = "Changes to current design will be lost.";
		else
			dirty = false;
						
		return dirty;
	}
	
	//var ScreenDesigner_UnloadHandler = function(e)
	//{
	//}
	
	//---------------------------------------------------------------------------
	// Connect Handlers 
	//---------------------------------------------------------------------------
	var ScreenDesigner_ConnectHandlers = function()
	{
		// Set-up the handler to handle resize events and also call the handler
		// directly for the initial set-up. This will also render everything
		window.addEventListener("resize", ScreenDesigner_ResizeWindowHandler, false);

		// Set-up handle to store local settings when leaving page
		// window.addEventListener("unload", ScreenDesigner_UnloadHandler);
				
		// Set-up the handler for the "load" and "import" buttons
		document.getElementById('ID_LoadFile').addEventListener('change', ScreenDesigner_LoadFile, false);
		document.getElementById('ID_LoadBook').addEventListener('change', ScreenDesigner_LoadWorkbook, false);
		document.getElementById('ID_ImportBook').addEventListener('change', ScreenDesigner_ImportWorkbook, false);
		document.getElementById('ID_LoadGallery').addEventListener('change', ScreenDesigner_LoadGallery, false);
		document.getElementById('ID_LoadImageFile').addEventListener('change', ScreenDesigner_LoadImage, false); // 2020.11.30
		
		// Project-specific fields
		document.getElementById('ID_ProjectName').addEventListener('change', ScreenDesigner_ProjectFieldChange, false);
		
		// Handler to dismiss popup menus
		window.addEventListener("click", ScreenDesigner_Collection.HideAllPopupMenus);
		
		// Keypress Handler to handle undo/redo
		document.addEventListener("keydown", ScreenDesigner_HandleKeydown);
		
		// Connect controls. "handler" is either "onclick" or "onchange"		
		for (var i = 0; i < editControlList.length; i++)
		{
			var ctl = editControlList[i];
			var id = ctl.id;
			var h = ctl.handler;
			var e = document.getElementById(id);
			if (e != undefined)
			{
				e[h] = ScreenDesigner_HandleControls;
				if (ctl.undo != undefined && ctl.undo == "focus")
					e.addEventListener("blur", ScreenDesigner_UndoHandler_ControlLostFocus)

				// 2021.07.30: Set the increment handlers for all of the dimension controls
				if (ctl.dim != undefined)
				{
					e.addEventListener("keydown", evt => ScreenDesigner_HandleDimensionInputFieldAdjust(evt, "keydown"));
					e.addEventListener("mousewheel", evt => ScreenDesigner_HandleDimensionInputFieldAdjust(evt, "mousewheel"));
				}
			}
			else
				console.log("ScreenDesigner_Init: element not found: " + id);
		}


		// Create input fields for range (slider) inputs; 2021.03.25
		for (var i = 0; i < editControlList.length; i++)
		{
			var ctl = editControlList[i];
			var e = document.getElementById(ctl.id);
			if (e != undefined && e.type == "range")
			{
				// <input type="number" id="ID_ImgContrasts-Value" class="ControlInput-small"   min="-255" max="255" value="0">
				let input = document.createElement("input")
				input.pairedElement = e;
				input.pairIsCopy = true;
				e.pairedElement = input;
				input.type = "number";
				input.value = 0;
				input.id = ctl.id + "-Value";
				input.classList.add("ControlInput");
				input.classList.add("ControlInput-small");
				input["oninput"] = ScreenDesigner_HandleControls;
				if (ctl.undo != undefined && ctl.undo == "focus")
					input.addEventListener("blur", ScreenDesigner_UndoHandler_ControlLostFocus);
				e.after(input); // Add to DOM
			}
		}
		
		// Connect buttons to handlers
		for (var i = 0; i < buttonControlList.length; i++)
		{
			var id = buttonControlList[i].id;
			var c = buttonControlList[i].call;
			var e = document.getElementById(id);
			if (e != undefined)
			{
				// Functions can not be put in an array before they are defined
				var fn = undefined;
				if (c == "zoom")
					fn = ScreenDesigner_Zoom_priv;
				else if (c == "save")
					fn = ScreenDesigner_SaveAsBtnHandler;
				else if (c == "modify")
					fn = ScreenDesigner_ModifyDesignBtnHandler;
				else if (c == "new")
					fn = ScreenDesigner_NewDesignBtnHandler;
				else if (c == "book")
					fn = ScreenDesigner_WorkbookBtnHandler;
				else if (c == "project")
					fn = ScreenDesigner_ProjectBtnHandler;
				else if (c == "misc")
					fn = ScreenDesigner_Misc_BtnHandler;
				else if (c == "open")
					fn = ScreenDesigner_Open_BtnHandler;
				else if (c == "export")	// 2021.08.03
					fn = ScreenDesigner_Export_BtnHandler;
				else
					console.log("ScreenDesigner_Init: unknown call request ('" + c + "') for button " + id);
					
				e["onclick"] = fn;
			}
			else
				console.log("ScreenDesigner_Init: element (button) not found: " + id);
		}
		
		// Buttons in the Tile tab, under the "Edit Options.." collapsable div
		var editOptionList = document.getElementsByClassName("EditOptionCheckbox");
		for (var i = 0; i < editOptionList.length; i++)
			editOptionList[i]["onclick"] = ScreenDesigner_EditOptionBtnHandler;
		
		// Toolbar tools
		var toolsList = document.getElementsByClassName("CL_ToolbarItem");
		for (var i = 0; i < toolsList.length; i++)
			toolsList[i].addEventListener("click", ScreenDesigner_HandleToolbarItem);
		
		// Build tooltips programmatically.
		var editOptionLabels = document.getElementsByClassName("EditOptionCheckboxLabel");
		for (var i = 0; i < editOptionLabels.length; i++)
		{
			var shortcutChar = editOptionLabels[i].getAttribute("editShortcutChar");
			if (shortcutChar != undefined)
			{
				var tooltip = document.createElement('span'); 
				tooltip.className = "EditOptionCheckboxTip color-tooltip";
				// 2022.02.16: Remove "Shortcut:" prefix so I can increase the text size
				//tooltip.innerHTML = "Shortcut: '" + shortcutChar + "'";
				tooltip.innerHTML = shortcutChar;
				editOptionLabels[i].appendChild(tooltip);	
			}
		}

		// 2021.04.03: Drag
		let draggable = document.getElementById("ID_DragJSON");
		if (draggable != undefined)
		{
			draggable.addEventListener("dragstart", evt => ScreenDesigner_DragHandler(evt, "dragstart"));
			draggable.addEventListener("dragend",   evt => ScreenDesigner_DragHandler(evt, "dragend"));
		}
		else
			console.log("ScreenDesigner_Init: missing ID_DragJSON");

		// 2022.04.14: Wait until gallery tab is selected before starting render of designs
		let galleryTab = document.getElementById("ID_GalleryAreaR");
		if (galleryTab != undefined)
			galleryTab.addEventListener("click", ScreenDesigner_Gallery.Revealed);
	}
	
	
	//---------------------------------------------------------------------------
	//	Create Color Gradient UI
	//
	//
	// 			<input type="checkbox" id="ID_GradientEnable"><label for="ID_GradientEnable" class="TabAreaCheckbox">Fill Space with Gradient</label><br>
	// 			<span class="ControlLabel">Color:</span><colorpicker id="ID_GradientColorA"></colorpicker><br>
	// 			<!-- start x, start y -->
	// 			<!-- repeat before start: yes/no -->
	// 			<span class="ControlLabel">Color:</span><colorpicker id="ID_GradientColorB"></colorpicker><br>
	// 			<!-- end x, end y -->
	// 			<!-- repeat after end: yes/no -->
	// 			<span class="ControlLabel">Style:</span>
	// 			<select id="ID_GradientStyle" style="width:130px" class="ControlInput">
	// 			  <option value="topToBottom">Top to bottom</option>
	// 			  <option value="leftToRight">Left to right</option>
	// 			  <option value="circular">Circular</option>
	// 			</select><br>
	// 			<span class="ControlLabel">Ramp:</span>
	// 			<select id="ID_GradientRamp" style="width:130px" class="ControlInput">
	// 			  <option value="linear">Linear</option>
	// 			  <option value="sine">Sine</option>
	// 			</select><br>
	//---------------------------------------------------------------------------
	var ScreenDesigner_CreateColorGradientUI = function()
	{
		//checkbox:click:checked, select:change:value, number:input:value
		let cgDiv = document.getElementById("ID_ColorGradientDiv");
		let gradientRef = {idx: 0};
		
		var checkbox = document.createElement("input");
		checkbox.setAttribute("type", "checkbox");
		checkbox.classList.add("gradientProperty-enable");
		checkbox.addEventListener("click", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "enable", "checked", "always")); 
		var label = document.createElement("label");
		label.classList.add("TabAreaCheckbox");
		var text = document.createTextNode("Fill Space with Gradient");
		label.appendChild(checkbox);
		label.appendChild(text);
		cgDiv.appendChild(label);
		
		cgDiv.appendChild(document.createElement("br"));
		
		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Color:";
		cgDiv.appendChild(span);

		var cp = document.createElement("colorpicker");
		cp.classList.add("gradientProperty-colorA");
		cgDiv.appendChild(cp);
		ColorPicker.Connect(cp);
		cp.addEventListener("change", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "colorA", "value", "focus"));
		
		cgDiv.appendChild(document.createElement("br"));

		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Color:";
		cgDiv.appendChild(span);

		var cp = document.createElement("colorpicker");
		cp.classList.add("gradientProperty-colorB");
		cgDiv.appendChild(cp);
		ColorPicker.Connect(cp);
		cp.addEventListener("change", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "colorB", "value", "focus"));
		
		cgDiv.appendChild(document.createElement("br"));

		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Origin:";
		cgDiv.appendChild(span);
		
		var input = document.createElement("input");
		input.setAttribute("type", "number");
		input.classList.add("ControlInput");
		input.setAttribute("min", "-9999"); // Help the browser limit the width of the field
		input.setAttribute("max", "9999");
		input.classList.add("gradientProperty-referenceX");
		input.addEventListener("input", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "referenceX", "value", "focus")); 
		cgDiv.appendChild(input);

		
		var input = document.createElement("input");
		input.setAttribute("type", "number");
		input.setAttribute("min", "-9999"); // Help the browser limit the width of the field
		input.setAttribute("max", "9999");
		input.classList.add("ControlInput");
		input.classList.add("gradientProperty-referenceY");
		input.addEventListener("input", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "referenceY", "value", "focus")); 
		cgDiv.appendChild(input);

		var select =  document.createElement("select");
		select.classList.add("ControlLabel");
		select.addEventListener("change", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "setOrigin", "value"));
		var option = document.createElement("option");
		option.innerHTML = "Set to ...";
		option.value = "reset";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "center";
		option.value = "center";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "top left";
		option.value = "topLeft";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "bottom left";
		option.value = "bottomLeft";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "top right";
		option.value = "topRight";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "bottom right";
		option.value = "bottomRight";
		select.appendChild(option);
		cgDiv.appendChild(select);

		cgDiv.appendChild(document.createElement("br"));
		
		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Style:";
		cgDiv.appendChild(span);

		var select =  document.createElement("select");
		select.classList.add("ControlLabel");
		select.classList.add("gradientProperty-style");
		select.addEventListener("change", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "style", "value", "always"));
		var option = document.createElement("option");
		option.innerHTML = "Linear";
		option.value = Gradient.Style.LINEAR;
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "Circular";
		option.value = Gradient.Style.CIRCULAR;
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "Radial";
		option.value = Gradient.Style.RADIAL;
		select.appendChild(option);
		cgDiv.appendChild(select);
		
		cgDiv.appendChild(document.createElement("br"));
		
		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Ramp:";
		cgDiv.appendChild(span);

		var select =  document.createElement("select");
		select.classList.add("ControlLabel");
		select.classList.add("gradientProperty-ramp");
		select.addEventListener("change", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "ramp", "value", "always"));
		var option = document.createElement("option");
		option.innerHTML = "Linear";
		option.value = Gradient.Ramp.LINEAR;
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "Sine";
		option.value = Gradient.Ramp.SINE;
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "Cubic";
		option.value = Gradient.Ramp.CUBIC;
		select.appendChild(option);
		cgDiv.appendChild(select);
		
		cgDiv.appendChild(document.createElement("br"));
		
		var checkbox = document.createElement("input");
		checkbox.setAttribute("type", "checkbox");
		checkbox.classList.add("gradientProperty-cycleBefore");
		checkbox.addEventListener("click", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "cycleBefore", "checked", "always"));
		var label = document.createElement("label");
		label.classList.add("TabAreaCheckbox");
		var text = document.createTextNode("Gradient extends before start");
		label.appendChild(checkbox);
		label.appendChild(text);
		cgDiv.appendChild(label);
		
		cgDiv.appendChild(document.createElement("br"));
		
		var checkbox = document.createElement("input");
		checkbox.setAttribute("type", "checkbox");
		checkbox.classList.add("gradientProperty-cycleAfter");
		checkbox.addEventListener("click", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "cycleAfter", "checked", "always")); 
		var label = document.createElement("label");
		label.classList.add("TabAreaCheckbox");
		var text = document.createTextNode("Gradient extends after end");
		label.appendChild(checkbox);
		label.appendChild(text);
		cgDiv.appendChild(label);
		
		cgDiv.appendChild(document.createElement("br"));
		
		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Size:";
		cgDiv.appendChild(span);
		
		var input = document.createElement("input");
		input.setAttribute("type", "number");
		input.setAttribute("min", "0");
		input.classList.add("ControlInput");
		input.classList.add("gradientProperty-size");
		input.addEventListener("input", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "size", "value", "focus")); 
		cgDiv.appendChild(input);

		var select =  document.createElement("select");
		select.classList.add("ControlLabel");
		select.addEventListener("change", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "setSize", "value"));
		var option = document.createElement("option");
		option.innerHTML = "Set to ...";
		option.value = "reset";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "width";
		option.value = "width";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "height";
		option.value = "height";
		select.appendChild(option);
		var option = document.createElement("option");
		option.innerHTML = "radius";
		option.value = "radius";
		select.appendChild(option);
		cgDiv.appendChild(select);

		cgDiv.appendChild(document.createElement("br"));
		
		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Angle:";
		cgDiv.appendChild(span);
		
		var input = document.createElement("input");
		input.setAttribute("type", "number");
		input.setAttribute("min", "0");
		input.classList.add("ControlInput");
		input.classList.add("gradientProperty-angle");
		input.addEventListener("input", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "angle", "value", "focus")); 
		cgDiv.appendChild(input);

		cgDiv.appendChild(document.createElement("br"));
		
		var span = document.createElement("span");
		span.classList.add("ControlLabel");
		span.innerHTML = "Count:";
		cgDiv.appendChild(span);
		
		var input = document.createElement("input");
		input.setAttribute("type", "number");
		input.setAttribute("min", "0");
		input.classList.add("ControlInput");
		input.classList.add("gradientProperty-count");
		input.addEventListener("input", evt => ScreenDesigner_HandleGradientUI(evt, gradientRef, "count", "value", "focus")); 
		cgDiv.appendChild(input);
	}

	//---------------------------------------------------------------------------
	//	Populate Gradient UI
	//---------------------------------------------------------------------------
	var ScreenDesigner_PopulateGradientUI = function(gradientDivRef, gradient)
	{
		let propList = Gradient.Properties;
		let gDiv;
		
		if (gradientDivRef.div != undefined)
			gDiv = gradientDivRef.div;
		else if (gradientDivRef.id != undefined)
			gDiv = document.getElementById(gradientDivRef.id);
			
		
		for (var i = 0; i < propList.length; i++)
		{
			let gProp = propList[i].prop;
			let gType = propList[i].type;
			
			let e = gDiv.querySelector(".gradientProperty-" + gProp);
			
			if (e != undefined)
			{
				let value = gradient[gProp];
				
				if (gType == 'number')
					e.value = value;
				else if (gType == 'boolean')
					e.checked = value;
				else if (gType == 'color')
					e.setAttribute("value", value);
				else if (gType == 'enum')
					e.value = value;
				else
					console.log("ScreenDesigner_PopulateGradientUI: unknown gradient property type: " + gType);
			}
			else
			{
				// Some properties might not be exposed in the UI
			}
		}
		
	}

	//---------------------------------------------------------------------------
	//	Handle Gradient UI
	//		
	//---------------------------------------------------------------------------
	var ScreenDesigner_HandleGradientUI = function(evt, gradientReference, gradientProperty, accessProperty, undoBehavior)
	{
		let updated = false;
		let value = evt.target[accessProperty];

		// Find the parent div for this set of gradient controls		
		function getGradientContainerDiv(e) 
		{
			while (e != null && !e.classList.contains("gradientDiv"))
				e = e.parentElement;
				
			if (e == null)
				console.log("ScreenDesigner_HandleGradientUI: parent gradient div not found");

			return e;
		}
		
		// Menu for setting the size from one of the frame values
		if (gradientProperty == "setSize")
		{
			let size = undefined;

			// Reset the menu back to "set..."
			evt.target.value = "reset";

			if (value == "radius")
				size = appData.frame.radius;
			else if (value == "width")
				size = appData.frame.width;
			else if (value == "height")
				size = appData.frame.height;

			if (size != undefined)
			{
				ScreenDesigner_PerformSnapshot();
				appData.gradients[0].size = size;
				updated = true;

				let gDiv = getGradientContainerDiv(evt.target);
				if (gDiv != undefined)
				{
					let e = gDiv.querySelector(".gradientProperty-size");
					e.value = size;
				}
			}
		}
		// Menu for setting the origin based on the frame
		else if (gradientProperty == "setOrigin")
		{
			let origin = undefined;
			// This needs to handle the radius for those frames that use it.
			let size = ScreenGenerator.GetSize(appData);
			
			let x = (size.width  != undefined) ? size.width/2  : size.radius;
			let y = (size.height != undefined) ? size.height/2 : size.radius;
			
			// Reset the menu back to "set..."
			evt.target.value = "reset";

			if (value == "center")
				origin = {x:0, y:0};
			else if (value == "topLeft")
				origin = {x:-x, y:y};
			else if (value == "topRight")
				origin = {x:x, y:y};
			else if (value == "bottomLeft")
				origin = {x:-x, y:-y};
			else if (value == "bottomRight")
				origin = {x:x, y:-y};

			if (origin != undefined)
			{
				ScreenDesigner_PerformSnapshot();
				appData.gradients[0].referenceX = origin.x;
				appData.gradients[0].referenceY = origin.y;
				updated = true;

				let gDiv = getGradientContainerDiv(evt.target);
				if (gDiv != undefined)
				{
					let e = gDiv.querySelector(".gradientProperty-referenceX");
					e.value = origin.x;
					e = gDiv.querySelector(".gradientProperty-referenceY");
					e.value = origin.y;
				}
			}
		}
		// Control for a specific gradient parameter was updated
		else
		{
			// 2021.07.06: Use _ConsiderSnapshot instead of _PerformSnapshot since
			// we are called repeatedly when tracking sliders and colors. Note that
			// the undoBehavior is specified in the _CreateColorGradientUI function
			let pseudoId = "gradientProp_" + gradientProperty;
			let undoControlInfo = {undo:undoBehavior, id:pseudoId}; 
			ScreenDesigner_UndoHandler_ConsiderSnapshot(appData, undoControlInfo);
			
			// Update the parameter
			appData.gradients[0][gradientProperty] = value;
			updated = true;
		}
		
		if (updated)
		{
			ScreenDesigner_DesignMarkDirty(appData);
			ScreenGenerator_Design_DataModified(5);
			ScreenDesignerCanvas.Render();
		}
	}
	
	//---------------------------------------------------------------------------
	//	Highlight Restricted Features
	//---------------------------------------------------------------------------
	var ScreenDesigner_HighlightRestrictedFeatures = function()
	{
		for (var i = 0; i < buttonControlList.length; i++)
		{
			var b = buttonControlList[i];
			
			if (b.restricted != undefined)
			{
				var e = document.getElementById(b.id);
				if (e != undefined)
				{
					if (ScreenDesignerAccount.CanPerform(b.restricted))
						e.classList.remove("CL_BlockedFeature");
					else
						e.classList.add("CL_BlockedFeature");
				}
			}
		}
	}
	
	//---------------------------------------------------------------------------
	//	Update Downloads Remaining
	//---------------------------------------------------------------------------
	var ScreenDesigner_UpdateDownloadsRemaining = function(downloadsRemaining)
	{
		if (downloadsRemaining != undefined)
		{
			var msg;
			
			if (downloadsRemaining == -1)
				msg = "";
			else if (downloadsRemaining < -1)
				msg = "--"; // 2021.07.07: There is a condition where the downloads decrement when they shouldn't
			else if (downloadsRemaining == 0)
				msg = "No downloads remaining";
			else if (downloadsRemaining == 1)
				msg = "1 download remaining";
			else
				msg = downloadsRemaining + " downloads remaining"
				
			var e = document.getElementById("ID_DownloadsRemaining");
			e.innerHTML = msg;
		}
		else
		{
			console.log("ScreenDesigner_UpdateDownloadsRemaining: downloadsRemaining is undefined");
		}
	}
	
	//---------------------------------------------------------------------------
	// Introduction display
	//---------------------------------------------------------------------------
	var ScreenDesigner_OptionallyDisplayIntro = function()
	{
		// If there is no properties for help, then show the help/intro message
		//
		var storedHelpVersion = 0;
		
		try {
			var persistentHelpStr = localStorage.getItem(LocalStorage_PersistentSettings_Help);
			var persistentHelp = undefined;
			
			if (persistentHelpStr != undefined)
				persistentHelp = JSON.parse(persistentHelpStr);
				
			if (persistentHelp != undefined && persistentHelp.helpVersion != undefined)
				storedHelpVersion = persistentHelp.helpVersion;
		}
		catch (error)
		{
			// If the localStorage call failed, then the default is to skip the help display
			storedHelpVersion = appHelpVersion;
		}
		
		if (storedHelpVersion < appHelpVersion) 
		{
			// Write the latest help version
			try {
				localStorage.setItem(LocalStorage_PersistentSettings_Help, JSON.stringify({helpVersion:appHelpVersion}));
			}
			catch (error) { /* Do nothing */ }
			
			ScreenDesigner_HelpMgr.Show();
		}
	}
		
	//---------------------------------------------------------------------------
	// Update Floating Panels
	//		Show the system (design, workbook, project, etc) status
	//---------------------------------------------------------------------------
	/*
				<div id="ID_StatusDiv" class="CL_FloatingPanel">
					<span id="ID_StatusInfo" class="noselect CL_OrganizerText CL_FloatingPanelInfo">Status...</span>
				</div>
				<div id="ID_SummaryDiv" class="CL_FloatingPanel">
					<span id="ID_SummaryInfo" class="noselect CL_OrganizerText CL_FloatingPanelInfo">Info...</span>
				</div>
	*/
	
	var floatingPanelList = [
		{	content: "status",		type:"string",	levelCount: 3,	level: 1,	position: "BottomLeft"	},
		{	content: "summary",		type:"string",	levelCount: 2,	level: 1,	position: "BottomRight"	},
		{	content: "miniview",	type:"other",	levelCount: 2,	level: 0,	position: "TopRight"	},
		{	content: "histogram",	type:"other",	levelCount: 2,	level: 0,	position: "TopLeft"		}
	];
	
	//---------------------------------------------------------------------------
	// Init Floating Panels
	//		Build the floating panels that sit in the render canvas. The panels
	//		created are specified with the 'floatingPanelList' list.
	//
	//			<div class="CL_FloatingPanel">
	//				<span class="noselect CL_OrganizerText CL_FloatingPanelInfo">Status...</span>
	//			</div>
	//---------------------------------------------------------------------------
	var ScreenDesigner_InitFloatingPanels = function()
	{
		try {
			var holder = document.getElementById("ID_EditCanvasDiv"); // ID_FloatingPanels
		
			for (var i = 0; i < floatingPanelList.length; i++)
			{
				var panel = floatingPanelList[i];
			
				var floatingPanelDiv = document.createElement("div");
				floatingPanelDiv.classList.add("CL_FloatingPanel");
				floatingPanelDiv.classList.add("CL_FloatingPanel" + panel.position);
				floatingPanelDiv.addEventListener("click", ScreenDesigner_FloatingPanel_AdvanceLevel);
				// Add a copy of the panel info to the div for convenient reference and so
				// we can modify it as needed
				floatingPanelDiv.polygoniaPanel = JSON.parse(JSON.stringify(panel));

				var info = document.createElement("span");
				info.classList.add("noselect");
				info.classList.add("CL_OrganizerText");
				info.classList.add("CL_FloatingPanelInfo");
				info.innerHTML = panel.content;
				floatingPanelDiv.appendChild(info);
			
				holder.appendChild(floatingPanelDiv);
			}
		}
		catch (err) {
			console.log("ScreenDesigner_InitFloatingPanels: error");
			console.log(err);
		}
	}
	
	//---------------------------------------------------------------------------
	// Floating Panel: Advance
	//		Advance the 'display level' of a floating panel. Called by the 
	//		event handler in response to 'click'
	//---------------------------------------------------------------------------
	var ScreenDesigner_FloatingPanel_AdvanceLevel = function(evt)
	{
		try {
			var panelDiv = evt.currentTarget;
			var panelInfo = panelDiv.polygoniaPanel;
		
			if (panelInfo != undefined)
			{
				panelInfo.level = (panelInfo.level + 1) % panelInfo.levelCount;
				ScreenDesigner_FloatingPanel_Update(panelDiv);
			}
			else
			{
				console.log("ScreenDesigner_FloatingPanel_AdvanceLevel: polygoniaPanel undefined");
			}
		}
		catch (err) {
			console.log("ScreenDesigner_FloatingPanel_AdvanceLevel: error");
			console.log(err);
		}
		
	}
	
	//---------------------------------------------------------------------------
	// Floating Panel: Update
	//		Update the info in a floating panel. Dispatches to the appropriate
	//		'build string' routine depending on the panel content identifier (string)
	//---------------------------------------------------------------------------
	var ScreenDesigner_FloatingPanel_Update = function(floatingPanelDiv)
	{
		try {
			var panelInfo = floatingPanelDiv.polygoniaPanel;
		
			if (panelInfo != undefined)
			{
				if (panelInfo.type == "string")
				{
					var panelStatusStr = "";
					var panelStatusDiv = floatingPanelDiv.querySelector(".CL_FloatingPanelInfo");
				
					if (panelInfo.content == "status")
						panelStatusStr = ScreenDesigner_FloatingPanel_GetStatusStr(panelInfo.level);
					else if (panelInfo.content == "summary")
						panelStatusStr = ScreenDesigner_FloatingPanel_GetSummaryStr(panelInfo.level);
					
					panelStatusDiv.innerHTML = panelStatusStr;
				}
				else
				{
					if (panelInfo.content == "miniview" || panelInfo.content == "histogram" )
						ScreenDesigner_FloatingPanel_UpdateMiniviewer(floatingPanelDiv, panelInfo);
				}
			}
			else
			{
				console.log("ScreenDesigner_FloatingPanel_Update: polygoniaPanel undefined");
			}
		}
		catch (err) {
			console.log("ScreenDesigner_FloatingPanel_Update: error");
			console.log(err);
		}
	}
	
	//---------------------------------------------------------------------------
	// Floating Panel: Update All
	//		Update all floating panels
	//---------------------------------------------------------------------------
	var ScreenDesigner_FloatingPanel_UpdateAll = function()
	{
		try {
			var panels = document.querySelectorAll(".CL_FloatingPanel");
		
			for (var i = 0; i < panels.length; i++)
			{
				// 2021.03.25: Only update "string" panels
				if (panels[i].polygoniaPanel == undefined || panels[i].polygoniaPanel.type == "string")
					ScreenDesigner_FloatingPanel_Update(panels[i]);
			}
		}
		catch (err) {
			console.log("ScreenDesigner_FloatingPanel_UpdateAll: error");
			console.log(err);
		}
	}

	//---------------------------------------------------------------------------
	// Update Info Displays
	//		A more readable name than "ScreenDesigner_FloatingPanel_UpdateAll"
	//---------------------------------------------------------------------------
	var ScreenDesigner_UpdateInfoDisplays = function()
	{
		ScreenDesigner_FloatingPanel_UpdateAll();
	}
	
	//---------------------------------------------------------------------------
	// Floating Panel: GetStatusStr
	//		Get the system (design, workbook, project, etc) status
	//---------------------------------------------------------------------------
	var ScreenDesigner_FloatingPanel_GetStatusStr = function(level)
	{
		var statusStr = "";
		
		if (level == 0)
		{
			statusStr = "<em>Status...</em>"
			if (ScreenDesigner_DesignIsDirty(appData) || ScreenDesigner_Workbook.IsDirty())
				statusStr += "<span style='color:red;'>EDITED</span><br>";
		}
		else if (level == 1)
		{
			statusStr = "<em>Status: </em>";

			// Design status
			if (ScreenDesigner_Workbook.IsDesignInAppWorkbook(appData))
			{
				statusStr += "WORKBOOK design";
			}
			if (ScreenDesigner_StorageMgr.IsDesignInProject(appData))
			{
				statusStr += "PROJECT design";
				if (ScreenDesigner_DesignIsDirty(appData))
					statusStr += "<span style='color:red;'> EDITED</span><br>";
			}
			if (!ScreenDesigner_Workbook.IsDesignInAppWorkbook(appData) && !ScreenDesigner_StorageMgr.IsDesignInProject(appData))
			{
				statusStr += "STANDALONE design";
				if (ScreenDesigner_DesignIsDirty(appData))
					statusStr += ", <span style='color:red;'>EDITED</span>";
			}

			if (ScreenDesigner_Workbook.IsDirty())
				statusStr += "; Workbook <span style='color:red;'>EDITED</span>";
		}
		else if (level == 2)
		{
			var ds = "";
			var ws = "";
			var ps = "";
			var ss = "";

			// Design status
			ds += "<span class='underline'>Design:</span><br>";
			if (ScreenDesigner_DesignIsDirty(appData))
				ds += "<span style='color:red;'>EDITED</span><br>";
			if (ScreenDesigner_Workbook.IsDesignInAppWorkbook(appData))
				ds += "WORKBOOK design" + "<br>";
			if (ScreenDesigner_StorageMgr.IsDesignInProject(appData))
				ds += "PROJECT design" + "<br>";
			if (!ScreenDesigner_Workbook.IsDesignInAppWorkbook(appData) && !ScreenDesigner_StorageMgr.IsDesignInProject(appData))
				ds += "STANDALONE design" + "<br>";
			ds += "<br>";
		
			// Workbook status		
			ws += "<span class='underline'>Workbook:</span><br>";
			if (ScreenDesigner_Workbook.IsDirty())
				ws += "<span style='color:red;'>EDITED</span><br>";
			ws += "Designs: " + ScreenDesigner_Workbook.GetDesignCount() + "<br>";
			ws += "<br>";
		
			// Project status
			ps += "<span class='underline'>Project:</span><br>";
			ps += "Designs: " + ScreenDesigner_StorageMgr.GetProjectDesignCount() + "<br>";
			ps += "<br>";

			// System status
			ss += "<span class='underline'>Storage:</span><br>";
			ss += "Projects: " + ScreenDesigner_StorageMgr.GetProjectCount() + "<br>";
		
			statusStr = "<em>Status</em><br><br>" + ds + ws + ps + ss;
		}
		
		return statusStr;
	}

	//---------------------------------------------------------------------------
	// Floating Panel: GetSummaryStr
	//		Get the design summary
	//---------------------------------------------------------------------------
	var ScreenDesigner_FloatingPanel_GetSummaryStr = function(level)
	{
		var summaryStr = "";
		
		if (level == 0)
		{
			summaryStr = "...";
		}
		else if (level == 1)
		{
			summaryStr = "<em>Size: </em>";
			summaryStr += ScreenDesigner_GetDesignDimensionsStr();
		}
		
		return summaryStr;
	}

	//---------------------------------------------------------------------------
	// Floating Panel: Update Miniviewer
	//		2021.03.22: Added
	//		2021.03.30: Added histogram support
	//---------------------------------------------------------------------------
	var ScreenDesigner_FloatingPanel_UpdateMiniviewer = function(miniviewerDiv, panelInfo)
	{
		let level = panelInfo.level;
		let content = panelInfo.content;
		let id = "";
		
		if (content == "miniview")
			id = "ID_Miniviewer";
		else if (content == "histogram")
			id = "ID_Histogram";
		
		var panelStatusDiv = miniviewerDiv.querySelector(".CL_FloatingPanelInfo");
		let e = document.getElementById(id);
		if (level == 0)
		{
			panelStatusDiv.innerHTML = "...";
			
			if (e != undefined)
				e.remove();
		}
		else
		{
			// Clear the text in the div, otherwise it will show above the canvas
			panelStatusDiv.innerHTML = "";
			if (e == undefined)
			{
				var minicanvas = document.createElement("canvas");
				minicanvas.id = id;
				minicanvas.classList.add("CL_Miniviewer");
				panelStatusDiv.appendChild(minicanvas);
			
				minicanvas.width = minicanvas.clientWidth;
				minicanvas.height = minicanvas.clientHeight;
			
				if (content == "miniview")
					ScreenDesignerCanvas.RenderMini();
				else if (content == "histogram")
					ScreenDesignerCanvas.RenderHistogram();
			}
		}
	}

	//---------------------------------------------------------------------------
	// 	Misc button control
	//		Toggle the project/workbook organizer
	//		Show help
	//		Slideshow (currently disabled)
	//---------------------------------------------------------------------------
	var ScreenDesigner_Misc_BtnHandler = function(evt)
	{
		var buttonName = this.id;
		
		if (buttonName == "ID_OrgExpanderButton")
		{
			ScreenDesigner_ToggleOrganizer();
		}
		else if (buttonName == "ID_ShowHelp")
		{
			ScreenDesigner_HelpMgr.Show();
		}
		else if (buttonName == "ID_ResizeEditor")
		{
			ScreenDesigner_SwapEditorAndRender();
		}
		else if (buttonName == "ID_SwapFgBgColorsBtn") // 2021.03.13
		{
			ScreenDesigner_SwapFgBgColors();
		}
		else if (buttonName == "ID_ResetImgAdj") // 2021.03.25
		{
			ScreenDesigner_ResetImageAdjustments("adjustments");
		}
		else if (buttonName == "ID_ResetImgZoom") // 2021.03.30
		{
			ScreenDesigner_ResetImageAdjustments("zoom");
		}
		else if (buttonName == "ID_GalleryLabel")
		{
			/* 2018.06.06: Don't support the slideshow
			if (appSlideshow != undefined)
				ScreenDesigner_CancelSlideshow();
			else if (appGallery != undefined)
				ScreenDesigner_StartSlideshow(appGallery);
			else
				console.log("No gallery for slideshow.");
			*/
		}
		else if (buttonName == "ID_CopyJSON") // 2021.04.05
		{
			ScreenDesigner_Clipboard_CopyJSON();
		}
	}
	
	//---------------------------------------------------------------------------
	// 	Swap Foreground and Background Colors
	//		2021.03.13: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_SwapFgBgColors = function()
	{
		let fg = appData.general.fillColor;
		let bg = appData.general.backColor;
		
		
		ScreenDesigner_PerformSnapshot();
		
		appData.general.fillColor = bg;
		appData.general.backColor = fg;
		appData.general.renderFill = true;
		appData.general.renderBack = true;

		ScreenDesigner_DesignMarkDirty(appData); // 2021.03.17
		ScreenDesignerCanvas.Render();
		ScreenGenerator_Design_DataModified(5);
		ScreenDesigner_PopulateControlValues(appData);
	}
	
	//---------------------------------------------------------------------------
	// 	Open File button control
	//		Wrapper function to control access to file upload
	//---------------------------------------------------------------------------
	var ScreenDesigner_Open_BtnHandler = function(evt)
	{
		var buttonName = this.id;
		
		if (buttonName == "ID_OpenDesign")
		{
			if (ScreenDesignerAccount.CanPerformOrShowBlockedUI("uploadDesign"))
				document.getElementById("ID_LoadFile").click();
		}
		if (buttonName == "ID_OpenWorkbook")
		{
			if (ScreenDesignerAccount.CanPerformOrShowBlockedUI("saveWorkbook"))
				document.getElementById("ID_LoadBook").click();
		}
		if (buttonName == "ID_ImportWorkbook")
		{
			if (ScreenDesignerAccount.CanPerformOrShowBlockedUI("saveWorkbook"))
				document.getElementById("ID_ImportBook").click();
		}
		if (buttonName == "ID_LoadImageBtn")
		{
			//if (ScreenDesignerAccount.CanPerformOrShowBlockedUI("saveWorkbook"))
				document.getElementById("ID_LoadImageFile").click();
		}
		if (buttonName == "ID_RemoveImageBtn")
		{
			ScreenDesigner_RemoveImage();
		}
	}

	//---------------------------------------------------------------------------
	// 	Export button control
	//		2021.08.03: Created
	//---------------------------------------------------------------------------
	var ScreenDesigner_Export_BtnHandler = async function(evt)
	{
		var buttonName = this.id;
		
		if (buttonName == "ID_DesignExport_Zazzle")
		{
			ScreenDesigner_DesignExport.PrepareExport({partner:"Zazzle"});
		}
		else
		{
			console.log("ScreenDesigner_Export_BtnHandler: button not handled: " + buttonName);
		}
	}
		
	var ScreenDesigner_ToggleOrganizer = function()
	{
		//var showWorkbook = (appWorkbook != undefined);
		
		var btn = document.getElementById('ID_OrgExpanderButtonImg');
		var e = document.getElementById('ID_OrganizerHider');
		if (e != undefined)
		{
			var hidden = e.classList.toggle('CL_HideElement');
			
			btn.src = hidden ? "rsrcs/OrganizerBtnImage_Closed.png" : "rsrcs/OrganizerBtnImage_Open.png";
			
			// 2018.01.04: Call resize to correct aspect ratio of canvas
			ScreenDesigner_ResizeWindowHandler();
		}
		else
			console.log("ScreenDesigner_ToggleOrganizer: missing ID_OrganizerHider");
			
		// This is not necessary here, but now I can use show/hide organizer 
		// to force a refresh of the status
		ScreenDesigner_UpdateInfoDisplays();
	}

	//---------------------------------------------------------------------------
	//	Load and Import button handlers
	//---------------------------------------------------------------------------
	var ScreenDesigner_LoadFile = function(evt)
	{
		var continueWithLoad = ScreenDesigner_PromptForPossibleUnsavedDesignChanges();
		
		if (continueWithLoad)
		{
			ScreenDesignerFileIO.LoadFile(evt, "designLoad");
			SystemAnalytics.Record("DesignImport");
		}
	}

	var ScreenDesigner_LoadWorkbook = function(evt)
	{
		var continueWithLoad = true;
		
		if (ScreenDesigner_Workbook.IsDirty())
			continueWithLoad = confirm("Unsaved changes to workbook will be lost.");
		
		if (continueWithLoad)
		{
			ScreenDesignerFileIO.LoadFile(evt, "workbookLoad");
			SystemAnalytics.Record("WorkbookImport");
		}		
	}

	var ScreenDesigner_ImportWorkbook = function(evt)
	{
		ScreenDesignerFileIO.LoadFile(evt, "workbookImport");
		SystemAnalytics.Record("WorkbookImport");
	}

	var ScreenDesigner_LoadGallery = function(evt)
	{
		ScreenDesignerFileIO.LoadFile(evt, "galleryLoad");
		SystemAnalytics.Record("GalleryImport");
	}
	
	var ScreenDesigner_LoadImage = function(evt)
	{
		ScreenImageCanvas.LoadImageFile(evt);
	}
	
	//---------------------------------------------------------------------------
	//	Remove Image
	//		2021.03.12: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_RemoveImage = function()
	{
		if (appData.designType == DesignType.TYPE_IMAGE)
		{
			appData.imageData = undefined;
			appData.imageDataURL = undefined;
			ScreenImageCanvas.ClearImageData();
			ScreenDesigner_DesignMarkDirty(appData);
			ScreenGenerator_Design_DataModified(2);
		}
		else
		{
			console.log("ScreenDesigner_RemoveImage: design is not TYPE_IMAGE");
		}
	}
	
	//---------------------------------------------------------------------------
	//	Create New Design
	//
	//		action: "DefaultDesign", "SimilarDesign", "CopyDesign"
	//		location: "Project", "standalone", "Workbook"
	//
	//---------------------------------------------------------------------------
	var ScreenDesigner_CreateNewDesign = function(action, location, designType = DesignType.TYPE_REGULAR_TILING)
	{
		var newDesignData = undefined;
		var msgStr = undefined;

		if (action == "DefaultDesign")
		{
			newDesignData = ScreenDesigner_NewScreenDesignerData(designType);
			msgStr = "New design";
		}
		else if (action == "SimilarDesign")
		{
			// Clone the current design so that we retain all of the settings...
			newDesignData = appData.Clone();
			// ...but then clear the lines
			newDesignData.ClearLines();
			// Mark it is "unchanged"
			ScreenDesigner_DesignClearDirty(newDesignData); // 2018.07.18: Use routine instead of setting directly
			
			msgStr = "Similar design";
		}
		else if (action == "CopyDesign")
		{
			var newDesignData = appData.Clone();
			msgStr = "Design copy";
		}


		if (newDesignData != undefined)
		{
			// 2018.07.20: Use primary function to set new data instead of making multiple calls
			ScreenDesigner_SetCurrentDesignData(newDesignData); 

			// 2018.07.24: Add to workbook
			// 2018.12.11: Add to project; replace id test with location test
			if (location == "Workbook")
			{
				ScreenDesigner_Workbook.AddDesign(newDesignData, true);
				msgStr += " added to Workbook";
			}
			else if (location == "Project")
			{	
				ScreenDesigner_StorageMgr.AddDesign(newDesignData, true);
				msgStr += " added to Project";
			}
		}
		
		ScreenDesigner_UpdateInfoDisplays();
		ScreenDesigner_HighlightActiveDesign();
		
		if (msgStr != undefined)
			ScreenDesigner.DisplayMessageBanner(msgStr);
	}

	//---------------------------------------------------------------------------
	//	Select New Design Type
	//---------------------------------------------------------------------------
	var ScreenDesigner_SelectNewDesignType = function()
	{
		var designType = DesignType.TYPE_REGULAR_TILING;
		
		
		return designType;
	}
	
	//---------------------------------------------------------------------------
	//	Project Button Handler
	//---------------------------------------------------------------------------
	var ScreenDesigner_ProjectBtnHandler = function(evt) 
	{
		if (this.id == "ID_NewProjectDesign" || this.id == "ID_NewProjectDesign2")
		{
			if (ScreenDesigner_PromptForPossibleUnsavedDesignChanges())
			{
				ScreenDesigner_ShowSelectNewDesignType(true);
				//ScreenDesigner_CreateNewDesign("DefaultDesign", "Project");
			}
		}
		else if (this.id == "ID_NewDesign_Regular")
		{
			ScreenDesigner_ShowSelectNewDesignType(false);
			var designLocation = (evt.altKey) ? "Standalone" : "Project";
			ScreenDesigner_CreateNewDesign("DefaultDesign", designLocation, DesignType.TYPE_REGULAR_TILING);
		}
		else if (this.id == "ID_NewDesign_Image")
		{
			ScreenDesigner_ShowSelectNewDesignType(false);
			var designLocation = (evt.altKey) ? "Standalone" : "Project";
			ScreenDesigner_CreateNewDesign("DefaultDesign", designLocation, DesignType.TYPE_IMAGE);
		}
		else if (this.id == "ID_NewDesign_Sketch")
		{
			ScreenDesigner_ShowSelectNewDesignType(false);
			var designLocation = (evt.altKey) ? "Standalone" : "Project";
			ScreenDesigner_CreateNewDesign("DefaultDesign", designLocation, DesignType.TYPE_GRID_SKETCH);
		}
		else if (this.id == "ID_AddProjectDesign" || this.id == "ID_AddProjectDesign2")
		{
			// 2019.10.10: Use the common routine to perform 'AddProject'
			// The common routine does not check if a design is in a project or 
			// workbook; it simply clones the current design. That is the net effect
			// of the code below. For a standalone design the code below simply adds the 
			// design; the common code makes a copy (and effectively discards the original),
			// which has the net effect of doing the same.
			ScreenDesigner_CreateNewDesign("CopyDesign", "Project");
			/*
			var designData = undefined;
			
			// If the design is in the workbook, then we need a copy
			if (ScreenDesigner_Workbook.IsDesignInAppWorkbook(appData))
				designData = appData.Clone();
			// If the design is already in the project, then treat this as
			// "add copy to project"
			else if (ScreenDesigner_StorageMgr.IsDesignInProject(appData))
				designData = appData.Clone();
			// Otherwise, add the current design to the project
			else
				designData = appData;
			
			if (designData != undefined)
				ScreenDesigner_StorageMgr.AddDesign(designData);
			*/
		}
		else if (this.id == "ID_NewProject" || this.id == "ID_NewProject2")
		{
			if (ScreenDesignerAccount.CanPerformOrShowBlockedUI("createProject"))
			{
				if (ScreenDesigner_StorageMgr.IsDesignInProject(appData))
					ScreenDesigner_StorageMgr.UploadChanges();
				ScreenDesigner_StorageMgr.NewProject();
			}
		}
		else if (this.id == "ID_OpenProject" || this.id == "ID_OpenProject2")
		{
			if (ScreenDesigner_StorageMgr.IsDesignInProject(appData))
				ScreenDesigner_StorageMgr.UploadChanges();
			ScreenDesigner_StorageMgr.ShowProjectList();
		}
		else if (this.id == "ID_HideProjectList")
		{
			ScreenDesigner_StorageMgr.HideProjectList();
		}
		else if (this.id == "ID_ProjectListBackground")
		{
			if (this.id == "ID_ProjectListBackground" && evt.target.id == "ID_ProjectListBackground")
				ScreenDesigner_StorageMgr.HideProjectList();
		}
		else if (this.id == "ID_DeleteProject")
		{
			var continueWithDelete = confirm("Deleting a project is permanent and can not be undone.");
			
			if (continueWithDelete)
				ScreenDesigner_StorageMgr.DeleteCurrentProject();
		}
		else if (this.id == "ID_CloseProject")
		{
		}
		else if (this.id == "ID_HideSelectDesignType")
		{
			ScreenDesigner_ShowSelectNewDesignType(false);
		}
		else if (this.id == "ID_SelectDesignTypeBackground")
		{
			if (this.id == "ID_SelectDesignTypeBackground" && evt.target.id == "ID_SelectDesignTypeBackground")
				ScreenDesigner_ShowSelectNewDesignType(false);
		}
		else
		{
			console.log("ScreenDesigner_ProjectBtnHandler: " + this.id + " not handled");
		}
		
		ScreenDesigner_UpdateInfoDisplays();
		ScreenDesigner_HighlightActiveDesign();
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_ShowSelectNewDesignType = function(showIt)
	{
		// When hidden, the display style is "none". Change it to "flex" to show it.
		var e = document.getElementById("ID_SelectDesignTypeBackground");
		
		if (showIt)
			e.style.display = "flex";
		else
			e.style.display = "none";
	}
	
	//---------------------------------------------------------------------------
	//	New design
	//		Actions: DefaultDesign SimilarDesign CopyDesign
	//		Locations: Project Workbook Standalone
	//---------------------------------------------------------------------------
	var ScreenDesigner_NewDesignBtnHandler = function(evt) 
	{
		var action = undefined;
		var location = undefined;
		var confirmFirst = true;
		
		// A radio button in the UI indicates where the new design should be located
		var locationCheckedRadio = document.querySelector("input[name=NM_NewDesignLocation]:checked");
		// Code conservatively in case no button is checked;
		if (locationCheckedRadio != undefined)
			location = locationCheckedRadio.value;
		else
			location = "Standalone"; // Should report a message to the console.

		
		// Determine the action and location
		if (this.id == "ID_NewWorkbookDesign")
		{
			action = "DefaultDesign";
			location = "Workbook";
		}
		else if (this.id == "ID_NewDefaultDesign")
		{
			action = "DefaultDesign";
		}
		else if (this.id == "ID_NewSimilarDesign")
		{
			action = "SimilarDesign";
		}
		else if (this.id == "ID_NewDesignCopy")
		{
			action = "CopyDesign";
			// We don't ask about saving the possibly changed design. Either it is part 
			// of a workbookor part of a project. If it is neither, then the net effect
			// is that this does nothing.
			confirmFirst = false;
		}
		
		
		//console.log("New design: action: " + action + ", location: " + location);
		
		if (action == undefined)
		{
			console.log("ScreenDesigner_NewDesignBtnHandler: '" + this.id + "' not handled");
		}
		else
		{
			var continueWithChange = !confirmFirst || ScreenDesigner_PromptForPossibleUnsavedDesignChanges();
			
			if (continueWithChange)
			{
				// 2019.09.12: Moved to a new routine
				ScreenDesigner_CreateNewDesign(action, location);
				
			}
		}
		
	}
	
	
	//---------------------------------------------------------------------------
	//	Update Sketch
	//
	//	2021.08.31: Added snapshot so undo works
	//---------------------------------------------------------------------------
	var ScreenDesigner_UpdateSketch = function(tileList, sketchMode)
	{
		var sketchUpdated = false;
		
		// Set allEdges according to the tiling. We use this value later to determine
		// if a tile is blank when the edges are individually erased. Note that we should
		// be asking the tessellation for the edge count -- this is a hack.
		var allEdges; // Each bit represents an edge
		if (appData.tiling.shape == 0 /* Tiling.SQUARE */)
			allEdges = 0x0f; /* 4 sides */
		else if (appData.tiling.shape == 2 /* Tiling.TRIGRID */)
			allEdges = 0x07; /* 3 sides */
		else if (appData.tiling.shape == 3 /* Tiling.HEXGRID */)
			allEdges = 0x3f; /* 6 sides */
		else if (appData.tiling.shape == 6 /* Tiling.RHOMBUS */)
			allEdges = 0x0f; /* 4 sides */
		else
			allEdges = 0x07; /* minimum */

		// Function to perform a snapshot only once if the data is about to be changed
		function updated() {
			if (!sketchUpdated)
			{
				sketchUpdated = true;
				ScreenDesigner_PerformSnapshot();
			}
		}
		
		// We need a "rendered" design since the render contains the tile polygon list, which
		// maps tile polygons back to tile xy locations
		if (appDesignRender != undefined && appDesignRender.tilePolylist != undefined)
		{
			for (var i = 0; i < tileList.length; i++)
			{
				// Each tile contain an edge number and a polygon Index
				let tile = tileList[i];
				// The tag for each polygon in the tile list contains x & y location of the tile
				let tag = appDesignRender.tilePolylist.GetPolygonTagInfo(tile.polyIdx);
				let loc = (tag != undefined) ? tag.location : undefined;
				// This will fail if loc is undefined
				let tileSketchItem = {x:loc.x, y:loc.y, draw:0 /* solid tile */};
				var drawValue = undefined;
				
				if (sketchMode == ScreenDesignerCanvas.SketchMode.SOLID_TILE)
					drawValue = 0;
				else if (sketchMode == ScreenDesignerCanvas.SketchMode.HOLLOW_TILE)
					drawValue = allEdges;
			
				tileSketchItem.draw = drawValue;
				
				if (tag == undefined)
					console.log("ScreenDesigner_UpdateSketch: no tag info found");
				else if (loc == undefined)
					console.log("ScreenDesigner_UpdateSketch: tile location not in tag");
			
				if (loc != undefined)
				{
					// Find the index in the sketch data of the tile at location xy, if it exists.
					// If it doesn't exist then we add it later (if drawing). If it does exist we update it.
					let idx = appData.sketchData.findIndex(d => (d.x == loc.x && d.y == loc.y));
					
					if (sketchMode == ScreenDesignerCanvas.SketchMode.SOLID_TILE || sketchMode == ScreenDesignerCanvas.SketchMode.HOLLOW_TILE)
					{
						if (idx == -1) // Location not in sketch data; add it
						{
							updated();
							appData.sketchData.push(tileSketchItem);
						}
						else if (appData.sketchData[idx].draw != drawValue) // update it
						{	
							updated();
							appData.sketchData[idx].draw = drawValue;
						}
					}
					else if (sketchMode == ScreenDesignerCanvas.SketchMode.TILE_EDGE)
					{
						let edge = (tile.edge != undefined) ? tile.edge : 0;
						drawValue = (1 << edge);
						tileSketchItem.draw = drawValue;
						
						if (idx == -1)
						{
							updated();
							appData.sketchData.push(tileSketchItem);
						}
						else if ((appData.sketchData[idx].draw & drawValue) == 0)
						{
							updated();
							appData.sketchData[idx].draw = (appData.sketchData[idx].draw | drawValue);
						}
					}
					else if (sketchMode == ScreenDesignerCanvas.SketchMode.ERASE_TILE && idx != -1)
					{
						updated();
						appData.sketchData.splice(idx, 1);
					}
					else if (sketchMode == ScreenDesignerCanvas.SketchMode.ERASE_EDGE)
					{
						let edge = (tile.edge != undefined) ? tile.edge : 0;
						drawValue = (1 << edge);

						if (idx != -1)
						{
							// If it is currently a "full" tile, then the value will be zero, so set it to all edges
							if (appData.sketchData[idx].draw == 0)
								appData.sketchData[idx].draw = allEdges;
								
							// Refresh only if we are changing a bit
							if ((appData.sketchData[idx].draw & drawValue) != 0)
								updated();

							// Clear the bit for the selected edge
							appData.sketchData[idx].draw = (appData.sketchData[idx].draw & ~drawValue);
							// If all edges are turned off, then remove the tile from the list.
							// Note that it is possible to have tiles listed, but all of the visible edges
							// are cleared. This happens because adding a hollow tile does not take into 
							// account how many edges there are to a tile.
							// 2021.08.31: Now mask with allEdges. This will allow us to remove items that might 
							// have bits sets, but the edges are not visible.
							if ((appData.sketchData[idx].draw & allEdges) == 0)
								appData.sketchData.splice(idx, 1);
						}

						// 2021.08.30: Adjacent tile needed when erase an edge, since the edge could be in the adjacent tile
						let adjacentTile = ScreenGenerator.AdjacentTile(appData, {x:loc.x, y:loc.y, edge:tile.edge});
						let adjacentIdx = -1;
						if (adjacentTile != undefined)
							adjacentIdx = appData.sketchData.findIndex(d => (d.x == adjacentTile.x && d.y == adjacentTile.y));

						// Same as above
						if (adjacentIdx != -1)
						{
							drawValue = (adjacentTile.edge != undefined) ? (1 << adjacentTile.edge) : 1;
							
							if (appData.sketchData[adjacentIdx].draw == 0)
								appData.sketchData[adjacentIdx].draw = allEdges;
								
							if ((appData.sketchData[adjacentIdx].draw & drawValue) != 0)
								updated();
								
							appData.sketchData[adjacentIdx].draw = (appData.sketchData[adjacentIdx].draw & ~drawValue);
							if ((appData.sketchData[adjacentIdx].draw & allEdges) == 0)
								appData.sketchData.splice(adjacentIdx, 1);
						}
					}
				}
			}
			
			if (sketchUpdated)
			{
				ScreenDesigner_DesignMarkDirty(appData);
				ScreenGenerator_Design_DataModified(1);
			}
		}
		else
		{
			console.log("ScreenDesigner_UpdateSketch: missing appDesignRender or appDesignRender.tilePolylist");
		}
	}

	//---------------------------------------------------------------------------
	//	Add Adjacent Tile Edges
	//		2021.09.23: Add the adjacent tiles and edges to the list
	//---------------------------------------------------------------------------
	var ScreenDesigner_AddAdjacentTileEdges = function(tileList)
	{
		var expandedTileList = [];

		for (var i = 0; i < tileList.length; i++)
		{
			let tile = tileList[i];

			// The tag for each polygon in the tile list contains x & y location of the tile
			let tag = appDesignRender.tilePolylist.GetPolygonTagInfo(tile.polyIdx);
			let loc = (tag != undefined) ? tag.location : undefined;

			expandedTileList.push(tile);
			let adjacentTile = ScreenGenerator.AdjacentTile(appData, {x:loc.x, y:loc.y, edge:tile.edge});

			if (adjacentTile != undefined)
			{
				for (var j = 0; j < appDesignRender.tilePolylist.GetPolygonCount(); j++)
				{
					let g = appDesignRender.tilePolylist.GetPolygonTagInfo(j);
					if (g != undefined && g.location != undefined && g.location.x == adjacentTile.x && g.location.y == adjacentTile.y)
					{
						expandedTileList.push({polyIdx:j, edge:adjacentTile.edge});
						break;
					}
				}
			}
		}

		return expandedTileList;
	}

	//---------------------------------------------------------------------------
	//	Use Loaded Data
	//		Called when a file has successfully loaded by ScreenDesignerFileIO
	//---------------------------------------------------------------------------
	var ScreenDesigner_UseLoadedData = function(loadedData, loadingInfo)
	{
		// Use the data loaded from a file.
		// The data is expected to be a Javascript object that is either
		// a ScreenDesignerData object or a ScreenDesignerWorkbook object
		//
		
		// We store a value in the our "reserved" property of the objects we write
		// that identify which of our known objects it is.
		var dataType = loadedData["reserved"];
		
		// Determine what to do depending on a combination of the type of data loadedData
		// and the requested load operation (load design, load workbook, or import workbook)
		if (dataType == ScreenDesignerDataType.DESIGN_DATA)
		{
			Object.setPrototypeOf(loadedData, ScreenDesignerData.prototype);
			ScreenDesigner_DesignClearDirty(loadedData); // 2018.07.18: Use routine instead of setting
			loadedData.reserved = ScreenDesignerDataType.DESIGN_DATA;
			loadedData.AddMissingProperties();

			if (loadingInfo == "designLoad")
			{
				// 2018.07.20: Use primary function to set new data instead of making multiple calls
				ScreenDesigner_SetCurrentDesignData(loadedData); 
			}
			else if (loadingInfo == "workbookImport")
			{
				ScreenDesigner_Workbook.AddDesign(loadedData, true);
			}
			else if (loadingInfo == "workbookLoad")
			{
				ScreenDesigner_Workbook.AddDesign(loadedData, true);
			}
			else
				console.log("ScreenDesigner_UseLoadedData: Unknown loadingInfo: '" + loadingInfo + "'")
		}
		else if (dataType == ScreenDesignerDataType.WORKBOOK_DATA)
		{
			ScreenDesigner_Workbook.SetObjectPrototypes(loadedData);
			ScreenDesigner_DesignClearDirty(loadedData); // 2018.07.18: Use routine instead of setting

			if (loadingInfo == "designLoad")
			{
				alert("The file opened was a workbook instead of design. Try using the 'Load Workbook' or 'Import Workbook' buttons");
			}
			else if (loadingInfo == "workbookImport")
			{
				for (var i = 0; i < loadedData.designs.length; i++)
					ScreenDesigner_Workbook.AddDesign(loadedData.designs[i], false);
			}
			else if (loadingInfo == "workbookLoad")
			{
				ScreenDesigner_Workbook.Use(loadedData);
			}
			else if (loadingInfo == "galleryLoad")
			{
				ScreenDesigner_Gallery.UseWorkbook(loadedData);
			}
			else
				console.log("ScreenDesigner_UseLoadedData: Unknown loadingInfo: '" + loadingInfo + "'")
		}
		else // Object type is not identified. TEMPORARILY load as design, to handle currently saved files
		{
			console.log("WARNING!!! --- Loaded data ASSUMED to be design data!")
			Object.setPrototypeOf(loadedData, ScreenDesignerData.prototype);
			ScreenDesigner_DesignClearDirty(loadedData); // 2018.07.18: Use routine instead of setting
			loadedData.reserved = ScreenDesignerDataType.DESIGN_DATA;
			loadedData.AddMissingProperties();

			if (loadingInfo == "designLoad")
			{
				// 2018.07.20: Use primary function to set new data instead of making multiple calls
				ScreenDesigner_SetCurrentDesignData(loadedData); 
			}
			else if (loadingInfo == "workbookImport")
			{
				ScreenDesigner_Workbook.AddDesign(loadedData, true);
			}
			else if (loadingInfo == "workbookLoad")
			{
				ScreenDesigner_Workbook.AddDesign(loadedData, true);
			}
			else
				console.log("ScreenDesigner_UseLoadedData: Unknown loadingInfo: '" + loadingInfo + "'")
		}

		ScreenDesigner_UpdateInfoDisplays();
	}

	//---------------------------------------------------------------------------
	//	Clear Design Data
	//		Clears the design data. Intended to be called when logging-out so 
	//		no user data remains.
	//---------------------------------------------------------------------------
	var ScreenDesigner_ClearDesignData = function()
	{
		var newDesign = ScreenDesigner_NewScreenDesignerData(DesignType.TYPE_REGULAR_TILING);
	
		ScreenDesigner_SetCurrentDesignData(newDesign);
	}
	
	//---------------------------------------------------------------------------
	//	Set Current Design Data
	//		Primary function to set the design data that is being edited
	//---------------------------------------------------------------------------
	var ScreenDesigner_SetCurrentDesignData = function(designData)
	{
		try {
			if (ScreenDesigner_StorageMgr.IsDesignInProject(appData))
				ScreenDesigner_StorageMgr.UploadChanges();
				
			// 2021.03.23: Clear the processed data from Image designs
			if (appData.designType == DesignType.TYPE_IMAGE)
				appData.processedData = undefined;

			// Replaces current design data with provided data. Current data
			// is lost if not part of a workbook. Caller should verify that
			// data is either saved, part of a workbook, or can be discarded
			//
			designData.AddMissingProperties();
			designData.Validate();
			designData.ResetNextElementId(); // 2018.07.18: Added
			
			ScreenDesigner_SetDesign(designData);
			
			// 2020.12.01: Added
			if (designData.designType == DesignType.TYPE_IMAGE)
			{
				ScreenDesigner_RestoreEditorToTilingArea(); // 2021.03.17
				
				if (designData.imageDataURL != undefined)
				{
					let imgSettings = {offsetX:designData.image.imgOffsetX, offsetY:designData.image.imgOffsetY, zoom:designData.image.imgZoom};
					ScreenImageCanvas.SetImageData(designData.imageDataURL, imgSettings);
					designData.processedData = ScreenImageCanvas.GetProcessedData(); // 2021.03.23
				}
				else
				{
					//console.log("ScreenDesigner_SetCurrentDesignData: imageDataURL is missing");
					//2021.03.12: Was using this to set a blank image: ScreenImageCanvas.SetImageData("data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=");
					ScreenImageCanvas.ClearImageData();
				}
			}

			ScreenDesigner_UpdateUI();

			ScreenDesigner_HighlightActiveDesign();

			ScreenDesigner_UpdateInfoDisplays();
			
			ScreenDesigner_UndoHandler_ResetStack();
			
			// 2020.08.06: Zoom so that design is centered and full visible
			ScreenDesignerCanvas.ZoomToFrame();
			
		}
		catch (error) {
			console.log("ScreenDesigner_SetCurrentDesignData err: " + error);
		}
	}

	var ScreenDesigner_HighlightActiveDesign = function()
	{
		// In case a workbook item was shown, this will clear that. 
		// 2018.07.19: Moved to here from outside of this routine
		ScreenDesigner_Workbook.UI_ShowCurrentActive(appData); 

		ScreenDesigner_StorageMgr.UI_ShowCurrentActive(appData); 
	}
	
	//---------------------------------------------------------------------------
	//	Get Design Generation
	//		Returns rendered design object
	//		2021.08.10: Created
	//---------------------------------------------------------------------------
	var ScreenDesigner_GetDesignRender = function()
	{
		return appDesignRender;
	}

	//---------------------------------------------------------------------------
	//	Get AppConfig
	//		Returns app config
	//		2021.08.10: Created
	//---------------------------------------------------------------------------
	var ScreenDesigner_GetAppConfig = function()
	{
		return appConfig;
	}

	var ScreenDesigner_PerformSnapshot = function()
	{
		ScreenDesigner_UndoHandler_ConsiderSnapshot(appData)
	}
	
	var ScreenDesigner_ApplyUndoRedo = function(undoRedoDesignData)
	{
		appData.Import(undoRedoDesignData);

		if (appData.designType == DesignType.TYPE_IMAGE)
		{
			// Copied from _SetCurrentDesignData
			let imgSettings = {offsetX:appData.image.imgOffsetX, offsetY:appData.image.imgOffsetY, zoom:appData.image.imgZoom};
			ScreenImageCanvas.SetImageData(appData.imageDataURL, imgSettings);
		}

		// Create a render object
		appDesignRender = ScreenGenerator.Create(appData, ScreenGenerationType.COMPLETE_DESIGN);

		ScreenDesignerCanvas.SetDesignRender(appDesignRender);
			
		ScreenDesigner_UpdateUI({resetZoom:false});

		ScreenDesigner_LineInfoTable_Build();
		ScreenDesigner_ColorPalette_Build(); // 2020.10.15
	}

	var ScreenDesigner_SetDesign = function(newDesignData)
	{
		appData = newDesignData;

		// Create a render object
		appDesignRender = ScreenGenerator.Create(appData, ScreenGenerationType.COMPLETE_DESIGN);

		ScreenDesignerCanvas.SetDesignRender(appDesignRender);
		
		ScreenDesigner_LineInfoTable_Build();
		ScreenDesigner_ColorPalette_Build(); // 2020.10.15
	}

	var ScreenDesigner_UpdateUI = function(options = undefined) // 2019.10.12: Added 'options'
	{
		ScreenDesigner_PopulateControlValues(appData)
		ScreenDesigner_UpdateUnits(appData.general.units);

		var editTileInfo = ScreenGenerator.GetEditTileInfo(appData, undefined);
		ScreenEditorCanvas.ResetAll(options);
		ScreenEditorCanvas.SetEditTileInfo(editTileInfo);		
		ScreenEditorCanvas.LoadLineData(appData.elements);		
		
		ScreenDesigner_UpdateEditorRenderOptions();

		// 2020.12.08: Image designs may not have the data available.
		if (appData.ReadyToRender())
			ScreenDesigner_RenderRequest.RenderFullDesign(appDesignRender, false /* phase delay */);
			
		ScreenEditorCanvas.Render();
		
		ScreenDesigner_HideElement("ID_SketchToolbarDiv", (appData.designType != DesignType.TYPE_GRID_SKETCH));
	}

	
	//---------------------------------------------------------------------------
	//	Save and Export
	//---------------------------------------------------------------------------
	var ScreenDesigner_DownloadDesign = function()
	{
		var saEvent = "DesignExport";
		var requestedDownloadFormats = [];
		var downloadedFormatCount = 0;
		var didDownload = false;
		
		// Get list of download formats currently checked
		for (var i = 0; i < downloadFormatCheckboxes.length; i++)
		{
			var df = downloadFormatCheckboxes[i];
			var id = df.id;
			var e = document.getElementById(id);
			
			if (e != undefined)
			{
				requestedDownloadFormats[id] = e.checked;
			
				if (e.checked)
					downloadedFormatCount++;
			}
			else
			{
				console.log("ScreenDesigner_DownloadDesign: '" + id + "' missing.");
			}
		}
		
		if (downloadedFormatCount == 0)
			ScreenDesigner.DisplayMessageBanner("No file formats selected for downloading. Please select at least one file format to download.")
		
		// Download each of the selected formats
		if (requestedDownloadFormats["ID_SaveTXT"])
		{
			var name = ScreenDesignerFileIO.GetDocumentName();
			ScreenDesignerFileIO.SaveAsTXT(appData, name);
			SystemAnalytics.Record(saEvent, { format:"TXT", destination:"file" } );
		}
		
		if (requestedDownloadFormats["ID_SaveJSON"])
		{
			var name = ScreenDesignerFileIO.GetDocumentName();
			var jsonStr = ScreenDesigner_GetJSONOutput();
			ScreenDesignerFileIO.SaveAsJSON(jsonStr, name);
			SystemAnalytics.Record("JSONExport", { destination:"file" } );
		}

		if (requestedDownloadFormats["ID_ExportPNG"])
		{
			ScreenDesigner_ExportAsPNG(true /* save to file */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"file" } );
			didDownload = true;
		}

		if (requestedDownloadFormats["ID_WindowPNG"])
		{
			ScreenDesigner_ExportAsPNG(false /* open in new window */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"window" } );
			didDownload = true;
		}
		
		if (requestedDownloadFormats["ID_WindowMinPNG"])
		{
			ScreenDesigner_ExportAsPNG(false /* open in new window */, true /* minimal rect */, false /* single copy */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"window", subformat:"minimumTile" } );
			didDownload = true;
		}
		
		if (requestedDownloadFormats["ID_TestWndMinPNG"])
		{
			ScreenDesigner_ExportAsPNG(false /* open in new window */, true /* minimal rect */, true /* test */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"window", subformat:"minimumTile" } );
			didDownload = true;
		}
		
		if (requestedDownloadFormats["ID_ExportMinPNG"])
		{
			ScreenDesigner_ExportAsPNG(true /* save to file */, true /* minimal rect */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"file", subformat:"minimumTile" } );
			didDownload = true;
		}
		
		if (requestedDownloadFormats["ID_WindowSVG"])
		{
			var svgData = ScreenDesignerFileIO.GenerateSVG(appDesignRender)
			var newWindow = window.open();
			newWindow.document.write(svgData);
			SystemAnalytics.Record(saEvent, { format:"SVG", destination:"window" } );
			didDownload = true;
		}
		
		
		if (!ScreenGenerator.IsComplete(appDesignRender))
		{
			console.log("Need to wait for render to complete");
			alert("Need to wait for render to complete, then try save again.");
		}
	
		if (ScreenGenerator.IsComplete(appDesignRender))
		{
			if (requestedDownloadFormats["ID_SaveSVG"])
			{
				ScreenDesignerFileIO.SaveAsSVG(appDesignRender);
				SystemAnalytics.Record(saEvent, { format:"SVG", destination:"file" } );
				didDownload = true;
			}

			if (requestedDownloadFormats["ID_SaveDXF"])
			{
				ScreenDesignerFileIO.SaveAsDXF(appDesignRender);
				SystemAnalytics.Record(saEvent, { format:"DXF", destination:"file" } );
				didDownload = true;
			}

			if (requestedDownloadFormats["ID_ExportDXF"])
			{
				ScreenDesignerFileIO.ExportAsDXF(appDesignRender);
				SystemAnalytics.Record(saEvent, { format:"DXF", destination:"file", subformat:"noData" } );
				didDownload = true;
			}

			if (requestedDownloadFormats["ID_ExportSVG"]) // 2022.02.17: Added
			{
				ScreenDesignerFileIO.ExportAsSVG(appDesignRender);
				SystemAnalytics.Record(saEvent, { format:"SVG", destination:"file", subformat:"noData" } );
				didDownload = true;
			}
			
			ScreenDesigner_DesignClearDirty(appData); // 2018.07.18: Use routine instead of setting
		}
		
		//console.log("ScreenDesigner_DownloadDesign: downloadedFormatCount: " + downloadedFormatCount);
		
		if (didDownload)
			ScreenDesignerAccount.DecrementDownloadCount();
	}
	
	//---------------------------------------------------------------------------
	//	Save and Export
	//---------------------------------------------------------------------------
	var ScreenDesigner_SaveAsBtnHandler = function(evt)
	{
		// Handles buttons related to saving data and displaying data in new windows
		//
		
		var saEvent = "DesignExport";
		
		if (this.id == "ID_DownloadSelected")
		{
			if (ScreenDesignerAccount.CanPerformOrShowBlockedUI("downloadDesign"))
				ScreenDesigner_DownloadDesign();
		}
		
		else if (this.id == "ID_SaveTXT")
		{
			var name = ScreenDesignerFileIO.GetDocumentName();
			ScreenDesignerFileIO.SaveAsTXT(appData, name);
			SystemAnalytics.Record(saEvent, { format:"TXT", destination:"file" } );
		}
		else if (this.id == "ID_ExportPNG")
		{
			if (appWorkbookCommonEdit)
			{
				ScreenDesigner_Workbook.ExportAllAsPNG();
				SystemAnalytics.Record(saEvent, {format:"PNG", destination:"file", note:"workbook"} );
			}
			else
			{
				ScreenDesigner_ExportAsPNG(true /* save to file */);
				SystemAnalytics.Record(saEvent, { format:"PNG", destination:"file" } );
			}
				
		}
		else if (this.id == "ID_WindowPNG")
		{
			ScreenDesigner_ExportAsPNG(false /* open in new window */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"window" } );
		}
		else if (this.id == "ID_WindowMinPNG")
		{
			ScreenDesigner_ExportAsPNG(false /* open in new window */, true /* minimal rect */, false /* single copy */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"window", subformat:"minimumTile" } );
		}
		else if (this.id == "ID_TestWndMinPNG")
		{
			ScreenDesigner_ExportAsPNG(false /* open in new window */, true /* minimal rect */, true /* test */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"window", subformat:"minimumTile" } );
		}
		else if (this.id == "ID_ExportMinPNG")
		{
			ScreenDesigner_ExportAsPNG(true /* save to file */, true /* minimal rect */);
			SystemAnalytics.Record(saEvent, { format:"PNG", destination:"file", subformat:"minimumTile" } );
		}
		else if (this.id == "ID_WindowSVG")
		{
			var svgData = ScreenDesignerFileIO.GenerateSVG(appDesignRender)
			var newWindow = window.open();
			newWindow.document.write(svgData);
			SystemAnalytics.Record(saEvent, { format:"SVG", destination:"window" } );
		}
		else
		{
			if (!ScreenGenerator.IsComplete(appDesignRender))
			{
				console.log("Need to wait for render to complete");
				alert("Need to wait for render to complete, then try save again.");
			}
		
			if (ScreenGenerator.IsComplete(appDesignRender))
			{
				if (this.id == "ID_SaveSVG")
				{
					ScreenDesignerFileIO.SaveAsSVG(appDesignRender);
					SystemAnalytics.Record(saEvent, { format:"SVG", destination:"file" } );
				}
				else if (this.id == "ID_SaveDXF")
				{
					ScreenDesignerFileIO.SaveAsDXF(appDesignRender);
					SystemAnalytics.Record(saEvent, { format:"DXF", destination:"file" } );
				}
				else if (this.id == "ID_ExportDXF")
				{
					ScreenDesignerFileIO.ExportAsDXF(appDesignRender);
					SystemAnalytics.Record(saEvent, { format:"DXF", destination:"file", subformat:"noData" } );
				}
				else if (this.id == "ID_SaveAsJSON") // 2021.04.05
				{
					var name = ScreenDesignerFileIO.GetDocumentName();
					var jsonStr = ScreenDesigner_GetJSONOutput();
					ScreenDesignerFileIO.SaveAsJSON(jsonStr, name);
					SystemAnalytics.Record("JSONExport", { format:"JSON", destination:"file" } );
				}
				else
					console.log("ScreenDesigner_SaveAsBtnHandler: unknown button id:" + this.id);
				
				ScreenDesigner_DesignClearDirty(appData); // 2018.07.18: Use routine instead of setting
			}
		}

		ScreenDesigner_UpdateInfoDisplays();
	}

	var ScreenDesigner_ExportAsPNG = function(saveToFile, minimalRepeatingPattern = false, testRepeatingPattern = false)
	{
		// Generate a PNG image of the data and either open it in a new window (saveToFile==false)
		// or write it to a file (saveToFile==true). This will not perform a final render before 
		// creating the PNG.
		
		// 2019.03.25: Refactored slightly so that we can call "RenderPNGBlob" when exporting the full design as a PNG. 
		// This addresses the issue that Chrome has dealing with very large downloads.
		
		if (saveToFile)
		{
			if (minimalRepeatingPattern)
			{
				var pngInfo = ScreenRenderer.RenderPNG(appDesignRender, ScreenRenderer.RenderType.FOR_EXPORT, {minimalRepeatingPattern:true}); 
				var pngData = pngInfo.data;
				ScreenDesignerFileIO.ExportAsPNG(pngData);
			}
			else
			{
				// Note that RenderPNGBlob takes a callback to perform the download
				ScreenRenderer.RenderPNGBlob(appDesignRender, ScreenDesignerFileIO.ExportAsPNGBlob);
			}
		}
		else
		{
			var pngInfo = ScreenRenderer.RenderPNG(appDesignRender, ScreenRenderer.RenderType.FOR_EXPORT, {minimalRepeatingPattern:minimalRepeatingPattern}); 
			var pngData = pngInfo.data;
			var newWindow = window.open();
			newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
			
			// If the 'test' flag is true, add multiple copies to the new window
			if (testRepeatingPattern)
			{
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				
				newWindow.document.write("<br>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				
				newWindow.document.write("<br>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
				newWindow.document.write("<img src='"+ pngData+"' alt='rendered PNG'/>");
			}
		}
	}

	//---------------------------------------------------------------------------
	//	Zoom
	//---------------------------------------------------------------------------
	var ScreenDesigner_Zoom_priv = function(evt)
	{
		// Editor zoom buttons
		if (this.id == "ID_ZoomFullTile")
			ScreenEditorCanvas.Zoom(ScreenEditorCanvas.ZoomTo.FULL_TILE);

		else if (this.id == "ID_ZoomSmallestSubTile")
			ScreenEditorCanvas.Zoom(ScreenEditorCanvas.ZoomTo.SMALLEST_SUBTILE);

		// Result zoom buttons
		else if (this.id == "ID_ZoomFrameFull")
			ScreenDesignerCanvas.Zoom(ScreenDesignerCanvas.ZoomTo.FRAME);
		else if (this.id == "ID_ZoomFrameOne")
		{
			var scale = BasicUnitsMgr.CalcScale(appData.general.units, appData.general.dpi);
			ScreenDesignerCanvas.Zoom(ScreenDesignerCanvas.ZoomTo.ONE_TO_ONE, scale);
		}
	}

	//---------------------------------------------------------------------------
	//	Drag Handler
	//		2021.04.03: Handles the start of the drag.
	//		2021.04.05: Added dragend
	//---------------------------------------------------------------------------
	var ScreenDesigner_DragHandler = function(evt, evtName)
	{
		// Drag starting: provide data to the dataTransfer object
		if (evtName == "dragstart")
		{
			// Get a JSON string of the output data and set it into the drag object
			let jsonStr = ScreenDesigner_GetJSONOutput();
			evt.dataTransfer.setData("text/json", jsonStr);
			evt.dataTransfer.setData("text/plain", jsonStr);
			// Not setting value of 'evt.dataTransfer.effectAllowed' since the default "all" is fine
		}
		// Drag ending: If the drag completed successfully, then log the event
		else if (evtName == "dragend")
		{
			if (evt.dataTransfer.dropEffect != "none")
				SystemAnalytics.Record("JSONExport", { destination:"drag" } );
		}
		else
		{
			console.log("ScreenDesigner_DragHandler: not handled: " + evtName);
		}
	}

	//---------------------------------------------------------------------------
	//	Clipboard: Copy JSON
	//		2021.04.05: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_Clipboard_CopyJSON = function()
	{
		let jsonStr = ScreenDesigner_GetJSONOutput();

		navigator.clipboard.writeText(jsonStr)
		.then ( () =>
			{
				ScreenDesigner.DisplayMessageBanner("Copied JSON to clipboard.", {size:"small", position:"bottom"});
				SystemAnalytics.Record("JSONExport", { destination:"clipboard" } );
			} )
		.catch (err =>
			{
				ScreenDesigner.DisplayMessageBanner("Failed to copy to clipboard.", {size:"small", position:"bottom"});
			});
	}

	//---------------------------------------------------------------------------
	//	Get JSON Output
	//		2021.04.03: Returns a JSON string containing the polygon list and
	//		any other data useful to external apps
	//		2021.04.06: Wrap the list in a design object and clean-up the polygons
	//		to remove any private data and to reduce the export size
	//---------------------------------------------------------------------------
	var ScreenDesigner_GetJSONOutput = function()
	{
		let designOutput = {};
		let polygonList = {polygons:[]};

		// Copy only the info tag and the x,y value for each point
		function cleanUpPoly(poly)
		{
			let out = {points:[], info:poly.info};
			poly.points.forEach(pt => out.points.push({x:pt.x, y:pt.y}));
			return out;
		}

		// Get a cleaned up copy of each polygon and push it on the output list
		appDesignRender.offsetPolyList.polygons.forEach(poly => polygonList.polygons.push(cleanUpPoly(poly)));

		designOutput.polygonList = polygonList;

		let jsonStr = JSON.stringify(designOutput);

		return jsonStr;
	}
	
	//---------------------------------------------------------------------------
	//	Swap Editor
	//---------------------------------------------------------------------------
	var ScreenDesigner_SwapEditorAndRender = function()
	{
		// The elements to swap
		let editor = document.getElementById("ID_TileCanvasAndToolbars");
		let render = document.getElementById("ID_EditCanvasAndToolbars");
		// The elements to swap into (the "holders")
		let editorHolder = document.getElementById("ID_TileCanvasHolder");
		let renderHolder = document.getElementById("ID_EditCanvasHolder");


		// Info to describe the zoom level for each element before the swap
		let editorRelativeLayout = ScreenEditorCanvas.GetRelativeLayout();
		let renderRelativeLayout = ScreenDesignerCanvas.GetRelativeLayout();

		// If the editor is in the "editor holder", then swap the editor and the render
		if (editor.parentElement == editorHolder)
		{
			editorHolder.appendChild(render);
			renderHolder.appendChild(editor);
		}
		// Otherwise, restore them to the original
		else
		{
			editorHolder.appendChild(editor);
			renderHolder.appendChild(render);
		}

		ScreenDesignerCanvas.ResizeHandler();
		ScreenDesignerCanvas.SetRelativeLayout(renderRelativeLayout);
		ScreenDesignerCanvas.Render();

		ScreenEditorCanvas.ResizeHandler();
		ScreenEditorCanvas.SetRelativeLayout(editorRelativeLayout);
		ScreenEditorCanvas.Render();
	}
	
	//---------------------------------------------------------------------------
	//	Restore Editor To Tiling Area
	//		2021.03.17: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_RestoreEditorToTilingArea = function()
	{
		let editor = document.getElementById("ID_TileCanvasAndToolbars");
		let editorHolder = document.getElementById("ID_TileCanvasHolder");
		
		if (editor.parentElement != editorHolder)
			ScreenDesigner_SwapEditorAndRender();
	}
	
	//---------------------------------------------------------------------------
	//	Show Processed Image
	//		2021.03.23: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_ShowProcessedImage = function()
	{
		let pi = document.getElementById("ID_ProcessCanvas");
		
		if (pi != undefined)
			pi.classList.toggle("CL_HideElement");
	}
	
	//---------------------------------------------------------------------------
	//	Calc Zoom Changes
	//		Called from both the editor canvas and the render canvas when they are
	//		swapped in the DOM
	//---------------------------------------------------------------------------
	var ScreenDesigner_CalcZoomChanges = function(canvas, layout, zoom, originIsCenter)
	{
		let newZoom = zoom.Clone();
		
		// The dimensions of the tile editor area. We need to use these if the canvas is
		// hidden, which we know because the clientWidth and clientHeight are zero.
		let defaultWidth  = 350;
		let defaultHeight = 350;

		var targetWidth   = (canvas.clientWidth  == 0) ? defaultWidth  : canvas.clientWidth;
		var targetHeight  = (canvas.clientHeight == 0) ? defaultHeight : canvas.clientHeight;

		var initialWidth  = (layout.width  == 0) ? defaultWidth  : layout.width;
		var initialHeight = (layout.height == 0) ? defaultHeight : layout.height;

		var target  = (targetWidth < targetHeight) ? targetWidth : targetHeight;
		var initial = (initialWidth < initialHeight) ? initialWidth : initialHeight;
		let scale = target / initial;

		// Determine the relative offset (of the editor canvas)
		var relativeLeft = (initialWidth/2  - (defaultWidth  * newZoom.scale.x)/2) - newZoom.offset.x;
		var relativeTop  = (initialHeight/2 - (defaultHeight * newZoom.scale.y)/2) - newZoom.offset.y;

		// Update the scale
		newZoom.scale.x *= scale;
		newZoom.scale.y *= scale;

		// The editor canvas origin is the top-left, while the render canvas origin is
		// the center.
		if (originIsCenter)
		{
			// Compute a delta to move the center from the initial location to the target location
			var targetCenter  = {x:targetWidth/2,  y:targetHeight/2  };
			var initialCenter = {x:initialWidth/2, y:initialHeight/2 };

			newZoom.offset.x += targetCenter.x - initialCenter.x;
			newZoom.offset.y += targetCenter.y - initialCenter.y;
		}
		else
		{
			// Compute a delta to move the top-left corner that will keep the drawing in the
			// canvas centered (if it is currently centered) or offset by the same amount
			var targetLeft = targetWidth/2  - (defaultWidth  * newZoom.scale.x)/2;
			var targetTop  = targetHeight/2 - (defaultHeight * newZoom.scale.y)/2;
			newZoom.offset.x = targetLeft - relativeLeft;
			newZoom.offset.y = targetTop  - relativeTop;
		}

		newZoom.Validate();

		return newZoom;
	}
	
	//---------------------------------------------------------------------------
	//	Design Data Modified 
	//		Re-generate and re-render
	//---------------------------------------------------------------------------
	var ScreenGenerator_Design_DataModified = function(changeType)
	{
		ScreenDesignerCanvas.Render();
		
		// 2019.03.04: Now calling this routine for color changes also, so added meaning 
		// to 'changeType'. At the moment '5' is rather arbitrary.
		if (changeType < 5)
		{
			// 2021.05.14: Improve redraw time for TYPE_GRID_SKETCH; add "options"
			var options = undefined;
			var delayBetweenPhases = true;
			
			if (appData.designType == DesignType.TYPE_GRID_SKETCH)
			{
				delayBetweenPhases = false;
				options = {delayTime:0};
			}
			if (appData.designType == DesignType.TYPE_IMAGE)
			{
				delayBetweenPhases = false;
				options = {delayTime:0};
			}
				
			ScreenGenerator.DesignDataChanged(appDesignRender, changeType);
			ScreenDesigner_RenderRequest.RenderFullDesign(appDesignRender, delayBetweenPhases);
		}
		
		// Give workbook opportunity to update design, if needed
		ScreenDesigner_Workbook.UpdateDesignIfInWorkbook(appData);

		// Give storage/project mgr opportunity to update design, if needed
		ScreenDesigner_StorageMgr.UpdateDesignIfInProject(appData);

		ScreenDesigner_UpdateInfoDisplays();
	}
	
	//---------------------------------------------------------------------------
	//	Is Rectangular Frame
	//		2021.08.26: Created so it can used to identify when Aspect Ratio
	//		is shown (for design export)
	//---------------------------------------------------------------------------
	var ScreenDesigner_IsRectangularFrame = function()
	{
		var isRectFrame = (	appData.frame.shape == FrameShape.FRAME_RECTANGLE 		||
							appData.frame.shape == FrameShape.FRAME_NORMAN_WINDOW 	||
							appData.frame.shape == FrameShape.FRAME_DIAMOND 		||
							appData.frame.shape == FrameShape.FRAME_ISO_TRIANGLE);
							
		return isRectFrame;
	}
	
	//---------------------------------------------------------------------------
	//	Update UI Functions
	//---------------------------------------------------------------------------
	var ScreenDesigner_ShowRelevantFrameDimensions = function()
	{
		// 2018.06.07: Added Norman window
		// 2019.02.18. Added Isoscles triangle
		// 2021.08.26: Moved to function
		var showRectDim = ScreenDesigner_IsRectangularFrame();

		// 2020.08.05: Added to distinguish from a third class of frames
		var showPolyDim = (	appData.frame.shape == FrameShape.FRAME_TRIANGLE 	||
							appData.frame.shape == FrameShape.FRAME_PENTAGON 	||
							appData.frame.shape == FrameShape.FRAME_HEXAGON 	||
							appData.frame.shape == FrameShape.FRAME_OCTAGON 	||
							appData.frame.shape == FrameShape.FRAME_SIDES_12 	||
							appData.frame.shape == FrameShape.FRAME_SIDES_36);

		// 2020.08.05: Rotation is disabled for Single Tile
		var showRotation = (appData.frame.shape != FrameShape.FRAME_SINGLE_TILE);

		var e = document.getElementById('ID_FrameRectDimensions');
		if (e != undefined)
			e.style.display = (showRectDim ? 'block' : 'none');

		var e = document.getElementById('ID_FramePolyDimensions');
		if (e != undefined)
			e.style.display = (showPolyDim ? 'block' : 'none');

		var e = document.getElementById('ID_FrameRotateDimensions');
		if (e != undefined)
			e.style.display = (showRotation ? 'block' : 'none');
	}
	
	var ScreenDesigner_ShowRelevantDocumentDimensions = function()
	{
		var e = document.getElementById('ID_FixedBoundsDimensions');
		if (e != undefined)
			e.style.display = (appData.frame.fixedBounds ? 'block' : 'none');
	}
	
	//---------------------------------------------------------------------------
	//	Hide Element
	//		2020.03.09: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_HideElement = function(elementId, hideElement)
	{
		let e = document.getElementById(elementId)
		if (e != undefined)
		{
			if (hideElement)
				e.classList.add("CL_HideElement");
			else
				e.classList.remove("CL_HideElement");
		}
		else
		{
			console.log("ScreenDesigner_SetHideElement: missing " + elementId);
		}
	}
	
	//---------------------------------------------------------------------------
	//	Show Relevant Tile Controls
	//		2020.08.18: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_ShowRelevantTileControls = function()
	{
		// 2021.03.09: Use convenience function
		ScreenDesigner_HideElement("ID_SymmetryOptions", !ScreenGenerator.ShowSymmetryOptions(appData));
		/*
		let e = document.getElementById("ID_SymmetryOptions")
		if (e != undefined)
		{
			if (ScreenGenerator.ShowSymmetryOptions(appData))
				e.classList.remove("CL_HideElement");
			else
				e.classList.add("CL_HideElement");
		}
		else
		{
			console.log("missing ID_SymmetryOptions");
		}
		*/
	}
	
	//---------------------------------------------------------------------------
	//	Show Relevant Tab Controls
	//		Shows either the Tiling tab or the Image tab
	//		2021.03.09: Added
	//		2021.05.10: Add Sketch
	//---------------------------------------------------------------------------
	var ScreenDesigner_ShowRelevantTabControls = function()
	{
		// Table to map design types to design type tabs in the UI
		let typeToTab = [
			{type:DesignType.TYPE_REGULAR_TILING,	labelId:"ID_TilingTabAreaL",	radioId:"ID_TilingTabAreaR"	},
			{type:DesignType.TYPE_IMAGE,			labelId:"ID_ImageTabAreaL",		radioId:"ID_ImageTabAreaR"	},
			{type:DesignType.TYPE_GRID_SKETCH,		labelId:"ID_SketchTabAreaL",	radioId:"ID_SketchTabAreaR"	}
		];

		// Is a design type tab currently selected?
		let isAboutToHideSelected = false;
		for (var i = 0; i < typeToTab.length; i++)
			isAboutToHideSelected = (isAboutToHideSelected || ScreenDesigner_UI_IsTabSelected(typeToTab[i].radioId));
		
		// Show the tab for the current design type
		for (var i = 0; i < typeToTab.length; i++)
		{
			let ttt = typeToTab[i];
			let hideIt = (ttt.type != appData.designType);
			ScreenDesigner_HideElement(ttt.labelId, hideIt);
		}
		
		// Switch tabs if needed
		if (isAboutToHideSelected)
		{
			// Id of tab  for current design type
			let currentTabRadioId = undefined;
			for (var i = 0; i < typeToTab.length && currentTabRadioId == undefined; i++)
			{
				if (typeToTab[i].type == appData.designType)
					currentTabRadioId = typeToTab[i].radioId;
			}
			if (currentTabRadioId == undefined)
			{
				console.log("ScreenDesigner_ShowRelevantTabControls: id not in table");
				currentTabRadioId = typeToTab[0].radioId
			}
		
			ScreenDesigner_UI_SelectTab(currentTabRadioId);
		}
	}

	//---------------------------------------------------------------------------
	//	Populate Control Values
	//---------------------------------------------------------------------------
	var ScreenDesigner_PopulateControl = function(data, controlInfo)
	{
		// Use the id in the control list to get the element from the page
		var id = controlInfo.id;
		var e = document.getElementById(id);
		
		// Use the prop in the control list to get the value from the object
		var cat  = controlInfo.cat;
		var prop = controlInfo.prop;
		
		// But first check if it is in the layout object
		if (e == undefined)
		{
			console.log("Setting " + id + ", but element not found");
		}
		// 2020.10.07: Added "system" controls
		else if (controlInfo.sys != undefined)
		{
			//systemSettings[prop] = value;
		}
		else if (data[cat] != undefined && data[cat][prop] != undefined)
		{
			var value = data[cat][prop];
			
			if (controlInfo.radioValue == undefined) // 2019.02.19: Support radio controls
			{
				// Use the access in the control list to determine how to update the element
				var access = controlInfo.access;
			
				e[access] = value;
			
				if (id.includes("Color") && access == "value")
					e.setAttribute("value", value);
					
				// 2021.03.25: Update paired elements with the same value.
				if (e.pairedElement != undefined)
					e.pairedElement.value = value;
			}
			else
			{
				// If the property value matches the radio value, then check the 
				// radio button, otherwise clear the button
				let rv = controlInfo.radioValue;
				e.checked = (value == rv);
			}
		}
		else if (controlInfo.optional) // 2018.01.24: Added "optional"
		{
			// The value in the designData was undefined, so clear it from the control
			var access = controlInfo.access;
			e[access] = "";
		}
		else
		{
			console.log("Setting " + id + ", but " + cat + "." + prop + " not found");
		}
	}
	
	//---------------------------------------------------------------------------
	//	Populate Control Values
	//---------------------------------------------------------------------------
	var ScreenDesigner_PopulateControlValues = function(data)
	{
		// Load controls with values from layout
		// 2021.03.29: Factor out code to populate a single control
		for (var i = 0; i < editControlList.length; i++)
		{
			ScreenDesigner_PopulateControl(data, editControlList[i]);
		}
		
		// 2021.07.03: Add color gradient
		ScreenDesigner_PopulateGradientUI({id:"ID_ColorGradientDiv"}, data.gradients[0]);

		ScreenDesigner_ShowRelevantFrameDimensions();
		ScreenDesigner_ShowRelevantDocumentDimensions();
		ScreenDesigner_ShowRelevantTileControls(); // 2020.08.18
		ScreenDesigner_ShowRelevantTabControls(); // 2021.03.09
		ScreenDesigner_UpdateTileDimensions();
		ScreenDesigner_UpdateScaleDimension(); // 2021.07.12
	}

	//---------------------------------------------------------------------------
	//	Populate Control List
	//		2021.03.29: Populates a specific list of controls
	//---------------------------------------------------------------------------
	var ScreenDesigner_PopulateControlsByIdList = function(data, controlIdList)
	{
		for (var i = 0; i < controlIdList.length; i++)
		{
			var id = controlIdList[i];
			var controlInfo = editControlList.find(ci => ci.id == id);

			if (controlInfo != undefined)
				ScreenDesigner_PopulateControl(data, controlInfo);
			else
				console.log("ScreenDesigner_PopulateControlList: unknown control id: '" + controlIdList[i] + "'");
		}
	}


	var ScreenDesigner_GetDimensionsStr = function(width, height, places = 2)
	{
		var str = undefined;
		
		if (width != undefined && height != undefined && width > 0 && height > 0)
		{
			var w = width.toFixed(places);
			var h = height.toFixed(places);
			str = w + " x " + h;
		}

		return str;
	}

	var ScreenDesigner_PopulateDimensions = function(id, width, height, places = 2)
	{
		var e = document.getElementById(id);
		
		if (e != undefined)
		{
			var str = ScreenDesigner_GetDimensionsStr(width, height, places);
			e.innerHTML = (str != undefined) ? str : undefined;
		}
	}	
	
	var ScreenDesigner_GetDesignDimensions = function()
	{
		var width  = appDesignRender.bounds != undefined ? (appDesignRender.bounds.max.x - appDesignRender.bounds.min.x) : 0;
		var height = appDesignRender.bounds != undefined ? (appDesignRender.bounds.max.y - appDesignRender.bounds.min.y) : 0;
		return {width: width, height: height};
	}
	
	var ScreenDesigner_GetDesignDimensionsStr = function()
	{
		var dim = ScreenDesigner_GetDesignDimensions();
		return ScreenDesigner_GetDimensionsStr(dim.width, dim.height);
	}
	
	var ScreenDesigner_UpdateDimensions = function()
	{
		var dim = ScreenDesigner_GetDesignDimensions();
		ScreenDesigner_PopulateDimensions("ID_BoundsSize", dim.width, dim.height);
	}
	
	var ScreenDesigner_UpdateTileDimensions = function()
	{
		try {
			var editTileInfo = ScreenGenerator.GetEditTileInfo(appData, undefined);

			var width  = editTileInfo.bounds.size.x;
			var height = editTileInfo.bounds.size.y;
		
			ScreenDesigner_PopulateDimensions("ID_TileDimensions", width, height);
		}
		catch (err) {
			console.log("ScreenDesigner_UpdateTileDimensions: " + err);
		}
	}
		
	var ScreenDesigner_UI_SelectTab = function(tabId, value)
	{
		var e = document.getElementById(tabId);
		if (e != undefined)
			e.checked = (value != undefined) ? value : true;
		else
			console.log("Can not find tab '" + tabId + "'");
	}
	
	//---------------------------------------------------------------------------
	//	UI: Is Tab Selected
	//		2021.03.09: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_UI_IsTabSelected = function(tabId)
	{
		var selected = false;
		
		var e = document.getElementById(tabId);
		if (e != undefined)
		{
			selected = e.checked;
			if (selected == undefined)
				console.log("ScreenDesigner_UI_IsTabSelected: 'selected' is undefined for '" + tabId + "'");
		}
		else
			console.log("ScreenDesigner_UI_IsTabSelected: Can not find tab '" + tabId + "'");
			
		return selected;		
	}
	
	//---------------------------------------------------------------------------
	//	Update Units
	//---------------------------------------------------------------------------
	var ScreenDesigner_UpdateUnits = function(units)
	{
		var unitStr = BasicUnitsMgr.GetStr(units);
		var unitsElements = document.getElementsByClassName("ControlLabelUnits");
		
		for (var i = 0; i < unitsElements.length; i++)
			unitsElements[i].textContent = unitStr;
		
		// 2021.07.29: Adjust the increment values according to the units of the design
		let widthChangeScale = (units == BasicUnits.INCHES) ? 10 : 1;
		ScreenEditorCanvas.SetWidthScale(widthChangeScale);
	}

	//---------------------------------------------------------------------------
	//	Handle Dimension Input Field Adjust
	//---------------------------------------------------------------------------
	var ScreenDesigner_HandleDimensionInputFieldAdjust = function(evt, evtName)
	{
		let prec = undefined; // Precision: 1, 10, or 100
		let dir = 1; // Direction: -1 or 1
		
		if (evtName == "keydown" && (evt.key == "ArrowDown" || evt.key == "ArrowUp"))
		{
			dir = (evt.key == "ArrowDown") ? -1 : 1;
			
			if (evt.shiftKey)
				prec = 10;
			else if (evt.metaKey)
				prec = 100;
			else
				prec = 1
		}
		else if (evtName == "mousewheel")
		{
			dir = (evt.deltaY < 0) ? -1 : 1;
			prec = (appData.general.units == BasicUnits.INCHES) ? 10 : 1;
			
			if (evt.shiftKey)
				prec *= 10;
			else if (evt.metaKey)
				prec *= 100;
		}
		
		if (prec != undefined)
		{
			let value = Number(evt.target.value);
			value = Math.floor(prec * value + dir) / prec;

			evt.target.value = value;

			// We need to dispatch an event to get our normal handling to occur
			const event = new InputEvent('input', {view: window, bubbles: true, cancelable: true, inputType: 'insertText'});
			evt.target.dispatchEvent(event);
			
			evt.preventDefault();
		}
	}

	var ScreenDesigner_UpdateUIforParameterChange = function(controlInfo, value)
	{
		// DDK 2018.01.19: Factored out of ScreenDesigner_HandleControls so it can 
		// be called from the "workbook common edit" code
		//
			

		if (controlInfo.id == "ID_Units")
		{
			var units = parseInt(value);
	
			if (units == BasicUnits.PIXELS || units == BasicUnits.INCHES || units == BasicUnits.MILLIMETERS)
				ScreenDesigner_UpdateUnits(units);
			else
				Debug_assert("ID_Units control returned illegal units value");
		}
		else if (controlInfo.id == "ID_FrameShape")
		{
			ScreenDesigner_ShowRelevantFrameDimensions();
		}
		else if (controlInfo.id == "ID_FixedBounds")
		{
			ScreenDesigner_ShowRelevantDocumentDimensions();
		}
		else if (controlInfo.id == "ID_DocumentWidth" || controlInfo.id == "ID_DocumentHeight")
		{
			ScreenDesignerCanvas.Render();
		}
		else if (controlInfo.id == "ID_TileShape") // 2020.08.18
		{
			ScreenDesigner_ShowRelevantTileControls();
		}
		else if (controlInfo.id == "ID_UseLineColors" || controlInfo.id == "ID_EnableLattice") // 2020.08.25, 2022.02.01: Lattice
		{
			ScreenDesigner_LineInfoTable_Build();
			ScreenEditorCanvas.Render(); // 2022.02.08
		}
	}
		
	var ScreenDesigner_BuildOptionsListMatching = function(matchProperty)
	{
		var options = {};
		
		for (var i = 0; i < editControlList.length; i++)
		{
			var ci = editControlList[i];
			if (matchProperty in ci && appData[ci.cat] != undefined && appData[ci.cat][ci.prop] != undefined)
			{
				if (options[ci.prop] != undefined && ci.radioValue == undefined)
					console.log("ScreenDesigner_BuildOptionsListMatching: repeated property name: " + ci.prop);
					
				options[ci.prop] = appData[ci.cat][ci.prop];
			}
		}
		return options;
	}
	
	var ScreenDesigner_UpdateEditorRenderOptions = function()
	{
		var editOptions = ScreenDesigner_BuildOptionsListMatching("editor");
		ScreenEditorCanvas.SetRenderOptions(editOptions);
	}
	
	//---------------------------------------------------------------------------
	//	Resize Handler
	//---------------------------------------------------------------------------
	var ScreenDesigner_ResizeWindowHandler = function()
	{
		ScreenDesignerCanvas.ResizeHandler();
		
		ScreenDesignerCanvas.Render();
		
		ScreenDesigner_Gallery.Resize();
		
		ScreenDesigner_StorageMgr.PositionProjectListBackground();
		
		// 2020.07.30: Added now that the editor can be resizedx		
		ScreenEditorCanvas.ResizeHandler();
		ScreenEditorCanvas.Render();
	}

	//---------------------------------------------------------------------------
	//	Position Toolbars
	//		2020.07.30: Added
	//---------------------------------------------------------------------------
	/*
	var ScreenDesigner_PositionToolbars = function()
	{
		return;
		
		try {
			// General toolbar
			let gtb = document.getElementById("ID_GeneralToolbarDiv");
			
			if (gtb != undefined)
			{
				gtb.style.right = "5px";
				//gtb.style.top  = posY + "px";
			}

			// Render toolbar and canvas
			let rtb = document.getElementById("ID_CanvasToolbarDiv");
			let rcn = document.getElementById("ID_EditCanvas");
			
			if (rtb != undefined && rcn != undefined)
			{
				// 2020.08.03: I changed the DOM hierarchy, so this is no longer needed
				// --getBoundingClientRect returns values relative to the viewport,
				// --but style.left and style.top for absolutely positioned elements
				// --are relative to the positioned parent element. Therefore, we
				// --need to determine the position of the render canvas (known as 
				// --"ID_EditCanvas") relative to its original container
				//
				let bounds = rcn.getBoundingClientRect();
			
				var posX = bounds.left  + 5;
				var posY = bounds.top   + 5;
			
				rtb.style.left = posX + "px";
				rtb.style.top  = posY + "px";
			}

			// Edit toolbar and canvas
			let etb = document.getElementById("ID_EditorToolbarDiv");
			let ecn  = document.getElementById("ID_TileCanvas");
			
			if (etb != undefined && ecn != undefined)
			{
				let bounds = ecn.getBoundingClientRect();
				let boundsTB = etb.getBoundingClientRect();
			
				var posX = bounds.right - boundsTB.width - 5;
				var posY = bounds.top   + 5;
			
				etb.style.left = posX + "px";
				etb.style.top  = posY + "px";
			}
		}
		catch (err) {
			console.log(err);
		}
	}
	*/

	//---------------------------------------------------------------------------
	//	Handle Keypress
	//---------------------------------------------------------------------------
	var ScreenDesigner_HandleKeydown = function(evt)
	{
		let key = evt.key || evt.keyCode;
		let ctrl = evt.ctrlKey;

		if ((key == 'z' || key == 90) && ctrl)  /* 'ctrl-Z' */
			ScreenDesigner_UndoHandler_Undo();
		else if ((key == 'y' || key == 89) && ctrl)  /* 'ctrl-Y' */
			ScreenDesigner_UndoHandler_Redo();
	}

	//---------------------------------------------------------------------------
	//	Undo Handler: ResetStack
	//---------------------------------------------------------------------------
	var ScreenDesigner_UndoHandler_ResetStack = function()
	{
		undoStack = {};
		undoStack.lastControlId = undefined;
		undoStack.snapshots = [];
		undoStack.topSnapshot = undefined;
		undoStack.undoLevel = undefined;
	}

	//---------------------------------------------------------------------------
	//	Undo Handler: Undo
	//---------------------------------------------------------------------------
	var ScreenDesigner_UndoHandler_Undo = function()
	{
		//ScreenDesigner.DisplayMessageBanner("Undo.", {size:"small", position:"bottom"});
		
		if (undoStack.snapshots.length  > 0)
		{
			if (undoStack.topSnapshot == undefined)
			{
				undoStack.topSnapshot = appData.Clone();
				undoStack.undoLevel = undoStack.snapshots.length;
			}

			if (undoStack.undoLevel > 0)
			{
				undoStack.undoLevel--;
				ScreenDesigner_ApplyUndoRedo(undoStack.snapshots[undoStack.undoLevel]);
			}
		}
	}

	//---------------------------------------------------------------------------
	//	Undo Handler: Redo
	//---------------------------------------------------------------------------
	var ScreenDesigner_UndoHandler_Redo = function()
	{
		//ScreenDesigner.DisplayMessageBanner("Redo.");
		if (undoStack.topSnapshot != undefined)
		{
			undoStack.undoLevel++;

			if (undoStack.undoLevel < undoStack.snapshots.length)
			{
				ScreenDesigner_ApplyUndoRedo(undoStack.snapshots[undoStack.undoLevel]);
			}
			else
			{
				ScreenDesigner_ApplyUndoRedo(undoStack.topSnapshot);
				undoStack.undoLevel = undefined;
				undoStack.topSnapshot = undefined;
			}
		}
	}

	//---------------------------------------------------------------------------
	//	Undo Handler: ControlLostFocus
	//		Called when specific controls lose focus. This clears the tracking
	//		of controls that send continual values (while being changed) to the
	//		design. 
	//---------------------------------------------------------------------------
	var ScreenDesigner_UndoHandler_ControlLostFocus = function(evt)
	{
		undoStack.lastControlId = undefined;
	}

	//---------------------------------------------------------------------------
	//	Undo Handler: Consider Snapshot
	//		2021.03.15: controlInfo can now be a string for changes that don't
	//		come from controls. Called by _UpdateImage, which gets changes from
	//		move movements
	//---------------------------------------------------------------------------
	var ScreenDesigner_UndoHandler_ConsiderSnapshot = function(designData, controlInfo = undefined)
	{
		var undoBehavior;
		var controlId = undefined;
		
		// 2021.03.15: Determine undo behavior and controlId based on controlInfo parameter type and value
		if (controlInfo == undefined)
		{
			undoBehavior = "always";
		}
		else if (typeof(controlInfo) == "object")
		{
			undoBehavior = controlInfo.undo;
			controlId = controlInfo.id;
		}
		else if (typeof(controlInfo) == "string")
		{
			undoBehavior = "focus";
			controlId = controlInfo;
		}
		else
		{
			undoBehavior = "never";
		}
			
		if (undoBehavior == "always")
		{
			ScreenDesigner_UndoHandler_Snapshot(designData);
		}
		else if (undoBehavior == "never")
		{
			// Do nothing
		}
		else if (undoBehavior == "focus")
		{
			// Only do a snapshot once for this control until this control loses focus
			if (undoStack.lastControlId == undefined || undoStack.lastControlId != controlId)
				ScreenDesigner_UndoHandler_Snapshot(designData);
		}
		else
		{
			console.log("ScreenDesigner_UndoHandler_ConsiderSnapshot: Unexpected 'undo' indicator: '" + controlInfo + "'");
		}

		undoStack.lastControlId = controlId;
	}

	//---------------------------------------------------------------------------
	//	Undo Handler: Snapshot
	//---------------------------------------------------------------------------
	var ScreenDesigner_UndoHandler_Snapshot = function(designData)
	{
		// Clear any more recent snapshots 
		if (undoStack.topSnapshot != undefined)
		{
			if (undoStack.undoLevel != undefined)
				undoStack.snapshots.splice(undoStack.undoLevel);
			else
				console.log("ScreenDesigner_UndoHandler_Snapshot: undoLevel is undefined");
			undoStack.undoLevel = undefined;
			undoStack.topSnapshot = undefined;
		}
		
		var snapshot = designData.Clone();		
		undoStack.snapshots.push(snapshot);
		
		while (undoStack.snapshots.length > 20)
			undoStack.snapshots.shift()
		
	}

	//---------------------------------------------------------------------------
	//	...
	//---------------------------------------------------------------------------
	var ScreenDesigner_NewScreenDesignerData = function(designType = undefined)
	{
		if (designType == undefined)
		{
			designType = DesignType.TYPE_REGULAR_TILING;
			console.log("ScreenDesigner_NewScreenDesignerData called with unspecified designType");
		}
		
		var designData = new ScreenDesignerData(designType);
		
		// 2021.03.11: Reasonable defaults for image designs
		if (designType == DesignType.TYPE_IMAGE)
		{
			designData.tiling.shape = 3 /* Tiling.HEXGRID */;
			designData.tiling.size = 10;
			designData.general.fillColor = "#1010e0";
			designData.general.renderLine = false;
		}
		// 2021.05.11: Reasonable defaults for sketch designs
		else if (designType == DesignType.TYPE_GRID_SKETCH)
		{
			designData.tiling.size = 20;
			designData.general.fillColor = "#2020e0";
			designData.general.renderLine = true;
			designData.tiling.showTiles = true;
			designData.frame.width = 400;
			designData.frame.height = 400;
		}
		
		return designData;
	}

	var ScreenDesigner_DesignIsDirty = function(designData)
	{
		return designData.dirty;
	}
	

	var ScreenDesigner_CurrentDesignNeedsSaving = function()
	{
		var needsSaving = false;
		
		if (appData != undefined)
		{
			// Designs in project are saved automatically.
			if (!ScreenDesigner_StorageMgr.IsDesignInProject(appData))
				needsSaving = appData.dirty && !ScreenDesigner_Workbook.IsDesignInAppWorkbook(appData);
		}
				 
		return needsSaving;
	}

	var ScreenDesigner_CurrentDesignIsStandalone = function()
	{
		var isStandalone = false;
		
		isStandalone = (appData != undefined &&
						!ScreenDesigner_StorageMgr.IsDesignInProject(appData) &&
						!ScreenDesigner_Workbook.IsDesignInAppWorkbook(appData));
				 
		return isStandalone;
	}
	
	var ScreenDesigner_CurrentDesignIsInProject = function()
	{
		var isInProject= false;
		
		isInProject = (appData != undefined &&
						ScreenDesigner_StorageMgr.IsDesignInProject(appData));
				 
		return isInProject;
	}
	
	var ScreenDesigner_PromptForPossibleUnsavedDesignChanges = function()
	{
		// 2018.07.19: Added routine and made public so it can called from refactored code
		var continueWithEdit = true;
		
		if (ScreenDesigner_CurrentDesignNeedsSaving())
			continueWithEdit = confirm("Unsaved changes to current design will be lost.");
			
		return continueWithEdit;
	}
	
	//---------------------------------------------------------------------------
	//	Local Storage
	//		2018.07.20: No longer used; transitioning to cloud storage
	//		2019.03.11: Restoring local storage for settings not specific to a design.
	//				 	These could be store in cloud storage, but this is fine for
	//					minor things, like which tab was shown.
	//---------------------------------------------------------------------------
	var ScreenDesigner_RetrieveSettings = function()
	{
		// 2018.07.18: No longer using local storage for settings; will be using cloud 
		// storage instead
		// 2019.03.12: Restore use local settings, specifically for UI settings not specific to a design
		
		// Retrieve any persistent settings and merge the ones listed in the
		// control list with the appData 
		// 2018.01.11: Add exception error handling
		var persistentPropsStr = undefined;
		try {
			persistentPropsStr = localStorage.getItem(LocalStorage_PersistentSettings);
		}
		
		catch (error)
		{
			console.log("Polygonia -- Unable to retrieve settings: '" + error + "'");
		}
		
		if (persistentPropsStr != undefined)
		{
			var persistentProperties = JSON.parse(persistentPropsStr);
			
			/*
			// For all of the items in the control list, see if there is a stored property. 
			for (var i = 0; i < editControlList.length; i++)
			{
				var ci = editControlList[i];

				// The property must both exist in the persistentProperties object...
				if (persistentProperties[ci.cat] != undefined && persistentProperties[ci.cat][ci.prop] != undefined)
				{
					// ...and in the appData object...
					if (appData[ci.cat] != undefined && appData[ci.cat][ci.prop] != undefined)
					{
						// ...to be retained.
						appData[ci.cat][ci.prop] = persistentProperties[ci.cat][ci.prop];
					}
				}
			}
			*/
			
			if (persistentProperties["miscUI"] != undefined)
			{
				// Select the last tab viewed
				if (persistentProperties["miscUI"]["currentTab"] != undefined)
				{
					var tabId = persistentProperties["miscUI"]["currentTab"];
					ScreenDesigner_UI_SelectTab(tabId);

					// 2022.04.14: If the gallery tab is shown, then start rendering thumbnails
					if (tabId == "ID_GalleryAreaR")
						ScreenDesigner_Gallery.Revealed();
				}
				
				// Restore edit options
				if (persistentProperties["miscUI"]["editOptions"] != undefined)
					ScreenDesigner_UpdateEditOptionSettings(persistentProperties["miscUI"]["editOptions"]);
			}

			/* 
			// If there was a stored segment list, then use that.
			if (persistentProperties["elements"] != undefined)
			{
				appData.elements = persistentProperties["elements"];
				appData.ResetNextElementId();
				appData.Validate();
			}
			*/ 
		}
		
	}
	
	var ScreenDesigner_StoreSettings = function()
	{
		// Everything in the control list get stored. 
		var persistentProperties = {};
		
		/* 2019.03.11: Now that we are calling this routine again, don't store appData 
		for (var i = 0; i < editControlList.length; i++)
		{
			var ci = editControlList[i];
			if (appData[ci.cat] != undefined && appData[ci.cat][ci.prop] != undefined)
			{
				if (persistentProperties[ci.cat] == undefined)
					persistentProperties[ci.cat] = {};
					
				persistentProperties[ci.cat][ci.prop] = appData[ci.cat][ci.prop];
			}
		}
		*/
				
		// Determine current tab
		var tabs = document.getElementsByName("TabAreaGroup");
		var currentTabId = undefined;
		for (var i = 0; i < tabs.length; i++)
		{
			if (tabs[i].checked)
				currentTabId = tabs[i].id;
		}
		
		if (currentTabId != undefined)
		{
			if (persistentProperties["miscUI"] == undefined)
				persistentProperties["miscUI"] = {};
			
			persistentProperties["miscUI"]["currentTab"] = currentTabId;
		}
		
		var editOptions = ScreenDesigner_GetEditOptionSettings();
		if (editOptions != undefined)
		{
			if (persistentProperties["miscUI"] == undefined)
				persistentProperties["miscUI"] = {};
			
			persistentProperties["miscUI"]["editOptions"] = editOptions;
		}

		
		/* 2019.03.11: Now that we are calling this routine again, don't store appData 
		// Store the segment list.
		if (appData.elements.length > 0)
			persistentProperties["elements"] = appData.elements;
		*/
		
		// Put the object into storage
		// 2018.01.11: Handle failure
		try 
		{
			localStorage.setItem(LocalStorage_PersistentSettings, JSON.stringify(persistentProperties));
		}
		
		catch (error)
		{
			console.log("Polygonia -- Unable to store settings: '" + error + "'");
		}
	}
	
	//---------------------------------------------------------------------------
	//	Handle Controls
	//		Reads the updated value from a control and passes the value to
	//		a function to update the design data or update a common value in
	//		the designs in a workbook
	//---------------------------------------------------------------------------
	function ScreenDesigner_HandleControls(e)
	{
		var id = this.id; // 2021.03.25
		
		var value = undefined;
		var controlInfo = undefined;
		
		// 2021.03.25: Update paired elements with the same value.
		// Also, map the paired id to the original id
		if (this.pairedElement != undefined)
		{
			this.pairedElement.value = this.value;
			if (this.pairIsCopy)
				id = this.pairedElement.id;
		}
		
		// Iterate over the control list to find the control with the matching id
		// and use the "access" field to determine how to read the value from the
		// element
		for (var i = 0; i < editControlList.length && value == undefined; i++)
		{
			if (id === editControlList[i].id)
			{
				controlInfo = editControlList[i];
				value = this[controlInfo.access];
			}
		}
		
		// Process the value
		if (value != undefined)
		{
			// Insure numbers are numbers
			if (controlInfo.type != undefined && controlInfo.type == "float")
				value = Number(value);
			
			// DDK 2018.01.19: Workbook Common Edit
			if (appWorkbookCommonEdit && controlInfo.wbCommonEdit)
			{
				ScreenDesigner_Workbook.UpdateCommonParameter(controlInfo, value);
			}
			else
			{
				ScreenDesigner_UpdateParameter(controlInfo, value);
				ScreenDesigner_UpdateUIforParameterChange(controlInfo, value);
			}
		}
		else
		{
			console.log("ScreenDesigner_HandleControls: " + this.id + " not handled");
		}
	}

	//---------------------------------------------------------------------------
	//	API to modify the design data
	//---------------------------------------------------------------------------
	var ScreenDesigner_ModifyDesignBtnHandler = function(evt) 
	{
		if (this.id == "ID_RestoreDefaults")
		{
			// Restore the design data to its defaults, which resets
			// everything except the lines, the shape, and the size
			appData.RestoreDefaults();
			
			// Refresh the UI
			ScreenDesigner_PopulateControlValues(appData)
			ScreenDesigner_UpdateUnits(appData.general.units);
			ScreenDesigner_UpdateEditorRenderOptions();
			ScreenGenerator_Design_DataModified(1);
		}
		else if (this.id == "ID_ClearLines")
		{
			var continueWithChange = true;
		
			continueWithChange = confirm("Clearing all lines can not be undone.");
		
			if (continueWithChange)
			{
				appData.ClearLines();
				ScreenDesigner_DesignClearDirty(appData); // 2018.07.18: Use routine instead of setting directly
				
				ScreenEditorCanvas.ResetAll();			
				ScreenGenerator_Design_DataModified(1);
			}
		}
		else if (this.id == "ID_CloseGaps")
		{
			ScreenEditorCanvas.CloseGaps();			
		}
		else if (this.id == "ID_ConvertToPixels") // 2021.06.28
		{
			ScreenDesigner_ConvertDesignUnits(BasicUnits.PIXELS);
		}
		else if (this.id == "ID_ConvertToInches") // 2021.06.30
		{
			ScreenDesigner_ConvertDesignUnits(BasicUnits.INCHES);
		}
		else if (this.id == "ID_ConvertToMM") // 2021.06.30
		{
			ScreenDesigner_ConvertDesignUnits(BasicUnits.MILLIMETERS);
		}
		else if (this.id == "ID_ScaleDesign") // 2021.07.12
		{
			ScreenDesigner_HandleScaleDesign();
		}
		else if (this.id == "ID_ScaleTile") // 2021.07.14
		{
			ScreenDesigner_HandleScaleTile();
		}
		else
		{
			console.log("ScreenDesigner_ModifyDesignBtnHandler: " + this.id + " not handled");
		}
	}

	var ScreenDesigner_DesignMarkDirty = function(designData)
	{
		// 2019.10.25: This was "appData.dirty = true;" instead of
		designData.dirty = true;
	}
	
	var ScreenDesigner_DesignClearDirty = function(designData)
	{
		// 2019.10.25: This was "appData.dirty = false;" instead of
		designData.dirty = false;
	}
	
	function ScreenDesigner_MaintainAspectRatio(controlId)
	{
		// If we change the aspect ratio, then we adjust the height. 
		// We do this regardless of the "lock" setting
		if (controlId == "ID_AspectRatio")
		{
			appData.frame.height = appData.frame.width / appData.frame.aspect;
			var e = document.getElementById("ID_FrameHeight");
			e["value"] = appData.frame.height;
		}
		// If the aspect ratio is locked..
		else if (appData.frame.lockAspect)
		{
			// Adjusting the width will change the height. Note that if the control
			// hit was "lock aspect ratio", which would have set the flag, then we 
			// also adjust the height
			if (controlId == "ID_FrameWidth" || controlId == "ID_LockAspectRatio")
			{
				appData.frame.height = appData.frame.width / appData.frame.aspect;
				var e = document.getElementById("ID_FrameHeight");
				e["value"] = appData.frame.height;
			}
			// Adjusting the height will change the width
			else if (controlId == "ID_FrameHeight")
			{
				appData.frame.width = appData.frame.height * appData.frame.aspect;
				var e = document.getElementById("ID_FrameWidth");
				e["value"] = appData.frame.width;
			}
		}
		// If the aspect ratio is not locked
		else
		{
			// Then we update the aspect ratio if the width or height is changed
			if (controlId == "ID_FrameWidth" || controlId == "ID_FrameHeight")
			{
				appData.frame.aspect = appData.frame.width / appData.frame.height;
				var e = document.getElementById("ID_AspectRatio");
				e["value"] = appData.frame.aspect;
			}
		}
	}
	
	function ScreenDesigner_PossibleDiamondIsoTriangleConversion(newFrameShape)
	{
		var scaleFactor = undefined;
		
		// If going from Diamond to Isosceles Triangle, then reduce height by half
		// If going from Isosceles Triangle to Diamond, then double height
		if (appData.frame.shape == FrameShape.FRAME_DIAMOND && newFrameShape == FrameShape.FRAME_ISO_TRIANGLE)
			scaleFactor = 1/2;
		else if (appData.frame.shape == FrameShape.FRAME_ISO_TRIANGLE && newFrameShape == FrameShape.FRAME_DIAMOND)
			scaleFactor = 2;

		// Update values and UI if we have a scale factor
		if (scaleFactor != undefined)
		{
			appData.frame.height *= scaleFactor;
			appData.frame.aspect /= scaleFactor;
			
			var e = document.getElementById("ID_FrameHeight");
			e["value"] = appData.frame.height;

			var e = document.getElementById("ID_AspectRatio");
			e["value"] = appData.frame.aspect;
		}
	}

	//---------------------------------------------------------------------------
	//	Scale Design
	//		2021.07.12: Refactored from ConvertDesignUnits
	//		2021.07.14: Added support for specifying conversionList and 
	//		conversionFlags so this can be called from _ScaleTile
	//
	//	scaleSettings
	//		scaleNum
	//		scaleDem
	//		newUnits (optional)
	//---------------------------------------------------------------------------
	function ScreenDesigner_ScaleDesign(scaleSettings)
	{
		// All of the values that are dimensions (i.e, in mm, inches, or pixels) that will be scaled
		let fullConversionList = {
			general:[ "cornerSize", "lineWidth", "shadowX", "shadowY", "shadowWidth", "shadowBlur", "sketchLineWidth" ],
			frame:[ "width", "height", "radius", "border", "margin", "docWidth", "docHeight" ],
			tiling:[ "size", "segwidth", "offsetX", "offsetY"],
			drillholes:[ "dhFrameVertexSize", "dhFrameVertexOffset" ],
			image:[ "tileSize", "minEdgeDist", "minPolySize" ]//, "imgOffsetX", "imgOffsetY"
		}
		
		let fullConversionFlags = {
			lineWidths: true,
			gradients: true
		};
		
		let defaultEndpointScaling = "scaleWithTile"; // vs. "endpointsReverseScaled"

		// Scale factor is numerator/denominator
		let conversionNum = scaleSettings.scaleNum;
		let conversionDen = scaleSettings.scaleDen;

		// Use the new units, if provided, to determine precision
		let units = (scaleSettings.newUnits != undefined) ? scaleSettings.newUnits : appData.general.units;

		// Precision for the conversion depends on the units
		let precision = [];
		precision[BasicUnits.PIXELS] = 2;
		precision[BasicUnits.INCHES] = 3;
		precision[BasicUnits.MILLIMETERS] = 2;
		let conversionPrecision = precision[units];
		
		// Use the conversion list and flags if provided, otherwise use the full list and flags
		let conversionList = (scaleSettings.conversionList != undefined) ? scaleSettings.conversionList : fullConversionList;
		let conversionFlags = (scaleSettings.conversionFlags != undefined) ? scaleSettings.conversionFlags : fullConversionFlags;
		let endpointScaling = (scaleSettings.endpointScaling != undefined) ? scaleSettings.endpointScaling : defaultEndpointScaling;

		// Conversion function		
		function convertValue(oldValue, conversionNum, convertDen, precision)
		{
			let newValue = (oldValue * conversionNum)/convertDen;

			if (precision == 0)
				newValue = Math.floor(newValue);
			else if (isFinite(precision))
			{
				let pot = Math.pow(10, precision);
				newValue = Math.round(newValue * pot)/pot;
			}

			return newValue;
		}

		//console.log("Factor", conversionFactor, "  Precision", conversionPrecision);

		ScreenDesigner_PerformSnapshot();

		// Iterate of the list of groups and convert all of the values
		let keys = Object.keys(conversionList);

		for (var i = 0; i < keys.length; i++)
		{
			let group = keys[i];
			for (var j = 0; j < conversionList[group].length; j++)
			{
				let param = conversionList[group][j];
				let oldValue = appData[group][param];
				appData[group][param] = convertValue(oldValue, conversionNum, conversionDen, conversionPrecision);
			}
		}

		// Scale the line widths
		if (conversionFlags.lineWidths)
		{
			for (var i = 0; i < appData.elements.length; i++)
			{
				let oldWidth = appData.elements[i].width;
				appData.elements[i].width = convertValue(oldWidth, conversionNum, conversionDen, conversionPrecision);
			}
		}

		// The endPoints scale by default, because they are relative to the tile size.
		// Therefore, if we are NOT scaling the endpoints, then we actually have to 
		// reverse scale them
		if (endpointScaling == "endpointsReverseScaled")
		{
			let reverseNum = conversionDen;
			let reverseDen = conversionNum;

			// We need to reverse scale the points towards the center of the tile. The center
			// is different for the the different tile shapes. Also, the GetEditTileInfo function
			// returns info relative to the current tile size. We need to convert the center
			// to the the center of the line data. The line data values are in the range of 0 to 1.
			// It would probably make a lot more sense to add a "unit center" to the tessellation 
			// code for each tile shape.
			var tileInfo = ScreenGenerator.GetEditTileInfo(appData);
			let ctr = tileInfo.center;
			let sqr = tileInfo.square; // {size:sz, offset:{x:sqOffsetX, y:sqOffsetY}};
			
			ctr.x = (ctr.x - sqr.offset.x)/sqr.size;
			ctr.y = (ctr.y - sqr.offset.y)/sqr.size;

			for (var i = 0; i < appData.elements.length; i++)
			{
				appData.elements[i].ptA.x = ctr.x + convertValue(appData.elements[i].ptA.x - ctr.x, reverseNum, reverseDen);
				appData.elements[i].ptA.y = ctr.y + convertValue(appData.elements[i].ptA.y - ctr.y, reverseNum, reverseDen);
				appData.elements[i].ptB.x = ctr.x + convertValue(appData.elements[i].ptB.x - ctr.x, reverseNum, reverseDen);
				appData.elements[i].ptB.y = ctr.y + convertValue(appData.elements[i].ptB.y - ctr.y, reverseNum, reverseDen);
			}
		}

		// Scale the gradients
		if (conversionFlags.gradients)
		{
			for (var i = 0; i < appData.gradients.length; i++)
			{
				let propList = ["size", "referenceX", "referenceY"];

				propList.forEach(prop => {
					let oldValue = appData.gradients[i][prop];
					appData.gradients[i][prop] = convertValue(oldValue, conversionNum, conversionDen, conversionPrecision);
				});
			}
		}

		// If new units were provided, then set them in the design
		if (scaleSettings.newUnits != undefined)
			appData.general.units = scaleSettings.newUnits;

		// Refresh the display
		ScreenDesigner_UpdateUI();	
		ScreenDesigner_LineInfoTable_Build();
		ScreenDesigner_DesignMarkDirty(appData);
		ScreenGenerator_Design_DataModified(1);
		ScreenDesignerCanvas.ZoomToFrame();
	}


	//---------------------------------------------------------------------------
	//	Get Scale Dimension
	//		2021.07.12: Returns "scale from" and "scale to" sizes
	//---------------------------------------------------------------------------
	function ScreenDesigner_GetScaleDimension()
	{
		var e = document.getElementById("ID_ScaleDimension");
		let scaleDimension = (e != undefined) ? e.value : undefined;

		e = document.getElementById("ID_ScaleToDimension");
		let scaleToSize = (e != undefined) ? Number(e.value) : undefined;

		var scaleFromSize = undefined;

		if (scaleDimension == "radius")
			scaleFromSize = appData.frame.radius;
		else if (scaleDimension == "width")
			scaleFromSize = appData.frame.width;
		else if (scaleDimension == "height")
			scaleFromSize = appData.frame.height;
		else if (scaleDimension == "tileSize")
			scaleFromSize = appData.tiling.size;
		else if (scaleDimension == "percent")
			scaleFromSize = 100;

		//console.log(scaleDimension, scaleFromSize, typeof scaleFromSize, scaleToSize, typeof scaleToSize);

		return {scaleFrom:scaleFromSize, scaleTo:scaleToSize};
	}

	//---------------------------------------------------------------------------
	//	Update Scale Dimension
	//		2021.07.12: Populate the value into the 'scale from' dimension that
	//		corresponds to the item selected on the 'scale' menu
	//		2021.07.14: Add 'scale tile dimension'
	//---------------------------------------------------------------------------
	function ScreenDesigner_UpdateScaleDimension()
	{
		var scaleDimension = ScreenDesigner_GetScaleDimension();

		var e = document.getElementById("ID_ScaleFromDimension");
		if (e != undefined)
			e.innerHTML = scaleDimension.scaleFrom;

		var e = document.getElementById("ID_ScaleTileFromDimension");
		if (e != undefined)
			e.innerHTML = appData.tiling.size;
	}
	
	//---------------------------------------------------------------------------
	//	Handle Scale Tile
	//		2021.07.14
	//---------------------------------------------------------------------------
	function ScreenDesigner_HandleScaleTile()
	{
		function isChecked(id)
		{
			let e = document.getElementById(id);
			if (e == undefined)
				console.log("Not found: " + id);
			return (e != undefined) ? e.checked : false;
		}
		
		// Get the 'scale from' and 'scale to' values
		var e = document.getElementById("ID_ScaleTileToDimension");
		let scaleTo = (e != undefined) ? Number(e.value) : undefined;
		let scaleFrom = appData.tiling.size;
		
		// If the scale values exist, are numbers, and are not zero,
		// then scale the design
		if (scaleTo != undefined && isFinite(scaleTo) && scaleTo > 0)
		{
			let conversionList = { general:[], tiling:[ "size" ] };
			
			if (isChecked("ID_ScaleTileOffset"))
				conversionList.tiling = conversionList.tiling.concat([ "offsetX", "offsetY" ]);

			if (isChecked("ID_ScaleTileLineWidth"))
				conversionList.general.push("lineWidth");

			if (isChecked("ID_ScaleTileCornerRadius"))
				conversionList.general.push("cornerSize");

			if (isChecked("ID_ScaleTileShadows"))
				conversionList.general = conversionList.general.concat([ "shadowX", "shadowY", "shadowWidth", "shadowBlur" ]);

			let conversionFlags = {	};
			conversionFlags.lineWidths = isChecked("ID_ScaleTileWidths");
			
			// By default endPoints are scaled with tile. ("default" is not defined to 
			// do anything.)
			let endpointScaling = isChecked("ID_ScaleTileEndpoints") ? "default" : "endpointsReverseScaled";

			let scaleSettings = { scaleNum:scaleTo, scaleDen:scaleFrom, conversionList, conversionFlags, endpointScaling };
			//console.log(JSON.stringify(scaleSettings));

			ScreenDesigner_ScaleDesign(scaleSettings);
		}
		else
		{
			ScreenDesigner_DisplayMessageBanner("Not scaled.");
		}
	}
	
	//---------------------------------------------------------------------------
	//	Handle Scale Design
	//		2021.07.12
	//---------------------------------------------------------------------------
	function ScreenDesigner_HandleScaleDesign()
	{
		// Get the 'scale from' and 'scale to' values
		var scaleDimension = ScreenDesigner_GetScaleDimension();
		var scaleFrom = scaleDimension.scaleFrom;
		var scaleTo = scaleDimension.scaleTo;
		
		// If the scale values exist, are numbers, and are not zero,
		// then scale the design
		if (scaleFrom != undefined && isFinite(scaleFrom) && scaleFrom > 0 && 
			scaleTo != undefined && isFinite(scaleTo) && scaleTo > 0)
		{
			let scaleSettings = { scaleNum:scaleTo, scaleDen:scaleFrom };

			ScreenDesigner_ScaleDesign(scaleSettings);
		}
		else
		{
			ScreenDesigner_DisplayMessageBanner("Not scaled.");
		}
	}
	
	//---------------------------------------------------------------------------
	//	Set and (optional) Lock Aspect Ratio
	//		2021.08.26
	//---------------------------------------------------------------------------
	function ScreenDesigner_SetAndLockAspectRatio(aspectRatio, lockAspectRatio = undefined)
	{
		ScreenDesigner_PerformSnapshot();
		
		appData.frame.aspect = aspectRatio;
		appData.frame.height = appData.frame.width / appData.frame.aspect;
		
		if (lockAspectRatio != undefined)
			appData.frame.lockAspect = lockAspectRatio;

		// Refresh the display
		ScreenDesigner_UpdateUI();	
		ScreenDesigner_DesignMarkDirty(appData);
		ScreenGenerator_Design_DataModified(1);
	}
	
	//---------------------------------------------------------------------------
	//	Convert Design Units
	//		2021.06.28
	//---------------------------------------------------------------------------
	function ScreenDesigner_ConvertDesignUnits(newUnits = BasicUnits.PIXELS)
	{
		let currentUnits = appData.general.units;

		// Convert the design if the units are different
		if (currentUnits != newUnits)
		{
			// Tiny table of sizes
			let resolution = [];
			resolution[BasicUnits.PIXELS] = appData.general.dpi;
			resolution[BasicUnits.INCHES] = 1;
			resolution[BasicUnits.MILLIMETERS] = 25.4;

			let scaleSettings = { scaleNum:resolution[newUnits], scaleDen:resolution[currentUnits], newUnits:newUnits };

			ScreenDesigner_ScaleDesign(scaleSettings);
		}
		else
		{
			ScreenDesigner_DisplayMessageBanner("No change in design units.");
		}
	}
	
	//---------------------------------------------------------------------------
	//	Update Parameter
	//---------------------------------------------------------------------------
	function ScreenDesigner_UpdateParameter(controlInfo, value)
	{
		var forceRegen = 0;
		let prevImageMonochromeStyle = appData.image.monochromeStyle; // 2022.04.09: To determine if we need to re-map colors
		
		if (controlInfo.type != undefined && controlInfo.type == "float")
			value = Number(value);
			
		// 2021.03.30: We need to regenerate the design if renderLine is toggled for Image designs
		if ((controlInfo.id == "ID_ToggleLine" || controlInfo.id == "ID_LineColor") && appData.designType == DesignType.TYPE_IMAGE)
			forceRegen = 2;
		// 2022.04.08: Regenerate required for line width changes for image "line" designs 
		if (controlInfo.id == "ID_LineWidth" && appData.designType == DesignType.TYPE_IMAGE && (appData.image.monochromeStyle == "M" || appData.image.monochromeStyle == "N"))
			forceRegen = 2;

		// 2020.10.07: Added "system" controls
		if (controlInfo.sys != undefined)
		{
			systemSettings[controlInfo.prop] = value;
			
			if (controlInfo.prop == "selectPolygon")
				ScreenDesignerCanvas.SetProperty(controlInfo.prop, value);
			else if (controlInfo.prop == "scaleDimension")
				ScreenDesigner_UpdateScaleDimension();

			if (controlInfo.sys == "diags") // 2022.02.08: Render for diags changes
				ScreenDesignerCanvas.Render();
		}
		else if (controlInfo.cat in appData)
		{
			if (controlInfo.id == "ID_TileSize" && value < ScreenDesignLimits.MINIMUM_TILE_SIZE)
				value = ScreenDesignLimits.MINIMUM_TILE_SIZE;
			
			// 2018.08.07: Add aspect ratio support (for rectangles and diamonds)
			if (controlInfo.id == "ID_AspectRatio" && value <= ScreenDesignLimits.MINIMUM_ASPECT_RATIO)
				value = ScreenDesignLimits.MINIMUM_ASPECT_RATIO;
			else if (controlInfo.id == "ID_AspectRatio" && value >= ScreenDesignLimits.MAXIMUM_ASPECT_RATIO)
				value = ScreenDesignLimits.MAXIMUM_ASPECT_RATIO;

			// 2018.01.24: Allow optional parameters to be removed from data
			if (controlInfo.optional && value.length == 0)
				value = undefined;
				
			// 2019.01.28: Undo support
			ScreenDesigner_UndoHandler_ConsiderSnapshot(appData, controlInfo);
			
			// 2019.02.19: If we are switching between Diamond and Isosceles Triangle or vice versa, then 
			// adjust the height and aspect ratio so that going from Diamond to IsoTriangle gives us a triangle
			// that is half of the diamond 
			if (controlInfo.id == "ID_FrameShape")
				ScreenDesigner_PossibleDiamondIsoTriangleConversion(value);
			
			appData[controlInfo.cat][controlInfo.prop] = value;

			// 2019.03.04: Moved from ScreenDesigner_UpdateUIforParameterChange to here, as this appears
			// to be a more appropriate place.
			// Only update the dirty flag if the control data modifies the data
			// Some controls will, but they hit the flag via another call (typically UpdateLine)
			if (controlInfo["noChange"] == undefined)
				ScreenDesigner_DesignMarkDirty(appData); // 2018.07.18: Use routine instead of setting directly
			
			// 2018.08.07: Calculate related values if aspect ratio is locked
			var aspectRatioControlList = ["ID_AspectRatio", "ID_LockAspectRatio", "ID_FrameHeight", "ID_FrameWidth"];
			if (aspectRatioControlList.includes(controlInfo.id) )
				ScreenDesigner_MaintainAspectRatio(controlInfo.id);

			//console.log(controlInfo.cat + "." + controlInfo.prop + "=" + appData[controlInfo.cat][controlInfo.prop] + ", type:" + typeof(appData[controlInfo.cat][controlInfo.prop]));

			// 2021.03.29: Let the image canvas know that we changed the zoom.
			// The image canvas will redraw and will ask the Screen Designer to reload the image data.
			if (controlInfo.id == "ID_ImgZoom")
			{
				ScreenImageCanvas.SetProperty(controlInfo.prop, value);
			}

			// 2022.04.08: Re-assign fill, edge, and background colors  in a reasonable way for specific changes between image processing settings
			let imageProcessingControls = ["ID_ImgProcessLight", "ID_ImgProcessDark", "ID_ImgProcessLightLn", "ID_ImgProcessDarkLn"];
			if (imageProcessingControls.includes(controlInfo.id) && appData.designType == DesignType.TYPE_IMAGE)
				ScreenDesigner_ReassignColorsForImageProcessingChange(prevImageMonochromeStyle)

			if ("regen" in controlInfo || forceRegen)
			{
				let regen = forceRegen ? forceRegen : controlInfo.regen;
				ScreenGenerator_Design_DataModified(regen);
			}
			
			if ("redraw" in controlInfo && !forceRegen)
			{
				ScreenDesignerCanvas.Render();
				ScreenGenerator_Design_DataModified(5);
			}
			
			if ("editor" in controlInfo)
			{
				ScreenDesigner_UpdateEditorRenderOptions();
				ScreenEditorCanvas.Render();
			}
			
			// 2020.08.18: Added 'regenTile' to control list to replace individual ID comparisons
			if ("regenTile" in controlInfo)
			{
				// Maybe....should go somewhere else...
				var editTileInfo = ScreenGenerator.GetEditTileInfo(appData, undefined);
				ScreenEditorCanvas.SetEditTileInfo(editTileInfo);
				ScreenEditorCanvas.Render();
				ScreenDesigner_UpdateTileDimensions();
			}

			if (controlInfo.id == "ID_FrameRender")
			{
				// Tell the display that the frame has only one side
				ScreenDesignerCanvas.Render();
			}
		}
		else
		{
			console.log("ScreenDesigner_UpdateParameter: '" + controlInfo.cat + "' not found in appData");
		}
	}
	
	//---------------------------------------------------------------------------
	//	Reassign Colors For Image Processing Change
	//		2022.04.09: Re-assign the foreground color, line color, and background
	//		color so that processed image is still correct
	//---------------------------------------------------------------------------
	var ScreenDesigner_ReassignColorsForImageProcessingChange = function(prevImageMonochromeStyle)
	{
		// L: 'Light polygons [BACKGROUND] on dark [FILL]'
		// D: 'Dark polygons [BACKGROUND] on light [FILL]'
		// N: 'Light lines [EDGE] on dark [BACKGROUND]'
		// M: 'Dark lines [EDGE] on light [BACKGROUND]'
		
		// PREV:   fill  edge  back	
		
		// L -> D: back  ....  fill
		// L -> N: edge  back  fill
		// L -> M: edge  fill  ....

		// D -> L: back  ....  fill
		// D -> N: edge  fill  ....
		// D -> M: edge  back  fill
		
		// N -> L: back  fill  edge
		// N -> D: edge  fill  ....
		// N -> M: ....  back  edge
		
		// M -> L: edge  fill  ....
		// M -> D: back  fill  edge
		// M -> N: ....  back  edge
		
		// Color re-assignment table. Note that the capital letters are the values in the
		// design that represent the different image processing modes available
		let m = [];
		m["LD"] = [ "backColor",	"",				"fillColor" ];
		m["LN"] = [ "lineColor",	"backColor",	"fillColor" ];
		m["LM"] = [ "lineColor",	"fillColor",	""			];
		
		m["DL"] = [ "backColor",	"",				"fillColor"	];
		m["DN"] = [ "lineColor",	"fillColor",	""			];
		m["DM"] = [ "lineColor",	"backColor",	"fillColor" ];
		
		m["NL"] = [ "backColor",	"",				"fillColor"	];
		m["ND"] = [ "lineColor",	"fillColor",	""			];
		m["NM"] = [ "",				"backColor",	"lineColor" ];
		
		m["ML"] = [ "lineColor",	"fillColor",	""			];
		m["MD"] = [ "backColor",	"fillColor",	"lineColor"	];
		m["MN"] = [ "",				"backColor",	"lineColor" ];
		
		// Copy the current colors
		let c = { fillColor: appData.general.fillColor, lineColor: appData.general.lineColor, backColor: appData.general.backColor };
		// Build the key by concatenating the previous and the current image processing mode
		let e = prevImageMonochromeStyle + appData.image.monochromeStyle;
		// Get the re-assigns, if it exists
		let s = m[e];

		if (s != undefined)
		{
			if (s[0] != "") // New fill color
			{
				appData.general.fillColor = c[s[0]];
				appData.general.renderFill = true;
			}
			if (s[1] != "") // New line color
			{
				appData.general.lineColor = c[s[1]];
			}
			if (s[2] != "") // New back color
			{
				appData.general.backColor = c[s[2]];
				appData.general.renderBack = true;
			}

			ScreenDesigner_PopulateControlsByIdList(appData, ["ID_FillColor", "ID_LineColor", "ID_BackColor"])
		}
	}
	
	var ScreenDesigner_UpdateLine = function(elementId, lineInfo)
	{
		var found = 0;
		for (var i = 0; i < appData.elements.length; i++)
		{
			if (appData.elements[i].id == elementId)
			{
				found++;
				appData.elements[i] = lineInfo;
				appData.elements[i].id = elementId;
				ScreenDesigner_DesignMarkDirty(appData); // 2018.07.18: Use routine instead of setting directly	
				ScreenDesigner_LineInfoTable_UpdateLine({lineIdx:i}); // 2019.09.20: Added
			}
		}
		
		ScreenGenerator_Design_DataModified(1);
		
		if (found == 0)
			console.log("ScreenDesigner_UpdateLine: id: " + elementId + " not found");
		else if (found > 1)
			console.log("ScreenDesigner_UpdateLine: id: " + elementId + " found " + found + " times");
	}
	
	var ScreenDesigner_UpdateLineProperty = function(elementId, propertyName, propertyValue)
	{
		var found = 0;
		for (var i = 0; i < appData.elements.length; i++)
		{
			if (appData.elements[i].id == elementId)
			{
				found++;
				appData.elements[i][propertyName] = propertyValue;
				ScreenDesigner_DesignMarkDirty(appData); // 2018.07.18: Use routine instead of setting directly	
				ScreenDesigner_LineInfoTable_UpdateLine({lineIdx:i}); // 2019.09.20: Added
			}
		}
		
		ScreenGenerator_Design_DataModified(1);
		
		if (found == 0)
			console.log("ScreenDesigner_UpdateLineProperty: id: " + elementId + " not found");
		else if (found > 1)
			console.log("ScreenDesigner_UpdateLineProperty: id: " + elementId + " found " + found + " times");
	}
	
	var ScreenDesigner_UpdateLineEditState = function(elementId, lineStateInfo)
	{
		for (var i = 0; i < appData.elements.length; i++)
		{
			if (appData.elements[i].id == elementId)
			{
				ScreenDesigner_LineInfoTable_UpdateEditState(i, lineStateInfo)
			}
		}
	}
		
	var ScreenDesigner_AddLine = function(lineInfo)
	{
		var elementId = appData.nextElementId;
		appData.nextElementId++;
		lineInfo.id = elementId;
		appData.elements.push(lineInfo);
		ScreenDesigner_DesignMarkDirty(appData); // 2018.07.18: Use routine instead of setting directly
		appData.Validate();
		
		//console.log(JSON.stringify(lineInfo));
		
		ScreenGenerator_Design_DataModified(1);
		
		// 2020.08.25: Added
		var options = undefined;
		if (appData.tiling.useLineColors)
			options = {showColorPickers:true}
			
		// 2019.09.20: Added
		ScreenDesigner_LineInfoTable_AppendLine(options);
		
		return elementId;
	}
	
	var ScreenDesigner_DeleteLine = function(elementId)
	{
		var found = 0;
		for (var i = appData.elements.length - 1; i >= 0; i--)
		{
			if (appData.elements[i].id == elementId)
			{
				found++;
				appData.elements.splice(i, 1);
				// 2019.09.20: Added
				ScreenDesigner_LineInfoTable_DeleteLine(i + 1);
				ScreenDesigner_DesignMarkDirty(appData); // 2018.07.18: Use routine instead of setting directly	
			}
		}
		
		ScreenGenerator_Design_DataModified(1);

		if (found == 0)
			console.log("ScreenDesigner_DeleteLine: id: " + elementId + " not found");
		else if (found > 1)
			console.log("ScreenDesigner_DeleteLine: id: " + elementId + " found " + found + " times");
	}

	//---------------------------------------------------------------------------
	//	Update Image
	//---------------------------------------------------------------------------
	var ScreenDesigner_UpdateImage = function(imageSettings)
	{
		// 2021.03.23: Snapshot before updating the appData
		ScreenDesigner_UndoHandler_ConsiderSnapshot(appData, "UpdateImage"); // 2021.03.15
		
		appData.imageData = ScreenImageCanvas.GetImageData();

		appData.processedData = ScreenImageCanvas.GetProcessedData(); // 2021.03.23
		
		appData.image.imgOffsetX = imageSettings.offset.x;
		appData.image.imgOffsetY = imageSettings.offset.y;
		appData.image.imgZoom = imageSettings.scale;

		ScreenDesigner_PopulateControlsByIdList(appData, ["ID_ImgZoom"]); // 2021.03.29

		ScreenGenerator_Design_DataModified(2);
		
		ScreenDesigner_DesignMarkDirty(appData); // 2021.03.12
	}
	
	//---------------------------------------------------------------------------
	//	Reload Image Data
	//		2021.03.29: Added. Called by Image canvas in response to settings
	//		changes originating from Screen Designer (this class).
	//---------------------------------------------------------------------------
	var ScreenDesigner_ReloadImageData = function()
	{
		appData.imageData = ScreenImageCanvas.GetImageData();
		appData.processedData = ScreenImageCanvas.GetProcessedData();
	}


	//---------------------------------------------------------------------------
	//	Set Image Data
	//---------------------------------------------------------------------------
	var ScreenDesigner_SetImageData = function(imageDataURL)
	{
		// Doesn't work...might need to clear "imageData"...ScreenDesigner_UndoHandler_ConsiderSnapshot(appData, "SetImageData"); // 2021.03.23
		
		appData.imageDataURL = imageDataURL;
		
		ScreenGenerator_Design_DataModified(2);
		
		ScreenDesigner_DesignMarkDirty(appData);
	}
	
	//---------------------------------------------------------------------------
	//	Image Load Complete
	//---------------------------------------------------------------------------
	var ScreenDesigner_ImageLoadComplete = function()
	{
		// The image data has been completely loaded into the image element.
		appData.imageData = ScreenImageCanvas.GetImageData();
		ScreenDesigner_RenderRequest.RenderFullDesign(appDesignRender, false /* phase delay */);
	}
	
	//---------------------------------------------------------------------------
	//	Reset Image Adjustments
	//		2021.03.25: Added; Resets contrast and brightness
	//		2021.03.30: Added reset for zoom; added propertySet parameter
	//---------------------------------------------------------------------------
	var ScreenDesigner_ResetImageAdjustments = function(propertySet)
	{
		ScreenDesigner_PerformSnapshot();
		
		if (propertySet == "adjustments")
		{
			appData.image.brightness = 0;
			appData.image.contrast = 0;
			appData.image.posterize = false;
			appData.image.posterizeLevels = 8;
		}
		else if (propertySet == "zoom")
		{
			ScreenImageCanvas.SetProperty("imgZoom", "reset");
			
			let imageSettings = ScreenImageCanvas.GetImageSettings();
			appData.image.imgOffsetX = imageSettings.offset.x;
			appData.image.imgOffsetY = imageSettings.offset.y;
			appData.image.imgZoom = imageSettings.scale;
		}
		else
		{
			console.log("ScreenDesigner_ResetImageAdjustments: not handled: '" + propertySet + "'");
		}

		ScreenDesigner_DesignMarkDirty(appData);
		ScreenDesignerCanvas.Render();
		ScreenGenerator_Design_DataModified(2);
		ScreenDesigner_PopulateControlValues(appData);
	}
	
	//---------------------------------------------------------------------------
	//	Line Info Table constants
	//		Note: The ColType table is a duplicate of the same table in ScreenEditor.js
	//---------------------------------------------------------------------------
	var ColumnTypeStrings = Object.freeze([
		"Space",
		"Radio",
		"RightNum",
		"RightDec",
		"MultiVal",
		"Selected",
		"Color",
		"BitField"
	]);
	
	let emptyCircle = String.fromCharCode(9675); // 9711, 
	let fullCircle  = String.fromCharCode(9679); // 11044, 
	let halfCircle  = "<span style='font-size:8px; line-height: 10px; vertical-align: 2px'>" + String.fromCharCode(9680) + "</span>"; 
	let emptySquare = String.fromCharCode(9633);
	let fullSquare  = String.fromCharCode(9632); // 9608, 
	
	//---------------------------------------------------------------------------
	//	Build Line Info Table
	//		2019.09.19: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_Build = function()
	{
		var columnInfo = ScreenDesigner.GetLineInfoColumns();
		var container = document.getElementById("ID_LineInfoContainer");
		
		// Add handlers for action in container (div), but not in table
		// (Note that we add these every time we rebuild this table, but the spec says it is safe.)
		container.addEventListener("mouseenter", ScreenDesigner_LineInfoTable_Container_MouseEnter);
		container.addEventListener("click", ScreenDesigner_LineInfoTable_Container_Click);
		
		// Clear previous table
		container.innerHTML = "";
		
		// Create elements and append
		var table = document.createElement('table');
		var tbody = document.createElement('tbody');
		var thead = document.createElement('thead');
		var colgr = document.createElement('colgroup');
		table.appendChild(thead);
		table.appendChild(colgr);
		table.appendChild(tbody);

		// Populate the headings
		thead.insertRow(0);
		for (var columnIdx = 0; columnIdx < columnInfo.length; columnIdx++)
		{
			let colType = columnInfo[columnIdx].colType;
			thead.rows[0].insertCell(columnIdx);
			var col = thead.rows[0].cells[columnIdx];

			if (columnInfo[columnIdx].desc != undefined)
				col.title = columnInfo[columnIdx].desc;

			col.classList.add("CL_LineInfoTable_Heading");
			col.classList.add("CL_LineInfoTable_Heading-" + ColumnTypeStrings[colType]);

			var str = (columnInfo[columnIdx].label != undefined) ? columnInfo[columnIdx].label : "";
			col.appendChild(document.createTextNode(str));
		}
		
		// Populate the column in the column group
		for (var columnIdx = 0; columnIdx < columnInfo.length; columnIdx++)
		{
			let colType = columnInfo[columnIdx].colType;
			var col = document.createElement('col');
			col.classList.add("CL_LineInfoTable_Column");
			col.classList.add("CL_LineInfoTable_Column-" + ColumnTypeStrings[colType]);
			colgr.appendChild(col);
		}


		//table.createCaption();
		//table.caption.appendChild(document.createTextNode('A DOM-generated Table'));

		container.appendChild(table);	

		// 2020.08.25: Added
		var options = undefined;
		if (appData.tiling.useLineColors)
			options = {showColorPickers:true}
			
			
		// Populate the rows
		for (var lineIdx = 0; lineIdx < appData.elements.length; lineIdx++)
			ScreenDesigner_LineInfoTable_AppendLine(options);
	}
	
	//---------------------------------------------------------------------------
	//	Line Info Table: Container MouseEnter
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_Container_MouseEnter = function(evt)
	{
		// Clear the "hover" state if we move off of the line info table.
		// This should be redundant, since the mouseLeave function on the
		// table rows should handle this, but I have seen some cases where
		// that is not called.
		ScreenEditorCanvas.ClearMouseOver();
	}
	
	//---------------------------------------------------------------------------
	//	Line Info Table: Container Click 
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_Container_Click = function(evt)
	{
		// Use the SelectLine to unselect all lines.
		// (It's not pretty, but it was a quick implementation.)
		ScreenEditorCanvas.SelectLine("");
	}
	

	//---------------------------------------------------------------------------
	//	Line Info Table: Append Line Info
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_AppendLine = function(options = undefined)
	{
		var columnInfo = ScreenDesigner.GetLineInfoColumns();
		var container = document.getElementById("ID_LineInfoContainer");
		var tbody = container.getElementsByTagName("tbody")[0];
		
		// Add the row
		tbody.insertRow();
		var row = tbody.rows[tbody.rows.length - 1];
		row.tabIndex = 0;
		
		row.addEventListener("mouseenter",	evt => ScreenDesigner_LineInfoTable_RowHandler(evt, "mouseenter"),   false);
		row.addEventListener("mousemove",	evt => ScreenDesigner_LineInfoTable_RowHandler(evt, "mousemove"),    false); // 2022.03.09
		row.addEventListener("mouseleave",	evt => ScreenDesigner_LineInfoTable_RowHandler(evt, "mouseleave"),   false);
		row.addEventListener("keydown",		evt => ScreenDesigner_LineInfoTable_RowHandler(evt, "keydown"   ),   false);
		
		// Add the columns
		for (var columnIdx = 0; columnIdx < columnInfo.length; columnIdx++)
		{
			let colType = columnInfo[columnIdx].colType;
			
			row.insertCell(columnIdx);
			var col = row.cells[columnIdx];
			
			col.classList.add("CL_LineInfoTable_Cell");
			col.classList.add("CL_LineInfoTable_Cell-" + ColumnTypeStrings[colType]);

			let colIdx = columnIdx; // For proper scope
			col.addEventListener("click", evt => ScreenDesigner_LineInfoTable_CellHandler(evt, "click", colIdx),   false);
			
			// 2020.08.25: Added
			if (colType == ColType.COLOR && (options != undefined && options.showColorPickers))
			{
				let pm = document.createElement("palettemenu");
				pm.classList.add("palettemenu-small");
				PaletteMenu.Connect(pm);
				pm.paletteInfo = ScreenDesigner_ColorPalette_Get;
				pm.addEventListener("change", evt => ScreenDesigner_LineInfoTable_CellHandler(evt, "change", colIdx),   false);
				col.appendChild(pm)
			}
		}

		// Populate the columns
		ScreenDesigner_LineInfoTable_UpdateLine({row:row});
	}

	//---------------------------------------------------------------------------
	//	Update Line Info
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_UpdateLine = function(lineInfo)
	{
		var row = undefined;
		var lineIdx = undefined;
		
		try {
			// "lineInfo" should either contain a line index or a row object
			if (lineInfo.row != undefined)
			{
				row = lineInfo.row;
				lineIdx = row.rowIndex - 1; // "-1" because of the header
			}
			else if (lineInfo.lineIdx != undefined)
			{
				lineIdx = lineInfo.lineIdx;
				var container = document.getElementById("ID_LineInfoContainer");
				var tbody = container.getElementsByTagName("tbody")[0];
				if (lineIdx >= 0 && lineIdx < tbody.rows.length)
					row = tbody.rows[lineIdx];
				else
					console.log("ScreenDesigner_LineInfoTable_UpdateLine: lineInfo.lineIdx out of range", JSON.stringify(lineInfo));
			}

			if (row != undefined && lineIdx != undefined)
			{		
				// Get the data and populate the columns
				var info = ScreenEditorCanvas.GetFormattedLineInfo(appData.elements, lineIdx);
				var columnInfo = ScreenDesigner.GetLineInfoColumns();
				
				// 2019.10.10: Show 'ignored' lines in gray
				if (info["ignored"])
					row.style.color = "#808080";
				else
					row.style.color = null;
		
				for (var columnIdx = 0; columnIdx < columnInfo.length; columnIdx++)
				{
					let colType = columnInfo[columnIdx].colType;
					let prop    = columnInfo[columnIdx].prop;
			
					var col = row.cells[columnIdx];
			
					var str = "";
					if (colType == ColType.RIGHT_DEC || colType == ColType.RIGHT_NUM || colType == ColType.BIT_FIELD)
						str = info[prop];
					else if (colType == ColType.RADIO)
						str = info[prop] ? fullCircle : emptyCircle;
					else if (colType == ColType.MULTI_VAL)
						str = info[prop] ? (info[prop] == 1 ? fullCircle : info[prop] ) : emptyCircle; // 2020.08.31: Use value instead of half-circle
					else if (colType == ColType.SELECTED)
						str = info[prop].selected ? fullSquare : emptySquare;
					else if (colType == ColType.SPACE)
						str = "";
					else if (colType == ColType.COLOR)
						str = info[prop];
					else
						str = "??";
			
					if (colType != ColType.COLOR)
					{
						col.innerHTML = str;
					}
					else
					{
						let pm = col.getElementsByTagName("palettemenu");
						let colorId = info[prop]
						let colorInfo = ScreenDesigner_ColorPalette_GetColorInfoById(colorId);

						if (pm.length > 0)
							PaletteMenu.SetColorInfo(pm[0], colorInfo);
					}
					// 2022.02.09: Indicate which columns should highlight the endpoints
					if (columnInfo[columnIdx].hover != undefined)
						col.dataset.hover = columnInfo[columnIdx].hover;

					// 2022.02.17: Primary to handle arrow key presses when the mouse is over the column
					col.dataset.columnidx = columnIdx;
				}
			}
		}
		catch (err) {
			console.log("ScreenDesigner_LineInfoTable_UpdateLine error", JSON.stringify(lineInfo));
		}
		
	}
	
	//---------------------------------------------------------------------------
	//	Update Palette Entry
	//		Update the color for all line info rows with this palette entry
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_UpdatePaletteEntry = function(colorId, color)
	{
		try {
			// Get the column index for the colorId column
			let columnInfo = ScreenDesigner.GetLineInfoColumns();
			let colorIdColumnIdx = columnInfo.findIndex(info => info.prop == "colorId");

			// 2022.02.08: The color column might not be displayed.
			if (colorIdColumnIdx != -1)
			{
				// Get the rows of the table
				let container = document.getElementById("ID_LineInfoContainer");
				let tbody = container.getElementsByTagName("tbody")[0];
				let trows = tbody.rows;
			
				for (var lineIdx = 1; lineIdx < trows.length; lineIdx++)
				{
					var row = trows[lineIdx];
					var col = row.cells[colorIdColumnIdx];
					let pm = col.getElementsByTagName("palettemenu");
				
					if (pm.length > 0)
						PaletteMenu.UpdateMatchingColorInfo(pm[0], colorId, color);
				}
			}
		}
		catch (err) {
			console.log("ScreenDesigner_LineInfoTable_UpdateLine error");
			console.error(err);
		}
	}

	//---------------------------------------------------------------------------
	//	Update Edit Info
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_UpdateEditState = function(lineIdx, editState)
	{
		try {
			// Get the data and populate the columns
			var columnInfo = ScreenDesigner.GetLineInfoColumns();
		
			// Get the row
			var container = document.getElementById("ID_LineInfoContainer");
			var tbody = container.getElementsByTagName("tbody")[0];
		
			if (tbody == undefined || tbody.rows == undefined || tbody.rows.length < lineIdx || lineIdx < 0)
			{
				console.log("ScreenDesigner_LineInfoTable_UpdateEditState: missing tbody, tbody.rows, or tbody.rows is too short, lineIdx:" + lineIdx);
			}
			else
			{
				var row = tbody.rows[lineIdx];
		
				if (editState.mouseOver)
					row.classList.add("CL_LineInfoTable-MouseOver");
				else
					row.classList.remove("CL_LineInfoTable-MouseOver");
			
		
				for (var columnIdx = 0; columnIdx < columnInfo.length; columnIdx++)
				{
					let prop    = columnInfo[columnIdx].prop;

					if (prop == "ptAselected" || prop == "ptBselected")
					{			
						var str = "";
				
						if (prop == "ptAselected")
							str = editState.ptAselected ? fullSquare : emptySquare;
						else if (prop == "ptBselected")
							str = editState.ptBselected ? fullSquare : emptySquare;
			
						row.cells[columnIdx].innerHTML = str;
					}
				}
			}
		}
		catch (err) {
			console.log("ScreenDesigner_LineInfoTable_UpdateEditState error", err);
			console.log(err);
		}
		
	}
	
	//---------------------------------------------------------------------------
	//	Line Info Table: Delete Line
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_DeleteLine = function(lineIdx)
	{
		var container = document.getElementById("ID_LineInfoContainer");
		var table = container.getElementsByTagName("table")[0];
		
		table.deleteRow(lineIdx);
	}
	
	//---------------------------------------------------------------------------
	//	Line Info Table: Row Handler
	//---------------------------------------------------------------------------
	var sdLineInfoTable_EditInProgress = undefined;
	var sdLineInfoTable_EditInfo = {};
	var sdLineInfoTable_ColumnOver = undefined; // 2022.02.09

	var ScreenDesigner_LineInfoTable_RowHandler = function(evt, action)
	{
		var lineIdx = evt.currentTarget.rowIndex - 1;
		
		try {
			// 2022.02.09: Get flags indicating if we should should mouse over highlights for the endpoints
			let cell = (isFinite(evt.clientX) && isFinite(evt.clientY)) ? document.elementFromPoint(evt.clientX, evt.clientY) : undefined;
			let hover = (cell != undefined) ? cell.dataset.hover : "";
			let ptAmouseOver = (hover == "ptA") ? true : undefined;
			let ptBmouseOver = (hover == "ptB") ? true : undefined;

			if (action == "mouseenter")
			{
				ScreenEditorCanvas.SetLineEditState(lineIdx, {mouseOver: true, ptAmouseOver, ptBmouseOver});
				if (sdLineInfoTable_EditInProgress == undefined)
					evt.currentTarget.focus();
				sdLineInfoTable_ColumnOver = cell; // 2022.02.09
			}
			else if (action == "mouseleave")
			{
				ScreenEditorCanvas.SetLineEditState(lineIdx, {mouseOver: false});
				if (sdLineInfoTable_EditInProgress == undefined)
					evt.currentTarget.blur();
				sdLineInfoTable_ColumnOver = undefined; // 2022.02.09
			}
			else if (action == "mousemove") // 2022.02.09
			{
				if (sdLineInfoTable_ColumnOver != cell)
				{
					ScreenEditorCanvas.SetLineEditState(lineIdx, {mouseOver: true, ptAmouseOver, ptBmouseOver});
					sdLineInfoTable_ColumnOver = cell;
				}
			}
			else if (action == "keydown")
			{
				let columnInfo = ScreenDesigner.GetLineInfoColumns();
				let key = evt.key; // String.fromCharCode(evt.keyCode).toLowerCase()
				let columnInfoLine = columnInfo.find(e => (e.key == key || e.key == key.toLowerCase()));
			
				if (columnInfoLine != undefined && !evt.ctrlKey && !evt.metaKey && !evt.altKey)
					ScreenEditorCanvas.ToggleLineProperty(lineIdx, columnInfoLine.prop, evt.shiftKey);
				// 2022.02.17: Process arrow keys over any column
				else if ((sdLineInfoTable_ColumnOver != undefined) && (evt.key == "ArrowUp" || evt.key == "ArrowDown" || evt.key == "ArrowLeft" || evt.key == "ArrowRight"))
				{
					let columnIdx = sdLineInfoTable_ColumnOver.dataset.columnidx;
					if (columnIdx != undefined)
					{
						ScreenEditorCanvas.HandleArrow(evt, {overColumn:columnIdx});
						ScreenEditorCanvas.Render();
						evt.preventDefault();
					}
				}
			}	
		}
		catch (error) {
			console.log("ScreenDesigner_LineInfoTable_RowHandler error");
			console.log(error);
		}		
	}
	
	//---------------------------------------------------------------------------
	//	Line Info Table: Cell Handler
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_CellHandler = function(evt, action, columnIdx)
	{
		try {
			var columnInfo = ScreenDesigner.GetLineInfoColumns();
			let colType = columnInfo[columnIdx].colType;
			let prop = columnInfo[columnIdx].prop;
			
			let lineIdx = evt.currentTarget.parentNode.rowIndex - 1; // "-1" to account for the header
		
			if (action == "click")
			{
				if (prop == "width")
				{
					ScreenDesigner_LineInfoTable_EditCell(lineIdx, columnIdx, prop);
				}
				else if (colType == ColType.RADIO || colType == ColType.MULTI_VAL || colType == ColType.SELECTED)
				{
					ScreenEditorCanvas.ToggleLineProperty(lineIdx, columnInfo[columnIdx].prop);
				}
				else if (colType == ColType.COLOR)
				{
					// Don't expect to be here
				}
				else if (prop == "rotate" || prop == "reflect")
				{
					ScreenEditorCanvas.ToggleLineProperty(lineIdx, columnInfo[columnIdx].prop);
				}
				else
				{
					ScreenEditorCanvas.SelectLine(lineIdx, evt.shiftKey);
				}
			}
			else if (action == "change")
			{
				if (colType == ColType.COLOR)
				{
					// paletteEntry: {color, colorId}
					// colorId may be undefined, which indicates "clear color"
					let paletteEntry = evt.target.paletteEntry;
					// The palettemenu is in a cell, so we need to back-up two levels to get to the row
					let lineIdx = evt.target.parentNode.parentNode.rowIndex - 1; // "-1" to account for the header
					let colorId = (paletteEntry != undefined) ? paletteEntry.colorId : undefined;
					ScreenEditorCanvas.UpdateLineProperty(lineIdx, columnInfo[columnIdx].prop, colorId);
				}
			}
		}
		catch (error) {
			console.log("ScreenDesigner_LineInfoTable_CellHandler error");
			console.log(error);
		}
		
		evt.stopPropagation();
		evt.preventDefault();
	}

	//---------------------------------------------------------------------------
	//	Line Info Table: Edit Cell
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_EditCell = function(lineIdx, columnIdx, prop)
	{
		// Get the row
		let container = document.getElementById("ID_LineInfoContainer");
		let tbody = container.getElementsByTagName("tbody")[0];
		let row = tbody.rows[lineIdx];
		let cell = row.cells[columnIdx];
		
		if (cell != undefined || (sdLineInfoTable_EditInProgress != undefined && sdLineInfoTable_EditInProgress.parentNode != cell))
		{
			// If there is already a cell being edited, then accept the edit
			ScreenDesigner_LineInfoTable_AcceptCellEdit();
			
			// Get the current value from the cell (before replacing it with an edit field)
			var val = cell.innerHTML;
			cell.innerHTML = "";
			
			// Create an input field and set the behavior
			var input = document.createElement("input");
			input.type = "number";
			input.step = "0.1";
			input.classList.add("CL_LineInfoTable_Input");
			
			// Set the value we copied from the cell
			input.value = val;
			
			// Place it in the cell
			cell.appendChild(input);
			
			// Focus the cell and add handlers
			input.focus();
			input.addEventListener("blur", ScreenDesigner_LineInfoTable_AcceptCellEdit);
			input.addEventListener("keydown", ScreenDesigner_LineInfoTable_KeyHandler);
			input.addEventListener("click", evt => evt.stopPropagation());
			
			// Track that we are editing a cell
			sdLineInfoTable_EditInProgress = input;
			sdLineInfoTable_EditInfo.undoVal = val;
			sdLineInfoTable_EditInfo.prop    = prop;
			sdLineInfoTable_EditInfo.lineIdx = lineIdx;
		}
	}	
	
	//---------------------------------------------------------------------------
	//	Line Info Table: Key Handler
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_KeyHandler = function(evt)
	{
		if (evt.code == "Enter" || evt.code == "Esc" || evt.code == "Escape")
		{
			if (evt.code == "Esc" || evt.code == "Escape")
				evt.currentTarget.value = sdLineInfoTable_EditInfo.undoVal;
			evt.preventDefault();
			evt.currentTarget.blur();
		}
	}
	
	//---------------------------------------------------------------------------
	//	Line Info Table: Accept Cell Edit
	//---------------------------------------------------------------------------
	var ScreenDesigner_LineInfoTable_AcceptCellEdit = function()
	{
		if (sdLineInfoTable_EditInProgress != undefined)
		{
			// Replace the input field in the cell with the value in the input field
			var val = sdLineInfoTable_EditInProgress.value;
			var editedCell = sdLineInfoTable_EditInProgress.parentNode;
			// 2019.10.11: If the field was cleared use the undo value. Otherwise 
			// convert the value a number. If we can't convert, then use the undo value
			if (val == "")
				val = sdLineInfoTable_EditInfo.undoVal;
			else
			{
				val = Number(val);
				if (Number.isNaN(val))
					val = sdLineInfoTable_EditInfo.undoVal;
			}
			editedCell.innerHTML = val;
			sdLineInfoTable_EditInProgress = undefined;
			
			// Update the data
			ScreenEditorCanvas.UpdateLineProperty(sdLineInfoTable_EditInfo.lineIdx, sdLineInfoTable_EditInfo.prop, val);
		}
	}

	//---------------------------------------------------------------------------
	//	Color Palette: Build
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_Build = function(options = undefined)
	{
		var container = document.getElementById("ID_ColorPalette");
		
		container.innerHTML = "";
		
		if (appData.colors != undefined)
		{
			for (var i = 0; i < appData.colors.length; i++)
			{
				let colorInfo = appData.colors[i];
				
				let cp = document.createElement("colorpicker");
				cp.classList.add("colorpicker-palette");
				cp.setAttribute("value", colorInfo.color);
				ColorPicker.Connect(cp);
				cp.addEventListener("change", evt => ScreenDesigner_ColorPalette_ColorHandler(evt, "change", colorInfo.colorId), false);
				container.appendChild(cp)

			}
		}
		
		var br = document.createElement("br");
		container.appendChild(br);
		
		let addBtn = document.createElement("button");
		addBtn.innerHTML = "Add..";
		addBtn.addEventListener("click", ScreenDesigner_ColorPalette_Add);
		container.appendChild(addBtn);
	}

	//---------------------------------------------------------------------------
	//	Color Palette: Add
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_Add = function()
	{
		var colorInfo = {colorId:0, color:"#808080"};
		
		if (appData.colors == undefined)
		{
			colorInfo.colorId =  1;
			appData.colors = [colorInfo];
		}
		
		else
		{
			let maxColorId = appData.colors.reduce((maxColorId, colorInfo) => Math.max(maxColorId, colorInfo.colorId), 0);
			colorInfo.colorId = maxColorId + 1;
			appData.colors.push(colorInfo);
		}
		
		// Mark the design as dirty
		ScreenDesigner_DesignMarkDirty(appData);
		
		// Show 'edited'
		ScreenDesigner_UpdateInfoDisplays();

		ScreenDesigner_ColorPalette_Build();
	}

	//---------------------------------------------------------------------------
	//	Color Palette: Get Color Info By Id
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_GetColorInfoById = function(colorId)
	{
		var ci = undefined;
		
		if (appData.colors != undefined)
			ci = appData.colors.find(ci => ci.colorId == colorId);
		
		return ci;
	}
	
	//---------------------------------------------------------------------------
	//	Color Palette: Get Color By Id
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_GetColorById = function(colorId)
	{
		let ci = ScreenDesigner_ColorPalette_GetColorInfoById(colorId);

		var color = (ci != undefined) ? ci.color : undefined;

		return color;
	}

	//---------------------------------------------------------------------------
	//	Color Palette: Set Color By Id
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_SetColorById = function(colorId, color)
	{
		var updated = false;
		
		// Validate the color??

		if (appData.colors != undefined)
		{
			let ci = appData.colors.find(ci => ci.colorId == colorId);
			
			if (ci != undefined)
			{
				ci.color = color;
				updated = true;
			}
		}
		
		return updated;
	}

	//---------------------------------------------------------------------------
	//	Color Palette: Get Color By Id
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_NextColorId = function(colorId, reverse)
	{
		var nextColorId = undefined;
		
		let len = appData.colors.length;
		
		if (appData.colors != undefined)
		{
			let idx = appData.colors.findIndex(ci => ci.colorId == colorId);
			
			if (idx != -1)
			{
				
				let d = !reverse ? 1 : len;
				
				idx = (idx + d) % (len + 1);
				
				nextColorId = (idx < len) ? appData.colors[idx].colorId : undefined;
			}
			else
			{
				if (reverse)
					nextColorId = appData.colors[len - 1].colorId;
				else
					nextColorId = appData.colors[0].colorId;
			}
		}
		
		return nextColorId;
	}

	//---------------------------------------------------------------------------
	//	Color Palette: Color Handler
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_ColorHandler = function(evt, action, colorId)
	{
		let color = evt.target["value"];

		if (ScreenDesigner_ColorPalette_SetColorById(colorId, color))
		{
			// Mark the design as dirty
			ScreenDesigner_DesignMarkDirty(appData);

			// Undo (based on focus change)
			// XXXXXX

			// Redraw but only if colorId is in use (not re-render)
			ScreenGenerator_Design_DataModified(5);
			
			// Redraw the editor so update the color in the line list.
			// There are plenty of ways to optimize this, but for now
			// just redraw the entire canvas
			ScreenEditorCanvas.Render();
			
			// Update the line info table
			ScreenDesigner_LineInfoTable_UpdatePaletteEntry(colorId, color);
		}
	}

	//---------------------------------------------------------------------------
	//	Color Palette: Color Handler
	//---------------------------------------------------------------------------
	var ScreenDesigner_ColorPalette_Get = function(paletteMenuElement)
	{
		return (appData.colors != undefined) ? appData.colors : [];
	}
	
	//---------------------------------------------------------------------------
	//	Calc Snap Info
	//		2019.03.05: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_CalcSnapData = function(includeSurroundingTiles)
	{
		return ScreenGenerator.CalcSnapData(appData, includeSurroundingTiles);
	}
	
	//---------------------------------------------------------------------------
	//	Handle Toolbar Item
	//		
	//---------------------------------------------------------------------------
	var ScreenDesigner_HandleToolbarItem = function(evt)
	{
		var id = evt.currentTarget.id;
		
		// Edit Canvas Toolbar (was referred to as 'Edit Canvas Toolbar', but  ... )
		if (id == "ID_Toolbar_Undo")
			ScreenDesigner_UndoHandler_Undo();
		else if (id == "ID_Toolbar_Redo")
			ScreenDesigner_UndoHandler_Redo();
		else if (id == "ID_Toolbar_Delete")
			ScreenEditorCanvas.HandleDelete();
			
		else if (this.id == "ID_Toolbar_EditorZoom")
			ScreenEditorCanvas.Zoom(ScreenEditorCanvas.ZoomTo.FULL_TILE);
		else if (this.id == "ID_Toolbar_EditorResize")
			ScreenDesigner_SwapEditorAndRender();

		else if (this.id == "ID_Toolbar_ImageShowProcessed")
			ScreenDesigner_ShowProcessedImage();

		// Render Canvas Toolbar
		else if (id == "ID_CanvasToolbar_Zoom")
		{
			// 2021.07.15: Add option and shift
			if (evt.altKey)
			{
				var scale = BasicUnitsMgr.CalcScale(appData.general.units, appData.general.dpi);
				ScreenDesignerCanvas.Zoom(ScreenDesignerCanvas.ZoomTo.ONE_TO_ONE, scale);
			}
			else if (evt.shiftKey)
				ScreenDesignerCanvas.Zoom(ScreenDesignerCanvas.ZoomTo.SMALLER_DIM);
			else
				ScreenDesignerCanvas.Zoom(ScreenDesignerCanvas.ZoomTo.FRAME);
		}
		else if (id == "ID_CanvasToolbar_Download")
		{
			ScreenDesigner.UI_SelectTab("ID_FileTabAreaR");
			ScreenDesigner.UI_SelectTab("cD01");
			ScreenDesigner.UI_SelectTab("cD03", false);
			ScreenDesigner.UI_SelectTab("cD04", false);
			ScreenDesigner.UI_SelectTab("cD06", false);
			ScreenDesigner.UI_SelectTab("cD08", false);
			ScreenDesigner.UI_SelectTab("cD09", false);
			ScreenDesigner.UI_HighlightElement("ID_Downloads");
		}
		else if (id == "ID_CanvasToolbar_New")
		{
			if (ScreenDesigner_PromptForPossibleUnsavedDesignChanges())
			{
				ScreenDesigner_ShowSelectNewDesignType(true);
				/*
				if (evt.altKey)
					ScreenDesigner_CreateNewDesign("DefaultDesign", "Standalone");
				else
					ScreenDesigner_CreateNewDesign("DefaultDesign", "Project");
				*/
			}
		}
		else if (id == "ID_CanvasToolbar_Copy")
		{
			if (evt.altKey)
				ScreenDesigner_CreateNewDesign("CopyDesign", "Standalone");
			else
				ScreenDesigner_CreateNewDesign("CopyDesign", "Project");
		}
		else if (id == "ID_CanvasToolbar_Make")
		{
			ScreenDesigner_DesignExport.PrepareExport();
		}
		
		// Render Canvas Toolbar
		else if (id == "ID_SketchToolbar_SolidTile")
		{
			ScreenDesignerCanvas.SetSketchMode(ScreenDesignerCanvas.SketchMode.SOLID_TILE);
			ScreenDesigner_SelectSketchTool(id);
		}
		else if (id == "ID_SketchToolbar_HollowTile")
		{
			ScreenDesignerCanvas.SetSketchMode(ScreenDesignerCanvas.SketchMode.HOLLOW_TILE);
			ScreenDesigner_SelectSketchTool(id);
		}
		else if (id == "ID_SketchToolbar_TileEdge")
		{
			ScreenDesignerCanvas.SetSketchMode(ScreenDesignerCanvas.SketchMode.TILE_EDGE);
			ScreenDesigner_SelectSketchTool(id);
		}
		else if (id == "ID_SketchToolbar_EraseTile")
		{
			ScreenDesignerCanvas.SetSketchMode(ScreenDesignerCanvas.SketchMode.ERASE_TILE);
			ScreenDesigner_SelectSketchTool(id);
		}
		else if (id == "ID_SketchToolbar_EraseEdge")
		{
			ScreenDesignerCanvas.SetSketchMode(ScreenDesignerCanvas.SketchMode.ERASE_EDGE);
			ScreenDesigner_SelectSketchTool(id);
		}
		// 

			
		// Not handled
		else
			console.log("ScreenDesigner_HandleToolbarItem: unexpected toolbar item: " + id);

	}

	//---------------------------------------------------------------------------
	//	Select Sketch Tool
	//---------------------------------------------------------------------------
	var ScreenDesigner_SelectSketchTool = function(toolId)
	{
		let tools = document.getElementsByClassName("CL_ToolbarItem-sketch");
		for (var i = 0; i < tools.length; i++)
		{
			if (tools[i].id == toolId)
				tools[i].classList.add("CL_ToolbarItem-selected");
			else
				tools[i].classList.remove("CL_ToolbarItem-selected");
		}
	}
	
	
	//---------------------------------------------------------------------------
	//	Edit Options Buttons Interface
	//		Interface between 'edit options' buttons in UI and the corresponding
	//		settings in the editor canvas
	//---------------------------------------------------------------------------
	var ScreenDesigner_EditOptionBtnHandler = function(evt)
	{
		// Handles the buttons in the Tile tab, under the "Edit Options.." collapsable div
		var editOption = this.getAttribute("editOption");
		var editValue = this.checked;
		ScreenEditorCanvas.SetEditOption(editOption, editValue);
	}
	
	var ScreenDesigner_UpdateEditOptionBtn = function(editOption, editValue)
	{
		// Handles the settings of the buttons in the Tile tab, under the "Edit Options.." collapsable div
		// This is called from the edit canvas when the user presses a shortcut key
		var editOptionList = document.getElementsByClassName("EditOptionCheckbox");
		for (var i = 0; i < editOptionList.length; i++)
		{
			if (editOptionList[i].getAttribute("editOption") == editOption)
				editOptionList[i].checked = editValue;
		}
	}

	//---------------------------------------------------------------------------
	//	Get Edit Option Settings
	//		Called from 'save settings'
	//---------------------------------------------------------------------------
	var ScreenDesigner_GetEditOptionSettings = function()
	{
		// Get the settings for the edit options (in the Tile tab, under the 
		// "Edit Options.." collapsable div)
		return ScreenEditorCanvas.GetEditOptions();
	}
	
	//---------------------------------------------------------------------------
	//	Update Edit Option Settings
	//		Called from 'restore settings'
	//---------------------------------------------------------------------------
	var ScreenDesigner_UpdateEditOptionSettings = function(editOptions)
	{
		for (var opt in editOptions)
			if (editOptions.hasOwnProperty(opt))
			{
				ScreenDesigner_UpdateEditOptionBtn(opt, editOptions[opt]);
				ScreenEditorCanvas.SetEditOption(opt, editOptions[opt]);
			}
	}
	
	//---------------------------------------------------------------------------
	// ...
	//---------------------------------------------------------------------------
	var ScreenDesigned_SetWorkbookCommonEdit = function(enableEditAll)
	{
		// Enables or disables editing of all items in the workbook at the same time ("common edit").
		// At present it is "all items", since there is no selection capability in the workbook.
		// 
		
		// Keep track of the setting
		appWorkbookCommonEdit = enableEditAll;

		// Add or remove a class to those controls that will apply
		// to all designs in the workbook. The CSS class outlines the controls
		// so it is clear which controls are "common"
		for (var i = 0; i < editControlList.length; i++)
		{
			if (editControlList[i].wbCommonEdit)
			{
				var e = document.getElementById(editControlList[i].id);
				if (enableEditAll)
					e.classList.add("ControlWorkbookCommonEdit");
				else
					e.classList.remove("ControlWorkbookCommonEdit");
			}
		}
	}

	
	//---------------------------------------------------------------------------
	// ...
	//---------------------------------------------------------------------------
	var ScreenDesigner_RenderEditDesign = function()
	{
		ScreenDesigner_RenderRequest.RenderEditDesign(appData);
	}
	
	//---------------------------------------------------------------------------
	//	2022.04.11: Changed optional 'priority' param to options config param
	//---------------------------------------------------------------------------
	var ScreenDesigner_RenderThumbnailDesign = function(designData, imageElement, initialDelay, dimensions, config = undefined)
	{
		ScreenDesigner_RenderRequest.RenderThumbnailDesign(designData, imageElement, initialDelay, dimensions, config);
	}


	//---------------------------------------------------------------------------
	//	
	//---------------------------------------------------------------------------
	var ScreenDesigner_WorkbookBtnHandler = function(evt)
	{
		var buttonName = this.id;
		
		if (buttonName == "ID_AddToBook")
		{
			var designData = appData;
			
			// If the design is in a project, then we have to make a copy. It is not
			// valid to have a design in both a workbook and a project
			if (ScreenDesigner_StorageMgr.IsDesignInProject(appData))
				designData = appData.Clone();
				
			ScreenDesigner_Workbook.AddDesign(designData, true /* current */);
		}
		else if (buttonName == "ID_AddCopyToBook")
		{
			var copyData = appData.Clone();
			ScreenDesigner_Workbook.AddDesign(copyData, false /* not current */);
		}
		else if (buttonName == "ID_NewBookFromProject")
		{
			var continueClose = true;
			
			if (ScreenDesigner_Workbook.IsDirty())
				continueClose = confirm("Unsaved changes to workbook will be lost.");
			
			if (continueClose)
			{
				ScreenDesigner_Workbook.Close();
				ScreenDesigner_CopyProjectToWorkbook();
			}
		}
		else if (buttonName == "ID_SaveBook")
		{
			if (ScreenDesignerAccount.CanPerformOrShowBlockedUI("saveWorkbook"))
			{
				ScreenDesigner_Workbook.Save();
				SystemAnalytics.Record("WorkbookExport", { format:"TXT", destination:"file" } );
			}
		}
		else if (buttonName == "ID_CloseBook")
		{
			var continueClose = true;
			
			if (ScreenDesigner_Workbook.IsDirty())
			{
				continueClose = confirm("Unsaved changes to workbook will be lost.");
			}
			
			if (continueClose)
				ScreenDesigner_Workbook.Close();
		}
		else if (buttonName == "ID_WorkbookEditAll")
		{
			var enableEditAll = this.checked;
			
			ScreenDesigned_SetWorkbookCommonEdit(enableEditAll);
		}		
		else
		{
			console.log("ScreenDesigner_WorkbookBtnHandler: " + buttonName + " not handled");
		}
		
		ScreenDesigner_UpdateInfoDisplays();
	}
	
	//---------------------------------------------------------------------------
	//	
	//---------------------------------------------------------------------------
	var ScreenDesigner_ProjectFieldChange = function(evt)
	{
		var id = this.id;
		
		if (id == "ID_ProjectName")
		{
			var e = document.getElementById("ID_ProjectName");
			var name = e.value;
			ScreenDesigner_StorageMgr.UpdateProjectName(name);
		}
	}
	
	//---------------------------------------------------------------------------
	//	
	//---------------------------------------------------------------------------
	var ScreenDesigner_CopyProjectToWorkbook = function()
	{
		var designList = ScreenDesigner_StorageMgr.GetProjectDesignIDList();
		
		for (var i = 0; i < designList.length; i++)
		{
			var designData = ScreenDesigner_StorageMgr.GetDataForDesignID(designList[i]);
			var copyData = designData.Clone();
			ScreenDesigner_Workbook.AddDesign(copyData, false /* not current */);
		}
	}

	//---------------------------------------------------------------------------
	// Display Message Banner
	//---------------------------------------------------------------------------
	var ScreenDesigner_DisplayMessageBanner = function(message, options = undefined)
	{
		// Create a div that will be shown as a "banner", set the message, and
		// add it to the body of the document. It will be displayed with the
		// animation ("fade in") that is part of the .message-banner class
		var banner = document.createElement("div");
		banner.classList.add("message-banner");

		// 2019.10.14: Added options
		if (options != undefined && options.size == "small")
			banner.classList.add("message-banner-small");
		if (options != undefined && options.position == "bottom")
			banner.classList.add("message-banner-bottom");

		banner.innerHTML = message;
		document.body.appendChild(banner); 

		// This clean-up function will be called by the timer, below. It will
		// add a "fade out" animation and an animationend listener. The listener
		// will simply remove the banner div from the document.
		function cleanUp(banner) {
			banner.classList.add("message-banner-fadeout");
			banner.addEventListener("animationend", function(evt) {document.body.removeChild(evt.target)}, false);		
		}
		setTimeout(cleanUp, 2000, banner);
	}

	//---------------------------------------------------------------------------
	// Highlight Element
	//---------------------------------------------------------------------------
	var ScreenDesigner_HighlightElement = function(elementId)
	{
		var elHighlight =  document.getElementById(elementId);
	
		if (elHighlight != undefined)
		{
			elHighlight.classList.add("element-highlight");

			// This clean-up function will be called by the timer, below. It will
			// add a "fade out" animation and an animationend listener. The listener
			// will simply remove the banner div from the document.
			function fadeout() {
				elHighlight.removeEventListener("animationend", fadeout);
				elHighlight.classList.remove("element-highlight"); 
				elHighlight.classList.remove("element-highlight-fadeout"); 
			}

			function cleanUp(elHighlight) {
				elHighlight.classList.add("element-highlight-fadeout");
				elHighlight.addEventListener("animationend", fadeout, false);		
			}
			
			
			setTimeout(cleanUp, 2000, elHighlight);
		}
	}

	//---------------------------------------------------------------------------
	//	Show And Highlight Element
	//		2021.08.26: Factored into separate function
	//---------------------------------------------------------------------------
	var ScreenDesigner_ShowAndHighlightElement = function(showMeId)
	{
		var e = document.getElementById(showMeId);
		var collapsible = undefined;
		while (e != undefined && !e.classList.contains("TabArea"))
		{
			// Is the element in a collapsing checkbox? If it is, we will 
			// need to close all other collapsable divs and insure this one is expanded
			if (e.classList.contains("CollapsingContainer"))
				collapsible = e;
			e = e.parentElement;
		}
			
		if (e != undefined)
		{
			let radioId = e.id + "R";
			ScreenDesigner.UI_SelectTab(radioId);
			if (collapsible != undefined)
			{
				let collapsibles = e.querySelectorAll(".CollapsingContainer");
				for (var i = 0; i < collapsibles.length; i++)
				{
					let cb = collapsibles[i].querySelector(".CollapsingCheckbox");
					if (cb != undefined)
						cb.checked = (collapsibles[i] == collapsible);
				}
			}
			ScreenDesigner.UI_HighlightElement(showMeId);
		}
	}
	
	//---------------------------------------------------------------------------
	//	Display "Hold Command Key" Banner
	//		Display the "Hold command key to zoom" message banner, but only once
	//		(possible once for each canvas)
	//---------------------------------------------------------------------------
	var holdCommandKeyMessageShown = []; // Stores timestamps per id
	var ScreenDesigner_DisplayHoldCommandKeyBanner = function(id)
	{
		
		if (!holdCommandKeyMessageShown[id])
		{
			ScreenDesigner_DisplayMessageBanner("Press the command key ('&#8984;') to zoom with two fingers.");
			var d = new Date();
			holdCommandKeyMessageShown[id] = d.getTime();
		}
		else
		{
			// Reset after 15 seconds
			var d = new Date();
			var t = d.getTime() - holdCommandKeyMessageShown[id];
			if (t > 15000)	
				holdCommandKeyMessageShown[id] = undefined;
		}
	}

	//---------------------------------------------------------------------------
	//	GetDiagsSettings
	//		2022.02.08: Added. Used by screen render
	//---------------------------------------------------------------------------
	var ScreenDesigner_GetDiagsSettings = function()
	{
		return Object.assign({}, systemSettings);
	}

	//---------------------------------------------------------------------------
	//	Get Line Info Cols
	//		2022.02.08: Added. Used to hide some columns; moved from ScreenEditor
	//---------------------------------------------------------------------------
	var ScreenDesigner_GetLineInfoColumns = function()
	{
		let cols = [];

		for (var i = 0; i < seAllLineInfoCols.length; i++)
		{
			let col = seAllLineInfoCols[i];

			let include =
				(col.group == undefined) ||
				(col.group == "color" && appData.tiling.useLineColors) ||
				(col.group == "lattice" && appData.tiling.enableLattice);

			if (include)
				cols.push(col);
		}

		return cols;
	}

	//---------------------------------------------------------------------------
	//	API
	//---------------------------------------------------------------------------
	return {
		Init:								ScreenDesigner_Init,
		Open:								ScreenDesigner_Open,
		Close:								ScreenDesigner_Close,
		Revealed:							ScreenDesigner_Revealed,
		UpdateLine:							ScreenDesigner_UpdateLine,
		UpdateLineProperty:					ScreenDesigner_UpdateLineProperty,
		UpdateLineEditState:				ScreenDesigner_UpdateLineEditState,
		UpdateImage:						ScreenDesigner_UpdateImage, // 2020.11.30
		ReloadImageData:					ScreenDesigner_ReloadImageData, // 2021.03.29
		SetImageData:						ScreenDesigner_SetImageData, // 2020.12.01
		ImageLoadComplete:					ScreenDesigner_ImageLoadComplete,  // 2020.12.08
		ColorPalette_GetColorById:			ScreenDesigner_ColorPalette_GetColorById,
		ColorPalette_NextColorId:			ScreenDesigner_ColorPalette_NextColorId,
		AddLine:							ScreenDesigner_AddLine,
		DeleteLine:							ScreenDesigner_DeleteLine,
		CalcSnapData:						ScreenDesigner_CalcSnapData,
		NewScreenDesignerData:				ScreenDesigner_NewScreenDesignerData,
		DesignClearDirty:					ScreenDesigner_DesignClearDirty,
		DesignIsDirty:						ScreenDesigner_DesignIsDirty,
		CurrentDesignIsStandalone:			ScreenDesigner_CurrentDesignIsStandalone,
		CurrentDesignIsInProject:			ScreenDesigner_CurrentDesignIsInProject,
		UpdateSketch:						ScreenDesigner_UpdateSketch,
		AddAdjacentTileEdges:				ScreenDesigner_AddAdjacentTileEdges, // 2021.09.23
		UseLoadedData:						ScreenDesigner_UseLoadedData,
		RenderLimitedDesign:				ScreenDesigner_RenderEditDesign,
		UpdateEditOptionBtn:				ScreenDesigner_UpdateEditOptionBtn,
		RenderThumbnailDesign:				ScreenDesigner_RenderThumbnailDesign,
		PromptForPossibleUnsavedDesignChanges:	ScreenDesigner_PromptForPossibleUnsavedDesignChanges,
		UI_SelectTab:						ScreenDesigner_UI_SelectTab,
		UI_HighlightElement:				ScreenDesigner_HighlightElement,
		UI_ShowAndHighlightElement:			ScreenDesigner_ShowAndHighlightElement, // 2021.08.26
		SetCurrentDesignData:				ScreenDesigner_SetCurrentDesignData,
		GetDesignRender:					ScreenDesigner_GetDesignRender,
		IsRectangularFrame:					ScreenDesigner_IsRectangularFrame, // 2021.08.26
		SetAndLockAspectRatio:				ScreenDesigner_SetAndLockAspectRatio, // 2021.08.26
		GetAppConfig:						ScreenDesigner_GetAppConfig,
		UpdateDimensions:					ScreenDesigner_UpdateDimensions,
		UpdateTileDimensions:				ScreenDesigner_UpdateTileDimensions,
		UpdateInfoDisplays:					ScreenDesigner_UpdateInfoDisplays,
		HighlightActiveDesign:				ScreenDesigner_HighlightActiveDesign,
		HighlightRestrictedFeatures:		ScreenDesigner_HighlightRestrictedFeatures,
		UpdateDownloadsRemaining:			ScreenDesigner_UpdateDownloadsRemaining,
		DisplayMessageBanner:				ScreenDesigner_DisplayMessageBanner,
		PerformSnapshot:					ScreenDesigner_PerformSnapshot,
		DisplayHoldCommandKeyBanner:		ScreenDesigner_DisplayHoldCommandKeyBanner,
		CalcZoomChanges:					ScreenDesigner_CalcZoomChanges,
		GetDiagsSettings:					ScreenDesigner_GetDiagsSettings, // 2022.02.08
		GetLineInfoColumns:					ScreenDesigner_GetLineInfoColumns, // 2022.02.08
	};
}());


//---------------------------------------------------------------------------
//	ScreenDesigner_RenderRequest
//---------------------------------------------------------------------------
var ScreenDesigner_RenderRequest = (function() {

	var appDesignRenderContext = undefined; // 2018.01.04 (was ..Timer)
	var appEditRenderContext = undefined; // 2018.01.04: added

	//---------------------------------------------------------------------------
	// Render Requests
	//---------------------------------------------------------------------------

	var ScreenDesigner_RenderRequest_RenderEditDesign = function(appData)
	{
		if (ScreenGenerator.IsDataRenderable(appData))
		{
			var generation = ScreenGenerator.Create(appData, ScreenGenerationType.MINIMAL_DESIGN);
			var context = ScreenDesigner_RenderTask.CreateContext(generation);
		
			context.completionCallback = ScreenDesigner_RenderEditDesign_Completed;

			// Stop any current render
			if (appEditRenderContext != undefined)
			{
				ScreenDesigner_RenderQueue.RemoveTask(appEditRenderContext);
				ScreenDesigner_RenderTask.CancelTimer(appEditRenderContext);
				appEditRenderContext = undefined;
			}

			appEditRenderContext = context;
			ScreenDesigner_RenderQueue.AddTask(context, TaskPriority.EDIT_CANVAS);
		}
	}


	var ScreenDesigner_RenderEditDesign_Completed = function()
	{
		var offsetPolyList = undefined
		
		offsetPolyList = appEditRenderContext.designRender.offsetPolyList;
		
		appEditRenderContext = undefined;
		
		ScreenEditorCanvas.SetRenderedDesign(offsetPolyList);
	}

	var ScreenDesigner_RenderRequest_RenderThumbnailDesign = function(designData, imageElement, initialDelay, dimensions, config = undefined)
	{
		// 2022.04.11: Replaced priority options param with config optional param
		let priority = (config != undefined && config.priority != undefined) ? config.priority : TaskPriority.DEFAULT;

		if (ScreenGenerator.IsDataRenderable(designData))
		{
			var generation = ScreenGenerator.Create(designData, ScreenGenerationType.COMPLETE_DESIGN);
			var context = ScreenDesigner_RenderTask.CreateContext(generation);
			
			// The initial delay will be longer for the design currently being edited to minimize re-renders during editing
			context.initialDelay = initialDelay;
			context.dimensions = dimensions;

			// Stop any current render
			if (imageElement.context != undefined)
			{
				ScreenDesigner_RenderQueue.RemoveTask(imageElement.context);
				ScreenDesigner_RenderTask.CancelTimer(imageElement.context);
				imageElement.context = undefined;
			}
		
			imageElement.context = context;
			context.completionCallback = ScreenDesigner_RenderThumbnailDesign_Completed;
			context.imageElement = imageElement;
			// 2022.04.11: Added userCallback and userRef
			context.userCallback = (config != undefined) ? config.userCallback : undefined;
			context.userRef = (config != undefined) ? config.userRef : undefined;

			ScreenDesigner_RenderQueue.AddTask(context, priority);
		}
		else
		{
			console.log("ScreenDesigner_RenderRequest_RenderThumbnailDesign: not renderable");
		}
	}

	var ScreenDesigner_RenderThumbnailDesign_Completed = function(context)
	{
		// Clear the context from the image element
		if (context.imageElement != undefined) // 2021.03.11: Added for completeness
			context.imageElement.context = undefined;
		
		// 2019.09.25: I added an 'options' parameter to RenderPNG so we can now pass
		// the context.dimensions. I am not going to do that yet, since I don't want to
		// break anything.
		
		var pngInfo = ScreenRenderer.RenderPNG(context.designRender, ScreenRenderer.RenderType.THUMBNAIL);
		
		if (pngInfo != undefined && context.imageElement != undefined)
		{
			context.imageElement.src = pngInfo.data;

			// 2022.04.11: Call userCallback, if provided with
			// pngInfo: {data:pngData, width:canvas.width, height:canvas.height}, where pngData = canvas.toDataURL("image/png")
			if (context.userCallback != undefined)
				context.userCallback(context.userRef, pngInfo);
		}
		else
		{
			if (pngInfo == undefined)
				console.log("ScreenDesigner_RenderThumbnailDesign_Completed: pngInfo is undefined");
		}
	}
		
	//---------------------------------------------------------------------------
	//	Screen Designer Render Queue: Render Full Design
	//		2021.05.14: Added options
	//---------------------------------------------------------------------------
	var ScreenDesigner_RenderRequest_RenderFullDesign = function(appDesignRender, delayBetweenPhases, options = undefined)
	{
		var context = ScreenDesigner_RenderTask.CreateContext(appDesignRender);
		
		context.delayBetweenPhases = delayBetweenPhases;
		context.endOfPhaseCallback = ScreenDesigner.UpdateDimensions;
		context.intermediateCallback = ScreenDesignerCanvas.Render;
		context.completionCallback = ScreenDesigner_RenderFullDesign_Complete;
		
		if (options != undefined && options.delayTime != undefined)
			context.delayTime = options.delayTime;

		// Stop any current render timer and clear the context
		//
		if (appDesignRenderContext != undefined)
		{
			ScreenDesigner_RenderQueue.RemoveTask(appDesignRenderContext);
			ScreenDesigner_RenderTask.CancelTimer(appDesignRenderContext);
			appDesignRenderContext = undefined;
		}

		appDesignRenderContext = context;
		ScreenDesigner_RenderQueue.AddTask(context, TaskPriority.FULL_SCREEN_CANVAS);
	}

	var ScreenDesigner_RenderFullDesign_Complete = function()
	{
		ScreenDesignerCanvas.Render();
		try {
			ScreenImageCanvas.RenderProcessed(appDesignRenderContext.designRender.designData.processedData);
		}
		catch (err) {
			console.error(err);
		}
		appDesignRenderContext = undefined;
	}
	
	var ScreenDesigner_RenderRequest_RenderAndSaveDesign = function(designData, designNumber)
	{
		if (ScreenGenerator.IsDataRenderable(designData))
		{
			var generation = ScreenGenerator.Create(designData, ScreenGenerationType.COMPLETE_DESIGN);
			var context = ScreenDesigner_RenderTask.CreateContext(generation);
			
			context.designNumber = designNumber;
			context.completionCallback = ScreenDesigner_RenderAndSaveDesign_Complete;

			ScreenDesigner_RenderQueue.AddTask(context, TaskPriority.WORKBOOK_EXPORT_ALL);
		}
	}

	var ScreenDesigner_RenderAndSaveDesign_Complete = function(context)
	{
		var pngInfo = ScreenRenderer.RenderPNG(context.designRender, ScreenRenderer.RenderType.FOR_EXPORT);
		
		if (pngInfo != undefined)
		{
			var suffix = "_" + context.designNumber;
			ScreenDesignerFileIO.ExportAsPNG(pngInfo.data, suffix);
		}
	}

	
	return {
		RenderEditDesign:			ScreenDesigner_RenderRequest_RenderEditDesign,
		RenderThumbnailDesign:		ScreenDesigner_RenderRequest_RenderThumbnailDesign,
		RenderFullDesign:			ScreenDesigner_RenderRequest_RenderFullDesign,
		RenderAndSaveDesign:		ScreenDesigner_RenderRequest_RenderAndSaveDesign
	}
	
}());


//---------------------------------------------------------------------------
//	ScreenDesigner_RenderQueue
//---------------------------------------------------------------------------
var ScreenDesigner_RenderQueue = (function() {

	var appRenderTasks = { current:undefined, priority:[], nextId:0 };

	var ScreenDesigner_RenderQueue_AddTask = function(renderContext, priority = TaskPriority.DEFAULT)
	{
		renderContext.priority = priority;
		renderContext.nextDelayTime = renderContext.initialDelay;
		renderContext.taskId = appRenderTasks.nextId++;
		
		if (appRenderTasks.priority[priority] == undefined)
			appRenderTasks.priority[priority] = [];
		
		//console.log("Added renderContext with id:" + renderContext.taskId + " and priority:" + renderContext.priority + " to queue");
		appRenderTasks.priority[priority].push(renderContext);
			
		// If there is no current task, then start the newly added task
		if (appRenderTasks.current == undefined)
			ScreenDesigner_RenderQueue_AdvanceTask()
			
		//ScreenDesigner_RenderQueue_Dump();
	}

	var ScreenDesigner_RenderQueue_RemoveTask = function(renderContext)
	{
		if (appRenderTasks.current == renderContext)
		{
			appRenderTasks.current = undefined;
		}
		
		//console.log("Removing renderContext with id:" + renderContext.taskId + " and priority:" + renderContext.priority + " to queue");

		if (appRenderTasks.priority[renderContext.priority] == undefined)
		{
			console.log("ScreenDesigner_RenderQueue_RemoveTask: priority '" + renderContext.priority + "' task queue not found");
		}
		else
		{
			var idx = appRenderTasks.priority[renderContext.priority].indexOf(renderContext);
			if (idx === -1)
			{
				console.log("ScreenDesigner_RenderQueue_RemoveTask: task with priority '" + renderContext.priority + "' not found in task queue");
			}
			else
			{
				appRenderTasks.priority[renderContext.priority].splice(idx, 1);
			}
		}
		
		//ScreenDesigner_RenderQueue_Dump();
	}
	
	var ScreenDesigner_RenderQueue_CancelTasksWithPriority = function(priority)
	{
		// 2018.01.22: Added
		
		if (appRenderTasks.priority[priority] != undefined)
		{
			while (appRenderTasks.priority[priority].length > 0)
			{
				var context = appRenderTasks.priority[priority].pop();
				ScreenDesigner_RenderTask.CancelTimer(context);
			}
		}
	}
	
	var ScreenDesigner_RenderQueue_Dump = function()
	{
		var str = "tasks: ";
		
		for (var i = 0; i < appRenderTasks.priority.length; i++)
		{
			if (appRenderTasks.priority[i] != undefined && appRenderTasks.priority[i].length > 0)
			{
				str = str + "" + i + ":" + appRenderTasks.priority[i].length;
			}
		}
		
		console.log("Queue: " + str);
	}
	
	var ScreenDesigner_RenderQueue_GetNextTask = function()
	{
		var renderContext = undefined;
	
		for (var i = 0; i < appRenderTasks.priority.length && renderContext == undefined; i++)
		{
			while (appRenderTasks.priority[i] != undefined && appRenderTasks.priority[i].length > 0 && renderContext == undefined)
			{
				renderContext = appRenderTasks.priority[i][0];
				
				if (ScreenGenerator.IsComplete(renderContext.designRender))
				{
					console.log("ScreenDesigner_RenderQueue_GetNextTask: completed renderContext found in task queue");
					appRenderTasks.priority[i].splice(0, 1);
					renderContext = undefined;
				}
			}
		}
		
		return renderContext;
	}
	
	var ScreenDesigner_RenderQueue_Callback = function(renderContext)
	{
		if (appRenderTasks.current != renderContext)
			console.log("ScreenDesigner_RenderQueue_Callback: appRenderTasks.current != renderContext");
			
		appRenderTasks.current = undefined;
	}

	var ScreenDesigner_RenderQueue_AdvanceTask = function(renderContext = undefined)
	{
		var renderContext = ScreenDesigner_RenderQueue_GetNextTask();
		
		if (renderContext != undefined)
		{
			appRenderTasks.current = renderContext;
			ScreenDesigner_RenderTask.Advance(renderContext);
		}
	}
	
	return {
		AddTask:		ScreenDesigner_RenderQueue_AddTask,
		RemoveTask:		ScreenDesigner_RenderQueue_RemoveTask,
		Callback:		ScreenDesigner_RenderQueue_Callback,
		AdvanceTask:	ScreenDesigner_RenderQueue_AdvanceTask
	}
	
}());


//---------------------------------------------------------------------------
//	ScreenDesigner_RenderTask
//---------------------------------------------------------------------------
var ScreenDesigner_RenderTask = (function() {

	//---------------------------------------------------------------------------
	// Render Task
	//---------------------------------------------------------------------------
	var ScreenDesigner_RenderTask_CreateContext = function(designRender)
	{
		//console.log("ScreenDesigner_CreateRenderContext");
		var context = {};
		
		context.designRender = designRender;	// The ScreenGenerator to render
		context.delayBetweenPhases = false;		// True means use longer delay between phases	

		context.initialDelay = 1;				// Delay before first callback
		context.timeLimit = 25;					// Amount of time to spend rendering, in  ms
		
		context.endOfPhaseDelay = 500;			// Delay between phases
	 	context.delayTime = 5; 					// Delay until next render call, in ms
		
		context.timer = undefined; 				// No timer yet
		context.endOfPhaseCallback = undefined;		// Called at the end of a phase
		context.intermediateCallback = undefined;	// Called at the end of the timer task when the render is not complete
		context.completionCallback = undefined;		// Called at the end of the timer task when the render is complete

		return context;
	}

	var ScreenDesigner_RenderTask_Advance = function(context)
	{
		// Start the process of generating the design by using a timer. The
		// timer callback actually does the work. This gets the render/generate call 
		// flow out of the UI event call flow.
		//
		// Set a very short (1ms) timeout so the initial data (frame, etc) gets
		// calculated immediately. This helps with the responsiveness and getting
		// something to show on screen right away.
		context.timer = setTimeout(ScreenDesigner_RenderTask_Callback, context.nextDelayTime /*ms*/, context);
	}

	var ScreenDesigner_RenderTask_CancelTimer = function(context)
	{
		if (context.timer != undefined)
		{
			clearTimeout(context.timer);
			context.timer = undefined;
		}
	}

	var ScreenDesigner_RenderTask_Callback = function(context)
	{
		// Callback for the render timer. 
		//
		
		// Let task queue know, so it can clear its reference		
		ScreenDesigner_RenderQueue.Callback(context);
		
		// One-shot timer has completed, so clear our reference
		context.timer = undefined;
		
	 	context.nextDelayTime = context.delayTime;
		var startTimestamp = Date.now();
		
		// If we are at the end of a phase, but not complete then move to the next phase
		if (ScreenGenerator.EndOfPhase(context.designRender) && !ScreenGenerator.IsComplete(context.designRender))
			ScreenGenerator.PhaseAdvance(context.designRender);
			
		// Render the design until we hit the time limit or hit the end of a phase.
		var noPhaseDelay = !context.delayBetweenPhases;
		while ((Date.now() - startTimestamp < context.timeLimit) && (!ScreenGenerator.EndOfPhase(context.designRender) || noPhaseDelay) &&
				!ScreenGenerator.IsComplete(context.designRender))
		{
			ScreenGenerator.Advance(context.designRender, 10 /* steps */);
			
			if (noPhaseDelay && ScreenGenerator.EndOfPhase(context.designRender))
			{
				if (context.endOfPhaseCallback != undefined)
					context.endOfPhaseCallback();
			}
		}
	
		// If we are at the end of a phase, then call that callback. This is used, for example, to update the dimensions
		// when the main design is being rendered
		if (ScreenGenerator.EndOfPhase(context.designRender) && context.endOfPhaseCallback != undefined)
			context.endOfPhaseCallback();
			
		// Start a new timer if not done yet
		if (!ScreenGenerator.IsComplete(context.designRender))
		{
			// Use a longer delay before starting the next phase. This will delay some of the 
			// more time consuming rendering until the user stops makes changes
			if (ScreenGenerator.EndOfPhase(context.designRender) && context.delayBetweenPhases)
				context.nextDelayTime = context.endOfPhaseDelay;
		}

		// Callback, typically used to tell the canvas to render what we have so far
		if (!ScreenGenerator.IsComplete(context.designRender))
		{
			if (context.intermediateCallback != undefined)
				context.intermediateCallback(context);
		}
		else
		{
			if (context.completionCallback != undefined)
				context.completionCallback(context);
		}
		

		if (!ScreenGenerator.IsComplete(context.designRender))
		{
			ScreenDesigner_RenderQueue.AdvanceTask(context);
		}
		else
		{
			ScreenDesigner_RenderQueue.RemoveTask(context);
			ScreenDesigner_RenderQueue.AdvanceTask(undefined);
		}
		
		
	}
	
	return {
		CreateContext:		ScreenDesigner_RenderTask_CreateContext,
		Advance:			ScreenDesigner_RenderTask_Advance,
		CancelTimer:		ScreenDesigner_RenderTask_CancelTimer
	}
	
}());


//---------------------------------------------------------------------------
//	ScreenDesigner_Collection
// 		Manages a collection of designs in the UI, such as a workbook 
//		or a project.
//
//		All data is stored in the DOM
//---------------------------------------------------------------------------
var ScreenDesigner_Collection = (function() {

	// Workbook Item sizes
	var workbookItemDim = {size:{x:58, y:58}, margin: 2};
	
	var ScreenDesigner_Collection_AddItem = function(design, designID, contents, getThumbnailInfoFunc, collectionObject, collectionIDchar = "x")
	{
		// 2018.08.22: design may be undefined at this point. _SetItemDesign was added to allow
		// the design to be assigned later
		try {
			// HTML structure:
			// div
			//   img (rendered tile)
			//   div (for popdown menu)
			//     img (triangle)
			//     div (menu content)
			//       a "Edit"
			//       a "Edit Copy"
			//       a "Remove"
		
			var ref = collectionIDchar + designID;
			//console.log("ScreenDesigner_Collection_AddItem: ref:'" + ref + "'");

			var itemContainer = document.createElement('div'); 
			itemContainer.id = ("H" + ref);
			itemContainer.classList.add("WorkbookItemContainer");
			itemContainer.collectionObject = collectionObject;
			// 2019.10.13: Add loading spinner if we don't have a design
			if (design == undefined)
				itemContainer.classList.add("spinner-loading");
			itemContainer.addEventListener("mouseover", ScreenDesigner_Thumbnail.Show, false);
			itemContainer.addEventListener("mouseout",  ScreenDesigner_Thumbnail.Hide, false);
		
			var designImage = document.createElement('img'); 
			designImage.className = "WorkbookItemImage";
			designImage.id = ("E" + ref);
			designImage.collectionObject = collectionObject;
			designImage.onclick = ScreenDesigner_Collection_HandleItemSelected;
		
			// 2018.01.11: Add a reference to the thumbnail image to the element with the ScreenDesigner_Thumbnail_Show 
			itemContainer.thumbnailImg = designImage; 
		
			// 2018.01.23: Add thumbnailInfo
			itemContainer.thumbnailInfo = {func:getThumbnailInfoFunc, context:design};
		
			// Add popup menu
			var menuContainer = document.createElement('div');
			menuContainer.className = "WorkbookItemPopupContainer";
		
			var menuBtn = document.createElement('img');
			menuBtn.className = "WorkbookItemPopup";
			menuBtn.src = ScreenDesigner_Collection_CreateMenuIcon();
			menuBtn.onclick = ScreenDesigner_Collection_HandleItemSelected;
			menuBtn.id = ("P" + ref);
			menuBtn.collectionObject = collectionObject;
		
			var menuList = document.createElement('div');
			menuList.className = "WorkbookItemMenuList";
			menuList.id = ("M" + ref);
			menuList.collectionObject = collectionObject;
		
			var menuItem_Edit = document.createElement('a');
			menuItem_Edit.innerHTML = "Edit";
			menuItem_Edit.id = ("E" + ref);
			menuItem_Edit.collectionObject = collectionObject;
			menuItem_Edit.onclick = ScreenDesigner_Collection_HandleItemSelected;
		
			var menuItem_Copy = document.createElement('a');
			menuItem_Copy.innerHTML = "Edit Copy";
			menuItem_Copy.id = ("C" + ref);
			menuItem_Copy.collectionObject = collectionObject;
			menuItem_Copy.onclick = ScreenDesigner_Collection_HandleItemSelected;
		
			var menuItem_Remove = document.createElement('a');
			menuItem_Remove.innerHTML = "Remove";
			menuItem_Remove.id = ("R" + ref);
			menuItem_Remove.collectionObject = collectionObject;
			menuItem_Remove.onclick = ScreenDesigner_Collection_HandleItemSelected;
		
			menuContainer.appendChild(menuBtn);
			menuContainer.appendChild(menuList);
			menuList.appendChild(menuItem_Edit);
			menuList.appendChild(menuItem_Copy);
			menuList.appendChild(menuItem_Remove);
		
			itemContainer.appendChild(designImage);
			itemContainer.appendChild(menuContainer);
		
			contents.appendChild(itemContainer);
		}
		catch (error) {
			console.log("ScreenDesigner_Collection_AddItem error: " + error);
		}		
	}

	var ScreenDesigner_Collection_GetItemContainer = function(designID, collectionIDchar) // 2019.10.13: Factored out
	{
		var ref = collectionIDchar + designID;
		var id = "H" + ref;
		var itemContainer = document.getElementById(id);

		return itemContainer;
	}
	var ScreenDesigner_Collection_SetItemDesign = function(design, designID, collectionIDchar = "x")
	{
		var itemContainer = ScreenDesigner_Collection_GetItemContainer(designID, collectionIDchar);

		itemContainer.thumbnailInfo.context = design;
		
		// 2019.10.13: Remove loading spinner
		itemContainer.classList.remove("spinner-loading");
	}

	var ScreenDesigner_Collection_SetLoadFailure = function(designID, collectionIDchar = "x")
	{
		var itemContainer = ScreenDesigner_Collection_GetItemContainer(designID, collectionIDchar);

		itemContainer.classList.remove("spinner-loading");
		
		var p = document.createElement('p');
		p.innerHTML = "Unable to load design. Click to try again.";
		p.classList.add("WorkbookItemLoadFailure");
		p.id = "F" + collectionIDchar + designID;
		p.collectionObject = itemContainer.collectionObject;
		p.addEventListener("click",  ScreenDesigner_Collection_HandleItemSelected, false);
		itemContainer.appendChild(p);
	}

	var ScreenDesigner_Collection_ClearLoadFailure = function(designID, collectionIDchar, options)
	{
		var itemContainer = ScreenDesigner_Collection_GetItemContainer(designID, collectionIDchar);

		var ps = itemContainer.getElementsByClassName("WorkbookItemLoadFailure");
		ps[0].remove();
	}

	var ScreenDesigner_Collection_ShowLoading = function(designID, collectionIDchar)
	{
		var itemContainer = ScreenDesigner_Collection_GetItemContainer(designID, collectionIDchar);
		itemContainer.classList.add("spinner-loading");
	}

	var ScreenDesigner_Collection_HandleItemSelected = function(e)
	{
		// Called when any of the workbook elements are clicked on
		//
		var id = e.currentTarget.id;
		var cmd = id.substring(0, 1);
		var col = id.substring(1, 2);
		var designID = id.substring(2);
		var collectionObject = e.target.collectionObject;
		var designData = undefined;
		
		//console.log("ScreenDesigner_Collection_HandleItemSelected: " + e.target.id + " (cmd:'" + cmd + "' col:'" + col + "' id:'" + designID + "')");
		
		if (collectionObject != undefined)
			designData = collectionObject.GetDataForDesignID(designID);
		

		if (true /*designData != undefined*/)
		{
			if (cmd == "E" || cmd == "C") // Edit item
			{
				// 2019.10.13: Test that the designData is not an empty object
				if (designData != undefined && Object.keys(designData).length > 0)
				{
					var continueWithEdit = ScreenDesigner.PromptForPossibleUnsavedDesignChanges();

					if (continueWithEdit)
					{
						var dataToUse;
						var dataRefToUse;
					
						// NOTE: This copied data is NOT in the workbook
						if (cmd == "E")
						{
							dataToUse = designData;
							dataRefToUse = designID;
						}
						else
						{
							dataToUse = designData.Clone();
							dataRefToUse = undefined;
						}

						collectionObject.SetCurrentDesignRef(dataRefToUse);
						ScreenDesigner.SetCurrentDesignData(dataToUse);
					}
				}
				else
				{
					collectionObject.MissingDesignData(designID);
				}
			}
			else if (cmd == "P") // Show popup menu
			{
				ScreenDesigner_Collection_ShowPopupMenu(designID, col);
			}
			else if (cmd == "F") // Re-attempt failed load
			{
				collectionObject.MissingDesignData(designID);
			}
			else if (cmd == "R") // Remove item
			{
				var continueWithRemove = true;

				continueWithRemove = confirm("Removing item can not be undone.");

				if (continueWithRemove)
				{
					ScreenDesigner_Thumbnail.Hide();

					var success = collectionObject.RemoveDesignAndUpdate(designData, designID);
					
					if (success)
					{
						var itemId = "H" + col + designID;
						var itemElement = document.getElementById(itemId);
						if (itemElement != undefined)
						{
							itemElement.remove();
							ScreenDesigner.HighlightActiveDesign();
						}
						else
						{
							console.log("ScreenDesigner_Collection_HandleItemSelected: Could not find '" + itemId + "' to remove");
						}

						ScreenDesigner.UpdateInfoDisplays();
					}
				}
			}
			else
			{
				console.log("ScreenDesigner_Collection_HandleItemSelected: unknown cmd: '" + cmd + "'");
			}
		}
		else
		{
			console.log("ScreenDesigner_Collection_HandleItemSelected: could not find design '" + designID + "' for command '" + cmd + "'");
		}
	}

	var ScreenDesigner_Collection_CreateMenuIcon = function()
	{
		// Programmatically create an image of triangle for the popup menu.
		// Returns the PNG data.
		//
		var iconW = 12;
		var iconH = 6;
		
		var aCanvas = document.createElement('canvas');
		aCanvas.width  = iconW;
		aCanvas.height = iconH;

		var aContext = aCanvas.getContext("2d");
		aContext.clearRect(0, 0, iconW, iconH);
		aContext.lineWidth = 1;
		aContext.strokeStyle = "rgba(0, 0, 0, 0.75)";
		aContext.beginPath();
		
		for (var i = 0; i < iconH; i++)
		{
			aContext.moveTo(i, i);
			aContext.lineTo(iconW - i, i);
		}
		
		aContext.stroke();
				
		var pngData = aCanvas.toDataURL("image/png");
		return pngData;
	}


	var ScreenDesigner_Collection_ShowPopupMenu = function(designID, collectionIDchar)
	{
		// Show the popup menu list for the associated designID and also 
		// set the display attribute of the popup menu button to keep it
		// shown even when the mouse moves off of the workbook item. This
		// is necessary because the button is shown with the 'hover' pseudo-class
		// and now that the menu is shown, we don't want the button to disappear.
		//
		var menuBtn =  document.getElementById("P" + collectionIDchar + designID);
		if (menuBtn != undefined)
			menuBtn.style.display = 'block';

		var menuList = document.getElementById("M" + collectionIDchar + designID);
		if (menuList != undefined)
			menuList.style.display = 'block';
			
		if (menuBtn == undefined || menuList == undefined)
			console.log("ScreenDesigner_Collection_ShowPopupMenu: could not find menu button and/or menu list for designID:" + designID + " collectionID: '" + collectionIDchar + "'");
	}

	var ScreenDesigner_Collection_HideAllPopupMenus = function(evt)
	{
		// Hide all workbook popup menus, except if the popup menu button
		// was clicked-on, then keep that one open
		//

		// Determine if the click was on a popup menu button		
		var keepOpen = undefined;
		if (evt.target.matches('.WorkbookItemPopup'))
			keepOpen = evt.target.id.substring(1);
		
		// Iterate over the menu lists and close them all, unless one matches the 
		// clicked on popup button
		var menuLists = document.getElementsByClassName("WorkbookItemMenuList");
		for (var i = 0; i < menuLists.length; i++)
		{
			if (keepOpen == undefined || keepOpen != menuLists[i].id.substring(1))
				menuLists[i].removeAttribute("style");
		}

		// Iterate over the popup button and close them all, unless one matches the 
		// clicked on button
		var menuBtns = document.getElementsByClassName("WorkbookItemPopup");
		for (var i = 0; i < menuBtns.length; i++)
		{
			if (keepOpen == undefined || keepOpen != menuBtns[i].id.substring(1))
				menuBtns[i].removeAttribute("style");
		}
	}
	
	return {
		AddItem:				ScreenDesigner_Collection_AddItem,
		SetItemDesign:			ScreenDesigner_Collection_SetItemDesign,
		HideAllPopupMenus: 		ScreenDesigner_Collection_HideAllPopupMenus,
		SetLoadFailure:			ScreenDesigner_Collection_SetLoadFailure,
		ClearLoadFailure:		ScreenDesigner_Collection_ClearLoadFailure,
		ShowLoading:			ScreenDesigner_Collection_ShowLoading
	}
	
}());


//---------------------------------------------------------------------------
//	ScreenDesigner_Workbook
//---------------------------------------------------------------------------
var ScreenDesigner_Workbook = (function() {

	var appWorkbook = undefined;
	var workbookCollectionIDchar = "w";
	
	var ScreenDesigner_Workbook_GetDesignCount = function()
	{
		return (appWorkbook != undefined) ? appWorkbook.designs.length : 0;
	}
	
	var ScreenDesigner_Workbook_GetDataForDesignID = function(designID)
	{
		var designData = undefined;
		
		if (appWorkbook != undefined)
		{
			for (var i = 0; i < appWorkbook.designs.length && designData == undefined; i++)
				if (appWorkbook.designs[i].designID == designID)
					designData = appWorkbook.designs[i];
		}
				
		return designData;
	}
	
	
	var ScreenDesigner_Workbook_UI_ShowCurrentActive = function(designData)
	{
		// If the current appData is in the workbook, then show it as active
		var imgs = document.getElementsByClassName("WorkbookItemCurrent");
		while (imgs.length > 0)
			imgs[0].classList.remove("WorkbookItemCurrent");

		var eTab = document.getElementById("ID_WorkbookTabAreaL");
		eTab.classList.remove("CL_TabLabelHighlight");
		
		if (ScreenDesigner_Workbook_IsDesignInAppWorkbook(designData))
		{
			var id = ("E" + workbookCollectionIDchar + designData.designID);
			var img = document.getElementById(id);
			if (img != undefined)
				img.classList.add("WorkbookItemCurrent");
			else
				console.log("ScreenDesigner_Workbook_UI_ShowCurrentActive: design '" + id + "' not found in UI");
				
			// Show that the active design is in a workbook
			eTab.classList.add("CL_TabLabelHighlight");
		}
	}

	var ScreenDesigner_Workbook_UpdateDesignIfInWorkbook = function(designData)
	{
		// If the current design is in the workbook, then update its image
		if (ScreenDesigner_Workbook_IsDesignInAppWorkbook(designData))
			ScreenDesigner_Workbook_UI_UpdateOne(designData, 5000 /* initial delay */);
	}

	var ScreenDesigner_Workbook_UI_UpdateOne = function(design, initialDelay)
	{
		// Updates the design in the workbook UI.
		// The design must already exist in the UI and in the app's workbook
		
		if (ScreenDesigner_Workbook_IsDesignInAppWorkbook(design))
		{
			var id = ("E" + workbookCollectionIDchar + design.designID);
			var img = document.getElementById(id);
			if (img != undefined)
			{
				var pngData = ScreenDesignerItemRenderer.RenderPNG(design, {x:60, y:60});
				img.src = pngData;
				
				// 2018.01.09: Use a thread to render a thumbnail
				var workbookItemDim = {size:{x:58, y:58}, margin: 2}; // SECOND definition of this!!!
				ScreenDesigner_RenderRequest.RenderThumbnailDesign(design, img, initialDelay, workbookItemDim, {priority:TaskPriority.WORKBOOK_THUMBNAIL});
			}
			else
			{
				console.log("ScreenDesigner_Workbook_UI_UpdateOne: design '" + id + "' not found in UI");
			}
		}
		else
		{
			console.log("ScreenDesigner_Workbook_UI_UpdateOne: design does not exist in app's workbook");
		}
	}

	var ScreenDesigner_Workbook_UI_UpdateDesignCount = function()
	{
		var e = document.getElementById("ID_WorkbookInfo");
	
		if (e != undefined)
		{
			var count = appWorkbook != undefined ? appWorkbook.designs.length : 0;
			var str = count + ((count == 1) ? " design" : " designs");
			e.innerHTML = str;
		}
		else
		{
			console.log("ScreenDesigner_Workbook_UI_UpdateDesignCount: Can't find ID_WorkbookInfo");
		}
	}
	

	var ScreenDesigner_Workbook_UI_AddOne = function(design)
	{
		// Adds one design to the workbook UI. Note that this only
		// updates the UI, and not the workbook list
		// The design must already exist in the app's workbook
		//

		if (ScreenDesigner_Workbook_IsDesignInAppWorkbook(design))
		{
			var contents = document.getElementById("ID_WorkbookContent");
			if (contents != undefined)
			{
				ScreenDesigner_Collection.AddItem(design, design.designID, contents, ScreenDesigner_Workbook_UI_GetThumbnailInfo, ScreenDesigner_Workbook, workbookCollectionIDchar);
			
				// Render the tile into the 'img' element
				ScreenDesigner_Workbook_UI_UpdateOne(design, 250 /* initial delay */);
			}
		}
		else
		{
			console.log("ScreenDesigner_Workbook_UI_AddOne: design does not exist in app's workbook");
		}
	}

	var ScreenDesigner_Workbook_UI_AddAll = function(workbook)
	{
		// (Re-)Builds the UI for the workbook. 
		// Pass undefined to clear the workbook items from the UI
		// Does not update the app's workbook reference (that is, does not store 'workbook')
		
		var contents = document.getElementById("ID_WorkbookContent");
		if (contents != undefined)
		{
			// Remove all items on the page in the workbook Content area
			while (contents.hasChildNodes())
    			contents.removeChild(contents.lastChild);
			
			// Add the designs if a workbook was provided
			if (workbook != undefined)
			{
				for (var i = 0; i < workbook.designs.length; i++)
					ScreenDesigner_Workbook_UI_AddOne(workbook.designs[i]);
			}
			
			ScreenDesigner_Workbook_UI_UpdateDesignCount(); // 2018.01.22
		}
	}
	
	var ScreenDesigner_Workbook_UI_GetThumbnailInfo = function(design)
	{
		// 2018.01.23: Added
		//
		var info = undefined;
		var count = appWorkbook.designs.length;
		var index = undefined;		

		for (var i = 0; i < appWorkbook.designs.length && index == undefined; i++)
			if (appWorkbook.designs[i].designID == design.designID)
				index = i;
		
		if (index != undefined)
		{
			if (design.general.note != undefined)
				info = design.general.note + " (" + (index + 1) + " of " + count + ")";
			else
				info = "Design " + (index + 1) + " of " + count;
		}
		else
			console.log("ScreenDesigner_Workbook_UI_GetThumbnailInfo: design id " + design.designID + " not found in workbook.");
		
		return info;
	}
	
	var ScreenDesigner_Workbook_Create = function()
	{
		// Create an empty workbook
		var workbook = new ScreenDesignerWorkbook();
		
		return workbook;		
	}
	
	
	var ScreenDesigner_Workbook_AssignDesignID = function(designData)
	{
		// Assigns next id to the design. The design must be in the app's workbook
		if (ScreenDesigner_Workbook_IsDesignInAppWorkbook(designData))
		{
			designData.designID = appWorkbook.nextID;
			appWorkbook.nextID++;
		}
		else
		{
			console.log("ScreenDesigner_Workbook_AssignDesignId: design not in workbook");
		}
	}

	var ScreenDesigner_Workbook_RebuildDesignIDs = function()
	{
		// Creates a unique ids for each design, which are used when 
		// the user clicks on the designs in the UI.
		if (appWorkbook != undefined)
		{		
			// Clear all previous design Ids, just in case
			for (var i = 0; i < appWorkbook.designs.length; i++)
				appWorkbook.designs[i].designID = undefined;
				
			appWorkbook.nextID = 1;

			for (var i = 0; i < appWorkbook.designs.length; i++)
				ScreenDesigner_Workbook_AssignDesignID(appWorkbook.designs[i]);
		}
		else
		{
			console.log("ScreenDesigner_Workbook_RebuildDesignIds: workbook is undefined");
		}
	}


	var ScreenDesigner_Workbook_Test = function()
	{
		var workbook = ScreenDesigner_Workbook_Create();
		
		ScreenDesigner_Workbook_Use(workbook);
		
		for (var i = 0; i < 5; i++)
		{
			var design = ScreenDesigner_NewScreenDesignerData();
			design.tiling.shape = (i % 4);
			
			ScreenDesigner_Workbook_AddDesign(design);
		}
	}

	var ScreenDesigner_Workbook_IsDesignInAppWorkbook = function(designData)
	{
		var containsDesign = false;
		
		if (appWorkbook != undefined)
			containsDesign = appWorkbook.designs.includes(designData)
			
		return containsDesign;
	}
	
	var ScreenDesigner_Workbook_IsDirty = function()
	{
		// Returns true if the app's workbook needs saving
		var isDirty = false;
		
		if (appWorkbook != undefined)
		{
			isDirty = appWorkbook.dirty;
			
			// Loop until we find the first dirty design
			for (var i = 0; i < appWorkbook.designs.length && !isDirty; i++)
				isDirty = appWorkbook.designs[i].dirty;
		}
		
		return isDirty;
	}

	var ScreenDesigner_Workbook_Use = function(workbook)
	{
		// Sets the provided workbook at the current workbook in the app. 
		// Does not check if the app's workbook is dirty before overriding it.
		appWorkbook = workbook;
		
		// Create design Ids for the items before adding them to the UI
		ScreenDesigner_Workbook_RebuildDesignIDs();
		
		ScreenDesigner_Workbook_UI_AddAll(workbook);
	}
	
	var ScreenDesigner_Workbook_Close = function()
	{
		// Closes the app's workbook. 
		// Does not check if the workbook is dirty.
		appWorkbook = undefined;
		ScreenDesigner_Workbook_UI_AddAll(undefined);
	}
	
	var ScreenDesigner_Workbook_Save = function()
	{
		// Save the workbook
		//
		if (appWorkbook != undefined && appWorkbook.designs.length > 0)
		{
			var name = ScreenDesignerFileIO.GetWorkbookName();
			ScreenDesignerFileIO.SaveAsTXT(appWorkbook, name);
			ScreenDesigner_Workbook_MarkAsClean();
		}
		else
		{
			alert("No open workbook to save.");
		}
	}

	var ScreenDesigner_Workbook_MarkAsClean = function()
	{
		appWorkbook.dirty = false;
		
		for (var i = 0; i < appWorkbook.designs.length; i++)
			ScreenDesigner.DesignClearDirty(appWorkbook.designs[i]); // 2018.07.18: Use routine instead of setting directly
	}
	
	var ScreenDesigner_Workbook_AddDesign = function(designData, showAsCurrent)
	{
		// Add's the provided designData object to the app's workbook.
		// If no workbook exists, then it is created.
		
		if (appWorkbook == undefined)
			appWorkbook = ScreenDesigner_Workbook_Create();
		
		if (appWorkbook.designs.includes(designData))
		{
			console.log("ScreenDesigner_Workbook_AddDesign: design already in workbook");
		}
		else
		{
			appWorkbook.designs.push(designData);
			appWorkbook.dirty = true;
			ScreenDesigner_Workbook_AssignDesignID(designData);
			ScreenDesigner_Workbook_UI_AddOne(designData); 
			ScreenDesigner_Workbook_UI_UpdateDesignCount(); // 2018.01.22
			if (showAsCurrent)
				ScreenDesigner.HighlightActiveDesign();
		}
	}

	var ScreenDesigner_Workbook_SetCurrentDesignRef = function(designRef)
	{
		//console.log("ScreenDesigner_Workbook_SetCurrentDesignRef: " + designRef);
	}	

	var ScreenDesigner_Workbook_MissingDesignData = function(designRef)
	{
		console.log("ScreenDesigner_Workbook_MissingDesignData: " + designRef);
	}	

	var ScreenDesigner_Workbook_RemoveDesignAndUpdate = function(designData, designRef)
	{
		ScreenDesigner_Workbook_RemoveDesign(designData);
		
		return true /* always successful */;
	}

	var ScreenDesigner_Workbook_RemoveDesign = function(designData)
	{
		// Remove the specified design from the app workbook
		//
		
		if (appWorkbook != undefined)
		{
			var idx = appWorkbook.designs.indexOf(designData);
			if (idx > -1)
			{
				appWorkbook.designs.splice(idx, 1);
				appWorkbook.dirty = true;
			}
		}
		else
		{
			console.log("ScreenDesigner_Workbook_RemoveDesign: app workbook is undefined.")
		}
	}


	var ScreenDesigner_Workbook_UpdateCommonParameter = function(controlInfo, value)
	{
		// DDK 2018.01.19: Added

		for (var i = 0; i < appWorkbook.designs.length; i++)
		{
			var designData = appWorkbook.designs[i]; 
			if (designData == appData)
			{
				ScreenDesigner_UpdateParameter(controlInfo, value);
				ScreenDesigner_UpdateUIforParameterChange(controlInfo, value);
			}
			else
			{
				if (controlInfo.cat in designData)
				{
					designData[controlInfo.cat][controlInfo.prop] = value;
					ScreenDesigner_Workbook_UI_UpdateOne(designData, 5000);
				}
			}
		}
	}

	var ScreenDesigner_Workbook_ExportAllAsPNG = function()
	{
		// Save all of the items in the workbook at PNG files
		// 2018.01.22: Added
		
		// Cancel any previous 'export all' jobs
		ScreenDesigner_Workbook_CancelExportAll();
		
		for (var i = 0; i < appWorkbook.designs.length; i++)
		{
			var designData = appWorkbook.designs[i]; 
			ScreenDesigner_RenderRequest.RenderAndSaveDesign(designData,  (i + 1));
		}
	}
	
	var ScreenDesigner_Workbook_CancelExportAll = function()
	{
		ScreenDesigner_RenderQueue_CancelTasksWithPriority(TaskPriority.WORKBOOK_EXPORT_ALL); 
	}

	var ScreenDesigner_Workbook_SetObjectPrototypes = function(workbook)
	{
		if (workbook["reserved"] == ScreenDesignerDataType.WORKBOOK_DATA)
		{
			Object.setPrototypeOf(workbook, ScreenDesignerWorkbook.prototype);
			for (var i = 0; i < workbook.designs.length; i++)
			{
				Object.setPrototypeOf(workbook.designs[i], ScreenDesignerData.prototype);
				workbook.designs[i].AddMissingProperties();
				workbook.designs[i].Validate(); // 2018.01.11: Added
				ScreenDesigner.DesignClearDirty(workbook.designs[i]); // 2018.07.18: Use routine instead of setting directly
			}
		}
		else
		{
			console.log("ScreenDesigner_Workbook_SetObjectPrototypes: workbook 'reserved' flag does not match");
		}
	}
	
	return {
		Use:						ScreenDesigner_Workbook_Use,
		Save:						ScreenDesigner_Workbook_Save,
		Close:						ScreenDesigner_Workbook_Close,
		SetObjectPrototypes:		ScreenDesigner_Workbook_SetObjectPrototypes,
		AddDesign:					ScreenDesigner_Workbook_AddDesign,
		IsDirty:					ScreenDesigner_Workbook_IsDirty,
		GetDataForDesignID:			ScreenDesigner_Workbook_GetDataForDesignID,
		RemoveDesignAndUpdate:		ScreenDesigner_Workbook_RemoveDesignAndUpdate,
		SetCurrentDesignRef:		ScreenDesigner_Workbook_SetCurrentDesignRef,
		MissingDesignData:			ScreenDesigner_Workbook_MissingDesignData,
		GetDesignCount:				ScreenDesigner_Workbook_GetDesignCount,
		
		UI_ShowCurrentActive:		ScreenDesigner_Workbook_UI_ShowCurrentActive,
		ExportAllAsPNG:				ScreenDesigner_Workbook_ExportAllAsPNG,
		IsDesignInAppWorkbook:		ScreenDesigner_Workbook_IsDesignInAppWorkbook,
		UpdateDesignIfInWorkbook:	ScreenDesigner_Workbook_UpdateDesignIfInWorkbook,
		UpdateCommonParameter:		ScreenDesigner_Workbook_UpdateCommonParameter,
		UI_UpdateDesignCount:		ScreenDesigner_Workbook_UI_UpdateDesignCount
	}
	
}());


//---------------------------------------------------------------------------
//	Project (cloud) API 
//---------------------------------------------------------------------------
var ScreenDesigner_StorageMgr = (function() {

	var appStorageInfo = undefined;
	var projectCollectionIDchar = "p";
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_Open = function()
	{
		//console.log("ScreenDesigner_StorageMgr_Open");
		
		// Create the object to track info for the storage manager
		appStorageInfo = {};
		appStorageInfo.currentProjectRef = undefined;
		appStorageInfo.currentDesignRef = undefined;
		appStorageInfo.uploadTimer = undefined;
		appStorageInfo.uploadInterval = 30*1000; /* ms */
		
		// Open the storage manager, and catch any errors
		try
		{
			StorageManager.Open()
			.then ( () =>	
				{
					//console.log("[then] ScreenDesigner_StorageMgr_Open: GetProjectList");
					var userSettings = StorageManager.GetUserSettings();
					var projectList = StorageManager.GetProjectList();
					//console.log("userSettings: " + JSON.stringify(userSettings) + ", projectList: " + JSON.stringify(projectList));
				
					// If there are no projects, then create the initial project
					if (projectList.length == 0)
					{
						var projRef = StorageManager.CreateProject();
						appStorageInfo.currentProjectRef = projRef;
					
					}
					// (TEMPORARY) Otherwise, default to editing the first design of the first project
					else
					{
						//console.log("projectList: " + JSON.stringify(projectList));
						// Open the most recently opened project if specified and it exists
						var projRef = userSettings.lastProjRef;
						if (projRef == undefined || !projectList.includes(projRef))
						{
							projRef = projectList[0];
							ScreenDesigner_StorageMgr_UpdateLastProjectRef(projRef);
						}

						appStorageInfo.currentProjectRef = projRef;
					}
				
					return StorageManager.Project_Load(appStorageInfo.currentProjectRef);
				})
			.then ( (projectRef) => 
				{
					ScreenDesigner_StorageMgr_ValidateProjectInfo(appStorageInfo.currentProjectRef);
					
					var designList = StorageManager.Project_GetDesignList(appStorageInfo.currentProjectRef);

					if (designList.length == 0)
					{
						appStorageInfo.currentDesignRef = ScreenDesigner_StorageMgr_CreateDesign(appStorageInfo.currentProjectRef);
					}
					else
					{
						// Open most recently viewed design
						var projectInfo = StorageManager.Project_GetInfo(appStorageInfo.currentProjectRef);
						//console.log("projectInfo: " + JSON.stringify(projectInfo));
						var designRef = projectInfo.lastDesignRef;
						if (designRef == undefined || !designList.includes(designRef))
						{
							designRef = designList[0];
							ScreenDesigner_StorageMgr_UpdateLastDesignRef(designRef);
						}

						appStorageInfo.currentDesignRef = designRef;
					}

					// Update UI for any project-specific fields
					ScreenDesigner_StorageMgr_PopulateProjectUI();
					
					// Show the designs in the current project and edit the last viewed design
					ScreenDesigner_Project_LoadDesigns(appStorageInfo.currentProjectRef, appStorageInfo.currentDesignRef);
				} )
			.then ( () => 
				{
					ScreenDesigner_StorageMgr_StartUploadTask();
				} )
			.catch (err =>	
				{
					console.log("ScreenDesigner_StorageMgr_Open error: " + err);
				});
		}
		catch (err)
		{
			console.log("ScreenDesigner_StorageMgr_Open exception catch: " + err);
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_Rebuild = async function()
	{
		//console.log("ScreenDesigner_StorageMgr_Rebuild");
		//console.log(JSON.stringify(StorageManager.GetProjectList()));

		await StorageManager.Rebuild()

		//console.log("Rebuilt project list");
		//console.log(JSON.stringify(StorageManager.GetProjectList()));
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_CreateProjectInfo = function()
	{
		// Create the object that holds the project info and is stored with the project
		//
		// projectInfo
		//   name
		//   lastDesignRef
		
		var info = {};
		
		info.name = "untitled";
		
		return info;
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_ValidateProjectInfo = function(projectRef)
	{
		if (StorageManager.Project_IsLoaded(projectRef))
		{
			var projectInfo = StorageManager.Project_GetInfo(projectRef);
			if (projectInfo == undefined)
			{
				projectInfo = ScreenDesigner_StorageMgr_CreateProjectInfo();
				StorageManager.Project_SetInfo(projectRef, projectInfo);
			}
		}
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UpdateLastProjectRef = function(projectRef)
	{
		// Remember most recently opened project
		var userSettings = StorageManager.GetUserSettings();
		userSettings.lastProjRef = Number(projectRef);
		StorageManager.StoreUserSettings();
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UpdateLastDesignRef = function(designRef)
	{
		// Remember most recently viewed/edited design
		var projectInfo = StorageManager.Project_GetInfo(designRef);
		projectInfo.lastDesignRef = Number(designRef);
		StorageManager.Project_MarkDirty(designRef);
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_LoadProject = function(projectRef, forceEditMostRecent = false)
	{
		// We need to set this flag here because changing the "currentProjectRef"
		// will cause this "CurrentDesignIsStandalone" function to return an
		// incorrect result.
		// We also allow the caller to ignore the 'is standalone' setting. This is another
		// workaround needed, in this case when a project is deleted and the design is
		// orphaned. The correct solution is to support a "no design" state
		var editMostRecentDesign = forceEditMostRecent || !ScreenDesigner.CurrentDesignIsStandalone();
		
		appStorageInfo.currentProjectRef = projectRef;
		
		ScreenDesigner_StorageMgr_CancelUploadTask();
		
		ScreenDesigner_StorageMgr_UpdateLastProjectRef(projectRef);
		
		StorageManager.Project_Load(appStorageInfo.currentProjectRef)
		.then ( (projectRef) => 
			{
				ScreenDesigner_StorageMgr_ValidateProjectInfo(appStorageInfo.currentProjectRef);
				
				var projectInfo = StorageManager.Project_GetInfo(appStorageInfo.currentProjectRef);				
				var designList = StorageManager.Project_GetDesignList(appStorageInfo.currentProjectRef);
				
				if (gDebugLogging)
				{
					console.log("Project loaded: " + projectRef + " (" + projectRef / 65536 + ")" );
					console.log(JSON.stringify(projectInfo));
					console.log(JSON.stringify(designList));
					console.log(JSON.stringify(designList.map(x => x % 65536)));
				}				

				if (designList.length == 0)
					appStorageInfo.currentDesignRef = ScreenDesigner_StorageMgr_CreateDesign(appStorageInfo.currentProjectRef);
				else
				{
					// Open most recently viewed design
					var designRef = projectInfo.lastDesignRef;
					if (designRef == undefined || !designList.includes(designRef))
					{
						designRef = designList[0];
						ScreenDesigner_StorageMgr_UpdateLastDesignRef(designRef);

					}

					appStorageInfo.currentDesignRef = designRef;
				}

				// Update UI for any project-specific fields
				ScreenDesigner_StorageMgr_PopulateProjectUI();
				
				// Show the designs in the current project. If the current design is not a stand-alone design, then
				// edit the design that was most recently viewed
				var designToEdit = editMostRecentDesign ? appStorageInfo.currentDesignRef : undefined;
				ScreenDesigner_Project_LoadDesigns(appStorageInfo.currentProjectRef, designToEdit);
			} )
		.then ( () => 
			{
				ScreenDesigner_StorageMgr_StartUploadTask();
				ScreenDesigner.UpdateInfoDisplays();
			} )
		.catch (err =>	
			{
				console.log("ScreenDesigner_StorageMgr_LoadProject error: " + err);
			});
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_CreateDesign = function(projectRef)
	{
		var designRef;

		designRef = StorageManager.Project_CreateDesign(projectRef);
		var designData = ScreenDesigner.NewScreenDesignerData();
		StorageManager.Design_SetData(designRef, designData);
		
		return designRef;
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_Close = function()
	{
		ScreenDesigner_StorageMgr_CancelUploadTask();
		
		appStorageInfo.currentProjectRef = undefined;
		appStorageInfo.currentDesignRef = undefined;
		
		StorageManager.Close();
		
		// 2019.10.14: Clear the UI
		ScreenDesigner_StorageMgr_ClearProjectUI();
		var contents = document.getElementById("ID_ProjectContent");
		if (contents != undefined)
		{
			// Remove all items on the page in the Content area
			while (contents.hasChildNodes())
    			contents.removeChild(contents.lastChild);
    	}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_StartUploadTask = function()
	{
		appStorageInfo.uploadTimer = setTimeout(ScreenDesigner_StorageMgr_UploadTask, appStorageInfo.uploadInterval /*ms*/);
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_CancelUploadTask = function()
	{
		if (appStorageInfo.uploadTimer != undefined)
		{
			clearTimeout(appStorageInfo.uploadTimer);
			appStorageInfo.uploadTimer = undefined;
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UploadTask = function()
	{
		appStorageInfo.uploadTimer = undefined;
		
		ScreenDesigner_StorageMgr_UploadChanges();
		
		ScreenDesigner_StorageMgr_StartUploadTask();
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_NewProject = function(forceEditNewDesign = false)
	{
		ScreenDesigner.DisplayMessageBanner("New project created.");
		
		// We need to set this flag here because changing the "currentProjectRef"
		// will cause this "CurrentDesignIsStandalone" function to return an
		// incorrect result
		// The forceEditNewDesign works around a bug where deleting a project leaves
		// an orphaned design.
		var editMostRecentDesign = forceEditNewDesign || !ScreenDesigner.CurrentDesignIsStandalone();

		var projRef = StorageManager.CreateProject();
		appStorageInfo.currentProjectRef = projRef;
		
		ScreenDesigner_StorageMgr_UpdateLastProjectRef(projRef);
					
		StorageManager.Project_Load(appStorageInfo.currentProjectRef)
		.then ( (projectRef) => 
			{
				appStorageInfo.currentDesignRef = ScreenDesigner_StorageMgr_CreateDesign(appStorageInfo.currentProjectRef);

				// Show the designs in the new project. If the current design is not a stand-alone design, then
				// edit the design that was most recently viewed
				var designToEdit = editMostRecentDesign ? appStorageInfo.currentDesignRef : undefined;
				ScreenDesigner_Project_LoadDesigns(appStorageInfo.currentProjectRef, designToEdit);

				// 2022.04.14: Save the design to the server to fix the issue where the first design has no data if a second design is immediately created
				StorageManager.Design_Store(appStorageInfo.currentDesignRef, true /* force */);
			} )
		.catch (err =>	
			{
				console.log("ScreenDesigner_StorageMgr_Open error: " + err);
			});
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_DeleteCurrentProject = function()
	{
		// Determine if the current design being edited is in the project
		// We have to ask the ScreenDesigner, because the StorageMgr does
		// not know which design is being edited
		var forceEditOfDesign = ScreenDesigner.CurrentDesignIsInProject();
		
		// Clear the project reference and clear the project
		var deleteProjRef = appStorageInfo.currentProjectRef;
		appStorageInfo.currentProjectRef = undefined;
		StorageManager.DeleteProject(deleteProjRef);

		// If there are no other projects, then create a new, empty project
		if (ScreenDesigner_StorageMgr_GetProjectCount() == 0)
		{
			ScreenDesigner_StorageMgr_NewProject(forceEditOfDesign /* force edit of new design */);
		}
		// Otherwise, select the first project in the list
		else
		{
			var projectList = StorageManager.GetProjectList();
			var projRef = projectList[0];
			ScreenDesigner_StorageMgr_LoadProject(projRef, forceEditOfDesign /* force edit of loaded design */);
		}
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_EditDesign = function(designRef)
	{
		//console.log("ScreenDesigner_StorageMgr_EditDesign: " + designRef);

		try {
			var designData = StorageManager.Design_GetData(designRef);
			ScreenDesigner.DesignClearDirty(designData); // Clear dirty flag in case it was saved
			ScreenDesigner.SetCurrentDesignData(designData);
		}
		catch (error)
		{
			console.log("ScreenDesigner_StorageMgr_EditDesign error: " + error);
		}
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_ShowProjects = function()
	{
		// This will build the UI for the project list.
		var projectList = StorageManager.GetProjectList();
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UploadChanges = function()
	{
		if (appStorageInfo.currentDesignRef != undefined)
		{
			var designData = StorageManager.Design_GetData(appStorageInfo.currentDesignRef);
			
			if (designData != undefined)
			{
				if (ScreenDesigner.DesignIsDirty(designData))
				{
					ScreenDesigner.DesignClearDirty(designData);
					StorageManager.Design_MarkDirty(appStorageInfo.currentDesignRef);
					StorageManager.Design_Store(appStorageInfo.currentDesignRef);
					ScreenDesigner.UpdateInfoDisplays();
				}
			}
		}
		
		// Store changed project settings
		if (appStorageInfo.currentProjectRef != undefined && StorageManager.Project_IsDirty(appStorageInfo.currentProjectRef))
		{
			StorageManager.Project_Store(appStorageInfo.currentProjectRef);
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_GetDataForDesignID = function(designID)
	{
		var designData = undefined;
		//console.log("ScreenDesigner_StorageMgr_GetDataForDesignID: " + designID);
		
		designData = StorageManager.Design_GetData(designID);
		
		return designData;
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_SetCurrentDesignRef = function(designRef)
	{
		//console.log("ScreenDesigner_StorageMgr_SetCurrentDesignRef: " + designRef);
		
		if (designRef != appStorageInfo.currentDesignRef)
			ScreenDesigner_StorageMgr_UploadChanges();
			
		if (designRef != undefined)
		{
			// Validate
			var designList = StorageManager.Project_GetDesignList(appStorageInfo.currentProjectRef);
			var found = false;
			for (var i = 0; i < designList.length && !found; i++)
				found = (designList[i] == designRef);
				
			if (!found)
			{
				console.log("ScreenDesigner_StorageMgr_SetCurrentDesignRef: Could not find designRef");
				appStorageInfo.currentDesignRef = undefined;
			}
			else
			{
				appStorageInfo.currentDesignRef = designRef;
				
				// Store most recently edited designRef
				ScreenDesigner_StorageMgr_UpdateLastDesignRef(designRef);
			}
			
		}
		else
		{
			appStorageInfo.currentDesignRef = undefined;
		}
	}	

	//---------------------------------------------------------------------------
	//	Storage Mgr: Missing Design Data
	//	2019.10.13: Added
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_MissingDesignData = function(designRef)
	{
		//console.log("ScreenDesigner_StorageMgr_MissingDesignData: " + designRef);
		ScreenDesigner_Collection.ClearLoadFailure(designRef, projectCollectionIDchar);
		ScreenDesigner_Collection.ShowLoading(designRef, projectCollectionIDchar);
		ScreenDesigner_Project_LoadDesign(designRef);
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_RemoveDesignAndUpdate = function(designData, designRef)
	{
		var successfulRemove = false;
		
		//console.log("ScreenDesigner_StorageMgr_RemoveDesignAndUpdate: designRef:" + designRef + ", currentDesignRef:" + appStorageInfo.currentDesignRef);
		
		StorageManager.Project_DeleteDesign(designRef);
		successfulRemove = true;

		// 2019.10.14: Added message for designs that are in the editor, but no longer in the project
		if (designRef == appStorageInfo.currentDesignRef)
			ScreenDesigner.DisplayMessageBanner("Current design removed from project.", {position:"bottom"}); 

		return successfulRemove;
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_NewDesign = function()
	{
		console.log("ScreenDesigner_StorageMgr_NewDesign");
		
		ScreenDesigner_StorageMgr_UploadChanges();

		var designRef = ScreenDesigner_StorageMgr_CreateDesign(appStorageInfo.currentProjectRef);
		var designData = ScreenDesigner_StorageMgr_GetDataForDesignID(designRef);
				
		appStorageInfo.currentDesignRef = designRef;
		ScreenDesigner.SetCurrentDesignData(designData);
		ScreenDesigner_Project_UI_AddOne(designData, designRef)
		
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_AddDesign = function(designData, makeCurrent = true)
	{
		ScreenDesigner_StorageMgr_UploadChanges();

		if (!ScreenDesigner_StorageMgr_IsDesignInProject(designData))
		{
			var designRef = ScreenDesigner_StorageMgr_CreateDesign(appStorageInfo.currentProjectRef);
			StorageManager.Design_SetData(designRef, designData);
			StorageManager.Design_Store(designRef, true /* force */);
		
			if (makeCurrent)
				appStorageInfo.currentDesignRef = designRef;
			
			ScreenDesigner_Project_UI_AddOne(designData, designRef);
		}
		else
		{
			console.log("ScreenDesigner_StorageMgr_AddDesign: design already in project");
		}
		
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_UI_AddOne = function(design, designRef)
	{
		// Adds one design to the workbook UI. Note that this only
		// updates the UI
		//
		// design may be undefined if the design is not yet loaded

		var contents = document.getElementById("ID_ProjectContent");
		if (contents != undefined)
		{
			try {
				ScreenDesigner_Collection.AddItem(design, designRef, contents, ScreenDesigner_Project_UI_GetThumbnailInfo, ScreenDesigner_StorageMgr, projectCollectionIDchar);
		
				// Render the tile into the 'img' element
				if (design != undefined)
					ScreenDesigner_Project_UI_UpdateOne(design, designRef, {initialDelay:250});
			}
			catch (error)
			{
				console.log("ScreenDesigner_Project_UI_AddOne error: " + error);
			}
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_UI_SetItemDesign = function(design, designRef)
	{
		ScreenDesigner_Collection.SetItemDesign(design, designRef, projectCollectionIDchar);
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_UI_SetLoadFailure = function(designRef)
	{
		ScreenDesigner_Collection.SetLoadFailure(designRef, projectCollectionIDchar);
	}

	//---------------------------------------------------------------------------
	//	2022.04.12: Replace initialDelay and idPrefix with config param;
	//	added generateThumbnail flag
	//		config: {initialDelay:0, idPrefix:"E", generateThumbnail:true}
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_UI_UpdateOne = function(design, designRef, config)
	{
		var initialDelay = (config != undefined && config.initialDelay != undefined) ? config.initialDelay : 0;
		var idPrefix = (config != undefined && config.idPrefix != undefined) ? config.idPrefix : "E";
		var generateThumbnail = (config != undefined && config.generateThumbnail != undefined) ? config.generateThumbnail : true;

		var id = (idPrefix + projectCollectionIDchar + designRef);
		
		//console.log("ScreenDesigner_Project_UI_UpdateOne: design '" + id + "'");
		//console.log(JSON.stringify(design));
		// 2019.10.14: Test for empty object
		if (design == undefined || Object.keys(design).length == 0)
		{
			console.error("ScreenDesigner_Project_UI_UpdateOne: design '" + id + "' is undefined");
		}
		else
		{
			try {
				var img = document.getElementById(id);
				if (img != undefined)
				{
					var pngData = ScreenDesignerItemRenderer.RenderPNG(design, {x:60, y:60});
					img.src = pngData;

					// 2022.04.12: Added flag to optionally generate thumbnail since we can now load the thumbnail from the server
					if (generateThumbnail)
					{
						// 2018.01.09: Use a thread to render a thumbnail
						var workbookItemDim = {size:{x:58, y:58}, margin: 2}; // SECOND definition of this!!!
						// 2022.04.11: Using new config param to pass callback so we can store the thumbnail
						let config = {userCallback: ScreenDesigner_Project_ThumbnailReady, userRef:designRef, priority:TaskPriority.WORKBOOK_THUMBNAIL};
						ScreenDesigner_RenderRequest.RenderThumbnailDesign(design, img, initialDelay, workbookItemDim, config);
					}
				}
				else
				{
					console.log("ScreenDesigner_Project_UI_UpdateOne: design '" + id + "' not found in UI");
				}
			}
			catch (err) {
				console.log("ScreenDesigner_Project_UI_UpdateOne error: " + err + ", stack: " + err.stack);
			}
		}
	}

	//---------------------------------------------------------------------------
	//	 Project UI: Update Thumbnail
	//		2022.04.11: Created
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_UI_UpdateThumbnail = function(designRef, pngData, idPrefix = "E")
	{
		var id = (idPrefix + projectCollectionIDchar + designRef);

		try {
			var img = document.getElementById(id);
			if (img != undefined)
			{
				//console.log("_UpdateThumbnail", JSON.stringify(pngData).slice(0, 100));
				// 2022.04.13: Add an error handler to detect when we assign bad image data.
				let ref = designRef;
				let ele = img;
				let pfx = idPrefix
				img.addEventListener("error", (err) => ScreenDesigner_Project_UI_UpdateThumbnailError(ele, ref, pfx));
				img.src = pngData;
			}
			else
			{
				console.log("ScreenDesigner_Project_UI_UpdateThumbnail: design '" + id + "' not found in UI");
			}
		}
		catch (err) {
			console.log("ScreenDesigner_Project_UI_UpdateThumbnail error: " + err + ", stack: " + err.stack);
		}
	}

	//---------------------------------------------------------------------------
	//	 Project UI: Update Thumbnail Error
	//		2022.04.12: Created. Clears the bad thumbnail image and replaces it
	//		with the logo.
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_UI_UpdateThumbnailError = function(img, designRef, idPrefix)
	{
		console.log("_UI_UpdateThumbnailError: " + designRef);
		img.src = "rsrcs/logo_only.svg";
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_LoadDesigns = function(projectRef, designRefToEdit = undefined)
	{
		var contents = document.getElementById("ID_ProjectContent");
		if (contents != undefined)
		{
			// Remove all items on the page in the workbook Content area
			while (contents.hasChildNodes())
    			contents.removeChild(contents.lastChild);
			
			// Add the designs
			var designList = StorageManager.Project_GetDesignList(projectRef);

			for (var i = 0; i < designList.length; i++)
			{
				var designRef = designList[i];
				// 2018.08.22: Add the design, even though we may not have the design data yet.
				// This keeps the items in order
				ScreenDesigner_Project_UI_AddOne(undefined, designRef);
				
				// 2019.10.13: Factored code to load one design into a new routine
				var editWhenLoaded = (designRef == designRefToEdit);
				ScreenDesigner_Project_LoadDesign(designRef, editWhenLoaded);
			}
		}
	}

	//---------------------------------------------------------------------------
	//	 ScreenDesigner Project: Load Design
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_LoadDesign = function(designRef, editWhenLoaded = false)
	{
		let designData = undefined;

		StorageManager.Design_Load(designRef)
		.then(designInfo => 
			{
				var designRef = designInfo.ref;
				if (designInfo.data == undefined || designInfo.data == null || Object.keys(designInfo.data).length === 0)
				{
					console.log("ScreenDesigner_Project_LoadDesign: " + designRef + ", warning: ", (Object.keys(designInfo.data).length === 0) ? "empty object" : "undefined object");
					// 2019.10.13: Don't put in empty data. Instead, set the state as 'failed to load' 
					//designData = ScreenDesigner.NewScreenDesignerData();
					//StorageManager.Design_SetData(designRef, designData);
					// 2019.10.12: This line will wipe out user data if the design fails to load for any reason
					//--------StorageManager.Design_Store(designRef, true /* force */);
					// 2019.10.13: Indicate load failure
					ScreenDesigner_Project_UI_SetLoadFailure(designRef);
				}
				else
				{
					// 2022.04.11: Is there a "load thumbnail" function?
					let hasLoadThumbnail = (typeof StorageManager.Design_Load_Thumbnail === 'function');

					designData = designInfo.data;
					Object.setPrototypeOf(designData, ScreenDesignerData.prototype);
					
					designData.AddMissingProperties(); // 2021.07.02

					// 2022.04.13: Remove old properties (especially those accidentally added during development); returns true is properties removed
					// Store the design if modified
					if (designData.RemoveDeprecatedProperties())
						StorageManager.Design_Store(designRef, true /* force */);
				
					// 2019.10.13: Now only call these if we have successfully loaded the design data
					ScreenDesigner_Project_UI_SetItemDesign(designData, designRef);
					// 2022.04.11: Render the thumbnail (via _UI_UpdateOne) if the load thumbnail function does not exist
					// 2022.04.12: Changed _UpdateOne so that it can optionally generate thumbnail. We neeed to call it to get the 
					// initial (quick) render of the thumbnail
					ScreenDesigner_Project_UI_UpdateOne(designData, designRef, {initialDelay:250, generateThumbnail:!hasLoadThumbnail});
				
					// Don't edit a design from the project if a stand-alone design if present
					if (editWhenLoaded)
						ScreenDesigner_StorageMgr_EditDesign(designRef)

					// 2022.04.11: Attempt to load the thumbnail if the load thumbnail function exists
					if (hasLoadThumbnail)
						return StorageManager.Design_Load_Thumbnail(designRef);
					else
						return {}; // empty thumbnail
				}
			})
		.then(thumbnailInfo =>
			{
				// If we received a thumbnail, then use it to update the UI, otherwise request to generate the thumbnail
				if (thumbnailInfo != undefined && thumbnailInfo.thumbnail != undefined)
					ScreenDesigner_Project_UI_UpdateThumbnail(designRef, thumbnailInfo.thumbnail);
				else
					ScreenDesigner_Project_UI_UpdateOne(designData, designRef, {initialDelay:250});
			})
		.catch(err => 
			{
				console.log("ScreenDesigner_Project_LoadDesigns err: " + err + ", Stack: " + err.stack);
				// 2019.10.13: Indicate load failure
				ScreenDesigner_Project_UI_SetLoadFailure(designRef);
			})
	}
	
	//---------------------------------------------------------------------------
	//	ScreenDesigner Project: Thumbnail Ready
	//		2022.04.11: Added so that we can store the thumbnail on the server
	//
	//	pngInfo: {data:pngData, width:canvas.width, height:canvas.height},
	//		where pngData = canvas.toDataURL("image/png")
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_ThumbnailReady = function(designRef, pngInfo)
	{
		// 2022.04.11: Is there a "store thumbnail" function?
		if (typeof StorageManager.Design_Store_Thumbnail === 'function')
		{
			let pngFileName = "TH" + designRef + ".png";
			let pngData = pngInfo.data;
			//let pngFile = new File([pngData], pngFileName, { type: 'image/png' });

			//if (pngFile != undefined)
			{
				StorageManager.Design_Store_Thumbnail(designRef, pngData);
			}
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_Project_UI_GetThumbnailInfo = function(design)
	{
		var info = "";
		var count = StorageManager.Project_GetDesignCount(appStorageInfo.currentProjectRef);
		var index = undefined;		
		var designRef = undefined;
		var debug = "";
		
		if (design != undefined)
		{
			// Validate
			var designList = StorageManager.Project_GetDesignList(appStorageInfo.currentProjectRef);
			designRef = StorageManager.Project_LookupDesignRef(appStorageInfo.currentProjectRef, design);
			//console.log("designRef: " + designRef + " in " + JSON.stringify(designList));
			for (var i = 0; i < designList.length && index == undefined; i++)
				if (designList[i] == designRef)
					index = i;
			//console.log("index: " + JSON.stringify(index));
			//console.log("indexOf: " + designList.indexOf(designRef));
			//console.log("");
		}
		
		if (gDebugLogging)
		{
			debug = " {";
			debug += JSON.stringify(designRef) + ", "  + (designRef % 65536) + ", indexOf: " + designList.indexOf(designRef);
			debug += "}";
		}
		
		if (designRef != undefined)
		{
			if (design.general.note != undefined)
				info = design.general.note + " (" + (index + 1) + " of " + count + ")" + debug;
			else
				info = "Design " + (index + 1) + " of " + count + debug;
		}
		else
			console.log("ScreenDesigner_Project_UI_GetThumbnailInfo: design not found in project.");
		
		return info;
	}
	

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_GetProjectCount = function()
	{
		return StorageManager.ProjectList_GetCount();
	}


	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_GetProjectDesignCount = function()
	{
		return StorageManager.Project_GetDesignCount(appStorageInfo.currentProjectRef);
	}


	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_GetProjectDesignIDList = function()
	{
		// This returns a copy of the list
		return StorageManager.Project_GetDesignList(appStorageInfo.currentProjectRef);
	}


	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_IsDesignInProject = function(designData)
	{
		var containsDesign = false;
		
		if (appStorageInfo.currentProjectRef != undefined)
			containsDesign = StorageManager.Project_IsDesignDataInProject(appStorageInfo.currentProjectRef, designData);
			
		return containsDesign;
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_PositionProjectListBackground = function()
	{
		/*
			No longer necessary. The ID_ProjectListBackground is now
			positioned to cover the entire window (via CSS) 
			
		var e = document.getElementById("ID_ProjectListBackground");
		
		// Position the project list over the center of the UI.
		var eRef = document.getElementById("ID_CenterAuthorized");
		if (eRef != undefined && e.style.display != "none")
		{
			var ex = 0;
			var r = eRef.getBoundingClientRect();
			var st = e.style;
		
			st.top    = r.top    - ex + "px";
			st.left   = r.left   - ex + "px";
			st.bottom = r.bottom + ex + "px";
			st.right  = r.right  + ex + "px";
			st.width  = r.width  + 2 * ex + "px";
			st.height = r.height + 2 * ex  + "px";
		}
		*/
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_ShowProjectList = function()
	{
		// Add the projects to the 
		ScreenDesigner_StorageMgr_UI_BuildProjectList();

		// When hidden, the display style is "none". Change it to "flex" to show it.
		var e = document.getElementById("ID_ProjectListBackground");
		e.style.display = "flex";

		// It needs to be shown (by the above line) before being positioned
		ScreenDesigner_StorageMgr_PositionProjectListBackground();
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_HideProjectList = function()
	{
		var e = document.getElementById("ID_ProjectListBackground");
		e.style.display = "none";
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UI_UpdateProjectItem = function(projectRef)
	{
		var id = ("Pr_" + projectRef);
		var projectInfo = StorageManager.Project_GetInfo(projectRef);
		
		var projectItem = document.getElementById(id);
		var spanList = projectItem.getElementsByClassName("CL_ProjectItemInfo");
		var e = spanList[0];
		
		var info = "";
		
		if (projectInfo == undefined)
		{
			info = "Loading...";
		}
		else
		{
			// Project info
			var projectName = (projectInfo.name != undefined) ? projectInfo.name : "untitled";
			var count = StorageManager.Project_GetDesignCount(projectRef);
			info = "<span class='underline' style='font-size:14px'>" + projectName + "</span><br>";
			info += count + ((count == 1) ? " design" : " designs");
				
			e.innerHTML = info;
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UI_AddProjectItem = function(projectRef)
	{
		// Add the DOM elements
		
		var id = ("Pr_" + projectRef);

		var contents = document.getElementById("ID_ProjectList");
		
		var itemContainer = document.createElement('div'); 
		itemContainer.className = "CL_ProjectItemContainer";
		itemContainer.onclick = ScreenDesigner_StorageMgr_UI_ProjectSelected;
		itemContainer.id = id;
		
		var projectInfoSpan = document.createElement('span'); 
		projectInfoSpan.className = "CL_ProjectItemInfo";
		projectInfoSpan.innerHTML = "Loading...";
		
		var projectImage = document.createElement('img'); 
		projectImage.className = "CL_ProjectItemImage";
		//designImage.style.width  = (workbookItemDim.size.x) + "px";
		//designImage.style.height = (workbookItemDim.size.y) + "px";
		//designImage.id = ("E" + ref);
		//designImage.collectionObject = collectionObject;
		//designImage.onclick = ScreenDesigner_Collection_HandleItemSelected;
		
		itemContainer.appendChild(projectInfoSpan);	
		itemContainer.appendChild(projectImage);	

		contents.appendChild(itemContainer);	
		
		// Fill in the info
		ScreenDesigner_StorageMgr_UI_UpdateProjectItem(projectRef);
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UI_LoadProjectThumbnail = function(projectRef)
	{
		var projectInfo = StorageManager.Project_GetInfo(projectRef);
		var designList = StorageManager.Project_GetDesignList(projectRef);

		// Get the thumbnail design
		var designRef = projectInfo.thumbnailDesignRef;
		if (designRef == undefined || !designList.includes(designRef))
			designRef = designList[0];

		StorageManager.Design_Load(designRef)
		.then(designInfo => 
			{
				var designRef = designInfo.ref;
				var designData = undefined;
				// 2019.10.14: Test for empty objecdt
				if (designInfo.data == undefined || designInfo.data == null || Object.keys(designInfo.data).length == 0)
				{
					console.log("ScreenDesigner_StorageMgr_UI_LoadProjectThumbnail: No design data");
				}
				else
				{
					designData = designInfo.data;
					Object.setPrototypeOf(designData, ScreenDesignerData.prototype);
					designData.AddMissingProperties(); // 2021.07.02
				}
				
				
				if (designData != undefined)
				{
					// 2022.04.14: Is there a "load thumbnail" function?
					let hasLoadThumbnail = (typeof StorageManager.Design_Load_Thumbnail === 'function');
					// Get the project item from the UI
					var id = ("Pr_" + projectRef);
					var projectItem = document.getElementById(id);
					// Find the 'img' element
					var imgList = projectItem.getElementsByClassName("CL_ProjectItemImage");
					var e = imgList[0];
					// Set the id so that the _UpdateOne function can find it
					e.id = "PrThumb_" + projectCollectionIDchar + designRef;
					// Start the render
					// 2022.04.14: Don't generate thumbnail if we can try to load one
					ScreenDesigner_Project_UI_UpdateOne(designData, designRef, {initialDelay:0, idPrefix:"PrThumb_", generateThumbnail:!hasLoadThumbnail});
					// 2022.04.14: Attempt to load the thumbnail if the load thumbnail function exists
					if (hasLoadThumbnail)
						return StorageManager.Design_Load_Thumbnail(designRef);
					else
						return {}; // empty thumbnail
				}
			})
		.then(thumbnailInfo => // 2022.04.14: Handle thumbnail
			{
				// If we received a thumbnail, then use it to update the UI, otherwise request to generate the thumbnail
				if (thumbnailInfo != undefined && thumbnailInfo.thumbnail != undefined)
					ScreenDesigner_Project_UI_UpdateThumbnail(designRef, thumbnailInfo.thumbnail, "PrThumb_");
				else
					ScreenDesigner_Project_UI_UpdateOne(designData, designRef, {initialDelay:0, idPrefix:"PrThumb_"});
			})
		.catch(err => 
			{
				console.log("ScreenDesigner_Project_LoadDesigns err: " + err);
			})
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UI_BuildProjectList = function()
	{
		var contents = document.getElementById("ID_ProjectList");

		// Remove all items in the contents area
		while (contents.hasChildNodes())
			contents.removeChild(contents.lastChild);
		
		var projectList = StorageManager.GetProjectList();
		
		for (var i = 0; i < projectList.length; i++)
		{
			var projectRef = projectList[i];
			ScreenDesigner_StorageMgr_UI_AddProjectItem(projectRef);
			
			StorageManager.Project_Load(projectRef)
			.then ( (projectRef) => 
				{
					ScreenDesigner_StorageMgr_ValidateProjectInfo(projectRef);
					ScreenDesigner_StorageMgr_UI_UpdateProjectItem(projectRef);
					ScreenDesigner_StorageMgr_UI_LoadProjectThumbnail(projectRef);
				} )
			.catch (err =>	
				{
					console.log("ScreenDesigner_StorageMgr_UI_BuildProjectList error: " + err + ", stack: " + err.stack);
				});
		}
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UI_ProjectSelected = function(evt)
	{
		//console.log("ScreenDesigner_StorageMgr_UI_ProjectSelected: " + evt.target.id);

		ScreenDesigner_StorageMgr_HideProjectList();

		// Called when any of the workbook elements are clicked on
		//
		var id = this.id;
		var projRef = id.substring(3);
		
		ScreenDesigner_StorageMgr_LoadProject(projRef);
	}
		
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UI_UpdateOne = function(design, initialDelay)
	{
		// Updates the design in the workbook UI.
		// The design must already exist in the UI and in the app's workbook
		
		if (ScreenDesigner_StorageMgr_IsDesignInProject(design))
		{
			var designRef = StorageManager.Project_LookupDesignRef(appStorageInfo.currentProjectRef, design);
			var id = ("E" + projectCollectionIDchar + designRef);
			var img = document.getElementById(id);
			if (img != undefined)
			{
				var pngData = ScreenDesignerItemRenderer.RenderPNG(design, {x:60, y:60});
				img.src = pngData;
				
				// 2018.01.09: Use a thread to render a thumbnail
				var workbookItemDim = {size:{x:58, y:58}, margin: 2}; // ANOTHER definition of this!!!
				// 2022.04.14: Using new config param to pass callback so we can store the thumbnail
				let config = {userCallback: ScreenDesigner_Project_ThumbnailReady, userRef:designRef, priority:TaskPriority.WORKBOOK_THUMBNAIL};
				ScreenDesigner_RenderRequest.RenderThumbnailDesign(design, img, initialDelay, workbookItemDim, config);
			}
			else
			{
				console.log("ScreenDesigner_StorageMgr_UI_UpdateOne: design '" + id + "' not found in UI");
			}
		}
		else
		{
			console.log("ScreenDesigner_StorageMgr_UI_UpdateOne: design does not exist in app's workbook");
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UpdateDesignIfInProject = function(designData)
	{
		// If the current design is in the workbook, then update its image
		if (ScreenDesigner_StorageMgr_IsDesignInProject(designData))
		{
			ScreenDesigner_StorageMgr_UI_UpdateOne(designData, 5000 /* initial delay */);
		}
		
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UI_ShowCurrentActive = function(designData)
	{
		// Remove the indicator showing the current design
		var imgs = document.getElementsByClassName("ProjectItemCurrent");
		while (imgs.length > 0)
			imgs[0].classList.remove("ProjectItemCurrent");
				
		var eTab = document.getElementById("ID_ProjectTabAreaL");
		eTab.classList.remove("CL_TabLabelHighlight");
		
		// Show an indicator of which design is the current design
		if (ScreenDesigner_StorageMgr_IsDesignInProject(designData))
		{
			var designRef = StorageManager.Project_LookupDesignRef(appStorageInfo.currentProjectRef, designData);
			var id = ("E" + projectCollectionIDchar + designRef);
			var img = document.getElementById(id);
			if (img != undefined)
				img.classList.add("ProjectItemCurrent");
			else
				console.log("ScreenDesigner_StorageMgr_UI_ShowCurrentActive: design '" + id + "' not found in UI");
				
			// Show that the active design is in a project
			eTab.classList.add("CL_TabLabelHighlight");
		}

	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_PopulateProjectUI = function()
	{
		try {
			var projectInfo = StorageManager.Project_GetInfo(appStorageInfo.currentProjectRef);
			var e = document.getElementById("ID_ProjectName");
			var name = (projectInfo != undefined && projectInfo.name != undefined) ? projectInfo.name : "untitled";
			e.value = name;
		
			var e = document.getElementById("ID_ProjectInfo");
			var count = StorageManager.Project_GetDesignCount(appStorageInfo.currentProjectRef);
		
			var info = ""
			info += "'" + name + "'<br>";
			info += count + ((count == 1) ?  " design" : " designs");
			e.innerHTML = info;
		}
		catch (err) {
			console.log("ScreenDesigner_StorageMgr_PopulateProjectUI error: " + err);
		}
	}

	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_ClearProjectUI = function()
	{
		try {
			var e = document.getElementById("ID_ProjectName");
			e.value = "untitled";
	
			var e = document.getElementById("ID_ProjectInfo");
			e.innerHTML = "";
		}
		catch (err) {
			console.log("ScreenDesigner_StorageMgr_ClearProjectUI error: " + err);
		}
	}
	
	//---------------------------------------------------------------------------
	//	 
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_UpdateProjectName = function(projectName)
	{
		// If the name is empty, the use undefined
		if (projectName.length == 0 || projectName == "")
			projectName = undefined;

		var projectInfo = StorageManager.Project_GetInfo(appStorageInfo.currentProjectRef);

		// This should work if either one is undefined
		if (projectInfo.name != projectName)
		{
			projectInfo.name = projectName;

			StorageManager.Project_Store(appStorageInfo.currentProjectRef);
			ScreenDesigner_StorageMgr_PopulateProjectUI();
		}
	}
	

	//---------------------------------------------------------------------------
	//	 Increment Export Count
	//		Increments and returns export count. 
	//		2021.08.05: Create to used with exporting images for external sites
	//		(e.g., Zazzle)
	//		2021.08.12: Use GetUserSettings/StoreUserSettings instead of 
	//		per-project info
	//---------------------------------------------------------------------------
	var ScreenDesigner_StorageMgr_IncrementExportCount = function()
	{
		var userSettings = StorageManager.GetUserSettings();

		// Assign the initial export count if it is not defined (or if it is not a number)
		if (userSettings.exportCount == undefined || !isFinite(userSettings.exportCount))
			userSettings.exportCount = 0;

		userSettings.exportCount += 1;

		StorageManager.StoreUserSettings();

		return userSettings.exportCount;
	}
	

	//---------------------------------------------------------------------------
	//	 API
	//---------------------------------------------------------------------------
	return {
		Open:					ScreenDesigner_StorageMgr_Open,
		Close:					ScreenDesigner_StorageMgr_Close,
		UploadChanges:			ScreenDesigner_StorageMgr_UploadChanges,
		GetDataForDesignID:		ScreenDesigner_StorageMgr_GetDataForDesignID,
		RemoveDesignAndUpdate:	ScreenDesigner_StorageMgr_RemoveDesignAndUpdate,
		SetCurrentDesignRef:	ScreenDesigner_StorageMgr_SetCurrentDesignRef,
		MissingDesignData:		ScreenDesigner_StorageMgr_MissingDesignData,
		NewDesign:				ScreenDesigner_StorageMgr_NewDesign,
		AddDesign:				ScreenDesigner_StorageMgr_AddDesign,
		NewProject:				ScreenDesigner_StorageMgr_NewProject,
		DeleteCurrentProject:	ScreenDesigner_StorageMgr_DeleteCurrentProject,
		UpdateProjectName: 		ScreenDesigner_StorageMgr_UpdateProjectName,
		IncrementExportCount:	ScreenDesigner_StorageMgr_IncrementExportCount,	// 2021.08.05
		IsDesignInProject:		ScreenDesigner_StorageMgr_IsDesignInProject,
		ShowProjectList:		ScreenDesigner_StorageMgr_ShowProjectList,
		HideProjectList:		ScreenDesigner_StorageMgr_HideProjectList,
		UpdateDesignIfInProject:	ScreenDesigner_StorageMgr_UpdateDesignIfInProject,
		UI_ShowCurrentActive:	ScreenDesigner_StorageMgr_UI_ShowCurrentActive, 
		GetProjectCount:		ScreenDesigner_StorageMgr_GetProjectCount,
		GetProjectDesignCount:	ScreenDesigner_StorageMgr_GetProjectDesignCount,
		GetProjectDesignIDList:	ScreenDesigner_StorageMgr_GetProjectDesignIDList,
		PositionProjectListBackground:	ScreenDesigner_StorageMgr_PositionProjectListBackground,
		Rebuild:				ScreenDesigner_StorageMgr_Rebuild
	}
	
}());


//---------------------------------------------------------------------------
//	Design Export
//		2021.08.10: Created
//---------------------------------------------------------------------------
var ScreenDesigner_DesignExport = (function() {

	var de_uiConnected = false;
	var de_DesignExportImageURL = undefined;
	var de_PngInfo = undefined;
	var de_TemplateIdx = undefined;
	

	let zazzleSite = "https://www.zazzle.com/api/create/"
	let zazzleRender = "https://rlv.zazzle.com/svc/view"
	let zazzleAccountId = "238002690715355986";
	
	let templateId_MugWrapAround = "168918202407151023";	// 3400x1400 (7:17)
	let templateId_MugTwoSides   = "168619656538873524";	// square
	let templateId_Mousepad      = "144071202626078770";	// 1388x1163


	/*	Zazzle:
		The general resolution requirements (in pixels per inch) are:
		150ppi for apparel, aprons, bags, hats, mousepads, and ties.
		200ppi for mugs, drinkware, calendars, cards, keychains, magnets, postcards, and all stickers.
		300ppi for custom postage
		300ppi for photo enlargements/prints, and posters

		Mouse pad: 9.25"l x 7.75"w, 1388x1163 (31:37)
		Beach towel: 70" x 35", 10500x5250 (1:2)
		Kitchen towel: 16" x 24", 2400x3600 (2:3)
		Tie: 4" x 28" (1:7)
		Basic T-shirt (12:10)

	*/	
	let zazzleTemplateList = [
		{ tid:"179314803056615898",	name:"Phone case",					ar:(8/14),		height:1400,	dpi:200	},
		{ tid:"235669228487093028",	name:"T-shirt, design on front",	ar:(10/12),		height:1800,	dpi:150	},
		{ tid:"168918202407151023",	name:"Mug, design wraps around",	ar:(17/7),		height:1400,	dpi:200	},
		{ tid:"168619656538873524",	name:"Mug, design on two sides",	ar:(1.0),		height:1400,	dpi:200	},
		{ tid:"144071202626078770",	name:"Mousepad",					ar:(1388/1163),	height:1163,	dpi:150	},
		{ tid:"147083959195271359",	name:"2\" Square Magnet",			ar:(1.0),		height:400,		dpi:200	},
		{ tid:"256519678744688717",	name:"Custom sticker",				ar:(1.0),		height:1200,	dpi:200	},
		{ tid:"149570726995475044",	name:"Basic tote bag",				ar:(1.0),		height:1000,	dpi:150	},
		{ tid:"256205328323787201",	name:"Edge-to-edge tote bag",		ar:(1.0),		height:2400,	dpi:150	},
		{ tid:"189497072199708004",	name:"Throw Pillow",				ar:(1.0),		height:3000,	dpi:150	},
		{ tid:"197094999910100765",	name:"Kitchen Towel (v1)",			ar:(16/24),		height:2400,	dpi:150	},
		{ tid:"217086415342189488",	name:"Square sticker",				ar:(1.0),		height:900,		dpi:200	},
		{ tid:"256602423072917369",	name:"Long scarf",					ar:(16/72),		height:10800,	dpi:150	},
		{ tid:"256875517944843540",	name:"Square scarf",				ar:(1.0),		height:7500,	dpi:150	},
		{ tid:"151842438452538032",	name:"Men's tie",					ar:(4/28),		height:4200,	dpi:150	}
	];

	//---------------------------------------------------------------------------
	//	Prepare Export
	//		Shows the dialog, and renders and uploads the image.
	//		Note: The function can be called by the dialog when the dialog is 
	//		already displayed in order to perform a "resize" on the image
	//
	//		options: minimum width, minimum height, template idx
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_PrepareExport = function(options = undefined)
	{
		// Clear URL to uploaded image
		de_DesignExportImageURL = undefined;
		
		// Clear previous cached values
		de_TemplateIdx = undefined;
		de_PngInfo = undefined;
		
		// Clear any previous renders from the dialog
		ScreenDesigner_DesignExport_ResetDialog();
		
		// Show the dialog
		ScreenDesigner_DesignExport_ShowDialog(true);
		
		// 
		ScreenDesigner_DesignExport_UpdateDialog();
		
		// Render and upload the design image.
		// The callback from the upload will populate the dialog
		let renderConfig = undefined;
		if (options != undefined && options.templateIdx != undefined && isFinite(options.templateIdx))
		{
			let zt = zazzleTemplateList[options.templateIdx];
			renderConfig = {};
			renderConfig.fitToBounds = {width:Math.ceil(zt.height * zt.ar), height:zt.height};
		}
		ScreenDesigner_DesignExport_RenderPNG(renderConfig);

		// 2021.09.24: Analytics
		SystemAnalytics.Record("PRODUCT" /*, { format:"TXT", destination:"file" } */);
	}

	//---------------------------------------------------------------------------
	//	Show Dialog
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_ShowDialog = function(showIt = true)
	{
		let e = document.getElementById("ID_DesignExportBackground");
		if (e != undefined)
		{
			if (!de_uiConnected)
				ScreenDesigner_DesignExport_ConnectUI();
				
			if (showIt)
				e.style.display = "flex";
			else
				e.style.display = "none";
		}
		else
			console.log("ScreenDesigner_DesignExport_ShowDialog: missing element");
	}

	//---------------------------------------------------------------------------
	//	Hide Dialog
	//		Called from outside _DesignExport object
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_HideDialog = function()
	{
		ScreenDesigner_DesignExport_ShowDialog(false);
	}
	
	//---------------------------------------------------------------------------
	//	Reset Dialog
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_ResetDialog = function()
	{
		// Remove previous image renders
		let imagesDiv = document.getElementById("ID_ZazzleImageRenders");
		imagesDiv.innerHTML = "";
		
		let img = document.createElement("img");
		img.classList.add("CL_DesignExport_Animation");
		img.src = "./gifsimgs/animation_320.gif";
		imagesDiv.appendChild(img);
		
		//let sizeE = document.getElementById("ID_DesignExport_ImageSize");
		//sizeE.innerHTML = "";

		let e = document.getElementById("ID_ResizeImageOptions");
		if (e != undefined)
			e.style.display = "";
			
		ScreenDesigner_DesignExport_ShowHelpTipsTab(/*none*/);
	}

	//---------------------------------------------------------------------------
	//	Update Dialog
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_UpdateDialog = function()
	{
		let hasAR = ScreenDesigner.IsRectangularFrame();
		
		// ASPECT RATIO
		//	
		// Show the paragraphs and button in the "aspect ratio" tab according to whether or
		// not the design frame has an aspect ratio
		//
		var e = document.getElementById("ID_DesignExport_NoAspectRatio"); 
		if (e != undefined)
			e.style.display = hasAR ? "none" : "";

		var e = document.getElementById("ID_DesignExport_HasAspectRatio"); 
		if (e != undefined)
			e.style.display = hasAR ? "" : "none";

		var e = document.getElementById("ID_DesignExport_ShowAspectRatio"); 
		if (e != undefined)
			e.style.display = hasAR ? "" : "none";

		var e = document.getElementById("ID_DesignExport_SetAspectRatio"); 
		if (e != undefined)
		{
			e.style.display = (hasAR && de_TemplateIdx != undefined) ? "" : "none";
			
			// If a template is selected, then set the label of the "Set Aspect Ratio" button
			// to show what aspect ratio will be used
			if (de_TemplateIdx != undefined)
			{
				let ar = zazzleTemplateList[de_TemplateIdx].ar;
				ar = Math.round(ar * 100) / 100;
				var msg = "Set Aspect Ratio to " + ar;
				e.innerHTML = msg;
			}
		}
		
		var e = document.getElementById("ID_DesignExport_ProductAspectRatio"); 
		if (e != undefined)
		{
			e.style.display = (de_TemplateIdx != undefined) ? "" : "none";
			
			// If a template is selected, then show its aspect ratio
			if (de_TemplateIdx != undefined)
			{
				let zt = zazzleTemplateList[de_TemplateIdx]
				var ar = Math.floor(zt.ar * 1000)/1000;
				var msg = "The aspect ratio of the '" + zt.name + "' is  " + zt.ar;
				e.innerHTML = msg;
			}
		}

		var e = document.getElementById("ID_DesignExport_DesignAspectRatio"); 
		if (e != undefined)
		{
			e.style.display = (de_PngInfo != undefined) ? "" : "none";
			
			// If a design image was rendered, then show the design size and aspect ratio
			if (de_PngInfo != undefined)
			{
				var ar = de_PngInfo.width / de_PngInfo.height;
				ar = Math.floor(ar * 1000)/1000;
				var msg = "Design image size: " + de_PngInfo.width + " x " + de_PngInfo.height + ", Aspect ratio: " + ar;
				e.innerHTML = msg;
			}
		}


		// IMAGE SIZE WARNING
		//	
		var e = document.getElementById("ID_DesignExport_ImageSizeWarning_ResizeAction"); 
		if (e != undefined)
		{
			e.style.display = (de_PngInfo != undefined && de_TemplateIdx != undefined) ? "" : "none";
		}

		var e = document.getElementById("ID_DesignExport_ImageSizeWarning_Current"); 
		if (e != undefined)
		{
			e.style.display = (de_PngInfo != undefined) ? "" : "none";
			
			// If a design image was rendered, then show the design size
			if (de_PngInfo != undefined)
				e.innerHTML = "Design image size: " + de_PngInfo.width + " x " + de_PngInfo.height;
		}

		var e = document.getElementById("ID_DesignExport_ImageSizeWarning_Minimum"); 
		if (e != undefined)
		{
			//e.style.display = (de_TemplateIdx != undefined) ? "" : "none";
			
			// If a template is selected, then show its aspect ratio
			if (de_TemplateIdx != undefined)
			{
				let zt = zazzleTemplateList[de_TemplateIdx]
				let h = zt.height;
				let w = Math.floor(zt.height * zt.ar);
				var msg = "For the best design reproduction on  of the '<strong>" + zt.name + 
							"</strong>', the width should be at least <strong>" + w + 
							"</strong> pixels or the height should be at least <strong>" + h + "</strong> pixels";
				e.innerHTML = msg;
			}
			else
			{
				e.innerHTML = "<i>(Information about the minimum size for a product will be shown here when you click on an orange warning icon if it is present on a product render.)</i>";
			}
		}
	}
	
	//---------------------------------------------------------------------------
	//	Connect UI
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_ConnectUI = function()
	{
		var e;
		
		var controls = 
			[	"ID_DesignExportBackground", "ID_HideDesignExport" ];

		controls.forEach(id => {
			e = document.getElementById(id);
			if (e != undefined)
				e.addEventListener("click", ScreenDesigner_DesignExport_HandleControls);
			else
				console.log("ScreenDesigner_DesignExport_ConnectUI: missing: " + id);
		});
		
		// Checkboxes are used to support the tabs
		let checkboxes = document.querySelectorAll(".CL_Export_TipsCheckbox");
		for (var i = 0; i < checkboxes.length; i++)
			checkboxes[i].addEventListener("click", ScreenDesigner_DesignExport_HandleControls);

		// Connect the buttons. Note that most of the buttons are "show me ..."
		let infoDiv = document.getElementById("ID_Export_HelpInfoPanels");
		let buttons = infoDiv.querySelectorAll(".CL_Button");
		for (var i = 0; i < buttons.length; i++)
			buttons[i].addEventListener("click", ScreenDesigner_DesignExport_HandleControls);
		
		de_uiConnected = true;
	}

	//---------------------------------------------------------------------------
	//	Show Help Tips Tab
	//		Show the specified tab. If specified tab is not found, then no tabs
	//		are shown.
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_ShowHelpTipsTab = function(checkboxId = undefined, checked = true)
	{
		let checkboxes = document.querySelectorAll(".CL_Export_InfoCheckbox");
		for (var i = 0; i < checkboxes.length; i++)
			checkboxes[i].checked = false;
		
		// If we were given a tab ID, and we have to "check" it, and it is found, then "check" it
		let e = (checked && checkboxId != undefined) ? document.getElementById(checkboxId) : undefined;
		if (e != undefined)
			e.checked = true;
	}
	
	//---------------------------------------------------------------------------
	//	Handle Controls
	//		Handle all of the controls for the Design Export dialog
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_HandleControls = function(evt)
	{
		let id = this.id;
		
		// Close the dialog if the background was clicked on
		if (id == "ID_DesignExportBackground")
		{
			if (evt.target.id == "ID_DesignExportBackground")
				ScreenDesigner_DesignExport_ShowDialog(false);
		}	
		// Close the dialog if the "x" was clicked
		else if (id == "ID_HideDesignExport")
		{
			ScreenDesigner_DesignExport_ShowDialog(false);
		}
		// Manage the tabs for showing the tips
		else if (id == "ID_Export_01_c" || id == "ID_Export_02_c" || id == "ID_Export_03_c" || id == "ID_Export_04_c" || id == "ID_Export_05_c")
		{
			// Note we are passing in the "checked" status of the checkbox. The _ShowHelpTopsTab function will
			// use that to check the box if necessary
			ScreenDesigner_DesignExport_ShowHelpTipsTab(id, this.checked);
		}
		// Respond to the buttons. Note that the buttons do not have IDs. Instead the data attribute is used to indicate 
		// what the different buttons do
		else if (this.nodeName == "BUTTON")
		{
			// Highlight one of the controls elsewhere in the UI
			if (this.dataset.showId != undefined)
			{
				ScreenDesigner_DesignExport_HideDialog();
				let showMeId = this.dataset.showId;
				ScreenDesigner.UI_ShowAndHighlightElement(showMeId);
			}
			// Show one of the other design export tip tabs
			else if (this.dataset.showTab != undefined)
			{
				ScreenDesigner_DesignExport_ShowHelpTipsTab(this.dataset.showTab);
			}
			else if (this.dataset.action != undefined)
			{
				let action = this.dataset.action;
				if (action == "RESIZE")
				{
					if (de_TemplateIdx != undefined)
					{
						// Restart the dialog passing a template index. The _PrepareExport function
						// will use this to insure that the design image to determine how much to
						// upscale the image during rendering.
						ScreenDesigner_DesignExport_PrepareExport({templateIdx: de_TemplateIdx});
					}
					else
						console.log("Action 'RESIZE', but no template idx");
				}
				else if (action == "ASPECT_RATIO")
				{
					if (de_TemplateIdx != undefined)
					{
						// Get the aspect ratio from the template list
						let ar = zazzleTemplateList[de_TemplateIdx].ar;
						// Hide the dialog, set the aspect ratio, and highlight the aspect ratio
						ScreenDesigner_DesignExport_HideDialog();
						ScreenDesigner.SetAndLockAspectRatio(ar, true);
						ScreenDesigner.UI_ShowAndHighlightElement("ID_ShowAspectRatio");
					}
					else
						console.log("Action 'ASPECT_RATIO', but no template idx");
				}
				else
				{
					console.log("Not handled: action: " + action);
				}
			}
			else
			{
				console.log("ScreenDesigner_DesignExport_HandleControls: button not handled");
			}
		}
		else
		{
			console.log("ScreenDesigner_DesignExport_HandleControls: not handled: id:'" + this.id + "', label: '" + this.innerHTML + "',  " + this.nodeName);
		}
	}

	//---------------------------------------------------------------------------
	//	
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_RenderPNG = function(renderConfig = undefined)
	{
		let renderOptions = {
			backgroundColor:"#ffffff" // White background
		};
		
		// Copy any options passed in, particular the "fitToBounds" that might have been specified
		if (renderConfig != undefined)
			Object.assign(renderOptions, renderConfig);
			
		let designRender = ScreenDesigner.GetDesignRender();
		
		de_PngInfo = ScreenRenderer.RenderPNGBlob(designRender, ScreenDesigner_DesignExport_RenderPNG_Callback, renderOptions);
		
	}
	
	//---------------------------------------------------------------------------
	//	
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_UploadPNG = async function(pngBlob)
	{
		
		let exportId = ScreenDesigner_StorageMgr.IncrementExportCount();	

		var exportIdStr = exportId.toString();
		if (exportIdStr.length < 5)
			exportIdStr = ("00000" + exportIdStr).slice(-5);
			
		let pngFileName = "IMAGE_export_" + exportIdStr + ".png";
		let pngFile = new File([pngBlob], pngFileName, { type: 'image/png' });

		let userInfo = await ScreenDesignerAccount.GetCurrentUser(); 

		if (pngFile != undefined)
		{
			let userIdFileName = userInfo.userId + "/" + pngFileName;
			let result = await StorageManager.SaveImage(pngFile, userIdFileName);
		
			if (result != undefined)
			{
				let appConfig = ScreenDesigner.GetAppConfig();
				let userInfo = await ScreenDesignerAccount.GetCurrentUser();
		
				let url = "https://" + appConfig.s3_bucket + ".s3.amazonaws.com/public/" + userIdFileName; 
				//console.log(url);
				
				de_DesignExportImageURL = url;

			}
		}
	}

	//---------------------------------------------------------------------------
	//	Image Clean-Up
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_ImageCleanUp = function()
	{
		// Wait five seconds after logging in to clean up old images from the server
		setTimeout(ScreenDesigner_DesignExport_PurgeImages, 5000 /* ms */);
	}
	
	//---------------------------------------------------------------------------
	//	Purge Images
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_PurgeImages = async function()
	{
		let userInfo = await ScreenDesignerAccount.GetCurrentUser();
		let pngFilePrefix = "IMAGE_export_";
		let pngMatchKey = userInfo.userId + "/" + pngFilePrefix;
		
		StorageManager.PurgeImages({fileMatchKey:pngMatchKey});
	}
	

	//---------------------------------------------------------------------------
	//	RenderPNG Callback
	//		Uploads rendered image
	//		Populates dialog with renders from Zazzle
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_RenderPNG_Callback = async function(pngBlob)
	{
		await ScreenDesigner_DesignExport_UploadPNG(pngBlob);

		let encoded_png_url = encodeURIComponent(de_DesignExportImageURL);

		let imagesDiv = document.getElementById("ID_ZazzleImageRenders");
		imagesDiv.innerHTML = "";
		
		let imageSize = 160; // pixels
		
		let hasAspectRatio = ScreenDesigner.IsRectangularFrame();

		//var e = document.getElementById("ID_DesignExport_ImageSize");
		//if (e != undefined && de_PngInfo != undefined)
		//	e.innerHTML = "Size: " + de_PngInfo.width + " x " + de_PngInfo.height;

		for (var i = 0; i < zazzleTemplateList.length; i++)
		{
			let zt = zazzleTemplateList[i];
			let templateId = zt.tid;
			let z_render_url = zazzleRender + "?pid=" + templateId + "&max_dim=" + imageSize + "&at=" + zazzleAccountId + "&t_image1_url=" + encoded_png_url;
			//console.log(z_render_url);
			
			let div = document.createElement("div");
			div.classList.add("CL_DesignExport_ImageHolder");
			
			let img = document.createElement("img");
			// Set the first image render immediately, but add a delay for all subsequent image requests to give
			// Zazzle a chance to cache the image from Polygonia. This helps resolve the issue where many of the
			// render requests came back with the template image instead of the current design image.
			if (i == 0)
				img.src = z_render_url;
			else
			{
				let theImg = img;
				let theUrl = z_render_url;
				setTimeout(u => theImg.src = u, (500 + i * 200), theUrl);
			}
			img.classList.add("CL_DesignExport_Image");
			div.appendChild(img);
			
			let span = document.createElement("span");
			span.innerHTML = zazzleTemplateList[i].name;
			div.appendChild(span);

			if (de_PngInfo.height < zt.height && de_PngInfo.width < zt.height * zt.ar)
			{
				let h = zt.height;
				let infoStr = "This product requires a larger image (height at least " + h + "px) for the best reproduction. Click for options.";

				let warning = document.createElement("img");
				warning.src = "./rsrcs/orange-warning.png";
				warning.classList.add("CL_DesignExport_Warning");
				warning.setAttribute("title", infoStr);
				warning.addEventListener("click", evt => ScreenDesigner_DesignExport_ShowImageOptionsTab(evt, "ID_Export_05_c", templateIdx));
				div.appendChild(warning); 
			}

			//  Aspect ratio
			let designAR = de_PngInfo.width/de_PngInfo.height;

			if (hasAspectRatio && (Math.abs(designAR - zt.ar) > 0.01))
			{
				let ar = Math.round(zt.ar * 100) / 100;
				let infoStr = "This aspect ratio of this product is " + ar + " Click for options.";

				let arImg = document.createElement("img");
				arImg.src = "./rsrcs/aspect_ratio_icon.png";
				arImg.classList.add("CL_DesignExport_AR");
				arImg.setAttribute("title", infoStr);
				arImg.addEventListener("click", evt => ScreenDesigner_DesignExport_ShowImageOptionsTab(evt, "ID_Export_02_c", templateIdx));
				div.appendChild(arImg); 
			}

			let templateIdx = i;
			div.addEventListener("click", evt => ScreenDesigner_DesignExport_OpenZazzle(templateIdx));

			imagesDiv.appendChild(div);
		}

		// Update the dialog again now that we have the design image.
		ScreenDesigner_DesignExport_UpdateDialog();
	}

	//---------------------------------------------------------------------------
	//	Show Image Options Tab
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_ShowImageOptionsTab = function(evt, tabId, templateIdx)
	{
		de_TemplateIdx = templateIdx;
		
		// _UpdateDialog will adjust the text in all of the help tips tabs 
		// according to the selected template
		ScreenDesigner_DesignExport_UpdateDialog();
		
		// Show the requested tab, which is either "aspect ratio" or "image size warning"
		ScreenDesigner_DesignExport_ShowHelpTipsTab(tabId);
		
		evt.stopPropagation();
		evt.preventDefault();
	}

	//---------------------------------------------------------------------------
	//	Open Zazzle
	//---------------------------------------------------------------------------
	var ScreenDesigner_DesignExport_OpenZazzle = function(templateIdx)
	{
		let templateId = templateId_Mousepad;
		
		let encoded_png_url = encodeURIComponent(de_DesignExportImageURL);

		if (templateIdx >= 0 && templateIdx < zazzleTemplateList.length)
		{
			templateId = zazzleTemplateList[templateIdx].tid;
			let z_site_url = zazzleSite + "at-" + zazzleAccountId + "?ax=Linkover&pd=" + templateId + "&ed=true&t_image1_iid=" + encoded_png_url;
		
			var newWindow = window.open(z_site_url, '_blank');

			// 2021.09.24: Analytics
			SystemAnalytics.Record("ZAZZLE" , { product: zazzleTemplateList[templateIdx].name});
		}
		else
		{
			console.log("ScreenDesigner_DesignExport_OpenZazzle: templateIdx out of range: " + templateIdx);
		}
	}

	//---------------------------------------------------------------------------
	// API
	//---------------------------------------------------------------------------
	return {
		PrepareExport:			ScreenDesigner_DesignExport_PrepareExport,
		HideDialog:				ScreenDesigner_DesignExport_HideDialog,
		ImageCleanUp:			ScreenDesigner_DesignExport_ImageCleanUp
	}
	
}());


//---------------------------------------------------------------------------
//	ScreenDesigner_Gallery 
// 		2018.07.19: refactored to new class
//---------------------------------------------------------------------------
var ScreenDesigner_Gallery = (function() {

	// Gallery
	var appGalleryURL = "gallery.txt";
	var appGallery = undefined; // 2018.03.05: Added this to do slideshow
	var galleryItemDim = {size:{x:95, y:95}, margin: 0 };
	var appGalleryRevealed = false;
	
	var ScreenDesigner_Gallery_UI_HandleItemSelected = function(e)
	{
		// Called when any of the workbook elements are clicked on
		//
		var designData = e.target.designData;
		
		// ---Should validate designData---
		if (designData != undefined && designData["reserved"] == ScreenDesignerDataType.DESIGN_DATA)
		{
			var continueWithEdit = ScreenDesigner.PromptForPossibleUnsavedDesignChanges();

			if (continueWithEdit)
			{
				var dataToUse = designData.Clone();

				ScreenDesigner.SetCurrentDesignData(dataToUse);
				
				ScreenDesignerCanvas.ZoomToFrame();
				ScreenDesignerCanvas.Render();
			}
		}
		else
		{
			console.log("ScreenDesigner_Gallery_UI_HandleItemSelected: designData is either undefined on is fails validation");
		}
	}	

	var ScreenDesigner_Gallery_UI_UpdateOne = function(designImageElement)
	{
		var pngData = ScreenDesignerItemRenderer.RenderPNG(designImageElement.designData, {x:95, y:95});
		designImageElement.src = pngData;

		// 2018.01.11: Use a thread to render a thumbnail
		// 2022.04.14: Delay rendering example (gallery) thumbnails until later unless the gallery tab was already revealed.
		if (appGalleryRevealed)
			ScreenDesigner.RenderThumbnailDesign(designImageElement.designData, designImageElement, 250, galleryItemDim, {priority:TaskPriority.GALLERY_THUMBNAIL});
	}
	
	var ScreenDesigner_Gallery_UI_AddOne = function(design)
	{
		var contents = document.getElementById("ID_GalleryContent");
		if (contents != undefined)
		{
			var itemContainer = document.createElement('div');
			itemContainer.className = "GalleryItemContainer";
			
			// 2019.09.25: For small devices don't show the thumbnail, since it will be off screen for
			// the left column. Instead, double the size of the gallery items on a tap
			if (window.screen.width > 400)
			{
				itemContainer.addEventListener("mouseover", ScreenDesigner_Thumbnail.Show, false);
				itemContainer.addEventListener("mouseout",  ScreenDesigner_Thumbnail.Hide, false);
			}
			
			var designImage = document.createElement('img'); 
			designImage.className = "GalleryItem";
			designImage.designData = design;
			// 2019.09.25: Prevent click handling on gallery items on small devices
			if (window.screen.width > 400)
				designImage.onclick = ScreenDesigner_Gallery_UI_HandleItemSelected;
			
			// 2018.01.11: Add a reference to the thumbnail image to the element with the ScreenDesigner_Thumbnail_Show 
			itemContainer.thumbnailImg = designImage; 
			itemContainer.thumbnailOnLeft = true;
			
			itemContainer.appendChild(designImage);
			contents.appendChild(itemContainer);		
		
			// Render the tile into the 'img' element
			ScreenDesigner_Gallery_UI_UpdateOne(designImage);
		}
	}

	//---------------------------------------------------------------------------
	//	Gallery UI: Revealed
	//		2022.04.14: Added. Starts render of gallery thumbnails
	//---------------------------------------------------------------------------
	var ScreenDesigner_Gallery_UI_Revealed = function()
	{
		// The 'revealed' flag is used only to indicate to the load callback function that the
		// gallery tab is selected and that the thumbnails should be rendered when received.
		appGalleryRevealed = true;

		var contents = document.getElementById("ID_GalleryContent");
		if (contents != undefined)
		{
			let designElements = contents.getElementsByClassName("GalleryItem");

			for (let i = 0; i < designElements.length; i++)
			{
				let designImageElement = designElements[i];
				if (!designImageElement.thumbnailGenerated)
				{
					ScreenDesigner.RenderThumbnailDesign(designImageElement.designData, designImageElement, 250, galleryItemDim, {priority:TaskPriority.GALLERY_THUMBNAIL});
					designImageElement.thumbnailGenerated = true;
				}
			}
		}
	}

	var ScreenDesigner_Gallery_UI_Rebuild = function(workbook)
	{
		// (Re-)Builds the UI for the gallery. A gallery is 
		// simply a workbook.
		// Pass undefined to clear the gallery items from the UI
		
		var contents = document.getElementById("ID_GalleryContent");
		if (contents != undefined)
		{
			// Remove all items on the page in the workbook Content area
			while (contents.hasChildNodes())
    			contents.removeChild(contents.lastChild);
			
			// Add the designs if a workbook was provided
			if (workbook != undefined)
			{
				for (var i = 0; i < workbook.designs.length; i++)
					ScreenDesigner_Gallery_UI_AddOne(workbook.designs[i]);
			}
		}
	}

	var ScreenDesigner_Gallery_UseWorkbook = function(loadedGallery)
	{
		appGallery = loadedGallery;
		
		ScreenDesigner_Workbook.SetObjectPrototypes(loadedGallery);
		ScreenDesigner_Gallery_UI_Rebuild(loadedGallery);
	}
	

	var ScreenDesigner_Gallery_Load = function()
	{
		var galleryRequest = new XMLHttpRequest();

		galleryRequest.addEventListener("load",  ScreenDesigner_Gallery_LoadURLCompleted);
		galleryRequest.addEventListener("error", ScreenDesigner_Gallery_LoadFailed);
		galleryRequest.addEventListener("abort", ScreenDesigner_Gallery_LoadFailed);

		galleryRequest.open('GET', appGalleryURL);
		
		// 2018.01.11: Handle failure
		try {
			galleryRequest.send(null);
		}
		
		catch (error)
		{
			// Should we do something here?
			console.log("Polygonia -- " + error);
		}
	}
	
	var ScreenDesigner_Gallery_LoadTest = function()
	{
		/* 
		var str = "";
		var g = JSON.parse(str);
		ScreenDesigner_Gallery_UseWorkbook(g);
		*/
	}
	
	var ScreenDesigner_Gallery_LoadFailed = function(e)
	{
		// Failed to load gallery file
		ScreenDesigner_Gallery_LoadTest();
	}
	
	var ScreenDesigner_Gallery_LoadURLCompleted = function(evt)
	{
		// Get the contents from the (XMLHttpRequest) event
		
		if (this.status == 200)
		{
			// Parse the contents
			var contents = this.response;
			var loadedGallery = JSON.parse(contents);

			if (loadedGallery != undefined && loadedGallery["reserved"] == ScreenDesignerDataType.WORKBOOK_DATA)
			{
				ScreenDesigner_Gallery_UseWorkbook(loadedGallery);
			}
		}
		else
		{
			console.log("Unable to load gallery. Status: " + this.status);
		}
	}
	
	var ScreenDesigner_Gallery_Resize = function()
	{
		var galleryDiv = document.getElementById("ID_GalleryContent");
		if (galleryDiv != undefined)
		{
			// There is probably a better way to do this, especially the magic "200"
			// 2019.09.24: I removed this when allowing sign in on iPhones. Removing
			// these lines addressed the issue where the gallery was too short (since I
			// was also removing the radio tabs above the and buttons below). I think 
			// it is working now because of other improvements in the CSS.
			//
			//var h = window.innerHeight;
			//galleryDiv.style.maxHeight =  (h - 200) + "px";
		}
	}
	
	return {
		Load:			ScreenDesigner_Gallery_Load,
		UseWorkbook:	ScreenDesigner_Gallery_UseWorkbook,
		Resize:			ScreenDesigner_Gallery_Resize,
		Revealed:		ScreenDesigner_Gallery_UI_Revealed
	}
	
}());


//---------------------------------------------------------------------------
//	ScreenDesigner_Thumbnail 
// 		2018.07.19: refactored to new class
//---------------------------------------------------------------------------
var ScreenDesigner_Thumbnail = (function() {

	// Thumbnail Popup size
	var thumbnailPopupDim = {size:{x:150, y:150}, offset:{x:2, y:-10}, margin:10};
	
	var ScreenDesigner_Thumbnail_Show = function(evt)
	{
		//var tImgs = this.getElementsByClassName("WorkbookItemImage");
		var tImg = this.thumbnailImg;
		var showOnLeft = this.thumbnailOnLeft;
		var e = document.getElementById("ID_ThumbnailPopup");
		var eInfo = document.getElementById("ID_ThumbnailInfo"); // 2018.01.23: Added
		// 2019.10.13: Don't show thumbnail if it is not set
		if (e != undefined && tImg != undefined && tImg.src.length > 0)
		{
			var pImgs = e.getElementsByTagName("img");
			
			if (pImgs.length > 0)
			{
				var pImg = pImgs[0];
				//pImg.src = tImgs[0].src;
				pImg.src = tImg.src;
				
				var imgSize = {x:pImg.naturalWidth, y:pImg.naturalHeight};

				var scaledSize = MathUtil.ScaleSizeIntoContainerSize(imgSize, thumbnailPopupDim.size);
				
				// Set the size of the img element
				pImg.style.width  = scaledSize.x + "px";
				pImg.style.height = scaledSize.y + "px";
				
			
				var r = this.getBoundingClientRect();
				
				var top = (r.top   + thumbnailPopupDim.offset.y);
				var width = (scaledSize.x + 2 * thumbnailPopupDim.margin);
				var height = (scaledSize.y + 2 * thumbnailPopupDim.margin);
				var left;
				if (showOnLeft)
					left = (r.left - thumbnailPopupDim.offset.x - scaledSize.x - 2 * thumbnailPopupDim.margin - 15);
				else
					left = (r.right + thumbnailPopupDim.offset.x);

				// 2019.09.16: Keep thumbnail on screen to prevent scroll bar from popping up on Mac
				if (top > window.innerHeight - height - 10)
					top = window.innerHeight - height - 10;
			
				// Position and size the div enclosing the img element
				e.style.top    = top + "px";
				e.style.left   = left + "px";
				e.style.width  = width + "px";
				e.style.height = height + "px";
				e.style.display = "block";
		
				 // 2018.01.23: If there is info for the thumbnail, then show the "info" div
				var tInfo = this.thumbnailInfo; // 2018.01.23: Added
				if (eInfo != undefined && tInfo != undefined && tInfo.func != undefined && tInfo.context != undefined)
				{
					// Position and size the div enclosing the img element
					var padding = 2;
					var height = 12; // font size
					eInfo.style.top   = (top - height - 5 * padding) + "px";
					eInfo.style.left  = (left ) + "px";
					eInfo.style.width = (width - 2 * padding) + "px";
					eInfo.style.height = (height + 2 * padding) + "px";
					eInfo.style.display = "block";
				
					var str = tInfo.func(tInfo.context);
					eInfo.innerHTML = str;
				}
			}
		}
		else
		{
			//console.log("ScreenDesigner_Thumbnail_Show: ID_ThumbnailPopup not found");
		}
	}

	var ScreenDesigner_Thumbnail_Hide = function(evt)
	{
		var e = document.getElementById("ID_ThumbnailPopup");
		if (e != undefined)
		{
			e.style.display = "none";
		}
		else
		{
			console.log("ScreenDesigner_Thumbnail_Hide: ID_ThumbnailPopup not found");
		}

		var e = document.getElementById("ID_ThumbnailInfo");
		if (e != undefined)
		{
			e.style.display = "none";
		}
		else
		{
			console.log("ScreenDesigner_Thumbnail_Hide: ID_ThumbnailPopup not found");
		}
	}

	return {
		Hide:			ScreenDesigner_Thumbnail_Hide,
		Show:			ScreenDesigner_Thumbnail_Show
	}
	
}());


//---------------------------------------------------------------------------
// ScreenDesigner_Slideshow
//---------------------------------------------------------------------------
var ScreenDesigner_Slideshow = (function() {
	/*
	var ScreenDesigner_StartSlideshow = function(workbook)
	{
		var slideshowContext = {};
		
		slideshowContext.workbook = workbook;
		slideshowContext.nextIndex = 0;
		slideshowContext.delay = 5000;
		slideshowContext.startDelay = 100;
		slideshowContext.timer = undefined;
		
		appSlideshow = slideshowContext;
		
		slideshowContext.timer = setTimeout(ScreenDesigns_AdvanceSlideshow, slideshowContext.startDelay, appSlideshow);
	}
	
	var ScreenDesigns_AdvanceSlideshow = function(slideshowContext)
	{
		// Clear current timer
		slideshowContext.timer = undefined;
		
		// Display the next design
		var idx = slideshowContext.nextIndex;
		var designData = slideshowContext.workbook.designs[idx];
		var dataToUse = designData.Clone();

		ScreenDesigner_SetCurrentDesignData(dataToUse);
		ScreenDesignerCanvas.ZoomToFrame();
		ScreenDesignerCanvas.Render();
		
		// Update the index
		slideshowContext.nextIndex = ((idx + 1) % slideshowContext.workbook.designs.length);
		
		// Start a new timer
		slideshowContext.timer = setTimeout(ScreenDesigns_AdvanceSlideshow, slideshowContext.delay ms, slideshowContext);
		
	}
	
	var ScreenDesigner_CancelSlideshow = function()
	{
		if (appSlideshow != undefined && appSlideshow.timer != undefined)
		{
			clearTimeout(appSlideshow.timer);
			appSlideshow.timer = undefined;
		}
		
		appSlideshow = undefined;
	}
	
	
	return {
		xx:						xx,
		yy:						yy
	}
	*/
}());


	
//---------------------------------------------------------------------------
// Help
//---------------------------------------------------------------------------
var ScreenDesigner_HelpMgr = (function() {

	// Help system (2018.06.06)
	var appHelpMgr = undefined;

	var ScreenDesigner_HelpMgr_Show = function()
	{
		if (appHelpMgr == undefined)
			appHelpMgr = new HelpMgr(ScreenDesigner_HelpCallback);
			
		appHelpMgr.ShowHelp(0);
	}

	var ScreenDesigner_HelpCallback = function(pageNumber, helpData)
	{
		helpData.lastPage = false;
		
		if (pageNumber == 0) // Introduction
		{
			helpData.mainText = 
				"<em>Welcome to Polygonia</em><br><br>"+
				"Press the 'Next' button, below, or press the right arrow for a quick introduction.<br><br>" +
				"Move the mouse over <span class='CL_HelpHighlight' data-id-help='ID_PolygoniaHelp'>text in boxes</span> for highlights.<br><br>" +
				"Press 'Done' or the escape key to start right away!";
		}
		else if (pageNumber == 1) // Editing Designs
		{
			ScreenDesigner.UI_SelectTab("ID_TilingTabAreaR");
			ScreenDesigner.UI_SelectTab("cT01");
			
			helpData.mainText = 
				"<em>Creating Your Design</em><br><br>" +
				"You create your design by drawing lines (also called 'segments') in a <u>tile</u> in the " +
					"<span class='CL_HelpHighlight' data-id-help='ID_TileCanvas'>editor on the right.</span><br><br>" +
				"Each line segment you draw is mirrored, and then the original line and mirrored copy are rotated around the center of the tile.<br><br>" +
				"Click on the <span class='CL_HelpHighlight' data-id-help='cT01L'>Labels ....</span> to hide and show " + 
					"<span class='CL_HelpHighlight' data-id-help='cT01C'>additional options</span><br><br>" +
				"You can change the tile shape with the <span class='CL_HelpHighlight' data-id-help='ID_TileShape'>Shape:</span> popup menu.";
		}
		else if (pageNumber == 2) // Frame Options
		{
			ScreenDesigner.UI_SelectTab("ID_FrameTabAreaR");
			helpData.mainText = 
				"<em>Changing the Frame</em><br><br>" +
				"You can change the <u>frame</u> from a rectangle to a triangle, hexagon, or other shape by selecting it from the " +
					"<span class='CL_HelpHighlight' data-id-help='ID_FrameShape'>'Shape:' popup menu.</span><br><br>" +
				"The <span class='CL_HelpHighlight' data-id-help='ID_FrameBorder'>'Border Width'</span> sets the thickness or width of the frame.";
		}
		else if (pageNumber == 3) // Projects
		{
			ScreenDesigner.UI_SelectTab("ID_FileTabAreaR");
			ScreenDesigner.UI_SelectTab("cD03", false);
			ScreenDesigner.UI_SelectTab("cD01", false);
			ScreenDesigner.UI_SelectTab("cD04", false);
			ScreenDesigner.UI_SelectTab("cD06");
			helpData.mainText = 
				"<em>Projects: Storage for Designs</em><br><br>" +
				"Designs are stored in a Project and are saved in the cloud.<br><br>" + 
				"<span class='CL_HelpHighlight' data-id-help='ID_NewProjectDesign2'>New Design in Project</span> will create a new empty design in the current project.<br><br> " +
				"<span class='CL_HelpHighlight' data-id-help='ID_AddProjectDesign2'>Add Design to Project</span> will add the design to the current project. If the " +
					"design is already in the project, then it will add a copy of the design.<br><br>" +
				"<span class='CL_HelpHighlight' data-id-help='ID_NewProject2'>New Project</span> will create a new, empty project.<br><br>" +
				"<span class='CL_HelpHighlight' data-id-help='ID_OpenProject2'>Open Project</span> will show the list projects. "
		}
		else if (pageNumber == 4) // Creating New Designs
		{
			ScreenDesigner.UI_SelectTab("ID_FileTabAreaR");
			ScreenDesigner.UI_SelectTab("cD06", false);
			ScreenDesigner.UI_SelectTab("cD03");
			helpData.mainText = 
				"<em>Creating New Designs</em><br><br>" +
				"Click on the <span class='CL_HelpHighlight' data-id-help='cD03L'>Create New Design...</span> label to show the options for creating a new design."
		}
		else if (pageNumber == 5)
		{
			ScreenDesigner.UI_SelectTab("ID_FileTabAreaR");
			ScreenDesigner.UI_SelectTab("cD01");
			ScreenDesigner.UI_SelectTab("cD03", false);
			helpData.mainText = 
				"<em>Downloading Your Design</em><br><br>" +
				"Enter a name in the <span class='CL_HelpHighlight' data-id-help='ID_DocumentName'>File Name:</span> field<br><br>" +
				"<span class='CL_HelpHighlight' data-id-help='ID_DownloadOptions'>Select the file formats</span> you want to download <br><br> " + 
				"Click on the <span class='CL_HelpHighlight' data-id-help='ID_DownloadSelected'>Download Design in Selected Formats</span> button";
		}
		else if (pageNumber == 6)
		{
			ScreenDesigner.UI_SelectTab("ID_GalleryAreaR");
			helpData.mainText = 
				"<em>Gallery of Designs</em><br><br>" +
				"Looking for inspiration? You can use any of the designs in the gallery."
		}
		else
		{
			helpData.mainText = 
				"<em>Have Fun!</em><br><br>" +
				"If you have any questions or find any bugs, please email us!<br><br>" +
				"You can find our contact info on the <span class='CL_HelpHighlight' data-id-help='ID_About'>About</span> page.";
			
			helpData.lastPage = true;
		}
	}

	return {
		Show:			ScreenDesigner_HelpMgr_Show
	}
	
}());


/*-----------------------------------------------*
 * 2017.07.31: Safari is missing Object.assign
 * This will add the "assign" function to the Object
 * prototype so it is available everywhere.
 * https://stackoverflow.com/questions/39326907/safari-typeerror-undefined-is-not-a-function-evaluating-object-assign
 *-----------------------------------------------*/
if (!Object.assign) {
	Object.defineProperty(Object, 'assign', {
		enumerable: false,
		configurable: true,
		writable: true,
		value: function(target, firstSource) {
			'use strict';
			if (target === undefined || target === null) {
				throw new TypeError('Cannot convert first argument to object');
			}

			var to = Object(target);
			for (var i = 1; i < arguments.length; i++) {
				var nextSource = arguments[i];
				if (nextSource === undefined || nextSource === null) {
					continue;
				}

				var keysArray = Object.keys(Object(nextSource));
				for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
					var nextKey = keysArray[nextIndex];
					var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
					if (desc !== undefined && desc.enumerable) {
						to[nextKey] = nextSource[nextKey];
					}
				}
			}
			return to;
		}
	});
}

/*-----------------------------------------------*
 * Exports
 *-----------------------------------------------*/

function RebuildProject() { ScreenDesigner_StorageMgr.Rebuild(); }
global.RebuildProject = RebuildProject;

export {ScreenDesigner};
