//----------------------------------------------------------------------------------------
//	Palette Menu
//
//	Usage
//		<palettemenu></palettemenu>
//----------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------
// Palette Menu
//----------------------------------------------------------------------------------------
var PaletteMenu = (function() {

	var pm_Popup = undefined;
	var pm_ColorsContainer = undefined;
	var pm_Target = undefined;
	var pm_Tracking = false;
	var pm_ClearPaletteColor = Object.freeze("#e0e0e0");
	
	let pm_DefaultColorList = ["#ff0000", "#00ff00", "#0000ff", "#ffff00", "#ff00ff", "#00ffff"];
	
	var pm_ColorInfo = [];
	/*
	let pm_ColorInfo = [
		{color:"#ff0000", colorID:1}, 
		{color:"#00ff00", colorID:2}, 
		{color:"#0000ff", colorID:3}, 
		{color:"#00ff00", colorID:4}, 
		{color:"#ff00ff", colorID:5}, 
		{color:"#00ffff", colorID:6}, 
		];
	*/
	
	//------------------------------------------------------------------------------------
	// Init
	//------------------------------------------------------------------------------------
	var PaletteMenu_Init = function()
	{
		PaletteMenu_ConnectUI();
		PaletteMenu_AddPopup();
	}
	
	//------------------------------------------------------------------------------------
	//	Connect UI
	//------------------------------------------------------------------------------------
	var PaletteMenu_ConnectUI = function()
	{
		var cpList = document.getElementsByTagName("palettemenu");
		for (var i = 0; i < cpList.length; i++)
		{
			var e = cpList[i];
			PaletteMenu_Connect(e);
		}
	}

	//------------------------------------------------------------------------------------
	//	Connect one palette menu element
	//------------------------------------------------------------------------------------
	var PaletteMenu_Connect = function(paletteMenuElement)
	{
		paletteMenuElement.addEventListener("click", PaletteMenu_OnClick);
		
		//PaletteMenu_AddValueChangeObserver(paletteMenuElement);
		//PaletteMenu_HandleValueChange(paletteMenuElement);
	}

	//------------------------------------------------------------------------------------
	//	Add Value Change Observer
	//------------------------------------------------------------------------------------
	/*
	var PaletteMenu_AddValueChangeObserver = function(element)
	{
		var config = { attributes: true };
		
		var callback = function(mutationsList, observer) {
			for (var mutation of mutationsList)
			{
				//console.log(mutation.type, mutation.attributeName, mutation.target.id);
				
				if (mutation.type == "attributes" && mutation.attributeName == "value" && (pm_Target == undefined || pm_Target.id != mutation.target.id))
					PaletteMenu_HandleValueChange(mutation.target);
			}
		}
		
		var observer = new MutationObserver(callback);
		
		observer.observe(element, config);
	}
	*/

	//------------------------------------------------------------------------------------
	//	Handle Value Change
	//------------------------------------------------------------------------------------
	/*
	var PaletteMenu_HandleValueChange = function(element)
	{
		var paletteId = element.getAttribute("value");
		
		//element.style.backgroundColor = rgb;
		//element.dataset.rgb = rgb;
	}
	*/
	
	//------------------------------------------------------------------------------------
	//	On Change
	//------------------------------------------------------------------------------------
	/*
	var PaletteMenu_OnChange = function(evt)
	{
		console.log("PaletteMenu_OnChange", evt.target.id);
	}
	*/
	
	//------------------------------------------------------------------------------------
	//	Add Popup
	//------------------------------------------------------------------------------------
	var PaletteMenu_AddPopup = function()
	{
		// The div that contains the entire color picker
		var pmui = document.createElement("palettemenuui");
		pmui.classList.add("palettemenuui-hide");
		
		// For now, the 'colors container' will be the entire popup, but, by using
		// a separate variable, we can change this later in this function and the 
		// rest of the code will not need to change
		pm_ColorsContainer = pmui;
		PaletteMenu_PopulateColors();
		
		document.body.appendChild(pmui); 
		
		pm_Popup = pmui;
	}
			
	//------------------------------------------------------------------------------------
	//	Populate Colors
	//------------------------------------------------------------------------------------
	var PaletteMenu_PopulateColors = function()
	{
		pm_ColorsContainer.innerHTML = "";
		
		for (var i = 0; i < pm_ColorInfo.length; i++)
		{
			let ci = pm_ColorInfo[i];
			
			let cell = document.createElement("div");
			cell.classList.add("palettemenu-cell");
			
			var color = pm_ClearPaletteColor;
			
			if (typeof ci === "string")
				color = ci;
			else if (typeof ci === "object")
				color = ci.color;
				
			cell.style.backgroundColor = color;
			
			cell.paletteEntry = ci;
			cell.setAttribute("value", ci);
			
			cell.addEventListener("click", PaletteMenu_Select);
			pm_ColorsContainer.appendChild(cell);
		}
		
		if (1 /* add clear */)
		{
			let cell = document.createElement("div");
			cell.innerHTML = "&times;"
			cell.classList.add("palettemenu-cell");
			cell.classList.add("palettemenu-cell-delete");
			
			//var color = pm_ClearPaletteColor;


			cell.addEventListener("click", PaletteMenu_Select);
			pm_ColorsContainer.appendChild(cell);
		}
	}
			
	//------------------------------------------------------------------------------------
	//	Keep In Window
	//		Position the popup so that it is completely inside the window, if possible
	//------------------------------------------------------------------------------------
	var PaletteMenu_KeepInWindow = function()
	{
		let left = parseInt(pm_Popup.style.left);
		if (pm_Popup.clientWidth + left > document.documentElement.clientWidth)
		{
			var newLeft = (document.documentElement.clientWidth - pm_Popup.clientWidth);
			if (newLeft < 0)
				newLeft = 0;
			pm_Popup.style.left = newLeft + "px";
		}
	}
	
	//------------------------------------------------------------------------------------
	//	Show
	//		Shows/hides the popup and adds/removes a listener to the document to 
	//		detect mousedowns outside of the popup.
	//------------------------------------------------------------------------------------
	var PaletteMenu_Show = function(show)
	{
		if (show)
		{
			pm_Popup.classList.remove("palettemenuui-hide");
			
			// Add a listener to handle the case when the user clicks outside of the color picker
			document.addEventListener("mousedown", PaletteMenu_Hide)
		}
		else
		{
			pm_Popup.classList.add("palettemenuui-hide");
			// Remove the document listener
			document.removeEventListener("mousedown", PaletteMenu_Hide)
		}
	}

	//------------------------------------------------------------------------------------
	//	Hide
	//		Called only from a mousedown in the document.
	//------------------------------------------------------------------------------------
	var PaletteMenu_Hide = function(evt)
	{
		// If the mouse down is not in the popup and it is not
		// in a color picker element and the color picker popup is shown,
		// then hide the popup
		if (!pm_Popup.contains(evt.target) &&
			 evt.target.tagName != "PALETTEMENU" && 
			!pm_Popup.classList.contains("palettemenuui-hide"))
		{
			pm_Target = undefined;
			PaletteMenu_Show(false);
			evt.stopPropagation();
			evt.preventDefault();
		}
	}
	
	//------------------------------------------------------------------------------------
	//	Load Colors
	//------------------------------------------------------------------------------------
	var PaletteMenu_LoadColors = function()
	{
		if (pm_Target == undefined)
		{
			// no pm_Target
			pm_ColorInfo = pm_DefaultColorList;
		}
		else if (typeof pm_Target.paletteInfo === "function")
		{
			// Palette loaded from palette function
			let f = pm_Target.paletteInfo;
			pm_ColorInfo = f(pm_Target);
		}
		else if (Array.isArray(pm_Target.paletteInfo))
		{
			// palette provided in pm_Target
			pm_ColorInfo = pm_Target.paletteInfo;
		}
		else
		{
			// Use default colors if pm_Target.dataset.palette is not set
			pm_ColorInfo = pm_DefaultColorList;
		}
	}
	
	//------------------------------------------------------------------------------------
	//	On Click
	//------------------------------------------------------------------------------------
	var PaletteMenu_OnClick = function(evt)
	{
		pm_Tracking = false;

		// If it the picker is already displayed and if the click was on the same
		// control, then hide the color picker
		if (pm_Target != undefined && pm_Target == evt.target)
		{
			pm_Target = undefined;
			PaletteMenu_Show(false);
		}
		else
		{
			pm_Target = evt.target;
			
			// Now that we have a target, we can ask it for the colors to display
			PaletteMenu_LoadColors();
			PaletteMenu_PopulateColors();

			// Position the color picker below the control
			var rect = this.getBoundingClientRect();
			pm_Popup.style.top = (rect.bottom + 5) + "px";
			pm_Popup.style.left = rect.left + "px";
		
			// If it is not displayed, then show it
			if (pm_Popup.classList.contains("palettemenuui-hide"))
				PaletteMenu_Show(true);
			
			// Show the current color info
			//PaletteMenu_PopulateColorInfo(pm_Target.dataset.rgb);

			// Keep it in the window, if possible
			PaletteMenu_KeepInWindow();
		}

		evt.stopPropagation();
		evt.preventDefault();
	}
	
	
	//------------------------------------------------------------------------------------
	//	Select
	//------------------------------------------------------------------------------------
	var PaletteMenu_Select = function(evt)
	{
		let rgb = evt.target.style.backgroundColor;
		pm_Target.style.backgroundColor = rgb;
		
		let value = evt.target.getAttribute("value");
		pm_Target.setAttribute("value", value);
		
		let ci = evt.target.paletteEntry
		pm_Target.paletteEntry = ci;
		
		PaletteMenu_DispatchEvent();

		pm_Target = undefined;
		PaletteMenu_Show(false);
	}
	
	//------------------------------------------------------------------------------------
	//	Set Value
	//------------------------------------------------------------------------------------
	var PaletteMenu_SetColorInfo = function(paletteMenu, colorInfo)
	{
		var color = pm_ClearPaletteColor;
		
		if (typeof colorInfo === "string")
			color = colorInfo;
		else if (colorInfo != undefined && typeof colorInfo === "object")
			color = colorInfo.color;
		
		if (paletteMenu != undefined)
		{
			paletteMenu.style.backgroundColor = color;
			paletteMenu.paletteEntry = colorInfo;
		}
		else
		{
			console.log("PaletteMenu_SetColorInfo: paletteMenu is undefined");
		}
	}
	
	//------------------------------------------------------------------------------------
	//	Update Matching Color Info
	//------------------------------------------------------------------------------------
	var PaletteMenu_UpdateMatchingColorInfo = function(paletteMenu, colorId, color)
	{
		/*
		if (typeof colorInfo === "string")
			color = colorInfo;
		else if (colorInfo != undefined && typeof colorInfo === "object")
			color = colorInfo.color;
		*/
		
		if (paletteMenu != undefined)
		{
			if (paletteMenu.paletteEntry != undefined)
			{
				// Note that we are assuming a {colorID, color} object
				if (paletteMenu.paletteEntry.colorId == colorId)
				{
					paletteMenu.paletteEntry.color = color;
					paletteMenu.style.backgroundColor = color;
				}
			}
		}
		else
		{
			console.log("PaletteMenu_UpdateMatchingColorInfo: paletteMenu is undefined");
		}
	}
	
	//------------------------------------------------------------------------------------
	//	Dispatch Event
	//------------------------------------------------------------------------------------
	var PaletteMenu_DispatchEvent = function()
	{
		if (pm_Target != undefined)
		{
			var event = new Event("change", {bubbles: true, cancelable: true});
			pm_Target.dispatchEvent(event);
		}
		else
		{
			console.log("PaletteMenu_DispatchEvent: No target; event dropped");
		}
	}
	
	//------------------------------------------------------------------------------------
	//	Public API
	//------------------------------------------------------------------------------------
	return	{
		Init:						PaletteMenu_Init,
		Connect:					PaletteMenu_Connect,
		SetColorInfo:				PaletteMenu_SetColorInfo,
		UpdateMatchingColorInfo:	PaletteMenu_UpdateMatchingColorInfo
	};
}());

export { PaletteMenu };
