import { MathUtil } from "../../VectorUtilsJS/src/VectorUtilLib.js";


/*-----------------------------------------------*
 *	Utilities
 *
 *-----------------------------------------------*/
 
//-------------------------------------------------------------------
//	Calc Regular Polygon Points
//		Calculate the equally spaced points around a circle
//
//	Note that the the first point is on the x-axis
//
//	Params
//		radius
//		center {x, y}
//		sides
//		rotateHalf (optional, bool)
//		rotate (option, float)
//-------------------------------------------------------------------
function CalcRegularPolygonPoints(params)
{
	var points = [];
	var delta = 2 * Math.PI / params.sides;
	
	for (var v = 0; v < params.sides; v++)
	{
		var angle = v * delta;
		
		if (params.rotateHalf)
			angle += delta/2;
		else if (params.rotate)
			angle += params.rotate;
			
		var x = params.center.x + params.radius * Math.cos(angle);
		var y = params.center.y + params.radius * Math.sin(angle);

		points.push({x, y});
	}
	
	return points;
}


/*-----------------------------------------------*
 * Tessellation's
 *
 *-----------------------------------------------*/


var SquareTesselation = (function() {
	var SquareTesselation_CalcLayout = function(screenData)
	{
		var layout = {};
		
		// Size is height
		layout.size = screenData.tiling.size;
		
		layout.edgeLen = layout.size;
		
		layout.centered = screenData.tiling.centered;
		
		// 2018.06.07: Added
		layout.offsetX = screenData.tiling.offsetX;
		layout.offsetY = screenData.tiling.offsetY;
		layout.rotation = screenData.tiling.rotation;
		
		// Compute radius and edge length
		
		
		layout.spacing = {};
		layout.spacing.x = layout.size; // Center-to-center horizontal distance
		layout.spacing.y = layout.size;
	
		layout.sizeRadius = layout.size / Math.sqrt(2.0); // Radius of a circle circumscribing the square
		
		layout.sides = 4;
		layout.angle = 2 * Math.PI / layout.sides;
		
		// Describe a right triangle that is one-sixth of the equilateral triangle
		layout.subtile = {};
		layout.subtile.height = layout.edgeLen/2.0;
		layout.subtile.width  = layout.edgeLen/2.0;
		layout.subtile.angle  = layout.angle/2.0;

		return layout;
	}

	var SquareTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};

		minimalRepeatBounds.min.x = 0;
		minimalRepeatBounds.min.y = 0;
		minimalRepeatBounds.max.x = tilingLayout.edgeLen;
		minimalRepeatBounds.max.y = tilingLayout.edgeLen;
		
		return minimalRepeatBounds;
	}

	
	var SquareTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}};
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);

		// 2018.06.07: Fix the problem where some tiles are not included and handle tile offset
		var shiftX = tilingLayout.offsetX - (tilingLayout.centered ? tilingLayout.spacing.x/2 : 0);
		var shiftY = tilingLayout.offsetY - (tilingLayout.centered ? tilingLayout.spacing.y/2 : 0);
		
		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX - tilingLayout.spacing.x + 1)/tilingLayout.spacing.x);
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY - tilingLayout.spacing.y + 1)/tilingLayout.spacing.y);
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX + tilingLayout.spacing.x - 1)/tilingLayout.spacing.x);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY + tilingLayout.spacing.y - 1)/tilingLayout.spacing.y);
		
		return iterBounds;
	}
	
	var SquareTesselation_GetOriginOffset = function(tilingLayout)
	{
		var x = tilingLayout.spacing.x/2;
		var y = tilingLayout.spacing.y/2;
		
		if (tilingLayout.centered)
		{
			x = 0; 
			y = 0;
		}

		// 2018.06.07: Added		
		x += tilingLayout.offsetX;
		y += tilingLayout.offsetY;
		
		return {x:x, y:y};
	}
	
	var SquareTesselation_CalcTileCenter = function(tilingLayout, xPos, yPos)
	{
		var offset = SquareTesselation_GetOriginOffset(tilingLayout);
		var x = xPos * tilingLayout.spacing.x + offset.x;
		var y = yPos * tilingLayout.spacing.y + offset.y;
		
		return {x:x, y:y};
	}
	
	var SquareTesselation_CalcTileBounds= function(tilingLayout, xPos, yPos)
	{
		var center = SquareTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		var h = tilingLayout.spacing.x/2;
		var v = tilingLayout.spacing.y/2;
		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
	}

	var SquareTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		var tileInfo = {};
		var pointList = [];
		var clippingPointList = [];

		var center = SquareTesselation_CalcTileCenter(tilingLayout, xPos, yPos);

		for (var v = 0; v < 4; v++)
		{
			var angle = v * Math.PI / 2.0 + Math.PI/4.0 ;
			var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
			var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
			var pt = {x:x, y:y};
			clippingPointList.push(pt); // 2020.08.21: Used for clipToTile
			// 2018.06.08: Add rotation support
			if (tilingLayout.rotation != 0)
				pt = MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation);
			pointList.push(pt);
		}
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.rotCenter	= MathUtil.RotatePointAroundOrigin(center, tilingLayout.rotation); // 2021.03.15: Safer than updating the 'center'
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.clippingPoints = clippingPointList; // 2020.08.21
		
		// 2022.01.29: Compute flag to indicate if this is a "white" or "black" square
		tileInfo.latticeFlag = (((Math.abs(xPos) + Math.abs(yPos)) % 2) == 1);
				
		// 2022.04.08: Return perimeter, area, and 'scale to edge offset' factor
		let cos45 = 1/Math.sqrt(2); // sin(45), cos(45)
		let edge = 2 * tilingLayout.sizeRadius * cos45;
		tileInfo.perimeter = 4 * edge;
		tileInfo.area      = edge * edge;
		tileInfo.scaleOffsetConversion = tilingLayout.sizeRadius * cos45;

		return tileInfo;
	}

	var SquareTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		var tileInfo = {};
		var pointList = [];

		//var center = {x:0,  y:0};
		var center = SquareTesselation_GetOriginOffset(tilingLayout);

		for (var v = 0; v < 4; v++)
		{
			var angle = v * Math.PI / 2.0 + Math.PI/4.0 ;
			var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
			var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
			var pt = {x:x, y:y};
			pointList.push(pt);
		}
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.symmetryPoints = tileInfo.points; // 2020.08.18

		var a = Math.PI/4;
		tileInfo.snapDirections = [0.0, a, 2 * a, 3 * a];

		tileInfo.axisDirections = [0.0, a, 2 * a, 3 * a, 4 * a, 5 * a, 6 * a, 7 * a];

		return tileInfo;
	}
	
	var SquareTesselation_CreateTileListAround = function(xPos, yPos)
	{
		var tileLocations = [];
		
		tileLocations.push({x:xPos,     y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos - 1});
		tileLocations.push({x:xPos,     y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos});
		tileLocations.push({x:xPos + 1, y:yPos + 1});
		tileLocations.push({x:xPos,     y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos});
		
		return tileLocations;
	}

	//-------------------------------------------------------------------
	//	AdjacentTile
	//		2021.08.30: Added
	//-------------------------------------------------------------------
	var SquareTesselation_AdjacentTile = function(tile)
	{
		let adjacentTile = undefined;
		
		if (tile.edge == 0)
			adjacentTile = {x:tile.x,     y:tile.y + 1, edge:2};
		else if (tile.edge == 1)
			adjacentTile = {x:tile.x - 1, y:tile.y,     edge:3};
		else if (tile.edge == 2)
			adjacentTile = {x:tile.x,     y:tile.y - 1, edge:0};
		else if (tile.edge == 3)
			adjacentTile = {x:tile.x + 1, y:tile.y,     edge:1};

		return adjacentTile;
	}

	return {
		CalcLayout:						SquareTesselation_CalcLayout,
		CalcIteratorBounds:				SquareTesselation_CalcIteratorBounds,
		CalcTileBounds:					SquareTesselation_CalcTileBounds,
		CalcTileInfo:					SquareTesselation_CalcTileInfo,
		CalcTileCenter:					SquareTesselation_CalcTileCenter,
		CalcUnitTileInfo:				SquareTesselation_CalcUnitTileInfo,
		CreateTileListAround:			SquareTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			SquareTesselation_GetMinimalRepeatBounds,
		AdjacentTile:					SquareTesselation_AdjacentTile	// 2021.08.30
	};
}());

var SquareOffsetTesselation = (function() {
	var SquareOffsetTesselation_CalcLayout = function(screenData)
	{
		var layout = SquareTesselation.CalcLayout(screenData);

		return layout;
	}

	var SquareOffsetTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};
		
		minimalRepeatBounds.min.x = 0;
		minimalRepeatBounds.min.y = 0;
		minimalRepeatBounds.max.x = tilingLayout.edgeLen * 2;
		minimalRepeatBounds.max.y = tilingLayout.edgeLen;
		
		return minimalRepeatBounds;
	}

	
	var SquareOffsetTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}}
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);

		// 2019.02.22: Fix the problem where some tiles are not included and handle tile offset
		var shiftX = tilingLayout.offsetX - (tilingLayout.centered ? tilingLayout.spacing.x/2 : 0);
		var shiftY = tilingLayout.offsetY - (tilingLayout.centered ? tilingLayout.spacing.y/2 : 0);

		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX - tilingLayout.spacing.x + 1)/tilingLayout.spacing.x);
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY - tilingLayout.spacing.y + 1)/tilingLayout.spacing.y);
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX + tilingLayout.spacing.x - 1)/tilingLayout.spacing.x);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY + tilingLayout.spacing.y - 1)/tilingLayout.spacing.y);
		
		return iterBounds;
	}

	var SquareOffsetTesselation_CalcTileBounds= function(tilingLayout, xPos, yPos)
	{
		var center = SquareTesselation.CalcTileCenter(tilingLayout, xPos, yPos);
		var h = tilingLayout.spacing.x/2;
		var v = tilingLayout.spacing.y/2;
	
		if (Math.abs(xPos % 2) == 1)
		{
			var offset = tilingLayout.size/2;
			v += offset; 
		}
		
		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
	}

	
	var SquareOffsetTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		// 2019.02.22: Copied from SquareTesselation to fix rotated tiles issue
		var tileInfo = {};
		var pointList = [];
		var clippingPointList = [];

		var center = SquareTesselation.CalcTileCenter(tilingLayout, xPos, yPos);

		if (Math.abs(xPos % 2) == 1)
			center.y += tilingLayout.size/2;

		for (var v = 0; v < 4; v++)
		{
			var angle = v * Math.PI / 2.0 + Math.PI/4.0 ;
			var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
			var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
			var pt = {x:x, y:y};
			clippingPointList.push(pt); // 2020.08.21: Used for clipToTile
			// 2018.06.08: Add rotation support
			if (tilingLayout.rotation != 0)
				pt = MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation);
			pointList.push(pt);
		}
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.rotCenter	= MathUtil.RotatePointAroundOrigin(center, tilingLayout.rotation); // 2021.03.15: Safer than updating the 'center'
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.clippingPoints = clippingPointList; // 2020.08.21
		
				
		// 2022.04.08: Return perimeter, area, and 'scale to edge offset' factor
		let cos45 = 1/Math.sqrt(2); // sin(45), cos(45)
		let edge = 2 * tilingLayout.sizeRadius * cos45;
		tileInfo.perimeter = 4 * edge;
		tileInfo.area      = edge * edge;
		tileInfo.scaleOffsetConversion = tilingLayout.sizeRadius * cos45;

		return tileInfo;
	}
	
	var SquareOffsetTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		return SquareTesselation.CalcUnitTileInfo(tilingLayout);
	}

	var SquareOffsetTesselation_CreateTileListAround = function(xPos, yPos)
	{
		return SquareTesselation.CreateTileListAround(xPos, yPos);
	}

	return {
		CalcLayout:						SquareOffsetTesselation_CalcLayout,
		CalcIteratorBounds:				SquareOffsetTesselation_CalcIteratorBounds,
		CalcTileInfo:					SquareOffsetTesselation_CalcTileInfo,
		CalcTileBounds:					SquareOffsetTesselation_CalcTileBounds,
		CalcUnitTileInfo:				SquareOffsetTesselation_CalcUnitTileInfo,
		CreateTileListAround:			SquareOffsetTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			SquareOffsetTesselation_GetMinimalRepeatBounds
	};
}());

var TrigridTesselation = (function() {

	var TriGrid_CalcTriOrientation = function(xPos, yPos)
	{
		var trianglePointsUp = false;
	
		var evenCol = ((xPos % 2) == 0);
		var evenRow = ((yPos % 2) == 0);
	
		trianglePointsUp = (evenCol && evenRow) || (!evenCol && !evenRow);
	
		return trianglePointsUp;	
	}

	var TriGrid_CalcTileCenter = function(layout, xPos, yPos)
	{
		var center = {x:0, y:0};
		
		center.x = xPos * layout.spacing.x;
		center.y = yPos * layout.spacing.y;
		
		// 2018.06.07: Added		
		center.x += layout.offsetX;
		center.y += layout.offsetY;

		if (!layout.centered)
		{
			center.x += layout.edgeLen/2;
			center.y += layout.verticalCenter;
		}
		else
		{
			// 2018.02.02: Added "orthocentered" option
			if (layout.orthocentered)
				center.y +=  layout.vertOffset;
		}

		return center;
	}

	var TriGrid_CalcTriOrthoCenter = function(layout, xPos, yPos)
	{
		var trianglePointsUp = TriGrid_CalcTriOrientation(xPos, yPos);

		//var center = {x:0, y:0};
		//center.x = xPos * layout.spacing.x;
		//center.y = yPos * layout.spacing.y + layout.verticalCenter;
		//center.y += layout.vertOffset * (trianglePointsUp ? 1 : -1);

		var center = TriGrid_CalcTileCenter(layout, xPos, yPos);
		center.y += layout.vertOffset * (trianglePointsUp ? -1 : 1);

		return center;
	}

	var TrigridTesselation_CalcLayout = function(screenData)
	{
		var layout = {};
		
		// Size is height of a triangle
		layout.size = screenData.tiling.size;
		
		layout.edgeLen = layout.size * 2.0 / Math.sqrt(3.0);

		layout.centered = screenData.tiling.centered;
		// 2018.02.02: Added
		layout.orthocentered = screenData.tiling.orthocentered;
		
		// 2018.06.07: Added
		layout.offsetX = screenData.tiling.offsetX;
		layout.offsetY = screenData.tiling.offsetY;
		layout.rotation = screenData.tiling.rotation;

		// Compute radius and edge length
		
		
		layout.spacing = {};
		layout.spacing.x = layout.edgeLen/2.0; // Center-to-center horizontal distance
		layout.spacing.y = layout.size; // layout.spacingEdgeLen * Math.sqrt(3.0)/2.0;
	
		layout.sizeRadius = layout.size * 2.0 / 3.0; // Radius of a circle circumscribing the triangle
		
		var verticalCenter = Math.sqrt(3.0)/4.0;
		var orthoCenter = 1.0/(2.0*Math.sqrt(3.0));
	
		layout.verticalCenter = verticalCenter * layout.edgeLen; // The height of the triangle divided by two
		layout.vertOffset = (verticalCenter - orthoCenter) * layout.edgeLen; // Offset from vert center to center of equilateral triangle
		
		layout.sides = 3;
		layout.angle = 2 * Math.PI / layout.sides;
		
		// 2021.04.09: The "min offset" is the space between the tiles (for image designs).
		layout.minOffsetFactor = 3/2;
		
		// Describe a right triangle that is one-sixth of the equilateral triangle
		layout.subtile = {};
		layout.subtile.height = layout.sizeRadius/2.0;
		layout.subtile.width  = layout.edgeLen/2.0;
		layout.subtile.angle  = layout.angle/2.0;

		return layout;
	}

	var TrigridTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};
		
		minimalRepeatBounds.min.x = 0;
		minimalRepeatBounds.min.y = 0;
		minimalRepeatBounds.max.x = tilingLayout.edgeLen;
		minimalRepeatBounds.max.y = tilingLayout.spacing.y * 2;
		
		return minimalRepeatBounds;
	}

	var TrigridTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}}
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);
		
		// 2018.06.07: Handle tile offset and centering
		var shiftX = tilingLayout.offsetX;
		var shiftY = tilingLayout.offsetY;

		// 2018.06.07: These lines are copied from TriGrid_CalcTileCenter
		if (!tilingLayout.centered)
		{
			shiftX += tilingLayout.edgeLen/2;
			shiftY += tilingLayout.verticalCenter;
		}
		else
		{
			if (tilingLayout.orthocentered)
				shiftY +=  tilingLayout.vertOffset;
		}

		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX - tilingLayout.spacing.x + 1)/tilingLayout.spacing.x);
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY - tilingLayout.spacing.y + 1)/tilingLayout.spacing.y);
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX + tilingLayout.spacing.x - 1)/tilingLayout.spacing.x);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY + tilingLayout.spacing.y - 1)/tilingLayout.spacing.y);
		
		return iterBounds;
	}
	
	var TrigridTesselation_CalcTileBounds = function(tilingLayout, xPos, yPos)
	{
		var center = TriGrid_CalcTileCenter(tilingLayout, xPos, yPos);
		var h = tilingLayout.spacing.x/2;
		var v = tilingLayout.spacing.y/2;
		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
	}
	
	var TrigridTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		var triCoords = {};
		var pointList = [];
		var clippingPointList = [];

		var trianglePointsUp = TriGrid_CalcTriOrientation(xPos, yPos);
		var center = TriGrid_CalcTriOrthoCenter(tilingLayout, xPos, yPos);
		var direction = (trianglePointsUp ? 0: Math.PI) + Math.PI/2.0; /* 90 degrees */

		for (var v = 0; v < 3; v++)
		{
			var angle = v * 2.0 * Math.PI / 3.0 + direction;
			var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
			var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
			var pt = {x:x, y:y};
			clippingPointList.push(pt); // 2020.08.21: Used for clipToTile
			// 2018.06.08: Add rotation support
			if (tilingLayout.rotation != 0)
				pt = MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation);
			pointList.push(pt);
		}
		
		triCoords.points		= pointList;
		triCoords.center		= center;
		triCoords.rotCenter		= MathUtil.RotatePointAroundOrigin(center, tilingLayout.rotation); // 2021.03.15: Safer than updating the 'center'
		triCoords.startAngle	= (trianglePointsUp ? 0 : Math.PI);
		triCoords.sides			= tilingLayout.sides;
		triCoords.symmetrySides = triCoords.sides; // 2020.08.20: Added
		triCoords.clippingPoints = clippingPointList; // 2020.08.21

		// 2022.01.29: Return flag to indicate if this is an "adjacent" or "original" orientation
		triCoords.latticeFlag = !trianglePointsUp;

		// 2022.04.08: Return perimeter, area, and 'scale to edge offset' factor
		triCoords.perimeter = tilingLayout.sizeRadius * 3 * Math.sqrt(3); 	// 6 x r x cos(30)
		triCoords.area      = tilingLayout.sizeRadius * triCoords.perimeter / 4; 						// 6 x r x r x cos(30) * 1/2 * sin(30)
		triCoords.scaleOffsetConversion = tilingLayout.sizeRadius * 1/2; // sin(30)

		return triCoords;
	}

	var TrigridTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		var tileInfo = {};
		var pointList = [];

		var trianglePointsUp = true;
		var center = TriGrid_CalcTileCenter(tilingLayout, 0, 0); //{x:0, y:0};
		center.y += tilingLayout.vertOffset * (true /*trianglePointsUp*/ ? -1 : 1);
		var direction = (trianglePointsUp ? 0 : Math.PI) + Math.PI/2.0; /* 90 degrees */

		for (var v = 0; v < 3; v++)
		{
			var angle = v * 2.0 * Math.PI / 3.0 + direction;
			var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
			var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
			var pt = {x:x, y:y};
			pointList.push(pt);
		}
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.startAngle	= (trianglePointsUp ? Math.PI : 0);
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.symmetryPoints = tileInfo.points; // 2020.08.18

		var directionCount = tilingLayout.sides * 2;
		tileInfo.snapDirections = [];
		for (var i = 0; i < directionCount; i++)
			tileInfo.snapDirections.push(i * Math.PI/directionCount);
		
		var directionCount = tilingLayout.sides * 2;
		tileInfo.axisDirections = [];
		for (var i = 0; i < directionCount; i++)
			tileInfo.axisDirections.push(i * 2 * Math.PI/directionCount + Math.PI / 2.0 );

		return tileInfo;
	}
	
	var TrigridTesselation_CreateTileListAround = function(xPos, yPos)
	{
		var tileLocations = [];
		
		tileLocations.push({x:xPos,     y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos});
		tileLocations.push({x:xPos + 1, y:yPos});
		tileLocations.push({x:xPos - 2, y:yPos});
		tileLocations.push({x:xPos + 2, y:yPos});
		
		tileLocations.push({x:xPos,     y:yPos - 1});
		tileLocations.push({x:xPos - 1, y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos - 1});
		tileLocations.push({x:xPos - 2, y:yPos - 1});
		tileLocations.push({x:xPos + 2, y:yPos - 1});

		
		tileLocations.push({x:xPos,     y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos + 1});
		tileLocations.push({x:xPos + 1, y:yPos + 1});
		
		return tileLocations;
	}

	//-------------------------------------------------------------------
	//	AdjacentTile
	//		2021.08.30: Added
	//-------------------------------------------------------------------
	var TrigridTesselation_AdjacentTile = function(tile)
	{
		let adjacentTile = undefined;
		
		var trianglePointsUp = TriGrid_CalcTriOrientation(tile.x, tile.y);

		if (tile.edge == 0)
			adjacentTile = trianglePointsUp ? {x:tile.x - 1, y:tile.y, edge:0} : {x:tile.x + 1, y:tile.y, edge:0};

		else if (tile.edge == 1)
			adjacentTile = trianglePointsUp ? {x:tile.x, y:tile.y - 1, edge:1} : {x:tile.x, y:tile.y + 1, edge:1};

		else if (tile.edge == 2)
			adjacentTile = trianglePointsUp ? {x:tile.x + 1, y:tile.y, edge:2} : {x:tile.x - 1, y:tile.y, edge:2};

		return adjacentTile;
	}
	
	return {
		CalcLayout:						TrigridTesselation_CalcLayout,
		CalcIteratorBounds:				TrigridTesselation_CalcIteratorBounds,
		CalcTileInfo:					TrigridTesselation_CalcTileInfo,
		CalcTileBounds:					TrigridTesselation_CalcTileBounds,
		CalcUnitTileInfo:				TrigridTesselation_CalcUnitTileInfo,
		CreateTileListAround:			TrigridTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			TrigridTesselation_GetMinimalRepeatBounds,
		AdjacentTile:					TrigridTesselation_AdjacentTile	// 2021.08.30
	};
}());

var HexgridTesselation = (function() {
	var HexgridTesselation_CalcLayout = function(screenData)
	{
		var layout = {};
		
		// Size is height
		layout.size = screenData.tiling.size;
		
		layout.edgeLen = layout.size / Math.sqrt(3.0);
		
		// 2018.06.07: Added
		layout.offsetX = screenData.tiling.offsetX;
		layout.offsetY = screenData.tiling.offsetY;
		layout.rotation = screenData.tiling.rotation;

		// 2018.06.08: Added
		layout.centered = screenData.tiling.centered;

		// Compute radius and edge length
		
		layout.sizeRadius = layout.edgeLen; // Radius of a circle circumscribing the square
		
		layout.spacing = {};
		layout.spacing.x = layout.sizeRadius + layout.edgeLen / 2.0; // Center-to-center horizontal distance
		layout.spacing.y = layout.size;
	
		
		layout.sides = 6;
		layout.angle = 2 * Math.PI / layout.sides;
		
		// Describe a right triangle that is one-sixth of the equilateral triangle
		layout.subtile = {};
		layout.subtile.height = layout.size/2.0;
		layout.subtile.width  = layout.edgeLen/2.0;
		layout.subtile.angle  = layout.angle/2.0;

		return layout;
	}

	var HexgridTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};

		minimalRepeatBounds.min.x =  0;
		minimalRepeatBounds.min.y =  0;
		minimalRepeatBounds.max.x =  tilingLayout.edgeLen * 3;
		minimalRepeatBounds.max.y =  tilingLayout.size;
		
		return minimalRepeatBounds;
	}

	
	var HexgridTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}}
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);

		// 2018.06.07: Handle tile offset
		var shiftX = tilingLayout.offsetX;
		var shiftY = tilingLayout.offsetY;

		// 2022.02.11: Change how the bounds are extended. Previously we were simply subtracting
		// one from the spacing (e.g., spacing.x - 1), but this does not work well when the
		// tile size is a small number. which happens now that inches are now supported
		let margin = 0.99;

		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX)/tilingLayout.spacing.x - margin);
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY)/tilingLayout.spacing.y - margin);
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX)/tilingLayout.spacing.x + margin);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY)/tilingLayout.spacing.y + margin);
		
		return iterBounds;
	}
	
	var HexgridTesselation_CalcTileCenter = function(tilingLayout, xPos, yPos)
	{
		var center = {x:xPos * tilingLayout.spacing.x,  y:yPos * tilingLayout.spacing.y};

		// 2018.06.08: Added to support centering, 
		// !!! >>> although for hexagons it is the opposite <<< !!!
		if (!tilingLayout.centered)
			center.x += tilingLayout.edgeLen; 

		// 2018.06.07: Added		
		center.x += tilingLayout.offsetX;
		center.y += tilingLayout.offsetY;

		return center;
	}

	var HexgridTesselation_CalcTileBounds = function(tilingLayout, xPos, yPos)
	{
		var center = HexgridTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		var h = tilingLayout.spacing.x/2;
		var v = tilingLayout.spacing.y/2;
		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
	}

	var HexgridTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		var tileInfo = {};
		var pointList = [];
		var clippingPointList = [];

		var center = HexgridTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		
		if (Math.abs(xPos % 2) == 1)
			center.y += tilingLayout.size / 2.0;

		for (var v = 0; v < 6; v++)
		{
			var angle = v * Math.PI / 3.0;
			var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
			var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
			var pt = {x:x, y:y};
			clippingPointList.push(pt); // 2020.08.21: Used for clipToTile
			// 2018.06.08: Add rotation support
			if (tilingLayout.rotation != 0)
				pt = MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation);
			pointList.push(pt);
		}
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.rotCenter	= MathUtil.RotatePointAroundOrigin(center, tilingLayout.rotation); // 2021.03.15: Safer than updating the 'center'
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.clippingPoints = clippingPointList; // 2020.08.21
		
		// 2022.04.08: Return perimeter, area, and 'scale to edge offset' factor
		tileInfo.perimeter = tilingLayout.sizeRadius * 6;
		tileInfo.area      = tilingLayout.sizeRadius * tilingLayout.sizeRadius * 3 * Math.sqrt(3) / 2;// 12 x r x r x cos(60) * 1/2 * sin(60)
		tileInfo.scaleOffsetConversion = tilingLayout.sizeRadius * Math.sqrt(3)/2; // sin(60)

		return tileInfo;
	}

	var HexgridTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		var tileInfo = {};
		var pointList = [];

		var center = {x:0,  y:0};
		
		for (var v = 0; v < 6; v++)
		{
			var angle = v * Math.PI / 3.0;
			var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
			var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
			var pt = {x:x, y:y};
			pointList.push(pt);
		}
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.symmetryPoints = tileInfo.points; // 2020.08.18
		

		var directionCount = tilingLayout.sides * 2;
		tileInfo.snapDirections = [];
		for (var i = 0; i < directionCount; i++)
			tileInfo.snapDirections.push(i * Math.PI/directionCount);

		var directionCount = 2 * tilingLayout.sides;
		tileInfo.axisDirections = [];
		for (var i = 0; i < directionCount; i++)
			tileInfo.axisDirections.push(i * 2 * Math.PI/directionCount);
		
		return tileInfo;
	}
	
	var HexgridTesselation_CreateTileListAround = function(xPos, yPos)
	{
		var tileLocations = [];
		
		tileLocations.push({x:xPos,     y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos});
		tileLocations.push({x:xPos + 1, y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos - 1});
		tileLocations.push({x:xPos    , y:yPos - 1});
		tileLocations.push({x:xPos    , y:yPos + 1});
		
		
		return tileLocations;
	}
	
	//-------------------------------------------------------------------
	//	Adjacent Tile
	//		2021.08.30: Added
	//-------------------------------------------------------------------
	var HexgridTesselation_AdjacentTile = function(tile)
	{
		let adjacentTile = undefined;
		
		let tileIsVertOffset =  (Math.abs(tile.x % 2) == 1);

		if (tile.edge == 0)
			adjacentTile = tileIsVertOffset ? {x:tile.x + 1, y:tile.y + 1, edge:3} : {x:tile.x + 1, y:tile.y,     edge:3};
		else if (tile.edge == 1)
			adjacentTile =                    {x:tile.x,     y:tile.y + 1, edge:4};
		else if (tile.edge == 2)
			adjacentTile = tileIsVertOffset ? {x:tile.x - 1, y:tile.y + 1, edge:5} : {x:tile.x - 1, y:tile.y,     edge:5};
		else if (tile.edge == 3)
			adjacentTile = tileIsVertOffset ? {x:tile.x - 1, y:tile.y    , edge:0} : {x:tile.x - 1, y:tile.y - 1, edge:0};
		else if (tile.edge == 4)
			adjacentTile =                    {x:tile.x,     y:tile.y - 1, edge:1};
		else if (tile.edge == 5)
			adjacentTile = tileIsVertOffset ? {x:tile.x + 1, y:tile.y    , edge:2} : {x:tile.x + 1, y:tile.y - 1, edge:2};

		return adjacentTile;
	}
	
	return {
		CalcLayout:						HexgridTesselation_CalcLayout,
		CalcIteratorBounds:				HexgridTesselation_CalcIteratorBounds,
		CalcTileBounds:					HexgridTesselation_CalcTileBounds,
		CalcTileInfo:					HexgridTesselation_CalcTileInfo,
		CalcUnitTileInfo:				HexgridTesselation_CalcUnitTileInfo,
		CreateTileListAround:			HexgridTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			HexgridTesselation_GetMinimalRepeatBounds,
		AdjacentTile:					HexgridTesselation_AdjacentTile	// 2021.08.30
	};
}());

var MandalaTesselation = (function() {
	var MandalaTesselation_CalcLayout = function(screenData)
	{
		var layout = {};
		
		// The arrangement can be squares or hexagons
		layout.hexArrangement = (screenData.tiling.arrangement == 3); /* Tiling.HEXGRID */
		
		// Common settings (independent of arrangement)
			
		// 2018.06.07: Added
		layout.offsetX = screenData.tiling.offsetX;
		layout.offsetY = screenData.tiling.offsetY;
		layout.rotation = screenData.tiling.rotation;
		
		// Mandala info
		layout.symmetrySides = screenData.tiling.symmetrySides;
		layout.symmetryRotate = screenData.tiling.symmetryRotate;
		layout.symmPointsInside = screenData.tiling.symmPointsInside;

		if (layout.hexArrangement)
		{
			// Size is height
			layout.size = screenData.tiling.size;
		
			layout.edgeLen = layout.size / Math.sqrt(3.0);
		

			// 2018.06.08: Added
			layout.centered = screenData.tiling.centered;

			// Compute radius and edge length
			layout.sizeRadius = layout.edgeLen; // Radius of a circle circumscribing the square
		
			layout.insideRadius = layout.size/2; // 2020.08.18: Inscribed radius 
		
			layout.spacing = {};
			layout.spacing.x = layout.sizeRadius + layout.edgeLen / 2.0; // Center-to-center horizontal distance
			layout.spacing.y = layout.size;
	
		
			layout.sides = 6;
			layout.angle = 2 * Math.PI / layout.sides;
		
		
			// Describe a right triangle that is one-sixth of the equilateral triangle
			layout.subtile = {};
			layout.subtile.height = layout.size/2.0;
			layout.subtile.width  = layout.edgeLen/2.0;
			layout.subtile.angle  = layout.angle/2.0;
		}
		else
		{
			// Size is height
			layout.size = screenData.tiling.size;
			layout.edgeLen = layout.size;

			// 2018.06.08: Added
			layout.centered = screenData.tiling.centered;

			// Compute radius and edge length
			layout.sizeRadius = layout.edgeLen / Math.sqrt(2.0); // Radius of a circle circumscribing the square

			layout.insideRadius = layout.size/2; // 2020.08.18: Inscribed radius 

			layout.spacing = {x: layout.size, y: layout.size};
			layout.sides = 4;
			layout.angle = Math.PI / 2;

			// Describe a right triangle that is one-sixth of the equilateral triangle
			layout.subtile = {};
			layout.subtile.height = layout.size/2.0;
			layout.subtile.width  = layout.edgeLen/2.0;
			layout.subtile.angle  = layout.angle/2.0;
		}

		return layout;
	}

	var MandalaTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};

		minimalRepeatBounds.min.x =  0;
		minimalRepeatBounds.min.y =  0;
		minimalRepeatBounds.max.x =  tilingLayout.edgeLen * 3;
		minimalRepeatBounds.max.y =  tilingLayout.size;
		
		return minimalRepeatBounds;
	}

	
	var MandalaTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}}
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);

		// 2018.06.07: Handle tile offset
		var shiftX = tilingLayout.offsetX;
		var shiftY = tilingLayout.offsetY;

		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX - tilingLayout.spacing.x + 1)/tilingLayout.spacing.x);
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY - tilingLayout.spacing.y + 1)/tilingLayout.spacing.y);
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX + tilingLayout.spacing.x - 1)/tilingLayout.spacing.x);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY + tilingLayout.spacing.y - 1)/tilingLayout.spacing.y);
		
		return iterBounds;
	}
	
	var MandalaTesselation_CalcTileCenter = function(tilingLayout, xPos, yPos)
	{
		var center = {x:xPos * tilingLayout.spacing.x,  y:yPos * tilingLayout.spacing.y};

		if (tilingLayout.hexArrangement)
		{
			// 2018.06.08: Added to support centering, 
			// !!! >>> although for hexagons it is the opposite <<< !!!
			if (!tilingLayout.centered)
				center.x += tilingLayout.edgeLen; 
		}
		else
		{
			if (!tilingLayout.centered)
			{
				center.x += tilingLayout.spacing.x/2; 
				center.y += tilingLayout.spacing.y/2; 
			}
		}

		center.x += tilingLayout.offsetX;
		center.y += tilingLayout.offsetY;

		return center;
	}

	var MandalaTesselation_CalcTileBounds = function(tilingLayout, xPos, yPos)
	{
		var center = MandalaTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		var h = tilingLayout.spacing.x/2;
		var v = tilingLayout.spacing.y/2;
		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
	}

	var MandalaTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		var tileInfo = {};
		var pointList = [];
		var clippingPointList = [];

		var center = MandalaTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		
		if (tilingLayout.hexArrangement)
		{
			if (Math.abs(xPos % 2) == 1)
				center.y += tilingLayout.size / 2.0;

			for (var v = 0; v < tilingLayout.sides; v++)
			{
				var angle = v * 2 * Math.PI / tilingLayout.sides;
				var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
				var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
				var pt = {x:x, y:y};
				clippingPointList.push(pt); // 2020.08.21: Used for clipToTile
				// 2018.06.08: Add rotation support
				if (tilingLayout.rotation != 0)
					pt = MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation);
				pointList.push(pt);
			}
		}
		else
		{
			for (var v = 0; v < 4; v++)
			{
				var angle = v * Math.PI / 2.0 + Math.PI/4.0 ;
				var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
				var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
				var pt = {x:x, y:y};
				clippingPointList.push(pt); // 2020.08.21: Used for clipToTile
				// 2018.06.08: Add rotation support
				if (tilingLayout.rotation != 0)
					pt = MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation);
				pointList.push(pt);
			}
		}
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.rotCenter	= MathUtil.RotatePointAroundOrigin(center, tilingLayout.rotation); // 2021.03.15: Safer than updating the 'center'
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.clippingPoints = clippingPointList;
		
		// Mandala info
		tileInfo.symmetrySides = tilingLayout.symmetrySides;

		return tileInfo;
	}
	
	var MandalaTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		var tileInfo = {};
		var pointList = [];
		var center = {x:0,  y:0};

		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;

		if (tilingLayout.hexArrangement)
		{
			tileInfo.points		= CalcRegularPolygonPoints({center, sides:tilingLayout.sides, radius:tilingLayout.sizeRadius});
			tileInfo.center		= center;
		}
		else
		{	
			/*
			var x = tilingLayout.spacing.x/2;
			var y = tilingLayout.spacing.y/2;
		
			if (tilingLayout.centered)
			{
				x = 0; 
				y = 0;
			}

			x += tilingLayout.offsetX;
			y += tilingLayout.offsetY;
			*/
			
			for (var v = 0; v < 4; v++)
			{
				var angle = v * Math.PI / 2.0 + Math.PI/4.0 ;
				var x = center.x + tilingLayout.sizeRadius * Math.cos(angle);
				var y = center.y + tilingLayout.sizeRadius * Math.sin(angle);
		
				var pt = {x:x, y:y};
				pointList.push(pt);
			}
		
			tileInfo.points		= pointList;
			tileInfo.center		= center;
		}

		var directionCount = tilingLayout.symmetrySides * 2;
		tileInfo.snapDirections = [];
		for (var i = 0; i < directionCount; i++)
			tileInfo.snapDirections.push(i * Math.PI/directionCount);

		var directionCount = 2 * tilingLayout.symmetrySides;
		tileInfo.axisDirections = [];
		for (var i = 0; i < directionCount; i++)
			tileInfo.axisDirections.push(i * 2 * Math.PI/directionCount);

		// Mandala info
		tileInfo.symmetrySides = tilingLayout.symmetrySides;

		// For Mandala, we calc a separate point list for the symmetry, since this
		// can differ from the point list that outlines the tile
		var rotateAmount = 0;
		if (tilingLayout.symmetryRotate)
		{
			if ((tilingLayout.symmetrySides % 4) == 0)
				rotateAmount = Math.PI/tilingLayout.symmetrySides; // half a section; moves an edge to the top (and bottom)
			else 
				rotateAmount = Math.PI/2; // 90 degrees; moves a vertex to the top
		}
		var r = tilingLayout.symmPointsInside ? tilingLayout.insideRadius : tilingLayout.sizeRadius;
		tileInfo.symmetryPoints		= CalcRegularPolygonPoints({center, sides:tilingLayout.symmetrySides, radius:r, rotate:rotateAmount});

		return tileInfo;
	}
	
	var MandalaTesselation_CreateTileListAround = function(xPos, yPos)
	{
		var tileLocations = [];
		
		tileLocations.push({x:xPos,     y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos});
		tileLocations.push({x:xPos + 1, y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos - 1});
		tileLocations.push({x:xPos    , y:yPos - 1});
		tileLocations.push({x:xPos    , y:yPos + 1});
		
		
		return tileLocations;
	}
	
	return {
		CalcLayout:						MandalaTesselation_CalcLayout,
		CalcIteratorBounds:				MandalaTesselation_CalcIteratorBounds,
		CalcTileBounds:					MandalaTesselation_CalcTileBounds,
		CalcTileInfo:					MandalaTesselation_CalcTileInfo,
		CalcUnitTileInfo:				MandalaTesselation_CalcUnitTileInfo,
		CreateTileListAround:			MandalaTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			MandalaTesselation_GetMinimalRepeatBounds
	};
}());


var BrickTesselation = (function() {
	var BrickTesselation_CalcLayout = function(screenData)
	{
		var layout = {};
		
		// Size is width
		// Height is one-half the width
		layout.size = screenData.tiling.size;
		
		layout.edgeLen = layout.size;  // << Is the meaningful for bricks?
		
		layout.centered = screenData.tiling.centered;
		
		// 2018.06.07: Added
		layout.offsetX = screenData.tiling.offsetX;
		layout.offsetY = screenData.tiling.offsetY;
		layout.rotation = screenData.tiling.rotation;
		
		
		layout.spacing = {};
		layout.spacing.x = layout.size; // Center-to-center horizontal distance
		layout.spacing.y = layout.size/2;
	
		layout.sides = 4;
		layout.angle = 2 * Math.PI / layout.sides;
		
		// 2021.04.13: The "min offset" is the space between the tiles (for image designs).
		// Because the height is half the width, we have to double the minOffset
		layout.minOffsetFactor = 2;
		
		return layout;
	}

	var BrickTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};

		minimalRepeatBounds.min.x = 0;
		minimalRepeatBounds.min.y = 0;
		minimalRepeatBounds.max.x = tilingLayout.edgeLen;
		minimalRepeatBounds.max.y = tilingLayout.edgeLen;
		
		return minimalRepeatBounds;
	}

	
	var BrickTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}};
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);

		// 2018.06.07: Fix the problem where some tiles are not included and handle tile offset
		var shiftX = tilingLayout.offsetX - (tilingLayout.centered ? tilingLayout.spacing.x/2 : 0);
		var shiftY = tilingLayout.offsetY - (tilingLayout.centered ? tilingLayout.spacing.y/2 : 0);
		
		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX - tilingLayout.spacing.x + 1)/tilingLayout.spacing.x);
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY - tilingLayout.spacing.y + 1)/tilingLayout.spacing.y);
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX + tilingLayout.spacing.x - 1)/tilingLayout.spacing.x);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY + tilingLayout.spacing.y - 1)/tilingLayout.spacing.y);
		
		return iterBounds;
	}
	
	var BrickTesselation_GetOriginOffset = function(tilingLayout)
	{
		var x = tilingLayout.spacing.x/2;
		var y = tilingLayout.spacing.y/2;
		
		if (tilingLayout.centered)
		{
			x = 0; 
			y = 0;
		}

		// 2018.06.07: Added		
		x += tilingLayout.offsetX;
		y += tilingLayout.offsetY;
		
		return {x:x, y:y};
	}
	
	var BrickTesselation_CalcTileCenter = function(tilingLayout, xPos, yPos)
	{
		var offset = BrickTesselation_GetOriginOffset(tilingLayout);
		var x = xPos * tilingLayout.spacing.x + offset.x;
		var y = yPos * tilingLayout.spacing.y + offset.y;
		
		if (Math.abs(yPos % 2) == 1)
			x += tilingLayout.spacing.x/2;
		
		return {x:x, y:y};
	}
	
	var BrickTesselation_CalcTileBounds= function(tilingLayout, xPos, yPos)
	{
		var center = BrickTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		var h = tilingLayout.spacing.x/2;
		var v = tilingLayout.spacing.y/2;
		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
	}

	var BrickTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		var tileInfo = {};
		var pointList = [];
		var clippingPointList = [];

		var center = BrickTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		let wd = tilingLayout.size/2;
		let hg = tilingLayout.size/4;
		let x = center.x;
		let y = center.y;
		
		clippingPointList.push({x:x + wd, y:y + hg});
		clippingPointList.push({x:x - wd, y:y + hg});
		clippingPointList.push({x:x - wd, y:y - hg});
		clippingPointList.push({x:x + wd, y:y - hg});
			
		clippingPointList.forEach(pt => 
				pointList.push(MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation))
			);
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.rotCenter	= MathUtil.RotatePointAroundOrigin(center, tilingLayout.rotation); // 2021.03.15: Safer than updating the 'center'
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.clippingPoints = clippingPointList; // 2020.08.21
			
		// 2022.04.08: Return perimeter, area, and 'scale to edge offset' factor
		tileInfo.perimeter = tilingLayout.size * 3;
		tileInfo.area      = tilingLayout.size * tilingLayout.size / 2;
		tileInfo.scaleOffsetConversion = tilingLayout.size;

		return tileInfo;
	}

	var BrickTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		var tileInfo = {};
		var pointList = [];

		//var center = {x:0,  y:0};
		var center = BrickTesselation_GetOriginOffset(tilingLayout);

		let wd = tilingLayout.size/2;
		let hg = tilingLayout.size/4;
		let x = center.x;
		let y = center.y;
		
		pointList.push({x:x + wd, y:y + hg});
		pointList.push({x:x - wd, y:y + hg});
		pointList.push({x:x - wd, y:y - hg});
		pointList.push({x:x + wd, y:y - hg});
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.symmetryPoints = tileInfo.points; // 2020.08.18

		var a = Math.PI/4;
		tileInfo.snapDirections = [0.0, a, 2 * a, 3 * a];

		tileInfo.axisDirections = [0.0, a, 2 * a, 3 * a, 4 * a, 5 * a, 6 * a, 7 * a];

		return tileInfo;
	}
	
	var BrickTesselation_CreateTileListAround = function(xPos, yPos)
	{
		var tileLocations = [];
		
		tileLocations.push({x:xPos,     y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos - 1});
		tileLocations.push({x:xPos,     y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos});
		tileLocations.push({x:xPos + 1, y:yPos + 1});
		tileLocations.push({x:xPos,     y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos});
		
		return tileLocations;
	}

	return {
		CalcLayout:						BrickTesselation_CalcLayout,
		CalcIteratorBounds:				BrickTesselation_CalcIteratorBounds,
		CalcTileBounds:					BrickTesselation_CalcTileBounds,
		CalcTileInfo:					BrickTesselation_CalcTileInfo,
		CalcTileCenter:					BrickTesselation_CalcTileCenter,
		CalcUnitTileInfo:				BrickTesselation_CalcUnitTileInfo,
		CreateTileListAround:			BrickTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			BrickTesselation_GetMinimalRepeatBounds
	};
}());

var RhombusTesselation = (function() {
	var RhombusTesselation_CalcLayout = function(screenData)
	{
		var layout = {};
		
		// Size is edge length, which is also the width for the vertical rhombuses
		layout.size = screenData.tiling.size;
		
		layout.edgeLen = layout.size;
		
		layout.centered = screenData.tiling.centered;
		
		// 2018.06.07: Added
		layout.offsetX = screenData.tiling.offsetX;
		layout.offsetY = screenData.tiling.offsetY;
		layout.rotation = screenData.tiling.rotation;
		
		
		layout.spacing = {};
		layout.spacing.x = layout.size; // Center-to-center horizontal distance
		layout.spacing.y = layout.size * Math.sqrt(3)/2;
	
		layout.sides = 4;
		layout.angle = 2 * Math.PI / layout.sides;
		
		// 2021.04.09: The "min offset" is the space between the tiles (for image designs).
		layout.minOffsetFactor = 2 / Math.sqrt(3);
		
		return layout;
	}

	var RhombusTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};

		minimalRepeatBounds.min.x = 0;
		minimalRepeatBounds.min.y = 0;
		minimalRepeatBounds.max.x = tilingLayout.edgeLen;
		minimalRepeatBounds.max.y = tilingLayout.edgeLen;
		
		return minimalRepeatBounds;
	}

	
	var RhombusTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}};
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);

		// 2018.06.07: Fix the problem where some tiles are not included and handle tile offset
		var shiftX = tilingLayout.offsetX - (tilingLayout.centered ? tilingLayout.spacing.x/2 : 0);
		var shiftY = tilingLayout.offsetY - (tilingLayout.centered ? tilingLayout.spacing.y/2 : 0);
		
		// 2021.04.09: Subtract 2 from the min values because some tiles where missing
		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX - tilingLayout.spacing.x + 1)/tilingLayout.spacing.x) - 2;
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY - tilingLayout.spacing.y + 1)/tilingLayout.spacing.y) - 2;
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX + tilingLayout.spacing.x - 1)/tilingLayout.spacing.x);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY + tilingLayout.spacing.y - 1)/tilingLayout.spacing.y);
		
		return iterBounds;
	}
	
	var RhombusTesselation_GetOriginOffset = function(tilingLayout)
	{
		var x = tilingLayout.spacing.x/2;
		var y = tilingLayout.spacing.y/2;
		
		if (tilingLayout.centered)
		{
			x = 0; 
			y = 0;
		}

		// 2018.06.07: Added		
		x += tilingLayout.offsetX;
		y += tilingLayout.offsetY;
		
		return {x:x, y:y};
	}
	
	var RhombusTesselation_CalcTileCenter = function(tilingLayout, xPos, yPos)
	{
		let pointsAndCenter = RhombusTesselation_CalcTilePoints(tilingLayout, xPos, yPos);

// 		var offset = RhombusTesselation_GetOriginOffset(tilingLayout);
// 		var orientation = RhombusTesselation_CalcOrientation(tilingLayout, xPos, yPos);
// 		
// 		var pos
// 		var x = xPos * tilingLayout.spacing.x + offset.x;
// 		var y = yPos * tilingLayout.spacing.y + offset.y;
// 		
// 		var angle = Math.PI/6 + Math.PI/3 * orientation;
		
// 		if (Math.abs(yPos % 2) == 1)
// 			x += tilingLayout.spacing.x/2;
// 		
		return pointsAndCenter.center;
	}
	
	var RhombusTesselation_CalcTileBounds= function(tilingLayout, xPos, yPos)
	{
		let pointsAndCenter = RhombusTesselation_CalcTilePoints(tilingLayout, xPos, yPos);
// 		var center = RhombusTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
// 		var h = tilingLayout.spacing.x/2;
// 		var v = tilingLayout.spacing.y/2;
//		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
		return MathUtil.FindPolygonBounds(pointsAndCenter.points);
	}

	var RhombusTesselation_CalcOrientation = function(tilingLayout, xPos, yPos)
	{
		let even = ((yPos & 2) == 0);
		let column = (xPos % 3);
		var orientation;
		
		if (even)
			orientation = column;
		else
			orientation = column + 3;
			
		return orientation;
	}
	
	var RhombusTesselation_CalcTilePoints = function(tilingLayout, xPos, yPos)
	{
		let wd = tilingLayout.size/2;
		let hg = tilingLayout.size * Math.sqrt(3)/2;
		let oddRow = ((yPos % 2) != 0);
		let column = (xPos >= 0) ? (xPos % 3) : (2 - ((Math.abs(xPos)-1) % 3)); // 2021.09.23: Subtracted 1 if xPos < 0
		let xRel = Math.floor(xPos/3) * 3;
		var x = xRel * tilingLayout.spacing.x + (oddRow ? tilingLayout.spacing.x*3/2 : 0)// + offset.x;
		var y = yPos * tilingLayout.spacing.y;// + offset.y;
		let points = [];
		var orientation = Math.PI/6 * 5 - column * Math.PI/3; // (150, 90, 30 degrees)
		
		let angle = orientation; 
		
		// Compute the center
		x += hg * Math.cos(orientation);
		y += hg * Math.sin(orientation);
		
		// Compute the points
		points.push({x:x + hg * Math.cos(angle),               y:y + hg * Math.sin(angle)               });
		points.push({x:x + wd * Math.cos(angle + Math.PI/2),   y:y + wd * Math.sin(angle + Math.PI/2)   });
		points.push({x:x + hg * Math.cos(angle + Math.PI),     y:y + hg * Math.sin(angle + Math.PI)     });
		points.push({x:x + wd * Math.cos(angle + Math.PI*3/2), y:y + wd * Math.sin(angle + Math.PI*3/2) });
		
		return {points, center:{x:x, y:y}};
	}
	
	var RhombusTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		var tileInfo = {};
		var pointList = [];
		var clippingPointList = [];

		//var center = RhombusTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		//var orientation = RhombusTesselation_CalcOrientation(tilingLayout, xPos, yPos);
		
		let pointsAndCenter = RhombusTesselation_CalcTilePoints(tilingLayout, xPos, yPos);
		clippingPointList = pointsAndCenter.points;
			
		clippingPointList.forEach(pt => 
				pointList.push(MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation))
			);
		
		tileInfo.points		= pointList;
		tileInfo.center		= pointsAndCenter.center;
		tileInfo.rotCenter	= MathUtil.RotatePointAroundOrigin(pointsAndCenter.center, tilingLayout.rotation);
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides;
		tileInfo.clippingPoints = clippingPointList;
	
		// 2022.04.08: Return perimeter, area, and 'scale to edge offset' factor
		let wd = tilingLayout.size/2;
		let hg = tilingLayout.size * Math.sqrt(3)/2;
		tileInfo.perimeter = 4 * Math.sqrt(wd * wd + hg * hg);
		tileInfo.area      = 2 * wd * hg;
		tileInfo.scaleOffsetConversion = tilingLayout.size;

		return tileInfo;
	}

	var RhombusTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		var tileInfo = {};
		//var pointList = [];

		//var center = {x:0,  y:0};
		//var center = RhombusTesselation_GetOriginOffset(tilingLayout);
		//var orientation = RhombusTesselation_CalcOrientation(tilingLayout, xPos, yPos);
		let xPos = 0;
		let yPos = 0;
		let pointsAndCenter = RhombusTesselation_CalcTilePoints(tilingLayout, xPos, yPos);
		//pointList = pointsAndCenter.points;

		
		tileInfo.points		= pointsAndCenter.points;
		tileInfo.center		= pointsAndCenter.center;
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides;
		tileInfo.symmetryPoints = tileInfo.points;

		var a = Math.PI/4;
		tileInfo.snapDirections = [0.0, a, 2 * a, 3 * a];

		tileInfo.axisDirections = [0.0, a, 2 * a, 3 * a, 4 * a, 5 * a, 6 * a, 7 * a];

		return tileInfo;
	}
	
	var RhombusTesselation_CreateTileListAround = function(xPos, yPos)
	{
		var tileLocations = [];
		
		tileLocations.push({x:xPos,     y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos - 1});
		tileLocations.push({x:xPos,     y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos});
		tileLocations.push({x:xPos + 1, y:yPos + 1});
		tileLocations.push({x:xPos,     y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos});
		
		return tileLocations;
	}

	//-------------------------------------------------------------------
	//	AdjacentTile
	//		2021.09.23: Added
	//-------------------------------------------------------------------
	var RhombusTesselation_AdjacentTile = function(tile)
	{
		let adjacentTile = undefined;

		let xPos = tile.x;
		let yPos = tile.y;
		// These two lines must match the ones above in _CalcTilePoints
		let oddRow = ((yPos % 2) != 0);
		let column = (xPos >= 0) ? (xPos % 3) : (2 - ((Math.abs(xPos)-1) % 3)); // 2021.09.23: Subtracted 1 if xPos < 0

		let x = tile.x;
		let y = tile.y;

		if (column == 0)
		{
			if (tile.edge == 0)
				adjacentTile = oddRow?{x:x+1, y:y-1, edge:3}:{x:x-2, y:y-1, edge:3};
			else if (tile.edge == 1)
				adjacentTile = oddRow?{x:x+2, y:y-1, edge:0}:{x:x-1, y:y-1, edge:0};
			else if (tile.edge == 2)
				adjacentTile = {x:x+1, y:y, edge:1};
			else if (tile.edge == 3)
				adjacentTile = oddRow?{x:x+2, y:y+1, edge:2}:{x:x-1, y:y+1, edge:2};
		}
		else if (column == 1)
		{
			if (tile.edge == 0)
				adjacentTile = oddRow?{x:x+1, y:y+1, edge:3}:{x:x-2, y:y+1, edge:3};
			else if (tile.edge == 1)
				adjacentTile = {x:x-1, y:y, edge:2};
			else if (tile.edge == 2)
				adjacentTile = {x:x+1, y:y, edge:1};
			else if (tile.edge == 3)
				adjacentTile = oddRow?{x:x+2, y:y+1, edge:0}:{x:x-1, y:y+1, edge:0};
		}
		else // column == 2
		{
			if (tile.edge == 0)
				adjacentTile = oddRow?{x:x+1, y:y+1, edge:1}:{x:x-2, y:y+1, edge:1};
			else if (tile.edge == 1)
				adjacentTile = {x:x-1, y:y, edge:2};
			else if (tile.edge == 2)
				adjacentTile = oddRow?{x:x+1, y:y-1, edge:3}:{x:x-2, y:y-1, edge:3};
			else if (tile.edge == 3)
				adjacentTile = oddRow?{x:x+2, y:y-1, edge:0}:{x:x-1, y:y-1, edge:0};
		}

		//if (xPos <= 0/* || yPos < 0*/)
		//{
		//	console.log("c:" + column + " e:" + tile.edge + " r:" + (oddRow?"O":"E") + " " + tile.x + "," + tile.y);
		//	console.log(JSON.stringify(tile), JSON.stringify(adjacentTile));
		//}

		return adjacentTile;
	}

	return {
		CalcLayout:						RhombusTesselation_CalcLayout,
		CalcIteratorBounds:				RhombusTesselation_CalcIteratorBounds,
		CalcTileBounds:					RhombusTesselation_CalcTileBounds,
		CalcTileInfo:					RhombusTesselation_CalcTileInfo,
		CalcTileCenter:					RhombusTesselation_CalcTileCenter,
		CalcUnitTileInfo:				RhombusTesselation_CalcUnitTileInfo,
		CreateTileListAround:			RhombusTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			RhombusTesselation_GetMinimalRepeatBounds,
		AdjacentTile:					RhombusTesselation_AdjacentTile	// 2021.09.23
	};
}());


var RectangleTesselation = (function() {
	var RectangleTesselation_CalcLayout = function(screenData)
	{
		var layout = {};
		
		const isSlot = (screenData.tiling.shape == 107 /*Tiling.SLOTS*/);
		const width  = (isSlot ? screenData.tiling.sizeB : screenData.tiling.size );
		const height = (isSlot ? screenData.tiling.size  : screenData.tiling.sizeB);
		
		// Size is width
		// Height is one-half the width
		layout.size = width;
		layout.sizeB = height;
		layout.adjacencyShift = screenData.tiling.adjacencyShift;
		
		layout.edgeLen = layout.size;  // << Is the meaningful for bricks?
		
		layout.centered = screenData.tiling.centered;
		
		// 2018.06.07: Added
		layout.offsetX = screenData.tiling.offsetX;
		layout.offsetY = screenData.tiling.offsetY;
		layout.rotation = screenData.tiling.rotation;
		
		
		layout.spacing = {};
		layout.spacing.x = layout.size; // Center-to-center horizontal distance
		layout.spacing.y = layout.sizeB;
	
		layout.sides = 2;
		layout.angle = 2 * Math.PI / layout.sides;
		
		// 2021.04.13: The "min offset" is the space between the tiles (for image designs).
		// Because the height is half the width, we have to double the minOffset
		// 2022.10.24: TODO: Adjust for this rectangle tesselation
		layout.minOffsetFactor = 2;
		
		return layout;
	}

	var RectangleTesselation_GetMinimalRepeatBounds = function(tilingLayout)
	{
		var minimalRepeatBounds = {min:{}, max:{}};

		minimalRepeatBounds.min.x = 0;
		minimalRepeatBounds.min.y = 0;
		minimalRepeatBounds.max.x = tilingLayout.size;
		minimalRepeatBounds.max.y = tilingLayout.sizeB;
		
		return minimalRepeatBounds;
	}

	
	var RectangleTesselation_CalcIteratorBounds = function(tilingLayout, frameBounds)
	{
		var iterBounds = {min:{x:0, y:0}, max:{x:0, y:0}};
		
		// 2018.12.08: Handle tile rotation
		if (tilingLayout.rotation != 0)
			frameBounds = MathUtil.CalcRotatedFrameBounds(frameBounds, -tilingLayout.rotation);

		// 2018.06.07: Fix the problem where some tiles are not included and handle tile offset
		var shiftX = tilingLayout.offsetX - (tilingLayout.centered ? tilingLayout.spacing.x/2 : 0);
		var shiftY = tilingLayout.offsetY - (tilingLayout.centered ? tilingLayout.spacing.y/2 : 0);
		
		iterBounds.min.x = Math.floor((frameBounds.min.x - shiftX - tilingLayout.spacing.x + 1)/tilingLayout.spacing.x);
		iterBounds.min.y = Math.floor((frameBounds.min.y - shiftY - tilingLayout.spacing.y + 1)/tilingLayout.spacing.y);
		iterBounds.max.x = Math.ceil ((frameBounds.max.x - shiftX + tilingLayout.spacing.x - 1)/tilingLayout.spacing.x);
		iterBounds.max.y = Math.ceil ((frameBounds.max.y - shiftY + tilingLayout.spacing.y - 1)/tilingLayout.spacing.y);
		
		return iterBounds;
	}
	
	var RectangleTesselation_GetOriginOffset = function(tilingLayout)
	{
		var x = tilingLayout.spacing.x/2;
		var y = tilingLayout.spacing.y/2;
		
		if (tilingLayout.centered)
		{
			x = 0; 
			y = 0;
		}

		// 2018.06.07: Added		
		x += tilingLayout.offsetX;
		y += tilingLayout.offsetY;
		
		return {x:x, y:y};
	}
	
	var RectangleTesselation_CalcTileCenter = function(tilingLayout, xPos, yPos)
	{
		var offset = RectangleTesselation_GetOriginOffset(tilingLayout);
		var x = xPos * tilingLayout.spacing.x + offset.x;
		var y = yPos * tilingLayout.spacing.y + offset.y;
		
		//if (Math.abs(yPos % 2) == 1)
		//	x += tilingLayout.spacing.x/2;
		
		return {x:x, y:y};
	}
	
	var RectangleTesselation_CalcTileBounds= function(tilingLayout, xPos, yPos)
	{
		var center = RectangleTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		var h = tilingLayout.spacing.x/2;
		var v = tilingLayout.spacing.y/2;
		return {min:{x:center.x - h, y:center.y - v}, max:{x:center.x + h, y:center.y + v}};
	}

	var RectangleTesselation_CalcTileInfo = function(tilingLayout, xPos, yPos)
	{
		var tileInfo = {};
		var pointList = [];
		var clippingPointList = [];

		var center = RectangleTesselation_CalcTileCenter(tilingLayout, xPos, yPos);
		let wd = tilingLayout.size/2;
		let hg = tilingLayout.sizeB/2;
		let x = center.x;
		let y = center.y;
		
		// 2022.11.02: The ScalePolygonForSlots function in ScreenDesignGenerator.js depends on
		// these point being in this order: topRight, topLeft, bottomLeft, bottomRight
		clippingPointList.push({x:x + wd, y:y + hg});
		clippingPointList.push({x:x - wd, y:y + hg});
		clippingPointList.push({x:x - wd, y:y - hg});
		clippingPointList.push({x:x + wd, y:y - hg});
			
		clippingPointList.forEach(pt => 
				pointList.push(MathUtil.RotatePointAroundOrigin(pt, tilingLayout.rotation))
			);
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.rotCenter	= MathUtil.RotatePointAroundOrigin(center, tilingLayout.rotation); // 2021.03.15: Safer than updating the 'center'
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.clippingPoints = clippingPointList; // 2020.08.21
			
		// 2022.04.08: Return perimeter, area, and 'scale to edge offset' factor
		tileInfo.perimeter = tilingLayout.size * 3;
		tileInfo.area      = tilingLayout.size * tilingLayout.size / 2;
		tileInfo.scaleOffsetConversion = tilingLayout.size;
		
		// 2022.10.31: Add info describing how to do a linear scale
		// xxxx

		return tileInfo;
	}

	var RectangleTesselation_CalcUnitTileInfo = function(tilingLayout)
	{
		var tileInfo = {};
		var pointList = [];

		//var center = {x:0,  y:0};
		var center = RectangleTesselation_GetOriginOffset(tilingLayout);

		let wd = tilingLayout.size/2;
		let hg = tilingLayout.sizeB/2;
		let x = center.x;
		let y = center.y;
		
		pointList.push({x:x + wd, y:y + hg});
		pointList.push({x:x - wd, y:y + hg});
		pointList.push({x:x - wd, y:y - hg});
		pointList.push({x:x + wd, y:y - hg});
		
		tileInfo.points		= pointList;
		tileInfo.center		= center;
		tileInfo.startAngle	= 0;
		tileInfo.sides		= tilingLayout.sides;
		tileInfo.symmetrySides = tileInfo.sides; // 2020.08.17: Added
		tileInfo.symmetryPoints = tileInfo.points; // 2020.08.18

		var a = Math.PI/4;
		tileInfo.snapDirections = [];

		tileInfo.axisDirections = [];

		return tileInfo;
	}
	
	var RectangleTesselation_CreateTileListAround = function(xPos, yPos)
	{
		var tileLocations = [];
		
		tileLocations.push({x:xPos,     y:yPos});
		tileLocations.push({x:xPos - 1, y:yPos - 1});
		tileLocations.push({x:xPos,     y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos - 1});
		tileLocations.push({x:xPos + 1, y:yPos});
		tileLocations.push({x:xPos + 1, y:yPos + 1});
		tileLocations.push({x:xPos,     y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos + 1});
		tileLocations.push({x:xPos - 1, y:yPos});
		
		return tileLocations;
	}

	return {
		CalcLayout:						RectangleTesselation_CalcLayout,
		CalcIteratorBounds:				RectangleTesselation_CalcIteratorBounds,
		CalcTileBounds:					RectangleTesselation_CalcTileBounds,
		CalcTileInfo:					RectangleTesselation_CalcTileInfo,
		CalcTileCenter:					RectangleTesselation_CalcTileCenter,
		CalcUnitTileInfo:				RectangleTesselation_CalcUnitTileInfo,
		CreateTileListAround:			RectangleTesselation_CreateTileListAround,
		GetMinimalRepeatBounds:			RectangleTesselation_GetMinimalRepeatBounds
	};
}());

export { 
	SquareTesselation, 
	SquareOffsetTesselation, 
	TrigridTesselation, 
	HexgridTesselation, 
	MandalaTesselation, 
	BrickTesselation, 
	RhombusTesselation,
	RectangleTesselation
};

