//==========================================================================================
//
//	Amplify Account Manager
//
//		This module manages the account status in a NoSQL data. 
//------------------------------------------------------------------------------------------
//
//	Database Schema
//		userId (Key)
//		userName
//		email
//		createdTime
//		lastLogin
//		lastRelaunch
//
//	Configuration
//		acctTableName:	name of table
//		defaultAttrMap:	map of default attributes
//==========================================================================================

import Auth from '@aws-amplify/auth';

// 2019.04.10: Import only dynamodb to reduce bundle size
//var AWS = require('aws-sdk');
var dynamoDB = require('aws-sdk/clients/dynamodb');
var AWS = require('aws-sdk/global');

var AccountManager = (function() {

	// Account database table name
	var acctTableName = undefined;
	
	// Database client object; used for making all db calls
	var acctDocClient = undefined;
	
	// Loaded account record (item)
	var acctItemInfo = undefined;
	
	// Times of the most recent login and the most recent relaunch
	var acctItemLastLogin = undefined;
	var acctItemLastRelaunch = undefined;
	
	// Periodic timer to refresh the user credentials
	var acctRefreshCredentialsTimer = undefined;

	// Periodic timer to monitor for 'wake from sleep'. Needed to
	// refresh credentials immediately
	var acctWakeFromSleep = { timer: undefined, timestamp: undefined, interval: 3000 };
	
	var acctItemBaseSchema = Object.freeze([
			{ key: "userId"			},
			{ key: "userName"		},
			{ key: "email"			},
			{ key: "createdTime"	},
			{ key: "lastLogin"		},
			{ key: "lastRelaunch"	},
			{ key: "authenticatedBy",	optional: true	}
		]);
		
	var acctSchemaAdditions = undefined;

	var acctItemSchema = undefined;
	
	//------------------------------------------------------------------------------
	//	Init
	//		Stores the table name
	//------------------------------------------------------------------------------
	var AccountManager_Init = function(accountMgrConfig)
	{
		acctTableName = accountMgrConfig.acctTableName;

		acctSchemaAdditions = accountMgrConfig.schemaAdditions;
		
		// Make a copy of the base schema
		acctItemSchema = JSON.parse(JSON.stringify(acctItemBaseSchema));
		
		// Append the schema additions, if provided
		if (acctSchemaAdditions != undefined)
			acctItemSchema = acctItemSchema.concat(acctSchemaAdditions);

		// setInterval(AccountManager_PingTest, 60 * 1000);
	}
	
	//------------------------------------------------------------------------------
	//	Get Account Schema
	//		Returns a copy of the account schema (to be used by dashboard)
	//------------------------------------------------------------------------------
	var AccountManager_GetAccountSchema = function()
	{
		return JSON.parse(JSON.stringify(acctItemSchema));
	}
	
	//------------------------------------------------------------------------------
	//	SignedIn
	//		Start a task to keep the client credentials refreshed
	//		Note that 'UpdateLastLogin' and 'UpdateLastRelaunch' will also be
	//		called as needed.
	//------------------------------------------------------------------------------
	var AccountManager_SignedIn = function()
	{
		// Periodically refresh the document client credentials
		acctRefreshCredentialsTimer = setInterval(AccountManager_DocClientRefresh, 13 * 60 * 1000);
		
		AccountManager_MonitorForWakeFromSleep();
	}
	
	//------------------------------------------------------------------------------
	//	Reset
	//		Clear all of the user data
	//------------------------------------------------------------------------------
	var AccountManager_Reset = function()
	{
		acctDocClient = undefined;
		acctItemInfo = undefined;
		acctItemLastLogin = undefined;
		
		if (acctRefreshCredentialsTimer != undefined)
		{
			clearInterval(acctRefreshCredentialsTimer);
			acctRefreshCredentialsTimer = undefined;
		}

		if (acctWakeFromSleep.timer != undefined)
		{
			clearInterval(acctWakeFromSleep.timer);
			acctWakeFromSleep.timer = undefined;
		}
	}
	
	//------------------------------------------------------------------------------
	//	Monitor For Wake From Sleep
	//		Start a task to monitor for waking from sleep. If this happens
	//		we need to refresh the credentials quickly, so new  work is not lost.
	//------------------------------------------------------------------------------
	var AccountManager_MonitorForWakeFromSleep = function()
	{
		// Periodically refresh the document client credentials
		acctWakeFromSleep.timestamp = (new Date()).getTime();
		acctWakeFromSleep.timer = setInterval(() => 
			{
				var now = (new Date()).getTime();
				if (now - acctWakeFromSleep.timestamp > acctWakeFromSleep.interval * 2)
					AccountManager_WokenFromSleep();
				acctWakeFromSleep.timestamp = now;
			}, 
			acctWakeFromSleep.interval);
	}
	
	//------------------------------------------------------------------------------
	//	Woken From Sleep
	//		Called after a long delay is detected, typically from waking from sleep.
	//------------------------------------------------------------------------------
	var AccountManager_WokenFromSleep = function()
	{
		AccountManager_DocClientRefresh();
	}
	
	//------------------------------------------------------------------------------
	//	PingTest
	//		Loads the record from the db
	//------------------------------------------------------------------------------
	/*
	var AccountManager_PingTest = function()
	{
		AccountManager_GetKeyParams()
		.then(getParams =>
		{	
			acctDocClient = AccountManager_GetDocClient();
	
			return new Promise((resolve, reject) =>
				{
					acctDocClient.get(getParams, (error, result) => { (error) ? reject(error) : resolve(result); });
				});
		})
		.then(data =>
		{
			acctItemInfo = data.Item;
			
			if (acctItemInfo == undefined)
			{
				console.log("AccountManager_PingTest: account info is undefined");
			}
			else
			{
				console.log((new Date()).toLocaleTimeString(), " ping test success");
			}
		})
		.catch(err => 
		{
			console.error("AccountManager_PingTest err: " + err);
		});
	}
	*/
	
	//------------------------------------------------------------------------------
	//	Get Doc Client
	//		Return a database client handle
	//------------------------------------------------------------------------------
	var AccountManager_GetDocClient = function()
	{
		if (acctDocClient == undefined)
			acctDocClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2012-08-10'});
			
		return acctDocClient;
	}
		
	//------------------------------------------------------------------------------
	//	Doc Client Refresh
	//		The credentials in the DynamoDB DocumentClient object expire and are not
	//		refreshed automatically in the same way as the credentials in the Amplify
	//		objects. This function is called via setInterval to periodically create a 
	//		DocumentClient object with refreshed credentials.
	//------------------------------------------------------------------------------
	var AccountManager_DocClientRefresh = function()
	{
		Auth.currentUserCredentials()
		.then(credentials => 
		{
			acctDocClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2012-08-10', credentials:credentials});
		})
		.catch(err =>
		{
			console.log("AccountManager_DocClientRefresh error: ", err);
		});
			
	}
	

	//------------------------------------------------------------------------------
	//	Create Account
	//		Create a record in the database
	//------------------------------------------------------------------------------
	var AccountManager_CreateAccount = function(subscriptionLevel = undefined)
	{
		Auth.currentUserInfo()
		.then(currentUser =>
			{	
				//console.log("AccountManager_CreateAccount");
				//console.log(currentUser);
				var item = {};
				item.userId = currentUser.id;
				item.userName = currentUser.username;
				item.email = currentUser.attributes.email;
				item.createdTime = new Date().getTime();
				item.lastLogin = 0;
				item.lastRelaunch = 0;
				item.authenticatedBy = currentUser.authenticatedBy;
				
				if (acctSchemaAdditions != undefined)
					for (var i = 0; i < acctSchemaAdditions.length; i++)
						item[acctSchemaAdditions[i].key] = acctSchemaAdditions[i].value; 
				
				if (subscriptionLevel != undefined)
					item.subscriptionLevel = subscriptionLevel;
				
				//console.log("AccountManager_CreateAccount: " + JSON.stringify(item, null, 2));
				
				return new Promise(function (resolve, reject) {
					let putParams = {
						TableName: acctTableName,
						Item: item,
						ConditionExpression: 'attribute_not_exists(userId)'
					};
					
					acctDocClient.put(putParams, (error, result) => { (error) ? reject(error) : resolve(result); });
				});
			})
		.catch(err => 
			{
				console.log("AccountManager_CreateAccount err: " + err);
			});
	}

	//------------------------------------------------------------------------------
	//	Delete Account
	//		Returns a promise 
	//------------------------------------------------------------------------------
	var AccountManager_DeleteAccount = function()
	{
		//console.log("AccountManager_DeleteAccount");
		var deleteAccountPromise = new Promise((resolve, reject) =>
		{
			resolve("AccountManager_DeleteAccount: No action needed");
		});
				
		return deleteAccountPromise;
	}

	//------------------------------------------------------------------------------
	//	Get Key Params
	//		Get an object that we can use to find the account in the db
	//------------------------------------------------------------------------------
	var AccountManager_GetKeyParams = function()
	{
		// Return, via a Promise, an object with the table name and key populated
		return new Promise((resolve, reject) => 
			{
				Auth.currentUserInfo()
				.then(currentUser =>
				{	
					if (currentUser == undefined)
					{
						reject(new Error("AccountManager_GetKeyParams: Auth.currentUserInfo returned undefined"));
					}
					else if (currentUser.id == undefined)
					{
						reject(new Error("AccountManager_GetKeyParams: Auth.currentUserInfo returned currentUser with undefined id"));
					}
					else
					{
						var keyParams = {
							TableName: acctTableName,
							Key: {userId: currentUser.id}
						};
				
						resolve(keyParams);
					}
				})
				.catch(err => 
				{
					reject(err);
				});
			});
	}
	
	//------------------------------------------------------------------------------
	//	Get Current User (promise)
	//		Return a promise that resolves to an object with the userId, userName, and email
	//------------------------------------------------------------------------------
	var AccountManager_GetCurrentUser = function()
	{
		// Return, via a Promise, an object with the table name and key populated
		return new Promise((resolve, reject) => 
			{
				Auth.currentUserInfo()
				.then(currentUser => resolve( { userId: currentUser.id, userName: currentUser.username, email: currentUser.attributes.email }) )
				.catch(err =>  reject(err) );
			});
	}
	
	//------------------------------------------------------------------------------
	//	Load Account (promise)
	//		Resolves with a copy of the user account item
	//------------------------------------------------------------------------------
	var AccountManager_LoadAccount = function()
	{
		return new Promise((resolve, reject) =>
			{
				AccountManager_GetKeyParams()
				.then(getParams =>
					{	
						acctDocClient = AccountManager_GetDocClient();
				
						return new Promise((resolve, reject) =>
							{
								acctDocClient.get(getParams, (error, result) => { (error) ? reject(error) : resolve(result); });
							});
					})
				.then(data =>
					{
						acctItemInfo = data.Item;
						

						if (acctItemInfo == undefined)
						{
							console.log("AccountManager_LoadAccount: account info is undefined");
							resolve(undefined);
						}
						else
						{
							//AccountManager_LogAcctInfo(acctItemInfo);
							resolve(JSON.parse(JSON.stringify(acctItemInfo)));
						}
					})
				.catch(err => 
					{
						console.error("AccountManager_LoadAccount err: " + err);
						reject(err);
					});
			});
	}
	
	//------------------------------------------------------------------------------
	//	Log Account Info
	//		
	//------------------------------------------------------------------------------
	var AccountManager_LogAcctInfo = function(acctItemInfo)
	{
		//console.log("AccountManager_LogAcctInfo [then]: " + JSON.stringify(acctItemInfo, null, 2));
		var attrReview = [];
		for (var i = 0; i < acctItemSchema.length; i++)
		{
			var k = acctItemSchema[i].key;
			var review = {key:k};
			if (acctItemInfo[k] != undefined)
				review.value  = acctItemInfo[k];
			review.status = (acctItemInfo[k] != undefined) ? "ok" : "MISSING";
			if (acctItemSchema[i].optional)
				review.status += " (optional)";
			attrReview.push(review);
		}

		// Look for any properties not listed in the schema
		for (var property in acctItemInfo)
		{
			if (acctItemInfo.hasOwnProperty(property))
			{
				var e = acctItemSchema.find(function(e) { return (e.key == this.property) }, {property:property});
				if (e == undefined)
					attrReview.push({key:property, value:acctItemInfo[property], status:"UNEXPECTED"});
			}
		}
			
		//console.log("AccountManager_LogAcctInfo acctItemInfo");
		console.table(attrReview);
	}
	
	//------------------------------------------------------------------------------
	//	Update Last Login
	//
	//------------------------------------------------------------------------------
	var AccountManager_UpdateLastLogin = function()
	{
		AccountManager_GetKeyParams()
		.then(updateParams =>
			{	
				acctDocClient = AccountManager_GetDocClient();
				
				updateParams.UpdateExpression = 'set #a = :t',
				updateParams.ExpressionAttributeNames = {'#a' : 'lastLogin'},
				updateParams.ExpressionAttributeValues = {':t' : new Date().getTime() };
				updateParams.ReturnValues = "UPDATED_OLD";
				updateParams.ConditionExpression = 'attribute_exists(userId)';

				return new Promise((resolve, reject) =>
					{
						acctDocClient.update(updateParams, (error, result) => { (error) ? reject(error) : resolve(result); });
					});
			})
		.then(data =>
			{
				acctItemLastLogin = (data.Attributes != undefined) ? data.Attributes.lastLogin : 0;
				//console.log("AccountManager_UpdateLastLogin [then]: " + JSON.stringify(data, null, 2) );
			})
		.catch(err => 
			{
				if (err.name === "ConditionalCheckFailedException")
					AccountManager_CreateAccount();
				else
					console.log("AccountManager_UpdateLastLogin error: " + err);
			});
		
	}
	
	//------------------------------------------------------------------------------
	//	Update Last Relaunch
	//
	//	*	Update the 'lastRelaunch' attribute with the current time and 
	//		retrieve the old value. 
	//	*	If there is no previous record, then call CreateAccount.
	//	*	Load (get) the 'lastLogin' attribute
	//------------------------------------------------------------------------------
	var AccountManager_UpdateLastRelaunch = function()
	{
		AccountManager_GetKeyParams()
		.then(updateParams =>
			{	
				acctDocClient = AccountManager_GetDocClient();
				
				// Update the "lastRelaunch" attribute
				updateParams.UpdateExpression = 'set #a = :t',
				updateParams.ExpressionAttributeNames = {'#a' : 'lastRelaunch'}; 
				updateParams.ExpressionAttributeValues = {':t' : new Date().getTime() };
				updateParams.ReturnValues = "UPDATED_OLD";
				updateParams.ConditionExpression = 'attribute_exists(userId)';

				return new Promise((resolve, reject) =>
					{
						acctDocClient.update(updateParams, (error, result) => { (error) ? reject(error) : resolve(result); });
					});
			})
		.then(lastRelaunchData =>
			{
				//console.log("AccountManager_UpdateLastRelaunch [then] lastRelaunchData: " + JSON.stringify(lastRelaunchData, null, 2) );
				acctItemLastRelaunch = (lastRelaunchData.Attributes != undefined) ? lastRelaunchData.Attributes.lastRelaunch : 0;
				
				return AccountManager_GetKeyParams();
			})
		.then(getParams =>
			{	
				acctDocClient = AccountManager_GetDocClient();
				
				// Retrieve only the "lastLogin" attribute
				getParams.ProjectionExpression = "lastLogin";
				
				return new Promise((resolve, reject) => 
					{
						acctDocClient.get(getParams, (error, result) => { (error) ? reject(error) : resolve(result); });
					});
			})
		.then(lastLoginData =>
			{
				//console.log("AccountManager_UpdateLastRelaunch [2nd then] lastLoginData : " + JSON.stringify(lastLoginData, null, 2) );
				acctItemLastLogin = (lastLoginData.Item != undefined) ? lastLoginData.Item.lastLogin : 0;
			})
		.catch(err => 
			{
				if (err.name === "ConditionalCheckFailedException")
					AccountManager_CreateAccount();
				else
					console.log("AccountManager_UpdateLastRelaunch error: " + err);
			});
		
	}
	
	//------------------------------------------------------------------------------
	//	Get Last Login
	//
	//------------------------------------------------------------------------------
	var AccountManager_GetLastLogin = function()
	{
		return (acctItemLastLogin != undefined) ? acctItemLastLogin : 0;
	}

	//------------------------------------------------------------------------------
	//	Update Attributes (returns promise)
	//
	//------------------------------------------------------------------------------
	var AccountManager_UpdateAttributes = function(attributeParams)
	{
		console.log("AccountManager_UpdateAttributes: ", JSON.stringify(attributeParams, null, 2));
		return new Promise((resolve, reject) => 
		{
			AccountManager_GetKeyParams()
			.then(updateParams =>
				{	
					acctDocClient = AccountManager_GetDocClient();
				
					var ue = "set";
					var an = {};
					var av = {};
					var idx = 0;
	
					for (var property in attributeParams)
					{
						if (attributeParams.hasOwnProperty(property))
						{
							ue += (idx == 0 ? "" : ",") + (" #" + idx + " = :" + idx);
							an["#"+idx] = property;
							av[":"+idx] = attributeParams[property];
							idx++;
						}
					}
	
					updateParams.UpdateExpression = ue;	
					updateParams.ExpressionAttributeNames = an;
					updateParams.ExpressionAttributeValues = av;
					updateParams.ConditionExpression = 'attribute_exists(userId)';
				
					//updateParams.ReturnValues = "UPDATED_OLD";
					
					console.log("AccountManager_UpdateAttributes dynamoDB params: ", JSON.stringify(updateParams, null, 2));
					
					acctDocClient.update(updateParams, (error, result) => 
					{ 
						if (error)
						{
							console.log("AccountManager_UpdateAttributes 'update' error: " + error + ", code: " + error.code);
							reject(error);
						}
						else
						{
							console.log("AccountManager_UpdateAttributes 'update' result: " + JSON.stringify(result, null, 2));
							resolve(result);
						}
					});
				})
			.catch(err => 
				{
					console.log("AccountManager_UpdateAttributes error: " + err);
					/*
					if (err.name === "ConditionalCheckFailedException")
						console.log("AccountManager_UpdateAttributes 'No User' error: " + err);
					else
						console.log("AccountManager_UpdateAttributes error: " + err);
					*/
					reject(err);
				});
		});
	}

	//------------------------------------------------------------------------------
	//	Read Attributes (internal promise)
	//
	//------------------------------------------------------------------------------
	var AccountManager_ReadAttributes = function(attributeArray)
	{
		AccountManager_GetKeyParams()
		.then(getParams =>
			{	
				acctDocClient = AccountManager_GetDocClient();
				
				getParams.ProjectionExpression = attributeArray.join();

				return new Promise((resolve, reject) =>
					{
						acctDocClient.get(getParams, (error, result) => { (error) ? reject(error) : resolve(result); });
					});
			})
		.then(getData =>
			{
				console.log("AccountManager_ReadAttributes data: ", JSON.stringify(getData));
			})
		.catch(err => 
			{
				console.log("AccountManager_ReadAttributes error: " + err);
			});
		
	}

	//------------------------------------------------------------------------------
	//	x
	//
	//------------------------------------------------------------------------------
	var AccountManager_DecrementAttribute = function(attributeName)
	{
		AccountManager_GetKeyParams()
		.then(decrementAttributeParams =>
			{	
				acctDocClient = AccountManager_GetDocClient();
				
				// Update the specified attributes
				decrementAttributeParams.UpdateExpression = 'set #a = #a - :one',
				decrementAttributeParams.ExpressionAttributeNames = {'#a' : attributeName}; 
				decrementAttributeParams.ExpressionAttributeValues = {':one' : 1};
				decrementAttributeParams.ConditionExpression = 'attribute_exists(userId)';

				//console.log("AccountManager_DecrementAttribute: " + JSON.stringify(decrementAttributeParams, null, 2));
				
				return new Promise((resolve, reject) =>
					{
						acctDocClient.update(decrementAttributeParams, (error, result) => { (error) ? reject(error) : resolve(result); });
					});
			})
		.then(() =>
			{
				// Nothing to do for success.
				//console.log("AccountManager_DecrementAttribute: success, " + JSON.stringify(decrementResponseData, null, 2) );
			})
		.catch(err => 
			{
				console.log("AccountManager_DecrementAttribute error: " + err);
			});
		
	}

	//------------------------------------------------------------------------------
	//	x
	//------------------------------------------------------------------------------
	var db_Scan = function() 
	{
		acctDocClient.scan({TableName: acctTableName}, function(err, data) {
			console.log("Scan results for '" + acctTableName + "':");
			if (err) {
				console.log("Unable to scan: " + "\n" + JSON.stringify(err, undefined, 2));
			} else {
				console.log("scan succeeded: " + "\n" + JSON.stringify(data, undefined, 2));
			}
		});
	}
	
	//------------------------------------------------------------------------------
	//	Public API
	//
	//------------------------------------------------------------------------------
	return {
		Init:					AccountManager_Init,
		SignedIn:				AccountManager_SignedIn,
		Reset:					AccountManager_Reset,
		GetAccountSchema:		AccountManager_GetAccountSchema,
		//CreateAccount:			AccountManager_CreateAccount,
		LoadAccount:			AccountManager_LoadAccount,
		DeleteAccount:			AccountManager_DeleteAccount,
		UpdateLastLogin:		AccountManager_UpdateLastLogin,
		UpdateLastRelaunch:		AccountManager_UpdateLastRelaunch,
		GetLastLogin:			AccountManager_GetLastLogin,
		UpdateAttributes:		AccountManager_UpdateAttributes,
		ReadAttributes:			AccountManager_ReadAttributes,
		DecrementAttribute:		AccountManager_DecrementAttribute,
		GetCurrentUser:			AccountManager_GetCurrentUser
	}
	
}());

//------------------------------------------------------------------------------
//	Export for public access
//
//------------------------------------------------------------------------------
export { AccountManager };
 