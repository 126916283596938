import { GenerateDXF, GenerateSVG } from "../../VectorUtilsJS/src/VectorFileLib.js";
import { Polygon_CalcCentroid } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { ScreenDesigner } from "./ScreenDesigner.js";
import { DesignType } from "./ScreenDesignGenerator.js";
import { Gradient } from "../../VectorUtilsJS/src/GradientLib.js";
import { VectorCornerStyle } from "../../VectorUtilsJS/src/VectorUtilLib.js"; // 2022.02.12

/*-----------------------------------------------*
 * ScreenDesignerFileIO
 *
 *-----------------------------------------------*/

// IO (singleton)
var ScreenDesignerFileIO = (function() {

	// Regular expressions for matching file types
	var REGEX_svg = /\.svg$/i;
	var REGEX_txt = /\.txt$/i;
	var REGEX_dxf = /\.dxf$/i;
	var REGEX_json = /\.json$/i;


	// File being loaded
	var appLoadingFile = undefined;
	var appLoadingInfo = undefined;
	var addDataChanged = false;
	
	var Namespace_CompanyName = "CompanyName";
	var Namespace_AppName = "AppName";
	var Namespace_DataName = "DataName";
	var Namespace_DataId = "DataId";
	
	var ScreenDesignerFileIO_Init = function(namespace)
	{
		if (namespace != undefined)
		{
			Namespace_CompanyName = namespace["companyName"];
			Namespace_AppName     = namespace["appName"];
			Namespace_DataName    = namespace["dataName"];
			Namespace_DataId      = namespace["dataId"];
		}
	}
	
	var ScreenDesignerFileIO_UpdateDocumentName_priv = function()
	{
		// Update one of the document name fields using the name of
		// the loaded file.
		//
		
		// Determine which name field to update
		// (This is not pretty, but it works.)
		var id = "ID_DocumentName";
		if (appLoadingInfo == "workbookLoad" || appLoadingInfo == "workbookImport")
			id = "ID_WorkbookName";
		
		// Find the element and only continue with both an element and a file name
		var e = document.getElementById(id);
		if (e != undefined && appLoadingFile != undefined)
		{
			var filename = appLoadingFile;
		
			// Regular expressions
			var countStripRegEx = / \([0-9]+\)$/;
		
			// Only one of these is allowed to be applied
			if (REGEX_svg.test(filename))
				filename = filename.replace(REGEX_svg, "");
			else if (REGEX_txt.test(filename))
				filename = filename.replace(REGEX_txt, "");
			else if (REGEX_json.test(filename))
				filename = filename.replace(REGEX_json, "");
			else if (REGEX_dxf.test(filename))
				filename = filename.replace(REGEX_dxf, "");
						
			// Remove any trailing " (nnn)"
			filename = filename.replace(countStripRegEx, "");	
			
			// Put in the document
			e.value = filename;
			
			// Clear it
			appLoadingFile = undefined;
			appLoadingInfo = undefined;
		}
	}
	
	var ScreenDesignerFileIO_GetDocumentNameFromElement = function(elementID)
	{
		var docName = "untitled";
		var e = document.getElementById(elementID);
		
		if (e != undefined)
		{
			var n = e.value.trim();			
			if (n.length > 0)
				docName = n;
		}
		
		return docName;
	}

	var ScreenDesignerFileIO_GetDocumentName= function()
	{
		return ScreenDesignerFileIO_GetDocumentNameFromElement("ID_DocumentName");
	}

	var ScreenDesignerFileIO_GetWorkbookName = function()
	{
		return ScreenDesignerFileIO_GetDocumentNameFromElement("ID_WorkbookName");
	}
	
	var ScreenDesignerFileIO_SaveAs = function(fileType /* svg, txt, dxf, png */, fileData, documentName)
	{
		//convert svg source to URI data scheme.
		var urlPrefix = "";
		
		if (fileType === "svg")
			urlPrefix = "data:image/svg+xml;charset=utf-8,";
		else if (fileType === "txt")
			urlPrefix = "data:text/plain;charset=utf-8,";
		else if (fileType === "json") // 2021.04.05
			urlPrefix = "data:text/plain;charset=utf-8,";
		else if (fileType === "dxf")
			urlPrefix = "data:text/plain;charset=utf-8,";
		else if (fileType === "png")
			urlPrefix = undefined; // Data is already prepared
		else
			Debug_assert("ScreenDesignerFileIO_SaveAs: Unknown fileType:" + fileType);
		
		var url = undefined;		
		if (urlPrefix != undefined)
			url = urlPrefix + encodeURIComponent(fileData);
		else
			url = fileData;

		// Get the document name from the page
		var docName = documentName;
		docName += "." + fileType;

		// Create an element to perform the download and then clean-up
		var a = document.createElement('a');
		a.setAttribute('href', url);
		a.setAttribute('download', docName);
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	var ScreenDesignerFileIO_SaveAsBlob = function(fileType /* svg, dxf */, fileData, documentName)
	{
		try {
			var docName = documentName + "." + fileType;
			var blobType = "";

			if (fileType === "svg")
				blobType = "image/svg+xml;charset=utf-8,";
			else if (fileType === "dxf")
				blobType = "text/plain;charset=utf-8,";
			else
				console.log("ScreenDesignerFileIO_SaveAsBlob: Unknown fileType:" + fileType);

			var blob = new Blob([fileData], {type: blobType});
			var url = URL.createObjectURL(blob);

			// Handler to clean-up the URL after the download
			var clickHandler = function()
			{
				requestAnimationFrame(function() {
					URL.revokeObjectURL(element.href);
				})

				a.removeAttribute('href')
				a.removeEventListener('click', clickHandler)
			};

			// Create an element to perform the download and then clean-up
			var a = document.createElement('a');
			a.setAttribute('href', url);
			a.setAttribute('download', docName);
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			a.addEventListener('click', clickHandler);
			document.body.removeChild(a);
		}
		catch (err) {
			console.log("ScreenDesignerFileIO_SaveAsBlob");
			console.log(err);
		}
	}
	
	//------------------------------------------------------------------------------
	//	GenerateSVG Gradients
	//		2021.07.07: Adds the gradient colors to an SVG
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_Gradients = function(designRender)
	{
		var appData = designRender.designData;
		var gradientSettings = appData.gradients[0];

		var polySettings = {
			cornerStyle: appData.general.cornerStyle,
			cornerSize: appData.general.cornerSize
		};

		let polygonList = designRender.offsetPolyList;

		for (var pIdx = 0; pIdx < polygonList.GetPolygonCount(); pIdx++)
		{
			let tagInfo = polygonList.GetPolygonTagInfo(pIdx);
			// 2022.03.09: Use 'component'
			if (tagInfo != undefined && tagInfo.component == "designEdge" && (tagInfo.isFrame == undefined || !tagInfo.isFrame))
			{
				// Calculate the centroid of the polygon, which is used to
				// determine the gradient color
				var polygon = polygonList.GetPolygonPoints(pIdx);
				var centroid = Polygon_CalcCentroid(polygon);

				var clr = Gradient.CalcColor(gradientSettings, centroid);
				GenerateSVG.SetFillInfo(true, clr);
				GenerateSVG.SetStrokeInfo(true, clr, 0.25);

				// Indicate that only the current polygon should be rendered
				polySettings.tagMatch = {polygonIdx:pIdx};
				GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
			}
		}
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Background
	//		2022.02.13: Refactored
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleBackground = function(designRender, polySettings)
	{
		var appData = designRender.designData;

		if (appData.general.outputBackground)
		{
			GenerateSVG.SetFillInfo(true, appData.general.backColor);
			GenerateSVG.SetStrokeInfo(false, "#000000", 1.0);
			// When color lines are enabled, there will be extra polygons created for the frame.
			// We have to exclude those by adding 'isFill:undefined', which matches polygons without the 'isFill'
			polySettings.tagMatch = {isFrame:true, isFill:undefined};
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Lattice Lines
	//		2022.02.13: Refactored
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleLatticeLines = function(designRender, polySettings)
	{
		var appData = designRender.designData;

		if (appData.tiling.enableLattice && appData.general.renderLattice)
		{
			GenerateSVG.SetFillInfo(false, "#ffffff");
			GenerateSVG.SetStrokeInfo(true, appData.general.latticeColor, appData.general.latticeWidth);
			// 2022.03.09: Added 'component' property
			let latticePolySettings = {isClosed:false, tagMatch: {component:"latticeEdge"}};
			// 2022.02.12: Support curves
			latticePolySettings.cornerSize  = appData.general.cornerSize
			latticePolySettings.cornerStyle = (appData.tiling.enableLatticeCurves) ? appData.general.cornerStyle : VectorCornerStyle.ANGLE;
			latticePolySettings.curveLimit  = 1.0;
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, latticePolySettings);
		}
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Center Line
	//		2022.02.13: Refactored
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleCenterLine = function(designRender, polySettings)
	{
		var appData = designRender.designData;

		if (appData.general.renderCenter)
		{
			GenerateSVG.SetFillInfo(false, "#ffffff");
			GenerateSVG.SetStrokeInfo(true, appData.general.centerColor, appData.general.centerWidth); // 2022.02.04: Use new settings
			// 2022.03.09: Moved everything to offsetPolyList and added 'component' property.
			polySettings.tagMatch = {component:"centerLine"}; // 2021.04.13: Add all because new default is isStroke
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle MidLine
	//		2022.03.09: Created
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleMidLine = function(designRender, polySettings)
	{
		var appData = designRender.designData;

		if (appData.general.renderMidLine)
		{
			GenerateSVG.SetFillInfo(false, "#ffffff");
			GenerateSVG.SetStrokeInfo(true, appData.general.midLineColor, appData.general.midLineWidth);
			polySettings.tagMatch = {component:"midLine"};
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Notches and Holes
	//		2022.02.13: Refactored
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleNotchesAndHoles = function(designRender, polySettings)
	{
		// 2017.12.04: Added initial drill hole support
		if (designRender.drillHoles.length > 0)
			GenerateSVG.AddCircleList(designRender.drillHoles);

		// 2018.08.08: Added initial notches support
		if (designRender.notches != undefined && designRender.notches.length > 0)
			GenerateSVG.AddNotchesList(designRender.notches);
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Primary Design
	//		2022.02.13: Refactored
	//		Adds the polygons for the design that are not edges and not color polygons.
	//		The flags control whether the combine fill and line settings are used
	//		or just one or the other
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign = function(designRender, polySettings, flags)
	{
		var appData = designRender.designData;

		// 2021.04.12: Add "isStroke"
		// 2022.03.09: Use 'component'
		polySettings.tagMatch = {component:"designEdge"}; // Match only polygons without a tag; 2022.02.10: add isClosed

		if (flags.fill && flags.line && appData.general.renderFill && appData.general.renderLine)
		{
			GenerateSVG.SetFillInfo(appData.general.renderFill, appData.general.fillColor);
			GenerateSVG.SetStrokeInfo(appData.general.renderLine, appData.general.lineColor, appData.general.lineWidth);
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}

		else if (flags.fill && appData.general.renderFill)
		{
			GenerateSVG.SetFillInfo(appData.general.renderFill, appData.general.fillColor);
			GenerateSVG.SetStrokeInfo(false, "#000000", 1.0);
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}

		else if (flags.line && appData.general.renderLine)
		{
			GenerateSVG.SetFillInfo(false, "#ffffff");
			GenerateSVG.SetStrokeInfo(appData.general.renderLine, appData.general.lineColor, appData.general.lineWidth);
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}
	}


	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Image Lines
	//		2022.04.08: Created
	//		Renders "ImageLine" component
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleImageLines = function(designRender, polySettings)
	{
		var appData = designRender.designData;

		polySettings.tagMatch = {component:"imageLine"};

		GenerateSVG.SetFillInfo(false, "#ffffff");
		GenerateSVG.SetStrokeInfo(true, appData.general.lineColor, appData.general.lineWidth);
		GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Shadow
	//		2022.03.10: Added
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleShadow = function(designRender, polySettings, flags)
	{
		var appData = designRender.designData;

		polySettings.tagMatch = {component:"designEdge"};
		
		GenerateSVG.EnableFilter({applyFilter:true});

		if (flags.fill && flags.line && appData.general.renderShadowFill && appData.general.renderShadowLine)
		{
			GenerateSVG.SetFillInfo(appData.general.renderShadowFill, appData.general.shadowColor);
			GenerateSVG.SetStrokeInfo(appData.general.renderShadowLine, appData.general.shadowColor, appData.general.shadowWidth);
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}

		else if (flags.fill && appData.general.renderShadowFill)
		{
			GenerateSVG.SetFillInfo(appData.general.renderShadowFill, appData.general.shadowColor);
			GenerateSVG.SetStrokeInfo(false, "#000000", 1.0);
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}

		else if (flags.line && appData.general.renderShadowLine)
		{
			GenerateSVG.SetFillInfo(false, "#ffffff");
			GenerateSVG.SetStrokeInfo(appData.general.renderShadowLine, appData.general.shadowColor, appData.general.shadowWidth);
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}
		
		if (flags.line && appData.general.renderShadowLine && appData.general.renderCenter)
		{
			GenerateSVG.SetFillInfo(false, "#ffffff");
			GenerateSVG.SetStrokeInfo(appData.general.renderShadowLine, appData.general.shadowColor, appData.general.shadowWidth);
			polySettings.tagMatch = {component:"centerLine"}; 
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}
		
		if (flags.line && appData.general.renderShadowLine && appData.tiling.enableLattice && appData.general.renderLattice)
		{
			GenerateSVG.SetFillInfo(false, "#ffffff");
			GenerateSVG.SetStrokeInfo(appData.general.renderShadowLine, appData.general.shadowColor, appData.general.shadowWidth);
			polySettings.tagMatch = {component:"latticeEdge"}; 
			GenerateSVG.AddPolygonList(designRender.offsetPolyList, polySettings);
		}

		GenerateSVG.EnableFilter({applyFilter:false});
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Palette Color Polygons
	//		2022.02.13: Refactored
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandlePaletteColorPolygons = function(designRender, polySettings)
	{
		var appData = designRender.designData;

		GenerateSVG.SetStrokeInfo(false, "#000000", 1.0);

		// For each of the colors in the palette...
		for (var colorIdx = 0; colorIdx < appData.colors.length; colorIdx++)
		{
			let colorInfo = appData.colors[colorIdx];

			// ...that have both a color and a colorId...
			if (colorInfo != undefined && colorInfo.color != undefined && colorInfo.colorId != undefined)
			{
				// 2021.04.12: Replace "area" with "isFill"
				// 2022.03.09: Use 'component'
				let tagMatch = {component:"colorFill", /*tag:"area",*/ colorId:colorInfo.colorId};

				// ...and that have polygons using that colorId...
				if (designRender.offsetPolyList.SomePolygonTagMatches(tagMatch))
				{
					// ...draw the polygons using that colorId
					GenerateSVG.SetFillInfo(true, colorInfo.color);
					let colorPolySettings = Object.assign({}, polySettings);
					colorPolySettings.tagMatch = tagMatch;
					GenerateSVG.AddPolygonList(designRender.offsetPolyList, colorPolySettings/*{tagMatch}*/);
				}
			}
		}
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG: Handle Color Polygons
	//		2022.02.13: Refactored
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG_HandleColorPolygons = function(designRender, polySettings)
	{
		var appData = designRender.designData;

		// 2021.03.30: Color polygon fill may use stroke width if polygons have "stroke" tag
		GenerateSVG.SetStrokeInfo(false, "#000000", appData.general.lineWidth);

		// 2022.02.16: Add polySettings to get curves
		GenerateSVG.AddPolygonListColors(designRender.offsetPolyList, polySettings);
	}

	//------------------------------------------------------------------------------
	//	GenerateSVG
	//		2022.02.17: Add options, allows 'embed data' flag
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_GenerateSVG = function(designRender, options)
	{
		var b = designRender.bounds;
		var offsetX = -b.min.x;
		var offsetY = -b.min.y;
		var exportW = b.max.x - b.min.x;
		var exportH = b.max.y - b.min.y;
		
		var appData = designRender.designData;
		var embedData = (options == undefined || options.embedData == undefined) ? true : options.embedData;

		var renderShadowLineBelow = appData.general.renderShadowLine && !appData.general.shadowLineAboveColor;
		var renderShadowLineAbove = appData.general.renderShadowLine && appData.general.shadowLineAboveColor;
		
		GenerateSVG.Start(exportW, exportH, appData.general.units);
		GenerateSVG.SetOffset(offsetX, offsetY);
		
		// 2021.03.27: Handle more options for when color polygons need to be rendered
		let exportStyle = 0; /* Single path */
		
		if (appData.designType == DesignType.TYPE_REGULAR_TILING && appData.tiling.useLineColors)
			exportStyle = 1; /* Multiple paths, grouped by color id */
		else if (appData.designType == DesignType.TYPE_IMAGE && (appData.image.monochromeStyle == "G" || appData.image.monochromeStyle == "C"))
			exportStyle = 2; /* Multiple paths, no grouping */
		else if (appData.designType == DesignType.TYPE_IMAGE && (appData.image.monochromeStyle == "N" || appData.image.monochromeStyle == "M"))
			exportStyle = 3; /* Image Lines */

		// 2021.04.13: Use polySettings for all AddPolygonList calls. The tagMatch key is set before each call.
		var polySettings = {
			cornerStyle: appData.general.cornerStyle,
			cornerSize: appData.general.cornerSize
		};

		// 2022.03.10: Add a shadow filter if necessary
		if (appData.general.renderShadowLine || appData.general.renderShadowFill)
			GenerateSVG.AddBlurFilter({offsetX:appData.general.shadowX, offsetY:appData.general.shadowY, stdDev:appData.general.shadowBlur})

		// 2022.02.13: Refactored code so that this would be cleaner, and then reorganized this
		// so that the common code was was not included in the changes between the exportStyles
		//
		GenerateSVG.StartGroup({label:{name:"Layer 1", id:"layer1"}}); // 2019.02.15

		// 2022.02.12: Outside frame, for Cricut
		ScreenDesignerFileIO_GenerateSVG_HandleBackground(designRender, polySettings);

		// 2021.07.07: Save gradients to SVG
		if (appData.gradients[0].enable)
			ScreenDesignerFileIO_GenerateSVG_Gradients(designRender);

		// 2022.03.10: Add shadows
		if (renderShadowLineBelow || appData.general.renderShadowFill)
			ScreenDesignerFileIO_GenerateSVG_HandleShadow(designRender, polySettings, {fill:true, line:true});

		// 2020.10.23: If not using colors, then keep everything in one SVG path with a fill and a stroke
		if (exportStyle == 0 /* Single path */)
		{
			if (renderShadowLineAbove) // 2022.03.10: Add shadows
			{
				// Fill
				ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign(designRender, polySettings, {fill:true, line:false});
			
				// Shadow
				ScreenDesignerFileIO_GenerateSVG_HandleShadow(designRender, polySettings, {fill:false, line:true});
			
				// Stroke
				ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign(designRender, polySettings, {fill:false, line:true});
			}
			else
			{
				ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign(designRender, polySettings, {fill:true, line:true});
			}
		}

		// Colors with a color palette: write each color in its own group
		else if (exportStyle == 1 /* Multiple paths, grouped by color id */)
		{
			// Fill
			ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign(designRender, polySettings, {fill:true, line:false});
			
			// Colors by color palette
			ScreenDesignerFileIO_GenerateSVG_HandlePaletteColorPolygons(designRender, polySettings);
			
			// Stroke
			ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign(designRender, polySettings, {fill:false, line:true});
		}

		// Per-polygon colors: write each polygon individually
		else if (exportStyle == 2) /* Multiple paths, no grouping */
		{
			// Fill
			ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign(designRender, polySettings, {fill:true, line:false});
			
			// Colors
			ScreenDesignerFileIO_GenerateSVG_HandleColorPolygons(designRender, polySettings);
			
			// Stroke
			ScreenDesignerFileIO_GenerateSVG_HandlePrimaryDesign(designRender, polySettings, {fill:false, line:true});
		}

		// Image Lines
		else // exportStyle == 3 /* Image Lines */
		{
			// Image Lines
			ScreenDesignerFileIO_GenerateSVG_HandleImageLines(designRender, polySettings);
		}
		
		// 2022.02.04: Add lattice lines
		ScreenDesignerFileIO_GenerateSVG_HandleLatticeLines(designRender, polySettings);

		ScreenDesignerFileIO_GenerateSVG_HandleCenterLine(designRender, polySettings);

		ScreenDesignerFileIO_GenerateSVG_HandleMidLine(designRender, polySettings); // 2022.03.09

		ScreenDesignerFileIO_GenerateSVG_HandleNotchesAndHoles(designRender, polySettings);

		GenerateSVG.EndGroup(); // 2019.02.15


		// 2022.02.17: Option to omit private data
		if (embedData)
		{
			var privateDataStr;
			// 2021.03.27: Use the design's stringify function, if it has one
			if (typeof appData.Stringify === 'function')
				privateDataStr = appData.Stringify();
			else
				privateDataStr = JSON.stringify(appData);

			GenerateSVG.SetPrivateNamespace(Namespace_CompanyName, Namespace_AppName, Namespace_DataName);
			GenerateSVG.EmbedPrivateData(Namespace_DataId, privateDataStr);
		}
		var svg = GenerateSVG.Finish();
	
		return svg;
	}

	var ScreenDesignerFileIO_SaveAsSVG = function(designRender)
	{
		var svg = ScreenDesignerFileIO_GenerateSVG(designRender);
		var documentName = ScreenDesignerFileIO_GetDocumentName();
		
		ScreenDesignerFileIO_SaveAsBlob("svg", svg, documentName);
	}

	//------------------------------------------------------------------------------
	//	Export As SVG
	//		2022.02.17: Added to deal with issue where Inkscape crashes
	//		when importing files with custom XML tags
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_ExportAsSVG = function(designRender)
	{
		var svg = ScreenDesignerFileIO_GenerateSVG(designRender, {embedData:false});
		var documentName = ScreenDesignerFileIO_GetDocumentName();

		ScreenDesignerFileIO_SaveAsBlob("svg", svg, documentName);
	}

	var ScreenDesignerFileIO_SaveAsTXT = function(appData, documentName)
	{
		var txtStr;
		// 2021.03.27: Use the design's stringify function, if it has one
		if (typeof appData.Stringify === 'function')
			txtStr = appData.Stringify();
		else
			txtStr = JSON.stringify(appData);
		
		ScreenDesignerFileIO_SaveAs("txt", txtStr, documentName);
	}

	//------------------------------------------------------------------------------
	//	Save As JSON
	//		2021.04.05: Saves JSON string. Note that it does not convert the
	//		input, and, instead, saves it exactly as it is provided.
	//------------------------------------------------------------------------------
	var ScreenDesignerFileIO_SaveAsJSON = function(jsonStr, documentName)
	{
		ScreenDesignerFileIO_SaveAs("json", jsonStr, documentName);
	}
	
	var ScreenDesignerFileIO_SaveDXF_priv = function(designRender, embedPrivateData)
	{
		var b = designRender.bounds;
		var offsetX = -b.min.x;
		var offsetY = -b.min.y;
		var exportW = b.max.x - b.min.x;
		var exportH = b.max.y - b.min.y;
		
		var appData = designRender.designData;

		// 2020.11.07: DXF export does not support colors for now.  
		var polySettings = {
			cornerStyle: appData.general.cornerStyle,
			cornerSize: appData.general.cornerSize,
			tagMatch: undefined /* filter out color polygons */
		};

		GenerateDXF.Start(exportW, exportH, appData.general.units);
		GenerateDXF.SetOffset(offsetX, offsetY);
		GenerateDXF.StartEntities();
		GenerateDXF.AddPolygonList(designRender.offsetPolyList, polySettings);
		
		if (appData.general.renderLine && appData.general.renderCenter)
		{
			GenerateDXF.AddPolygonList(designRender.centerPolyList, {cornerStyle:appData.general.cornerStyle, cornerSize:appData.general.cornerSize});
		}

		// 2017.12.04: Added initial drill hole support		
		if (designRender.drillHoles.length > 0)
			GenerateDXF.AddCircleList(designRender.drillHoles);
		
		// 2018.08.08: Added initial notches support		
		if (designRender.notches != undefined && designRender.notches.length > 0)
			GenerateDXF.AddNotchesList(designRender.notches);

		GenerateDXF.FinishEntities();
			
		GenerateDXF.StartObjects();
		GenerateDXF.WriteDictionary();

		if (embedPrivateData)
		{
			var privateDataStr;
			// 2021.03.27: Use the design's stringify function, if it has one
			if (typeof appData.Stringify === 'function')
				privateDataStr = appData.Stringify();
			else
				privateDataStr = JSON.stringify(appData);
			GenerateDXF.EmbedPrivateData("[AppData]", privateDataStr);
		}

		GenerateDXF.FinishObjects();

		var dxf = GenerateDXF.Finish();
	
		var documentName = ScreenDesignerFileIO_GetDocumentName();
		ScreenDesignerFileIO_SaveAsBlob("dxf", dxf, documentName);
	}
	
	var ScreenDesignerFileIO_SaveAsDXF = function(designRender)
	{
		ScreenDesignerFileIO_SaveDXF_priv(designRender, true);
	}
	
	var ScreenDesignerFileIO_ExportAsDXF = function(designRender)
	{
		ScreenDesignerFileIO_SaveDXF_priv(designRender, false);
	}

	var ScreenDesignerFileIO_ExportAsPNG = function(pngData, suffix = undefined)
	{
		// 2018.01.22: Added suffix parameter
		var documentName = ScreenDesignerFileIO_GetDocumentName();
		if (suffix != undefined)
			documentName += suffix;
			
		ScreenDesignerFileIO_SaveAs("png", pngData, documentName);
	}
	
	var ScreenDesignerFileIO_ExportAsPNGBlob = function(pngBlob)
	{
		try {
			if (pngBlob != undefined)
			{
				var documentName = ScreenDesignerFileIO_GetDocumentName();
				var docName = documentName + ".png";
				var url = URL.createObjectURL(pngBlob);

				// Handler to clean-up the URL after the download
				var clickHandler = function()
				{
					requestAnimationFrame(function() {
						URL.revokeObjectURL(element.href);
					})

					a.removeAttribute('href')
					a.removeEventListener('click', clickHandler)
				};

				// Create an element to perform the download and then clean-up
				var a = document.createElement('a');
				a.setAttribute('href', url);
				a.setAttribute('download', docName);
				a.style.display = 'none';
				document.body.appendChild(a);
				a.click();
				a.addEventListener('click', clickHandler);
				document.body.removeChild(a);
			}
		}
		catch (err) {
			console.log("ScreenDesignerFileIO_ExportAsPNGBlob");
			console.log(err);
		}
	}

	var ScreenDesignerFileIO_HandleLoadedData_priv = function(loadedData)
	{
		if (loadedData != undefined)
		{
			ScreenDesigner.UseLoadedData(loadedData, appLoadingInfo);
			ScreenDesignerFileIO_UpdateDocumentName_priv();
		}
	}

	var ScreenDesignerFileIO_LoadSVG_priv = function(evt)
	{
		// Data that may be loaded from the document
		var loadedAppData = undefined;
		
		// Get the contents from the (file) event
		var contents = evt.target.result;

		// Parse the contents
		var parser = new DOMParser();
		var xmlDoc = parser.parseFromString(contents,"image/svg+xml"); // "text/xml"

		// Get the data, if it exists
		var appDataElement = xmlDoc.getElementById(Namespace_DataId);
		var appDataStr = undefined;
		
		if (appDataElement != undefined)
			appDataStr = appDataElement.textContent;
			
		if (appDataStr != undefined)
			loadedAppData = JSON.parse(appDataStr);
			
		ScreenDesignerFileIO_HandleLoadedData_priv(loadedAppData);
	}
	
	var ScreenDesignerFileIO_LoadTXT_priv = function(evt)
	{
		// Data that may be loaded from the document
		var loadedAppData = undefined;
		
		// Get the contents from the (file) event
		var contents = evt.target.result;

		// Parse the contents
		var loadedAppData = JSON.parse(contents);
		
		ScreenDesignerFileIO_HandleLoadedData_priv(loadedAppData);
	}
	
	var ScreenDesignerFileIO_LoadDXF_priv = function(evt)
	{
		// Data that may be loaded from the document
		var loadedAppData = undefined;
		
		// Get the contents from the (file) event
		var contents = evt.target.result;

    	// By lines
    	var lines = contents.split('\n');
    	var contents = "";
    	var prefix = "[AppData]";
    	
    	for (var line = 0; line < lines.length; line++)
    	{
    		if (lines[line].includes(prefix, 0))
      			contents += lines[line].substring(prefix.length);
    	}
    	
		// Parse the contents
		loadedAppData = JSON.parse(contents);
		
		if (loadedAppData != undefined)
			ScreenDesignerFileIO_HandleLoadedData_priv(loadedAppData);
	}
	
	var ScreenDesignerFileIO_LoadCompleted_priv = function(evt)
	{
		if (REGEX_svg.test(appLoadingFile))
			ScreenDesignerFileIO_LoadSVG_priv(evt);
			
		else if (REGEX_txt.test(appLoadingFile) || REGEX_json.test(appLoadingFile))
			ScreenDesignerFileIO_LoadTXT_priv(evt);
			
		else if (REGEX_dxf.test(appLoadingFile))
			ScreenDesignerFileIO_LoadDXF_priv(evt);
			
		else
			Debug_assert("ScreenDesignerFileIO_LoadCompleted_priv: Unexpected file type:" + appLoadingFile);
	}

	var ScreenDesignerFileIO_IsSupportFileType_priv = function(filename)
	{
		var isSupported = false;
	
		if (REGEX_svg.test(filename))
			isSupported = true;
			
		else if (REGEX_txt.test(filename))
			isSupported = true;
			
		else if (REGEX_json.test(filename))
			isSupported = true;

		else if (REGEX_dxf.test(filename))
			isSupported = true;
		
		return isSupported
	}
	
	var ScreenDesignerFileIO_LoadFile = function(e, loadInfo)
	{
		var file = e.target.files[0];
		if (file != undefined)
		{
			if (ScreenDesignerFileIO_IsSupportFileType_priv(file.name))
			{
				var reader = new FileReader();
				reader.onload = ScreenDesignerFileIO_LoadCompleted_priv;
				reader.readAsText(file);
			
				// Store file name to use if file is successfully loaded
				appLoadingFile = file.name;
				appLoadingInfo = loadInfo;
			}
			else
			{
				console.log("Unsupported file type: " + file.name);
			}
		}
	}

	return {
		Init:					ScreenDesignerFileIO_Init,
		GenerateSVG:			ScreenDesignerFileIO_GenerateSVG,
		SaveAsSVG:				ScreenDesignerFileIO_SaveAsSVG,
		SaveAsDXF:				ScreenDesignerFileIO_SaveAsDXF,
		SaveAsTXT:				ScreenDesignerFileIO_SaveAsTXT,
		SaveAsJSON:				ScreenDesignerFileIO_SaveAsJSON,
		ExportAsDXF:			ScreenDesignerFileIO_ExportAsDXF,
		ExportAsPNG:			ScreenDesignerFileIO_ExportAsPNG,
		ExportAsSVG:			ScreenDesignerFileIO_ExportAsSVG,
		ExportAsPNGBlob:		ScreenDesignerFileIO_ExportAsPNGBlob,
		LoadFile:				ScreenDesignerFileIO_LoadFile,
		GetWorkbookName:		ScreenDesignerFileIO_GetWorkbookName,
		GetDocumentName:		ScreenDesignerFileIO_GetDocumentName,
	};
}());

export { ScreenDesignerFileIO };