// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cloud_logic': 'enable',
    'aws_cloud_logic_custom': [{"id":"sxapokuwq8","name":"AccountManager","description":"Manage account info table","endpoint":"https://sxapokuwq8.execute-api.us-east-1.amazonaws.com/Development","region":"us-east-1","paths":["/admin","/admin/123","/cancel","/cancel/123","/query","/query/123","/refresh","/refresh/123"]},{"id":"dbnwrwonf9","name":"EventLogger","description":"Logs info, warning, error, and assertion events","endpoint":"https://dbnwrwonf9.execute-api.us-east-1.amazonaws.com/Development","region":"us-east-1","paths":["/log","/log/123"]},{"id":"9kt9toifrj","name":"StripeProcessing","description":"Manage Stripe interactions from client","endpoint":"https://9kt9toifrj.execute-api.us-east-1.amazonaws.com/Development","region":"us-east-1","paths":["/process","/process/123"]},{"id":"4k78u8qmte","name":"StripeWebhook","description":"Process events from Stripe","endpoint":"https://4k78u8qmte.execute-api.us-east-1.amazonaws.com/Development","region":"us-east-1","paths":["/event","/event/123"]}],
    'aws_cognito_identity_pool_id': 'us-east-1:4c268c34-61ef-4e5d-8835-b1954f6eee94',
    'aws_cognito_region': 'us-east-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'polygonia-hosting-mobilehub-578236831',
    'aws_content_delivery_bucket_region': 'us-east-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd1vv30xxzr06z2.cloudfront.net',
    'aws_dynamodb': 'enable',
    'aws_dynamodb_all_tables_region': 'us-east-1',
    'aws_dynamodb_table_schemas': [{"tableName":"polygonia-mobilehub-578236831-AccountInfo","attributes":[{"name":"userId","type":"S"}],"indexes":[],"region":"us-east-1","hashKey":"userId"}],
    'aws_mobile_analytics_app_id': '6999024326b7444f97d53a7e4261ddef',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': 'dbee91c8-4975-49b3-ab0f-94bc895d31b2',
    'aws_project_name': 'Polygonia',
    'aws_project_region': 'us-east-1',
    'aws_resource_bucket_name': 'polygonia-deployments-mobilehub-578236831',
    'aws_resource_name_prefix': 'polygonia-mobilehub-578236831',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'polygonia-userfiles-mobilehub-578236831',
    'aws_user_files_s3_bucket_region': 'us-east-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_ZMPgxJmZw',
    'aws_user_pools_mfa_type': 'OFF',
    'aws_user_pools_web_client_id': '1lc9kto0fsrif3go5p186b3sm0',
}

export default awsmobile;
