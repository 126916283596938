//==========================================================================================
//
//	Amplify Authentication Manager
//
//		This module manages the interaction between the user and the AWS Amplify Auth
//		class. 
//------------------------------------------------------------------------------------------
//
//==========================================================================================

import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
var AWS = require('aws-sdk/global');

var AuthManager = (function() {

	//	Structure of elements expected in HTML
	//		btn: ID_AuthBtn
	//		txt: ID_AuthUser
	//		div: ID_AuthDiv
	//			div: ID_AuthSignUp
	//   		div: ID_AuthSignIn
	//			div: ID_AuthForgot
	//			div: ID_AuthConfirm
	//			div: ID_AuthChange
	//
	//
	//	Configuration object passed to Init: 
	//	{
	//		analyticsRecord: callback to record analytics events
	//		authSignOut: callback to notify application of sign out
	//		authSignIn: callback to notify application of sign in
	//	}
	//
	// State transitions:
	//		UNAUTHORIZED -(START_SIGNIN)-> SHOW_SIGNIN -(SIGNIN)-> PENDING_SIGNIN -(SIGNEDIN)-> AUTHORIZED
	//		UNAUTHORIZED -(START_SIGNUP)-> SHOW_SIGNUP -(SIGNUP)-> PENDING_CONF -(START_CONFIRM)-> SHOW_CONFIRM -(CONFIRM)-> PENDING_SIGNUP -(SIGNEDUP)> AUTHORIZED 
	
	//------------------------------------------------------------------------------
	//	Authentication States
	//
	//	Do not change these numbers without also updating the authElementList, below.
	//	The vis field of the authElementList is a set of flags to determine which
	//	elements are visible according to the state.
	//------------------------------------------------------------------------------
	var AuthState = Object.freeze({
		UNAUTHORIZED:			0,
		AUTHORIZED:				1,
		
		SHOW_SIGNUP:			2,
		PENDING_CONF:			3,
		SHOW_CONFIRM:			4,
		PENDING_SIGNUP:			5, 
		
		SHOW_SIGNIN:			6, 
		PENDING_SIGNIN:			7,
		
		SHOW_FORGOT:			8,
		PENDING_FORGOT:			9,
		SHOW_FORGOTCONFIRM:		10,
		PENDING_FORGOTCONFIRM:	11,
		
		SHOW_CHANGEPSWD:		12,
		PENDING_CHANGEPSWD:		13,
		
		SHOW_DELETEACCT:		14,
		CONFIRM_DELETEACCT:		15,
		PENDING_DELETEACCT:		16,
				
		PENDING_UNAUTH:			30,
		
		ERRORAUTH:				98,	// Show an error while authenticated
		ERROR:					99
		
		});
		
	//------------------------------------------------------------------------------
	//	Authentication Events
	//
	//	Various UI and server events will be translated into these events and passed
	//	to the state machine for processing
	//------------------------------------------------------------------------------
	var AuthEvent = Object.freeze({
		DISMISS:			0,
		
		START_SIGNIN:		1,
		SIGNIN:				2,
		SIGNEDIN:			3,
		SIGNIN_FED:			30, // 2020.07.06: "Federated SignIn". Advances state machine to "Pending SignIn"
		SIGNEDIN_FED:		31, // 2020.07.06
		
		START_SIGNOUT:		4,
		SIGNEDOUT:			5,

		START_SIGNUP:		6,
		SIGNUP:				7,
		START_CONFIRM:		8,
		CONFIRM:			9,
		SIGNEDUP:	   		10,
		
		START_FORGOT:		11,
		FORGOT:   			12,
		START_FORGOTCONF:	13,
		FORGOTCONFIRM:		14,
		DONE_FORGOT:		15,

		START_CHANGEPSWD:	16,
		CHANGEPSWD:   		17,
		DONE_CHANGEPSWD:	18,
		
		START_DELETEACCT:	19,
		CONFIRMED_DELETEACCT:	20,
		DELETEACCT:			21,
		DONE_DELETEACCT:	22,
		
		ERROR:		 		99
		});
		
	
	//------------------------------------------------------------------------------
	//	Authentication Element List
	//
	//	The list of elements managed by this module
	//------------------------------------------------------------------------------
	var authElementControlList = [
		{ id: "ID_AuthBannerBtnI",			handler:"onclick",	isBtn:1,	amEvt:AuthEvent.START_SIGNIN	},
		{ id: "ID_AuthBannerBtnU",			handler:"onclick",	isBtn:1,	amEvt:AuthEvent.START_SIGNUP	},
		{ id: "ID_AuthBannerBtnO",			handler:"onclick",	isBtn:1,	amEvt:AuthEvent.START_SIGNOUT	},
		{ id: "ID_AuthBackground",								isBtn:0,	amEvt:AuthEvent.DISMISS			},
		{ id: "ID_AuthClose",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.DISMISS			},
		{ id: "ID_AuthBtnI",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.SIGNIN			},
		{ id: "ID_AuthBtnU",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.SIGNUP			},
		{ id: "ID_AuthBtnU2",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.START_SIGNUP	},
		{ id: "ID_AuthBtnC",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.CONFIRM			},
		{ id: "ID_AuthBtnFP",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.START_FORGOT	},
		{ id: "ID_AuthBtnFI",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.START_SIGNIN	},
		{ id: "ID_AuthBtnHA",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.START_SIGNIN	},
		{ id: "ID_AuthBtnF",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.FORGOT			},
		{ id: "ID_AuthBtnCF",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.FORGOTCONFIRM	},
		{ id: "ID_AuthBtnCP",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.CHANGEPSWD		},
		{ id: "ID_AuthBtnDC",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.DISMISS			},
		{ id: "ID_AuthBtnDCC",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.DISMISS			},
		{ id: "ID_AuthBtnDA",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.DELETEACCT		},
		{ id: "ID_AuthBtnDAC",				handler:"onclick",	isBtn:1,	amEvt:AuthEvent.CONFIRMED_DELETEACCT	},
		{ id: "ID_AuthAgreeTC",				handler:"onchange",	isBtn:1		},
		{ id: "ID_CustomGoogleBtn",			handler:"onclick",	isBtn:1,	amEvt:AuthEvent.SIGNIN_FED		}, // 2020.07.06
		/* Settings support */
		{ id: "ID_AuthUser",				handler:"onclick",	isBtn:1		},
		{ id: "ID_AuthSettingsClose",		handler:"onclick",	isBtn:1		},
		{ id: "ID_AuthSettingsBackground",	handler:"onclick",	isBtn:1		},
		{ id: "ID_AuthChangePswd",			handler:"onclick",	isBtn:1		},
		{ id: "ID_AuthBtnDeleteAcct",		handler:"onclick",	isBtn:1		},
		{ id: "ID_ShowPlanInfo",			handler:"onclick",	isBtn:1		},
		{ id: "ID_CancelPlan",				handler:"onclick",	isBtn:1		},
		{ id: "ID_RestorePlan",				handler:"onclick",	isBtn:1		},
		{ id: "ID_BillingPortal",			handler:"onclick",	isBtn:1		}
		];
	
	var authElementVisList = [
		{ id: "ID_AuthBackground",	disp:1, hide:[AuthState.UNAUTHORIZED, AuthState.AUTHORIZED]	},
		{ id: "ID_AuthBannerBtnI",	disp:2, show:[AuthState.UNAUTHORIZED]	},
		{ id: "ID_AuthBannerBtnU",	disp:2, show:[/*AuthState.UNAUTHORIZED*/]	},
		{ id: "ID_AuthBannerBtnO",	disp:2, show:[AuthState.AUTHORIZED]	},
		{ id: "ID_AuthUser",		disp:2, show:[AuthState.AUTHORIZED]	},
		{ id: "ID_AuthSignIn",		disp:1, show:[AuthState.SHOW_SIGNIN, AuthState.PENDING_SIGNIN]	},
		{ id: "ID_AuthSignUp",		disp:1, show:[AuthState.SHOW_SIGNUP, AuthState.PENDING_CONF]	},
		{ id: "ID_AuthConfirm",		disp:1, show:[AuthState.SHOW_CONFIRM, AuthState.PENDING_SIGNUP]	},
		{ id: "ID_AuthForgot",		disp:1, show:[AuthState.SHOW_FORGOT, AuthState.PENDING_FORGOT]	},
		{ id: "ID_AuthForgotConf",	disp:1, show:[AuthState.SHOW_FORGOTCONFIRM, AuthState.PENDING_FORGOTCONFIRM]	},
		{ id: "ID_AuthChange",		disp:1, show:[AuthState.SHOW_CHANGEPSWD, AuthState.PENDING_CHANGEPSWD]	},
		{ id: "ID_AuthDeleteAcct",	disp:1, show:[AuthState.SHOW_DELETEACCT]	},
		{ id: "ID_AuthDeleteConf",	disp:1, show:[AuthState.CONFIRM_DELETEACCT, AuthState.PENDING_DELETEACCT]	},
		{ id: "ID_AuthError",		disp:1, show:[AuthState.ERROR, AuthState.ERRORAUTH]	},
		{ id: "ID_AuthProgress",	disp:1, show:[AuthState.PENDING_CONF,	AuthState.PENDING_SIGNUP, AuthState.PENDING_SIGNIN, 
												  AuthState.PENDING_UNAUTH, AuthState.PENDING_FORGOT, AuthState.PENDING_CHANGEPSWD,
												  AuthState.PENDING_DELETEACCT]	}
		];
		
	var authPasswordElementList = [
		{ id: "ID_AuthPswdU",	show: [AuthState.SHOW_SIGNUP, AuthState.PENDING_CONF]										},
		{ id: "ID_AuthPswdCN",	show: [AuthState.SHOW_CHANGEPSWD, AuthState.PENDING_CHANGEPSWD],		msg: "New Password"	},
		{ id: "ID_AuthPswdF",	show: [AuthState.SHOW_FORGOTCONFIRM, AuthState.PENDING_FORGOTCONFIRM]						}
	];
		
	var authHelpTextList = [
		{ 	msg:	//"Password must have at least 8 characters and have a combination of uppercase letters, lowercase letters, special characters, and numbers.<br><br>" +
					"You will receive an email with a confirmation code. <br><br>" +
					"Enter the confirmation code on the next screen.",	
			states:[AuthState.SHOW_SIGNUP, AuthState.PENDING_CONF]	},
			
		{ 	msg:	"Check your email for a confirmation code. <br><br>" +
					"Once you enter it here you will be asked to sign in.",	
			states:[AuthState.SHOW_CONFIRM, AuthState.PENDING_SIGNUP]	},
			
		{ 	msg:	"You will receive an email with a confirmation code. <br><br>" + 
					"Enter the confirmation code on the next dialog.",	
			states:[AuthState.SHOW_FORGOT, AuthState.PENDING_FORGOT]	}
		];
	
	//------------------------------------------------------------------------------
	//	Class variables
	//
	//------------------------------------------------------------------------------
	var authState = AuthState.UNAUTHORIZED;
	var authError = undefined;
	var authUser = undefined;
	var authPendingUsername = undefined;
	var authCredentialsId = undefined;
	
	// Google 
	var authGoogleAuth2 = undefined;
	
	// Periodic timer to monitor for 'wake from sleep'. Needed to
	// refresh credentials immediately
	var authWakeFromSleep = { timer: undefined, timestamp: undefined, interval: 3000 };
	
	// 2019.03.12: Periodic timer to refresh the token. This is needed because the token
	// will expire if there are no Amplify calls, which happens if the the user leaves
	// the page up and logged in but does not do anything.
	var authRefreshCredentialsTimer = undefined;
	
	// 2019.05.13: When the window gets focus, start a short timer to refresh the credentials
	// to determine if the user signed in to the app from another window
	var authCheckTokenTimer = undefined;
	
	//------------------------------------------------------------------------------
	//	Messages
	//------------------------------------------------------------------------------
	var authErrorMsg = undefined;
	
	//------------------------------------------------------------------------------
	//	Configuration
	//
	//		analyticsRecord
	//		authPreSignOut
	//		authSignOut
	//		authSignIn
	//		accountPreDelete
	//		accountManager, API:
	//			GetLastLogin
	//			Reset
	//			CreateAccount
	//			UpdateLastLogin
	//			UpdateLastRelaunch
	//		accountInfo, API:
	//			GetSettingsInfoStr
	//			ShowSubscriptionInfo
	//			UpdateSettingsButtons
	//------------------------------------------------------------------------------
	var authMgrconfig = {};
	
	
	//------------------------------------------------------------------------------
	//	Local debugging functions
	//
	//------------------------------------------------------------------------------
	function authMgrLogInfo(str) { /* console.log(str); */ }
	function authMgrLogError(str) { console.error(str); }
	function authMgrLogDebug(str) { console.log(str); }
	
	//------------------------------------------------------------------------------
	//	Init
	//
	//------------------------------------------------------------------------------
	var AuthManager_Init = function(configuration)
	{
		authMgrLogInfo("AuthManager_Init");
		authMgrconfig = configuration;
		
		Hub.listen('auth', AuthManager_EventListener);

		AuthManager_SetupUI();		
	 	AuthManager_UpdateUI();
		
		AuthManager_CheckAuthStatus();
		
		// Initializing Google API from here instead of from HTML (using 
		// button initialization) so we can control when the sign-in callback
		// happens. 
		AuthManager_Google_Init();
	}

	//------------------------------------------------------------------------------
	//	Finalize Init
	//		In case we need to do something after CheckAuthStatus finishes
	//------------------------------------------------------------------------------
	var AuthManager_FinalizeInit = function()
	{
		/*console.log("AuthManager_FinalizeInit; authState: " + authState);*/
	}

	//------------------------------------------------------------------------------
	//	Event Listener
	//		Respond to auth events from the hub
	//		2020.07.06: Added
	//------------------------------------------------------------------------------
	var AuthManager_EventListener = function(data)
	{
		const { payload } = data;
		//console.log("%c" + payload.event + "%c", "color:green;", "color:initial;");
		//console.log(payload.data);

		switch (payload.event)
		{
			case 'signIn':
				if (payload.data.authenticatedBy == "google")
					AuthManager_ProcessAMEvent(AuthEvent.SIGNEDIN_FED);
				else
					AuthManager_ProcessAMEvent(AuthEvent.SIGNEDIN);
				break;
			case 'signUp':
				break;
			case 'signOut':
				break;
			case 'signIn_failure':
				break;
			case 'configured':
				break;
			default:
				console.error("AuthManager_EventListener: Unexpected event: ${data.payload.event}");
				break;
		}
	}

	//------------------------------------------------------------------------------
	//	Monitor For Wake From Sleep
	//		Start a task to monitor for waking from sleep. If this happens
	//		we need to refresh the credentials quickly, so new  work is not lost.
	//------------------------------------------------------------------------------
	var AuthManager_MonitorForWakeFromSleep = function()
	{
		// 2019.03.13: Periodically refresh the token
		authRefreshCredentialsTimer = setInterval(AuthManager_AttemptTokenRefreshOrSignOut, 11 * 60 * 1000);

		// Periodically refresh the document client credentials
		authWakeFromSleep.timestamp = (new Date()).getTime();
		authWakeFromSleep.timer = setInterval(() => 
			{
				var now = (new Date()).getTime();
				var elapsed = now - authWakeFromSleep.timestamp;
				if (elapsed > authWakeFromSleep.interval * 2)
					AuthManager_WokenFromSleep(elapsed);
				authWakeFromSleep.timestamp = now;
			}, 
			authWakeFromSleep.interval);
	}
	
	//------------------------------------------------------------------------------
	//	Reset Monitor Wake From Sleep
	//		Called during sign out.
	//------------------------------------------------------------------------------
	var AuthManager_ResetMonitorWakeForSleep = function()
	{
		if (authWakeFromSleep.timer != undefined)
		{
			clearInterval(authWakeFromSleep.timer);
			authWakeFromSleep.timer = undefined;
		}

		if (authRefreshCredentialsTimer != undefined)
		{
			clearInterval(authRefreshCredentialsTimer);
			authRefreshCredentialsTimer = undefined;
		}
	}
	
	//------------------------------------------------------------------------------
	//	Woken From Sleep
	//		Called after a long delay is detected, typically from waking from sleep.
	//------------------------------------------------------------------------------
	var AuthManager_WokenFromSleep = function(elapsed)
	{
		/*
		{
			var secs = Math.floor(elapsed/1000);
			var mins = Math.floor(secs/60);
			secs = secs % 60;
			var hrs = Math.floor(mins/60);
			mins = mins % 60;
			console.log("AuthManager_WokenFromSleep: " + hrs + "h" + mins + "m" + secs + "s elapsed");
		}
		*/
		AuthManager_AttemptTokenRefreshOrSignOut();
	}
	
	//------------------------------------------------------------------------------
	//	Attempt Token Refresh Or Sign Out
	//		Called after a long delay is detected, typically from waking from sleep.
	//------------------------------------------------------------------------------
	var AuthManager_AttemptTokenRefreshOrSignOut = function()
	{
		//console.log("AuthManager_AttemptTokenRefreshOrSignOut", (new Date()).toTimeString());
		
		// Perform the same calls to Auth as when the page was opened to check if a
		// user is already authenticated so that we can either refresh the token or
		// do a sign out.
		Auth.currentAuthenticatedUser()
		.then(result => 
		{
			return Auth.currentUserCredentials();
		})
		.then(credentials => 
		{
			//console.log("AttemptTokenRefreshOrSignOut:  expired: " + credentials.expired + ", expiredWindow:" + credentials.expiryWindow + " expireTime:" + credentials.expireTime);

			global.AWS.config.credentials = credentials;
			
			// Did the credentials change? Then sign out	
			if (AuthManager_CredentialsIdChanged(credentials))
			{
				// 2019.10.14: Wrap with a try/catch in case this is causing an error
				try {
					console.log("AuthManager_AttemptTokenRefreshOrSignOut: Signing out due to credential change.");
					AuthManager_HandleAMEvent(AuthEvent.START_SIGNOUT);
				}
				catch (err) {
					console.log("AuthManager_AttemptTokenRefreshOrSignOut: error from AuthManager_HandleAMEvent");
					console.log(err);
					throw err;
				}
			}
			// For consistency, always cache the id from the most recent credentials
			else
			{
				AuthManager_CacheCredentialsId(credentials);
			}
		})
		.catch(err => 
		{
			console.log("AuthManager_AttemptTokenRefreshOrSignOut: Error occurred ---> Signing out due to inactivity.");
			console.log(err);
			AuthManager_HandleAMEvent(AuthEvent.START_SIGNOUT);
			
			// 2021.08.27: Reload the page in an attempt to clear the error where Amplify can not figure out the 
			// authenticated user if you sign in after a forced sign-out
			location.reload();
		})
	}
	
	//------------------------------------------------------------------------------
	//	Handle Focus Change
	//------------------------------------------------------------------------------
	var AuthManager_HandleFocusChange = function(nowHasFocus)
	{
		function AuthManager_CheckTokenAfterFocus()
		{
			authCheckTokenTimer = undefined; 
			if (AuthManager_IsAuthorized())
				AuthManager_AttemptTokenRefreshOrSignOut();
		}
		
		// If we just got focus and we are signed in and we do not currently have an active timer
		// then create a timer to call the 'attempt token refresh' function
		if (nowHasFocus && AuthManager_IsAuthorized() && authCheckTokenTimer == undefined)
			authCheckTokenTimer = setTimeout(AuthManager_CheckTokenAfterFocus, 500);
	}
	

	//------------------------------------------------------------------------------
	//	Get Id From Credentials
	//------------------------------------------------------------------------------
	var AuthManager_GetIdFromCredentials = function(credentials)
	{
		var id = undefined;
		
		if (credentials != undefined && credentials._identityId != undefined) 
			id = JSON.parse(JSON.stringify(credentials._identityId));
			
		return id;
	}
	//------------------------------------------------------------------------------
	//	Cache Credentials Id
	//------------------------------------------------------------------------------
	var AuthManager_CacheCredentialsId = function(credentials)
	{
		authCredentialsId = AuthManager_GetIdFromCredentials(credentials);
	}
	
	//------------------------------------------------------------------------------
	//	Cache Credentials Id
	//------------------------------------------------------------------------------
	var AuthManager_CredentialsIdChanged = function(credentials)
	{
		var id = AuthManager_GetIdFromCredentials(credentials);

		return (id !== authCredentialsId);
	}
	
	//------------------------------------------------------------------------------
	//	Diags Monitor
	//
	//------------------------------------------------------------------------------
	var AuthManager_DiagsMonitor = function()
	{
		/*
		var e = document.getElementById("ID_AuthDiags");
		
		if (e != undefined)
		{
			var timeStr = (new Date()).toLocaleTimeString();
			var s = timeStr + "  ";
			
			Auth.currentUserCredentials()
			.then(credentials =>
			{
				//console.log(JSON.stringify(credentials, null, 2));
				
				var needsRefresh = (credentials.needsRefresh != undefined) ? credentials.needsRefresh() : "???"; // credentials.needsRefresh();
				var expired = credentials.expired;
				var expiryWindow = credentials.expiryWindow;
				var expireTimeStr = credentials.expireTime;
		
				s += "needsRefresh:" + needsRefresh + ", expired: " + expired + ", expiredWindow:" + expiryWindow + " expireTime:" + expireTimeStr;
				e.innerHTML = s;
			})
			.catch(err =>
			{
				s += "err:" + err.message;
				e.innerHTML = s;
			});
		}
		*/
	}

	//------------------------------------------------------------------------------
	//	Setup UI
	//
	//------------------------------------------------------------------------------
	var AuthManager_SetupUI = function()
	{
		// Attach handlers to buttons and set AM event values
		for (var i = 0; i < authElementControlList.length; i++)
		{
			var ctl = authElementControlList[i];
			var e = document.getElementById(ctl.id);
			if (e == undefined)
			{
				authMgrLogDebug("AuthManager_SetupUI: Missing element '" + ctl.id + "'");
			}
			else
			{
				if (ctl.isBtn)
					e[ctl.handler] = AuthManager_ButtonHandler;
					
				if (ctl.amEvt != undefined)
					e.authMgrEvt = ctl.amEvt;
			}
		}
		
		// Attach handlers to password fields to provide feedback for
		// password requirements
		for (var i = 0; i < authPasswordElementList.length; i++)
		{
			var ctl = authPasswordElementList[i];
			var e = document.getElementById(ctl.id);
			e.addEventListener("input", AuthManager_ProvidePasswordFeedback)
		}
		
		// When the user clicks anywhere outside of the modal, close it
		// Also, attached a keydown handler to detect esc and enter for the dialogs
		e = document.getElementById("ID_AuthBackground");
		if (e != undefined)
		{
			e.onclick = AuthManager_ButtonHandler;
			e.addEventListener("keydown", AuthManager_KeyDown);
		}
		else
			authMgrLogDebug("Missing ID_AuthBackground");

		// Disable sign-up button
		var doSignUpButton = document.getElementById("ID_AuthBtnU");
		doSignUpButton.disabled = true;		
	}
	
	//------------------------------------------------------------------------------
	//	Start Sign Up
	//
	//	Called externally, primarily from the Subscription Plan dialog, to start
	//	the sign-up process.
	//------------------------------------------------------------------------------
	var AuthManager_StartSignUp = function()
	{
		if (authState == AuthState.UNAUTHORIZED)
		{
			AuthManager_HandleAMEvent(AuthEvent.START_SIGNIN);
		}
		else
		{
			authMgrLogError("AuthManager_StartSignUp: Called by not in UNAUTHORIZED state");
		}
	}
	
	//------------------------------------------------------------------------------
	//	Provide Password Feedback
	//
	//	Provides feedback indicating if password matches requirements.
	//------------------------------------------------------------------------------
	var AuthManager_ProvidePasswordFeedback = function(evt)
	{
		//let pswd = evt.target.value.trim();
		//AuthManager_PopulatePasswordFeedback(pswd);
		AuthManager_ProvidePasswordFeedbackForID(evt.target.id);
	}
	
	var AuthManager_ProvidePasswordFeedbackForID = function(id)
	{
		let e = document.getElementById(id);
		let pswd = e.value.trim();
		var msg = undefined;
		
		// Support alternative message (basically, instead of "Password", allow "New Password")
		for (var i = 0; i < authPasswordElementList.length && msg == undefined; i++)
			if (authPasswordElementList[i].id == id)
				msg = authPasswordElementList[i].msg;
				
		AuthManager_PopulatePasswordFeedback(pswd, msg);
	}

	var AuthManager_ValidatePassword = function(pswd)
	{
		var v = {};
		let symbols = "[^$*.\[\]{}()?-\"!@#%&/\,><':;|_~`]";
		v.lenOK = (pswd.length >= 8);
		v.symOK = false;
		v.uppOK = RegExp('[A-Z]').test(pswd);
		v.lowOK = RegExp('[a-z]').test(pswd);
		v.numOK = RegExp('[0-9]').test(pswd);

		for (var i = 0; i < symbols.length && !v.symOK; i++)
			v.symOK = pswd.includes(symbols[i]);
		
		v.pswdOK = (v.lenOK && v.symOK && v.uppOK && v.lowOK && v.numOK);
		
		return v;
	}
		
	var AuthManager_PopulatePasswordFeedback = function(pswd, passwordMsg = undefined)
	{
		let v = AuthManager_ValidatePassword(pswd);
					
		let e = document.querySelector(".CL_AuthDialogPasswordText");
		
		let br = "<br>";
		let checkmark = "<span class='CL_AuthDialogPasswordStatus CL_AuthDialogPasswordStatus-checkmark'>&#10004;</span>";
		let bigx = "<span class='CL_AuthDialogPasswordStatus CL_AuthDialogPasswordStatus-bigx'>&#10060;</span>";
		let dash = "<span class='CL_AuthDialogPasswordStatus CL_AuthDialogPasswordStatus-dash'>&#10148;</span>"; // Vert bar: &#10074;
		let dashred = "<span class='CL_AuthDialogPasswordStatus CL_AuthDialogPasswordStatus-dashred'>&#10148;</span>";
		let space = "<span class='CL_AuthDialogPasswordStatus-space'></span>";
		let str = "";
		
		let passwordStr = (passwordMsg == undefined) ? "Password" : passwordMsg;
		
		if (v.pswdOK)
		{
			str = checkmark + " " + passwordStr;
		}
		else
		{
			let anyOK = (v.lenOK || v.symOK || v.uppOK || v.lowOK || v.numOK);
			str = (anyOK ? dash : dashred) + " " + passwordStr + br;
			str += space + (v.lenOK ? checkmark : bigx) + " Length" + br;
			str += space + (v.symOK ? checkmark : bigx) + " Symbols" + br;
			str += space + (v.uppOK ? checkmark : bigx) + " Uppercase" + br;
			str += space + (v.lowOK ? checkmark : bigx) + " Lowercase" + br;
			str += space + (v.numOK ? checkmark : bigx) + " Numbers";
		}
		
		e.innerHTML = str;
		
	}
	
	//------------------------------------------------------------------------------
	//	Update UI
	//
	//	Update the visibility of all of the listed elements according to the 
	//	authentication state. Also clear the fields when the background, and, 
	//	therefore, the entire authentication UI, is hidden.
	//------------------------------------------------------------------------------
	var AuthManager_UpdateUI = function()
	{
		for (var i = 0; i < authElementVisList.length; i++)
		{
			// Get the entry form the array
			var ctl = authElementVisList[i];
			// Set the visible flag depending on whether or not the current
			// auth state is listed in the "show list" or "hide list" for the element
			var vis = (ctl.show != undefined ? ctl.show.includes(authState) : false) || (ctl.hide != undefined ? !ctl.hide.includes(authState) : false);
			// Get the display type, "block" or "inline-block"
			var d = ctl.disp;

			var e = document.getElementById(ctl.id);
			if (e != undefined)
			{
				if (!vis)
					e.style.display = "none";
				else if (d == 1)
					e.style.display = "block";
				else if (d == 2)
					e.style.display = "inline-block";
			}
			else
			{
				authMgrLogDebug("Missing '" + ctl.id + "'");
			}
			
			// 2018.06.06: Clear all the fields when the authentication UI is hidden.
			// The main reason to do this is that it keeps 1Password from popping up
			// anytime the buttons in the help system are pressed.
			if (ctl.id == "ID_AuthBackground" && ctl.hide.includes(authState))
				AuthManager_ClearAllFields();
				
			// 2019.10.14: Load the authorizing animation the first time we need it
			if (ctl.id == "ID_AuthProgress" && vis)
			{
				let img = document.getElementById("ID_AuthProgressGIF");
				if (img.getAttribute("data-src-loaded") == undefined)
				{
					let src = img.getAttribute("data-src");
					img.setAttribute("src", src);
					img.setAttribute("data-src-loaded", "yes");
				}
			}
		}
		
		// 2018.08.30: Focus
		if (authState == AuthState.SHOW_SIGNIN)
			document.getElementById('ID_AuthUserI').focus();
		else if (authState == AuthState.SHOW_SIGNUP)
			document.getElementById('ID_AuthUserU').focus();
		else if (authState == AuthState.SHOW_CONFIRM)
			document.getElementById('ID_AuthConfC').focus();
			
		// 2018.12.07: Show help for some states
		let helpDlg = document.querySelector(".CL_AuthDialogHelp");
		var helpText = undefined;
		// Look through the table to find if there is a message for the current state
		for (var i = 0; i < authHelpTextList.length && helpText == undefined; i++)
		{
			if (authHelpTextList[i].states.includes(authState))
				helpText = authHelpTextList[i].msg;
		}
		
		// 2019.02.04: Determine if we need to show the password assistance
		var pswdAssistId = undefined;
		for (var pk = 0; pk < authPasswordElementList.length && pswdAssistId == undefined; pk++)
			if (authPasswordElementList[pk].show.includes(authState))
				pswdAssistId = authPasswordElementList[pk].id;
		
		// If no message, then hide the help box, otherwise show it with the message
		// 2019.01.30: Also use help box to show error messages
		// 2019.02.04: Also use for password assistance
		if (helpText == undefined && authErrorMsg == undefined && pswdAssistId == undefined)
		{
			helpDlg.classList.add("CL_AuthHideElement");
		}
		else
		{
			helpDlg.classList.remove("CL_AuthHideElement");
			var ht = document.querySelector(".CL_AuthDialogHelpText");
			ht.innerHTML = (helpText != undefined) ? helpText : "";
			if (helpText == undefined)
				ht.classList.add("CL_AuthHideElement");
			else
				ht.classList.remove("CL_AuthHideElement");
			
			var et = document.querySelector(".CL_AuthDialogErrorText");
			et.innerHTML = (authErrorMsg != undefined) ? authErrorMsg : "";
			if (authErrorMsg == undefined)
				et.classList.add("CL_AuthHideElement");
			else
				et.classList.remove("CL_AuthHideElement");

			let pswdAssist = document.querySelector(".CL_AuthDialogPasswordText");
			if (pswdAssistId != undefined)
			{
				pswdAssist.classList.remove("CL_AuthHideElement");
				AuthManager_ProvidePasswordFeedbackForID(pswdAssistId);
			}
			else
			{
				pswdAssist.classList.add("CL_AuthHideElement")
			}
		
		}	
		
		// 2019.02.07: Added call and routine
		AuthManager_AdjustUIForAuthenticationSource();
	}
	
	//------------------------------------------------------------------------------
	//	 Adjust UI For Authentication Source
	//
	//		Hide some buttons if the user is not authenticated by AWS,
	//		that is, if it is a Google SignIn
	//------------------------------------------------------------------------------
	var AuthManager_AdjustUIForAuthenticationSource = function()
	{
		try
		{
			var authIsAWS = (authUser == undefined) || (authUser.authenticatedBy == undefined);
			var authImageURL = (authUser != undefined) ? authUser.imageURL : undefined;
		
			var e = document.querySelector("#ID_AuthChangePswd");
			
			if (e == undefined)
				console.log("Can't find #ID_AuthChangePswd");
			else
			if (authIsAWS)
				e.classList.remove("CL_AuthHideElement");
			else
				e.classList.add("CL_AuthHideElement");
			
			
			var e = document.getElementById("ID_AuthUserImg");
			if (e != undefined)
			{
				if (authIsAWS || authImageURL == undefined)
				{
					e.classList.add("CL_AuthHideElement");
					e.removeAttribute('src');
				}
				else
				{
					e.classList.remove("CL_AuthHideElement");
					e.src = authImageURL;
				}
			}
		}
		catch (err)
		{
			console.log("AuthManager_AdjustUIForAuthenticationSource");
			console.log(err);
		}
	}

	//------------------------------------------------------------------------------
	//	ParseJWT
	//
	//	Not used, but keeping it around anyway
	//------------------------------------------------------------------------------
	const parseJwt = function(token)
	{
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} 
		catch (e) 
		{
			return null;
		}
	};
	
	//------------------------------------------------------------------------------
	//	Check Auth Status
	//
	//------------------------------------------------------------------------------
	var AuthManager_CheckAuthStatus = function()
	{
		Auth.currentAuthenticatedUser()
		.then(result => {
							authUser = result;
							AuthMgr_PopulateUsername();
							authState = AuthState.AUTHORIZED;
							AuthManager_AccountMgrSignedIn();
							AuthManager_MonitorForWakeFromSleep();
							AuthManager_UpdateUI();
							
							return Auth.currentUserCredentials();
						})
		.then(credentials => 
			{
				global.AWS.config.credentials = credentials;

				AuthManager_CacheCredentialsId(credentials);
				
				AuthManager_UpdateLastRelaunch();
				
				AuthManager_PerformSignInCallback();
			})
		.catch(err => 
			{ 
				/* Not signed in; do nothing */ 
				//console.log("Polygonia: Not signed-in.");
			})
		.then(() => 
				AuthManager_FinalizeInit()
			);
	}

	//------------------------------------------------------------------------------
	//	Google Init
	//		Calls startAuth after Sign in V2 finishes setting up.
	//------------------------------------------------------------------------------
	var AuthManager_Google_Init = function()
	{
		// 2019.06.09: Ad blocks can prevent the Google API from loading. Using
		// the try/catch to detect this.
		try {
			if (gapi != undefined)
				gapi.load('auth2', AuthManager_Google_InitSigninV2);
		}
		catch (err) {
			document.getElementById("ID_GoogleAuth").classList.add("CL_AuthHideElement");
			document.getElementById("ID_GoogleFailed").classList.remove("CL_AuthHideElement");
		}
	};
	
	//------------------------------------------------------------------------------
	//	Google Init Signin V2
	//		Initializes Signin v2 and sets up listeners.
	//		and renders the button
	//------------------------------------------------------------------------------
	var AuthManager_Google_InitSigninV2 = function()
	{
		authGoogleAuth2 = gapi.auth2.init({
			client_id: '879092337093-qfhr0qppkr7d6311kf3hattafca0b5f6.apps.googleusercontent.com',
			scope: 'email profile'});

		if (authGoogleAuth2.isSignedIn.get() == true)
			console.log("AuthManager_Google_InitSigninV2: google user signed-in");
			
		// Render the button
		//AuthManager_Google_RenderButton();
		AuthManager_Google_AttachSignIn("ID_CustomGoogleBtn");
		
		// Listen for sign-in state changes.
		//authGoogleAuth2.isSignedIn.listen(AuthManager_Google_SignInChanged);

		// Listen for changes to current user.
		//authGoogleAuth2.currentUser.listen(AuthManager_Google_UserChanged);

		// Sign in the user if they are currently signed in.
		//if (authGoogleAuth2.isSignedIn.get() == true)
		//	authGoogleAuth2.signIn();
	};


	//------------------------------------------------------------------------------
	//	Google Attach Sign In
	//		Attach the callbacks to the sign-in button
	//		This seems to be the only way to keep the sign in from happening
	//		automatically
	//------------------------------------------------------------------------------
	function AuthManager_Google_AttachSignIn(elementId)
	{
		var element = document.getElementById(elementId);
		
		authGoogleAuth2.attachClickHandler(element, {}, AuthManager_Google_SignInSuccess, AuthManager_Google_SignInFailure);
	}

	//------------------------------------------------------------------------------
	//	Google Render Button
	//		Render and configure the button
	//------------------------------------------------------------------------------
	/*
	var AuthManager_Google_RenderButton = function() 
	{
		gapi.signin2.render('ID_GoogleSignIn',
			{
				'scope': 'profile email',
				'width': 225,
				//'height': 40,
				'longtitle': true,
				'theme': 'light',
				'onsuccess': AuthManager_Google_SignInSuccess,
				'onfailure': AuthManager_Google_SignInFailure
			});
	}
	*/
	
	//------------------------------------------------------------------------------
	//	Google Sign In Success
	//		
	//------------------------------------------------------------------------------
	var AuthManager_Google_SignInSuccess = function(googleUser) 
	{
		/*
			var profile = googleUser.getBasicProfile();
			console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
			console.log('Name: ' + profile.getName());
			console.log('Given Name: ' + profile.getGivenName());
			console.log('Family Name: ' + profile.getFamilyName());
			console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
			console.log('Image: ' + profile.getImageUrl()); // This is null if the 'email' scope is not present.
		*/
	
		// Only authorize a google user if we are not already signed in	
		if (authUser == undefined)
			AuthManager_Google_Authorized(googleUser);
		else
			console.log("AuthManager_Google_SignInSuccess: Already signed in");
	}
	
	//------------------------------------------------------------------------------
	//	Google Sign In Failure
	//		
	//------------------------------------------------------------------------------
	var AuthManager_Google_SignInFailure = function(error) 
	{
		console.log("AuthManager_Google_SignInOnFailure");
		console.log(error);
	}

	//------------------------------------------------------------------------------
	//	Google Sign In Changed
	//		Listener method for sign-out live value.
	//		val: the updated signed out state.
	//------------------------------------------------------------------------------
	/*
	var AuthManager_Google_SignInChanged = function (val)
	{
		console.log("AuthManager_Google_SignInChanged: Sign In state changed to: ", val);
	};
	*/
	
	//------------------------------------------------------------------------------
	//	Google User Changed
	//		Listener method for when the user changes.
	//		googleUser: user the updated user.
	//------------------------------------------------------------------------------
	/*
	var AuthManager_Google_UserChanged = function (googleUser)
	{
		console.log("AuthManager_Google_UserChanged: User now: ", googleUser);
		
		//console.log("getId: ", googleUser.getId());
		//console.log("getGrantedScopes: ", googleUser.getGrantedScopes());
		//console.log("getAuthResponse: ", JSON.stringify(googleUser.getAuthResponse(), undefined, 2));
		
		console.log("userChanged", JSON.stringify(googleUser, undefined, 2));
	};
	*/
	
	//------------------------------------------------------------------------------
	//	Google Authorized
	//
	//------------------------------------------------------------------------------
	var AuthManager_Google_Authorized = function(googleUser)
	{
		let googleIdToken = googleUser.getAuthResponse().id_token;
        let expires_at = googleUser.getAuthResponse().expires_at;

        const profile = googleUser.getBasicProfile();
        let user = {
            username: profile.getName(),
            authenticatedBy: "google",
            imageURL: profile.getImageUrl(),
            attributes: { email: profile.getEmail() }
        };
        
		// 2020.07.06: Using Amplify.Hub to listen for signIn event
		Auth.federatedSignIn("google", { token: googleIdToken, expires_at }, user)
		/*
		.then(credentials => 
		{ 
			// If success, you will get the AWS credentials
			global.AWS.config.credentials = credentials;
			
			AuthManager_CacheCredentialsId(credentials);
			
			return Auth.currentAuthenticatedUser();
		})
		.then(user => 
		{
			// If success, the user object you passed in Auth.federatedSignIn
			authUser = user;
			AuthMgr_PopulateUsername();
			authState = AuthState.AUTHORIZED;
			AuthManager_AccountMgrSignedIn();
			AuthManager_MonitorForWakeFromSleep();
			AuthManager_UpdateUI();
			AuthManager_UpdateLastRelaunch();
			AuthManager_PerformSignInCallback();
		})
		*/
		.catch(e => 
		{
			console.log(e)
		});
	}

	//------------------------------------------------------------------------------
	//	Google Sign Out
	//
	//------------------------------------------------------------------------------
	var AuthManager_Google_SignOut = function()
	{
		var auth2 = gapi.auth2.getAuthInstance();
		
		auth2.signOut()
		.then(() => 
		{
			/* console.log("AuthManager_Google_SignOut completed."); */
		})
		.catch(error => console.log("AuthManager_Google_SignOut err: " + err));
	}
  
  	//------------------------------------------------------------------------------
	//	Report Session Status
	//
	//------------------------------------------------------------------------------
	var AuthManager_ReportSessionStatus = function()
	{
		Auth.currentSession()
		.then(result => {
							console.log("Session Status... ");
							console.log("idToken");
							console.log("  username: " + result.idToken.payload["cognito:username"]);
							console.log("  email: " + result.idToken.payload["email"]);
							console.log("accessToken");
							console.log("  username: " + result.accessToken.payload["username"]);
						})
		.catch(err => console.log("AuthManager_ReportSessionStatus err: " + err));
	}

	//------------------------------------------------------------------------------
	//	Sign In
	//
	//------------------------------------------------------------------------------
	var AuthManager_SignIn = function()
	{
		var username = document.getElementById('ID_AuthUserI').value;
		var password = document.getElementById('ID_AuthPswdI').value;
		
		// 2019.01.31: Check for empty fields
		username = username.trim();
		password = password.trim();
		
		if (username.length == 0 || password.length == 0)
		{
			authError = new Error("Please provide a username and password");
			setTimeout(() => AuthManager_ProcessAMEvent(AuthEvent.ERROR), 0);
		}
		else
		{		
			authPendingUsername = username;
		
			document.getElementById('ID_AuthPswdI').value = "";
	
			Auth.signIn(username, password)
				.then(user => 
					{
						// 2020.07.06: Now handled by Hub.Listen and state machine
						//authUser = user;
						//AuthManager_ProcessAMEvent(AuthEvent.SIGNEDIN);
					})
				.catch(err => 
					{
						authMgrLogError(err); 
						authError = err; 
						AuthManager_ProcessAMEvent(AuthEvent.ERROR);
					});	
		}
	}

	//------------------------------------------------------------------------------
	//	Sign Out
	//
	//------------------------------------------------------------------------------
	var AuthManager_SignOut = function()
	{
		Auth.signOut()
			.then(data => 
				{
					(data == undefined) ? authMgrLogInfo("logged out") : authMgrLogInfo("logged out: " + data);
					AuthManager_ProcessAMEvent(AuthEvent.SIGNEDOUT);
				})
			.catch(err => 
				{
					authMgrLogError(err);
					authError = err; 
					AuthManager_ProcessAMEvent(AuthEvent.ERROR);
				});
	}

	//------------------------------------------------------------------------------
	//	Sign Up
	//
	//------------------------------------------------------------------------------
	var AuthMgr_SignUp = function()
	{
		var username = document.getElementById('ID_AuthUserU').value;
		var email = document.getElementById('ID_AuthEmailU').value;
		var password = document.getElementById('ID_AuthPswdU').value;
		var acceptedStr = (Date.now() / 1000).toFixed().toString(); // dateTime in seconds
		
		
		// 2019.01.31: Check for empty fields
		username = username.trim();
		email = email.trim();
		password = password.trim();
		
		if (username.length == 0 || email.length == 0 || password.length == 0)
		{
			authError = new Error("Please provide a username, email, and password");
			setTimeout(() => AuthManager_ProcessAMEvent(AuthEvent.ERROR), 0);
		}
		else if (!AuthManager_ValidatePassword(password).pswdOK)
		{
			authError = new Error("Please enter an acceptable password");
			setTimeout(() => AuthManager_ProcessAMEvent(AuthEvent.ERROR), 0);
		}
		else
		{
			authPendingUsername = username;

			document.getElementById('ID_AuthPswdU').value = "";
	
			Auth.signUp({username:username, password:password, attributes:{email:email, 'custom:acceptedtos':acceptedStr}})
				.then(user => 
					{
						//authMgrLogInfo(user); 
						authUser = user;
						AuthManager_ProcessAMEvent(AuthEvent.START_CONFIRM);
					})
				.catch(err => 
					{
						authMgrLogError(err);
						authError = err; 
						AuthManager_ProcessAMEvent(AuthEvent.ERROR);
					});	
		}
	}

	//------------------------------------------------------------------------------
	//	Confirm Sign Up
	//
	//------------------------------------------------------------------------------
	var AuthMgr_ConfirmSignUp = function()
	{
		var code = document.getElementById('ID_AuthConfC').value;
	
		Auth.confirmSignUp(authPendingUsername, code)
			.then(data => 
				{
					//authMgrLogInfo(data); 
					AuthManager_ProcessAMEvent(AuthEvent.SIGNEDUP);
				})
			.catch(err => 
				{
					authMgrLogError(err); 
					authError = err; 
					AuthManager_ProcessAMEvent(AuthEvent.ERROR);
				});
	}

	//------------------------------------------------------------------------------
	//	Forgot
	//
	//------------------------------------------------------------------------------
	var AuthManager_Forgot = function()
	{
		var username = document.getElementById('ID_AuthUserF').value;
		//var password = document.getElementById('ID_AuthPswdI').value;
		
		authPendingUsername = username;
		
		Auth.forgotPassword(username)
			.then(data => 
				{
					authMgrLogInfo("AuthManager_Forgot [then] " + data);
					AuthManager_ProcessAMEvent(AuthEvent.START_FORGOTCONF);
				})
			.catch(err => 
				{
					authMgrLogError(err); 
					authError = err; 
					AuthManager_ProcessAMEvent(AuthEvent.ERROR);
				});	
	}

	//------------------------------------------------------------------------------
	//	Confirm Forgot Password
	//
	//------------------------------------------------------------------------------
	var AuthMgr_ForgotConfirm = function()
	{
		var code = document.getElementById('ID_AuthConfF').value;
		var password = document.getElementById('ID_AuthPswdF').value;
		
		Auth.forgotPasswordSubmit(authPendingUsername, code, password)
			.then(data => 
				{
					AuthManager_ProcessAMEvent(AuthEvent.DONE_FORGOT);
				})
			.catch(err => 
				{
					authMgrLogError(err); 
					authError = err; 
					AuthManager_ProcessAMEvent(AuthEvent.ERROR);
				});
	}
	
	//------------------------------------------------------------------------------
	//	Change Password
	//
	//------------------------------------------------------------------------------
	var AuthManager_ChangePassword = function()
	{
		var oldPassword = document.getElementById('ID_AuthPswdCO').value;
		var newPassword = document.getElementById('ID_AuthPswdCN').value;

		// 2019.02.04: Check for empty fields
		oldPassword = oldPassword.trim();
		newPassword = newPassword.trim();
		
		if (oldPassword.length == 0 || newPassword.length == 0)
		{
			authError = new Error("Please provide your old password and a password");
			setTimeout(() => AuthManager_ProcessAMEvent(AuthEvent.ERROR), 0);
		}
		else if (!AuthManager_ValidatePassword(newPassword).pswdOK)
		{
			authError = new Error("Please enter an acceptable new password");
			setTimeout(() => AuthManager_ProcessAMEvent(AuthEvent.ERROR), 0);
		}
		else
		{
			Auth.changePassword(authUser, oldPassword, newPassword)
				.then(data =>
					{
						authMgrLogInfo("AuthManager_ChangePassword [then] " + data);
						AuthManager_ProcessAMEvent(AuthEvent.DONE_CHANGEPSWD);
					})
				.catch(err =>
					{
						authMgrLogInfo("AuthManager_Forgot [catch] " + err);
						authMgrLogError(err); 
						authError = err; 
						AuthManager_ProcessAMEvent(AuthEvent.ERROR);
					});
		}
	}

	//------------------------------------------------------------------------------
	//	Delete Account
	//
	//------------------------------------------------------------------------------
	var AuthManager_DeleteAccount = function()
	{
		console.log("AuthManager_DeleteAccount");
		 
		if (authMgrconfig.analyticsRecord != undefined)
			authMgrconfig.analyticsRecord("ACCOUNT DELETED");
		
		// If an "account pre-delete" callback was provided, then use it to get a promise.
		// If, for some reason, the function returns undefined, then convert that into a 
		// resolved promise. Similarly, if no callback was provided, then use a resolved 
		// promise.
		var preDeletePromise;
		if (authMgrconfig.accountPreDelete != undefined)
		{
			preDeletePromise = authMgrconfig.accountPreDelete();
			if (preDeletePromise == undefined)
			{
				authMgrLogError("AuthManager_DeleteAccount: preDeletePromise returned undefined.");
				preDeletePromise = Promise.resolve(0);
			}
		}
		else
			preDeletePromise = Promise.resolve(0);
			
		// When the pre-delete promise is completed, regardless of the outcome, delete the user
		preDeletePromise
			.then(result => console.log("AuthManager_DeleteAccount preDeletePromise results: " + JSON.stringify(result, null, 2)))
			.catch(err => console.log("AuthManager_DeleteAccount preDeletePromise error: ", err, ", code: ", err.code, ", stack: ", err.stack))
			.finally(() =>
				{
					console.log("AuthManager_DeleteAccount preDeletePromise completed");
					
					var isGoogle = (authUser.authenticatedBy == "google");
					
					if (isGoogle)
					{
						// Do a sign out to clear the Auth info. Otherwise, there is nothing else to 
						// do when deleting account created with Google sign-in (as far as I know)
						Auth.signOut()
						.finally(() =>
							{
								AuthManager_ProcessAMEvent(AuthEvent.DONE_DELETEACCT);
							});
					}
					else
					{
						// Wrap with an exception handler, just in case.
						try {

							// Tell Amplify to delete the user
							authUser.deleteUser(error => 
								{
									if (error)
									{
										if (authMgrconfig.analyticsRecord != undefined)
											authMgrconfig.analyticsRecord("ERROR DELETING ACCOUNT");
									
										authMgrLogError("AuthManager_DeleteAccount [finally] error:" + JSON.stringify(error));
									}
							
									AuthManager_ProcessAMEvent(AuthEvent.DONE_DELETEACCT);
								});
						}
						catch (err) {
							if (authMgrconfig.analyticsRecord != undefined)
								authMgrconfig.analyticsRecord("EXCEPTION DELETING ACCOUNT");
						
							authMgrLogError("AuthManager_DeleteAccount [finally exception] error:" + JSON.stringify(error));
							AuthManager_ProcessAMEvent(AuthEvent.DONE_DELETEACCT);
						}
					}
				});
	}	

	//------------------------------------------------------------------------------
	//	Start Sign Out
	//		2019.02.27: Factored out of state machine so it can be called from
	//		more states
	//------------------------------------------------------------------------------
	var AuthManager_StartSignOut = async function()
	{
		// 2019.02.07: Handle google signOut
		var isGoogle = (authUser.authenticatedBy == "google");
		
		AuthManager_CloseDialogs();
		var result = await AuthManager_PerformPreSignOutCallback();
		authUser = undefined;
		authCredentialsId = undefined;
		AuthManager_AccountMgrReset();
		AuthManager_ResetMonitorWakeForSleep();
		AuthMgr_PopulateUsername();
		if (isGoogle)
			AuthManager_Google_SignOut();
		AuthManager_SignOut();
		AuthManager_PerformSignOutCallback();
	}
	
	//------------------------------------------------------------------------------
	//	Get Username
	//
	//------------------------------------------------------------------------------
	var AuthMgr_GetUsername = function()
	{
		return (authUser != undefined && authUser.username != undefined) ? authUser.username : "";
	}

	//------------------------------------------------------------------------------
	//	Populate Username
	//
	//------------------------------------------------------------------------------
	var AuthMgr_PopulateUsername = function()
	{
		var e = document.getElementById("ID_AuthUser");
		if (e != undefined)
			e.innerHTML = AuthMgr_GetUsername();
		else
			authMgrLogDebug("AuthMgr_PopulateUsername: missing ID_AuthUser");
	}
	
	//------------------------------------------------------------------------------
	//	Clear Error Msg
	//
	//------------------------------------------------------------------------------
	var AuthMgr_ClearErrorMsg = function()
	{
		authErrorMsg = undefined;
	}
	
	//------------------------------------------------------------------------------
	//	Populate Error
	//
	//------------------------------------------------------------------------------
	var AuthMgr_PopulateError = function(useHelpPanel = true)
	{
		var msg = "Unknown error";
		
		// Get the error string
		if (authError == undefined)
			msg = "Unknown error";
		else if (typeof authError === 'object' && authError.message != undefined)
			msg = authError.message
		else if (typeof authError === 'string')
			msg = authError;
		
		// Convert the string into something more meaningful if we can or for
		// specific strings we expect
		if (msg.includes("DUPLICATE_EMAIL"))
			msg = "A user with the same email address already exists."
		// 2020.07.29: Prevent errors saying 'validation' or 'constraint'
		else if (msg.includes("validation") || msg.includes("regular expression"))
		{
			if (msg.includes("userName"))
				msg = "The username was not accepted. Please try your email address.";
			else
				msg = "An error has occurred. Please try again."
		}
		
		if (useHelpPanel)
		{
			authErrorMsg = msg;
		}
		else
		{
			var e = document.getElementById("ID_AuthErrorText");
			if (e != undefined)
				e.innerHTML = msg;
			else
				authMgrLogDebug("AuthMgr_PopulateUsername: missing ID_AuthErrorText");
		}
	}
	
	//------------------------------------------------------------------------------
	//	Report Attributes
	//
	//------------------------------------------------------------------------------
	var AuthManager_ReportAttributes = function()
	{
		// Dump the attributes to the console.
		// This is just for testing
		//
		
		Auth.userAttributes(authUser)
		  .then(attributes => 
		  		{
		  			console.log("Attributes for '" + authUser.username + "'");
		  			for (var i = 0; i < attributes.length; i++)
		  			{
		  				var name = attributes[i].Name;
		  				var value = attributes[i].Value;
			  			console.log("  " + name + ": " + value);
			  			
						if (name === 'custom:acceptedtos')
						{
							var acceptedDateTime = new Date(parseInt(value) * 1000);
							console.log("    " + acceptedDateTime.toUTCString());
						}
		  			}
		  		})
		  .catch(err => 
		  		{
		  			console.log("userAttributes error: " + err); 
		  		});
  	}

	//------------------------------------------------------------------------------
	//	Process Authentication Manager Event
	//
	//	Process events according to the current state
	//------------------------------------------------------------------------------
	var AuthManager_ProcessAMEvent = async function(authMgrEvt)
	{
		var nextState = undefined;
		
		AuthMgr_ClearErrorMsg();
		
		authMgrLogInfo("AuthManager_ProcessAMEvent: state:" + authState + ", event:" + authMgrEvt);

		if (authState == AuthState.UNAUTHORIZED)
		{
			if (authMgrEvt == AuthEvent.START_SIGNIN)
				nextState = AuthState.SHOW_SIGNIN;
			else if (authMgrEvt == AuthEvent.START_SIGNUP)
				nextState = AuthState.SHOW_SIGNUP;
		}
		else if (authState == AuthState.AUTHORIZED)
		{
			if (authMgrEvt == AuthEvent.START_SIGNOUT)
			{
				await AuthManager_StartSignOut();
				nextState = AuthState.PENDING_UNAUTH;
			}
			else if (authMgrEvt == AuthEvent.START_CHANGEPSWD)
			{
				nextState = AuthState.SHOW_CHANGEPSWD;
			}
			else if (authMgrEvt == AuthEvent.START_DELETEACCT)
			{
				nextState = AuthState.SHOW_DELETEACCT;
			}
		}
		else if (authState == AuthState.SHOW_SIGNUP)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.UNAUTHORIZED;
			else if (authMgrEvt == AuthEvent.SIGNUP)
			{
				AuthMgr_SignUp();
				nextState = AuthState.PENDING_CONF;
			}
			else if (authMgrEvt == AuthEvent.START_SIGNIN)
				nextState = AuthState.SHOW_SIGNIN;
		}
		else if (authState == AuthState.PENDING_CONF)
		{
			if (authMgrEvt == AuthEvent.START_CONFIRM)
			{
				nextState = AuthState.SHOW_CONFIRM;
			}
			else if (authMgrEvt == AuthEvent.ERROR)
			{
				AuthMgr_PopulateError();
				nextState = AuthState.SHOW_SIGNUP;
			}
		}
		else if (authState == AuthState.SHOW_CONFIRM)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.UNAUTHORIZED;
			else if (authMgrEvt == AuthEvent.CONFIRM)
			{
				AuthMgr_ConfirmSignUp();
				nextState = AuthState.PENDING_SIGNUP;
			}
		}
		else if (authState == AuthState.PENDING_SIGNUP)
		{
			if (authMgrEvt == AuthEvent.SIGNEDUP)
			{
				// 2018.09.04: After confirming sign-up, the user must still sign-in
				//AuthMgr_PopulateUsername();
				//AuthManager_PerformSignInCallback();
				nextState = AuthState.SHOW_SIGNIN;
			}
			else if (authMgrEvt == AuthEvent.ERROR)
			{
				AuthMgr_PopulateError();
				nextState = AuthState.SHOW_CONFIRM;
			}
		}
		else if (authState == AuthState.SHOW_SIGNIN)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.UNAUTHORIZED;
			else if (authMgrEvt == AuthEvent.START_FORGOT)
				nextState = AuthState.SHOW_FORGOT;
			else if (authMgrEvt == AuthEvent.START_SIGNUP)
				nextState = AuthState.SHOW_SIGNUP;
			else if (authMgrEvt == AuthEvent.SIGNIN)
			{
				AuthManager_SignIn();
				nextState = AuthState.PENDING_SIGNIN;
			}
			else if (authMgrEvt == AuthEvent.SIGNIN_FED) // 2020.07.06: Added
				nextState = AuthState.PENDING_SIGNIN;
		}
		else if (authState == AuthState.PENDING_SIGNIN)
		{
			if (authMgrEvt == AuthEvent.SIGNEDIN)
			{
				//console.log("%cCognito Sign In Success%c", "color:green;", "color:initial;");
				Auth.currentAuthenticatedUser()
				.then(user =>
					{
						authUser = user;
						AuthMgr_PopulateUsername();
						AuthManager_UpdateUI(); // 2020.07.27: Adjust the UI now, since this is a promise and can happen later
					 })
				.catch(error =>
					{
						console.log("unable to get currentAuthenticatedUser for cognito signin", error);
					});

				nextState = AuthState.AUTHORIZED;
				AuthManager_AccountMgrSignedIn();
				AuthManager_MonitorForWakeFromSleep();

				Auth.currentUserCredentials()
					.then(credentials =>
						{
							global.AWS.config.credentials = credentials;

							AuthManager_CacheCredentialsId(credentials);
							AuthManager_UpdateLastLogin();
							AuthManager_PerformSignInCallback();
						})
					.catch(error =>
						{
							console.log("unable to get credentials for cognito signin", error);
						});
			}
			else if (authMgrEvt == AuthEvent.SIGNEDIN_FED)
			{
				//console.log("%cFederated Sign In Success%c", "color:green;", "color:initial;");
				Auth.currentAuthenticatedUser()
				.then(user => 
					{
						authUser = user;
						AuthMgr_PopulateUsername();
						AuthManager_UpdateUI(); // 2020.07.27: Adjust the UI now, since this is a promise and can happen later
					 })
				.catch(error =>
					{
						console.log("unable to get currentAuthenticatedUser for federated signin", error);
					});
					
				nextState = AuthState.AUTHORIZED;
				AuthManager_AccountMgrSignedIn();
				AuthManager_MonitorForWakeFromSleep();

				Auth.currentUserCredentials()
					.then(credentials =>
						{
							global.AWS.config.credentials = credentials;

							AuthManager_CacheCredentialsId(credentials);
							AuthManager_UpdateLastLogin();
							AuthManager_PerformSignInCallback();
						})
					.catch(error =>
						{
							console.log("unable to get credentials for federated signin", error);
						});
			}
			else if (authMgrEvt == AuthEvent.ERROR)
			{
				if (authError != undefined && typeof authError === 'object' && authError.code != undefined &&  authError.code === 'UserNotConfirmedException')
				{
					nextState = AuthState.SHOW_CONFIRM;
				}
				else
				{
					AuthMgr_PopulateError();
					nextState = AuthState.SHOW_SIGNIN;
				}
			}
		}
		else if (authState == AuthState.SHOW_FORGOT)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.UNAUTHORIZED;
			else if (authMgrEvt == AuthEvent.START_SIGNIN)
				nextState = AuthState.SHOW_SIGNIN;
			else if (authMgrEvt == AuthEvent.FORGOT)
			{
				AuthManager_Forgot();
				nextState = AuthState.PENDING_FORGOT;
			}
			else if (authMgrEvt == AuthEvent.START_SIGNOUT)
			{
				await AuthManager_StartSignOut();
				nextState = AuthState.PENDING_UNAUTH;
			}
		}
		else if (authState == AuthState.PENDING_FORGOT)
		{
			if (authMgrEvt == AuthEvent.START_FORGOTCONF)
				nextState = AuthState.SHOW_FORGOTCONFIRM;
			else if (authMgrEvt == AuthEvent.ERROR)
			{
				AuthMgr_PopulateError();
				nextState = AuthState.SHOW_FORGOT;
			}
			else if (authMgrEvt == AuthEvent.START_SIGNOUT)
			{
				await AuthManager_StartSignOut();
				nextState = AuthState.PENDING_UNAUTH;
			}
		}
		else if (authState == AuthState.SHOW_FORGOTCONFIRM)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.UNAUTHORIZED;
			else if (authMgrEvt == AuthEvent.FORGOTCONFIRM)
			{
				AuthMgr_ForgotConfirm();
				nextState = AuthState.PENDING_FORGOTCONFIRM;
			}
		}
		else if (authState == AuthState.PENDING_FORGOTCONFIRM)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.UNAUTHORIZED;
			else if (authMgrEvt == AuthEvent.DONE_FORGOT)
			{
				nextState = AuthState.SHOW_SIGNIN;
			}
		}
		else if (authState == AuthState.SHOW_CHANGEPSWD)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.AUTHORIZED;
			else if (authMgrEvt == AuthEvent.CHANGEPSWD)
			{
				AuthManager_ChangePassword();
				nextState = AuthState.PENDING_CHANGEPSWD;
			}
			else if (authMgrEvt == AuthEvent.START_SIGNOUT)
			{
				await AuthManager_StartSignOut();
				nextState = AuthState.PENDING_UNAUTH;
			}
		}
		else if (authState == AuthState.PENDING_CHANGEPSWD)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.AUTHORIZED;
			else if (authMgrEvt == AuthEvent.DONE_CHANGEPSWD)
				nextState = AuthState.AUTHORIZED;
			else if (authMgrEvt == AuthEvent.ERROR)
			{
				AuthMgr_PopulateError();
				nextState = AuthState.SHOW_CHANGEPSWD;
			}
			else if (authMgrEvt == AuthEvent.START_SIGNOUT)
			{
				await AuthManager_StartSignOut();
				nextState = AuthState.PENDING_UNAUTH;
			}
		}
		else if (authState == AuthState.SHOW_DELETEACCT)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.AUTHORIZED;
			else if (authMgrEvt == AuthEvent.DELETEACCT)
			{
				nextState = AuthState.CONFIRM_DELETEACCT;
			}
			else if (authMgrEvt == AuthEvent.START_SIGNOUT)
			{
				await AuthManager_StartSignOut();
				nextState = AuthState.PENDING_UNAUTH;
			}
		}
		else if (authState == AuthState.CONFIRM_DELETEACCT)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.AUTHORIZED;
			else if (authMgrEvt == AuthEvent.CONFIRMED_DELETEACCT)
			{
				var isGoogle = (authUser.authenticatedBy == "google");
				
				AuthManager_AccountMgrReset();
				AuthManager_ResetMonitorWakeForSleep();
				if (isGoogle)
					AuthManager_Google_SignOut();
				AuthManager_DeleteAccount();
				nextState = AuthState.PENDING_DELETEACCT;
			}
			else if (authMgrEvt == AuthEvent.START_SIGNOUT)
			{
				await AuthManager_StartSignOut();
				nextState = AuthState.PENDING_UNAUTH;
			}
		}
		else if (authState == AuthState.PENDING_DELETEACCT)
		{
			//if (authMgrEvt == AuthEvent.DISMISS)
			//	nextState = AuthState.AUTHORIZED;
			//else 
			if (authMgrEvt == AuthEvent.DONE_DELETEACCT)
			{
				authUser = undefined;
				AuthMgr_PopulateUsername();
				AuthManager_PerformSignOutCallback();
				nextState = AuthState.UNAUTHORIZED;
			}
			else if (authMgrEvt == AuthEvent.ERROR)
			{
				AuthMgr_PopulateError(false);
				nextState = AuthState.ERROR;
			}
		}
		else if (authState == AuthState.PENDING_UNAUTH)
		{
			if (authMgrEvt == AuthEvent.SIGNEDOUT)
			{
				nextState = AuthState.UNAUTHORIZED;
			}
			else if (authMgrEvt == AuthEvent.ERROR)
			{
				AuthMgr_PopulateError(false);
				nextState = AuthState.ERROR;
			}
		}
		else if (authState == AuthState.ERROR)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.UNAUTHORIZED;
		}
		else if (authState == AuthState.ERRORAUTH)
		{
			if (authMgrEvt == AuthEvent.DISMISS)
				nextState = AuthState.AUTHORIZED;
		}
		else
		{
			authMgrLogDebug("AuthManager_ProcessAMEvent: State not handled:" + authState);
		}
		
		if (nextState == undefined)
		{
			authMgrLogDebug("Illegal event (" + authMgrEvt + ") received for state:" + authState);
		}
		else if (nextState != authState)
		{
			authState = nextState;
			AuthManager_UpdateUI()
			//authMgrLogDebug("AuthManager_ProcessAMEvent: New state:" + authState);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Accept Terms Checkbox Changed
	//
	//	Enable or disable the sign up button according to the state of the
	//	"accept terms" checkbox
	//------------------------------------------------------------------------------
	var AuthManager_AcceptTermsCheckboxChanged = function(checkbox)
	{
		//console.log("AuthManager_AcceptTermsCheckboxChanged");
		//console.log(checkbox.id + ": " + checkbox.checked);
		
		var doSignUpButton = document.getElementById("ID_AuthBtnU");
		doSignUpButton.disabled = !checkbox.checked;
	}

	//------------------------------------------------------------------------------
	//	KeyDown handler
	//
	//	Escape key will dismiss the dialog.
	//------------------------------------------------------------------------------
	var AuthManager_KeyDown = function(evt)
	{
		var processed = false;
		
		// Dismiss the dialog if escape key is pressed
		if (evt.keyCode == 27)
		{
			//console.log("Escape key. id: " + this.id + ", evt.target.id: " + evt.target.id);
			AuthManager_ProcessAMEvent(AuthEvent.DISMISS);
			processed = true;
		}
		// Process the Enter key intelligently
		else if (evt.keyCode == 13)
		{
			processed = true;
			evt.stopPropagation();
			evt.preventDefault();

			//console.log("Enter key. id: " + this.id + ", evt.target.id: " + evt.target.id);
			var id = evt.target.id;
			
			if (id == "ID_AuthPswdI")
				AuthManager_HandleAMEvent(AuthEvent.SIGNIN);
			else if (id == "ID_AuthPswdU" || id == "ID_AuthAgreeTC")
				AuthManager_HandleAMEvent(AuthEvent.SIGNUP);
			else if (id == "ID_AuthConfC")
				AuthManager_HandleAMEvent(AuthEvent.CONFIRM);
		}
		else
		{
			//console.log("AuthManager_KeyDown: keyCode: " + evt.keyCode + ", id: " + this.id + ", evt.target.id: " + evt.target.id);
		}

		// 2018.12.07: Event processors should return true is event should continue to processed, false otherwise
		return !processed; 
	}
	
	//------------------------------------------------------------------------------
	//	Handle Authorization Manager event
	//
	//		This does any necessary pre-flight before passing the event to the 
	//		state machine.
	//------------------------------------------------------------------------------
	var AuthManager_HandleAMEvent = function(amEvt)
	{
		// Detect the case where the "I agree to the terms and conditions" checkbox is 
		// not checked but the "Sign Up" button is enabled. If this happens, we display an alert.		
		if (amEvt == AuthEvent.SIGNUP && !document.getElementById("ID_AuthAgreeTC").checked)
			AuthManager_RejectSignup();
			
		// Otherwise process the event
		else
			AuthManager_ProcessAMEvent(amEvt);
	}	

	//------------------------------------------------------------------------------
	//	Button Handler
	//
	//------------------------------------------------------------------------------
	var AuthManager_ButtonHandler = function(evt)
	{
		var id = this.id;
		var amEvt = this.authMgrEvt;
		
		// Keep anything that might propagate to the background from being handled
		// as a background click
		if (this.id == "ID_AuthBackground" && evt.target.id != "ID_AuthBackground")
			return;
		
		if (this.id == "ID_AuthSettingsBackground" && evt.target.id != "ID_AuthSettingsBackground")
			return;
			
		//authMgrLogInfo("AuthManager_ButtonHandler, state:" + authState + ", evt.id:" + id + ", authMgrEvt:" + amEvt);
		
		// If we have an authorization manager event, pass it to the handler
		if (amEvt != undefined)
			AuthManager_HandleAMEvent(amEvt);
		// Update the UI if the "I agree" checkbox is checked or not
		else if (id == "ID_AuthAgreeTC")
			AuthManager_AcceptTermsCheckboxChanged(this);
	
		// SETTINGS support...
		else if (id == "ID_AuthUser")
			AuthManager_ShowAuthSettings(true);
		else if (id == "ID_AuthSettingsClose" || id == "ID_AuthSettingsBackground")
			AuthManager_ShowAuthSettings(false);
		else if (id == "ID_AuthChangePswd")
			AuthManager_ShowChangePassword();
		else if (id == "ID_AuthBtnDeleteAcct")
			AuthManager_ShowDeleteAccount();
		
		// SUBSCRIPTION PLAN support...
		else if (id == "ID_ShowPlanInfo")
			AuthManager_ShowSubscriptionInfo();
		else if (id == "ID_CancelPlan" || id == "ID_RestorePlan")
			AuthManager_HandleCancelOrRestorePlan(id == "ID_CancelPlan");
		else if (id == "ID_BillingPortal")
			AuthManager_ShowBillingPortal();
			
		// Report unexpected events
		else
			authMgrLogDebug("AuthManager_ButtonHandler: No authMgrEvt and no actionable button press, state:" + authState + ", evt.id:" + id);
		
	}
	
	//------------------------------------------------------------------------------
	//	Update Auth Settings
	//
	//------------------------------------------------------------------------------
	var AuthManager_UpdateAuthSettings = function(show)
	{
		var idList = ["ID_AuthSettingsUser", "ID_AuthUserDAC", "ID_AuthUserDA"]; 
		
		
		for (var i = 0; i < idList.length; i++)
		{
			var userElement = document.getElementById(idList[i]);
			if (userElement != undefined)
				userElement.innerHTML = AuthMgr_GetUsername();
			else
				console.log("AuthManager_UpdateAuthSettings: '" + idList[i] + "' is not found");
		}
		
		// Last login
		var lastLoginStr = AuthManager_GetLastLoginStr();
		lastLoginStr = (lastLoginStr == undefined) ? "" : ("Last login: " +  lastLoginStr);
		var infoElement = document.getElementById("ID_AuthSettingsInfo");
		infoElement.innerHTML = lastLoginStr;
		
		// Get settings info from callback
		var settingsInfo = AuthManager_GetSettingsInfo();
		
		// Subscription plan
		var planElement = document.getElementById("ID_AuthSettingsPlan");
		planElement.innerHTML = (settingsInfo != undefined) ? settingsInfo.summary : "";
	
		// Hide or show "Cancel Plan" and "Restore Plan" buttons as required
		var rb = document.getElementById("ID_RestorePlan");
		if (settingsInfo != undefined && settingsInfo.showRestoreButton)
			rb.classList.remove("CL_AuthHideElement");
		else
			rb.classList.add("CL_AuthHideElement");

		var cb = document.getElementById("ID_CancelPlan");
		if (settingsInfo != undefined && settingsInfo.showCancelButton)
			cb.classList.remove("CL_AuthHideElement");
		else
			cb.classList.add("CL_AuthHideElement");

		// 2020.07.14: Show billing portal button
		var bp = document.getElementById("ID_BillingPortal");
		if (settingsInfo != undefined && settingsInfo.showPortalButton)
			bp.classList.remove("CL_AuthHideElement");
		else
			bp.classList.add("CL_AuthHideElement");
	}

	//------------------------------------------------------------------------------
	//	Show Auth Settings
	//
	//------------------------------------------------------------------------------
	var AuthManager_ShowAuthSettings = function(show)
	{
		var bg = document.getElementById("ID_AuthSettingsBackground");
		bg.style.display = show ? "block" : "none";
		
		if (show)
			AuthManager_UpdateAuthSettings();
			
	}

	//------------------------------------------------------------------------------
	//	Close Dialogs
	//		Dismiss any dialogs or popups
	//------------------------------------------------------------------------------
	var AuthManager_CloseDialogs = function()
	{
		AuthManager_ShowSubscriptionInfo(false);
		AuthManager_DismissAccountInfoDialogs();
		AuthManager_ShowAuthSettings(false);
	}

	//------------------------------------------------------------------------------
	//	Show Change Password
	//
	//------------------------------------------------------------------------------
	var AuthManager_ShowChangePassword = function()
	{
		AuthManager_ShowAuthSettings(false);
		
		AuthManager_HandleAMEvent(AuthEvent.START_CHANGEPSWD);
	}

	//------------------------------------------------------------------------------
	//	Show Delete Account
	//
	//------------------------------------------------------------------------------
	var AuthManager_ShowDeleteAccount = function()
	{
		AuthManager_ShowAuthSettings(false);
		
		AuthManager_HandleAMEvent(AuthEvent.START_DELETEACCT);
	}

	//------------------------------------------------------------------------------
	//	Reject Sign Up
	//
	//------------------------------------------------------------------------------
	var AuthManager_RejectSignup = function()
	{
		if (authMgrconfig.analyticsRecord != undefined)
			authMgrconfig.analyticsRecord("SIGNUP_REJECTED");
			
		window.alert("Please accept the terms and conditions.");
	}

	//------------------------------------------------------------------------------
	//	Perform PreSignOut Callback
	//
	//------------------------------------------------------------------------------
	var AuthManager_PerformPreSignOutCallback = async function()
	{
		var result = 1;
		
		if (authMgrconfig.authPreSignOut != undefined)
			result = await authMgrconfig.authPreSignOut();
			
		//console.log("AuthManager_PerformPreSignOutCallback result: " + result);
	}
	
	//------------------------------------------------------------------------------
	//	Perform Sign Out Callback
	//
	//------------------------------------------------------------------------------
	var AuthManager_PerformSignOutCallback = function()
	{
		if (authMgrconfig.authSignOut != undefined)
			authMgrconfig.authSignOut();
	}
	
	//------------------------------------------------------------------------------
	//	Perform Sign In Callback
	//
	//------------------------------------------------------------------------------
	var AuthManager_PerformSignInCallback = function()
	{
		if (authMgrconfig.authSignIn != undefined)
			authMgrconfig.authSignIn();
	}
	
	//------------------------------------------------------------------------------
	//	Clear All Fields
	//
	//------------------------------------------------------------------------------
	var AuthManager_ClearAllFields = function()
	{
		var dlg = document.getElementById("ID_AuthDialog");
		var fields = (dlg != undefined) ? dlg.getElementsByTagName("input") : [];
		for (var i = 0; i < fields.length; i++)
		{
			var e = fields[i];
			//console.log(e.id + ", " + e.type + ", '" + e.value + "'");

			if (e.type == "checkbox")
				e.checked = false;
			else
				e.value = "";
		}
		
		// 2019.01.30: Clear error msg
		AuthMgr_ClearErrorMsg();
	}
	
	//------------------------------------------------------------------------------
	//	Create Account (Account Manager)
	//
	//------------------------------------------------------------------------------
	/*
		-- We don't really need to call this. 
		-- The first cal to "UpdateLastLogin" will take care of this if no account if found
	var AuthManager_AccountMgrCreateAccount = function()
	{
		if (authMgrconfig.accountManager != undefined && authMgrconfig.accountManager.CreateAccount != undefined)
			authMgrconfig.accountManager.CreateAccount();
	}
	*/
	
	//------------------------------------------------------------------------------
	//	Reset (Account Manager)
	//
	//------------------------------------------------------------------------------
	var AuthManager_AccountMgrReset = function()
	{
		if (authMgrconfig.accountManager != undefined && authMgrconfig.accountManager.Reset != undefined)
			authMgrconfig.accountManager.Reset();
	}
	
	//------------------------------------------------------------------------------
	//	Signed-In (Account Manager)
	//
	//------------------------------------------------------------------------------
	var AuthManager_AccountMgrSignedIn = function()
	{
		if (authMgrconfig.accountManager != undefined && authMgrconfig.accountManager.SignedIn != undefined)
			authMgrconfig.accountManager.SignedIn();
	}
	
	//------------------------------------------------------------------------------
	//	Update Last Login (Account Manager)
	//
	//------------------------------------------------------------------------------
	var AuthManager_UpdateLastLogin = function()
	{
		if (authMgrconfig.accountManager != undefined && authMgrconfig.accountManager.UpdateLastLogin != undefined)
			authMgrconfig.accountManager.UpdateLastLogin();
	}
	
	//------------------------------------------------------------------------------
	//	Update Last Relaunch (Account Manager)
	//
	//------------------------------------------------------------------------------
	var AuthManager_UpdateLastRelaunch = function()
	{
		if (authMgrconfig.accountManager != undefined && authMgrconfig.accountManager.UpdateLastRelaunch != undefined)
			authMgrconfig.accountManager.UpdateLastRelaunch();
		else if (authMgrconfig.accountManager != undefined && authMgrconfig.accountManager.UpdateLastLogin != undefined)
			authMgrconfig.accountManager.UpdateLastLogin();
	}
	

	//------------------------------------------------------------------------------
	//	Get Last Login String (Account Manager)
	//
	//------------------------------------------------------------------------------
	var AuthManager_GetLastLoginStr = function()
	{
		var lastLoginStr = undefined;
		
		if (authMgrconfig.accountManager != undefined && authMgrconfig.accountManager.GetLastLogin != undefined)
		{
			var lastLogin = authMgrconfig.accountManager.GetLastLogin();
			
			if (lastLogin != 0)
			{
				var date = new Date(lastLogin);
				lastLoginStr = date.toLocaleString();
			}
		}
		return lastLoginStr;
	}
	
	//------------------------------------------------------------------------------
	//	Get Settings Info (Account Info)
	//
	//------------------------------------------------------------------------------
	var AuthManager_GetSettingsInfo = function()
	{
		var settingsInfo = undefined;
		
		if (authMgrconfig.accountInfo != undefined && authMgrconfig.accountInfo.GetSettingsInfo != undefined)
			settingsInfo = authMgrconfig.accountInfo.GetSettingsInfo();
		
		return settingsInfo;
	}

	
	//------------------------------------------------------------------------------
	//	Show Billing Portal (Account Info)
	//
	//------------------------------------------------------------------------------
	var AuthManager_ShowBillingPortal = function(showIt = true)
	{
		if (authMgrconfig.accountInfo != undefined && authMgrconfig.accountInfo.ShowBillingPortal != undefined)
			authMgrconfig.accountInfo.ShowBillingPortal(showIt);
	}

	//------------------------------------------------------------------------------
	//	Show Subscription Info (Account Info)
	//
	//------------------------------------------------------------------------------
	var AuthManager_ShowSubscriptionInfo = function(showIt = true)
	{
		if (authMgrconfig.accountInfo != undefined && authMgrconfig.accountInfo.ShowSubscriptionInfo != undefined)
			authMgrconfig.accountInfo.ShowSubscriptionInfo(showIt);
	}

	//------------------------------------------------------------------------------
	//	DismissDialogs (Account Info)
	//
	//------------------------------------------------------------------------------
	var AuthManager_DismissAccountInfoDialogs = function(showIt = true)
	{
		if (authMgrconfig.accountInfo != undefined && authMgrconfig.accountInfo.DismissDialogs != undefined)
			authMgrconfig.accountInfo.DismissDialogs();
	}

	//------------------------------------------------------------------------------
	//	Handle Cancel or Restore Plan (Account Info)
	//
	//------------------------------------------------------------------------------
	var AuthManager_HandleCancelOrRestorePlan = function(doCancel)
	{
		if (authMgrconfig.accountInfo != undefined && authMgrconfig.accountInfo.DoCancelOrRestore != undefined)
			authMgrconfig.accountInfo.DoCancelOrRestore(doCancel);
	}
	
	//------------------------------------------------------------------------------
	//	Get Current User Info (async/await)
	//------------------------------------------------------------------------------
	var AuthManager_GetCurrentUserInfo = async function()
	{
		var userInfo = undefined;
		
		try {
			var currentUser = await Auth.currentUserInfo();
			
			userInfo = {};
			userInfo.userId = currentUser.id.slice(0);
			userInfo.username = currentUser.username.slice(0);
			userInfo.email = currentUser.attributes.email.slice(0);
		}
		catch (err) {
			console.log("AuthManager_GetCurrentUserInfo error", err);
		}
		
		//console.log("AuthManager_GetCurrentUserInfo: ", JSON.stringify(userInfo, null, 2));
		
		/*
		Auth.currentUserInfo()
			.then(currentUser => 
				{ 
					console.log("");
					console.log("id: " + currentUser.id);
					console.log("username: " + currentUser.username);
					console.log("email: " + currentUser.attributes.email);
				})
			.catch(err => console.log("Auth.currentUserInfo err: " + err));
		*/
		
		return userInfo;
	}


	//------------------------------------------------------------------------------
	//	Is Authorized
	//------------------------------------------------------------------------------
	var AuthManager_IsAuthorized = function()
	{
		return (authState == AuthState.AUTHORIZED);
	}
	
	//------------------------------------------------------------------------------
	//	Auth Test
	//------------------------------------------------------------------------------
	var AuthManager_AuthTest = function()
	{
		/*
		console.log("Auth Test");
		console.log("Test: Performing sign out."); 
		AuthManager_HandleAMEvent(AuthEvent.START_SIGNOUT);
		*/
	}
	
	
	//------------------------------------------------------------------------------
	//	Public API
	//
	//------------------------------------------------------------------------------
	return {
		Init:					AuthManager_Init,
		ReportSessionStatus:	AuthManager_ReportSessionStatus,
		GetCurrentUserInfo:		AuthManager_GetCurrentUserInfo,
		IsAuthorized:			AuthManager_IsAuthorized,
		StartSignUp:			AuthManager_StartSignUp,
		UpdateAuthSettings:		AuthManager_UpdateAuthSettings,
		Google_SignInSuccess:	AuthManager_Google_SignInSuccess,
		HandleFocusChange:		AuthManager_HandleFocusChange,
		AuthTest:				AuthManager_AuthTest
	}
	
}());


//------------------------------------------------------------------------------
//	Export for public access
//
//------------------------------------------------------------------------------

//function AuthTest() { AuthManager.AuthTest(); }
//global.AuthTest = AuthTest;

export { AuthManager };
