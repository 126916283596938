import { Transform, MathUtil, NORMAL_TOLERANCE } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { ScreenDesigner } from "./ScreenDesigner.js";
import { ScreenGenerator } from "./ScreenDesignGenerator.js";
import { PolygonListRenderer } from "./ScreenRenderer.js";

/*-----------------------------------------------*
 * Other Utility Functions
 *-----------------------------------------------*/
function getMousePos(aCanvas, evt) {

	var rect = aCanvas.getBoundingClientRect();
	var mouseX = (evt.changedTouches ? evt.changedTouches[0].clientX : evt.clientX) - rect.left; //this.offsetLeft;
	var mouseY = (evt.changedTouches ? evt.changedTouches[0].clientY : evt.clientY) - rect.top; //this.offsetTop;
	var insetX = 1; // These match the border on the canvas. Hardcoding is less
	var insetY = 1; // expensive than computing the style
	return {
		x: mouseX - insetX,
		y: mouseY - insetY
	};
}


function AnimatedZoom(startZoom, endZoom, interval, steps, updateFunc, completedFunc)
{
    this.startZoom = startZoom.Clone();
	this.endZoom   = endZoom.Clone();
	this.interval  = interval;
	this.count     = steps;
	this.step      = 0;
    this.callbackFunc  = updateFunc;
    this.completedFunc = completedFunc;
    this.timer = setTimeout(this.TimerFunc, this.interval /*ms*/, this);
}

AnimatedZoom.prototype.Stop = function(targetZoom)
{
	if (this.timer != undefined)
	{
		clearTimeout(this.timer.timer);
		this.timer = undefined;
	}
}
	
AnimatedZoom.prototype.TimerFunc = function(context)
{
	context.step++;
	var zoom = context.startZoom.CalcTransformTo(context.endZoom, context.step/context.count);

	if (context.callbackFunc != undefined)
		context.callbackFunc(zoom)
	
	if (context.step < context.count)
	{
		context.timer = setTimeout(context.TimerFunc, context.interval /*ms*/, context);
	}
	else
	{
		context.timer = undefined;
		if (context.completedFunc != undefined)
			context.completedFunc(context);
	}
}
	
var ScreenEditorCanvas = (function() {

	var seCanvas = undefined;
	var seContext = undefined;
	
	var seMousePos = undefined; /* Snapped point in xfrm coords */
	var seMouseTap = undefined; /* 2019.03.21: To handle touch events */
	var seCanvasMousePos = undefined; /* Unsnapped point in canvas coords */
	var seMouseDown = undefined; /* Snapped point in xfrm coords */
	var seTrackingState = 0; /* 2019.10.08: Distinguish between moving points and moving tile */
	var seCanvasTrackingPos = undefined; /* 2019.10.08 */
	var seMouseInLineInfoBox = false;
	var seLineInfoBoxOverColumn = undefined;
	var seLineList = [];
	var seOffsetPolyList = undefined;
	var seNewLineIndex = undefined;
	var seEditTileInfo = undefined;
	var seEditorMargin = 5;
	var seTransform = undefined; //new Transform();
	var seTransformToRelative = undefined; //new Transform();
	var seTransformTileToRelative = undefined; //new Transform();
	var seZoom = undefined; //new Transform();
	var seAnimatedZoom = undefined;
	var seSnapInfo = { points:[], lines:[], directions:[] }; // line:{pt:{x, y}, unit:{ux, uy} }
	var sePriorFocusedElement = undefined;
	var seCanvasMousePos = undefined;
	var seMoveSelection = false;
	var seLineInfo = { show:true, origin:{x:5, y:30}, spacing:{x:120, y:12}, radius:2.5, rightMargin:5 };
	var seDefaultSegmentWidth = 2.0;
	var seRenderOptions = {};
	var seUndoSnapshotInfo = undefined; // 2019.01.29
	var seEndpointsNearList = []; // 2019.09.17: For option-click on points
	var seEndpointsOptionIdx = 0;
	var seDefaultDimensions = {width: 350, height: 350}; // 2020.08.04: Added
	var seEditOptions = {
			enableMouseWheel: true,
			showSnapToInfo: false,
			showLineList: true,
			enableSnapToPointsLines: true,
			enableSnapToSymmetryLines: true,
			enableSnapToGuidelines: false,
			showFullDesign : false,
			enableExtraSnapPoints: true,
			showMirroredRotatedLines: true,
			showSubtileOutline: false,
			showTileOutline: true };
	var seMaxRotate = 2; // 2020.09.01: Added; updated when a tile is set
	var seMaxReflect = 2; // "
	var seWidthChangeScale = 1; // 2021.07.29: Change to 10 when units are inches. See _SetWidthScale
	
	var seFileImage = undefined;

	var ColType = Object.freeze({
		SPACE		: 0,
		RADIO		: 1,	// Boolean value
		RIGHT_NUM	: 2,
		RIGHT_DEC	: 3,
		MULTI_VAL	: 4,	// Value list (clicking rotates through values)
		SELECTED	: 5,
		COLOR		: 6,	// 2020.08.25: Added
		BIT_FIELD	: 7		// 2022.03.10: Added for lattice behavior
	});

	var TrackingState = Object.freeze({
		NONE		: 0,
		POINT		: 1,	// Moving one or more endpoints
		TILE		: 2		// Moving the entire tile on the canvas
	});

	
	// Values in pixels
	var MIN_ONSCREEN_LINE_LENGTH = 6;
	var MIN_ONSCREEN_SELECT_THRESHOLD = 6;
	var MIN_ONSCREEN_MOVE_DISTANCE = 3;
	var ONSCREEN_SNAP_THRESHOLD = 6;
	
	var SnappedType = Object.freeze({
		NONE		: 0,
		IN_RANGE	: 1,
		SELECTED	: 2
	});
	
	var ZoomTo = Object.freeze({
		FULL_TILE			: 0,
		MEDIUM_SUBTILE		: 1,
		SMALLEST_SUBTILE	: 2
	});
	
	var ScreenEditorCanvas_Init = function(canvasID)
	{
		seCanvas = document.getElementById(canvasID);
		seContext = seCanvas.getContext("2d");

		seTransform = new Transform();
		seTransformToRelative = new Transform();
		seTransformTileToRelative = new Transform();
		seZoom = new Transform();

		if (seCanvas == undefined || seContext == undefined)
			console.log("ScreenEditorCanvas_Init: the canvas or context could not be found");
		else
		{
			seCanvas.width  = seDefaultDimensions.width;
			seCanvas.height = seDefaultDimensions.height;
			
			seCanvas.addEventListener("mousemove",  ScreenEditorCanvas_MouseMove_priv, false);
			seCanvas.addEventListener("mousedown",  ScreenEditorCanvas_MouseDown_priv, false);
			seCanvas.addEventListener("mouseout",   ScreenEditorCanvas_MouseOut_priv,  false);
			seCanvas.addEventListener("mouseup",	ScreenEditorCanvas_MouseUp_priv,   false);
			seCanvas.addEventListener("mouseenter",	ScreenEditorCanvas_MouseEnter_priv,   false);
			seCanvas.addEventListener("mouseleave",	ScreenEditorCanvas_MouseLeave_priv,   false);
			seCanvas.addEventListener("wheel",      ScreenEditorCanvas_MouseWheel_priv,  {passive: false});
			seCanvas.addEventListener("keydown", 	ScreenEditorCanvas_KeyDown_priv, false);

			seCanvas.addEventListener("touchstart",  evt => ScreenEditorCanvas_HandleTouch(evt, "touchstart"),  false);
			seCanvas.addEventListener("touchmove",   evt => ScreenEditorCanvas_HandleTouch(evt, "touchmove"),   false);
			seCanvas.addEventListener("touchend",    evt => ScreenEditorCanvas_HandleTouch(evt, "touchend"),    false);
			seCanvas.addEventListener("touchcancel", evt => ScreenEditorCanvas_HandleTouch(evt, "touchcancel"), false);
		}
		
		// Update the edit option buttons in the UI with the settings here
		for (var property in seEditOptions)
		{
			if (seEditOptions.hasOwnProperty(property))
				ScreenDesigner.UpdateEditOptionBtn(property, seEditOptions[property]);
		}
		
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Get Line Info Cols
	 *		2022.02.08: Added. Used to hide some columns
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_GetLineInfoCols = function()
	{
		let seLineInfoCols = ScreenDesigner.GetLineInfoColumns();

		// 2022.02.08: Moved from _Init since the column count can change
		var w = 0;
		seLineInfoCols.forEach(c => w += c.width);
		seLineInfo.spacing.x = w;
		
		return seLineInfoCols;
	}

	/*----------------------------------------------------------------------------------*
	 *	Resize Handler
	 *		2020.07.30: Added.
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_ResizeHandler = function()
	{
		if (seCanvas != undefined)
		{
			if (seCanvas.clientWidth == 0 || seCanvas.clientHeight == 0)
			{
				seCanvas.width  = seDefaultDimensions.width;
				seCanvas.height = seDefaultDimensions.height;
			}
			else
			{
				seCanvas.width  = seCanvas.clientWidth;
				seCanvas.height = seCanvas.clientHeight;
			}
		}
	}
	
	var ScreenEditorCanvas_GetRelativeLayout = function()
	{
		var layout = {};
		if (seCanvas.clientWidth == 0 || seCanvas.clientHeight == 0)
		{
			layout.width  = seDefaultDimensions.width;
			layout.height = seDefaultDimensions.height;
		}
		else
		{
			layout.width  = seCanvas.clientWidth;
			layout.height = seCanvas.clientHeight;
		}
		layout.zoom = seZoom.Clone();
		
		return layout;
	}
	
	var ScreenEditorCanvas_SetRelativeLayout = function(layout)
	{
		seZoom = ScreenDesigner.CalcZoomChanges(seCanvas, layout, seZoom, false /* means that origin is top-left */);
	}
	
	
	/*----------------------------------------------------------------------------------*
	 *	Set Width Scale
	 *		2021.07.29: Added. Pass 1.0 for millimeters and pixels. Pass 10.0 for inches
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_SetWidthScale = function(widthScale)
	{
		seWidthChangeScale = Number(widthScale);
		
		if (!isFinite(seWidthChangeScale) || seWidthChangeScale < 1)
		{
			console.log("ScreenEditorCanvas_SetWidthScale: invalid value: " + seWidthChangeScale + ", " + typeof(seWidthChangeScale));
			seWidthChangeScale = 1.0;
		}
	}
	
	var ScreenEditorCanvas_SetEditOption = function(editOptionName, editOptionValue)
	{
		if (editOptionName == "enableMouseWheel")
		{
			seEditOptions.enableMouseWheel = editOptionValue;
		}
		else if (editOptionName == "showSnapToInfo")
		{
			seEditOptions.showSnapToInfo = editOptionValue;
			ScreenEditorCanvas_Render();
		}
		else if (editOptionName == "showLineList")
		{
			seEditOptions.showLineList = editOptionValue;
			ScreenEditorCanvas_MouseUpdate_priv();
			ScreenEditorCanvas_Render();
		}
		else if (editOptionName == "enableSnapToPointsLines")
		{
			seEditOptions.enableSnapToPointsLines = editOptionValue;
			ScreenEditorCanvas_RebuildSnapInfo();
			ScreenEditorCanvas_MouseUpdate_priv();
		}
		else if (editOptionName == "enableSnapToSymmetryLines")
		{
			seEditOptions.enableSnapToSymmetryLines = editOptionValue;
			ScreenEditorCanvas_RebuildSnapInfo();
			ScreenEditorCanvas_MouseUpdate_priv();
		}
		else if (editOptionName == "enableSnapToGuidelines")
		{
			seEditOptions.enableSnapToGuidelines = editOptionValue;
			ScreenEditorCanvas_MouseUpdate_priv();
		}
		else if (editOptionName == "showFullDesign")
		{
			seEditOptions.showFullDesign = editOptionValue;
			if (seEditOptions.showFullDesign)
				ScreenEditorCanvas_GenerateFinalRender();
			ScreenEditorCanvas_Render();
		}
		else if (editOptionName == "enableExtraSnapPoints")
		{
			seEditOptions.enableExtraSnapPoints = editOptionValue;
			ScreenEditorCanvas_RebuildSnapInfo();
			ScreenEditorCanvas_Render();
		}
		else if (editOptionName == "showMirroredRotatedLines")
		{
			seEditOptions.showMirroredRotatedLines = editOptionValue;
			ScreenEditorCanvas_Render();
		}
		else if (editOptionName == "showSubtileOutline")
		{
			seEditOptions.showSubtileOutline = editOptionValue;
			ScreenEditorCanvas_Render();
		}
		else if (editOptionName == "showTileOutline")
		{
			seEditOptions.showTileOutline = editOptionValue;
			ScreenEditorCanvas_Render();
		}
		else
		{
			console.log("Unknown edit option: " + editOptionName);
		}
	}
	
	var ScreenEditorCanvas_ToggleEditOption = function(optionName)
	{
		ScreenEditorCanvas_SetEditOption(optionName, !seEditOptions[optionName])
		ScreenDesigner.UpdateEditOptionBtn(optionName, seEditOptions[optionName]);
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Get Edit Options
	 *		Return a copy of the edit options (so the main app can store them)
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_GetEditOptions = function()
	{
		return JSON.parse(JSON.stringify(seEditOptions));
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Handle Delete
	 *		Can be called by delete key or toolbar
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_HandleDelete = function()
	{
		ScreenEditorCanvas_DeleteSelected_priv();
		ScreenEditorCanvas_RebuildSnapInfo();
		ScreenEditorCanvas_Render();
	}

	/*----------------------------------------------------------------------------------*
	 *	Handle Arrow
	 *		2022.02.17: Factored out so it can be called from line info list in UI
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_HandleArrow = function(evt, options)
	{
		var byAmountCode = 0;
		if (evt.key == "ArrowUp") // up
			byAmountCode = 10;
		if (evt.key == "ArrowDown") // down
			byAmountCode = -10;
		if (evt.key == "ArrowLeft") // left
			byAmountCode = -1;
		if (evt.key == "ArrowRight") //right
			byAmountCode = 1;
		
		// 2019.10.08: Pass evt so the shift/ctrl/meta/alt keys can be reviewed
		ScreenEditorCanvas_UpdateCurrentValue(byAmountCode, evt, options);
	}

	/*----------------------------------------------------------------------------------*
	 *	Keydown
	 *		
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_KeyDown_priv = function(evt)
	{
		// Shift, Control, Meta, Alt: pass "SCMA" to indicate required presses; all others must not be pressed
		function mc(flagStr) { 
			var shiftReq = flagStr.includes("S");
			var ctrlReq  = flagStr.includes("C");
			var metaReq  = flagStr.includes("M");
			var altReq   = flagStr.includes("A");
			
			var pass = ( 
				((!evt.shiftKey && !shiftReq) || (evt.shiftKey && shiftReq)) &&	// No shift key or "S" (shift key required)
				((!evt.ctrlKey  && !ctrlReq ) || (evt.ctrlKey  && ctrlReq )) &&	// No control key or "C" (control key required)
				((!evt.metaKey  && !metaReq ) || (evt.metaKey  && metaReq )) &&	// No meta key or "M" (meta key required)
				((!evt.altKey   && !altReq  ) || (evt.altKey   && altReq  ))	// No alt key or "A" (alt key required)
				)

			return pass;
		}
		
		//function logkey() { console.log(evt.keyCode, evt.key + "      " + (evt.shiftKey ? "SHIFT" : "     ") + " " + (evt.ctrlKey ? "CTRL" : "    ") + " " + (evt.metaKey ? "META" : "    ") + " " + (evt.altKey ? "ALT" : "   ")); }

		let modifierKeyCodes = [16, 17, 18, 20, 90, 91, 93];

		//if (!modifierKeyCodes.includes(evt.keyCode))
		//	logkey()

		if (modifierKeyCodes.includes(evt.keyCode))
		{
			// Do nothing
		}
		else if (evt.key == "a" && mc("C")) // Select all lines
		{
			//logkey();
			ScreenEditorCanvas_SelectAllEndPoints();
			ScreenEditorCanvas_Render();
			evt.preventDefault();
		}
		else if (evt.key == "ArrowUp" || evt.key == "ArrowDown" || evt.key == "ArrowLeft" || evt.key == "ArrowRight")
		{
			ScreenEditorCanvas_HandleArrow(evt);
			ScreenEditorCanvas_Render();
			evt.preventDefault();
		}
		else if (evt.key == "Escape" && mc(""))
		{
			ScreenEditorCanvas_CancelTracking_priv();
			ScreenEditorCanvas_Render();
			evt.preventDefault();
		}
		else if ((evt.key == "Backspace" || evt.key == "Delete") && mc(""))
		{
			ScreenEditorCanvas_HandleDelete();
			evt.preventDefault();
		}
		else if (evt.key == "g" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("enableSnapToGuidelines");
			evt.preventDefault();
		}
		else if (evt.key == "w" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("enableMouseWheel");
			evt.preventDefault();
		}
		else if (evt.key == "l" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("showLineList");
			evt.preventDefault();
		}
		else if (evt.key == "i" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("showSnapToInfo");
			evt.preventDefault();
		}
		else if (evt.key == "s" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("enableSnapToPointsLines");
			evt.preventDefault();
		}
		else if (evt.key == "y" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("enableSnapToSymmetryLines");
			evt.preventDefault();
		}
		else if (evt.key == "f" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("showFullDesign");
			evt.preventDefault();
		}
		else if (evt.key == "j" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("enableExtraSnapPoints");
			evt.preventDefault();
		}
		else if (evt.key == "e" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("showMirroredRotatedLines");
			evt.preventDefault();
		}
		else if (evt.key == "u" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("showSubtileOutline");
			evt.preventDefault();
		}
		else if (evt.key == "t" && mc(""))
		{
			ScreenEditorCanvas_ToggleEditOption("showTileOutline");
			evt.preventDefault();
		}
		else
		{
			let key = evt.key;
			let seLineInfoCols = ScreenEditorCanvas_GetLineInfoCols();
			let lineInfo = seLineInfoCols.find(e => (e.key == key || e.key == key.toLowerCase()));
			
			if (lineInfo != undefined && (mc("") | mc("S")))
				ScreenEditorCanvas_TogglePropertyMouseOverLines(lineInfo.prop, evt.shiftKey);
			else
				console.log(evt.key + "  " + (evt.shiftKey ? "SHIFT " : "") + (evt.ctrlKey ? "CTRL " : "") + (evt.metaKey ? "META " : "") + (evt.altKey ? "ALT" : ""));
		}
	}			
	
	var ScreenEditorCanvas_ForceFocus_priv = function(evt)
	{
		if (document.activeElement != seCanvas)
		{
			sePriorFocusedElement = document.activeElement;
			seCanvas.focus();
		}
	}

	var ScreenEditorCanvas_MouseEnter_priv = function(evt)
	{
		ScreenEditorCanvas_ForceFocus_priv();
	}
	
	var ScreenEditorCanvas_MouseLeave_priv = function(evt)
	{
		ScreenEditorCanvas_ClearMouseOver();
		ScreenEditorCanvas_Render();
		ScreenEditorCanvas_UpdateEditState();
		
		if (sePriorFocusedElement != undefined)
		{
			sePriorFocusedElement.focus();
			sePriorFocusedElement = undefined;
		}
	}
	
	var ScreenEditorCanvas_ResetSnapshot = function()
	{
		seUndoSnapshotInfo = undefined;
	}

	var ScreenEditorCanvas_PerformSnapshot = function(info = undefined)
	{
		if (seUndoSnapshotInfo == undefined || info == undefined)
		{
			ScreenDesigner.PerformSnapshot();
		}
		else if (info.prop != seUndoSnapshotInfo.prop || info.id != seUndoSnapshotInfo.id)
		{
			ScreenDesigner.PerformSnapshot();
		}
		
		seUndoSnapshotInfo = (info == undefined) ? undefined : {prop: info.prop, id: info.id};
	}
	
	var ScreenEditorCanvas_GetMousePos = function(evt)
	{
		var pt = getMousePos(seCanvas, evt);
		return pt;
	}
	
	var ScreenEditorCanvas_GetTouchPos = function(touch)
	{
		var rect = seCanvas.getBoundingClientRect();
		var mouseX = touch.clientX - rect.left;
		var mouseY = touch.clientY - rect.top;
		var insetX = 1; // These match the border on the canvas. Hardcoding is less
		var insetY = 1; // expensive than computing the style
		return {
			x: mouseX - insetX,
			y: mouseY - insetY
		};
	}
	
	var ScreenEditorCanvas_SelectEndpoints_priv = function(lineIndex, endpointsStr, selectPt)
	{
		var ln = seLineList[lineIndex];
		
		for (var i = 0; i < endpointsStr.length; i++)
		{
			var pt = (endpointsStr[i] == "A") ? ln.ptA : ln.ptB;
		
			pt.selected = selectPt;
			if (selectPt)
			{
				pt.origX = pt.x;
				pt.origY = pt.y;
			}
			else
			{
				pt.origX = undefined;
				pt.origY = undefined;
			}
		}
	}
	
	/*----------------------------------------------------------------------------------*
	//	Any Endpoints Selected
	//		2019.03.21: Added to help with touch handling
	*----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_AnyEndpointsSelected = function()
	{
		return seLineList.some(ln => (ln.ptA.selected || ln.ptB.selected));
	}
	
	var ScreenEditorCanvas_ClearSelectedPoints = function()
	{
		for (var i = 0; i < seLineList.length; i++)
		{
			ScreenEditorCanvas_SelectEndpoints_priv(i, "AB", false);
		}
	}
	
	var ScreenEditorCanvas_SelectAllEndPoints = function()
	{
		for (var i = 0; i < seLineList.length; i++)
		{
			ScreenEditorCanvas_SelectEndpoints_priv(i, "AB", true);
		}
	}

	var ScreenEditorCanvas_SelectLineEndPoints = function(lineIdx)
	{
		if (lineIdx >= 0 && lineIdx <= seLineList.length)
			ScreenEditorCanvas_SelectEndpoints_priv(lineIdx, "AB", true);
		else
			console.log("ScreenEditorCanvas_SelectLineEndPoints:", lineIdx, "out of range");
	}
	
	var ScreenEditorCanvas_ClearMouseOver = function()
	{
		for (var i = 0; i < seLineList.length; i++)
		{
			seLineList[i].mouseOver = false;
			seLineList[i].ptA.mouseOver = false; // 2022.02.08
			seLineList[i].ptB.mouseOver = false; // 2022.02.08
		}
	}
	

	var ScreenEditorCanvas_SelectPointsNear_orig = function(selectNearPt, toggle)
	{
		var selectThresh = MIN_ONSCREEN_SELECT_THRESHOLD / seZoom.scale.x;
		for (var i = 0; i < seLineList.length; i++)
		{
			var d = MathUtil.DistanceBetween(selectNearPt, seLineList[i].ptA);
			if (d < selectThresh)
			{
				var selectIt =  (toggle ? (!seLineList[i].ptA.selected) : true);
				ScreenEditorCanvas_SelectEndpoints_priv(i, "A", selectIt);
			}
			
			var d = MathUtil.DistanceBetween(selectNearPt, seLineList[i].ptB);
			if (d < selectThresh)
			{
				var selectIt =  (toggle ? (!seLineList[i].ptB.selected) : true);
				ScreenEditorCanvas_SelectEndpoints_priv(i, "B", selectIt);
			}
		}
	}

	var ScreenEditorCanvas_SelectPointsNear = function(selectNearPt, toggle, optionKey = false)
	{
		var doOptionSelect = false;
		var optionIdx = 0;
		
		// Create a list of points under the mouse
		var endpointNearList = [];
		var endpointLtrs = ["A", "B"];
		var selectThresh = MIN_ONSCREEN_SELECT_THRESHOLD / seZoom.scale.x;
		for (var i = 0; i < seLineList.length; i++)
			for (var j = 0; j < 2; j++)
			{
				var endPt = "pt" + endpointLtrs[j];
				var d = MathUtil.DistanceBetween(selectNearPt, seLineList[i][endPt]);
				if (d < selectThresh)
					endpointNearList.push({idx:i, endPt:endpointLtrs[j]});
			}
		
		// Determine if we should process as "option select"
		if (!optionKey)
			doOptionSelect = false;
		else if (endpointNearList.length == 0)
			endpointNearList = false;
		else
		{
			// Option key is pressed and we have a list of points under or near the mouse.
			// Now determine if we are on the same list as list time we option-clicked.
			doOptionSelect = true;
			optionIdx = 0;
			
			// Are the arrays of endpoints the same?
			var arraysMatch = (endpointNearList.length == seEndpointsNearList.length);
			for (var j = 0; j < endpointNearList.length && arraysMatch; j++)
				arraysMatch = (endpointNearList[j].idx == seEndpointsNearList[j].idx) && (endpointNearList[j].endPt == seEndpointsNearList[j].endPt);
			
			// If yes, then increment the index
			if (arraysMatch)
				optionIdx = (seEndpointsOptionIdx + 1) % endpointNearList.length;
		}
		
		// Select the points
		if (doOptionSelect)
		{
			seEndpointsNearList = endpointNearList;
			seEndpointsOptionIdx = optionIdx;
			
			var ep = endpointNearList[optionIdx];
			var endPt = "pt" + ep.endPt;
			var selectIt =  (toggle ? (!seLineList[ep.idx][endPt].selected) : true);
			ScreenEditorCanvas_SelectEndpoints_priv(ep.idx, ep.endPt, selectIt);
		}
		else
		{
			seEndpointsNearList = [];
			seEndpointsOptionIdx = 0;
			for (var i = 0; i < endpointNearList.length; i++)
			{
				var ep = endpointNearList[i];
				var endPt = "pt" + ep.endPt;
				var selectIt =  (toggle ? (!seLineList[ep.idx][endPt].selected) : true);
				ScreenEditorCanvas_SelectEndpoints_priv(ep.idx, ep.endPt, selectIt);
			}
		}
	}
	
	
	var ScreenEditorCanvas_IsPointOnSelected = function(nearPt)
	{
		var onSelected = false;
		
		var selectThresh = MIN_ONSCREEN_SELECT_THRESHOLD / seZoom.scale.x;
		for (var i = 0; i < seLineList.length; i++)
		{
			if (seLineList[i].ptA.selected)
			{
				var d = MathUtil.DistanceBetween(nearPt, seLineList[i].ptA);
				if (d < selectThresh)
				{
					onSelected = true;
					seLineList[i].ptA.snapDuringMove = true;
				}
			}
			
			if (seLineList[i].ptB.selected)
			{
				var d = MathUtil.DistanceBetween(nearPt, seLineList[i].ptB);
				if (d < selectThresh)
				{
					onSelected = true;
					seLineList[i].ptB.snapDuringMove = true;
				}
			}
		}
		
		return onSelected;
	}

	var ScreenEditorCanvas_MoveSelectedPoints = function(delta)
	{
		for (var i = 0; i < seLineList.length; i++)
		{
			if (seLineList[i].ptA.selected)
			{
				seLineList[i].ptA.x = seLineList[i].ptA.origX + delta.x;
				seLineList[i].ptA.y = seLineList[i].ptA.origY + delta.y;
				
				if (seLineList[i].ptA.snapDuringMove)
				{
					var pt = ScreenEditorCanvas_FindSnappedMousePos(seZoom.xfrm(seLineList[i].ptA));
					seLineList[i].ptA.x = pt.x;
					seLineList[i].ptA.y = pt.y;
				}
			}
			if (seLineList[i].ptB.selected)
			{
				seLineList[i].ptB.x = seLineList[i].ptB.origX + delta.x;
				seLineList[i].ptB.y = seLineList[i].ptB.origY + delta.y;
				if (seLineList[i].ptB.snapDuringMove)
				{
					pt = ScreenEditorCanvas_FindSnappedMousePos(seZoom.xfrm(seLineList[i].ptB));
					seLineList[i].ptB.x = pt.x;
					seLineList[i].ptB.y = pt.y;
				}
			}
		}
	}
	
	var ScreenEditorCanvas_CancelMoveSelectedPoints = function()
	{
		for (var i = 0; i < seLineList.length; i++)
		{
			if (seLineList[i].ptA.selected)
			{
				seLineList[i].ptA.x = seLineList[i].ptA.origX;
				seLineList[i].ptA.y = seLineList[i].ptA.origY;
			}
			if (seLineList[i].ptB.selected)
			{
				seLineList[i].ptB.x = seLineList[i].ptB.origX;
				seLineList[i].ptB.y = seLineList[i].ptB.origY;
			}
		}
	}

	var ScreenEditorCanvas_AcceptMoveSelectedPoints = function()
	{
		ScreenEditorCanvas_PerformSnapshot();
		
		for (var i = 0; i < seLineList.length; i++)
		{
			if (seLineList[i].ptA.selected)
			{
				seLineList[i].ptA.origX = seLineList[i].ptA.x;
				seLineList[i].ptA.origY = seLineList[i].ptA.y;
			}
			if (seLineList[i].ptB.selected)
			{
				seLineList[i].ptB.origX = seLineList[i].ptB.x;
				seLineList[i].ptB.origY = seLineList[i].ptB.y;
			}
			
			if (seLineList[i].ptA.selected || seLineList[i].ptB.selected)
			{
				ScreenEditorCanvas_UpdateLine_priv(i);
			}
		}
	}
	
	var ScreenEditorCanvas_DeleteSelected_priv = function()
	{
		var canBeDeletedCount = 0;
		
		// Determine if there any lines that can be deleted
		for (var i = 0; i < seLineList.length; i++)
		{
			if (seLineList[i].ptA.selected && seLineList[i].ptB.selected)
				canBeDeletedCount++;
		}
		
		if (canBeDeletedCount > 0)
		{
			ScreenEditorCanvas_PerformSnapshot();
			
			for (var i = seLineList.length - 1; i >= 0; i--)
			{
				if (seLineList[i].ptA.selected && seLineList[i].ptB.selected)
				{
					ScreenDesigner.DeleteLine(seLineList[i].id)
					seLineList.splice(i, 1);
					
					if (seEditOptions.showFullDesign)
						ScreenEditorCanvas_GenerateFinalRender();
				}
			}
		}
	}

	var ScreenEditorCanvas_TogglePropertyMouseOverLines = function(property, reverse = false)
	{
		var dataChanged = false;
		var redraw = false;
		let seLineInfoCols = ScreenEditorCanvas_GetLineInfoCols();
		var lineInfo = seLineInfoCols.find(e => (e.prop == property));

		// Look for any lines that are under the mouse
		for (var i = 0; i < seLineList.length; i++)
		{
			var ln = seLineList[i];
			
			if (ln.mouseOver)
			{
				redraw = true;
				
				// Perform an undo snapshot only if we need to
				// Note that "lineInfo.edit" indicates a data property, in contrast
				// to a "state property (such as 'endpoint selected')
				if (!dataChanged && lineInfo.edit)
				{
					ScreenEditorCanvas_PerformSnapshot();
					dataChanged = true;
				}

				if (lineInfo.colType == ColType.RADIO)
				{
					ln[property] = !ln[property];
				}
				else if (property == "rotate")
				{
					let dir = !reverse ? 1 : seMaxRotate;
					ln[property] = (ln[property] + dir) % (seMaxRotate + 1);
				}
				else if (property == "reflect")
				{
					if (ln[property] == undefined)
						ln[property] = 0;

					let dir = !reverse ? 1 : seMaxReflect - 1;
					ln[property] = (ln[property] + dir) % seMaxReflect;

					if (ln[property] == 0)
						ln[property] = undefined
				}
				else if (lineInfo.colType == ColType.SELECTED)
				{
					if (property == "ptAselected")
					{
						var isSel = ln.ptA.selected;
						ScreenEditorCanvas_SelectEndpoints_priv(i, "A", !isSel);
					}
					else if (property == "ptBselected")
					{
						var isSel = ln.ptB.selected;
						ScreenEditorCanvas_SelectEndpoints_priv(i, "B", !isSel);
					}
				}
				else if (property == "colorId")
				{
					ln[property] = ScreenDesigner.ColorPalette_NextColorId(ln[property], reverse);
				}

				if (lineInfo.edit)
					ScreenDesigner.UpdateLineProperty(ln.id, property, ln[property]);
			}
		}
		
		// Re-render and redraw only if we changed something
		if (redraw)
		{
			if (dataChanged)
			{
				if (seEditOptions.showFullDesign)
					ScreenEditorCanvas_GenerateFinalRender();
				ScreenEditorCanvas_RebuildSnapInfo();
			}
			ScreenEditorCanvas_Render();
			ScreenEditorCanvas_UpdateEditState();
		}
	}
	
	var ScreenEditorCanvas_ToggleLineProperty = function(lineIdx, property, reverse = false)
	{
		ScreenEditorCanvas_TogglePropertyMouseOverLines(property, reverse);
	}
		
	var ScreenEditorCanvas_UpdateLineProperty = function(lineIdx, property, value)
	{
		var ln = seLineList[lineIdx];
		let oldValue = ln[property];
		let newValue = (property == ColType.COLOR) ? value : Number(value);

		if (oldValue != newValue)
		{
			if (property != ColType.COLOR)
				ScreenEditorCanvas_PerformSnapshot(/*{prop:"width", id:ln.id}*/);

			ln[property] = value;

			ScreenDesigner.UpdateLineProperty(ln.id, property, ln[property]);
		
			if (seEditOptions.showFullDesign)
				ScreenEditorCanvas_GenerateFinalRender();
			ScreenEditorCanvas_RebuildSnapInfo();
			ScreenEditorCanvas_Render();
			ScreenEditorCanvas_UpdateEditState();
		}
	}
	
	var ScreenEditorCanvas_SelectLine = function(lineIdx, shiftKey = false)
	{
		// If we are passed a number, then select that line,
		// otherwise clear the selected points
		if (typeof lineIdx === 'number')
		{
			if (!shiftKey)
				ScreenEditorCanvas_ClearSelectedPoints();
			ScreenEditorCanvas_SelectLineEndPoints(lineIdx);
		}
		else
		{
			ScreenEditorCanvas_ClearSelectedPoints();
		}
		
		ScreenEditorCanvas_Render();
		ScreenEditorCanvas_UpdateEditState();
	}
	
		
	var ScreenEditorCanvas_HandleLineInfoMouseDown_priv = function(shiftKey)
	{
		let seLineInfoCols = ScreenEditorCanvas_GetLineInfoCols(); // 2022.02.08
		// 2019.03.15: Changed params from "evt" to "shiftKey"
		
		//console.log("Mouse down in line info, column:" + seLineInfoBoxOverColumn);
		// If no column selectable, then select the line
		if (seLineInfoBoxOverColumn == undefined)
		{
			if (!shiftKey)
				ScreenEditorCanvas_ClearSelectedPoints();

			for (var i = 0; i < seLineList.length; i++)
			{
				if (seLineList[i].mouseOver)
				{
					ScreenEditorCanvas_SelectEndpoints_priv(i, "AB", true);
				}
			}
		}
		else
		{
			var prop = seLineInfoCols[seLineInfoBoxOverColumn].prop;
			var ln = undefined;
			var lnIdx = undefined;
			for (var i = 0; i < seLineList.length && ln == undefined; i++)
			{
				if (seLineList[i].mouseOver)
				{
					ln = seLineList[i];
					lnIdx = i;
				}
			}
			
			if (ln != undefined)
			{
				if (prop == "ptAselected")
				{
					var isSel = ln.ptA.selected;
					ScreenEditorCanvas_SelectEndpoints_priv(lnIdx, "A", !isSel);
				}
				else if (prop == "ptBselected")
				{
					var isSel = ln.ptB.selected;
					ScreenEditorCanvas_SelectEndpoints_priv(lnIdx, "B", !isSel);
				}
				else if (seLineInfoCols[seLineInfoBoxOverColumn].colType == ColType.RADIO)
				{
					ScreenEditorCanvas_PerformSnapshot();
					ln[prop] = !ln[prop];
					ScreenDesigner.UpdateLineProperty(ln.id, prop, ln[prop]);
					if (seEditOptions.showFullDesign)
						ScreenEditorCanvas_GenerateFinalRender();
				}
				else if (prop == "rotate")
				{
					ScreenEditorCanvas_PerformSnapshot();

					ln[prop] = (ln[prop] + 1) % (seMaxRotate + 1);

					ScreenDesigner.UpdateLineProperty(ln.id, prop, ln[prop]);
					if (seEditOptions.showFullDesign)
						ScreenEditorCanvas_GenerateFinalRender();
				}
				else if (prop == "reflect")
				{
					ScreenEditorCanvas_PerformSnapshot();

					if (ln[prop] == undefined)
						ln[prop] = 1;
					else if (ln[prop] < seMaxReflect)
						ln[prop]++;
					else
						ln[prop] = undefined;

					ScreenDesigner.UpdateLineProperty(ln.id, prop, ln[prop]);
					if (seEditOptions.showFullDesign)
						ScreenEditorCanvas_GenerateFinalRender();
				}
				else if (prop == "width")
				{
				}
			}
		}
		
		ScreenEditorCanvas_RebuildSnapInfo();
	}

	var ScreenEditorCanvas_FindPointsUnderMousePos = function(canvasPt)
	{
		var mouseOverCount = 0;
		
		// First, convert the mouse point into canvas coordinates
		var pt = seZoom.reverse_xfrm(canvasPt);
		
		// Adjust snap threshold based on scale
		var snapThresh = seZoom.reverse_scaleNum(ONSCREEN_SNAP_THRESHOLD);
		
		// Find the closest snap line
		for (var i = 0; i < seLineList.length; i++)
		{
			var ln = seLineList[i];
			ln.ptA.mouseOver = (MathUtil.DistanceBetween(pt, ln.ptA) < snapThresh);
			ln.ptB.mouseOver = (MathUtil.DistanceBetween(pt, ln.ptB) < snapThresh);
			
			if (ln.ptA.mouseOver)
				mouseOverCount++;
			
			if (ln.ptB.mouseOver)
				mouseOverCount++;
		}
		
		return mouseOverCount;
	}
	
	var ScreenEditorCanvas_FindLinesUnderMousePos = function(canvasPt)
	{
		var mouseOverCount = 0;

		// First, convert the mouse point into canvas coordinates
		var pt = seZoom.reverse_xfrm(canvasPt);
		
		// Adjust snap threshold based on scale
		var snapThresh = seZoom.reverse_scaleNum(ONSCREEN_SNAP_THRESHOLD);
		
		for (var i = 0; i < seLineList.length; i++)
		{
			var ln = seLineList[i];
			ln.mouseOver = false;
			
			if (MathUtil.CalcPointToLineDistance(pt, ln.ptA, ln.ptB) < snapThresh)
			{
				var si = MathUtil.CalcNearestPointOnLine(pt, ln.ptA, ln.ptB);
				ln.mouseOver =  (si.cLineAB >= -0.01 && si.cLineAB < 1.01);
			}

			if (ln.mouseOver)
				mouseOverCount++;
		}
		
		return mouseOverCount;
	}
	
	var ScreenEditorCanvas_FindSnappedMousePos = function(mousePt, relativeToPt = undefined)
	{
		var Snapping = Object.freeze({
		LINE		: 0,
		DIR			: 1,
		DIR_PT		: 2
		});
		
		var snappedLineList = [];
		
		function compare(a,b) {
			if (a.info.snapping == Snapping.LINE && b.info.snapping != Snapping.LINE)
				return -1;
			else
				return a.distance - b.distance;
		}

		function SEC_UpdateSnappedLineList(point, angle, distance, info)
		{
			var reject = false;
			
			if (angle < Math.PI)
				angle = 2 * Math.PI + angle;
			while (angle >= Math.PI - .0001)
				angle -= Math.PI;
				
			var entry = {point:Object.assign({}, point), angle:angle, distance:distance, info:info};
			
			// If there is an item in the list with the same angle and is not a line and is farther away,
			// then delete it.
			for (var i = snappedLineList.length - 1; i >= 0 ; i--)
			{
				if (MathUtil.EqualWithinTolerance(angle, snappedLineList[i].angle, NORMAL_TOLERANCE))
				{
					if (distance < snappedLineList[i].distance && snappedLineList[i].info.snapping != Snapping.LINE)
						snappedLineList.splice(i, 1);
					else
						reject = true;
				}
			}
			
			if (!reject)
			{ 
				snappedLineList.push(entry);
				snappedLineList.sort(compare);
				if (snappedLineList.length > 2)
					snappedLineList.splice(2, snappedLineList.length - 2);
			}
		}
		
		ScreenEditorCanvas_ClearSnappedFlags_priv();
		
		// First, convert the mouse point into canvas coordinates
		var pt = seZoom.reverse_xfrm(mousePt);
		pt.snapped = false;
		
		// Adjust snap threshold based on scale
		var snapThresh = seZoom.reverse_scaleNum(ONSCREEN_SNAP_THRESHOLD);
		
		// The snap info data does not distinguish between points and lines from the design
		// and points and lines from the tile. The routine that builds the snap info data
		// handles that. Therefore, here we have to check for both flags.
		if (seEditOptions.enableSnapToPointsLines || seEditOptions.enableSnapToSymmetryLines)
		{
			// Find the closest snap point
			var snappedPointDist = 99;
			var snappedPointPt = undefined;
			var snappedPointIdx = -1;
			for (var i = 0; i < seSnapInfo.points.length; i++)
			{
				var d = MathUtil.DistanceBetween(pt, seSnapInfo.points[i]);
				if (d < snapThresh)
				{
					seSnapInfo.points[i].snapped = SnappedType.IN_RANGE;
					if (d < snappedPointDist)
					{
						snappedPointPt = Object.assign({}, seSnapInfo.points[i]);
						snappedPointDist = d;
						snappedPointIdx = i;
					}
				}
			}
		
			// Find the closest snap line
			var snappedLineDist = 99;
			var snappedLinePt = undefined;
			var snappedLineIdx = -1;
			for (var i = 0; i < seSnapInfo.lines.length; i++)
			{
				var ln = seSnapInfo.lines[i];
				d = MathUtil.CalcPointToLineDistance(pt, ln.ptA, ln.ptB);
			
				if (d < snapThresh)
				{
					var si = MathUtil.CalcNearestPointOnLine(pt, ln.ptA, ln.ptB);
					if (si.cLineAB >= -0.01 && si.cLineAB < 1.01)
					{
						seSnapInfo.lines[i].snapped = SnappedType.IN_RANGE;

						// Make a list of the two closest lines					
						SEC_UpdateSnappedLineList(ln.ptA, MathUtil.CalcAngle(ln.ptA, ln.ptB), d, {snapping:Snapping.LINE, idx:i});
					
						if (d < snappedLineDist)
						{
							snappedLineDist = d;
							snappedLinePt = si;
							snappedLineIdx = i;
							//console.log("snapped line: " + i + ", " + JSON.stringify(ln));
						}
					}
					//console.log(d + " " + JSON.stringify(snappedLinePt));
				}
			}
		}
		
		if (seEditOptions.enableSnapToGuidelines)
		{
			// Check for direction lines
			var snappedDirDist = 99;
			var snappedDirPt = undefined;
			var snappedDirIdx = -1;
			var snappedDirPtIdx = undefined;
		
			// If a relative point was provided, then snap the angle to one of the direction lines
			if (relativeToPt != undefined && MathUtil.DistanceBetween(relativeToPt, pt) > snapThresh * 2)
			{
		
				//var relativeAngle = MathUtil.CalcAngle(relativePt, pt);
				var snapPt = relativeToPt;
				for (var i = 0; i < seSnapInfo.directions.length; i++)
				{
					// xxx First, see if a snap point is on a direction line that goes through the mouse point
					var dx = Math.cos(seSnapInfo.directions[i].angle);
					var dy = Math.sin(seSnapInfo.directions[i].angle);
					d = MathUtil.CalcPointToLineDistance(snapPt, pt, {x:pt.x + dx, y:pt.y + dy});

					if (d < snapThresh)
					{
						var si = MathUtil.CalcNearestPointOnLine(pt, snapPt, {x:snapPt.x + dx, y:snapPt.y + dy});

						seSnapInfo.directions[i].snapped = SnappedType.IN_RANGE;
						// Make a line of the two closest lines					
						SEC_UpdateSnappedLineList(snapPt, seSnapInfo.directions[i].angle, d, {snapping:Snapping.DIR, idx:i});
					
						if (d < snappedDirDist)
						{
							snappedDirDist = d;
							snappedDirPt = si;
							snappedDirIdx = i;
						}
					}
				}
			}
		
			for (var j = 0; j < seSnapInfo.points.length; j++)
			{
				var snapPt = seSnapInfo.points[j];
				for (var i = 0; i < seSnapInfo.directions.length; i++)
				{
					// First, see if a snap point is on a direction line that goes through the mouse point
					var dx = Math.cos(seSnapInfo.directions[i].angle);
					var dy = Math.sin(seSnapInfo.directions[i].angle);
					d = MathUtil.CalcPointToLineDistance(snapPt, pt, {x:pt.x + dx, y:pt.y + dy});

					if (d < snapThresh)
					{
						var si = MathUtil.CalcNearestPointOnLine(pt, snapPt, {x:snapPt.x + dx, y:snapPt.y + dy});

						seSnapInfo.directions[i].snapped = SnappedType.IN_RANGE;
						seSnapInfo.points[j].snapped = SnappedType.IN_RANGE;
					
						SEC_UpdateSnappedLineList(snapPt, seSnapInfo.directions[i].angle, d, {snapping:Snapping.DIR_PT, idx:i, idxPt:j});

						if (d < snappedDirDist)
						{
							snappedDirDist = d;
							snappedDirPt = si;
							snappedDirIdx = i;
							snappedDirPtIdx = j;
						}
					}
				}
			}
		}
		
		var foundSnap = false;
		
		// Give priority to the snapped point
		if (!foundSnap && snappedPointPt != undefined)
		{
			pt = snappedPointPt;
			pt.snapped = true;
			pt.distance = snappedPointDist;
			seSnapInfo.points[snappedPointIdx].snapped = SnappedType.SELECTED;
			foundSnap = true;
		}

		// Else, if we have two lines in our snapped line list, then use 
		// their intersection as the snap point
		if (!foundSnap && snappedLineList.length == 2)
		{
			var lnA = snappedLineList[0];
			var lnB = snappedLineList[1];
			var interPt = MathUtil.CalcLineIntersectionWithAngles(lnA.point, lnA.angle, lnB.point, lnB.angle);
			
			// The calculated intersection point might be far away if the two lines are only
			// a few degrees apart. Therefore, do one more distance test to check for that
			if (interPt != undefined && MathUtil.DistanceBetween(pt, interPt) < snapThresh)
			{
				pt = interPt;
				pt.snapped = true;
				foundSnap = true;
				
				for (var i = 0; i < snappedLineList.length; i++)
				{
					var info = snappedLineList[i].info
					if (info.snapping == 0)
						seSnapInfo.lines[info.idx].snapped = SnappedType.SELECTED;
					if (info.snapping == 1 || info.snapping == 2)
						seSnapInfo.directions[info.idx].snapped = SnappedType.SELECTED;
					if (info.snapping == 2)
						seSnapInfo.points[info.idxPt].snapped = SnappedType.SELECTED;
				}
				
			}
		}
		
		// Else, if we have a snapped line, the use that
		if (!foundSnap && snappedLinePt != undefined)
		{
			pt = snappedLinePt;
			pt.snapped = true;
			pt.distance = snappedLineDist;
			seSnapInfo.lines[snappedLineIdx].snapped = SnappedType.SELECTED;
			foundSnap = true;
		}
		
		if (!foundSnap && snappedDirPt != undefined)
		{
			pt = snappedDirPt;
			pt.snapped = true;
			pt.distance = snappedDirDist;
			seSnapInfo.directions[snappedDirIdx].snapped = SnappedType.SELECTED;
			if (snappedDirPtIdx != undefined)
				seSnapInfo.points[snappedDirPtIdx].snapped = SnappedType.SELECTED;
			foundSnap = true;
		}
		
		return pt;
	}
	
	
	/*----------------------------------------------------------------------------------*
	 *	Close Gaps Find Snapped Point
	 *		Find a snapped point for the given design point.
	 *
	 *	Returns:
	 *		0. Can't snap to anything
	 *		1. Already snapped
	 *		2. Snapped to point, Snap point, id of snapped-to point or none if snapped to tile pts
	 *		3. Snapped to line, Snap point,  id of snapped-to points or none if snapped to tile pts
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_CloseGaps_FindSnappedPoint = function(designPt, designPtId)
	{
		let CLOSEGAPS_THRESHOLD = 5.0;
		var alreadySnapped = false;
		var result = undefined;
		var snapPoint = undefined;
		
		if (designPtId == undefined)
			console.log("ScreenEditorCanvas_CloseGaps_FindSnappedPoint: designPtId is undefined");

		// Iterate over all of the points in the snap info point list
		for (var k = 0; k < seSnapInfo.points.length && !alreadySnapped; k++)
		{
			var sPt = seSnapInfo.points[k];
			 
			// Don't compare the point to itself (or the other end of the line it is on)
			if (designPtId != sPt.id)
			{
				// Get the distance between the design point and the snap info point
				var d = MathUtil.DistanceBetween(designPt, sPt);

				// If the distance between the two points is zero, then the point
				// is considered snapped, and we can stop. We return info about 
				// the "already snapped to" point so we can mark it so we don't 
				// try to snap that to something else
				if (MathUtil.EqualWithinTolerance(d, 0.0, NORMAL_TOLERANCE))
				{
					alreadySnapped = true;
					snapPoint = {snapStatus: 1, id:sPt.id, to:"point", which:sPt.which};
				}
				// If the point is close enough and closer than any previous point, then 
				// save the point and distance
				else if (d < CLOSEGAPS_THRESHOLD)
				{
					if (snapPoint == undefined || (d < snapPoint.d))
					{
						snapPoint = {d:d, x:sPt.x, y:sPt.y, id:sPt.id, to:"point", which:sPt.which, snapStatus: 2};
					}
				}
			}
		} // for-loop seSnapInfo.points
		
		// Iterate over all of the line in the snap info line list.
		// Note that snapping to points is preferred, so if a snapPoint is already found 
		// then the line list won't be searched
		for (var k = 0; k < seSnapInfo.lines.length && !alreadySnapped && snapPoint == undefined; k++)
		{
			var sLn = seSnapInfo.lines[k];
			if (designPtId != sLn.ptA.id)
			{
				d = MathUtil.CalcPointToLineDistance(designPt, sLn.ptA, sLn.ptB);
		
				if (MathUtil.EqualWithinTolerance(d, 0.0, NORMAL_TOLERANCE))
				{
					alreadySnapped = true;
					snapPoint = {snapStatus: 1, id:sLn.ptA.id, to:"line"};
				}
				else if (d < CLOSEGAPS_THRESHOLD && (snapPoint == undefined || (d < snapPoint.d)))
				{
					var si = MathUtil.CalcNearestPointOnLine(designPt, sLn.ptA, sLn.ptB);
					if (si.cLineAB >= -0.01 && si.cLineAB < 1.01)
					{
						snapPoint = {d:d, x:si.x, y:si.y, id:sLn.ptA.id, to:"line", snapStatus: 3};
					}
				}
			}
		}
		
		// Return the results as described in the comments at the top
		if (snapPoint == undefined)
			result = {snapStatus: 0};
		
		else if (alreadySnapped)
			result = snapPoint;
			
		else
			result = snapPoint;

		return result;		
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Close Gaps Pass
	 *		Make one pass at closing gaps in the design
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_CloseGaps_Pass = function()
	{
		// Mark as "gapDone" the point or line that was either "snapped to" or "already
		// snapped to"
		function SEC_CG_MarkSourcePoints(sn)
		{
			for (var kk = 0; kk < seLineList.length && sn.id != undefined; kk++)
				if (seLineList[kk].id == sn.id)
				{
					if (sn.to == "line" || sn.which == "ptA")
						seLineList[kk].ptA.gapDone = true;
						
					if (sn.to == "line" || sn.which == "ptB")
						seLineList[kk].ptB.gapDone = true;
				}

			if (sn.id != undefined && !seLineList.some(ln => ln.id == sn.id))
				console.log("Did not find source pt in line list (a)");
		}

		// Iterate over all of the lines in the design line list
		// and call _FindSnappedPoint for both endpoints.
		for (var k = 0; k < seLineList.length; k++)
		{
			// Process ptA if it available to be moved
			if (!seLineList[k].ptA.gapDone)
			{
				var sn = ScreenEditorCanvas_CloseGaps_FindSnappedPoint(seLineList[k].ptA, seLineList[k].id);
				
				if (sn.snapStatus == 1)
				{
					seLineList[k].ptA.gapDone = true;
					SEC_CG_MarkSourcePoints(sn);
				}
				else if (sn.snapStatus == 2 || sn.snapStatus == 3)
				{
					seLineList[k].ptA.gapDone = true;
					seLineList[k].ptA.x = sn.x;
					seLineList[k].ptA.y = sn.y;
					seLineList[k].gapUpdated = true;
					
					SEC_CG_MarkSourcePoints(sn);
				}
			}
			
			// Process ptB if it available to be moved
			if (!seLineList[k].ptB.gapDone)
			{
				var sn = ScreenEditorCanvas_CloseGaps_FindSnappedPoint(seLineList[k].ptB, seLineList[k].id);
				
				if (sn.snapStatus == 1)
				{
					seLineList[k].ptB.gapDone = true;
					SEC_CG_MarkSourcePoints(sn);
				}
				else if (sn.snapStatus == 2 || sn.snapStatus == 3)
				{
					seLineList[k].ptB.gapDone = true;
					seLineList[k].ptB.x = sn.x;
					seLineList[k].ptB.y = sn.y;
					seLineList[k].gapUpdated = true;
					
					SEC_CG_MarkSourcePoints(sn);
				}
			}
		}
	}

	/*----------------------------------------------------------------------------------*
	 *	Close Gaps
	 *		Look for points that are close to snap lines or snap points and snap them
	 *----------------------------------------------------------------------------------*/
	var ScreenEditorCanvas_CloseGaps = function()
	{
		ScreenEditorCanvas_PerformSnapshot();
		
		var done = false;
		var passes = 0;
		var maxPasses = 1; // seLineList.length * 2
		
		try {
			// Mark all lines
			seLineList.forEach(ln => { ln.ptA.gapDone = false; ln.ptB.gapDone = false; })
		
			while (!done)
			{
				// Make one pass at closing the gaps in the design
				ScreenEditorCanvas_CloseGaps_Pass()
		
				// Check if all points have been processed
				done |= seLineList.every(ln => (ln.ptA.gapDone && ln.ptB.gapDone));

				// Limit how many times we go through here
				passes++;
				done |= (passes >= maxPasses);
			}
		
			// Were any lines updated? 
			if (seLineList.some(ln => ln.gapUpdated))
			{
				// Update the line info in the design data
				for (var k = 0; k < seLineList.length; k++)
					if (seLineList[k].gapUpdated)
						ScreenEditorCanvas_UpdateLine_priv(k);
				
				// Rebuild and re-render
				ScreenEditorCanvas_RebuildSnapInfo();
				ScreenEditorCanvas_Render();
				
				// Regenerate the final design, if we are showing it.
				if (seEditOptions.showFullDesign)
					ScreenEditorCanvas_GenerateFinalRender();
			}
				
			// Clear flags
			seLineList.forEach(ln => { delete ln.ptA.gapDone; delete ln.ptB.gapDone; delete ln.gapUpdated; })
			
		}
		catch (err) {
			console.log("ScreenEditorCanvas_CloseGaps: error");
			console.log(err);
		}
	}

	var ScreenEditorCanvas_MouseWheelZoom_priv =  function(evt)
	{
		var mousePt = getMousePos(seCanvas, evt);
		var changePercent = 0.05;
		var lowerScaleLimit = 0.50; 
		var scaleChanged = seZoom.AdjustScaleAroundBy(mousePt, (evt.deltaY > 0) ? changePercent : -changePercent, lowerScaleLimit);
		
		if (scaleChanged)
		{
			ScreenEditorCanvas_Render();
		
			evt.stopPropagation();
			evt.preventDefault();
		}
	}
	
	var ScreenEditorCanvas_UpdatePropertyByValue = function(propStr, amount, removeLowerPrecision)
	{
		var didSnapshot = false;
		
		if (propStr == "width")
		{
			var widthDelta = amount;
			
			for (var i = 0; i < seLineList.length; i++)
			{
				var ln = seLineList[i];
				if (ln.mouseOver)
				{
					var w = ln.width;
					
					if (removeLowerPrecision)
					{
						if (MathUtil.EqualWithinTolerance(Math.abs(widthDelta), 0.1, NORMAL_TOLERANCE))
						{
							w = Math.floor(10 * w + 0.5) / 10;
							w += widthDelta;
						}
						else if (MathUtil.EqualWithinTolerance(Math.abs(widthDelta), 0.01, NORMAL_TOLERANCE)) // 2021.07.30
						{
							w = Math.floor(100 * w + 0.5) / 100;
							w += widthDelta;
						}
						else if (MathUtil.EqualWithinTolerance(Math.abs(widthDelta), 1.0, NORMAL_TOLERANCE))
						{
							if (w == Math.floor(w))
								w += widthDelta;
							else if (widthDelta < 0)
								w = Math.floor(w);
							else
								w = Math.floor(w + 1);
						}
					}
					else
					{
						w += widthDelta;
					}
					if (w < 0)
						w = 0;

					w = Math.floor(w * 1000 + 0.5)/1000;

					if (ln.width != w)
					{
						if (!didSnapshot)
						{
							ScreenEditorCanvas_PerformSnapshot({prop:"width", id:ln.id});
							didSnapshot = true;
						}
						
						ln.width = w;
						ScreenDesigner.UpdateLineProperty(ln.id, "width", ln.width);
					}
				}
			}
		}
		// 2022.01.26: Added
		// 2022.01.28: Added lattice "behavior" values. 
		else if (propStr == "wzA" || propStr == "wzB" || propStr == "wzL" || propStr == "wbA" || propStr == "wbB" || propStr == "wbL") 
		{
			for (var i = 0; i < seLineList.length; i++)
			{
				var ln = seLineList[i];
				if (ln.mouseOver)
				{
					let value = ln[propStr];
					let update = (value == undefined) ? 0 : value;
					
					if (amount == 10) // Increment by one
						update += 1;
					else if (amount == -10) // Decrement by one
						update -= 1;

					// Valid behavior values are 0 to 7
					// 2022.02.03: The "+ 8" keeps the value positive and wraps as expected
					if (propStr == "wbA" || propStr == "wbB" || propStr == "wbL")
						update = ((update + 8) % 8)

					if (update == 0)
						update = undefined;

					if (value != update)
					{
						if (!didSnapshot)
						{
							ScreenEditorCanvas_PerformSnapshot({prop:propStr, id:ln.id});
							didSnapshot = true;
						}

						ln[propStr] = update;
							
						ScreenDesigner.UpdateLineProperty(ln.id, propStr, update);
					}
				}
			}
		}
		
		// 2019.10.08: Moved outside of the inner loop now that we have the didSnapshot flag
		if (didSnapshot)
		{
			if (seEditOptions.showFullDesign)
				ScreenEditorCanvas_GenerateFinalRender();
		}
	}

	//---------------------------------------------------------------------------
	//	Update Current Value
	//		2022.02.17: Added options, support pass overColumn param
	//---------------------------------------------------------------------------
	var ScreenEditorCanvas_UpdateCurrentValue = function(byAmountCode, evt, options)
	{
		let seLineInfoCols = ScreenEditorCanvas_GetLineInfoCols(); // 2022.02.08
		let overColumn = seLineInfoBoxOverColumn; 

		if (options != undefined && options.overColumn != undefined && options.overColumn >= 0 && options.overColumn < seLineInfoCols.length)
			overColumn = options.overColumn

		if (overColumn != undefined)
		{
			var prop = seLineInfoCols[overColumn].prop;
		
			if (prop == "width")
			{
				var widthDelta = byAmountCode/(10 * seWidthChangeScale);
				ScreenEditorCanvas_UpdatePropertyByValue("width", widthDelta, true /* reduce precision */);
			}
			else if (prop == "wzA" || prop == "wzB" || prop == "wzL" || prop == "wbA" || prop == "wbB" || prop == "wbL") // 2022.01.26: Add z-value support
			{
				ScreenEditorCanvas_UpdatePropertyByValue(prop, byAmountCode, false);
			}
		}
		// 2019.10.08: If option key is pressed, then change width of any lines under the mouse
		else if (evt.altKey /* option (Mac), alt (Windows) */)
		{
			var widthDelta = byAmountCode/(10 * seWidthChangeScale);
			ScreenEditorCanvas_UpdatePropertyByValue("width", widthDelta, true /* reduce precision */);
		}
	}

	var ScreenEditorCanvas_MouseWheelPropertyChange_priv =  function(evt)
	{
		let seLineInfoCols = ScreenEditorCanvas_GetLineInfoCols(); // 2022.02.08

		if (seLineInfoBoxOverColumn != undefined)
		{
			var prop = seLineInfoCols[seLineInfoBoxOverColumn].prop;
		
			if (prop == "width")
			{
				// 2021.07.30: Use seWidthChangeScale
				var widthDelta = 0.0 
				if (evt.deltaY > 0)
					widthDelta = 1/(10 * seWidthChangeScale);
				else if (evt.deltaY < 0)
					widthDelta = -1/(10 * seWidthChangeScale);

				ScreenEditorCanvas_UpdatePropertyByValue("width", widthDelta, false);

				evt.stopPropagation();
				evt.preventDefault();
			}
		}
	}

	// 2018.06.04: This is a copy of the same function in ScreenDesignerCanvas.js.
	//             It should be defined only once and referenced appropriately
	var ignoreMetaKeyFlag = undefined;
	function ignoreMetaKey()
	{
		if (ignoreMetaKeyFlag == undefined)
		{
			var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
			ignoreMetaKeyFlag = (macosPlatforms.indexOf(window.navigator.platform) === -1);
		}

		return ignoreMetaKeyFlag;
	}
	
	var ScreenEditorCanvas_MouseWheel_priv =  function(evt)
	{
		//console.log("dx:" + evt.deltaX + ", dy:" + evt.deltaY + ", mode:" + evt.deltaMode);

		// Ignore mouse wheel if tracking and mouse wheel "zoom" is enabled
		if (seMouseDown == undefined && seEditOptions.enableMouseWheel)
		{
			// Don't zoom over line info box
			if (seMouseInLineInfoBox)
				ScreenEditorCanvas_MouseWheelPropertyChange_priv(evt);
			else
			{
				// 2018.01.09: Added test for metaKey (command-key on Mac)
				// 2018.06.04: Provide option to ignore meta key
				// 2019.02.19: Show banner to let user know to hold meta key
				if (evt.metaKey || ignoreMetaKey())
					ScreenEditorCanvas_MouseWheelZoom_priv(evt);
				else
					ScreenDesigner.DisplayHoldCommandKeyBanner(seCanvas.id);
			}
		}
	}
	
	var ScreenEditorCanvas_CancelTracking_priv = function()
	{
		ScreenEditorCanvas_CancelMoveSelectedPoints();

		if (seNewLineIndex != undefined)
			ScreenEditorCanvas_FinalizeNewLine_priv(false);
		
		seMousePos = undefined;
		seMouseDown = undefined;
		seCanvasMousePos = undefined;
		seMoveSelection = false;
		seTrackingState = TrackingState.NONE; // 2019.10.08
		seCanvas.style.cursor = "default";
	}
	
	var ScreenEditorCanvas_GetModifiers = function(evt)
	{
		return {shiftKey:evt.shiftKey, altKey:evt.altKey, metaKey:evt.metaKey};
	}
	
	var ScreenEditorCanvas_MouseUpdate_priv  = function()
	{
		let seLineInfoCols = ScreenEditorCanvas_GetLineInfoCols(); // 2022.02.08

		if (seTrackingState == TrackingState.TILE)
		{
			if (seCanvasMousePos != undefined)
			{
				var delta = {x: seCanvasMousePos.x - seCanvasTrackingPos.x, y: seCanvasMousePos.y - seCanvasTrackingPos.y};

				if ((delta.x != 0 || delta.y != 0) && seZoom != undefined)
				{
					seZoom.offset.x += delta.x;
					seZoom.offset.y += delta.y;
			
					ScreenEditorCanvas_Render();
			
					seCanvasTrackingPos.x = seCanvasMousePos.x;
					seCanvasTrackingPos.y = seCanvasMousePos.y;
				}
			}
		}
		else
		{
			// Clear any special tracking info.
			seMouseInLineInfoBox = false;
			seLineInfoBoxOverColumn = undefined;
			for (var i = 0; i < seLineList.length; i++)
			{
				seLineList[i].mouseOver = false;
				seLineList[i].ptA.mouseOver = false; // 2022.02.08
				seLineList[i].ptB.mouseOver = false; // 2022.02.08
			}

			// Can be called from key events as well as mouse events.
			// if seCanvasMousePos is not set, then nothing happens
			if (seCanvasMousePos != undefined)
			{
				var pt = ScreenEditorCanvas_FindSnappedMousePos(seCanvasMousePos, seMouseDown);
				seMousePos = pt;
			
				// Are we currently moving something?
				if (seMoveSelection)
				{
					var delta = {x:(seMousePos.x - seMouseDown.x), y:(seMousePos.y - seMouseDown.y)};
					ScreenEditorCanvas_MoveSelectedPoints(delta);
				}
			
				// Is the mouse up?
				else if (seMouseDown == undefined)
				{
					var mouseOverLineInfo = false;
					// Is the mouse in the line info box?
					if (seEditOptions.showLineList)
					{
						var h = seLineList.length * seLineInfo.spacing.y;
						var x = seLineInfo.origin.x;
						var y = seLineInfo.origin.y + seLineInfo.spacing.y;
						mouseOverLineInfo = (seCanvasMousePos.x >= x && seCanvasMousePos.x <= x + seLineInfo.spacing.x && 
											seCanvasMousePos.y >= y && seCanvasMousePos.y <= y + h)
					}
				
					if (mouseOverLineInfo)
					{
						// Calculate the row
						var row = Math.floor((seCanvasMousePos.y - y) / seLineInfo.spacing.y);
						if (row >= 0 && row < seLineList.length)
						{
							seLineList[row].mouseOver = true;
							seLineList[row].ptA.mouseOver = false; // 2022.02.08
							seLineList[row].ptB.mouseOver = false; // 2022.02.08
						}
						// Determine the column
						var colIndex = 0;
						var colFound = undefined;
						var xcol = seCanvasMousePos.x - x;
						seLineInfoBoxOverColumn = undefined;
						while (colIndex < seLineInfoCols.length  && colFound == undefined)
						{
							if (xcol < seLineInfoCols[colIndex].width)
								colFound = colIndex;

							xcol -= seLineInfoCols[colIndex].width;
							colIndex++;
						}
				
						if (colFound != undefined && seLineInfoCols[colFound].selectable)
							seLineInfoBoxOverColumn = colFound;

						// 2022.02.08: Identify mouse over endpoints
						if (colFound != undefined && seLineInfoCols[colFound].hover != undefined)
						{
							if (seLineInfoCols[colFound].hover == "ptA")
								seLineList[row].ptA.mouseOver = true;
							if (seLineInfoCols[colFound].hover == "ptB")
								seLineList[row].ptB.mouseOver = true;
						}
						seMouseInLineInfoBox = true;
					}
					else
					{
						for (var i = 0; i < seLineList.length; i++)
						{
							seLineList[i].mouseOver = false;
							seLineList[i].ptA.mouseOver = false; // 2022.02.08
							seLineList[i].ptB.mouseOver = false; // 2022.02.08
						}

						var count = ScreenEditorCanvas_FindPointsUnderMousePos(seCanvasMousePos);
					
						if (count == 0)
							ScreenEditorCanvas_FindLinesUnderMousePos(seCanvasMousePos);
					}
				}
			
				// Mouse is down (and not moving a selection)
				else
				{
					// If...
					// ...no new line is being drawn, and ..
					// ...there was a mouse down, and...
					// ...the we are currently not moving a selecion, and...
					// ...we have moved far enough
					if (seNewLineIndex == undefined)
					{
						var d = MathUtil.DistanceBetween(seZoom.reverse_xfrm(seMouseDown), seCanvasMousePos);
						if (d > MIN_ONSCREEN_LINE_LENGTH)
							ScreenEditorCanvas_StartNewLine_priv(seMouseDown, seMousePos);
					}
	
					if (seNewLineIndex != undefined)
						ScreenEditorCanvas_UpdateNewLine_priv(seMousePos);
				}

				ScreenEditorCanvas_Render();
				ScreenEditorCanvas_UpdateEditState(); // 2019.09.20: Dispatch to ScreenDesigner to update line info table
			}
		}
	}
	
	var ScreenEditorCanvas_MouseMove_priv = function(evt)
	{
		seCanvasMousePos = ScreenEditorCanvas_GetMousePos(evt);
		ScreenEditorCanvas_MouseUpdate_priv();
		
		// The focus should always be on the canvas if the mouse is down
		if (seMouseDown != undefined)
			ScreenEditorCanvas_ForceFocus_priv();
	}

	var ScreenEditorCanvas_MouseDown_priv = function(evt)
	{
		// The focus should always be on the canvas if the mouse is down
		ScreenEditorCanvas_ForceFocus_priv();
		
		// Disable the double-click that highlights the canvas. 
		// There might be another way to do this.
		evt.preventDefault();
		
		var canvasMousePosition = ScreenEditorCanvas_GetMousePos(evt);
		var modifiers = ScreenEditorCanvas_GetModifiers(evt);
		ScreenEditorCanvas_HandleMouseDown(canvasMousePosition, modifiers);
	}
	
	var ScreenEditorCanvas_HandleMouseDown = function(canvasMousePosition, modifiers)
	{
		// 2019.03.21
		if (modifiers.touchStart && seMouseTap != undefined)
			seMouseDown = seMouseTap;
		seMouseTap = undefined; 
		
		// 2019.10.08: Determine the type of action
		if (modifiers.metaKey /* command (Mac), window (Windows) */)
			seTrackingState = TrackingState.TILE;
		else if (!seMouseInLineInfoBox)
			seTrackingState = TrackingState.POINT;
		
		
		if (seMousePos == undefined && seTrackingState != TrackingState.TILE)
		{
			seCanvasMousePos = canvasMousePosition;
			ScreenEditorCanvas_MouseUpdate_priv();
		}
		
		if (seTrackingState == TrackingState.TILE)
		{
			seCanvasTrackingPos = canvasMousePosition;
			seCanvas.style.cursor = "grabbing";
		}
		else if (seMouseInLineInfoBox)
		{
			ScreenEditorCanvas_HandleLineInfoMouseDown_priv(modifiers.shiftKey);
		}
		else
		{
			seMouseDown = seMousePos
			seCanvasMousePos = canvasMousePosition;
			seMousePos = ScreenEditorCanvas_FindSnappedMousePos(seCanvasMousePos);
		
			var shiftPressed = modifiers.shiftKey;
			var onSelectedPt = ScreenEditorCanvas_IsPointOnSelected(seMouseDown);
		
			seMoveSelection = false;
		
			if (onSelectedPt)
			{
				// Start moving a selected point
				seMoveSelection = true;
			}
			else if (shiftPressed)
			{
				// Extend selection (Or something else?)
			}
			else
			{
				// Clear any prior selection and start a new line		
				ScreenEditorCanvas_ClearSelectedPoints();
				ScreenEditorCanvas_RebuildSnapInfo();
				
				if (modifiers.touchTap)
					seMouseTap = JSON.parse(JSON.stringify(seMousePos));
			}
		}
		ScreenEditorCanvas_Render();
		ScreenEditorCanvas_UpdateEditState(); // 2019.09.20: Dispatch to ScreenDesigner to update line info table
	}

	var ScreenEditorCanvas_MouseUp_priv = function(evt)
	{
		var modifiers = ScreenEditorCanvas_GetModifiers(evt);
		ScreenEditorCanvas_HandleMouseUp(modifiers);
	}
	
	var ScreenEditorCanvas_HandleMouseUp = function(modifiers)
	{
		// seMousePos can be undefined if the tracking is cancelled
		if (seMousePos != undefined)
		{
			if (seMoveSelection)
			{
				if (seMouseDown != undefined)
				{
					var unscaledMoveDist = MathUtil.DistanceBetween(seMousePos, seMouseDown);
					// Convert to pixel values
					var moveDist = seZoom.scaleNum(unscaledMoveDist);
					
					if (moveDist < MIN_ONSCREEN_MOVE_DISTANCE)
					{
						ScreenEditorCanvas_CancelMoveSelectedPoints();
						
						if (!modifiers.shiftKey)
							ScreenEditorCanvas_ClearSelectedPoints();
						ScreenEditorCanvas_SelectPointsNear(seMouseDown, modifiers.shiftKey /* toggle is key down */, modifiers.altKey /* option */);
					}
					else
					{
						ScreenEditorCanvas_AcceptMoveSelectedPoints();
						if (seEditOptions.showFullDesign)
							ScreenEditorCanvas_GenerateFinalRender();
					}
				}
			}
			else
			{
				var pt = {x:seMousePos.x, y:seMousePos.y};
				if (seMouseDown != undefined)
				{
					var lineLen = MathUtil.DistanceBetween(seMousePos, seMouseDown);
					
					// Convert to pixel values
					lineLen = seZoom.scaleNum(lineLen);
					
					if (lineLen >= MIN_ONSCREEN_LINE_LENGTH)
					{
						if (seNewLineIndex != undefined)
						{
							ScreenEditorCanvas_FinalizeNewLine_priv(true);
							ScreenEditorCanvas_RebuildSnapInfo();
						}
					}
					else
					{
						// Without shift, clear previous selection 
						if (!modifiers.shiftKey)
							ScreenEditorCanvas_ClearSelectedPoints();
						ScreenEditorCanvas_SelectPointsNear(seMouseDown, modifiers.shiftKey /* toggle is key down */, modifiers.altKey /* option */);

						for (var i = 0; i < seLineList.length; i++)
						{
							if (seLineList[i].mouseOver)
							{
								ScreenEditorCanvas_SelectEndpoints_priv(i, "AB", true);
							}
						}
						
						// Clear the mouse tap if anything is selected
						if (ScreenEditorCanvas_AnyEndpointsSelected())
							seMouseTap = undefined;

					}
				}
			}
		
			ScreenEditorCanvas_CancelTracking_priv();
			seMousePos = pt;
			ScreenEditorCanvas_Render();
			ScreenEditorCanvas_UpdateEditState(); // 2019.09.20: Dispatch to ScreenDesigner to update line info table
		}
		else
		{
			// Clear the mouse tap if we get here
			seMouseTap = undefined;
		}
	}

	var ScreenEditorCanvas_MouseOut_priv = function(evt)
	{
		ScreenEditorCanvas_CancelTracking_priv();
		ScreenEditorCanvas_Render();
		ScreenEditorCanvas_UpdateEditState(); // 2019.09.20: Dispatch to ScreenDesigner to update line info table
	}
	
	var ScreenEditorCanvas_UpdateEditState = function()
	{
		for (var i = 0; i < seLineList.length; i++)
		{
			var ln = seLineList[i];
			var state = {};
			
			state.mouseOver = ln.mouseOver;
			state.ptAselected = ln.ptA.selected;
			state.ptBselected = ln.ptB.selected;

			ScreenDesigner.UpdateLineEditState(ln.id, state);
		}
		
	}
	
	var ScreenEditorCanvas_SetLineEditState = function(lineIdx, state)
	{
		if (lineIdx >= 0 && lineIdx < seLineList.length)
		{
			var ln = seLineList[lineIdx];
		
			if (state.mouseOver != undefined)
			{
				ln.mouseOver = state.mouseOver;
				// 2022.02.09: At mouse over info for the endpoints
				ln.ptA.mouseOver = false;
				ln.ptB.mouseOver = false;
				if (state.ptAmouseOver != undefined)
					ln.ptA.mouseOver = state.ptAmouseOver;
				if (state.ptBmouseOver != undefined)
					ln.ptB.mouseOver = state.ptBmouseOver;
			}

			ScreenEditorCanvas_Render();
			ScreenEditorCanvas_UpdateEditState(); // 2019.09.20: Dispatch to ScreenDesigner to update line info table
		}
	}
	
	/*----------------------------------------------------------------------------------*
	 * Handle Touch
	 *----------------------------------------------------------------------------------*/
	let TouchTapRadius = 10; // Distance touch can move and still be a 'tap' or 'hold'
	
	let TouchType = Object.freeze({
		NONE		: 0,	// No touch tracked
		TAP			: 1,	// Quick press in one spot, short duration
		HOLD		: 2,	// Long press in one spot
		MOVE		: 3		// Tap and slide 
	});
	
	let TouchGesture = Object.freeze({
		NONE		: 0,	// No touch tracked
		TAP			: 1,	// Quick press in one spot, short duration
		HOLD		: 2,	// Long press in one spot
		MOVE		: 3,	// Tap and slide 
		DRAG		: 4,	// Tap + Move
		PINCH		: 5,	// Two fingers
	});
	
	var seTouchIsMovingSomething = false;
	var seTouchInfo = { 
			identifier: undefined,
			touchType: TouchType.NONE,
			touchStart:{x:0, y:0},
			timeStart:0 ,
			delta: {x:0, y:0},
			deltaToStart: {x:0, y:0}
		};
	
	var ScreenEditorCanvas_HandleTouch = function(evt, touchAction)
	{
		// Prevent browser from doing things
		evt.preventDefault();

		////// Experiments... /////
		if (0)
		{
		var touch = evt.changedTouches[0];
		seCanvasMousePos = ScreenEditorCanvas_GetTouchPos(touch);
		ScreenEditorCanvas_MouseUpdate_priv();
		}
		
		////// Don't process touches yet /////
		//return;
		//////////////////////////////////////
		
		//	Find Touch
		//		Find the touch that we are tracking in the touch list
		function findTouch(identifier, touchList)
		{
			//var touch = touchList.find( t => t.identifier == identifier );
			var touch = undefined;
			for (var i = 0; i < touchList.length && touch == undefined; i++)
				if (touchList[i].identifier == identifier)
					touch = touchList[i];
					
			return touch;
		}
		
		//	Init Touch Info
		//		Capture all the info we need to track a touch
		function initTouchInfo(touchInfo, touch)
		{
			touchInfo.touchPos = ScreenEditorCanvas_GetTouchPos(touch);
			touchInfo.touchStart = JSON.parse(JSON.stringify(touchInfo.touchPos));
			touchInfo.timeStart = (new Date()).getTime();

			touchInfo.identifier = touch.identifier;
			
			touchInfo.delta.x = 0;
			touchInfo.delta.y = 0;

			touchInfo.deltaToStart.x = 0;
			touchInfo.deltaToStart.y = 0;

			touchInfo.distanceToStart = 0;
			touchInfo.touchType = TouchType.NONE;

		}
		
		//	Update Touch Info
		//		Update the info for the touch we are tracking and compute useful values
		function updateTouchInfo(touchInfo, touch)
		{
			let pos = ScreenEditorCanvas_GetTouchPos(touch);

			touchInfo.delta.x = pos.x - touchInfo.touchPos.x;
			touchInfo.delta.y = pos.y - touchInfo.touchPos.y;

			touchInfo.deltaToStart.x = pos.x - touchInfo.touchStart.x;
			touchInfo.deltaToStart.y = pos.y - touchInfo.touchStart.y;

			touchInfo.distanceToStart = Math.sqrt(touchInfo.deltaToStart.x * touchInfo.deltaToStart.x + touchInfo.deltaToStart.y * touchInfo.deltaToStart.y);

			touchInfo.touchPos = pos;
			
			if (touchInfo.distanceToStart <= TouchTapRadius)
				touchInfo.touchType = TouchType.TAP;
			else
				touchInfo.touchType = TouchType.MOVE;
		}

		if (touchAction == "touchstart")
		{
			// If we are not tracking any touches and this is the first touch, then start tracking 
			// The 'touchesStartedNow' is to handle the case where the user puts down a fingerA,
			// then puts down fingerB, lifts fingerA, then puts down fingerA. 
			let touchesStartedNow = (evt.changedTouches.length == evt.touches.length);
			if (seTouchInfo.identifier == undefined && touchesStartedNow)
			{
				var touch = evt.changedTouches[0];
				initTouchInfo(seTouchInfo, touch);

				seCanvasMousePos = {x:seTouchInfo.touchStart.x, y:seTouchInfo.touchStart.y};
				ScreenEditorCanvas_MouseUpdate_priv();
				
				if (ScreenEditorCanvas_AnyEndpointsSelected())
				{
					var onSelectedPt = ScreenEditorCanvas_IsPointOnSelected(seMousePos);
					if (onSelectedPt)
					{
						seTouchIsMovingSomething = true;
						ScreenEditorCanvas_HandleMouseDown(seTouchInfo.touchPos, {/* no modifiers */});
					}
				}
				else if (seMouseTap != undefined)
				{
					seTouchIsMovingSomething = true;
					ScreenEditorCanvas_HandleMouseDown(seTouchInfo.touchPos, {touchStart:true});
				}
				
			}
		}
		else if (touchAction == "touchmove")
		{
			if (seTouchInfo.identifier != undefined)
			{
				// Find the touch we started with
				var touch = findTouch(seTouchInfo.identifier, evt.changedTouches);
				
				if (touch != undefined)
				{
					updateTouchInfo(seTouchInfo, touch);

					seCanvasMousePos = {x:seTouchInfo.touchPos.x, y:seTouchInfo.touchPos.y};
					ScreenEditorCanvas_MouseUpdate_priv();
				}
			}
						
		}
		else if (touchAction == "touchend")
		{
			//ScreenEditorCanvas_HandleMouseUp({/* no modifiers */});
			
			if (seTouchInfo.identifier != undefined)
			{
				// Find the point we are tracking
				var touch = findTouch(seTouchInfo.identifier, evt.changedTouches);
				
				// If we are tracking ended, then process accordingly
				if (touch != undefined)
				{
					updateTouchInfo(seTouchInfo, touch);

					if (seTouchIsMovingSomething)
					{
						// If we were tracking a move, but determined that we didn't move anywhere,
						// then cancel the tracking, otherwise consider it a move and treat it as a 
						// 'mouse up'
						if (seTouchInfo.touchType == TouchType.TAP)
							ScreenEditorCanvas_CancelTracking_priv();
						else
							ScreenEditorCanvas_HandleMouseUp({/* no modifiers */});
					}
					else
					{
						if (seTouchInfo.touchType == TouchType.TAP)
						{
							ScreenEditorCanvas_HandleMouseDown(seTouchInfo.touchPos, {touchTap:true/* no modifiers */});
							ScreenEditorCanvas_HandleMouseUp({/* no modifiers */});
						}
					}
				}
				
				// Cancel tracking if the tracking touch has ended or if there are no more touches
				if (touch != undefined || evt.touches.length == 0)
				{
					ScreenEditorCanvas_CancelTracking_priv();
					ScreenEditorCanvas_Render();
				
					seTouchInfo.identifier = undefined;
					seTouchIsMovingSomething = false;
				}
			}
		
		}
		else if (touchAction == "touchcancel")
		{
			//// <<<< Does this apply to all touches or each touch?
			if (seTouchInfo.identifier != undefined)
			{
				// Find the point we are tracking
				var touch = findTouch(seTouchInfo.identifier, evt.changedTouches);
				
				// Cancel tracking if the tracking touch has ended or if there are no more touches
				if (touch != undefined || evt.touches.length == 0)
				{
					ScreenEditorCanvas_CancelTracking_priv();
					ScreenEditorCanvas_Render();

					seTouchInfo.identifier = undefined;
				}
			}
		}
	}
	
	var ScreenEditorCanvas_IsLineOnSymmetryAxis_priv = function(ptA, ptB)
	{
		var onAxis = false;

		// Determine if either point is at the center		
		var distPtA = MathUtil.DistanceBetween(seEditTileInfo.center, ptA);
		var distPtB = MathUtil.DistanceBetween(seEditTileInfo.center, ptB);
		var ptAisCenter = MathUtil.EqualWithinTolerance(distPtA, 0, NORMAL_TOLERANCE);
		var ptBisCenter = MathUtil.EqualWithinTolerance(distPtB, 0, NORMAL_TOLERANCE);
		// Find the angles of the line from the center to each point
		var angleToPtA = MathUtil.CalcAngle(seEditTileInfo.center, ptA);
		var angleToPtB = MathUtil.CalcAngle(seEditTileInfo.center, ptB);
		
		// Move the angles into the positive range
		if (angleToPtA < 0)
			angleToPtA = 2 * Math.PI + angleToPtA;
		if (angleToPtB < 0)
			angleToPtB = 2 * Math.PI + angleToPtB;
		
		
		if (ptAisCenter && ptBisCenter)
		{
			// Really shouldn't be here, since we are processing a zero-length line
			console.log("ScreenEditorCanvas_IsLineOnSymmetryAxis_priv: zero-length line?");
			onAxis = true;
		}
		else
		{
			if (ptAisCenter || ptBisCenter || MathUtil.EqualWithinTolerance(angleToPtA, angleToPtB, NORMAL_TOLERANCE))
			{
				var testAngle = ptAisCenter ? angleToPtB : angleToPtA;

				for (var i = 0; i < seEditTileInfo.axisDirections.length && !onAxis; i++)
				{
					var ad = seEditTileInfo.axisDirections[i]
					if (MathUtil.EqualWithinTolerance(testAngle, ad, NORMAL_TOLERANCE))
					{
						onAxis = true;
					}
				}
			}
		}
		//console.log("Angle to PtA: " + MathUtil.r2d(angleToPtA) + ", Angle to PtB: " + MathUtil.r2d(angleToPtB) + ", onAxis? " + onAxis);
		
		return onAxis;
	}
	
	var ScreenEditorCanvas_IsLineOnTileEdge_priv = function(ptA, ptB)
	{
		var onTileEdge = false;
		var len = seEditTileInfo.points.length;
		for (var i = 0; i < seEditTileInfo.points.length && !onTileEdge; i++)
		{
			var distPtA = MathUtil.CalcPointToLineDistance(ptA, seEditTileInfo.points[i], seEditTileInfo.points[(i + 1) % len]);
			var distPtB = MathUtil.CalcPointToLineDistance(ptB, seEditTileInfo.points[i], seEditTileInfo.points[(i + 1) % len]);
			var ptAonEdge = MathUtil.EqualWithinTolerance(distPtA, 0, NORMAL_TOLERANCE);
			var ptBonEdge = MathUtil.EqualWithinTolerance(distPtB, 0, NORMAL_TOLERANCE);
			
			onTileEdge = (ptAonEdge && ptBonEdge);
		}
		
		return onTileEdge;
	}

	var ScreenEditorCanvas_CalcTileLine_priv = function(editorLine)
	{
		var lineInfo = {}
		var ptA = seTransform.reverse_xfrm(editorLine.ptA);
		var ptB = seTransform.reverse_xfrm(editorLine.ptB);
		lineInfo.ptA = seTransformTileToRelative.xfrm(ptA);
		lineInfo.ptB = seTransformTileToRelative.xfrm(ptB);

		lineInfo.mirror = editorLine.mirror;
		lineInfo.reflect = editorLine.reflect; // 2020.08.31
		lineInfo.rotate = editorLine.rotate;
		lineInfo.visible = editorLine.visible;
		lineInfo.width = editorLine.width;
		if (editorLine.colorId != undefined) // 2020.09.02; 2020.10.16: Changed from 'color' to 'colorId'
			lineInfo.colorId = editorLine.colorId;

		// 2022.01.29: z-values
		lineInfo.wzA = editorLine.wzA;
		lineInfo.wzB = editorLine.wzB;
		lineInfo.wzL = editorLine.wzL;
		lineInfo.wbA = editorLine.wbA;
		lineInfo.wbB = editorLine.wbB;
		lineInfo.wbL = editorLine.wbL;

		// Turn off mirror if the line on on a symmetry axis
		if (lineInfo.mirror && ScreenEditorCanvas_IsLineOnSymmetryAxis_priv(ptA, ptB))
			lineInfo.mirror = false;

		// Turn off mirror if the line is on the tile edge
		if (lineInfo.mirror && ScreenEditorCanvas_IsLineOnTileEdge_priv(ptA, ptB))
			lineInfo.mirror = false;
			
		editorLine.mirror = lineInfo.mirror;

		return lineInfo;
	}

	var ScreenEditorCanvas_UpdateLine_priv = function(lineIdx)
	{
		var editorLine = seLineList[lineIdx];
		var lineInfo = ScreenEditorCanvas_CalcTileLine_priv(editorLine);

		ScreenDesigner.UpdateLine(editorLine.id, lineInfo);

		ScreenEditorCanvas_RebuildSnapInfo();
	}

	var ScreenEditorCanvas_LoadLineData = function(lineData)
	{
		if (seNewLineIndex != undefined)
			console.log("WARNING: ScreenEditorCanvas_LoadLineData: seNewLineIndex is defined; probable integrity issue.");
			
		for (var i = 0; i < lineData.length; i++)
		{
			var ln = lineData[i];
			var editorLine = {}
		
			var ptA = seTransformTileToRelative.reverse_xfrm(ln.ptA);
			var ptB = seTransformTileToRelative.reverse_xfrm(ln.ptB);
			editorLine.ptA = seTransform.xfrm(ptA);
			editorLine.ptB = seTransform.xfrm(ptB);
			
			editorLine.visible   = ln.visible;
			editorLine.width     = ln.width;
			editorLine.mirror    = ln.mirror;
			editorLine.reflect   = ln.reflect; // 2020.08.31
			editorLine.rotate    = ln.rotate;
			editorLine.id        = ln.id;
			editorLine.colorId   = ln.colorId; // 2020.09.02; 2020.10.16: Changed to 'colorId' from 'color'
		
			editorLine.mouseOver = false;
			editorLine.selected  = false;
			
			// 2022.01.27: z-values
			editorLine.wzA = ln.wzA;
			editorLine.wzB = ln.wzB;
			editorLine.wzL = ln.wzL;
			editorLine.wbA = ln.wbA;
			editorLine.wbB = ln.wbB;
			editorLine.wbL = ln.wbL;

			seLineList.push(editorLine);
		}

		if (seEditOptions.showFullDesign)
			ScreenEditorCanvas_GenerateFinalRender();
		ScreenEditorCanvas_RebuildSnapInfo();
		
		// Clear the mouse tap location
		seMouseTap = undefined;
	}
	
	
	var ScreenEditorCanvas_StartNewLine_priv = function(ptA, ptB)
	{
		if (seNewLineIndex != undefined)
			console.log("WARNING: ScreenEditorCanvas_StartNewLine_priv: seNewLineIndex is already defined: " + seNewLineIndex);
		
		var editorLine = {}
		
		editorLine.ptA = ptA;
		editorLine.ptB = ptB;
		editorLine.mouseOver = false;
		editorLine.selected = false;
		editorLine.visible = true;
		editorLine.width = seDefaultSegmentWidth;
		
		// _CalcTileLine_ will turn off mirror if necessary
		editorLine.mirror = seRenderOptions.mirrorSeg;
		editorLine.rotate = seRenderOptions.rotateSeg % (seMaxRotate + 1);
		editorLine.reflect = seRenderOptions.reflectSeg % seMaxReflect;
		
		seLineList.push(editorLine);

		seNewLineIndex = seLineList.length - 1;
	}

	var ScreenEditorCanvas_UpdateNewLine_priv = function(newPtB)
	{
		if (seNewLineIndex == undefined)
		{
			console.log("WARNING: ScreenEditorCanvas_UpdateNewLine_priv: seNewLineIndex is not defined");
		}
		else if (seNewLineIndex != seLineList.length - 1)
		{
			console.log("WARNING: ScreenEditorCanvas_UpdateNewLine_priv: seNewLineIndex (" + seNewLineIndex + ") does not match list length less one (" + seLineList.length - 1 + ")");
		}
		else
		{
			seLineList[seNewLineIndex].ptB.x = newPtB.x;
			seLineList[seNewLineIndex].ptB.y = newPtB.y;
		}
	}
	
	var ScreenEditorCanvas_FinalizeNewLine_priv = function(keepLine)
	{
		if (seNewLineIndex == undefined)
		{
			console.log("WARNING: ScreenEditorCanvas_FinalizeNewLine_priv: seNewLineIndex is not defined");
		}
		else if (seNewLineIndex != seLineList.length - 1)
		{
			console.log("WARNING: ScreenEditorCanvas_FinalizeNewLine_priv: seNewLineIndex (" + seNewLineIndex + ") does not match list length less one (" + seLineList.length - 1 + ")");
		}
		else
		{
			if (keepLine)
			{
				ScreenEditorCanvas_PerformSnapshot();
				var lineInfo = ScreenEditorCanvas_CalcTileLine_priv(seLineList[seNewLineIndex]);
				seLineList[seNewLineIndex].id = ScreenDesigner.AddLine(lineInfo);

				ScreenEditorCanvas_RebuildSnapInfo();
				if (seEditOptions.showFullDesign)
					ScreenEditorCanvas_GenerateFinalRender();
			}
			else
			{
				seLineList.splice(seNewLineIndex, 1);
			}
			
			seNewLineIndex = undefined;
		}
	}
		
	var ScreenEditorCanvas_CalcMappingTransform = function(srcBounds, dstBounds)
	{
		var transform = new Transform();
		
		transform.CalcMappingTransform(srcBounds, dstBounds);
		
		return transform;
	}
	
	var SEC_MoveTo = function(x, y)
	{
		var p = seZoom.xfrm({x:x, y:y});
		seContext.moveTo(p.x, p.y);
	}
	
	var SEC_LineTo = function(x, y)
	{
		var p = seZoom.xfrm({x:x, y:y});
		seContext.lineTo(p.x, p.y);
	}
	
	var moveOrLineToPt = function(theContext, thePoint, isMoveToFlag, theTransform)
	{
		if (theTransform != undefined)
			thePoint = theTransform.xfrm(thePoint);
			
		if (isMoveToFlag)
			theContext.moveTo(thePoint.x, thePoint.y);
		else
			theContext.lineTo(thePoint.x, thePoint.y);
	}
	
	var ScreenEditorCanvas_CreateRGBA = function(hexColorStr, alpha)
	{
		// Convert a hex color string to an rgba() string.
		// NOTE: Input MUST be a 7 character string starting with "#"
		//
		var r = parseInt(hexColorStr.substring(1,3), 16);
		var g = parseInt(hexColorStr.substring(3,5), 16);
		var b = parseInt(hexColorStr.substring(5,7), 16);
		
		var s = "rgba(" + r + "," + g + "," + b + "," + alpha + ")";
		
		//console.log(hexColorStr + " --> " + s);
		return s;
	}
	
	var ScreenEditorCanvas_RenderPolyLists = function()
	{
		if (seEditOptions.showFullDesign && seOffsetPolyList != undefined)
		{
			var theContext = seContext;
			var theTransform = seTransform;
			theTransform = theTransform.Multiply(seZoom);
			var alpha = 0.25;
		
			var renderRef = {theContext:theContext};
			var renderConfig = {theTransform:theTransform};
			var renderSettings = {};
			renderSettings.cornerStyle = seRenderOptions.cornerStyle;
			renderSettings.cornerSize = seRenderOptions.cornerSize;
			renderSettings.cornerSize = seRenderOptions.cornerSize;
			renderSettings.renderFill = seRenderOptions.renderFill;
			renderSettings.renderLine = !(seRenderOptions.renderFill || seRenderOptions.renderLine) || seRenderOptions.renderLine;
				
			theContext.lineWidth = theTransform.scaleNum(seRenderOptions.lineWidth);
			theContext.strokeStyle = ScreenEditorCanvas_CreateRGBA(seRenderOptions.lineColor, alpha);
			theContext.fillStyle = ScreenEditorCanvas_CreateRGBA(seRenderOptions.fillColor, alpha);
		
			PolygonListRenderer.Render(seOffsetPolyList, renderRef, renderSettings, renderConfig);

			// 2022.02.16: Show lattice edges
			if (seRenderOptions.renderLattice)
				PolygonListRenderer.RenderLatticeEdge(seOffsetPolyList, renderRef, renderSettings, renderConfig);
		}
	}

	
	var ScreenEditorCanvas_RenderSnapInfo = function()
	{
		// Only show snap info if tracking the mouse, ie, if mouse is over the canvas
		if (seMousePos != undefined && !seMouseInLineInfoBox)
		{
			var clrSnapPoints     = [ "rgba(0, 0, 255, 0.25)", "#c0e0c0", "rgba(0, 255, 0, 0.5)" ];
			var clrSnapLines      = [ "rgba(0, 0, 255, 0.10)", "#c0e0c0", "rgba(0, 255, 0, 0.5)" ];
			var clrSnapDirections = [ "rgba(0, 0, 255, 0.10)", "#c0e0c0", "rgba(0, 255, 0, 0.5)" ];
			
			seContext.lineWidth = 1.0;
			
			// Snap Directions
			//seContext.strokeStyle = "rgba(0, 255, 0, 0.1)";//"#e0ffe0";
			for (var i = 0; i < seSnapInfo.directions.length; i++)
			{
				var dir = seSnapInfo.directions[i];
				if ((seEditOptions.showSnapToInfo && seEditOptions.enableSnapToGuidelines) || dir.snapped == SnappedType.SELECTED)
				{
					seContext.strokeStyle = clrSnapDirections[dir.snapped];
					seContext.beginPath();
					var r = 400; /* enough to extend outside the canvas */
					var vx = r * Math.cos(dir.angle);
					var vy = r * Math.sin(dir.angle);
					SEC_MoveTo(seMousePos.x - vx, seMousePos.y - vy);
					SEC_LineTo(seMousePos.x + vx, seMousePos.y + vy);
					seContext.stroke();	
				}
			}

			// Snap Points
			var szNormal = 2;
			var szSnapped = 6;
			for (var i = 0; i < seSnapInfo.points.length; i++)
			{
				var pt = seSnapInfo.points[i];
				if (seEditOptions.showSnapToInfo || pt.snapped == SnappedType.SELECTED)
				{
					seContext.strokeStyle = clrSnapPoints[pt.snapped];
					var sz = (pt.snapped == SnappedType.SELECTED) ? szSnapped : szNormal;
					seContext.lineWidth = (pt.snapped == SnappedType.SELECTED) ? 2.0 : 1.0;
				
					seContext.beginPath();
					var p = seZoom.xfrm(pt);
					seContext.moveTo(p.x - sz, p.y - sz);
					seContext.lineTo(p.x + sz, p.y + sz);
					seContext.moveTo(p.x + sz, p.y - sz);
					seContext.lineTo(p.x - sz, p.y + sz);
					seContext.stroke();	
				}
			}
			
			// Snap Lines
			seContext.lineWidth = 1.0;
			for (var i = 0; i < seSnapInfo.lines.length; i++)
			{
				var line = seSnapInfo.lines[i];
				// 2019.03.05: Don't show snap lines. It's too busy
				if (seEditOptions.showSnapToInfo || line.snapped == SnappedType.SELECTED)
				{
					seContext.strokeStyle = clrSnapLines[line.snapped];
					seContext.beginPath();
					SEC_MoveTo(line.ptA.x, line.ptA.y);
					SEC_LineTo(line.ptB.x, line.ptB.y);
					seContext.stroke();	
				}
			}
		}
	}

	/*
	var ScreenEditorCanvas_CalcMirroredRotatesLines = function(ptA, ptB, mirror, rotate, center)
	{
		var lines = [];
		
		if (center != undefined)
		{
			var ptAc = {x:(ptA.x - center.x), y:(ptA.y - center.y)};
			var ptBc = {x:(ptB.x - center.x), y:(ptB.y - center.y)};

			if (mirror)
			{
				var ptAn = {x:(center.x - ptAc.x), y:(center.y + ptAc.y)};
				var ptBn = {x:(center.x - ptBc.x), y:(center.y + ptBc.y)};
				lines.push({ptA:ptAn, ptB:ptBn});
			}	
			var rotateCount = seEditTileInfo.symmetrySides;
			
			// 2019.02.19: Allow half as many rotations (for squares and hexagons)
			// 2020.08.31: Support arbitrary rotation value, which means "every one",
			// "every other", "every third", etc
			var deltaAngle = Math.PI * 2.0 / rotateCount;
			if (rotate > 1 && tileInfo.symmetrySides > 3)
			{
				deltaAngle *= rotate;
				rotateCount = Math.floor(rotateCount/rotate);
			}
			//if (rotate == 2 && (rotateCount % 2 == 0))
			//	rotateCount /= 2;
			//var deltaAngle = Math.PI * 2.0 / rotateCount;
		
			var count = (rotate ? rotateCount : 1);
			for (var i = 1; i < count; i++)
			{
				//var a = seEditTileInfo.startAngle + i * deltaAngle;
				var a =  i * deltaAngle;
				var cA = Math.cos(a);
				var sA = Math.sin(a);
			
				// Original
				var ptAo = {};
				ptAo.x = cA *  ptAc.x  - sA *  ptAc.y  ;
				ptAo.y = cA *  ptAc.y  + sA *  ptAc.x  ;
				var ptBo = {};
				ptBo.x = cA *  ptBc.x  - sA *  ptBc.y  ;
				ptBo.y = cA *  ptBc.y  + sA *  ptBc.x  ;
			
				lines.push({ptA:{x:ptAo.x+ center.x, y:ptAo.y+ center.y}, ptB:{x:ptBo.x+ center.x, y:ptBo.y+ center.y}});

				if (mirror)
				{
					lines.push({ptA:{x:-ptAo.x+ center.x, y:ptAo.y+ center.y}, ptB:{x:-ptBo.x+ center.x, y:ptBo.y+ center.y}});
				}
			} 
		}
		
		return lines;
	}
	*/
	
	var ScreenEditorCanvas_RenderLines = function()
	{
		var selectedColor = "rgba(0, 0, 255, 0.25)";
		var selectedEndpointColor = "rgba(255, 0, 255, 0.25)";
		var defaultColor = "red";
		var grayColor = "rgba(0, 0, 0, 0.25)";
		var grayerColor = "rgba(0, 0, 0, 0.10)";
		
		var selectedLineColor	= 		"rgb(0, 0, 255)";
		var defaultLineColor	= 		"rgb(0, 0, 128)";
		var hoverLineColor 		= 		"rgba(0, 0, 255, 0.25)";
		var notVisibleSelectedColor =	"rgba(0, 0, 255, 0.10)";
		var notVisibleSelectedEndpointColor =	"rgba(255, 0, 255, 0.10)";
		var notVisibleColor		=		"rgba(0, 0, 255, 0.10)";

		var selectedPtColor		= 		"rgba(255, 0, 255, 0.5)";
		var hoverPtColor		= 		"rgba(0, 0, 255, 0.25)";
		var hoverPtSize			= 14;
		var selectPtSize		= 10;

		var center = undefined; 
		
		if (seEditTileInfo != undefined)
			center = seTransform.xfrm(seEditTileInfo.center);

		seContext.lineWidth = 1.0;
		for (var i = 0; i < seLineList.length; i++)
		{
			var ln = seLineList[i];
			
			var ptA = ln.ptA;
			var ptB = ln.ptB;
			
			if (ln.mouseOver)
			{
				seContext.strokeStyle = ln.visible ? selectedColor : notVisibleSelectedColor;
				seContext.lineWidth = 5.0;
				seContext.beginPath();
				SEC_MoveTo(ptA.x, ptA.y);
				SEC_LineTo(ptB.x, ptB.y);
				seContext.stroke();	
			}
			
			if (ln.ptA.selected || ln.ptB.selected)
			{
				var x = (ptA.x + ptB.x)/2;
				var y = (ptA.y + ptB.y)/2;
				seContext.strokeStyle = ln.visible ? selectedEndpointColor : notVisibleSelectedEndpointColor;
				seContext.lineWidth = 5.0;
				seContext.beginPath();
				if (ln.ptA.selected)
				{
					SEC_MoveTo(ptA.x, ptA.y);
					SEC_LineTo(x, y);
				}
				if (ln.ptB.selected)
				{
					SEC_MoveTo(x, y);
					SEC_LineTo(ptB.x, ptB.y);
				}
					
				seContext.stroke();	
			}

			// 2022.02.08: Show mouseover endpoint
			if (ln.visible && (ln.ptA.mouseOver || ln.ptB.mouseOver))
			{
				let fract = 0.33;
				seContext.strokeStyle = hoverLineColor;
				seContext.lineWidth = 8.0;
				seContext.beginPath();
				if (ln.ptA.mouseOver)
				{
					var xA = ptA.x + fract * (ptB.x - ptA.x);
					var yA = ptA.y + fract * (ptB.y - ptA.y);
					SEC_MoveTo(ptA.x, ptA.y);
					SEC_LineTo(xA, yA);
				}
				if (ln.ptB.mouseOver)
				{
					var xB = ptB.x + fract * (ptA.x - ptB.x);
					var yB = ptB.y + fract * (ptA.y - ptB.y);
					SEC_MoveTo(xB, yB);
					SEC_LineTo(ptB.x, ptB.y);
				}

				seContext.stroke();	
			}
			
			if (ln.selected && ln.visible)
				seContext.strokeStyle = selectedLineColor;
			else if (ln.selected && !ln.visible)
				seContext.strokeStyle = notVisibleSelectedColor;
			else if (!ln.selected && ln.visible)
				seContext.strokeStyle = defaultLineColor;
			else
				seContext.strokeStyle = notVisibleColor;

			seContext.lineWidth = 1.0;
			seContext.beginPath();
			SEC_MoveTo(ptA.x, ptA.y);
			SEC_LineTo(ptB.x, ptB.y);
			seContext.stroke();	
			
		
			// 2020.08.31: Use new options param
			var options = {alternativeCenter:center, reflect:ln.reflect};
			
			var lines = undefined;
			//lines = ScreenEditorCanvas_CalcMirroredRotatesLines(ptA, ptB, ln.mirror, ln.rotate, center);
			if (seEditOptions.showMirroredRotatedLines)
				lines = ScreenGenerator.CalcMirroredRotatedTileSegmentList(seEditTileInfo, ln, ln.mirror, ln.rotate, options);
			else
				lines = [{pts:[[ln.ptA.x, ln.ptA.y], [ln.ptB.x, ln.ptB.y]]}];
				
			if (lines != undefined)
			{
				//console.log(JSON.stringify(lines));
				for (var j = 0; j < lines.length; j++)
				{
					seContext.strokeStyle = ln.visible ? grayColor : grayerColor;
					if (ln.visible)
					{
						seContext.beginPath();
						SEC_MoveTo(lines[j].pts[0].x, lines[j].pts[0].y);
						SEC_LineTo(lines[j].pts[1].x, lines[j].pts[1].y);
						seContext.stroke();	
					}
				}	
				}
		}


		for (var i = 0; i < seLineList.length; i++)
		{
			var pts = [seLineList[i].ptA, seLineList[i].ptB];
			seContext.lineWidth = 1.0;
		
			for (var j = 0; j < pts.length; j++)
			{
				if (pts[j].mouseOver)
				{
					seContext.fillStyle = hoverPtColor;
					var pt = seZoom.xfrm(pts[j]);
					seContext.fillRect(pt.x - hoverPtSize/2, pt.y - hoverPtSize/2, hoverPtSize, hoverPtSize);
				}
				if (pts[j].selected)
				{
					seContext.fillStyle = selectedPtColor;
					var pt = seZoom.xfrm(pts[j]);
					seContext.fillRect(pt.x - selectPtSize/2, pt.y - selectPtSize/2, selectPtSize, selectPtSize);
				}
			}
		}
	}

	//---------------------------------------------------------------------------
	//	Format Bit Field
	//		2019.09.19: Factored out of ScreenEditorCanvas_RenderLineInfo
	//---------------------------------------------------------------------------
	var ScreenEditorCanvas_FormatBitField = function(value)
	{
		// https://www.toptal.com/designers/htmlarrows/arrows/
		let reflection  = String.fromCodePoint(0x21d1);	// up harpoon
		let rotation    = String.fromCodePoint(0x2938);	// right side arc clockwise
		let translation = String.fromCodePoint(0x2195);	// up down arrow

		let str = "";
		let bitCharsSet = [reflection, rotation, translation];
		let bitCharsClear = ["_", "_", "_"];

		value = parseInt(value);
		if (!isFinite(value))
			value = 0;

		let mask = 1
		for (var bits = 0; bits < 3; bits++)
		{
			let b = ((value & mask) != 0);
			mask = 2 * mask;
			str += (b ? bitCharsSet[bits] : bitCharsClear[bits]);
		}

		return str;
	}

	//---------------------------------------------------------------------------
	//	Get Formatted Line Info
	//		2019.09.19: Factored out of ScreenEditorCanvas_RenderLineInfo
	//---------------------------------------------------------------------------
	var ScreenEditorCanvas_GetFormattedLineInfo = function(lineList, lineIdx, invertAngle = false)
	{
		var ln = lineList[lineIdx];
		var ptA = ln.ptA;
		var ptB = ln.ptB;
		
		var ptAselected = ptA.selected;
		var ptBselected = ptB.selected;
		var ptAmouseOver = ptA.mouseOver;
		var ptBmouseOver = ptB.mouseOver;
		var mirror = (ln.mirror  == undefined) ? false : ln.mirror;
		var rotate = (ln.rotate  == undefined) ? 0     : ln.rotate;
		var visible= (ln.visible == undefined) ? false : ln.visible;
		var reflect= (ln.reflect == undefined) ? 0     : ln.reflect;
		var width = ln.width;
		// 2019.10.10: Set a flag indicating that the line is ignored. For now
		// the reason is that the line is zero-length, but other reasons could
		// be possible in the future.
		var distSq = MathUtil.DistanceSquaredBetween(ptA, ptB);
		var ignoreSegment = (distSq < 0.000001);
		
		var angle = MathUtil.CalcAngle(ptA, ptB);
		angle = MathUtil.r2d(angle);
		
		var length = MathUtil.DistanceBetween(ptA, ptB);
		length = seTransform.reverse_scaleNum(length);
		length = length.toFixed(0);
		
		// Keep angles in 0..180 range
		if (angle < 0)
			angle += 360;
		if (angle > 180)
			angle -= 180;
		// The coordinates are inverted in the canvas, so make the angles look correct
		if (invertAngle && angle > 0)
			angle = 180 - angle;	
		
		var info = {};
		info["mirror"] = mirror;
		info["rotate"] = rotate;
		info["reflect"] = reflect; // 2020.08.31
		info["angle"] = angle;
		info["length"] = length;
		info["width"] = width;
		info["visible"] = visible;
		info["ptAselected"] = {selected:ptAselected, mouseOver:ptAmouseOver};
		info["ptBselected"] = {selected:ptBselected, mouseOver:ptBmouseOver};
		info["ignored"] = ignoreSegment; // 2019.10.10: Added
		info["colorId"] = ln.colorId ? ln.colorId : 0; // 2020.10.16: Changed to colorId
		// 2022.01.26: Added z-values
		// 2022.02.03: Return empty behavior if z-value is undefined for a cleaner display
		info["wzA"] = (ln.wzA != undefined) ? ln.wzA : 0;
		info["wzB"] = (ln.wzB != undefined) ? ln.wzB : 0;
		info["wzL"] = (ln.wzL != undefined) ? ln.wzL : 0;
		info["wbA"] = (ln.wzA == undefined) ? "" : ((ln.wbA != undefined) ? ln.wbA : 0);
		info["wbB"] = (ln.wzB == undefined) ? "" : ((ln.wbB != undefined) ? ln.wbB : 0);
		info["wbL"] = (ln.wzL == undefined) ? "" : ((ln.wbL != undefined) ? ln.wbL : 0);
		info["wbA"] = ScreenEditorCanvas_FormatBitField(info["wbA"]);
		info["wbB"] = ScreenEditorCanvas_FormatBitField(info["wbB"]);
		info["wbL"] = ScreenEditorCanvas_FormatBitField(info["wbL"]);
		
		return info;
	}

	//---------------------------------------------------------------------------
	//	Render Line Info: Background
	//		2022.02.10: Factored out of ScreenEditorCanvas_RenderLineInfo
	//---------------------------------------------------------------------------
	var ScreenEditorCanvas_RenderLineInfo_Background = function(seLineInfoCols)
	{
		var transBlueColor = "rgba(0, 0, 255, 0.25)";
		var midBlueColor   = "rgba(0, 0, 255, 0.5)";
		var darkBlueColor  = "rgba(0, 0, 128, 0.5)";

		if (seLineList.length > 0)
		{
			var x = seLineInfo.origin.x;
			var y = seLineInfo.origin.y + seLineInfo.spacing.y;
			var colLeft = 0;
			var colWidth = 0;

			// Clear the background
			// 2022.02.08: Increase readability by adding a light white background
			let oldFill = seContext.fillStyle;
			let m = 5;
			seContext.fillStyle = "rgba(255, 255, 255, 0.75)";
			seContext.fillRect(seLineInfo.origin.x-m, seLineInfo.origin.y-m, seLineInfo.spacing.x+2*m, seLineInfo.spacing.y * (seLineList.length + 1)+2*m);
			seContext.fillStyle = oldFill;

			// Draw headings
			for (var j = 0; j < seLineInfoCols.length; j++)
			{
				if (seLineInfoCols[j].colType != ColType.SPACE)
					seContext.fillText(seLineInfoCols[j].label, x, y);
				x += seLineInfoCols[j].width;
			}
			// Calculate left edge (and width) of column containing the mouse
			if (seLineInfoBoxOverColumn != undefined)
			{
				colWidth = seLineInfoCols[seLineInfoBoxOverColumn].width;
				for (var j = 0; j < seLineInfoBoxOverColumn; j++)
					colLeft += seLineInfoCols[j].width;
			}
			// Draw the box background, the horizontal lines, and some highlight info
			for (var i = 0; i <= seLineList.length; i++)
			{
				var x = seLineInfo.origin.x;
				var y = seLineInfo.origin.y + (i + 1) * seLineInfo.spacing.y + 2;
				
				// Highlight row under mouse
				if (i < seLineList.length && (seLineList[i].mouseOver != undefined) && (seLineList[i].mouseOver))
				{
					seContext.fillRect(x, y, seLineInfo.spacing.x, seLineInfo.spacing.y);
					
					if (seLineInfoBoxOverColumn != undefined)
					{
						seContext.strokeStyle = darkBlueColor;
						seContext.beginPath();
						seContext.rect(seLineInfo.origin.x + colLeft, y + 1, colWidth, seLineInfo.spacing.y - 2);
						seContext.stroke();
					}
				}
				// Line between rows
				seContext.strokeStyle = transBlueColor;
				seContext.beginPath();
				seContext.moveTo(x, y);
				seContext.lineTo(x + 	seLineInfo.spacing.x, y);
				seContext.stroke();
			}
		}
	}

	//---------------------------------------------------------------------------
	//	Render Line Info: Cell
	//		2022.02.10: Factored out of ScreenEditorCanvas_RenderLineInfo
	//---------------------------------------------------------------------------
	var ScreenEditorCanvas_RenderLineInfo_Cell = function(info, column, x, y)
	{
		var cw = column.width;

		if (column.colType == ColType.RIGHT_NUM)
		{
			var s = info[column.prop];
			var tw = seContext.measureText(s).width;
			seContext.fillText(s, x + cw - tw - seLineInfo.rightMargin, y);
		}
		else if (column.colType == ColType.RIGHT_DEC)
		{
			var s = info[column.prop];
			var sLeft = Math.floor(s);
			var sRight = s - sLeft;
			var tw = seContext.measureText(sLeft).width;
			var wdec = seContext.measureText(".00").width;
			seContext.fillText(s, x + cw - tw - wdec - seLineInfo.rightMargin, y);
		}
		else if (column.colType == ColType.RADIO)
		{
			var radioOn = info[column.prop];
			seContext.beginPath();
			var cx = x + cw/2 - 2;
			var cy = y - seLineInfo.spacing.y/2 + 2;
			seContext.arc(cx, cy, seLineInfo.radius, 0, 2 * Math.PI);

			if (radioOn)
				seContext.fill();

			seContext.stroke();
		}
		else if (column.colType == ColType.MULTI_VAL)
		{
			var value = info[column.prop];
			// 2020.08.31: Show a number instead of a half-circle for values greater than one
			if (value > 1)
			{
				var s = info[column.prop];
				var tw = seContext.measureText(s).width;
				seContext.fillText(s, x + cw - tw - seLineInfo.rightMargin, y);
			}
			else
			{
				var cx = x + cw/2 - 2;
				var cy = y - seLineInfo.spacing.y/2 + 2;

				if (value == 2)
				{
					seContext.beginPath();
					seContext.arc(cx, cy, seLineInfo.radius, 0, Math.PI);
					seContext.fill();
				}
				else if (value)
				{
					seContext.beginPath();
					seContext.arc(cx, cy, seLineInfo.radius, 0, 2 * Math.PI);
					seContext.fill();
				}

				seContext.beginPath();
				seContext.arc(cx, cy, seLineInfo.radius, 0, 2 * Math.PI);
				seContext.stroke();
			}
		}
		else if (column.colType == ColType.SELECTED)
		{
			var selected  = info[column.prop].selected;
			var mouseOver = info[column.prop].mouseOver;
			seContext.beginPath();
			var r = seLineInfo.radius;
			var cx = x + cw/2 - 2;
			var cy = y - seLineInfo.spacing.y/2 + 2;
			seContext.rect(cx - r, cy - r, 2 * r, 2 *r);

			if (selected)
				seContext.fill();
			seContext.stroke();
			if (mouseOver)
			{
				r++;
				seContext.rect(cx - r, cy - r, 2 * r, 2 *r);
				seContext.stroke();
			}
		}
		else if (column.colType == ColType.SPACE)
		{
			// Just space
		}
		else if (column.colType == ColType.COLOR) // 2020.08.25
		{
			// 2020.10.16
			let colorId = info[column.prop];
			let color = ScreenDesigner.ColorPalette_GetColorById(colorId);

			seContext.beginPath();
			var r = seLineInfo.radius + 2;
			var cx = x + cw/2 - 2;
			var cy = y - seLineInfo.spacing.y/2 + 2;
			seContext.rect(cx - r, cy - r, 2 * r, 2 *r);

			if (color != undefined)
			{
				let oldFillColor = seContext.fillStyle;
				seContext.fillStyle = color;
				seContext.fill();
				seContext.fillStyle = oldFillColor;
			}
			else
			{
				/*
				let oldLineColor = seContext.strokeStyle;
				seContext.strokeStyle = "#808080";
				seContext.stroke();
				seContext.strokeStyle = oldLineColor;
				*/
			}
		}
		else if (column.colType == ColType.BIT_FIELD) // 2022.03.10
		{
			var s = info[column.prop];
			let bw = 9; // Spacing of characters
			let bo = 3; // Offset of first char
			let oldFont = seContext.font;
			let oldFill = seContext.fillStyle;
			// Change to black text, slightly larger, for readability
			seContext.font = "15px";
			seContext.fillStyle = "black";
			for (var k = 0; k < s.length; k++)
			{
				if (s[k] != "_" && s[k] != " ")
					seContext.fillText(s[k], x + bo + k * bw, y);
			}
			seContext.font = oldFont;
			seContext.fillStyle = oldFill;
		}
		else 
		{
			// Unexpected
			seContext.fillText("???", x, y);
		}
	}

	//---------------------------------------------------------------------------
	//	Render Line Info
	//---------------------------------------------------------------------------
	var ScreenEditorCanvas_RenderLineInfo = function()
	{
		let seLineInfoCols = ScreenEditorCanvas_GetLineInfoCols(); // 2022.02.08

		var transBlueColor = "rgba(0, 0, 255, 0.25)";
		var midBlueColor   = "rgba(0, 0, 255, 0.5)";
		var darkBlueColor  = "rgba(0, 0, 128, 0.5)";
		var transRedColor  = "rgba(255, 0, 0, 0.25)";
		var darkRedColor   = "rgba(255, 0, 0, 0.5)";

		seContext.fillStyle = transBlueColor;
		seContext.strokeStyle = transBlueColor;
		seContext.lineWidth = 1.0;

		// Draw the background
		ScreenEditorCanvas_RenderLineInfo_Background(seLineInfoCols);

		// Draw the line info
		for (var i = 0; i < seLineList.length; i++)
		{
			var x = seLineInfo.origin.x;
			var y = seLineInfo.origin.y + (i + 2) * seLineInfo.spacing.y;
			
			var info = ScreenEditorCanvas_GetFormattedLineInfo(seLineList, i, true /* invert angle */);

			seContext.fillStyle   = info.ignored ? "#c0c0c0" : midBlueColor;
			seContext.strokeStyle = info.ignored ? "#c0c0c0" : transBlueColor;

			// Draw the data for each column according to the data type
			for (var j = 0; j < seLineInfoCols.length; j++)
			{
				let column = seLineInfoCols[j];
				ScreenEditorCanvas_RenderLineInfo_Cell(info, column, x, y);
				x += column.width;
			}
		}
	}

	//---------------------------------------------------------------------------
	//	Render Mouse Tracking
	//---------------------------------------------------------------------------
	var ScreenEditorCanvas_RenderMouseTracking = function()
	{
		// Show mouse location, but only when the mouse is not over the line info box
		if (seMousePos != undefined && !seMouseInLineInfoBox)
		{
			// Show the position in different colors depending of whether or not
			// the mouse is snapped
			seContext.fillStyle = (seMousePos.snapped) ? "red" : "blue";
			seContext.strokeStyle= (seMousePos.snapped) ? "red" : "blue";
			seContext.lineWidth = 1.0;
			seContext.beginPath();
			
			var p = seZoom.xfrm(seMousePos);
			seContext.moveTo(p.x - 6, p.y);
			seContext.lineTo(p.x + 6, p.y);
			seContext.moveTo(p.x, p.y - 6);
			seContext.lineTo(p.x, p.y + 6);
			seContext.stroke();
			
			var p = seZoom.xfrm(seMousePos);
			seContext.fillRect(p.x, p.y, 3, 3);
		}

		// Show mouse location, but only when the mouse is not over the line info box
		if (seCanvasMousePos != undefined && !seMouseInLineInfoBox)
		{
			// Show the position in different colors depending of whether or not
			// the mouse is snapped
			seContext.strokeStyle= "#c0c0c0";
			seContext.lineWidth = 1.0;
			seContext.beginPath();
			
			var p = seCanvasMousePos;
			var s = ONSCREEN_SNAP_THRESHOLD;
			seContext.arc(p.x, p.y, s, 0, 2 * Math.PI);
			seContext.stroke();
		}


		// Show mouse tap location
		if (seMouseTap != undefined)
		{
			seContext.strokeStyle= "rgba(255, 0, 255, 0.5)";
			seContext.lineWidth = 2.0;
			seContext.beginPath();
			
			var p = seMouseTap;
			var s = ONSCREEN_SNAP_THRESHOLD*1.5;
			seContext.arc(p.x, p.y, s, 0, 2 * Math.PI);
			seContext.stroke();
		}
	}
	
	var ScreenEditorCanvas_RenderTile = function()
	{
		if (seEditTileInfo != undefined)
		{
			var transform = seTransform;
			
			// Show center
			seContext.fillStyle = "#f0f0f0";
			var center = transform.xfrm(seEditTileInfo.center);
			var p = seZoom.xfrm(center);
			seContext.fillRect(p.x - 2, p.y - 2, 4, 4);
			
			
			seContext.lineWidth = 1.0;
			
			if (seEditOptions.showTileOutline)
			{
			seContext.strokeStyle = "rgba(0, 0, 0, 0.25)";
			seContext.beginPath();
			for (var i = 0; i < seEditTileInfo.points.length; i++)
			{
				var pt = transform.xfrm(seEditTileInfo.points[i]);
				if (i == 0)
					SEC_MoveTo(pt.x, pt.y);
				else
					SEC_LineTo(pt.x, pt.y);
			}
			seContext.closePath();
			seContext.stroke();	
			}
			
			if (seEditTileInfo.subtile != undefined && seEditOptions.showSubtileOutline)
			{
				seContext.strokeStyle = "rgba(0, 0, 0, 0.25)";
				
				var pt = {x:seEditTileInfo.center.x, y:seEditTileInfo.center.y};
				var sw = seEditTileInfo.subtile.width;
				var sh = -seEditTileInfo.subtile.height;
				
				seContext.beginPath();

				var t = transform.xfrm(pt);
				SEC_MoveTo(t.x, t.y);
				pt.y += sh;
				var t = transform.xfrm(pt);
				SEC_LineTo(t.x, t.y);
				pt.x += sw;
				var t = transform.xfrm(pt);
				SEC_LineTo(t.x, t.y);
				seContext.closePath();
				seContext.stroke();	
			}
		}
	}
		
	var ScreenEditorCanvas_Render = function()
	{
		seContext.clearRect(0, 0, seCanvas.width, seCanvas.height);

		ScreenEditorCanvas_RenderTile();
		
		ScreenEditorCanvas_RenderPolyLists();
		
		ScreenEditorCanvas_RenderLines();
		
		ScreenEditorCanvas_RenderSnapInfo();

		ScreenEditorCanvas_RenderMouseTracking();
		
		if (seEditOptions.showLineList)
			ScreenEditorCanvas_RenderLineInfo();
	}
	
	var ScreenEditorCanvas_AddTilePointsToSnapList = function()
	{
		// 2020.08.18: Now using .symmetryPoints instead of .points
		var len = seEditTileInfo.symmetryPoints.length;

		if (seEditOptions.enableSnapToSymmetryLines)
		{
			// Add points
			seSnapInfo.points.push(seTransform.xfrm(seEditTileInfo.center));
			for (var i = 0; i < len; i++)
			{
				// Vertex
				seSnapInfo.points.push(seTransform.xfrm(seEditTileInfo.symmetryPoints[i]));

				// Edge center
				var ptB1 = seTransform.xfrm(seEditTileInfo.symmetryPoints[i]);
				var ptB2 = seTransform.xfrm(seEditTileInfo.symmetryPoints[(i + 1) % len]);
				var ptB = {x:(ptB1.x + ptB2.x)/2, y:(ptB1.y + ptB2.y)/2};
				seSnapInfo.points.push(ptB);


				// 1/4 center
				var ptB = {x:ptB1.x + (ptB2.x - ptB1.x)*1/4, y:ptB1.y + (ptB2.y - ptB1.y)*1/4};
				seSnapInfo.points.push(ptB);
				var ptB = {x:ptB1.x + (ptB2.x - ptB1.x)*3/4, y:ptB1.y + (ptB2.y - ptB1.y)*3/4};
				seSnapInfo.points.push(ptB);
			}

			// Add line (segments)
			for (var i = 0; i < len; i++)
			{
				// Center to vertex
				var ptA = seTransform.xfrm(seEditTileInfo.center);
				var ptB = seTransform.xfrm(seEditTileInfo.symmetryPoints[i]);
				seSnapInfo.lines.push({ptA:ptA, ptB:ptB, guide:false});
			
				// Edges
				var ptA = seTransform.xfrm(seEditTileInfo.symmetryPoints[i]);
				var ptB = seTransform.xfrm(seEditTileInfo.symmetryPoints[(i + 1) % len]);
				seSnapInfo.lines.push({ptA:ptA, ptB:ptB, guide:false});
			
				// Center to edge center
				var ptA = seTransform.xfrm(seEditTileInfo.center);
				var ptB1 = seTransform.xfrm(seEditTileInfo.symmetryPoints[i]);
				var ptB2 = seTransform.xfrm(seEditTileInfo.symmetryPoints[(i + 1) % len]);
				var ptB = {x:(ptB1.x + ptB2.x)/2, y:(ptB1.y + ptB2.y)/2};
				seSnapInfo.lines.push({ptA:ptA, ptB:ptB, guide:false});
			}
		}
		// Add directions
		seSnapInfo.directions = [];
		for (var i = 0; i < seEditTileInfo.snapDirections.length; i++)
			seSnapInfo.directions.push({angle:seEditTileInfo.snapDirections[i]});
		
		ScreenEditorCanvas_ClearSnappedFlags_priv();
	}

	var ScreenEditorCanvas_AddLinePointsToSnapList = function()
	{
		ScreenEditorCanvas_AddLinePointsToSnapList_v2();
	}
		
	var ScreenEditorCanvas_AddLinePointsToSnapList_v1 = function()
	{
		var center = seTransform.xfrm(seEditTileInfo.center);
		
		for (var i = 0; i < seLineList.length; i++)
		{
			var editorLine = seLineList[i];
			// Currently being drawn
			if ((seNewLineIndex == undefined || i != seNewLineIndex) )
			{
				if (seEditOptions.enableExtraSnapPoints)
				{
					var lines = ScreenGenerator.CalcMirroredRotatedTileSegmentList(seEditTileInfo, editorLine, editorLine.mirror, editorLine.rotate, {alternativeCenter:center, reflect:editorLine.reflect});
					
					for (var j = 0; j < lines.length; j++)
					{
						// Don't snap to items that might be moving
						if (!editorLine.ptA.selected)
							seSnapInfo.points.push(Object.assign({},lines[j].pts[0]));
					
						if (!editorLine.ptB.selected)
							seSnapInfo.points.push(Object.assign({},lines[j].pts[1]));
					
						if (!editorLine.ptA.selected && !editorLine.ptB.selected)
							seSnapInfo.lines.push({ptA:Object.assign({},lines[j].pts[0]), ptB:Object.assign({},lines[j].pts[1])});
					}
				}
				else
				{
					// Don't snap to items that might be moving
					if (!editorLine.ptA.selected)
						seSnapInfo.points.push(Object.assign({},editorLine.ptA));
					
					if (!editorLine.ptB.selected)
						seSnapInfo.points.push(Object.assign({},editorLine.ptB));
					
					if (!editorLine.ptA.selected && !editorLine.ptB.selected)
						seSnapInfo.lines.push({ptA:Object.assign({},editorLine.ptA), ptB:Object.assign({},editorLine.ptB)});
				}
			}
		}
	}
		
		
	var ScreenEditorCanvas_AddLinePointsToSnapList_v2 = function()
	{
		try {
		
			if (1)
			{
				var lineList = ScreenDesigner.CalcSnapData(seEditOptions.enableExtraSnapPoints /* include surrounding tiles */);
				//console.log("ScreenEditorCanvas_AddLinePointsToSnapList_v2: lineList.length: " + lineList.length);
				for (var i = 0; i < lineList.length; i++)
				{
					var line = lineList[i];
			
					// Don't snap to items that might be moving
					//if (!editorLine.ptA.selected)
					var ptA = seTransform.xfrm(line.ptA);
					ptA.id = line.id;
					ptA.which = "ptA";
					seSnapInfo.points.push(ptA);
			
					//if (!editorLine.ptB.selected)
					var ptB = seTransform.xfrm(line.ptB);
					ptB.id = line.id;
					ptB.which = "ptB";
					seSnapInfo.points.push(ptB);
			
					//if (!editorLine.ptA.selected && !editorLine.ptB.selected)
					seSnapInfo.lines.push({ptA:seTransform.xfrm(line.ptA), ptB:seTransform.xfrm(line.ptB), id:line.id});
				}
			}
		}
		catch (err)	{
			console.log("ScreenEditorCanvas_AddLinePointsToSnapList_v2");
			console.log(err);
		}
	}
		
	
	var ScreenEditorCanvas_RebuildSnapInfo = function()
	{
		// Clear snap list
		seSnapInfo.points = [];
		seSnapInfo.lines = [];
		seSnapInfo.directions = [];

		ScreenEditorCanvas_AddTilePointsToSnapList();
		
		if (seEditOptions.enableSnapToPointsLines)
			ScreenEditorCanvas_AddLinePointsToSnapList();
	}

	var ScreenEditorCanvas_ClearSnappedFlags_priv = function()
	{
		// Add the "snapped" flags
		for (var i = 0; i < seSnapInfo.points.length; i++)
			seSnapInfo.points[i].snapped = SnappedType.NONE;
			
		for (var i = 0; i < seSnapInfo.lines.length; i++)
			seSnapInfo.lines[i].snapped = SnappedType.NONE;
			
		for (var i = 0; i < seSnapInfo.directions.length; i++)
			seSnapInfo.directions[i].snapped = SnappedType.NONE;
	}
	
	var ScreenEditorCanvas_SetRenderOptions = function(editOptions)
	{
		seDefaultSegmentWidth = editOptions.segwidth;

		seRenderOptions = editOptions;
	}
	
	var ScreenEditorCanvas_SetEditTileInfo = function(editTileInfo)
	{
		seEditTileInfo = editTileInfo;
		
		//var w = seEditTileInfo.bounds.max.x - seEditTileInfo.bounds.min.x;
		//var h = seEditTileInfo.bounds.max.y - seEditTileInfo.bounds.min.y;
		//console.log("tile size: " + w + " x " + h);
		
		// 2020.08.06: Use seDefaultDimensions instead of seCanvas since we had implicitly 
		// designed around a fixed canvas size, but now we can change the canvas size
		var canvasBounds = {
			min:{x:seEditorMargin, y:seDefaultDimensions.height - seEditorMargin}, 
			max:{x:seDefaultDimensions.width - seEditorMargin, y:seEditorMargin}};
		seTransform = ScreenEditorCanvas_CalcMappingTransform(seEditTileInfo.bounds, canvasBounds);
				
		var unitBounds = {min:{x:0, y:0}, max:{x:1, y:1}};
		seTransformToRelative = ScreenEditorCanvas_CalcMappingTransform(canvasBounds, unitBounds);
		seTransformTileToRelative = ScreenEditorCanvas_CalcMappingTransform(seEditTileInfo.bounds, unitBounds);
		
		// 2020.09.01: Set the max rotate and reflect values based on the tile symmetry.
		seMaxRotate  = Math.floor(seEditTileInfo.symmetrySides/2);
		seMaxReflect = seEditTileInfo.symmetrySides;
		
		ScreenEditorCanvas_RebuildSnapInfo();
		
		ScreenEditorCanvas_ResetSnapshot();

		if (seEditOptions.showFullDesign)
			ScreenEditorCanvas_GenerateFinalRender();
	}

	var ScreenEditorCanvas_ResetAll = function(options = undefined)
	{
		seLineList = [];
		
		ScreenEditorCanvas_RebuildSnapInfo();
		//ScreenEditorCanvas_GenerateFinalRender();
		seOffsetPolyList = undefined;
		
		if (options == undefined || options.resetZoom == undefined || options.resetZoom)
			seZoom = new Transform();

		ScreenEditorCanvas_Render();
		
		ScreenEditorCanvas_ResetSnapshot();
	}
	
	var ScreenEditorCanvas_Zoom = function(zoomTo)
	{
		var targetZoom = undefined;

		// 2020.08.06: Use seDefaultDimensions instead of seCanvas since we had implicitly 
		// designed around a fixed canvas size, but now we can change the canvas size
		var canvasBounds = {
			min:{x:seEditorMargin, y:seDefaultDimensions.height - seEditorMargin}, 
			max:{x:seDefaultDimensions.width - seEditorMargin, y:seEditorMargin}};

		if (zoomTo == ZoomTo.FULL_TILE)
		{
			// 2020.08.06: Now that editor canvas can be larger, we can no longer use
			// a default zoom of 1:1. Instead, calculate the difference between the default
			// canvas size and the actual canvas size.
			var actualCanvasBounds = {
				min:{x:seEditorMargin, y:seCanvas.height - seEditorMargin}, 
				max:{x:seCanvas.width - seEditorMargin, y:seEditorMargin}};
			targetZoom = ScreenEditorCanvas_CalcMappingTransform(canvasBounds, actualCanvasBounds);
		}
		else if (zoomTo == ZoomTo.SMALLEST_SUBTILE)
		{
				
			var subTileBounds = {min:{x:0, y:0}, max:{x:0, y:0}};
			
			var topLeft = seTransform.xfrm(seEditTileInfo.center);
			var p = {x:seEditTileInfo.center.x + seEditTileInfo.subtile.width, y:seEditTileInfo.center.y - seEditTileInfo.subtile.height};
			var botRight = seTransform.xfrm(p);
			
			subTileBounds.min.x = topLeft.x;
			subTileBounds.max.y = topLeft.y;
			subTileBounds.max.x = botRight.x;
			subTileBounds.min.y = botRight.y;
			
			targetZoom = ScreenEditorCanvas_CalcMappingTransform(subTileBounds, canvasBounds);
		}
		
		if (targetZoom != undefined)
		{
			if (seAnimatedZoom != undefined)
			{
				seAnimatedZoom.Stop();
				seAnimatedZoom = undefined;
			}

			seAnimatedZoom = new AnimatedZoom(seZoom, targetZoom, 15, 10, ScreenEditorCanvas_SetZoomTransform, ScreenEditorCanvas_AnimatedZoomCompleted);
		}
	}
	
	var ScreenEditorCanvas_SetZoomTransform = function(zoomTransform)
	{
		seZoom = zoomTransform;
		ScreenEditorCanvas_Render();
	}

	var ScreenEditorCanvas_AnimatedZoomCompleted = function(context)
	{
		seAnimatedZoom = undefined;
	}

	var ScreenEditorCanvas_GenerateFinalRender = function()
	{
		//seOffsetPolyList = ScreenDesigner.GenerateLimitedDesign();
		// 2018.01.04: Changed to use render thread
		ScreenDesigner.RenderLimitedDesign();
	}
	
	var ScreenEditorCanvas_SetRenderedDesign = function(offsetPolyList)
	{
		// 2018.01.04: Added to handle callback from render thread
		seOffsetPolyList = offsetPolyList;
		ScreenEditorCanvas_Render();
	}
	
	/*-----------------------------------------------*
	 * Public API
	 *-----------------------------------------------*/
	return {
		Init:					ScreenEditorCanvas_Init,
		ResizeHandler:			ScreenEditorCanvas_ResizeHandler,
		GetRelativeLayout:		ScreenEditorCanvas_GetRelativeLayout,
		SetRelativeLayout:		ScreenEditorCanvas_SetRelativeLayout,
		Render:					ScreenEditorCanvas_Render,
		SetEditTileInfo:		ScreenEditorCanvas_SetEditTileInfo,
		LoadLineData:			ScreenEditorCanvas_LoadLineData,
		SetRenderOptions:		ScreenEditorCanvas_SetRenderOptions,
		SetEditOption:			ScreenEditorCanvas_SetEditOption,
		GetEditOptions:			ScreenEditorCanvas_GetEditOptions,
		SetRenderedDesign:		ScreenEditorCanvas_SetRenderedDesign,
		ResetAll:				ScreenEditorCanvas_ResetAll,
		Zoom:					ScreenEditorCanvas_Zoom,
		ZoomTo:					ZoomTo,
		CloseGaps:				ScreenEditorCanvas_CloseGaps,
		HandleDelete:			ScreenEditorCanvas_HandleDelete,
		GetFormattedLineInfo:	ScreenEditorCanvas_GetFormattedLineInfo,
		SetLineEditState:		ScreenEditorCanvas_SetLineEditState,
		ToggleLineProperty:		ScreenEditorCanvas_ToggleLineProperty,
		UpdateLineProperty:		ScreenEditorCanvas_UpdateLineProperty,
		SelectLine:				ScreenEditorCanvas_SelectLine,
		ClearMouseOver:			ScreenEditorCanvas_ClearMouseOver,
		SetWidthScale:			ScreenEditorCanvas_SetWidthScale,
		HandleArrow:			ScreenEditorCanvas_HandleArrow // 2022.02.17
	};
}());

export { ScreenEditorCanvas };
