/*-----------------------------------------------*
 * Architecture
 *
 * SegmentList
 * - collection of line segments
 * - segments endpoints in x,y coordinates
 * - generated from TriGridData
 * - generated by TriGrid
 * - generates PolygonList
 * PolygonList
 * - collection of polygons
 * - generated from SegmentList
 *-----------------------------------------------*/

/*-----------------------------------------------*
 * Configuration Values
 *-----------------------------------------------*/
var ANGLE_TOLERANCE = 100;
var NORMAL_TOLERANCE = 10000;

/*-----------------------------------------------*
 * 
 *-----------------------------------------------*/
var Debug_log = function(str)
{
	console.log(str);
}

var Debug_assert = function(str)
{
	console.log("ASSERT: " + str);
}


/*-----------------------------------------------*
 * Major Components
 * 
 * MathUtil (API)
 * SegmentList (API)
 * Polygon List (API)
 *-----------------------------------------------*/


// Transform

function Transform(srcBounds, dstBounds)
{
    this.scale = {x:1.0, y:1.0};
    this.offset = {x:0, y:0};
    
    if (srcBounds != undefined && dstBounds != undefined)
    	this.CalcMappingTransform(srcBounds, dstBounds);
}

Transform.prototype.Clone = function()
{
	var t = new Transform();
	
	t.scale.x = this.scale.x;
	t.scale.y = this.scale.y;
	t.offset.x = this.offset.x;
	t.offset.y = this.offset.y;
	return t;
}

Transform.prototype.setOffset = function(offsetX, offsetY)
{
    this.offset = {x:offsetX, y:offsetY};
}

Transform.prototype.scaleBy = function(scaleX, scaleY)
{
	this.scale.x *= scaleX;
	this.scale.y *= scaleY;
    this.offset.x *= scaleX;
    this.offset.y *= scaleY;
}

Transform.prototype.invertVertical = function()
{
	this.scale.y = -this.scale.y;
}

//--------------------------------------------------------------------------------
//	isVerticalInverted
//		Returns true is the vertical scale is negative, which means that y-positive is up
//--------------------------------------------------------------------------------
Transform.prototype.isVerticalInverted = function()
{
	return (this.scale.y < 0);
}


Transform.prototype.xfrm = function(pt)
{
	return {x:(pt.x * this.scale.x + this.offset.x), y:(pt.y * this.scale.y + this.offset.y)};
}

Transform.prototype.scaleNum = function(num)
{
	//console.log("num:" + num + ", type:" + typeof(num) + ", scale.x:" + this.scale.x + ", scale.y:" + this.scale.y);
	return num * (Math.abs(this.scale.x) + Math.abs(this.scale.y))/2;
}

Transform.prototype.reverse_scaleNum = function(num)
{
	//console.log("num:" + num + ", type:" + typeof(num) + ", scale.x:" + this.scale.x + ", scale.y:" + this.scale.y);
	return num / ((Math.abs(this.scale.x) + Math.abs(this.scale.y))/2);
}

Transform.prototype.reverse_xfrm = function(pt)
{
	var x = (pt.x - this.offset.x) / this.scale.x;
	var y = (pt.y - this.offset.y) / this.scale.y;
	return {x:x, y:y};
}

Transform.prototype.CalcMappingTransform = function(srcBounds, dstBounds, zoomLevel = undefined)
{
	// srcBounds,dstBounds: {min:{x,y}, max:{x, y}}
	// transform: {scale, offset:{x, y}
	// Use: 
	//   xDst = xSrc * transform.scale + transform.offset.x;
	var dstSize = {x:(dstBounds.max.x - dstBounds.min.x), y:(dstBounds.max.y - dstBounds.min.y) };
	var srcSize = {x:(srcBounds.max.x - srcBounds.min.x), y:(srcBounds.max.y - srcBounds.min.y)};
	
	// 2017.11.20: Added tests for zero source size
	if (MathUtil.EqualWithinTolerance(srcSize.x, 0, NORMAL_TOLERANCE))
		srcSize.x = dstSize.x;
		
	if (MathUtil.EqualWithinTolerance(srcSize.y, 0, NORMAL_TOLERANCE))
		srcSize.y = dstSize.y;
		
	var scaleX = dstSize.x / srcSize.x;
	var scaleY = dstSize.y / srcSize.y;
	
	// Use Math.abs() to allow for inverted axis (that is, flipping the vertical axis)
	var scale = (Math.abs(scaleX) < Math.abs(scaleY)) ? Math.abs(scaleX) : Math.abs(scaleY);
	
	// 2019.11.23: Optionally specify zoom level
	if (zoomLevel != undefined && typeof(zoomLevel) == "number")
		scale = zoomLevel;
		
	var sx = scale * Math.sign(scaleX);
	var sy = scale * Math.sign(scaleY);
	
	// Compute offsets that center scaled srcBounds within dstBounds
	var offsetX = (dstSize.x - srcSize.x * sx)/2 - sx * srcBounds.min.x + dstBounds.min.x;
	var offsetY = (dstSize.y - srcSize.y * sy)/2 - sy * srcBounds.min.y + dstBounds.min.y;
	
	this.scale = {x:sx, y:sy};
	this.offset = {x:offsetX, y:offsetY};
}

Transform.prototype.CalcTransformTo = function(endTransform, r)
{
	// Calculate in intermediate transform matrix between this matrix and the
	// endTransform matrix. 0 <= r <= 1.0
	//
	var a = this;
	var b = endTransform;
	var tx = new Transform();
	
	tx.scale.x  = a.scale.x  + r * (b.scale.x  - a.scale.x);
	tx.scale.y  = a.scale.y  + r * (b.scale.y  - a.scale.y);
	tx.offset.x = a.offset.x + r * (b.offset.x - a.offset.x);
	tx.offset.y = a.offset.y + r * (b.offset.y - a.offset.y);
	
	return tx;
}

Transform.prototype.AdjustScaleAroundBy = function(aroundPt, byPercent, scaleLimit)
{
	var scaleChanged = false;
	
	var newScaleX = this.scale.x + this.scale.x * byPercent;
	var newScaleY = this.scale.y + this.scale.y * byPercent;

	if (scaleLimit == undefined || (Math.abs(newScaleX) > scaleLimit && Math.abs(newScaleY) > scaleLimit))
	{
		var prevOffsetDelta = {x:(this.offset.x - aroundPt.x), y:(this.offset.y - aroundPt.y)};
		var prevScale = Object.assign({}, this.scale);
		this.scale.x = newScaleX;
		this.scale.y = newScaleY;
		
		if (scaleLimit != undefined)
		{
			if (Math.abs(this.scale.x) < scaleLimit)
				this.scale.x = scaleLimit * Math.abs(this.scale.x);

			if (Math.abs(this.scale.y) < scaleLimit)
				this.scale.y = scaleLimit * Math.sign(this.scale.y);
		}
		
		var newOffSetDeltaX = prevOffsetDelta.x * this.scale.x/prevScale.x;
		var newOffSetDeltaY = prevOffsetDelta.y * this.scale.y/prevScale.y;
	
		this.offset.x = aroundPt.x + newOffSetDeltaX;
		this.offset.y = aroundPt.y + newOffSetDeltaY;
		scaleChanged = true;
	}
	
	return scaleChanged;
}

Transform.prototype.Multiply = function(aTransform)
{
	var mx = this.Clone();
	
	mx.offset.x = aTransform.scale.x * mx.offset.x + aTransform.offset.x;
	mx.offset.y = aTransform.scale.y * mx.offset.y + aTransform.offset.y;

	mx.scale.x *= aTransform.scale.x;
	mx.scale.y *= aTransform.scale.y;
	
	return mx;
}

Transform.prototype.Validate = function() // 2020.08.03
{
	let isInvalid = (n) => (Number.isNaN(n) || !Number.isFinite(n));
	
	if (isInvalid(this.scale.x))
		this.scale.x = 1.0;

	if (isInvalid(this.scale.y))
		this.scale.y = 1.0;

	if (isInvalid(this.offset.x))
		this.offset.x = 0.0;

	if (isInvalid(this.offset.y))
		this.offset.y = 0.0;
}



//-------------------------------------------------------------------------------------
//	MathUtil (API)
//
//
//-------------------------------------------------------------------------------------
var MathUtil = (function() {
	/*-----------------------------------------------*
	 * Utility Functions
	 *
	 * DistanceSquaredBetween
	 * DistanceBetween
	 * CalcAngleDiff
	 * CalcPointBetween
	 * CalcUnitVector
	 * CalcAngle
	 * xx CalcDistancePointToLin
	 * RectIntersectionTest
	 * EqualWithinTolerance
	 * r2d
	 * 
	 *-----------------------------------------------*/

	var EqualPt2 = function(posA, posB, epsilon = 0.0001)
	{
		//let epsilon = 0.0001; // Number.EPSILON

		return ((Math.abs(posA.x - posB.x) < epsilon) && (Math.abs(posA.y - posB.y) < epsilon));
	}

	
	var DistanceSquaredBetween = function(posA, posB)
	{
		return (posA.x - posB.x) * (posA.x - posB.x) + (posA.y - posB.y) * (posA.y - posB.y);
	}

	var DistanceBetween = function(posA, posB)
	{
		return Math.sqrt(DistanceSquaredBetween(posA, posB));
	}

	/*-----------------------------------------------*
	 * Calculate Angle Difference: 
	 * Calculate the difference between two angles
	 * (in radians), and keep the result between
	 * zero and 2Pi
	 *-----------------------------------------------*/
	var CalcAngleDiff = function(angleA, angleB)
	{
		var angleDiff;
	
		angleDiff = angleA - angleB;
	
		if (angleDiff > Math.PI)
			angleDiff -= 2 * Math.PI;
		else if (angleDiff < -Math.PI)
			angleDiff += 2 * Math.PI;
	
		return angleDiff;
	}

	/*-----------------------------------------------*
	 * Calculate Point Between
	 * Calculate a point, ptC, between two points, 
	 * ptA and B, using ratio where 
	 * ptC = ptA when ratio = 0.0 and 
	 * ptC = ptB when ratio = 1.0
	 *-----------------------------------------------*/
	var CalcPointBetween = function(ptA, ptB, ratio)
	{
		var x = ptA.x + (ptB.x - ptA.x) * ratio;
		var y = ptA.y + (ptB.y - ptA.y) * ratio;
	
		return {x:x, y:y};
	}

	/*-----------------------------------------------*
	 * Radians to Degrees (for logging)
	 *-----------------------------------------------*/
	var r2d = function(r)
	{
		return Math.round(r * 180 / Math.PI);
	}

	/*-----------------------------------------------*
	 * Calc Unit Vector
	 * 2021.06.15: A single param indicates vector
	 *-----------------------------------------------*/
	var CalcUnitVector = function(pointA, pointB = undefined)
	{
		var unitV;
		
		var x = (pointB != undefined) ? (pointB.x - pointA.x) : pointA.x;
		var y = (pointB != undefined) ? (pointB.y - pointA.y) : pointA.y;
		var len = Math.sqrt(x * x + y * y);
	
		if (len > 0)
			unitV = {x:x/len, y:y/len};

		//console.log(JSON.stringify(pointA) + "  " + JSON.stringify(pointB) + " len:" + len + " unitV:" + JSON.stringify(unitV));
	
		return unitV;
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var CalcAngle = function(fromPt, toPt)
	{
		var dx = toPt.x - fromPt.x;
		var dy = toPt.y - fromPt.y;
	
		var rad = Math.atan2(dy, dx);
	
		//if (rad < 0)
		//	rad = 2 * Math.PI + rad;
		
		return rad;
	}
	
	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var CalcVertexAngle = function(ptA, ptB, ptC)
	{
		return CalcAngleDiff(CalcAngle(ptA, ptB), CalcAngle(ptB, ptC));
	}
	
	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var CalcAngleBetweenLines = function(lnA, lnB)
	{
		return CalcAngleDiff(CalcAngle(lnA.ptA, lnA.ptB), CalcAngle(lnB.ptA, lnB.ptB));
	}
	
	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var IsAngle180 = function(ptA, ptB, ptC)
	{
		// Angle is 180 degrees if slopes are negative of
		// each other, i.e., 
		// dyBA/dxBA = -dyCB/dxCB 
		// dyBA * dxCB = -dyCB * dxBA
		// dyBA * dxCB + dyCB * dxBA = 0
		var dyBA = ptB.y - ptA.y;
		var dxBA = ptB.x - ptA.x;
		var dyCB = ptC.y - ptB.y;
		var dxCB = ptC.x - ptB.x;
		
		console.log("IsAngle180: ptA:" + JSON.stringify(ptA)+", ptB:" + JSON.stringify(ptB)+", ptC:" + JSON.stringify(ptC));
		console.log("IsAngle180: dyBA:"+dyBA+", dxBA:"+dxBA+", dyCB:"+dyCB+", dxCB:"+dxCB+", dyBA*dxCB + dyCB*dxBA:"+dyBA*dxCB + dyCB*dxBA); 
		 
		return EqualWithinTolerance(dyBA*dxCB + dyCB*dxBA, 0, ANGLE_TOLERANCE);
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var CalcIntersectionOfTwoLines = function(ptS, unitVectorS, ptT, unitVectorT)
	{
		var intersection = undefined;
	
		if (unitVectorS != undefined && unitVectorT != undefined)
		{
			//console.log(JSON.stringify(ptS) + " " + JSON.stringify(unitVectorS) +" "+JSON.stringify(ptT)+" "+JSON.stringify(unitVectorT));
			var den = unitVectorS.y * unitVectorT.x - unitVectorS.x * unitVectorT.y;
			var num = (ptS.x - ptT.x) * unitVectorT.y - (ptS.y - ptT.y) * unitVectorT.x;

			var dot = unitVectorS.x * unitVectorT.x + unitVectorS.y * unitVectorT.y;
	
			// The dot product of the unit vectors give the cosine of the angle between the 
			// vectors. If this is 1.0, then the lines are parallel and pointing in the same direction
			// If this is -1.0, then the are also parallel, but pointing in the opposite direction
			if (MathUtil.EqualWithinTolerance(dot, 1.0, NORMAL_TOLERANCE))
			{
				//intersection = {x:ptS.x + offsetDistance * unitVectorS.x, y:ptS.y + offsetDistance * unitVectorS.y};
			}
			else if (MathUtil.EqualWithinTolerance(dot, -1.0, NORMAL_TOLERANCE))
			{
				// Lines point in opposite direction. Return undefined
			}
			else
			{
				var v = num/den;
				intersection = {x:ptS.x + v * unitVectorS.x, y:ptS.y + v * unitVectorS.y};
				//console.log(JSON.stringify(intersection));
			}
		}
	
		return intersection;
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var CalcParallelLineOffset = function(ptP, ptOrigin, vectorLine, vectorOffset)
	{
		// A parallel line system is described with (ptOrigin, vectorLine, vectorOffset) where a given
		// parallel line can be then be identified as (point, vector):(ptOrigin + c * vectorOffset, vectorLine)
		// (Note that vectorOffset could simply be an orthogonal vector to vectorLine, but that is
		// not a requirement. The only requirement is that vectorLine and vectorOffset are not parallel.)
		//
		// This routine calculates the value c such that ptP is on the line (ptOrigin + c * vectorOffset, vectorLine)
		// 
		// Parameters:
		//   ptP: {x, y}
		//   ptOrigin: {x, y}
		//   vectorLine: {x, y}
		//   vectorOffset: {x, y}
		// Returns 
		//   undefined, if vectorLine and vectorOffset are parallel, otherwise
		//   float, c, as described above
		//
		var c = undefined;
		var vectorT = {x:(ptP.x - ptOrigin.x), y:(ptP.y - ptOrigin.y)};
		var num = vectorT.y      * vectorLine.x - vectorT.x      * vectorLine.y;
		var den = vectorOffset.y * vectorLine.x - vectorOffset.x * vectorLine.y;
		
		//if (!MathUtil.EqualWithinTolerance(den, 0, NORMAL_TOLERANCE))
		if (Math.abs(den) > 0.01)
			c = num / den;
		
		return c;
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var CalcLineSegmentIntersection = function(ptA, ptB, ptLine, vectorLine)
	{
		// Returns 
		//   undefined, if the line segment (A->B) is parallel to vectorLine, or
		//   float, c, where 0.0 is ptA, 1.0 is ptB
		//   The intersection point is ptA + c * (ptB - ptA)
		//
		var c = undefined;
		var vectorAB = {x:(ptA.x - ptB.x),    y:(ptA.y - ptB.y)};
		var vectorLA = {x:(ptA.x - ptLine.x), y:(ptA.y - ptLine.y)};
		var num = vectorLA.y * vectorLine.x - vectorLA.x * vectorLine.y;
		var den = vectorAB.y * vectorLine.x - vectorAB.x * vectorLine.y;
		
		if (!MathUtil.EqualWithinTolerance(den, 0, NORMAL_TOLERANCE))
			c = num / den;
		
		return c;
	}
	
	var CalcLineIntersectionWithAngles = function(ptA, angleA, ptB, angleB)
	{
		var pt = undefined;
		var vecA = {x:Math.cos(angleA), y:Math.sin(angleA)};
		var vecB = {x:Math.cos(angleB), y:Math.sin(angleB)};
		
		var c = CalcLineSegmentIntersection(ptA, {x:(ptA.x + vecA.x), y:(ptA.y + vecA.y)}, ptB, vecB);
		
		if (c != undefined)
			pt = {x:(ptA.x + c * vecA.x), y:(ptA.y + c * vecA.y)};

		return pt;		
	}
	
	var CalcLineSegmentIntersectionxx = function(ptA, ptB, ptLineA, ptLineB)
	{
		// Returns 
		//   undefined, if the line segment (A->B) is parallel to lineA->lineB, or
		//   float, c, where 0.0 is ptA, 1.0 is ptB
		//   The intersection point is ptA + c * (ptB - ptA)
		//
		var c = undefined;
		var vectorLine = {x:ptLineB.x - ptLineA.x, y:ptLineB.y - ptLineA.y};
		var vectorAB = {x:(ptA.x - ptB.x),    y:(ptA.y - ptB.y)};
		var vectorLA = {x:(ptA.x - ptLineA.x), y:(ptA.y - ptLineA.y)};
		var num = vectorLA.y * vectorLine.x - vectorLA.x * vectorLine.y;
		var den = vectorAB.y * vectorLine.x - vectorAB.x * vectorLine.y;
		
		if (!MathUtil.EqualWithinTolerance(den, 0, NORMAL_TOLERANCE))
			c = num / den;
		
		return c;
	}

	var CalcSegmentIntersection = function(ptA, ptB, ptC, ptD, alwaysReturnResults = false)
	{
		// q == ptA, s = ptB - ptA
		// p == ptC, r = ptD - ptC
		// t = (q - p) x s / (r x s)
		// u = (q - p) x r / (r x s)
		//
		// Returns 
		//   undefined, if the line segment (A->B) is parallel to lineA->lineB, or
		//   float, c, where 0.0 is ptA, 1.0 is ptB
		//   The intersection point is ptA + c * (ptB - ptA)
		//
		var c = undefined
		
		var s  = {x:(ptB.x - ptA.x),    y:(ptB.y - ptA.y)};
		var r  = {x:(ptD.x - ptC.x),    y:(ptD.y - ptC.y)};
		var qp = {x:(ptA.x - ptC.x),    y:(ptA.y - ptC.y)};
		var qt = {x:(ptA.x - ptD.x),    y:(ptA.y - ptD.y)};
		var num1 = qp.y * s.x - qp.x * s.y;
		var num2 = qp.y * r.x - qp.x * r.y;
		var num3 = qt.y * s.x - qt.x * s.y;
		var den  = r.y * s.x - r.x * s.y;
		var ptIntersection = undefined;
		
		
		var segmentsParallel = MathUtil.EqualWithinTolerance(den, 0, NORMAL_TOLERANCE);
		var coincidentSegments = false; // 2017.11.13: Added
		
		if (!segmentsParallel || alwaysReturnResults)
		{
			var cCD = undefined;
			var cAB = undefined;
			
			if (!segmentsParallel)
			{
				cCD = num1 / den;
				cAB = num2 / den;
			
				// Clean-up the values (if it's near 0.0 or 1.0, then make it 0.0 or 1.0)
				if (MathUtil.EqualWithinTolerance(cAB, 0.0, NORMAL_TOLERANCE))
					cAB = 0.0;
				else if (MathUtil.EqualWithinTolerance(cAB, 1.0, NORMAL_TOLERANCE))
					cAB = 1.0;

				if (MathUtil.EqualWithinTolerance(cCD, 0.0, NORMAL_TOLERANCE))
					cCD = 0.0;
				else if (MathUtil.EqualWithinTolerance(cCD, 1.0, NORMAL_TOLERANCE))
					cCD = 1.0;
			
				// Set endpointXY to true if the intersection is at point X or point Y
				var endpointAB = MathUtil.EqualWithinTolerance(cAB, 0.0, NORMAL_TOLERANCE) || MathUtil.EqualWithinTolerance(cAB, 1.0, NORMAL_TOLERANCE);
				var endpointCD = MathUtil.EqualWithinTolerance(cCD, 0.0, NORMAL_TOLERANCE) || MathUtil.EqualWithinTolerance(cCD, 1.0, NORMAL_TOLERANCE);
				
				// Set withinXY to true if the intersection is between point X and point Y
				var withinAB = (cAB > 0 && cAB < 1.0);
				var withinCD = (cCD > 0 && cCD < 1.0);

				// Attempt to reduce rounding errors by computing the point here and passing out
				// https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection
				// 1:ptA, 2:ptB, 3:ptC, 4:ptD
				var denB = (ptA.x - ptB.x) * (ptC.y - ptD.y) - (ptA.y - ptB.y) * (ptC.x - ptD.x);
				var numX = (ptA.x * ptB.y - ptA.y * ptB.x)*(ptC.x - ptD.x) - (ptA.x - ptB.x)*(ptC.x * ptD.y - ptC.y * ptD.x);
				var numY = (ptA.x * ptB.y - ptA.y * ptB.x)*(ptC.y - ptD.y) - (ptA.y - ptB.y)*(ptC.x * ptD.y - ptC.y * ptD.x);
				ptIntersection = {x:numX/denB, y:numY/denB};

			}
			else // 2017.11.13: Test for coincident lines
			{
				// 
				var onLineTestValue = s.y * ptC.x - s.x * ptC.y + ptB.x * ptA.y - ptB.y * ptA.x;
				if (MathUtil.EqualWithinTolerance(onLineTestValue, 0.0, NORMAL_TOLERANCE))
				{
					// Lines are parallel and go through the same points.
					// Do line segments overlap?
					if (0 /* line segments overlap */) 
					{
					}
				}

			}

			if (endpointAB && endpointCD && !alwaysReturnResults)
			{
				// Do nothing. 
			}
			else if ((endpointAB && withinCD) || (endpointCD && withinAB) || (withinAB && withinCD) || alwaysReturnResults)
 			{
				// c1: float, location of intersection on line ptA..ptB, where 0.0 = ptA and 1.0 = ptB
				// c2: float, location of intersection on line ptC..ptD, where 0.0 = ptC and 1.0 = ptD
				// segmentsParallel: boolean
				// ptCinsideAB: boolean, ?? (as of 5/16/2020: Only used in 'clip against polygon')
				// ptDinsideAB: boolean, ?? (as of 5/16/2020: Only used in 'clip against polygon')
				// ptIntersection: {x, y}, intersection of lines ptA..ptB and ptC..ptD
				//
 				c = {c1:cAB, c2:cCD, segmentsParallel:segmentsParallel, ptCinsideAB:(num1 < 0), ptDinsideAB:(num3 < 0), ptIntersection:ptIntersection};
 			}
		}
		
		return c;
	}
	
	// 2021.05.11: Changed to "Signed"
	var CalcPointToLineSignedDistance = function(point, linePtA, linePtB)
	{
		var distance = undefined;
		
		var vecAB = {x:(linePtB.x - linePtA.x), y:(linePtB.y - linePtA.y)};
		
		var n = vecAB.y * point.x - vecAB.x * point.y + linePtB.x * linePtA.y - linePtB.y * linePtA.x;
		var d = Math.sqrt(vecAB.x * vecAB.x + vecAB.y * vecAB.y);
		
		if (!MathUtil.EqualWithinTolerance(d, 0.0, NORMAL_TOLERANCE))
		{
			distance = n/d
		}
		return distance;
	}
	
	var CalcPointToLineDistance = function(point, linePtA, linePtB)
	{
		var distance = CalcPointToLineSignedDistance(point, linePtA, linePtB);
		
		return (distance != undefined) ? Math.abs(distance) : undefined;
	}

	var IsPointOnLine = function(point, linePtA, linePtB)
	{
		var onLine = false;
		
		var vecAB = {x:(linePtB.x - linePtA.x), y:(linePtB.y - linePtA.y)};
		
		var n = vecAB.y * point.x - vecAB.x * point.y + linePtB.x * linePtA.y - linePtB.y * linePtA.x;
		
		onLine = MathUtil.EqualWithinTolerance(n, 0.0, NORMAL_TOLERANCE);

		return onLine;
	}
	

	var CalcNearestPointOnLine = function(point, linePtA, linePtB)
	{
		var ptOnLine = undefined;
		
		// Vector from A to B
		var vecAB = {x:(linePtB.x - linePtA.x), y:(linePtB.y - linePtA.y)};
		// Normal vector
		var vecABnormal = {x:vecAB.y, y:-vecAB.x};
		// Point such that line (point -> pointB) is perpendicular to (linePtA -> linePtB)
		var pointB = {x:(point.x + vecABnormal.x), y:(point.y + vecABnormal.y)};
		
		var cInfo = CalcSegmentIntersection(point, pointB, linePtA, linePtB, true);
		
		if (!cInfo.segmentsParallel)
		{
			var c = cInfo.c1;
			
			// 2019.03.11: Use point now available
			//ptOnLine = {x: point.x + c * vecABnormal.x, y: point.y + c * vecABnormal.y };
			ptOnLine = cInfo.ptIntersection;
			// Also return a value that indicates where on the line the intersection occurs.
			// This value is between zero and one if the intersection occurs between 
			// linePtA and linePtB
			ptOnLine.cLineAB = cInfo.c2;
			
		}
		
		return ptOnLine;
	}
	
	var AreVectorsParallel = function(vectorA, vectorB)
	{
		var den = vectorA.y * vectorB.x - vectorA.x * vectorB.y;
		
		var vectorsParallel = MathUtil.EqualWithinTolerance(den, 0, NORMAL_TOLERANCE);
		
		return vectorsParallel;
	}
	

	//----------------------------------------------------------------------------------------------------
	//	Calc Middle Angle
	//		Find the middle angle, with respect to clockwise or counterclockwise direction,
	//		between a start angle and an end angle 
	//
	//	2022.02.01: Added
	//----------------------------------------------------------------------------------------------------
	var CalcMiddleAngle = function(startAngle, endAngle, ccw)
	{
		let tau = 2 * Math.PI;

		// Calculate the angle between the start and end angle.
		let angle = endAngle - startAngle;
		
		// Bring the angle into the range of 0 .. 2pi
		while (angle < 0)
			angle += tau;
			
		while (angle > tau)
			angle -= tau;

		// Compute the middle angle and adjust by 180 deg if counter-clockwise
		let middleAngle = startAngle + angle/2;
		if (ccw)
			middleAngle -= Math.PI;
		
		return middleAngle
	}
	
	/*-----------------------------------------------*
	 * Calculate Tangent Arc Parameters
	 * Given
	 *   Points ptA, ptB, ptC
	 *   Distance distance
	 * Find Arc info
	 *   Point ptCenter
	 *   Tangent Points on lines AB and BC
	 *   Radius r
	 *   Angles angleStart, angleEnd
	 * If distance < len(ptA,ptB)/2 or len(ptB,ptC)/2
	 *   Then distance = min of the half lengths
	 *
	 *	2021.06.01: Replace 'distance' param with 'configOrDistance'
	 *	to allow for corner radius as an alternative setting. 
	 *	Precedence is given to the distance if both distance and
	 *	radius are specified. If none are specified, distance is set to
	 *	zero.
	 *	2022.02.05: Calculate the point and angle for the middle of the arc.
	 *	The render functions (canvas, SVG, DXF) use these values for
	 *	"half curve" arc vertices
	 *-----------------------------------------------*/
	var CalcTangentArcParams = function(ptA, ptB, ptC, configOrDistance)
	{
		var arcParams = undefined;
		var distance = undefined;
		var radius = undefined;
		var curveLimit = 0.5;
		
		// First, determine if the settings has a radius value
		if (configOrDistance != undefined && isFinite(configOrDistance.radius))
			radius = configOrDistance.radius;

		// Second, check for distance. If no distance is found and no radius is 
		// given, then set distance to zero.
		if (isFinite(configOrDistance))
			distance = configOrDistance;
		else if (configOrDistance != undefined && isFinite(configOrDistance.distance))
			distance = configOrDistance.distance;
		else if (radius == undefined)
			distance = 0;

		// 2022.02.12: Curve limit
		if (configOrDistance != undefined && isFinite(configOrDistance.curveLimit))
			curveLimit = configOrDistance.curveLimit;
			
		// If the vertex, that is, the angle between line AB and line BC, is either zero or 180 degrees, then return undefined
		var angleStart = MathUtil.CalcAngle(ptA, ptB);
		var angleEnd   = MathUtil.CalcAngle(ptB, ptC);
		var vertexAngle = CalcAngleDiff(angleStart, angleEnd);
		
		// If we don't have distance, then we must have radius. 
		// Attempt to compute the distance based on the radius
		if (distance == undefined)
		{
			try {
				// 2021.06.11: Changed tan() angle from vertexAngle/2 to
				// Pi/2 - vertexAngle/2
				let a = Math.PI/2 - vertexAngle/2
				distance = Math.abs(radius/Math.tan(a));
			}
			catch (err) {
				distance = 0;
			}
		}
		
		if (!EqualWithinTolerance(vertexAngle, 0, ANGLE_TOLERANCE) &&
		    !EqualWithinTolerance(vertexAngle, -Math.PI, ANGLE_TOLERANCE) &&
		    !EqualWithinTolerance(vertexAngle, Math.PI, ANGLE_TOLERANCE) &&
		    distance > 0) // 2021.06.01: Don't return anything if distance is zero
		{
			var lenAB = DistanceBetween(ptA, ptB);
			var lenBC = DistanceBetween(ptB, ptC);
			var unitAB = CalcUnitVector(ptA, ptB);
			var unitBC = CalcUnitVector(ptB, ptC);
			
			// CalcUnitVector returns undefined for zero length segments
			if (unitAB != undefined && unitBC != undefined)
			{
				// Limit the distance
				// 2022.02.12: Replace 1/2 by curveLimit to allow for curves to go up to from endpoint to endpoint
				var d = distance;
		
				if (d > lenAB * curveLimit)
					d = lenAB * curveLimit;
			
				if (d > lenBC * curveLimit)
					d = lenBC * curveLimit;
			
				// Point ptD is on line AB, d units away from B toward A
				// Point ptE is on line BC, d units away from B toward C
				var ptD = {x:ptB.x - d * unitAB.x, y:ptB.y - d * unitAB.y};
				var ptE = {x:ptB.x + d * unitBC.x, y:ptB.y + d * unitBC.y};
			
				var ccw = false;
		
				if (vertexAngle < 0)
				{
					angleStart += -Math.PI/2;
					angleEnd   += -Math.PI/2;
				}
				else
				{
					angleStart += Math.PI/2;
					angleEnd   += Math.PI/2;
					ccw = true;
				}

				// 2022.02.05: Calculate the angle between the start and end angle with respect to the direction
				var angleMiddle = CalcMiddleAngle(angleStart, angleEnd, ccw);
		
				var ptCenter = CalcIntersectionOfTwoLines(ptD, {x:unitAB.y, y:-unitAB.x}, ptE, {x:unitBC.y, y:-unitBC.x});
		
				if (ptCenter != undefined)
				{
					var r = DistanceBetween(ptD, ptCenter);

					// 2022.02.05: Calculate the middle point on the arc. 
					var ptMiddle = CalcPointBetween(ptCenter, ptB, r/DistanceBetween(ptCenter, ptB));
		
					arcParams = { center:ptCenter, ptTangentAB:ptD, ptTangentBC:ptE, radius:r, distance:d, 
								startAngle:angleStart, endAngle:angleEnd, ccw:ccw, middleAngle:angleMiddle, ptMiddle };
				}
			}
		}
		return arcParams;
	}
	
	/*-----------------------------------------------*
	 * Calculate Quad Bezier Parameters
	 * Given
	 *   Points ptA, ptB, ptC
	 *   Distance distance
	 * Find Quad Bezier info
	 *   Tangent Points on lines AB and BC
	 * If distance < len(ptA,ptB)/2 or len(ptB,ptC)/2
	 *   Then distance = min of the half lengths
	 *
	 * 2022.02.12: Replace 'distance' with 'options'
	 *-----------------------------------------------*/
	var CalcQuadBezierParams = function(ptA, ptB, ptC, options)
	{
		var quadParams = undefined;
		var distance = 0;
		var curveLimit = 0.5;

		// Check for distance. 
		if (isFinite(options))
			distance = options;
		else if (options != undefined && isFinite(options.distance))
			distance = options.distance;

		// 2022.02.12: Curve limit
		if (options != undefined && isFinite(options.curveLimit))
			curveLimit = options.curveLimit;
			
		
		// If the vertex, that is, the angle between line AB and line BC, is either zero or 180 degrees, then return undefined
		var angleStart = MathUtil.CalcAngle(ptA, ptB);
		var angleEnd   = MathUtil.CalcAngle(ptB, ptC);
		var vertexAngle = CalcAngleDiff(angleStart, angleEnd);
		
		if (!EqualWithinTolerance(vertexAngle, 0, ANGLE_TOLERANCE) &&
		    !EqualWithinTolerance(vertexAngle, -Math.PI, ANGLE_TOLERANCE) &&
		    !EqualWithinTolerance(vertexAngle, Math.PI, ANGLE_TOLERANCE))
		{
			var lenAB = DistanceBetween(ptA, ptB);
			var lenBC = DistanceBetween(ptB, ptC);
			var unitAB = CalcUnitVector(ptA, ptB);
			var unitBC = CalcUnitVector(ptB, ptC);
			
			// CalcUnitVector returns undefined for zero length segments
			if (unitAB != undefined && unitBC != undefined)
			{
				// Limit the distance
				// 2022.02.12: Replace 1/2 with curveLimit
				var dAB = distance;
				var dBC = distance;
		
				if (dAB > lenAB * curveLimit)
					dAB = lenAB * curveLimit;
			
				if (dBC > lenBC * curveLimit)
					dBC = lenBC * curveLimit;
					
				if (1 /* symmetric */)
				{
					if (dAB > dBC)
						dAB = dBC;
					else if (dBC > dAB)
						dBC = dAB
				}
			
				// Point ptD is on line AB, d units away from B toward A
				// Point ptE is on line BC, d units away from B toward C
				var ptD = {x:ptB.x - dAB * unitAB.x, y:ptB.y - dAB * unitAB.y};
				var ptE = {x:ptB.x + dBC * unitBC.x, y:ptB.y + dBC * unitBC.y};
				
				// 2022.03.10: Calculate "half curve" values
				var ptMidAB = {x:(ptD.x + ptB.x)/2, y:(ptD.y + ptB.y)/2 };
				var ptMidBC = {x:(ptB.x + ptE.x)/2, y:(ptB.y + ptE.y)/2 };
				var ptMiddle = {x:(ptMidAB.x + ptMidBC.x)/2, y:(ptMidAB.y + ptMidBC.y)/2 };
					
				quadParams = { ptTangentAB:ptD, ptTangentBC:ptE, ptMidAB, ptMidBC, ptMiddle };
			}
		}
		return quadParams;
	}
	
	/*-----------------------------------------------*
	 * Rect Intersection Test
	 *-----------------------------------------------*/
	var RectIntersectionTest = function(r1, r2)
	{
		var result = false;
	
		if (r1.x + r1.width < r2.x)
		{ }
		else if (r2.x + r2.width < r1.x)
		{ }
		else if (r1.y + r1.height < r2.y)
		{ }
		else if (r2.y + r2.height < r1.y)
		{ }
		else
		{
			result = true;
		}
	
		return result;
	}

	var PolygonIntersectionResult = Object.freeze({
		NONE		: 0,
		PARTIAL		: 1,
		CONTAINED	: 2
		});

	var DoPolygonsIntersect = function(testPolygon, clipPolygon)
	{
		// Determine if the test polygon intersects the clipPolygon
		//
		var result = PolygonIntersectionResult.NONE;
		
		var edgesInside = 0;
		var edgesOutside = 0;
		var edgesBoth = 0;
		
		for (var i = 0; i < testPolygon.length; i++)
		{
			var ptA = testPolygon[i];
			var ptB = testPolygon[(i + 1) % testPolygon.length];
			
			var clipResult = ClipSegmentAgainstPolygon(ptA, ptB, clipPolygon);
			
			if (clipResult == undefined)
				edgesInside++;
			else if (clipResult.ignoreSegment)
				edgesOutside++;
			else
				edgesBoth++;
		}
		
		if (edgesBoth > 0)
			result = PolygonIntersectionResult.PARTIAL;
		else if (edgesInside == 0 && edgesOutside > 0)
			result = PolygonIntersectionResult.NONE;
		else if (edgesInside > 0 && edgesOutside == 0)
			result = PolygonIntersectionResult.CONTAINED;
		else if (edgesInside > 0 && edgesOutside > 0)
			result = PolygonIntersectionResult.PARTIAL;
		else
			console.log("DoPolygonsIntersect: log error: edgesInside:" + edgesInside + ", edgesOutside:" + edgesOutside + ", edgesBoth:" + edgesBoth + ", edge count:" + testPolygon.length);
			
			
		return result;
	}

	var ClipSegmentAgainstPolygon = function(pointA, pointB, clipPolygon)
	{
		// Clip the segment, defined by pointA -> pointB, against the (convex) polygon, given as
		// a list of points in clipPolygon.
		// Return:
		//   undefined: the segment lies completely within the clipPolygon
		//   {ignoreSegment:true}: the segment lies complete outside of the clipPolygon
		//   {ptA:{x, y}, ptB:{x, y}}: a new segment that lies within clipPolygon
		//
		
		var ptPair = undefined;
		var ptAinside = true;
		var ptBinside = true;
		var ptList = [];
		
		var intersectionList = []; // 2022.05.16: Stores {c, edgeIdx} instead of c; edgeIdx may be undefined

		// Build a list of intersections between the line segment and each edge in the polygon.
		// Also do a simple test to determine if the endpoints are in the polygon
		for (var i = 0; i < clipPolygon.length; i++)
		{
			var clipPtA = clipPolygon[i];
			var clipPtB = clipPolygon[(i + 1) % clipPolygon.length];
		
			// Determine where the line segment lies in relation to the polygon edge
			var cPair = MathUtil.CalcSegmentIntersection(clipPtA, clipPtB, pointA, pointB, true /* always return results */);
		
			if (cPair != undefined)
			{
				if (cPair.c1 >= 0 && cPair.c1 <= 1.0 && cPair.c2 >= 0 && cPair.c2 <= 1.0)
					intersectionList.push({c:cPair.c2, edgeIdx:i});
				
				// Simple test to determine if a point is outside a CONVEX polygon.
				// A different test is needed for concave or complex polygons.
				if (!cPair.ptCinsideAB)
					ptAinside = false;
					
				if (!cPair.ptDinsideAB)
					ptBinside = false;
			}
		}
		
		// Add the endpoints that are inside the polygon
		if (ptAinside)
			intersectionList.push({c:0.0});
			
		if (ptBinside)
			intersectionList.push({c:1.0});
			
		// Sort the intersection list
		intersectionList.sort(function(a, b) {return a.c - b.c});
		
		// Remove duplicate items from the list. This can happen if a line
		// intersects the endpoints of more than one line at the same location, such
		// as the corner of a frame.
		for (var k = intersectionList.length - 1; k > 0; k--)
			if (MathUtil.EqualWithinTolerance(intersectionList[k].c, intersectionList[k - 1].c, NORMAL_TOLERANCE))
				intersectionList.splice(k, 1);
		
		// Create a list of points, each pair represents a start/end of a line segment
		// 2022.05.16: Added clipPolyEdgeIdx to point
		for (var i = 0; i < intersectionList.length; i++)
		{
			var c = intersectionList[i].c;
			var p;
			
			if (MathUtil.EqualWithinTolerance(c, 0.0, NORMAL_TOLERANCE))
				p = pointA;
			else if (MathUtil.EqualWithinTolerance(c, 1.0, NORMAL_TOLERANCE))
				p = pointB;
			else
				p = {x: pointA.x + c * (pointB.x - pointA.x), y: pointA.y + c * (pointB.y - pointA.y), clipPolyEdgeIdx:intersectionList[i].edgeIdx};
			
			ptList.push(p);			
		}
		
		//if ((ptList.length % 2 != 0) || (intersectionList.length % 2 != 0))
		//{
		//	console.log("ClipSegmentAgainstPolygon: Unusual list length");
		//	console.log("c-values: " + JSON.stringify(intersectionList));
		//	console.log("points: " + JSON.stringify(ptList));
		//}
		
		// If the point list is empty, then return 'ignore segment'
		if (ptList.length == 0)
		{
			ptPair = {};
			ptPair.ignoreSegment = true;
		}
		// If the point list has one item, that means the segment probably 
		// intersected at the vertex of the polygon
		// NOTE: This will need to be re-evaluated for concave or complex polygons
		else if (ptList.length == 1)
		{
			ptPair = {};
			ptPair.ignoreSegment = true;
		}
		// If the point list has only the beginning and end points, then return indicator
		// that segment lies within polygon
		else if (ptAinside & ptBinside && ptList.length == 2)
		{
		}
		else
		{
			ptPair = {};
			ptPair.ptA = ptList[0];
			ptPair.ptB = ptList[1];
		}
			
		return ptPair;
	}

	var ScaleSizeIntoContainerSize = function(originalSize, containerSize)
	{
		var scaleX = containerSize.x / originalSize.x;
		var scaleY = containerSize.y / originalSize.y;
		var scale = (scaleX < scaleY) ? scaleX : scaleY;
		var scaledSize = {x:(originalSize.x * scale), y:(originalSize.y * scale)};
		
		return scaledSize;
	}


	function RotatePointAroundOrigin(pt, angle)
	{
		var rad = angle * Math.PI / 180.0;
		var x = pt.x * Math.cos(rad) - pt.y * Math.sin(rad);
		var y = pt.x * Math.sin(rad) + pt.y * Math.cos(rad);	
		return {x:x, y:y};
	}

	function RotatePoint(pt, refPt, angleRadians)
	{
		var x = refPt.x + (pt.x - refPt.x) * Math.cos(angleRadians) - (pt.y - refPt.y) * Math.sin(angleRadians);
		var y = refPt.y + (pt.x - refPt.x) * Math.sin(angleRadians) + (pt.y - refPt.y) * Math.cos(angleRadians);	
		return {x:x, y:y};
	}

	var CalcRotatedFrameBounds = function(frameBounds, rotation)
	{
		var pts = [];
		
		pts.push({x:frameBounds.min.x, y:frameBounds.min.y});
		pts.push({x:frameBounds.max.x, y:frameBounds.min.y});
		pts.push({x:frameBounds.max.x, y:frameBounds.max.y});
		pts.push({x:frameBounds.min.x, y:frameBounds.max.y});
		
		for (var i = 0; i < 4; i++)
			pts[i] = MathUtil.RotatePointAroundOrigin(pts[i], rotation);
			
		var rotatedFrameBounds = { min: {x: pts[0].x, y: pts[0].y}, max: {x: pts[0].x, y:pts[0].y} };
		
		for (var i = 1; i < 4; i++)
		{
			var x = pts[i].x;
			var y = pts[i].y;
			
			if (rotatedFrameBounds.min.x > x)
				rotatedFrameBounds.min.x = x;
			if (rotatedFrameBounds.max.x < x)
				rotatedFrameBounds.max.x = x;
			if (rotatedFrameBounds.min.y > y)
				rotatedFrameBounds.min.y = y;
			if (rotatedFrameBounds.max.y < y)
				rotatedFrameBounds.max.y = y;
				
		}
		
		return rotatedFrameBounds;
	}

	var FindPolygonBounds = function(polygon)
	{
		var bounds = {min:{x:polygon[0].x, y:polygon[0].y}, max:{x:polygon[0].x, y:polygon[0].y} };
		
		const minMaxBounds = (b, pt) =>  
		{
			if (pt.x < b.min.x) b.min.x = pt.x;
			if (pt.x > b.max.x) b.max.x = pt.x;
			if (pt.y < b.min.y) b.min.y = pt.y;
			if (pt.y > b.max.y) b.max.y = pt.y;
			
			return b;
		}
		
		bounds = polygon.reduce(minMaxBounds, bounds);
		
		return bounds;
	}

	// returns true if the point is within the bounds, or just outside by the 'distance'
	var IsPointInBounds = function(pt, b, d = 0)
	{
		return ( (b.min.x - d < pt.x) && (b.min.y - d < pt.y) && (pt.x < b.max.x + d) && (pt.y < b.max.y + d) );
	}

	/*-----------------------------------------------*
	 *	Is Point In Polygon
	 *
	 *	
	 *-----------------------------------------------*/
	var IsPointInPolygon = function(polygon, point)
	{
		return (PolygonClosestEdgeToPoint(polygon, point) != undefined);
	}
	
	/*-----------------------------------------------*
	 *	Polygon Closest Edge To Pt
	 *
	 *	
	 *-----------------------------------------------*/
	var PolygonClosestEdgeToPoint = function(polygon, point)
	{
		let closestEdgeIdx = undefined;
		let closestEdgeDist = undefined;
		let bounds = Polygon_FindBounds(polygon);
		let done = false;
				
		if (IsPointInBounds(point, bounds))
		{
			let len = polygon.length
			for (var i = 0; i < len && !done; i++)
			{
				let dist = -CalcPointToLineSignedDistance(point, polygon[i], polygon[(i + 1) % len]);
		
				if ((dist != undefined) && (dist > 0) && (closestEdgeDist == undefined || dist < closestEdgeDist))
				{
					closestEdgeDist = dist;
					closestEdgeIdx = i;
				}
				else if ((dist != undefined) && (dist < 0))
				{
					closestEdgeIdx = undefined;
					done = true;
				}
			}		
		}
		
		return closestEdgeIdx;
	}
	
	/*-----------------------------------------------*
	 *	Slice Closed Polygon
	 *
	 *	Returns an object with the polygon divided
	 *-----------------------------------------------*/
	var SliceClosedPolygon = function(pointList, linePt, lineVector)
	{
		let points = [];
		let results = undefined;
		let linePtA = {x:linePt.x, y:linePt.y};
		let linePtB = {x:linePt.x + lineVector.x, y:linePt.y + lineVector.y};
		
		// Copy the point list
		pointList.forEach(pt => points.push({x:pt.x, y:pt.y}));
		
		// First, determine if the points are all on one side of the line or the other by
		// calculating the signed distance between a point and a line
		let nMin = undefined;
		let nMax = undefined;
		var d = Math.sqrt(lineVector.x * lineVector.x + lineVector.y * lineVector.y);
		for (var i = 0; i < points.length; i++)
		{
			let pt = points[i];
			var dist = (lineVector.y * pt.x - lineVector.x * pt.y + linePtB.x * linePtA.y - linePtB.y * linePtA.x)/d;

			pt.dist = dist;
			
			if (nMin == undefined || nMin > dist)
				nMin = dist;
			if (nMax == undefined || nMax < dist)
				nMax = dist;
		}		
		
		if (nMin < 0 && nMax > 0)
		{
			//console.log(nMin.toFixed(4), nMax.toFixed(4));
			// Find where each segment intersects the line, if it exists
			for (var i = 0; i < points.length; i++)
			{
				let ptC = points[i];
				let ptD = points[(i + 1) % points.length];
				let result = CalcSegmentIntersection(linePtA, linePtB, ptC, ptD, true /*alwaysReturnResults*/);
			
				if (result != undefined && result.c2 >= 0.0 && result.c2 <= 1.0)
				{
					points[i].slice = result.ptIntersection;
				}
			}
			
			let left = {points:[]};
			let right = {points:[]};
			let s = undefined;
			
			for (var i = 0; i < points.length; i++)
			{
				let thisPt = points[i];
				let nextPt = points[(i + 1) % points.length];
				
				if (thisPt == undefined)
					console.log(i, "missing thisPt")
					
				if (Math.sign(thisPt.dist) == 1)
				{
					left.points.push(thisPt);
					
					if (Math.sign(nextPt.dist) == -1)
					{
						if (thisPt.slice == undefined)
							console.log(i, "missing slicePt")
							
						left.points.push(thisPt.slice);
						right.points.push(thisPt.slice);
					}
				}
				else if (Math.sign(thisPt.dist) == -1)
				{
					right.points.push(thisPt);
					
					if (Math.sign(nextPt.dist) == 1)
					{
						if (thisPt.slice == undefined)
							console.log(i, "missing slicePt")

						left.points.push(thisPt.slice);
						right.points.push(thisPt.slice);
					}
				}
				else
				{
					// Point on the slice line, so add it to both sides
					left.points.push(thisPt);
					right.points.push(thisPt);
				}
			}
			
			if (left.points.length > 0 && right.points.length > 0)
			{
				results = {left:left, right:right, sliced:true};
			}
		}
		else if (nMax > 0)
		{
			results = {left:{points:pointList}, sliced:false};
		}
		else
		{
			results = {right:{points:pointList}, sliced:false};
		}
		
		return results;
	}
	
	/*-----------------------------------------------*
	 *	Clip Polygon To Polygon
	 *
	 *	Returns the clipped polygon within another polygon.
	 *	Only works with convex polygons.
	 *	2021.03.26: Added
	 *-----------------------------------------------*/
	var ClipPolygonToPolygon = function(srcPolygon, clipPolygon)
	{
		let polygon = srcPolygon;
		
		let len = clipPolygon.length;
		
		// Slice the polygon with each edge of the clipPolygon. 
		for (var i = 0; i < len && polygon != undefined; i++)
		{
			let pt = clipPolygon[i];
			let nx = clipPolygon[(i + 1) % len];
			let vector = {x:nx.x - pt.x, y:nx.y - pt.y};
			let result = SliceClosedPolygon(polygon, pt, vector);
			
			if (result != undefined && result.right != undefined)
				polygon = result.right.points;
			else
				polygon = undefined;
		}
		
		return polygon;
	}
	
	/*-----------------------------------------------*
	 *	Line Intersects Polygon Test
	 *
	 *	Returns an object with the polygon divided
	 *-----------------------------------------------*/
	var LineIntersectsPolygonTest = function(pointList, linePt, lineVector, options)
	{
		let linePtB = {x:linePt.x + lineVector.x, y:linePt.y + lineVector.y};
		
		// First, determine if the points are all on one side of the line or the other by
		// calculating the signed distance between a point and a line
		let nMin = undefined;
		let nMax = undefined;
		var den = Math.sqrt(lineVector.x * lineVector.x + lineVector.y * lineVector.y);
		for (var i = 0; i < pointList.length; i++)
		{
			let pt = pointList[i];
			var dist = (lineVector.y * pt.x - lineVector.x * pt.y + linePtB.x * linePt.y - linePtB.y * linePt.x)/den;
			
			if (options != undefined && options.recordDistance != undefined)
			{
				let prop = (typeof options.recordDistance == "string") ? options.recordDistance : "distanceToLine";
				pt[prop] = dist;
			}
			
			if (nMin == undefined || nMin > dist)
				nMin = dist;
				
			if (nMax == undefined || nMax < dist)
				nMax = dist;
		}		
		
		let results = (nMin < 0 && nMax > 0);
		
		return results;
	}
	
	/*-----------------------------------------------*
	 * 	Reflect Point Over Angle
	 *-----------------------------------------------*/
		// 2020.08.31: Mirror angle: 0 is a vertical line
		// cos(-a) =  cos(a) 
		// sin(-a) = -sin(a)
	var ReflectPointOverAngle = function(pt, angle)
	{
		var ptR = {x:pt.x, y:pt.y}
		
		if (angle == 0)
		{
			ptR.x = -ptR.x
		}
		else 
		{
			var t = {}
			var cA = Math.cos(-angle);
			var sA = Math.sin(-angle);
			
			// Rotate
			t.x = cA *  ptR.x  - sA *  ptR.y;
			t.y = cA *  ptR.y  + sA *  ptR.x;
			// Mirror
			t.x = -t.x
			// Rotate back
			ptR.x = cA *  t.x  + sA *  t.y;
			ptR.y = cA *  t.y  - sA *  t.x;
		}
		
		return ptR
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var EqualWithinTolerance = function(valA, valB, toleranceBase)
	{
		var diff = Math.abs(valA - valB);
	
		//var equal = (Math.floor(diff * toleranceBase) == 0);
		var equal = (diff < 1/toleranceBase);
	
		return equal
	}
	
	//-------------------------------------------------------------------------------------
	//	CalcOffsetVertex
	//-------------------------------------------------------------------------------------
	var CalcOffsetVertex = function(threePointList, offsetDistanceAB, offsetDistanceCD)
	{
		var offsetVtx = undefined;
		var A = threePointList[0];
		var B = threePointList[1];
		var C = threePointList[1];
		var D = threePointList[2];

		var unitAB = CalcUnitVector(A, B);
		var unitCD = CalcUnitVector(C, D);
	
		if (offsetDistanceCD == undefined)
			offsetDistanceCD = offsetDistanceAB;

		// MathUtil.CalcUnitVector returns undefined for vectors of zero length
		if (unitAB != undefined && unitCD != undefined)
		{
			var E = {x:(B.x + offsetDistanceAB * unitAB.y), y:(B.y - offsetDistanceAB * unitAB.x)};
			var F = {x:(D.x + offsetDistanceCD * unitCD.y), y:(D.y - offsetDistanceCD * unitCD.x)};

			var den = unitAB.y * unitCD.x - unitAB.x * unitCD.y;
			var num = (E.x - F.x) * unitCD.y - (E.y - F.y) * unitCD.x;

			var dot = unitAB.x * unitCD.x + unitAB.y * unitCD.y;

			// The dot product of the unit vectors give the cosine of the angle between the 
			// vectors. If this is 1.0, then the lines are parallel and pointing in the same direction
			// If this is -1.0, then the are also parallel, but pointing in the opposite direction
			if (MathUtil.EqualWithinTolerance(dot, 1.0, NORMAL_TOLERANCE))
			{
				// Lines point in the same direction. Return the point (already calculated) that
				// is offsetDistance away.
				offsetVtx = E; //{x:E.x + offsetDistance * unitAB.x, y:E.y + offsetDistance * unitAB.y};
			}
			else if (MathUtil.EqualWithinTolerance(dot, -1.0, NORMAL_TOLERANCE))
			{
				// Lines point in opposite direction. Return undefined
			}
			else
			{
				var v = num/den;
				offsetVtx = {x:E.x + v * unitAB.x, y:E.y + v * unitAB.y};
			}
		}

		return offsetVtx;
	}
	

	//-------------------------------------------------------------------------------------
	//	CalcOffsetPolygon
	//		2021.04.30: Started using again. Updated a little
	//-------------------------------------------------------------------------------------
	var CalcOffsetPolygon = function(inputPolygon, offsetDistance)
	{
		var outputPolygon = [];
		var offsetPoly = [];
		var threePoints = [];

		for (var v = 0; v < inputPolygon.length; v++)
		{
			threePoints[0] = inputPolygon[(v == 0) ? (inputPolygon.length - 1) : (v-1)];
			threePoints[1] = inputPolygon[v];
			threePoints[2] = inputPolygon[(v+1) % inputPolygon.length];	
	
			var vtx = CalcOffsetVertex(threePoints, offsetDistance, offsetDistance);
	
			// Add the offset vertex if one could be computed
			if (vtx != undefined)
			{
				//vtx.tag = threePoints[1].tag;
				//outputPolygon.push(vtx);
				// 2022.03.03: Copy all of the properties from the original vertex
				let v = Object.assign({}, threePoints[1], vtx);
				outputPolygon.push(v);
			}
			else
			{
				vtx = Object.assign({}, vtx);
				outputPolygon.push(vtx);
			}
		}
		
		let reversedEdge = false;
		for (var i = 0; i < inputPolygon.length && !reversedEdge; i++)
		{
			let a = MathUtil.CalcAngleBetweenLines(
						{ptA:inputPolygon[i], ptB:inputPolygon[(i + 1) % inputPolygon.length]},
						{ptA:outputPolygon[i], ptB:outputPolygon[(i + 1) % outputPolygon.length]});
			
			let band = 0.001;
			if (Math.abs(a) >= Math.PI - band && Math.abs(a) <= Math.PI + band)
				reversedEdge = true;
		}

		if (reversedEdge)
			outputPolygon = undefined;
			
		return outputPolygon;
	}

	/*-----------------------------------------------*
	 *	Identity Matrix (3x3)
	 *-----------------------------------------------*/
	var IdentityMat = function()
	{
		return [[1,0,0],[0,1,0],[0,0,1]];
	}
	
	/*-----------------------------------------------*
	 *	Translate Matrix (3x3)
	 *-----------------------------------------------*/
	var TranslateMat = function(translate)
	{
		var m = IdentityMat();
		m[0][2] = translate.x;
		m[1][2] = translate.y;
		return m;
	}
	
	/*-----------------------------------------------*
	 *	Rotate Matrix (3x3)
	 *-----------------------------------------------*/
	var RotateMat = function(angleRadians)
	{
		var m = IdentityMat();
		m[0][0] = Math.cos(angleRadians);
		m[0][1] = Math.sin(angleRadians);
		m[1][1] = m[0][0];
		m[1][0] = -m[0][1];
		return m;
	}
	
	/*-----------------------------------------------*
	 *	Multiply Matrices (3x3)
	 *-----------------------------------------------*/
	var MultMat = function(a,b)
	{
		var m = IdentityMat();
		m[0][0] = a[0][0]*b[0][0] + a[0][1]*b[1][0] + a[0][2]*b[2][0];
		m[0][1] = a[0][0]*b[0][1] + a[0][1]*b[1][1] + a[0][2]*b[2][1];
		m[0][2] = a[0][0]*b[0][2] + a[0][1]*b[1][2] + a[0][2]*b[2][2];

		m[1][0] = a[1][0]*b[0][0] + a[1][1]*b[1][0] + a[1][2]*b[2][0];
		m[1][1] = a[1][0]*b[0][1] + a[1][1]*b[1][1] + a[1][2]*b[2][1];
		m[1][2] = a[1][0]*b[0][2] + a[1][1]*b[1][2] + a[1][2]*b[2][2];

		m[2][0] = a[2][0]*b[0][0] + a[2][1]*b[1][0] + a[2][2]*b[2][0];
		m[2][1] = a[2][0]*b[0][1] + a[2][1]*b[1][1] + a[2][2]*b[2][1];
		m[2][2] = a[2][0]*b[0][2] + a[2][1]*b[1][2] + a[2][2]*b[2][2];
		
		return m;
	}

	/*-----------------------------------------------*
	 *	Multiply Matrix List (3x3)
	 *-----------------------------------------------*/
	var MultMatList = function(mlist)
	{
		var m = IdentityMat();
		
		for (var i = 0; i < mlist.length; i++)
			m = MultMat(m, mlist[i]);
			
		return m;
		
	}
	var MultMatPt = function(m, pt)
	{
		return {x:(m[0][0]*pt.x + m[0][1]*pt.y + m[0][2]), y:(m[1][0]*pt.x + m[1][1]*pt.y + m[1][2])};
	}
	
	return {
		EqualPt2:						EqualPt2,
		DistanceSquaredBetween:			DistanceSquaredBetween,
		DistanceBetween:				DistanceBetween,
		CalcAngleDiff:					CalcAngleDiff,
		CalcPointBetween:				CalcPointBetween,
		r2d:							r2d,
		CalcUnitVector:					CalcUnitVector,
		CalcAngle:						CalcAngle,
		CalcVertexAngle:				CalcVertexAngle,
		CalcAngleBetweenLines:			CalcAngleBetweenLines,
		IsAngle180:						IsAngle180,
		CalcTangentArcParams:			CalcTangentArcParams,
		CalcQuadBezierParams:			CalcQuadBezierParams,
		RectIntersectionTest:			RectIntersectionTest,
		EqualWithinTolerance:			EqualWithinTolerance,
		CalcParallelLineOffset:			CalcParallelLineOffset,
		CalcLineSegmentIntersection:	CalcLineSegmentIntersection,
		CalcSegmentIntersection:		CalcSegmentIntersection,
		CalcLineIntersectionWithAngles:	CalcLineIntersectionWithAngles,
		CalcOffsetVertex:				CalcOffsetVertex, // 2022.01.26: Exported
		CalcOffsetPolygon:				CalcOffsetPolygon,
		AreVectorsParallel:				AreVectorsParallel,
		CalcPointToLineDistance:		CalcPointToLineDistance,
		CalcPointToLineSignedDistance:	CalcPointToLineSignedDistance,
		CalcNearestPointOnLine:			CalcNearestPointOnLine,
		IsPointOnLine:					IsPointOnLine,
		IsPointInPolygon:				IsPointInPolygon,
		PolygonClosestEdgeToPoint:		PolygonClosestEdgeToPoint,
		ClipSegmentAgainstPolygon:		ClipSegmentAgainstPolygon,
		DoPolygonsIntersect:			DoPolygonsIntersect,
		PolygonIntersectionResult:		PolygonIntersectionResult,
		ScaleSizeIntoContainerSize:		ScaleSizeIntoContainerSize,
		RotatePoint:					RotatePoint,
		RotatePointAroundOrigin:		RotatePointAroundOrigin,
		CalcRotatedFrameBounds:			CalcRotatedFrameBounds,
		FindPolygonBounds:				FindPolygonBounds,
		LineIntersectsPolygonTest:		LineIntersectsPolygonTest,
		SliceClosedPolygon:				SliceClosedPolygon,
		ClipPolygonToPolygon:			ClipPolygonToPolygon,
		ReflectPointOverAngle:			ReflectPointOverAngle,
		TranslateMat:					TranslateMat,
		RotateMat:						RotateMat,
		MultMat:						MultMat,
		MultMatList:					MultMatList,
		MultMatPt:						MultMatPt
		
	};
}());

//-------------------------------------------------------------------------------------
//	Math3D
//
//	Definitions
//		pt3: {x:x, y:y, z:z}
//		vec3: {x:x, y:y, z:z}
//		mat3: m[3][3]
//		line: {pt:pt3, v:vec3}
//		plane: {pt:pt3, n:vec3}
//		polygon: p[ .. pt3 .. ]
//-------------------------------------------------------------------------------------
var Math3D = (function() {

	//-------------------------------------------------------------------------------------
	//	Mat3
	//-------------------------------------------------------------------------------------
	function Mat3()
	{
		return [[0, 0, 0], [0, 0, 0], [0, 0, 0]];
	}
	
	//-------------------------------------------------------------------------------------
	//	IdentityMat3
	//-------------------------------------------------------------------------------------
	function IdentityMat3()
	{
		let m = Mat3();
		m[0][0] = 1;
		m[1][1] = 1;
		m[2][2] = 1;
	
		return m;
	}

	//-------------------------------------------------------------------------------------
	//	RotationMat3
	//-------------------------------------------------------------------------------------
	function RotationMat3(angle, axis) /* "x", "y", or "z" */
	{

		let c = Math.cos(angle);
		let s = Math.sin(angle);
		let m = IdentityMat3();
	
		if (axis == "x")
		{
			m[1][1] =  c; m[1][2] = s;
			m[2][1] = -s; m[2][2] = c;
		}
		else if (axis == "y")
		{
			m[2][2] =  c; m[2][0] = s;
			m[0][2] = -s; m[0][0] = c;
		}
		else if (axis == "z")
		{
			m[0][0] =  c; m[0][1] = s;
			m[1][0] = -s; m[1][1] = c;
		}
	
		return m;
	}

	//-------------------------------------------------------------------------------------
	//	MultMat3Mat3
	//-------------------------------------------------------------------------------------
	function MultMat3Mat3(ma, mb)
	{
		let m = Math3D.Mat3();
	
		m[0][0] = ma[0][0] * mb[0][0] + ma[0][1] * mb[1][0] + ma[0][2] * mb[2][0];
		m[0][1] = ma[0][0] * mb[0][1] + ma[0][1] * mb[1][1] + ma[0][2] * mb[2][1];
		m[0][2] = ma[0][0] * mb[0][2] + ma[0][1] * mb[1][2] + ma[0][2] * mb[2][2];

		m[1][0] = ma[1][0] * mb[0][0] + ma[1][1] * mb[1][0] + ma[1][2] * mb[2][0];
		m[1][1] = ma[1][0] * mb[0][1] + ma[1][1] * mb[1][1] + ma[1][2] * mb[2][1];
		m[1][2] = ma[1][0] * mb[0][2] + ma[1][1] * mb[1][2] + ma[1][2] * mb[2][2];
	
		m[2][0] = ma[2][0] * mb[0][0] + ma[2][1] * mb[1][0] + ma[2][2] * mb[2][0];
		m[2][1] = ma[2][0] * mb[0][1] + ma[2][1] * mb[1][1] + ma[2][2] * mb[2][1];
		m[2][2] = ma[2][0] * mb[0][2] + ma[2][1] * mb[1][2] + ma[2][2] * mb[2][2];

		return m;
	}

	//-------------------------------------------------------------------------------------
	//	SubVec3Vec3
	//-------------------------------------------------------------------------------------
	function SubVec3Vec3(a, b)
	{
		return {x:a.x - b.x, y:a.y - b.y, z:a.z - b.z};
	}
	
	//-------------------------------------------------------------------------------------
	//	InterpolateVec3Vec3
	//-------------------------------------------------------------------------------------
	function InterpolateVec3Vec3(a, b, t)
	{
		return {x:a.x + t * (b.x - a.x), y:a.y + t * (b.y - a.y), z:a.z + t * (b.z - a.z)};
	}
	
	//-------------------------------------------------------------------------------------
	//	LenVec3
	//-------------------------------------------------------------------------------------
	function LenVec3(v)
	{
		let len = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
		return len;
	}

	//-------------------------------------------------------------------------------------
	//	AddVec3ScaledVec3
	//-------------------------------------------------------------------------------------
	function AddVec3ScaledVec3(a, b, s = 1.0)
	{
		return {x:a.x + s * b.x, y:a.y + s * b.y, z:a.z + s * b.z};
	}

	//-------------------------------------------------------------------------------------
	//	MultMat3Vec3
	//-------------------------------------------------------------------------------------
	function MultMat3Vec3(m, v)
	{
		let u = {x:0, y:0, z:0};
	
		u.x = m[0][0] * v.x + m[0][1] * v.y + m[0][2] * v.z;
		u.y = m[1][0] * v.x + m[1][1] * v.y + m[1][2] * v.z;
		u.z = m[2][0] * v.x + m[2][1] * v.y + m[2][2] * v.z;
	
		return u;
	}

	//-------------------------------------------------------------------------------------
	//	UnitVec3
	//-------------------------------------------------------------------------------------
	function UnitVec3(v)
	{
		let len = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
		var u = undefined;
	
		if (len > 0)
			u = {x: v.x/len, y:v.y/len, z:v.z/len};
		
		return u;
	}

	//-------------------------------------------------------------------------------------
	//	ToUnitVec3
	//-------------------------------------------------------------------------------------
	function ToUnitVec3(v)
	{
		let len = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
	
		if (len > 0)
		{
			v.x /= len;
			v.y /= len;
			v.z /= len;
		}
		return v;
	}

	//-------------------------------------------------------------------------------------
	//	ScaleVec3
	//-------------------------------------------------------------------------------------
	function ScaleVec3(v, s)
	{
		return {x:s*v.x, y:s*v.y, z:s*v.z};
	}

	//-------------------------------------------------------------------------------------
	//	CrossProduct
	//-------------------------------------------------------------------------------------
	function CrossProduct(a, b)
	{
		let sx = a.y * b.z - a.z * b.y;
		let sy = a.z * b.x - a.x * b.z;
		let sz = a.x * b.y - a.y * b.x;
	
		return {x:sx, y:sy, z:sz};
	}

	//-------------------------------------------------------------------------------------
	//	DotProduct
	//-------------------------------------------------------------------------------------
	function DotProduct(a, b)
	{
		let dot = a.x * b.x + a.y * b.y + a.z * b.z;
	
		return dot;
	}


	//-------------------------------------------------------------------------------------
	//	OrthoPlaneUnitVectors
	//-------------------------------------------------------------------------------------
	var OrthoPlaneUnitVectors = function(planeNormal)
	{
		var orthoAB3s; // = {x:-unitAB.y, y:unitAB.x, z:0};
		var orthoAB3t; // = {x:0, y:0, z:1.0 /* unit length */};
	
		let ax = planeNormal.x;
		let ay = planeNormal.y;
		let az = planeNormal.z;
		let lenxy = Math.sqrt(ax * ax + ay * ay);
	
		// Orthogonal unit vector in XY plane (z is zero)
		var bx, by, bz;
		if (lenxy > 0.00001)
		{
			bx = -ay / lenxy;
			by =  ax / lenxy;
			bz =  0;
		}
		else
		{
			bx =  1;
			by =  0;
			bz =  0;
		}
	
		var orthoS = {x:bx, y:by, z:bz};
		var orthoT = Math3D.CrossProduct(planeNormal, orthoS);
		let len = Math.sqrt(orthoT.x * orthoT.x + orthoT.y * orthoT.y + orthoT.z * orthoT.z);
		orthoT.x /= len;
		orthoT.y /= len;
		orthoT.z /= len;
	
		return {s:orthoS, t:orthoT};
	}

	//-------------------------------------------------------------------------------------
	//	Line Plane Intersection
	//-------------------------------------------------------------------------------------
	var LinePlaneIntersection = function(line, plane)
	{
		let v = SubVec3Vec3(plane.pt, line.pt);
		let dpNum = DotProduct(v, plane.n);
		let dpDen = DotProduct(line.v, plane.n);
		let d = dpNum / dpDen;
		
		let pt = AddVec3ScaledVec3(line.pt, line.v, d);
		
		return pt;
	}

	//-------------------------------------------------------------------------------------
	//	Plane Plane Intersection
	//
	//	http://www.geomalgorithms.com/a05-_intersect-1.html
	//-------------------------------------------------------------------------------------
	var PlanePlaneIntersection = function(planeA, planeB)
	{
		let line = undefined;
		
		let planeAu = Math3D.UnitVec3(planeA.n);
		let planeBu = Math3D.UnitVec3(planeB.n);
		
		let v = Math3D.CrossProduct(planeAu, planeBu);
		
		if (v != undefined && Math3D.LenVec3(v) > 0.00001)
		{
			let u = Math3D.UnitVec3(v);
			
			// To get a point on the intersect line
			// zero the max coord, and solve for the other two
			let pt = {}; // intersect point
			
			// Calc the values of d in the plane equations; 
			//	ax + by + cz + d = 0
			//
    		let d1 = -Math3D.DotProduct(planeAu, planeA.pt);
    		let d2 = -Math3D.DotProduct(planeBu, planeB.pt);

			let maxc = 3;
			if (Math.abs(u.z) > Math.abs(u.x) && Math.abs(u.z) > Math.abs(u.y))
				maxc = 3;
			else if (Math.abs(u.x) > Math.abs(u.z) && Math.abs(u.x) > Math.abs(u.y))
				maxc = 1;
			else
				maxc = 2;
				
			if (maxc == 1) // intersect with x=0
			{
				let ux = (planeAu.y * planeBu.z - planeBu.y * planeAu.z);
				//if (Math.abs(ux - u.x) > 0.001)
				//	console.log("ux vs u.x" , ux, u.x);
					
				pt.x = 0;
				pt.y = (d2 * planeAu.z - d1 * planeBu.z) /  ux;
				pt.z = (d1 * planeBu.y - d2 * planeAu.y) /  ux;
			} 
			else if (maxc == 2) // intersect with y=0
			{
				let uy = (planeAu.z * planeBu.x - planeBu.z * planeAu.x);
				//if (Math.abs(uy - u.y) > 0.001)
				//	console.log("uy vs u.y" , uy, u.y);

				pt.x = (d1 * planeBu.z - d2 * planeAu.z) /  uy;
				pt.y = 0;
				pt.z = (d2 * planeAu.x - d1 * planeBu.x) /  uy;
			} 
			else // intersect with z=0
			{ 
				let uz = (planeAu.x * planeBu.y - planeBu.x * planeAu.y);
				//if (Math.abs(uz - u.z) > 0.001)
				//	console.log("uz vs u.z" , uz, u.z);
				
				pt.x = (d2 * planeAu.y - d1 * planeBu.y) /  uz;
				pt.y = (d1 * planeBu.x - d2 * planeAu.x) /  uz;
				pt.z = 0;
			}

    		line = {pt:pt, v:v};
		}
		
		return line;
	}
	
	//-------------------------------------------------------------------------------------
	//	Plane Line Distance
	//
	//		http://www.geomalgorithms.com/a02-_lines.html
	//-------------------------------------------------------------------------------------
	var PointLineDistance = function(pt3, line)
	{
		let u = Math3D.UnitVec3(line.v);
		let w = Math3D.SubVec3Vec3(pt3, line.pt);
		let b = Math3D.DotProduct(w, u);
		let t = Math3D.AddVec3ScaledVec3(w, u, -b);
		let distance = Math3D.LenVec3(t);
		
		return distance;
	}

	//-------------------------------------------------------------------------------------
	//	ProjectPt3OntoLine (i.e., the point on a line closest to another point)
	//
	//-------------------------------------------------------------------------------------
	var ProjectPt3OntoLine = function(pt3, line)
	{
		let v = Math3D.SubVec3Vec3(pt3, line.pt);
		let s = Math3D.DotProduct(v, line.v) / Math3D.DotProduct(line.v, line.v);
		let pt = Math3D.AddVec3ScaledVec3(line.pt, line.v, s);
		
		return pt;
	}
	

	//-------------------------------------------------------------------------------------
	//	Corner Bisect Plane
	//-------------------------------------------------------------------------------------
	var CornerBisectPlane = function(ptA, ptB, ptC)
	{
		var plane = {}; // {pt:pt3, n:vec3}
		plane.pt = {x:ptB.x, y:ptB.y, z:ptB.z};
	
		// Unit vectors for each edge
		let unitBA = Math3D.UnitVec3(Math3D.SubVec3Vec3(ptB, ptA));
		let unitBC = Math3D.UnitVec3(Math3D.SubVec3Vec3(ptB, ptC));
	
		// Treat unit vectors as edges of the rhombus, so that
		// add them together will create a new vector that bisects the angle
		let unitBisectB = Math3D.AddVec3ScaledVec3(unitBA, unitBC);
		
		// If the vectors BA and BC point in opposite directions, then the sum
		// of the vectors will be a zero-length vector.
		if (Math3D.LenVec3(unitBisectB) > 0.00001)
		{
			Math3D.ToUnitVec3(unitBisectB);
	
			// Find the normal for the plane that contains unitBA and unitBC
			let planeNormalABC = Math3D.CrossProduct(unitBA, unitBC);
	
			// Use the normal of the plane that contains corner ABC to find the 
			// and the vector that bisects ABC to find the normal of the plane
			// that bisects ABC
			plane.n = Math3D.CrossProduct(planeNormalABC, unitBisectB);
		}
		else
		{
			// Vectors BA and BC point in opposite directions. Therefore, the plane
			// normal is simply either one of these vectors.
			plane.n = Math3D.UnitVec3(unitBA);
		}
	
		return plane;	
	}

	var CornerBisectPlane4 = function(ptA, ptB, ptC, ptD)
	{
		// We should find where vectors AB and CB intersection and use that for
		// ptBC. Instead we will ASSUME (for the time being) that the angles ABC and BCD are the same
		var ptBC = InterpolateVec3Vec3(ptB, ptC, 0.5);
		
		return CornerBisectPlane(ptA, ptBC, ptD);
	}

	var CopyPt3 = function(p) 
	{ 
		return {x:p.x, y:p.y, z:p.z};
	}

	var EqualPt3 = function(pA, pB) 
	{ 
		let epsilon = 0.0001; // Number.EPSILON
		let dx = Math.abs(pA.x - pB.x);
		let dy = Math.abs(pA.y - pB.y);
		let dz = Math.abs(pA.z - pB.z);
		
		let result = (dx <= epsilon) && (dy <= epsilon) && (dz <= epsilon);
			
		return result;
	}

	//-------------------------------------------------------------------------------------
	//	API
	//-------------------------------------------------------------------------------------
	return {
		// Points
		CopyPt3:		CopyPt3,
		EqualPt3:		EqualPt3,
		
		// 3x3 Matrix
		Mat3:			Mat3,
		IdentityMat3:	IdentityMat3,
		RotationMat3:	RotationMat3,
		MultMat3Mat3:	MultMat3Mat3,
		MultMat3Vec3:	MultMat3Vec3,
		DotProduct:		DotProduct,
		CrossProduct:	CrossProduct,
		
		// Vector 
		UnitVec3:		UnitVec3,
		ToUnitVec3:		ToUnitVec3,
		ScaleVec3:		ScaleVec3,
		SubVec3Vec3:	SubVec3Vec3,
		AddVec3ScaledVec3:	AddVec3ScaledVec3,
		LenVec3:		LenVec3,
		InterpolateVec3Vec3:	InterpolateVec3Vec3,
		
		
		// Special functions
		PointLineDistance:		PointLineDistance,
		ProjectPt3OntoLine:		ProjectPt3OntoLine,
		LinePlaneIntersection:	LinePlaneIntersection,
		PlanePlaneIntersection:	PlanePlaneIntersection,
		OrthoPlaneUnitVectors:	OrthoPlaneUnitVectors,
		CornerBisectPlane:		CornerBisectPlane,
		CornerBisectPlane4:		CornerBisectPlane4
	};
}());


//-------------------------------------------------------------------------------------
//	Shapes3D
//
//		Solid shapes, as parametric functions or as points, edges, and faces
//-------------------------------------------------------------------------------------
var Shapes3D = (function() {

	//-------------------------------------------------------------------------------------
	//	SpherePt
	//
	//	http://mathworld.wolfram.com/Sphere.html
	//-------------------------------------------------------------------------------------
	function SpherePt(r, s, t)
	{
		var phi   = s * Math.PI;
		var theta = t * Math.PI * 2.0;
	
		var x = r * Math.sin(phi) * Math.cos(theta);
		var y = r * Math.sin(phi) * Math.sin(theta);
		var z = r * Math.cos(phi);
	
		var pt = {x:x, y:y, z:z};
		var nm = {x:x, y:y, z:z};
	
		return {pt:pt, normal:nm};
	}
	
	//-------------------------------------------------------------------------------------
	//	UnitTetrahedron
	//-------------------------------------------------------------------------------------
	function UnitTetrahedron()
	{
		var points =  [];
		var edges = [];
		var faces = [];
	
		points.push({x: Math.sqrt(8/9), y: 0, z: -1/3});
		points.push({x: -Math.sqrt(2/9), y: Math.sqrt(2/3), z: -1/3})
		points.push({x: -Math.sqrt(2/9), y: -Math.sqrt(2/3), z: -1/3})
		points.push({x: 0, y: 0, z: 1});
	
	
	
		edges.push([0, 1], [0, 2], [0, 3]);
		edges.push([1, 2], [2, 3], [3, 1])
	
	
	
		faces.push([0,2,1]);
		faces.push([0,3,2]);
		faces.push([0,1,3]);
		faces.push([3,1,2]);

		return {points:points, edges:edges, faces:faces};
	}

	//-------------------------------------------------------------------------------------
	//	UnitCube
	//-------------------------------------------------------------------------------------
	function UnitCube()
	{
		var points =  [];
		var edges = [];
		var faces = [];
	
		points.push({x: 1, y: 1, z: 1}, {x: 1, y:-1, z: 1}, {x:-1, y:-1, z: 1}, {x:-1, y: 1, z: 1});
		points.push({x: 1, y: 1, z:-1}, {x: 1, y:-1, z:-1}, {x:-1, y:-1, z:-1}, {x:-1, y: 1, z:-1});
	
		points.forEach(Math3D.ToUnitVec3);
	
	
		edges.push([0, 1], [1, 2], [2, 3], [3, 0]);
		edges.push([4, 5], [5, 6], [6, 7], [7, 4]);
		edges.push([0, 4], [1, 5], [2, 6], [3, 7]);
	
	
	
		faces.push([3,2,1,0]);
		faces.push([5,4,0,1]);
		faces.push([6,5,1,2]);
		faces.push([7,6,2,3]);
		faces.push([4,7,3,0]);
		faces.push([4,5,6,7]);

		return {points:points, edges:edges, faces:faces};
	}

	//-------------------------------------------------------------------------------------
	//	UnitOctahedron
	//-------------------------------------------------------------------------------------
	function UnitOctahedron()
	{
		var points =  [];
		var edges = [];
		var faces = [];
	
		points.push({x: 0, y: 0, z: 1});
		points.push({x: 1, y: 0, z: 0}, {x: 0, y: 1, z: 0}, {x:-1, y: 0, z: 0}, {x: 0, y:-1, z: 0});
		points.push({x: 0, y: 0, z: -1});
	
		edges.push([0, 1], [0, 2], [0, 3], [0, 4]);
		edges.push([1, 2], [2, 3], [3, 4], [4, 1]);
		edges.push([5, 1], [5, 2], [5, 3], [5, 4]);
	
		faces.push([0,1,2]);
		faces.push([0,2,3]);
		faces.push([0,3,4]);
		faces.push([0,4,1]);
		faces.push([5,2,1]);
		faces.push([5,3,2]);
		faces.push([5,4,3]);
		faces.push([5,1,4]);

		return {points:points, edges:edges, faces:faces};
	}

	//-------------------------------------------------------------------------------------
	//	UnitIcosahedron
	//-------------------------------------------------------------------------------------
	function UnitIcosahedron()
	{
		var points =  [];
		var edges = [];
		var faces = [];
	
		let phi = (1 + Math.sqrt(5))/2;
	
		points.push({x:0, y: 1, z: phi}, {x: 1, y: phi, z: 0}, {x: phi, y:0, z: 1});
		points.push({x:0, y:-1, z: phi}, {x:-1, y: phi, z: 0}, {x: phi, y:0, z:-1});
		points.push({x:0, y:-1, z:-phi}, {x:-1, y:-phi, z: 0}, {x:-phi, y:0, z:-1});
		points.push({x:0, y: 1, z:-phi}, {x: 1, y:-phi, z: 0}, {x:-phi, y:0, z: 1});
	
		points.forEach(Math3D.ToUnitVec3);
	
	
		edges.push([0, 1], [1, 2], [2, 0]);
		edges.push([0, 4], [0,11], [1, 4], [4, 11], [11, 3]);
		edges.push([0, 3], [2, 3]);
	
		edges.push([9, 5], [9, 6], [9,8], [6,7], [6, 10]);
		edges.push([5, 6], [6, 8], [8, 7], [7, 10], [10, 5]);
	
		//     0
		// 1 2 3 11 4
		//  5 10 7 8 9
		//     6
		edges.push([1, 5], [5, 2], [2, 10], [10, 3], [3, 7])
		edges.push([7, 11], [11, 8], [8, 4], [4, 9], [9, 1]);
	
		faces.push([0,1,2], [0,2,3], [0,3,11], [0,11,4], [0,4,1]);
		faces.push([2,1,5], [3,2,10], [11,3,7], [4,11,8], [1,4,9]);
		faces.push([2,5,10], [3,10,7], [11,7,8], [4,8,9], [1,9,5]);
		faces.push([6,10,5],[6,7,10], [6,8,7], [6,9,8], [6,5,9]);
		
		// The faces were created in the wrong direction. Fix it easily with this:
		faces.forEach(f => f.reverse());
		
		return {points:points, edges:edges, faces:faces};
	}

	//-------------------------------------------------------------------------------------
	//	
	//-------------------------------------------------------------------------------------
	function UnitDodecahedron()
	{
		var points =  [];
		var edges = [];
		var faces = [];
	
		let phi = (1 + Math.sqrt(5))/2;
	
		points.push({x: 1, y: 1, z: 1}, {x: 1, y: 1, z:-1}, {x: 1, y: -1, z: 1}, {x: 1, y:-1, z:-1});
		points.push({x:-1, y: 1, z: 1}, {x:-1, y: 1, z:-1}, {x:-1, y: -1, z: 1}, {x:-1, y:-1, z:-1});
		points.push({x:0, y:phi, z:1/phi}, {x:0, y:phi, z:-1/phi}, {x:0, y:-phi, z:1/phi}, {x:0, y:-phi, z:-1/phi});
		points.push({x:1/phi, y:0, z:phi}, {x:1/phi, y:0, z:-phi}, {x:-1/phi, y:0, z:phi}, {x:-1/phi, y:0, z:-phi});
		points.push({x:phi, y:1/phi, z:0}, {x:phi, y:-1/phi, z:0}, {x:-phi, y:1/phi, z:0}, {x:-phi, y:-1/phi, z:0});
	
		points.forEach(Math3D.ToUnitVec3);
		
		edges.push([0,8],[8,4],[4,14],[14,12],[12,0]);
		edges.push([1,9],[9,5],[5,15],[15,13],[13,1]);
		edges.push([0,16],[1,16],[8,9]);
		edges.push([13,3],[3,11],[11,7],[7,15]);
		edges.push([4,18],[18,5],[16,17],[17,3]);
		edges.push([7,19],[19,18]);
		edges.push([12,2],[2,17],[14,6],[6,19]);
		edges.push([10,11],[2,10],[10,6]);
		
		faces.push([12,14,4,8,0]);
		faces.push([1,9,5,15,13]);
		faces.push([1,13,3,17,16]);

		faces.push([9,1,16,0,8]);
		faces.push([12,2,10,6,14]);
		faces.push([12,0,16,17,2]);

		faces.push([13,15,7,11,3]);
		faces.push([7,15,5,18,19]);
		faces.push([4,14,6,19,18]);

		faces.push([2,17,3,11,10]);
		faces.push([7,19,6,10,11]);
		faces.push([4,18,5,9,8]);
		
		// The faces were created in the wrong direction. Fix it easily with this:
		faces.forEach(f => f.reverse());

		return {points:points, edges:edges, faces:faces};
	}

	function UnitTriacontahedron()
	{
		var points =  [];
		var edges = [];
		var faces = [];

		let icosa = UnitIcosahedron();
		let dodeca = UnitDodecahedron();
		
		let s = Math.sqrt((6 + 6/Math.sqrt(5))/(6 + 10/Math.sqrt(5)));
		dodeca.points.forEach(pt => {pt.x *= s; pt.y *= s; pt.z *= s;});
		
		var points = icosa.points.concat(dodeca.points);
		
		edges.push([0,12],[0,24],[0,26],[0,16],[0,20]);
		edges.push([3,26],[3,24],[2,24],[2,12],[1,12],[1,20],[4,20],[4,16],[11,16],[11,26]);
		edges.push([1,21],[21,4],[4,30],[30,11],[11,18],[18,3],[3,14],[14,2],[2,28],[28,1]);
		edges.push([1,13],[21,9],[4,17],[30,8],[11,31],[18,7],[3,22],[14,10],[2,29],[28,5]);
		edges.push([29,5],[5,13],[13,9],[9,17],[17,8],[8,31],[31,7],[7,22],[22,10],[10,29]);

		edges.push([25,9],[9,27],[27,8],[8,19],[19,7],[7,23],[23,10],[10,15],[15,5],[5,25]);
		edges.push([6,15],[6,23],[6,19],[6,27],[6,25]);	
		
		faces.push([13,9,25,5],[6,15,5,25],[6,25,9,27]);
		faces.push([9,17,8,27],[27,8,19,6],[1,13,5,28]);
		faces.push([28,5,29,2],[29,5,15,10],[2,29,10,14]);
		faces.push([23,10,15,6],[7,23,6,19],[7,22,10,23]);
		faces.push([9,13,1,21],[17,9,21,4],[4,21,1,20]);
		faces.push([20,1,12,0],[4,20,0,16],[30,8,17,4]);
		faces.push([4,16,11,30],[0,26,11,16],[0,24,3,26]);
		faces.push([26,3,18,11],[18,3,22,7],[11,18,7,31]);
		faces.push([3,14,10,22],[2,12,1,28],[11,31,8,30]);
		faces.push([0,12,2,24],[24,2,14,3],[31,7,19,8]);
		
		// The faces were created in the wrong direction. Fix it easily with this:
		faces.forEach(f => f.reverse());

		return {points:points, edges:edges, faces:faces};
	}

	function UnitTriacontahedronCool()
	{
		var points =  [];
		var edges = [];
		var faces = [];

		let icosa = UnitIcosahedron();
		let dodeca = UnitDodecahedron();
		
		let offset = icosa.points.length;
		dodeca.edges.forEach(e => {e[0] += offset, e[1] += offset});
		
		var points = icosa.points.concat(dodeca.points);
		var edges = icosa.edges.concat(dodeca.edges);

		return {points:points, edges:edges, faces:faces};
	}

	//-------------------------------------------------------------------------------------
	//	
	//-------------------------------------------------------------------------------------
	function Torus(config)
	{
		var points =  [];
		var edges = [];
		var faces = [];

		let or = config.outerRadius;
		let ir = config.innerRadius;
		let vs = config.stepsV;
		let us = config.stepsU;
		let va = config.angleV;
		let ua = config.angleU;
	
		let vaInc = Math.PI * 2.0 / vs;
		let uaInc = Math.PI * 2.0 / us;

		for (var vk = 0; vk < vs; vk++)	
		{
			for (var uk = 0; uk < us; uk++)
			{
				let v = va + vaInc * vk;
				let u = ua + uaInc * uk;
			
				let x = (or + ir * Math.cos(v)) * Math.cos(u);
				let y = (or + ir * Math.cos(v)) * Math.sin(u);
				let z = ir * Math.sin(v);
			
				let pt = {x:x, y:y, z:z};
				points.push(pt);
			}
		}
	
		for (var vk = 0; vk < vs; vk++)	
		{
			for (var uk = 0; uk < us; uk++)
			{
				let idx1 = vk * us + uk;
				let idx2 = vk * us + (uk + 1) % us;
				edges.push([idx1, idx2]);
			
				let idx3 = ((vk + 1) % vs) * us + uk;
				edges.push([idx1, idx3]);

				let idx4 = ((vk + 1) % vs) * us + (uk + 1) % us;
				faces.push([idx1, idx2, idx4, idx3]);
			}
		}
	
	
		return {points:points, edges:edges, faces:faces};	
	}

	//-------------------------------------------------------------------------------------
	//	
	//-------------------------------------------------------------------------------------
	function TorusKnot(config)
	{
		var points =  [];
		var edges = [];
		var faces = [];

		let or = config.outerRadius;
		let ir = config.innerRadius;
		let vs = config.stepsV;
		let us = config.stepsU;
		let va = config.angleV;
		let ua = config.angleU;
	
		let vaInc = Math.PI * 2.0 / vs;
		let uaInc = Math.PI * 2.0 / us;

		for (var vk = 0; vk < vs; vk++)	
		{
			for (var uk = 0; uk < us; uk++)
			{
				let v = va + vaInc * vk;
				let u = ua + uaInc * uk;
			
				let a = 1; // xxxx
				let x = (or + ir * Math.cos(v)) * Math.cos(u);
				let y = (or + ir * Math.cos(v)) * Math.sin(u);
				let z = ir * Math.sin(v);
			
				let pt = {x:x, y:y, z:z};
				points.push(pt);
			}
		}
	
		for (var vk = 0; vk < vs; vk++)	
		{
			for (var uk = 0; uk < us; uk++)
			{
				let idx1 = vk * us + uk;
				let idx2 = vk * us + (uk + 1) % us;
				edges.push([idx1, idx2]);
			
				let idx3 = ((vk + 1) % vs) * us + uk;
				edges.push([idx1, idx3]);

				let idx4 = ((vk + 1) % vs) * us + (uk + 1) % us;
				faces.push([idx1, idx2, idx4, idx3]);
			}
		}
	
	
		return {points:points, edges:edges, faces:faces};	
	}


	//-------------------------------------------------------------------------------------
	//	SubdivideEdges
	//-------------------------------------------------------------------------------------
	function SubdivideEdges(inputSolid)
	{
		var outputSolid = JSON.parse(JSON.stringify(inputSolid));
		var initialEdges = outputSolid.edges;
		outputSolid.edges = [];
	
		for (var i = 0; i < initialEdges.length; i++)
		{
			var idxA = initialEdges[i][0];
			var idxB = initialEdges[i][1];
			var ptC = {	x: (outputSolid.points[idxA].x + outputSolid.points[idxB].x)/2,
						y: (outputSolid.points[idxA].y + outputSolid.points[idxB].y)/2,
						z: (outputSolid.points[idxA].z + outputSolid.points[idxB].z)/2 };

			var idxC = outputSolid.points.length;
			outputSolid.points.push(ptC);
			outputSolid.edges.push([idxA, idxC], [idxC, idxB]);
		}
	
		return outputSolid;
	}

	//-------------------------------------------------------------------------------------
	//	UnitSpherifyPoints
	//-------------------------------------------------------------------------------------
	function UnitSpherifyPoints(points)
	{
		for (var i = 0; i < points.length; i++)
		{
			let uv = Math3D.UnitVec3(points[i]);
			points[i].x = uv.x;
			points[i].y = uv.y;
			points[i].z = uv.z;
		}
	}

	//-------------------------------------------------------------------------------------
	//	OrthoPlanePointList
	//-------------------------------------------------------------------------------------
	var OrthoPlanePointList = function(planeNormal, point, polygonPointCount, shapeRadius)
	{
		var orthos = Math3D.OrthoPlaneUnitVectors(planeNormal);
		var orthoAB3s = orthos.s; // {x:-unitAB.y, y:unitAB.x, z:0};
		var orthoAB3t = orthos.t; // {x:0, y:0, z:1.0 /* unit length */};
	
		let normalLen = Math.sqrt(planeNormal.x * planeNormal.x + planeNormal.y * planeNormal.y + planeNormal.z * planeNormal.z);
		var unitAB = {x: planeNormal.x / normalLen, y: planeNormal.y / normalLen, z: planeNormal.z / normalLen};
		//var unitAB = CalcUnitVector3(planeNormal);
		var ptList = [];
		var count = polygonPointCount;
	
		var stepAngle = Math.PI * 2 / count;
		var offsetAngle = Math.PI/polygonPointCount;
	
		var px = point.x;
		var py = point.y;
		var pz = (point.z != undefined) ? point.z : 0;
	
		var d = shapeRadius;
	
		for (var i = 0; i < count; i++)
		{
			var a = i * stepAngle + offsetAngle
			var s = Math.cos(a);
			var t = Math.sin(a);
		
			var nx = s * orthoAB3s.x + t * orthoAB3t.x;
			var ny = s * orthoAB3s.y + t * orthoAB3t.y;
			var nz = s * orthoAB3s.z + t * orthoAB3t.z;
		
			if (unitAB.z == undefined)
				unitAB.z = 0;
			
			var tx = unitAB.y * nz - unitAB.z * ny;
			var ty = unitAB.z * nx - unitAB.x * nz; 
			var tz = unitAB.x * ny - unitAB.y * nx;
		
			var x = px + d * nx;
			var y = py + d * ny; 
			var z = pz + d * nz; 
		
			var pt3 = {x:x, y:y, z:z, nx:nx, ny:ny, nz:nz, tx:tx, ty:ty, tz:tz};
		
			ptList.push(pt3)
		
		}
	
	
		return ptList;
	
	}


	//-------------------------------------------------------------------------------------
	//	API
	//-------------------------------------------------------------------------------------
	return {
		SpherePt:			SpherePt,
		UnitTetrahedron:	UnitTetrahedron,
		UnitCube:			UnitCube,
		UnitOctahedron:		UnitOctahedron,
		UnitIcosahedron:	UnitIcosahedron,
		UnitDodecahedron:	UnitDodecahedron,
		UnitTriacontahedron:	UnitTriacontahedron,
		Torus:				Torus,
		
		SubdivideEdges:		SubdivideEdges,
		UnitSpherifyPoints:	UnitSpherifyPoints,
		
		OrthoPlanePointList:	OrthoPlanePointList
	};
}());

//-------------------------------------------------------------------------------------
//	Projection3D
//
//		projection: "flat", "perspective", "camera", "camera_perspective"
//
//		https://math.stackexchange.com/questions/118832/how-are-3d-coordinates-transformed-to-2d-coordinates-that-can-be-displayed-on-th
//-------------------------------------------------------------------------------------
function Projection3D()
{
	this.Reset();
}

//-------------------------------------------------------------------------------------
//	Projection3D.Reset
//-------------------------------------------------------------------------------------
Projection3D.prototype.Reset = function()
{
	this.camera = {};
	this.camera.projection = "camera"; 
	this.camera.ax = 0;
	this.camera.ay = 0;
	this.camera.az = 0;
	this.camera.cameraVector = {x:0, y:0, z:1};
	
	this.m = Math3D.IdentityMat3();
}

//-------------------------------------------------------------------------------------
//	Projection3D.Update
//-------------------------------------------------------------------------------------
Projection3D.prototype.UpdateCamera = function(config)
{
	Object.assign(this.camera, config);
	this.CalcCamera();
}

//-------------------------------------------------------------------------------------
//	Projection3D.CalcCamera
//-------------------------------------------------------------------------------------
Projection3D.prototype.CalcCamera = function()
{
	let matax = Math3D.RotationMat3(this.camera.ax * Math.PI/180.0, "x");
	let matay = Math3D.RotationMat3(this.camera.ay * Math.PI/180.0, "y");
	let mataz = Math3D.RotationMat3(this.camera.az * Math.PI/180.0, "z");

	let m = Math3D.MultMat3Mat3(matax, matay);
	this.m = Math3D.MultMat3Mat3(m, mataz);
	
	let v = {x:0, y:0, z:1};
	this.camera.cameraVector = Math3D.MultMat3Vec3(this.m, v);
}

//-------------------------------------------------------------------------------------
//	Projection3D.Map3Dto2D
//-------------------------------------------------------------------------------------
Projection3D.prototype.Map3Dto2D = function(pt3)
{
	var pt2;
	var offset = 400;
	var planeDistance = 300;
	
	
	if (this.camera.projection == "flat")
	{
		pt2 = {x:pt3.x, y:pt3.z};
	}
	else if (this.camera.projection == "perspective")
	{
		var a = pt3.y + offset;
		var d = a /* - planeDistance*/;
	
		var s = d/planeDistance;
	
		pt2 = {x:s*pt3.x, y:s*pt3.z};
	}
	else
	{
		var v = Math3D.MultMat3Vec3(this.m, pt3);
		var a = v.z + offset;
		var d = a /* - planeDistance*/;
	
		var s;
	
		if (this.camera.projection == "camera") // not "camera_perspective"
			s = 1;
		else
			s = d/planeDistance;
			
		pt2 = {x:s*v.x, y:s*v.y};
	}
	
	return pt2;
}


//-------------------------------------------------------------------------------------
//	Enumerations
//
//		
//-------------------------------------------------------------------------------------

// VectorCornerStyle (enum)
var VectorCornerStyle = Object.freeze({
	ANGLE		: 0,
	ARC			: 1,
	QUAD_BEZ	: 2
	});

// BasicUnits (enum)
var BasicUnits = Object.freeze({
	PIXELS		: 0,
	INCHES		: 1,
	MILLIMETERS	: 2
	});

//-------------------------------------------------------------------------------------
//	Basic Units Mgr
//
//		
//-------------------------------------------------------------------------------------
var BasicUnitsMgr = (function() {
	
	var BasicUnitsMgr_GetStr = function(units)
	{
		var unitList = ["pixels", "in", "mm" ];
		var unitStr = "";
		
		// OPTIONAL: Validate the input
		if (units >= 0 && units <= BasicUnits.MILLIMETERS)
			unitStr = unitList[units];
		else
			unitStr = "(??)";
		
		return unitStr;		
	}

	var BasicUnitsMgr_CalcScale = function(units, dpi)
	{
		var scale = 1.0;
		
		if (units == BasicUnits.INCHES)
			scale = dpi;
		else if (units == BasicUnits.MILLIMETERS)
			scale = dpi / 25.4;
			
		return scale;
	}

	return {
		GetStr:					BasicUnitsMgr_GetStr,
		CalcScale:				BasicUnitsMgr_CalcScale
	};
}());

/*
function formatPt(p)
{
	function ns(n) { return ((n <= 0) ? "" : " " ); }
	function fn(n) {
		let s = ns(n) + n.toFixed(2);
		if (Math.abs(n) < 10)
			s = " " + s;
		return s;
	}

	let s = fn(p.x) + "," + fn(p.y);
	if (p.z != undefined)
		s += "," + ns(p.z) + p.z;
	else
		s += "   "

	return s;
}

function logPts(pA, pB, desc)
{
	let a = formatPt(pA);
	let b = formatPt(pB);
	let black = "color:black";
	let ac = (pA.z == undefined) ? black : "color:red";
	let bc = (pB.z == undefined) ? black : "color:red";
	let note = (desc != undefined) ? desc : "";
	console.log("A: %c" + a + "%c, B: %c" + b + "%c  " + note, ac, black, bc, black);
}
*/

var SegmentIntersectionHandling = Object.freeze({
	IGNORE_INTERSECTIONS: 0,
	SUBDIVIDE_SEGMENTS	: 1
});

var PolygonMiterType = Object.freeze({
	BUTT: 		0,
	SQUARE:		1,
	HEX:		2,
	ANGLE_90:	3,
	ANGLE_60:	4,
	ROUND:		10
});

// SegmentList (API)
var SegmentList = (function() {
	/*-----------------------------------------------*
	 * Segment List: list of segments that form
	 * one or more polygons
	 * 
	 * {
	 *    vertex:[ {x,y}, {x,y}, ... ]
	 *    segment:[ {a,b}, {a,b}, ... ]
	 * }
	 * x, y: coordinate
	 * a, b: indices into vertex[] array
	 *
	 * Functions:
	 * SegmentList_Validate(): Validates the integrity
	 * of the segment list
	 * SegmentList_ConstructPolygonList(): Converts segment 
	 * list into a list of points describing one or more 
	 * polygons
	 *-----------------------------------------------*/
	var SegDebug_local = function(str)
	{
		//console.log("Segment dbg | " + str);
	}

	
	var SegmentList_Create = function(intersectionHandling = SegmentIntersectionHandling.IGNORE_INTERSECTIONS)
	{
		var segList = { intersectionHandling:intersectionHandling, points:[], segments:[] };
	
		return segList;
	}
	
	var SegmentList_AddClipPolygon = function(segList, pointList)
	{
		// pointList is a closed polygon. When specified all segments will be tested and clipped 
		segList.clipPolygon = pointList;
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var SegmentList_FindPoint_priv = function(segList, point)
	{
		var index = -1;

		//	var xRound = Math.floor(point.x * 10000)/10000;
		//	var yRound = Math.floor(point.y * 10000)/10000;
		//	var ptRound = {x:xRound, y:yRound};
	
		for (var i = 0; i < segList.points.length && index == -1; i++)
		{
			if (MathUtil.EqualWithinTolerance(segList.points[i].x, point.x, NORMAL_TOLERANCE) && 
				MathUtil.EqualWithinTolerance(segList.points[i].y, point.y, NORMAL_TOLERANCE))
				index = i;
		}
	
		//console.log("FindPoint: " + JSON.stringify(point) + " result:"+ index);
		return index;
	}
	

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var SegmentList_FindOrAddPoint = function(segList, point)
	{
		var index = SegmentList_FindPoint_priv(segList, point);
	
		if (index == -1)
		{
			segList.points.push(point);
			index = segList.points.length - 1;
			//console.log("Added point: " + JSON.stringify(point) + " at "+ index);

		}
	
		return index;
	}


	var SegmentList_CalcSegmentIntersectionList = function(segList, pointA, pointB)
	{
		//var points = [];
		
		var intersectionList = [];
		let epsilon = 0.0001;
		
		function compare(a,b) {
		  return (a.c1 - b.c1);
		}

		// 2020.10.14: Input segment bounds
		var pMinX = (pointA.x < pointB.x) ? pointA.x - epsilon : pointB.x - epsilon;
		var pMaxX = (pointA.x < pointB.x) ? pointB.x + epsilon : pointA.x + epsilon;
		var pMinY = (pointA.y < pointB.y) ? pointA.y - epsilon : pointB.y - epsilon;
		var pMaxY = (pointA.y < pointB.y) ? pointB.y + epsilon : pointA.y + epsilon;
		
		if (segList.intersectionHandling != SegmentIntersectionHandling.IGNORE_INTERSECTIONS)
		{
			for (var i = 0; i < segList.segments.length; i++)
			{
				var s = segList.segments[i];
				var segPtA = segList.points[s.indexA];
				var segPtB = segList.points[s.indexB];

				// 2020.10.14: Segment bounds
				var sMinX = (segPtA.x < segPtB.x) ? segPtA.x - epsilon : segPtB.x - epsilon;
				var sMaxX = (segPtA.x < segPtB.x) ? segPtB.x + epsilon : segPtA.x + epsilon;
				var sMinY = (segPtA.y < segPtB.y) ? segPtA.y - epsilon : segPtB.y - epsilon;
				var sMaxY = (segPtA.y < segPtB.y) ? segPtB.y + epsilon : segPtA.y + epsilon;

				var cPair = undefined;
				
				// 2020.10.14: Compare the bounds to minimize calls to calc intersection
				if (!(pMaxX < sMinX || sMaxX < pMinX || pMaxY < sMinY || sMaxY < pMinY))
				{
					// Segment bounds overlap. Call 'calc intersection'
					cPair = MathUtil.CalcSegmentIntersection(pointA, pointB, segPtA, segPtB);
				}
			
				if (cPair != undefined && !MathUtil.EqualWithinTolerance(cPair.c1, intersectionList[intersectionList.length-1], NORMAL_TOLERANCE))
					intersectionList.push({c1:cPair.c1, segIndex:i, c2:cPair.c2});
			}
		}

		if (intersectionList.length == 0)
		{
			intersectionList.push({c1:0.0, segIndex:undefined, c2:undefined});
			intersectionList.push({c1:1.0, segIndex:undefined, c2:undefined});
		}
		else
		{
			// If the start of the line segment is not already in the list, then add it at the beginning
			if (!MathUtil.EqualWithinTolerance(intersectionList[0].c1, 0.0, NORMAL_TOLERANCE))
				intersectionList.unshift({c1:0.0, segIndex:undefined, c2:undefined});

			// If the end of the line segment is not already here, then add it at the end
			if (!MathUtil.EqualWithinTolerance(intersectionList[intersectionList.length-1].c1, 1.0, NORMAL_TOLERANCE))
				intersectionList.push({c1:1.0, segIndex:undefined, c2:undefined});
				
			intersectionList.sort(compare);
		}
		
		//for (var i = 0; i < cList.length; i++)
		//{
		//	var c = cList[i];
		//	var p = {x: pointA.x + c * (pointB.x - pointA.x), y: pointA.y + c * (pointB.y - pointA.y) };
		//	points.push(p);
		//}
		
		//if (cList.length > 2)
		//	console.log(JSON.stringify(cList));
		

		//console.log(JSON.stringify(intersectionList));
		return intersectionList;
	}

	var SegmentList_SplitSegment = function(segList, segIndex, split)
	{
		//console.log("Splitting " + segIndex + " at " + split);
		// Compute a new point that lies on the specified segment
		var seg = segList.segments[segIndex];
		var segPtA = segList.points[seg.indexA];
		var segPtB = segList.points[seg.indexB];
		var newPt = {x:segPtA.x + split * (segPtB.x - segPtA.x), y:segPtA.y + split * (segPtB.y - segPtA.y)};
		
		// Add the new point to the point list
		var ptIndex = SegmentList_FindOrAddPoint(segList, newPt);
		
		// Copy the current segment and set the start point as the new point
		var newSegment = Object.assign({}, seg);
		newSegment.indexA = ptIndex;
		
		// Set the end point of the specified segment as the new point also
		seg.indexB = ptIndex;

		// 2022.01.26: Segments now contain z-values for the start, middle, and end of the segment.
		// When we split a segment we have to adjust those values for the current and new segment
		seg.ptBz = seg.linez;
		newSegment.ptAz = seg.linez;

		// Add the new segment
		segList.segments.push(newSegment);
	}

	var SegmentList_ClipAgainstClipPolygon = function(segList, pointA, pointB)
	{
		var ptPair = undefined;
		var done = false;
		
		var ptAinside = true;
		var ptBinside = true;

		for (var i = 0; i < segList.clipPolygon.length && !done; i++)
		{
			var clipPtA = segList.clipPolygon[i];
			var clipPtB = segList.clipPolygon[(i + 1) % segList.clipPolygon.length];
		
			var cPair = MathUtil.CalcSegmentIntersection(clipPtA, clipPtB, pointA, pointB, true /* always return results */);
		
			if (cPair != undefined)
			{
				if (!cPair.ptCinsideAB)
					ptAinside = false;
					
				if (!cPair.ptDinsideAB)
					ptBinside = false;
					
				if (cPair.ptCinsideAB && cPair.ptDinsideAB)
				{
					// do nothing
				}
				else if (!cPair.ptCinsideAB && !cPair.ptDinsideAB)
				{
					ptPair = {};
					ptPair.ignoreSegment = true;
					done = true;
				}
				else if (cPair.c1 >= 0 && cPair.c1 <= 1.0 && cPair.c2 >= 0 && cPair.c2 <= 1.0)
				{
					var c = cPair.c2;
					var p = {x: pointA.x + c * (pointB.x - pointA.x), y: pointA.y + c * (pointB.y - pointA.y) };
					
					ptPair = {};
					if (cPair.ptCinsideAB)
					{
						ptPair.ptA = pointA;
						ptPair.ptB = p;
					}
					else
					{
						ptPair.ptA = p;
						ptPair.ptB = pointB;
					}
				}
			}
			
			if (!ptAinside && !ptBinside)
			{
				if (ptPair == undefined)
					ptPair = {};
				ptPair.ignoreSegment = true; // NEED TO TEST FOR INTERSECTION
			}
		}
		
		return ptPair;
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var SegmentList_AddSegment = function(segList, pointA, pointB, segmentOffset = 0, segmentTag, options)
	{
		var processSegment = true;
		var removeWithReverse = (options != undefined) ? options.removeWithReverse : false; // 2021.05.13
		var linez = (segmentTag != undefined && segmentTag.linez != undefined) ? segmentTag.linez : undefined; // 2022.01.26: Needs to be the z-value of the line, if provided
		
		if (segList.clipPolygon != undefined)
		{
			var clipResult = MathUtil.ClipSegmentAgainstPolygon(pointA, pointB, segList.clipPolygon);
			if (clipResult != undefined)
			{
				if (clipResult.ignoreSegment)
				{
					processSegment = false;
				}
				else
				{
					pointA = clipResult.ptA;
					pointB = clipResult.ptB;
					// 2022.01.26: TODO: We need to copy the z-values, if provided
				}
			}
		}
		
		if (processSegment)
		{
			var intersectionList = SegmentList_CalcSegmentIntersectionList(segList, pointA, pointB);
		
			var angle  = MathUtil.CalcAngle(pointA, pointB);
		
			for (var i = 0; i < intersectionList.length; i++)
			{
				var iInfo = intersectionList[i];
				if (iInfo["segIndex"] != undefined && !MathUtil.EqualWithinTolerance(iInfo.c2, 0.0, NORMAL_TOLERANCE) && !MathUtil.EqualWithinTolerance(iInfo.c2, 1.0, NORMAL_TOLERANCE))
					SegmentList_SplitSegment(segList, iInfo.segIndex, iInfo.c2);
			}
		
			var points = []
			var c = undefined;
			for (var i = 0; i < intersectionList.length; i++)
			{
				if (c == undefined || !MathUtil.EqualWithinTolerance(c, intersectionList[i].c1, NORMAL_TOLERANCE))
				{
					c = intersectionList[i].c1;
					var p = {x: pointA.x + c * (pointB.x - pointA.x), y: pointA.y + c * (pointB.y - pointA.y) };

					// For lattice support, copy the z-value corresponding to the start, end, or middle of the line
					if (MathUtil.EqualWithinTolerance(c, 0.0, NORMAL_TOLERANCE))
					{
						p.z = pointA.z;
						if (pointA.clipPolyEdgeIdx != undefined)
							p.clipPolyEdgeIdx = pointA.clipPolyEdgeIdx;
					}
					else if (MathUtil.EqualWithinTolerance(c, 1.0, NORMAL_TOLERANCE))
					{
						p.z = pointB.z;
						if (pointB.clipPolyEdgeIdx != undefined)
							p.clipPolyEdgeIdx = pointB.clipPolyEdgeIdx;
					}
					else
						p.z = linez;

					points.push(p);
				}
			}
		
			for (var i = 0; i < points.length - 1; i++)
			{
				var ptA = points[i];
				var ptB = points[i + 1];
	
				var indexA = SegmentList_FindOrAddPoint(segList, ptA);
				var indexB = SegmentList_FindOrAddPoint(segList, ptB);
	
				var segment = { indexA:indexA, indexB:indexB, angle:angle };
	
				segment.offset = segmentOffset;
			
				if (segmentTag != undefined)
					segment.tag = segmentTag;

				// 2022.01.26: Segments can now have z-values at the start, middle, and end
				// of the segment. We need to store this in the segment and not the start and end
				// points since different segments can have the same x,y points but different z-values
				if (ptA.z != undefined)
					segment.ptAz = ptA.z;
				if (ptB.z != undefined)
					segment.ptBz = ptB.z;
				if (linez != undefined)
					segment.linez = linez;

				//if (0 && (ptA.z != undefined || ptB.z != undefined))
				//{
				//	let bl = "color:black";
				//	let ac = (ptA.z != undefined) ? "color:red" : bl;
				//	let bc = (ptB.z != undefined) ? "color:red" : bl;
				//	let az = (ptA.z != undefined) ? (", Az:"+ptA.z) : "";
				//	let bz = (ptB.z != undefined) ? (", Bz:"+ptB.z) : "";
				//	console.log("%cA: " + ptA.x.toFixed(2) + "," + ptA.y.toFixed(2) +az + "%c" +
				//				"%c, B: " + ptB.x.toFixed(2) + "," + ptB.y.toFixed(2) + bz + "%c", ac, bl, bc, bl);
				//}

				// 2021.05.13: "Remove if Reverse" will remove a matching segment pointing in the 
				// opposite direction and omit the new segment. This allows us to build a 
				// polygon that is the outside of a collection of polygons.
				if (removeWithReverse) 
				{
					let reverseIdx = segList.segments.findIndex(s => 
										(s.indexA == indexB && s.indexB == indexA) && 
										(s.tag != undefined && segmentTag != undefined && s.tag.seTag == segmentTag.seTag) );
					if (reverseIdx != -1)
					{
						segment = undefined;
						segList.segments.splice(reverseIdx, 1);
					}
				}
				
				if (segment != undefined) // 2021.05.13: Added test
					segList.segments.push(segment);
			}
		}
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var SegmentList_GetSegmentCount = function(segList)
	{
		var count = 0;
	
		if ("segments" in segList)
			count = segList.segments.length;
		else
			Debug_log("SegmentList_GetSegmentCount: 'segments' not defined in segList");
		
		return count;
	}

	/*-----------------------------------------------*
	 * SegmentList: GetSegment
	 * Added so that the segment could be rendered
	 * on-screen (for validation/debugging)
	 *-----------------------------------------------*/
	var SegmentList_GetSegment = function(segList, index)
	{
		var segment;
	
		if (index < segList.segments.length)
		{
			var si = segList.segments[index];
		
			if (si.indexA < segList.points.length && si.indexB < segList.points.length)
			{
				segment = {};
			
				segment.ptA = {x:segList.points[si.indexA].x, y:segList.points[si.indexA].y};
				segment.ptB = {x:segList.points[si.indexB].x, y:segList.points[si.indexB].y};
				if ("tag" in si)
					segment.tag = si.tag;
				if ("offset" in si)
					segment.offset = si.offset;
			}
			else
			{
				Debug_log("SegmentList_GetSegment: point index out of range");
			}
		}
	
		return segment;
	}

	/*-----------------------------------------------*
	 * 
	 *-----------------------------------------------*/
	var SegmentList_GeneratePolygonList = function(segList)
	{
		var SegStringify_priv = function(i)
		{
			var iA = segList.segments[i].indexA;
			var iB = segList.segments[i].indexB;
			var pA = segList.points[iA];
			var pB = segList.points[iB];
			return "segIdx [" + i + "]: (" + iA + "->" + iB + ")  (" + Math.round(pA.x) + "," + Math.round(pA.y) + ") -> (" + Math.round(pB.x) + "," + Math.round(pB.y) + ")";
		}

		var polyList = new PolygonList();
		var done = 0;
	
		SegDebug_local("");
		SegDebug_local("Generate Polygon List");
		
		// Prepare segments for searching
		for (var i = 0; i < segList.segments.length; i++)
			segList.segments[i].used = 0;
	
		do
		{
			var segIndex = -1;
		
			// Find an unused segment
			for (var i = 0; i < segList.segments.length && segIndex == -1; i++)
				if (!segList.segments[i].used)
					segIndex = i;
		

			if (segIndex == -1)
			{
				// No segments, so we are done
				done = 1;
			}
			else
			{
				SegDebug_local("Start poly..." + SegStringify_priv(segIndex));
			
				//console.log("Found start at " + segIndex + ", ptIndexA:" + segList.segments[segIndex].indexA + ", ptIndexB: " + segList.segments[segIndex].indexB);
				// Create a new polygon and add points
				var polySegments = [];
				var cumulativeAngle = 0;
			
				var ptIndexB = segList.segments[segIndex].indexB;
				var segAngle = segList.segments[segIndex].angle;

				// Push first segment
				polySegments.push(segIndex);
			
				// Mark as used
				segList.segments[segIndex].used = 1;

				// Find connecting segments
				var f;
				do
				{
					segIndex = -1;
					var angleDiff = 0;
					for (var i = 0; i < segList.segments.length; i++)
					{
						if (!segList.segments[i].used && segList.segments[i].indexA == ptIndexB)
						{
							var angleDiffToSegI = MathUtil.CalcAngleDiff(segList.segments[i].angle, segAngle);
							if (MathUtil.EqualWithinTolerance(angleDiffToSegI, -Math.PI, ANGLE_TOLERANCE))
								angleDiffToSegI = Math.PI;
								
							SegDebug_local("    testing " + i + "...angle diff: " + MathUtil.r2d(segList.segments[i].angle) + " - " + MathUtil.r2d(segAngle) + " = " + MathUtil.r2d(angleDiffToSegI));
						
							if (segIndex == -1)
							{
								segIndex = i;
								angleDiff = angleDiffToSegI;
							}
							else if (angleDiffToSegI < angleDiff)
							{
								segIndex = i;
								angleDiff = angleDiffToSegI;
							}
						}
					} 
				
				
					// If we found a segment, add it to the polygon
					if (segIndex != -1)
					{
						// Test to determine if we have already completed a closed outside polygon
						// and are about to pick-up a point from an internal polygon.
						// 2017.06.07: Consider using the same test to ALLOW for adding a segment
						// to a closed inside polygon. This would handle the case where there is
						// a single edge (segment pair[s]) connected to the inside polygon 
						// 2017.06.14: Test for closed inside polygon here
						if (polySegments.length > 1)
						{
							// If the point we are about to add is the same as the first point and the cumulative
							// angle is 2Pi, then we have a closed outside polygon
							var firstSeg = polySegments[0];
							var lastSeg  = polySegments[polySegments.length - 1];
							if (segList.segments[firstSeg].indexA == segList.segments[lastSeg].indexB)
							{ 
								var tempAngleDiff = MathUtil.CalcAngleDiff(segList.segments[firstSeg].angle, segList.segments[lastSeg].angle);
								var tempCumulativeAngle = cumulativeAngle + tempAngleDiff;
								SegDebug_local("  previous poly is closed; tempCumulativeAngle: " + MathUtil.r2d(tempCumulativeAngle));
								if (MathUtil.EqualWithinTolerance(tempCumulativeAngle, 2*Math.PI, ANGLE_TOLERANCE))
								{
									SegDebug_local("  poly is outside; angleDiff: " + MathUtil.r2d(angleDiff) + ", tempAngleDiff: " + MathUtil.r2d(tempAngleDiff));
									if (angleDiff > tempAngleDiff)
										segIndex = -1;
								}
								else if (MathUtil.EqualWithinTolerance(tempCumulativeAngle, -2*Math.PI, ANGLE_TOLERANCE))
								{
									SegDebug_local("  poly is inside; angleDiff: " + MathUtil.r2d(angleDiff) + ", tempAngleDiff: " + MathUtil.r2d(tempAngleDiff));
									//segIndex = -1;
									if (angleDiff > tempAngleDiff)
										segIndex = -1;
								}
							}
						}

						// If we still have a segment, then add it to the list
						if (segIndex != -1)
						{
							SegDebug_local("  next at " + SegStringify_priv(segIndex));

							polySegments.push(segIndex);
							segList.segments[segIndex].used = 1;
							ptIndexB = segList.segments[segIndex].indexB;
							segAngle = segList.segments[segIndex].angle;
							cumulativeAngle += angleDiff;
							SegDebug_local("  angle diff: " + MathUtil.r2d(angleDiff) + ", cumulative angle: " + MathUtil.r2d(cumulativeAngle));
					
							// If most recently added point is the same as the first point and the cumulative
							// angle is -2Pi, then we have a closed inside polygon
							// 2017.06.14: Move this test above
							//var firstSeg = polySegments[0];
							//if (polySegments.length > 1 && ptIndexB == segList.segments[firstSeg].indexA)
							//{
							//	angleDiff = MathUtil.CalcAngleDiff(segList.segments[firstSeg].angle, segAngle);
							//	var tempCumulativeAngle = cumulativeAngle + angleDiff;
							//	SegDebug_local("  angle diff: " + MathUtil.r2d(angleDiff) + ", test cumulative angle: " + MathUtil.r2d(tempCumulativeAngle));
							//
							//	if (MathUtil.EqualWithinTolerance(tempCumulativeAngle, -2*Math.PI, ANGLE_TOLERANCE))
							//		segIndex = -1;
							//}
						}
					}
				
				}
				while (segIndex != -1)
			
				// Construct polygon from segments
				var polygon = [];
			
				// Iterate over the list of segments for this polygon
				// and add the first point of each segment
				for (var i = 0; i < polySegments.length; i++)
				{
					segIndex = polySegments[i];
					var ptIndexA = segList.segments[segIndex].indexA;
					
					var pt = {x:segList.points[ptIndexA].x, y:segList.points[ptIndexA].y};
					
					if (segList.segments[segIndex]["tag"] != undefined)
						pt.tag = segList.segments[segIndex].tag;
						
					if (segList.segments[segIndex]["offset"] != undefined)
						pt.offset = segList.segments[segIndex].offset;
						
					if (segList.points[ptIndexA].clipPolyEdgeIdx != undefined) // 2022.05.16
						pt.clipPolyEdgeIdx = segList.points[ptIndexA].clipPolyEdgeIdx;

					// Lattice z-value for ptA, where ptA is the starting point for the
					// edge leading away from this point.
					pt.ptAz = segList.segments[segIndex].ptAz;

					// Need index of previous segment to get any z-value for ptB, where
					// ptB is the ending point for the edge that was leading to this point.
					let prevI = (i + polySegments.length - 1) % polySegments.length;
					let prevSegIndex = polySegments[prevI];
					pt.ptBz = segList.segments[prevSegIndex].ptBz;
						
					// 2022.02.09: Identify the source "node" in the segment graph for
					// this point. We use propagate this to the lattice edges so we can
					// connect them efficiently.
					pt.nodeIdx = ptIndexA;

					polygon.push(pt);
				}
				// If the last point of the last segment does not match the first point of the
				// first segment, then this is an "open polygon"
				// --- TODO: Add "open polygon" support

				// Add to list
				polyList.AddPolygonPoints(polygon);
			}
		}
		while (!done)
	
	
		SegDebug_local("(done: Generate Polygon List)");

		return polyList;
	}

	var SegmentList_FindBounds = function(segList)
	{
		var minx = undefined;
		var miny = undefined;
		var maxx = undefined;
		var maxy = undefined;
		var bounds = undefined;
		
		var p = segList.points;
		for (var j = 0; j < p.length; j++)
		{
			if (minx == undefined)
				minx = p[j].x;
			else if (minx > p[j].x)
				minx = p[j].x;
				
			if (miny == undefined)
				miny = p[j].y;
			else if (miny > p[j].y)
				miny = p[j].y;
				
			if (maxx == undefined)
				maxx = p[j].x;
			else if (maxx < p[j].x)
				maxx = p[j].x;
				
			if (maxy == undefined)
				maxy = p[j].y;
			else if (maxy < p[j].y)
				maxy = p[j].y;
		}
		
		if (minx != undefined && miny != undefined)
			bounds = {min:{x:minx, y:miny}, max:{x:maxx, y:maxy}};
		
		return bounds;
	}
	
	return {
		Create:					SegmentList_Create,
		AddClipPolygon:			SegmentList_AddClipPolygon,
		AddSegment:				SegmentList_AddSegment,
		GetSegmentCount:		SegmentList_GetSegmentCount,
		GetSegment:				SegmentList_GetSegment,
		GeneratePolygonList:	SegmentList_GeneratePolygonList,
		FindBounds:				SegmentList_FindBounds
	};
}());


var Polygon_FindBounds = function(polygonPoints, bounds)
{
	// Find the bounds of one polygon. If the 'bounds' variable is defined,
	// then start with those values, otherwise create a new 'bounds' variable
	//
	if (polygonPoints.length > 0)
	{
		var p = polygonPoints;
		if (bounds == undefined)
			bounds = {min:{x:p[0].x, y:p[0].y}, max:{x:p[0].x, y:p[0].y}}
	
		for (var j = 0; j < p.length; j++)
		{
			if (bounds.min.x > p[j].x)
				bounds.min.x = p[j].x;
				
			if (bounds.min.y > p[j].y)
				bounds.min.y = p[j].y;
				
			if (bounds.max.x < p[j].x)
				bounds.max.x = p[j].x;
				
			if (bounds.max.y < p[j].y)
				bounds.max.y = p[j].y;
		}
	}

	return bounds;
}

//------------------------------------------------------------------------------------------
//	Polygon_CalcCentroid
//		Find the average of all of the points, which is equivalent to the centroid
//		2021.06.23: Created
//------------------------------------------------------------------------------------------
var Polygon_CalcCentroid = function(polygonPoints)
{
	var avg = undefined;

	let len = polygonPoints.length;
	if (len > 0)
	{
		avg = {x:0, y:0};
		polygonPoints.forEach( pt => {avg.x += pt.x; avg.y += pt.y});
		avg.x /= len;
		avg.y /= len;
	}
	
	return avg;
}

	/*-----------------------------------------------*
	 * Polygon List: Calc Offset Vertex
	 * Utility function to calculate a vertex that is
	 * the at the intersection of two lines that are
	 * parallel to the lines AB and BC and a given
	 * distance away.
	 * Returns nil/NULL if the lines AB and BC are
	 * parallel and point in the opposite directions.
	 *-------4----------------------------------------*/
	var PolygonList_CalcOffsetIntersection = function(lineAB, lineCD, offsetDistanceAB, offsetDistanceCD)
	{
		let PARALLEL_TOLERANCE = 1000;
		var offsetVtx = undefined;
		var A = lineAB.ptA; //threePointList[0];
		var B = lineAB.ptB; //threePointList[1];
		var C = lineCD.ptA; //threePointList[2];
		var D = lineCD.ptB; //threePointList[2];
	
		var unitAB = MathUtil.CalcUnitVector(A, B);
		var unitCD = MathUtil.CalcUnitVector(C, D);
		
		if (offsetDistanceCD == undefined)
			offsetDistanceCD = offsetDistanceAB;
	
		// MathUtil.CalcUnitVector returns undefined for vectors of zero length
		if (unitAB != undefined && unitCD != undefined)
		{
			var E = {x:(B.x + offsetDistanceAB * unitAB.y), y:(B.y - offsetDistanceAB * unitAB.x)};
			var F = {x:(D.x + offsetDistanceCD * unitCD.y), y:(D.y - offsetDistanceCD * unitCD.x)};
	
			var den = unitAB.y * unitCD.x - unitAB.x * unitCD.y;
			var num = (E.x - F.x) * unitCD.y - (E.y - F.y) * unitCD.x;

			var dot = unitAB.x * unitCD.x + unitAB.y * unitCD.y;
	
			// The dot product of the unit vectors give the cosine of the angle between the 
			// vectors. If this is 1.0, then the lines are parallel and pointing in the same direction
			// If this is -1.0, then the are also parallel, but pointing in the opposite direction
			if (MathUtil.EqualWithinTolerance(dot, 1.0, PARALLEL_TOLERANCE))
			{
				// Lines point in the same direction. Return the point (already calculated) that
				// is offsetDistance away.
				offsetVtx = E; //{x:E.x + offsetDistance * unitAB.x, y:E.y + offsetDistance * unitAB.y};
			}
			else if (MathUtil.EqualWithinTolerance(dot, -1.0, PARALLEL_TOLERANCE))
			{
				// Lines point in opposite direction. Return undefined
			}
			else
			{
				var v = num/den;
				offsetVtx = {x:E.x + v * unitAB.x, y:E.y + v * unitAB.y};
			}
		}
	
		return offsetVtx;
	}
	
	var PolygonList_CalcIntersection = function(lineAB, lineCD, debugLog = false)
	{
		var offsetVtx = undefined;
		var A = lineAB.ptA;
		var B = lineAB.ptB;
		var C = lineCD.ptA;
		var D = lineCD.ptB;
	
		var unitAB = MathUtil.CalcUnitVector(A, B);
		var unitCD = MathUtil.CalcUnitVector(C, D);
		
		// MathUtil.CalcUnitVector returns undefined for vectors of zero length
		if (unitAB != undefined && unitCD != undefined)
		{
			var den = unitAB.y * unitCD.x - unitAB.x * unitCD.y;
			var num = (B.x - D.x) * unitCD.y - (B.y - D.y) * unitCD.x;

			var dot = unitAB.x * unitCD.x + unitAB.y * unitCD.y;
	
			// The dot product of the unit vectors give the cosine of the angle between the 
			// vectors. If this is 1.0, then the lines are parallel and pointing in the same direction
			// If this is -1.0, then the are also parallel, but pointing in the opposite direction
			if (MathUtil.EqualWithinTolerance(dot, 1.0, NORMAL_TOLERANCE * 10))
			{
				// Lines point in the same direction. Return the point (already calculated) that
				// is offsetDistance away.
				offsetVtx = B;
			}
			else if (MathUtil.EqualWithinTolerance(dot, -1.0, NORMAL_TOLERANCE * 10))
			{
				// Lines point in opposite direction. Return undefined
				if (debugLog) console.log("      CalcIntersection: lines point in opposite directions; dot product: " + dot);
			}
			else
			{
				var v = num/den;
				//if (v >= 0)
					offsetVtx = {x:B.x + v * unitAB.x, y:B.y + v * unitAB.y};
			}
		}
		else
		{
				if (debugLog) console.log("      CalcIntersection: one or both unit vectors is undefined");
		}
	
		return offsetVtx;
	}
	
	var PolygonList_CalcIntersectionWithCheck = function(lineAB, lineCD, debugLog = false)
	{
		var offsetVtx = undefined;
		var A = lineAB.ptA;
		var B = lineAB.ptB;
		var C = lineCD.ptA;
		var D = lineCD.ptB;
	
		var unitAB = MathUtil.CalcUnitVector(A, B);
		var unitCD = MathUtil.CalcUnitVector(C, D);
		
		// MathUtil.CalcUnitVector returns undefined for vectors of zero length
		if (unitAB != undefined && unitCD != undefined)
		{
			var den = unitAB.y * unitCD.x - unitAB.x * unitCD.y;
			var numAB = (B.x - D.x) * unitCD.y - (B.y - D.y) * unitCD.x;
			var numCD = (B.x - D.x) * unitAB.y - (B.y - D.y) * unitAB.x;

			var dot = unitAB.x * unitCD.x + unitAB.y * unitCD.y;
	
			// The dot product of the unit vectors give the cosine of the angle between the 
			// vectors. If this is 1.0, then the lines are parallel and pointing in the same direction
			// If this is -1.0, then the are also parallel, but pointing in the opposite direction
			if (MathUtil.EqualWithinTolerance(dot, 1.0, NORMAL_TOLERANCE * 10))
			{
				// Lines point in the same direction. Return the point (already calculated) that
				// is offsetDistance away.
				offsetVtx = B;
			}
			else if (MathUtil.EqualWithinTolerance(dot, -1.0, NORMAL_TOLERANCE * 10))
			{
				// Lines point in opposite direction. Return undefined
				if (debugLog) console.log("      CalcIntersection: lines point in opposite directions; dot product: " + dot);
			}
			else
			{
				//if (numAB >= 0 && numCD >= 0)
				{
// 					var v = numAB/den;
// 					offsetVtx = {x:B.x + v * unitAB.x, y:B.y + v * unitAB.y};
					var v = numCD/den;
					offsetVtx = {x:D.x + v * unitCD.x, y:D.y + v * unitCD.y};
				}
			}
		}
		else
		{
				if (debugLog) console.log("      CalcIntersection: one or both unit vectors is undefined");
		}
	
		return offsetVtx;
	}

	var PolygonList_CalcOffsetVertex = function(threePointList, offsetDistanceAB, offsetDistanceBC)
	{
		var A = threePointList[0];
		var B = threePointList[1];
		var C = threePointList[2];
		return PolygonList_CalcOffsetIntersection({ptA:A, ptB:B}, {ptA:B, ptB:C}, offsetDistanceAB, offsetDistanceBC);
	}	


	/*-----------------------------------------------*
	 * Calculate a miter point list
	 * The miter in this case is defined as three points,
	 * where the middle point is on the same line as the
	 * line segment from startPt to endPt. The first
	 * and third points are calculated so that the 
	 * internal angles are 120 degrees.
	 *-----------------------------------------------*/
	var PolygonList_CalcMiterPointList = function(miterStartPt, miterEndPt, miterType, offsetAB, offsetBA)
	{
		var miterPtList = [];
		var A = miterStartPt;
		var B = miterEndPt;
	
		if (offsetBA == undefined)
			offsetBA = offsetAB;
		
		let w = offsetAB + offsetBA;
		var unitAB = MathUtil.CalcUnitVector(A, B);
			
		// 2020.10.02: If the offset is zero, then return the end point
		if (w == 0)
		{
			miterPtList = [{x:B.x, y:B.y}];
		}
		else if (unitAB != undefined)
		{
			var normAB = {x:-unitAB.y, y:unitAB.x};
			
			if (miterType == PolygonMiterType.HEX)
			{
				var radius = (w) / (Math.sqrt(3.0));
		
				for (var i = 0; i < 3; i++)
				{
					var angle = Math.PI/3 - i * Math.PI/3;
					var cosA = Math.cos(angle);
					var sinA = Math.sin(angle);
			
					var x = B.x + cosA * radius * unitAB.x + sinA * radius * unitAB.y;
					var y = B.y + cosA * radius * unitAB.y - sinA * radius * unitAB.x;

					miterPtList.push({x:x, y:y});
				}
			}
			else if (miterType == PolygonMiterType.BUTT || miterType == PolygonMiterType.SQUARE )
			{
				var squareOffset = 0;
				
				if (miterType == PolygonMiterType.SQUARE)
					squareOffset = (w) / 2;
					
				var x = B.x - offsetAB * normAB.x + squareOffset * unitAB.x;
				var y = B.y - offsetAB * normAB.y + squareOffset * unitAB.y;
				miterPtList.push({x:x, y:y});

				var x = B.x + offsetBA * normAB.x + squareOffset * unitAB.x;
				var y = B.y + offsetBA * normAB.y + squareOffset * unitAB.y;
				miterPtList.push({x:x, y:y});
			}
			else if (miterType == PolygonMiterType.ANGLE_90 || miterType == PolygonMiterType.ANGLE_60) 
			{
				var angleOffset = 0;
				
				if (miterType == PolygonMiterType.ANGLE_90)
					angleOffset = (w) / 2;
				else
					angleOffset = (w) * (Math.sqrt(3.0)/2);
					
				var x = B.x - offsetAB * normAB.x;
				var y = B.y - offsetAB * normAB.y;
				var ptStart = {x:x, y:y};
				
				var x = B.x + offsetBA * normAB.x;
				var y = B.y + offsetBA * normAB.y;
				var ptEnd = {x:x, y:y};
				
				// Handle the case where offsetAB is not equal to offsetBA
				var x = (ptStart.x + ptEnd.x)/2 + angleOffset * unitAB.x;
				var y = (ptStart.y + ptEnd.y)/2 + angleOffset * unitAB.y;
				var ptMid = {x:x, y:y};

				miterPtList.push(ptStart);
				miterPtList.push(ptMid);
				miterPtList.push(ptEnd);
			}
			else if (miterType == PolygonMiterType.ROUND)
			{
				var pts = 12;
				var d = Math.PI / pts;
				var radius = (w) / 2;
		
				for (var i = 0; i <= pts; i++)
				{
					var angle = Math.PI/2 - i * d;
					var cosA = Math.cos(angle);
					var sinA = Math.sin(angle);
			
					var x = B.x + cosA * radius * unitAB.x + sinA * radius * unitAB.y;
					var y = B.y + cosA * radius * unitAB.y - sinA * radius * unitAB.x;

					miterPtList.push({x:x, y:y});
				}
			}

		}
		
		return miterPtList;
	}
	

// Polygon List (API)
	/*-----------------------------------------------*
	 * Polygon List: list of points that form
	 * one or more polygons
	 *
	 * [
	 *    {points:[ {x,y}, {x,y}, {x,y} ... ]},
	 *    {points:[ {x,y}, {x,y}, {x,y} ... ]}
	 *    ...
	 * ] 
	 *
	 * Functions:
	 *
	 * PolygonList_Validate(): Validates the integrity 
	 * of the polygon list
	 *
	 * PolygonList.CalcOffsetPolygon(): Creates a new 
	 * polygon with parallel edges where each edge
	 * is a fixed distance from the corresponding edge
	 * in the input polygon
	 * 
	 *-----------------------------------------------*/

function PolygonList()
{
	this.polygons = [];
}

PolygonList.prototype.GetPolygonCount = function()
{
	return this.polygons.length;
}

PolygonList.prototype.GetPolygonPoints = function(polyIdx)
{
	return this.polygons[polyIdx].points;
}

/*--------------------------------------------------------------------------------------*
 *	Get Polygon Tag Info
 *		Return the tag (object) from the polygon
 *--------------------------------------------------------------------------------------*/
PolygonList.prototype.GetPolygonTagInfo = function(polyIdx)
{
	return this.polygons[polyIdx].info;
}

/*--------------------------------------------------------------------------------------*
 *	FindIndex
 *		Return the index of the first polygon matching the tag info
 *--------------------------------------------------------------------------------------*/
PolygonList.prototype.FindIndex = function(tagMatch)
{
	return this.polygons.findIndex(p => TagsMatch(p.info, tagMatch))
}

/*--------------------------------------------------------------------------------------*
 *	Update Polygon Tag Info
 *		Merge the provided tag with the current tag
 *--------------------------------------------------------------------------------------*/
PolygonList.prototype.UpdatePolygonTagInfo = function(polyIdx, tagUpdate)
{
	if (polyIdx >=0 && polyIdx < this.polygons.length)
	{
		if (this.polygons[polyIdx].info == undefined)
			this.polygons[polyIdx].info = {};
		
		Object.assign(this.polygons[polyIdx].info, tagUpdate);
	}
	else
	{
		console.log("PolygonList.prototype.UpdatePolygonTagInfo: polyIdx out of range: " + polyIdx + ", length: " + this.polygons.length);
	}
}


/*--------------------------------------------------------------------------------------*
 *	Tags Matches
 *		Returns true if the tagInfo matches the tagMatch
 *		2021.04.12: No longer matching on tagInfo.tag to allow the match criteria to
 *		be more flexible.
 *--------------------------------------------------------------------------------------*/
function TagsMatch(tagInfo, tagMatch)
{
	var matched = false;
	
	if (tagMatch != undefined && tagMatch.all)
		matched = true;
	else if (tagInfo == undefined && tagMatch == undefined)
		matched = true;
	// 2021.04.12: Match on specified keys without requiring "tag"
	else if (tagInfo != undefined && tagMatch != undefined /*&& tagInfo.tag == tagMatch.tag*/)
	{
		matched = true;
		
		// All of the properties of tagMatch must match the properties in tagInfo
		let keys = Object.keys(tagMatch);
		
		if (keys.length > 0)
		{
			for (var i = 0; (i < keys.length) && matched; i++)
			{
				let key = keys[i];
				matched = matched && (tagInfo[key] == tagMatch[key]);
			}
		}
	}
	
	return matched;
}

/*--------------------------------------------------------------------------------------*
 *	Polygon Tag Matches
 *		Returns true if the polygon at polyIdx matches the tagMatch
 *		2021.07.07: Add "polygonIdx" to tagMatch to match a specific polygon
 *--------------------------------------------------------------------------------------*/
PolygonList.prototype.PolygonTagMatches = function(polyIdx, tagMatch)
{
	let matched = false;
	
	if (tagMatch != undefined && tagMatch.polygonIdx != undefined && isFinite(tagMatch.polygonIdx))
	{
		matched = (polyIdx == tagMatch.polygonIdx);
	}
	else
	{
		let tagInfo = this.polygons[polyIdx].info;
		matched = TagsMatch(tagInfo, tagMatch);
	}
	
	return matched; 
}


/*--------------------------------------------------------------------------------------*
 *	Polygon Tag Matches
 *		Returns true if the polygon at polyIdx matches the tagMatch
 *--------------------------------------------------------------------------------------*/
PolygonList.prototype.SomePolygonTagMatches = function(tagMatch)
{
	let some = this.polygons.some(p => TagsMatch(p.info, tagMatch));
	
	return some;
}


/*--------------------------------------------------------------------------------------*
 *	Add Polygon Points
 *		Add an array of points (one polygon) to a polygon list
 *		2020.08.21: Added polygonInfo parameter
 *--------------------------------------------------------------------------------------*/
PolygonList.prototype.AddPolygonPoints = function(polygonPoints, polygonInfo = undefined)
{
	var poly = {points:polygonPoints};
	
	if (polygonInfo != undefined)
		poly.info = polygonInfo;
		
	// 2020.10.06: Added
	poly.bounds = MathUtil.FindPolygonBounds(polygonPoints);

	this.polygons.push(poly);
}

/*-----------------------------------------------*
 *	Find Polygons Under Point
 *		
 *	Returns
 *		array
 *			polygon index
 *			point index
 *-----------------------------------------------*/
PolygonList.prototype.FindPolygonsUnderPoint = function(point)
{
	// List of polygon indices that contain the point
	var idcs = [];
	this.polygons.forEach((pl, idx) => 
		{
			let result = MathUtil.PolygonClosestEdgeToPoint(pl.points, point);
			if (result != undefined)
				idcs.push({polyIdx:idx, edge:result});
		});
		
	return idcs;	
}


/*-----------------------------------------------*
 *	Find Polygon Edge Under Point
 *		
 *	Returns
 *		array
 *			polygon index
 *			point index
 *-----------------------------------------------*/
PolygonList.prototype.FindPolygonEdgeUnderPoint = function(point, distance = 0)
{
	// returns true if the point is within the bounds, or just outside by the 'distance'
	const PtInBounds = (pt, b, d) => ( (b.min.x - d < pt.x) && (b.min.y - d < pt.y) && (pt.x < b.max.x + d) && (pt.y < b.max.y + d) )
	
	const LineBounds = (lPtA, lPtB) => 
	{
		return { min: {x:((lPtA.x < lPtB.x) ? lPtA.x : lPtB.x), y:((lPtA.y < lPtB.y) ? lPtA.y : lPtB.y)}, 
		         max: {x:((lPtA.x < lPtB.x) ? lPtB.x : lPtA.x), y:((lPtA.y < lPtB.y) ? lPtB.y : lPtA.y)}  }
	}
	
	const PtInLineBounds = (pt, lPtA, lPtB, d) => PtInBounds(pt, LineBounds(lPtA, lPtB), d)
	
	// List of polygon indices (with empty arrays for pt indices) that contain the point
	var idcs = [];
	this.polygons.forEach((pl, idx) => 
		{ 
			if (PtInBounds(point, pl.bounds, distance)) 
				idcs.push({polyIdx:idx, ptIdcs:[]}); 
		})
	
	// For each polygon that contains the point, find the line that might contain the point
	for (var i = 0; i < idcs.length; i++)
	{
		let idx = idcs[i];
		let polyPoints = [];
		
		if (this.polygons[idx.polyIdx] != undefined && this.polygons[idx.polyIdx].points != undefined )
			polyPoints = this.polygons[idx.polyIdx].points;
		
		polyPoints.forEach((pt, ptIdx, pl) => 
			{
				let lineBounds = LineBounds(pt, polyPoints[(ptIdx + 1) % polyPoints.length])
				if (PtInBounds(point, lineBounds, distance)) 
					idx.ptIdcs.push(ptIdx); 
			})
	}
	
	// Remove the polygons froms the idcs list that have no lines that could contain the point
	idcs = idcs.filter(idx => idx.ptIdcs.length > 0);
	
	// For each line, determine if the point is within distance. 
	for (var i = 0; i < idcs.length; i++)
	{
		let idx = idcs[i];
		
		for (var j = idx.ptIdcs.length - 1; j >= 0 ; j--)
		{
			let ptIdx = idx.ptIdcs[j];
			let polyPoints = this.polygons[idx.polyIdx].points;
			let polyLen = polyPoints.length;
			let linePtA = polyPoints[ptIdx];
			let linePtB = polyPoints[(ptIdx + 1) % polyLen];
			if (MathUtil.CalcPointToLineDistance(point, linePtA, linePtB) > distance)
				idx.ptIdcs.splice(j, 1);
		}
	}

	// Remove the polygons froms the idcs list that have no lines that are within the 
	// specified distance to the point
	idcs = idcs.filter(idx => idx.ptIdcs.length > 0);
	
	return idcs;
}

/*-----------------------------------------------*
 *	Find Polygons Under Point
 *		
 *	Returns
 *		array
 *			polygon index
 *			point index
 *-----------------------------------------------*/
 /*
PolygonList.prototype.FindPolygonsUnderPoint = function(testPoint, polygonList)
{
	// returns true if the point is within the bounds, or just outside by the 'distance'
	const PtInBounds = (pt, b, d) => ( (b.min.x - d < pt.x) && (b.min.y - d < pt.y) && (pt.x < b.max.x + d) && (pt.y < b.max.y + d) )
	
	// List of polygon indices that contain the point
	var resultIdcs = [];
	var idcs = [];
	polygonList.polygons.forEach((pl, idx) => 
		{ 
			let bounds = MathUtil.FindPolygonBounds(pl.points)
			if (PtInBounds(testPoint, bounds, 0)) 
				idcs.push({idx, bounds}); 
		})
	
	// For each polygon that contains the point, find the line that might contain the point
	for (var i = 0; i < idcs.length; i++)
	{
		let idx = idcs[i].idx;
		let b = idcs[i].bounds;
		let outPt = {x:b.min.x - 10, y:b.min.y - 3};
		
		let polyPoints = [];
		
		polyPoints = polygonList.polygons[idx].points;
		
		let crossCount = 0;
		for (var j = 0; j < polyPoints.length; j++)
		{
			let c = MathUtil.CalcSegmentIntersection(testPoint, outPt, polyPoints[j], polyPoints[(j + polyPoints) % polyPoints.length]);
			if (c != undefined && c.cCD > 0.0 && c.cd < 1.0)
				crossCount++;
		}
		
		if ((crossCount % 2) == 1)
			resultIdcs.push(idx);
	}
	
	return idcs;

}
*/

/*-----------------------------------------------*
 *	Translate
 *		Offset all of the points
 *-----------------------------------------------*/
PolygonList.prototype.Translate = function(translatePt)
{
	// Note that this is "nested"
	this.polygons.forEach(pl => pl.points.forEach(pt => { pt.x += translatePt.x; pt.y += translatePt.y }));
}

/*-----------------------------------------------*
 *	Calc Offset Polygon (original)
 *		options:{endCapStyle, cleanUpReversals}
 *-----------------------------------------------*/
PolygonList.prototype.CalcOffsetPolygon = function(offsetDistance, options)
{
 	try 
	{
		var v = 6;
		
		if (options != undefined && options.useRoutine != undefined)
			v = options.useRoutine;

		if (v == 1)
			return this.CalcOffsetPolygon_v1(offsetDistance, options);
		else if (v == 4)
			return this.CalcOffsetPolygon_v4(offsetDistance, options);
		else if (v == 5)
			return this.CalcOffsetPolygon_v5(offsetDistance, options);
		else
			return this.CalcOffsetPolygon_v6(offsetDistance, options);
	}
	catch (err) {
		console.log("PolygonList.prototype.CalcOffsetPolygon:", err);
	}
}

/*-----------------------------------------------*
 *	Calc Offset Polygon (original)
 *		options:{endCapStyle, cleanUpReversals}
 *-----------------------------------------------*/
PolygonList.prototype.CalcOffsetPolygon_v1 = function(offsetDistance, options)
{
	var inputPolygonList = this;
	var outputPolygonList = new PolygonList();
	var endCapStyle = PolygonMiterType.BUTT;
	if (options == undefined || options.endCapStyle == undefined)
		endCapStyle = PolygonMiterType.BUTT;
	else
		endCapStyle = options.endCapStyle;

	for (var p = 0; p < inputPolygonList.GetPolygonCount(); p++)
	{
		var ip = inputPolygonList.GetPolygonPoints(p);
	
		var offsetPoly = [];
		var threePoints = [];
	
		for (var v = 0; v < ip.length; v++)
		{
			threePoints[0] = ip[(v == 0) ? (ip.length - 1) : (v-1)];
			threePoints[1] = ip[v];
			threePoints[2] = ip[(v+1) % ip.length];	
		
			var offsetAB = (threePoints[0]["offset"] == undefined) ? offsetDistance : threePoints[0]["offset"];
			var offsetBC = (threePoints[1]["offset"] == undefined) ? offsetDistance : threePoints[1]["offset"];
			
			var vtx = PolygonList_CalcOffsetVertex(threePoints, offsetAB, offsetBC);
		
			// Add the offset vertex if one could be computed
			if (vtx != undefined)
			{
				vtx.tag = threePoints[1].tag;
				vtx.inputPtIdx = v; // 2017.11.16
				offsetPoly.push(vtx);
			}
			// We won't find an offset vertex if the lines point in opposite directions.
			// In that case we create a list of points according to the "end cap" style
			else
			{
				var miterVtxList = PolygonList_CalcMiterPointList(threePoints[0], threePoints[1], endCapStyle, offsetAB, offsetBC);
				if (miterVtxList.length > 0)
				{
					for (var i = 0; i < miterVtxList.length; i++)
					{
						miterVtxList[i].tag = threePoints[1].tag;
						offsetPoly.push(miterVtxList[i]);
					}
				}
				else
				{
					console.log("PolygonList_CalcMiterPointList returned zero length");
					offsetPoly.push(threePoints[1]);
				}
			}
		}
		
		// 2019.01.11: Always analyze for inverted segments instead of only when 
		// cleanUpReversals is true
		var invertedCount = 0;
		for (var i = 0; i < offsetPoly.length; i++)
		{
			if (offsetPoly[i].inputPtIdx != undefined)
			{
				// Find the angle between the original line and offset line. Since the angle
				// is calculated from ptA -> ptB, it will have a direction. Therefore, the
				// difference between the lines should be either near zero or near 180 degrees
				var idx = offsetPoly[i].inputPtIdx;
				var srcAngle = MathUtil.CalcAngle(ip[idx], ip[(idx + 1) % ip.length]);
				var newAngle = MathUtil.CalcAngle(offsetPoly[i], offsetPoly[(i+1) % offsetPoly.length]);
				var deltaAngle = MathUtil.CalcAngleDiff(srcAngle, newAngle);
				while (deltaAngle < -0.01)
					deltaAngle += Math.PI * 2;
			
				// If the angle is close to 180 degrees, then the line is considered inverted.
				if (deltaAngle > Math.PI * 0.9)
				{
					offsetPoly[i].inverted = true;
					invertedCount++;
				}
			}
		}

		// 2017.11.16: Clean-up the output polygon, if requested
		var keepPolygon = true;
		if (options != undefined && options.cleanUpReversals)
		{
			
			/////////////////////////////////////////////////
			/////////////////////////////////////////////////
			
			// If no inversions, then nothing to do
			if (invertedCount == 0)
			{
			}
			// If every line if pointing in the opposite direction, then reject the poly
			else if (invertedCount == offsetPoly.length)
			{
				keepPolygon = false;
			}
			// If there are less than three segments pointing in the original direction, then reject
			else if (offsetPoly.length - invertedCount < 3)
			{
				keepPolygon = false;
			}
			// Else, try to remove the inverted lines and find the intersection of the non-inverted lines
			else
			{
				// This algorithm is a mess and needs to be rewritten. For now it works okay for basic cases.
				var done = false;
				var loopLimit = offsetPoly.length;
				var failed = false;
				while (!done && loopLimit-- >= 0 && !failed)
				{
					var nextInvertedIdx = undefined;
					var invertedLen = 0;
					var invertedLenStart = 0;
					var idx = 0;
					
					// Find the next inverted line
					while (nextInvertedIdx == undefined && idx < offsetPoly.length)
					{
						if (offsetPoly[idx].inverted)
							nextInvertedIdx = idx;
						idx++;
					}
					
					if (nextInvertedIdx == undefined)
						done = true;
					else
					{
						// Look for any sequence of inverted lines
						invertedLen = 1;
						while ((nextInvertedIdx + invertedLen) < offsetPoly.length && offsetPoly[nextInvertedIdx + invertedLen].inverted)
							invertedLen++;
						invertedLenStart = invertedLen;
						
						//console.log("nextInvertedIdx:" + nextInvertedIdx + ", invertedLen:" + invertedLen);
						
						var found = false;
						var removeRemaining = false;
						var maxSearchLen = 8;
						var searchLen = 1;
						var searchLenStart = searchLen;
						
						while (!found)
						{
							// Determine if there is an intersection between the two lines before and after the inverted sequence
							var ptA = offsetPoly[(nextInvertedIdx - 1 + offsetPoly.length) % offsetPoly.length];
							var ptB = offsetPoly[nextInvertedIdx];
							var ptC = offsetPoly[(nextInvertedIdx + invertedLen) % offsetPoly.length];
							var ptD = offsetPoly[(nextInvertedIdx + invertedLen + 1) % offsetPoly.length];
						
							var results = MathUtil.CalcSegmentIntersection(ptA, ptB, ptC, ptD, false /*true*/);
						
							//console.log("nextInvertedIdx:" + nextInvertedIdx + ", invertedLen:" + invertedLen + ", results:" + JSON.stringify(results));
							if (results != undefined && results.c1 >= -0.01 && results.c1 <= 1.01 && results.c2 >= -0.01 && results.c2 <= 1.01)
							{
								var ptEx = ptA.x + results.c1 * (ptB.x - ptA.x);
								var ptEy = ptA.y + results.c1 * (ptB.y - ptA.y);
							
								offsetPoly[nextInvertedIdx].x = ptEx;
								offsetPoly[nextInvertedIdx].y = ptEy;
								delete offsetPoly[nextInvertedIdx].inverted;
							
								//if (invertedLen > 2)
								//	console.log("removing points... inverted (i:" + p + ")... offsetPoly.length: " + offsetPoly.length + ", nextInvertedIdx:" + nextInvertedIdx + ", invertedLen:" + invertedLen);

								offsetPoly.splice(nextInvertedIdx + 1, invertedLen);
								
							
								found = true;
							}
							else
							{
								// If the lines did not intersect, then check the next line in the polygon.
								if ((invertedLen + 1 < offsetPoly.length - 1) && (searchLen < maxSearchLen))
								{
									invertedLen++;
									while ((nextInvertedIdx + invertedLen) < offsetPoly.length && offsetPoly[nextInvertedIdx + invertedLen].inverted)
										invertedLen++;
									searchLen++;
									//console.log("  nextInvertedIdx:" + nextInvertedIdx + ", invertedLen:" + invertedLen + ", searchLen:" + searchLen);
								}
								// If the lines after the inverted lines did not intersect the line before the inverted
								// lines, then back up one line and try again
								else
								{
									if (nextInvertedIdx > 0)
									{
										nextInvertedIdx--;
										invertedLenStart++;
										invertedLen = invertedLenStart;
										searchLenStart++;
										searchLen = searchLenStart;
																				
										if (invertedLen + 1 >= offsetPoly.length - 1 || (searchLen >= maxSearchLen))
										{
											found = true;
											// Could not handle the inverted segment
											if (options != undefined && options.debugLog)
												console.log("A:failed to handle inverted (i:" + p + ")... offsetPoly.length: " + offsetPoly.length + ", nextInvertedIdx:" + nextInvertedIdx + ", invertedLen:" + invertedLen);
											failed = true;
										}
										else
										{
											//console.log("  nextInvertedIdx:" + nextInvertedIdx + ", invertedLen:" + invertedLen + ", searchLen:" + searchLen);
										}
									}
									else
									{
										found = true;
										// Could not handle the inverted segment
										if (options != undefined && options.debugLog)
											console.log("B:failed to handle inverted (i:" + p + ")... offsetPoly.length: " + offsetPoly.length + ", nextInvertedIdx:" + nextInvertedIdx + ", invertedLen:" + invertedLen);
										failed = true;
									}
								}
							}
						}
					}
					//done = true;
				}
				// 2019.01.08: Delete all remaining inverted segments
				if (options != undefined && options.debugLog)
				{
					var unprocessedInvertedCount = 0;

					for (var i = 0; i < offsetPoly.length; i++)
						if (offsetPoly[i].inverted)
							unprocessedInvertedCount++;

					if (unprocessedInvertedCount > 0)
						console.log("unprocessedInvertedCount: " + unprocessedInvertedCount);
				}
				
				/*
				for (var i = offsetPoly.length - 1; i > 0; i--)
				{
					if (offsetPoly[i - 1].inverted)
					{
						offsetPoly.splice(i, 1);
					}
				} 
				if (offsetPoly.length < 3)
					keepPolygon = false;
				*/
			}
		}
		
		
		// 2017.11.16: Remove the temporary properties 
		// 2019.01.09: Provide option to retain notes
		if (options != undefined && (options.keepCleanUpReversalsNotes == undefined || !options.keepCleanUpReversalsNotes))
		{
			for (var i = 0; i < offsetPoly.length; i++)
			{
				delete offsetPoly[i].inputPtIdx;
				delete offsetPoly[i].inverted;
			}
		}
	
		// 2017.11.16: Added 'keepPolygon'
		if (keepPolygon)
			outputPolygonList.AddPolygonPoints(offsetPoly);
	}

	return outputPolygonList;
}

/*-----------------------------------------------*
 *	Calc Offset Polygon (version 2)
 *		options:{endCapStyle, cleanUpReversals}
 *-----------------------------------------------*/
PolygonList.prototype.CalcOffsetPolygon_v2 = function(offsetDistance, options)
{
	var inputPolygonList = this;
	var outputPolygonList = new PolygonList();
	
	if (options != undefined && options.debugLog)
		console.log("...calc offset poly...");
	
	// Determine the endcap style
	var endCapStyle = PolygonMiterType.BUTT;
	if (options == undefined || options.endCapStyle == undefined)
		endCapStyle = PolygonMiterType.BUTT;
	else
		endCapStyle = options.endCapStyle;

	// Calc the offset polygon for each of the polygons in the list
	for (var p = 0; p < inputPolygonList.GetPolygonCount(); p++)
	{
		var ip = inputPolygonList.GetPolygonPoints(p);
		
		// Start with a list of lines (basically the polygon), where each
		// line will be used to calculate a parallel line. The intersections
		// of consecutive parallel lines will yield the offset polygon
		var parallels = [];
		for (var v = 0; v < ip.length; v++)
		{
			var start = (v + ip.length - 1) % ip.length;
			var end   = (v + 0) % ip.length;
			var s = {idxA:start, idxB:end, include:true};
			parallels.push(s);
		}
		
		var offsetPoly;
		var offsetCalcCompleted = false;
		var offsetCalcPass = 0;
		var keepPolygon = true;
	
		while (!offsetCalcCompleted)
		{
			offsetPoly = [];
			var pstart = 0;
			
			// Find first segment to include
			while (pstart < parallels.length && !parallels[pstart].include)
				pstart++;
				
			while (pstart < parallels.length)
			{
				var pend = pstart + 1;
				var count = 0;
				while (count < parallels.length && !parallels[pend % parallels.length].include)
				{
					pend++;
					count++;
				}				
				
				// Get the info for two parallel lines from the parallels list
				var parAB = parallels[pstart];
				var parCD = parallels[pend % parallels.length];
				
				if (options != undefined && options.debugLog)
					console.log("Parallels (" + parAB.idxA + " --> " + parAB.idxB + ") and  (" + parCD.idxA + " --> " + parCD.idxB + ")");
			
				// Create the line objects for the _CalcOffsetIntersection
				var lineAB = {ptA:ip[parAB.idxA], ptB:ip[parAB.idxB]};
				var lineCD = {ptA:ip[parCD.idxA], ptB:ip[parCD.idxB]};
		
				// Get the offset info
				var offsetAB = (lineAB.ptA["offset"] == undefined) ? offsetDistance : lineAB.ptA["offset"];
				var offsetCD = (lineCD.ptA["offset"] == undefined) ? offsetDistance : lineCD.ptA["offset"];
			
				var vtx = PolygonList_CalcOffsetIntersection(lineAB, lineCD, offsetAB, offsetCD);
		
				// Add the offset vertex if one could be computed
				if (vtx != undefined)
				{
					vtx.tag = lineCD.ptA.tag; // or should it be lineAB.ptB?
					vtx.inputPtIdx = parAB.idxB;
					vtx.inputParallelIdx = pstart;
					offsetPoly.push(vtx);
				}
				// We won't find an offset vertex if the lines point in opposite directions.
				// In that case we create a list of points according to the "end cap" style
				else
				{
					var miterVtxList = PolygonList_CalcMiterPointList(lineAB.ptA, lineAB.ptB, endCapStyle, offsetAB, offsetCD);
					if (miterVtxList.length > 0)
					{
						for (var i = 0; i < miterVtxList.length; i++)
						{
							miterVtxList[i].tag = lineAB.ptB.tag; // or should it be lineCD.ptA?;
							offsetPoly.push(miterVtxList[i]);
						}
					}
					else
					{
						console.log("PolygonList_CalcMiterPointList returned zero length");
						offsetPoly.push(lineAB.ptB);
					}
				}
				
				// 
				pstart = pend;
			}
			
			// By default, we only do one pass. This can change if cleanUpReversals is true
			offsetCalcCompleted = true;
			
			// Analyze the results to identify inverted lines.
			// The option to use this to clean-up the result is below.
			var invertedCount = 0;
			
			for (var i = 0; i < offsetPoly.length; i++)
			{
				if (offsetPoly[i].inputPtIdx != undefined)
				{
					// Find the angle between the original line and offset line. Since the angle
					// is calculated from ptA -> ptB, it will have a direction. Therefore, the
					// difference between the lines should be either near zero or near 180 degrees
					var idx = offsetPoly[i].inputPtIdx;
					var srcAngle = MathUtil.CalcAngle(ip[idx], ip[(idx + 1) % ip.length]);
					var newAngle = MathUtil.CalcAngle(offsetPoly[i], offsetPoly[(i+1) % offsetPoly.length]);
					var deltaAngle = MathUtil.CalcAngleDiff(srcAngle, newAngle);
					while (deltaAngle < -0.01)
						deltaAngle += Math.PI * 2;
			
					// If the angle is close to 180 degrees, then the line is considered inverted.
					if (deltaAngle > Math.PI * 0.9)
					{
						offsetPoly[i].inverted = true;
						invertedCount++;
					}
				}
			}

			if (options != undefined && options.cleanUpReversals)
			{
				// If no inversions, then nothing to do
				if (invertedCount == 0)
				{
					offsetCalcCompleted = true;
				}
				// If every line if pointing in the opposite direction, then reject the poly
				else if (invertedCount == offsetPoly.length)
				{
					keepPolygon = false;
					offsetCalcCompleted = true;
				}
				// If there are less than three segments pointing in the original direction, then reject
				// 2019.01.09: Use length of input polygon instead of output polygon, since endcaps add points
				else if (ip.length - invertedCount < 3)
				{
					keepPolygon = false;
					offsetCalcCompleted = true;
				}
				// Else, try to remove the inverted lines and find the intersection of the non-inverted lines
				else
				{
					if (options.debugLog)
						console.log("inverted count: " + invertedCount);
					// Identify "runs" of inverted lines
				
					// Look for a non-inverted line first, in case the first entry is in the middle of a run
					var rstart = 0;
					var rlen = 0;
					while (rstart < offsetPoly.length && offsetPoly[rstart].inverted)
						rstart++;

					while (rstart < offsetPoly.length)
					{
						// Look for an inverted line
						while (rstart < offsetPoly.length && !offsetPoly[rstart].inverted)
							rstart++;
					
						// If we found one, then find the length
						if (rstart < offsetPoly.length)
						{
							rlen = 1;
							while (offsetPoly[(rstart + rlen) % offsetPoly.length].inverted && rlen < offsetPoly.length)
								rlen++;
					
							// Remove the inverted lines from the parallels list
							if (rlen == 1)
							{
								var oIdx = (rstart + 1) % offsetPoly.length;
								var pIdx = offsetPoly[oIdx].inputParallelIdx;
								if (pIdx != undefined)
									parallels[pIdx].include = false;
								else
									console.log("Missing inputParallelIdx at ", rstart);
							}
							else
							{
								var shift = 2;
								for (var ii = shift; ii < rlen + shift - 1; ii++)
								{
									var oIdx = (rstart + ii) % offsetPoly.length;
									var pIdx = offsetPoly[oIdx].inputParallelIdx;
									if (pIdx != undefined)
									{
										parallels[pIdx].include = false;
										if (options.debugLog)
											console.log("omitting parallel idx: " + pIdx);
									}
									else
										console.log("Missing inputParallelIdx at ", oIdx);
								}
							}						
							// Point to the first non-inverted line after the current run
							rstart += rlen;
						} // if-found-run-start	
					} // while-not-at-end-of-offsetPoly

					//
					offsetCalcPass++;
					offsetCalcCompleted = (offsetCalcPass > 1);
				} // if-else-removeInverted		
			}

		}
		
		
		// 2017.11.16: Remove the temporary properties 
		// 2019.01.09: Provide option to retain notes
		if (options != undefined && (options.keepCleanUpReversalsNotes == undefined || !options.keepCleanUpReversalsNotes))
		{
			for (var i = 0; i < offsetPoly.length; i++)
			{
				delete offsetPoly[i].inputPtIdx;
				delete offsetPoly[i].inverted;
			}
		}
	
		// 2017.11.16: Added 'keepPolygon'
		if (keepPolygon)
			outputPolygonList.AddPolygonPoints(offsetPoly);
			
	}

	return outputPolygonList;
}

/*-----------------------------------------------*
 *	Calc Offset Polygon (version 3)
 *		options:{endCapStyle, cleanUpReversals}
 *-----------------------------------------------*/
PolygonList.prototype.CalcOffsetPolygon_v3 = function(offsetDistance, options)
{
	var inputPolygonList = this;
	var outputPolygonList = new PolygonList();
	
	if (options.debugLog)
		console.log("...CalcOffsetPolygon_v3...");
	
	// Determine the endcap style
	var endCapStyle = PolygonMiterType.BUTT;
	if (options == undefined || options.endCapStyle == undefined)
		endCapStyle = PolygonMiterType.BUTT;
	else
		endCapStyle = options.endCapStyle;

	// Calc the offset polygon for each of the polygons in the list
	for (var p = 0; p < inputPolygonList.GetPolygonCount(); p++)
	{
		var ip = inputPolygonList.GetPolygonPoints(p);
		
		// Start with a list of lines (basically the polygon), where each
		// line will be used to calculate a parallel line. The intersections
		// of consecutive parallel lines will yield the offset polygon
		var lines = [];
		for (var v = 0; v < ip.length; v++)
		{
			var start = (v + ip.length - 1) % ip.length;
			var end   = (v + 0) % ip.length;
			var s = {idxA:start, idxB:end, include:true};
			lines.push(s);
		}
		
		var offsetPoly = [];
		var keepPolygon = true;
		var done = false;
		
		while (!done)
		{

			var lnA = 0;
			var lnB = 1;
			var verticesProcessed = 0;
			var vcurrent = 0;
			var consecutiveVtxCount = 0;
			var previousVtxAngle = undefined;
			var foundstart = false; 
			var loopMaxCount = lines.length * 4; // arbitrary
			var loopCount = 0;
			var failureOccurred = false;
		
			while (verticesProcessed < lines.length && loopCount < loopMaxCount && !failureOccurred)
			{
			
				// Get the info for two parallel lines from the lines list
				var parAB = lines[lnA];
				var parCD = lines[lnB % lines.length];
				var advanceLineA = true;
			
				if (options.debugLog)
					console.log("Lines (" + parAB.idxA + " --> " + parAB.idxB + ") and  (" + parCD.idxA + " --> " + parCD.idxB + ")");
		
				// Create the line objects for the _CalcOffsetIntersection
				var lineAB = {ptA:ip[parAB.idxA], ptB:ip[parAB.idxB]};
				var lineCD = {ptA:ip[parCD.idxA], ptB:ip[parCD.idxB]};
	
				// Get the offset info
				var offsetAB = (lineAB.ptA["offset"] == undefined) ? offsetDistance : lineAB.ptA["offset"];
				var offsetCD = (lineCD.ptA["offset"] == undefined) ? offsetDistance : lineCD.ptA["offset"];
		
				var vtx = PolygonList_CalcOffsetIntersection(lineAB, lineCD, offsetAB, offsetCD);
			
				var currentVtxAngle = MathUtil.CalcAngleBetweenLines(lineAB, lineCD);
				var currentLineAngle  = MathUtil.CalcAngle(lineAB.ptA, lineAB.ptB);
	
				// Add the offset vertex if one could be computed
				if (vtx != undefined)
				{
					vtx.tag = lineCD.ptA.tag; // or should it be lineAB.ptB?
					vtx.inputPtIdx = parCD.idxA;
					vtx.inputParallelIdx = lnA;
					offsetPoly.push(vtx);
					// Track the number of consecutive (non endCap) vertices
					consecutiveVtxCount++;

					// Check the angle of the previous line once we have at least two vertices
					if (consecutiveVtxCount >= 2)
					{
						var ptA = offsetPoly[offsetPoly.length - 2];
						var ptB = offsetPoly[offsetPoly.length - 1];
						var lineAngle  = MathUtil.CalcAngle(ptA, ptB);
					
						if (options.debugLog)
							console.log("Processed:", verticesProcessed, " Line angles:", currentLineAngle.toFixed(3), lineAngle.toFixed(3));
						
						var deltaAngle = MathUtil.CalcAngleDiff(currentLineAngle, lineAngle);
						while (deltaAngle < -0.01)
							deltaAngle += Math.PI * 2;
		
						// If the angle is close to 180 degrees, then the line is considered inverted.
						if (deltaAngle > Math.PI * 0.9)
						{
							if (options.debugLog)
								console.log("Processed:", verticesProcessed, " Line angles don't match:", currentLineAngle.toFixed(3), lineAngle.toFixed(3));
							if (!foundstart)
							{
								// If we have not found the start, then discard the (two) vertices found so far.
								offsetPoly = [];
							
								// Mark the line used to find those vertices as "not included"
								lines[lnA].include = false;

								// We discarded both vertices because they were computed using a line that 
								// is now excluded from the search. We will need to compute one of those 
								// vertices again, so we decrement the 'vertices processed' counter
								verticesProcessed--;
							
								// Restart the 'consecutive vertex count'
								consecutiveVtxCount = 0;

								if (options.debugLog)
									console.log("  discarding the (two) vertices in offset poly since start not found.");
							}
							else
							{
								// If we have already found the start, then this means that we have valid 
								// points in the offset poly and it is the new point that is causing the 
								// problem, so discard it.
								offsetPoly.splice(offsetPoly.length - 1, 1);
							
								// But if the last point is invalid, it also means that we can't use the line that
								// end with the last point, so we have to back up another point and recompute that
								offsetPoly.splice(offsetPoly.length - 1, 1);
							
								// Also, 
								advanceLineA = false;

								if (options.debugLog)
									console.log("  discarding last two vertices in offset poly. Length: " + offsetPoly.length);
							}
						}
						// Line is not inverted
						else
						{
							// If this is the first line we have found pointing in the correct
							// direction, then we have found the 'start' of the offset polygon.
							// ??? why? At this point we reset the verticesProcessed
							if (!foundstart)
							{
								foundstart = true;
								//verticesProcessed = 2;
							}
						}
					}				

					/*
					// Check the angle of the previous vertex once we have at least three vertices
					if (consecutiveVtxCount >= 3)
					{
						var ptA = offsetPoly[offsetPoly.length - 3];
						var ptB = offsetPoly[offsetPoly.length - 2];
						var ptC = offsetPoly[offsetPoly.length - 1];
						var vtxAngle = MathUtil.CalcVertexAngle(ptA, ptB, ptC);
				
						// If the angle does not match the corresponding angle of the input polygon
						// then...??
						if (options.debugLog)
							console.log("Processed:", verticesProcessed, " Vertex angles:", previousVtxAngle.toFixed(3), vtxAngle.toFixed(3));
						if (!MathUtil.EqualWithinTolerance(previousVtxAngle, vtxAngle, ANGLE_TOLERANCE))
						{
							console.log("Processed:", verticesProcessed, " Vertex angles don't match:", previousVtxAngle.toFixed(3), vtxAngle.toFixed(3));
						}
					}
					*/
				}
				// We won't find an offset vertex if the lines point in opposite directions.
				// In that case we create a list of points according to the "end cap" style
				else
				{
					var miterVtxList = PolygonList_CalcMiterPointList(lineAB.ptA, lineAB.ptB, endCapStyle, offsetAB, offsetCD);
					if (miterVtxList.length > 0)
					{
						for (var i = 0; i < miterVtxList.length; i++)
						{
							miterVtxList[i].tag = lineAB.ptB.tag; // or should it be lineCD.ptA?;
							offsetPoly.push(miterVtxList[i]);
						}
					}
					else
					{
						console.log("PolygonList_CalcMiterPointList returned zero length");
						offsetPoly.push(lineAB.ptB);
					}
				
					// Reset the 'consecutive vertex count'
					consecutiveVtxCount = 0;
				}
			
				// 
				//pstart = pend;
				//verticesProcessed++;
				loopCount++;
				if (advanceLineA)
				{
					lnA = lnB;
					lnB = (lnB + 1) % lines.length;
					while (!lines[lnB].include  && lnB != lnA)
						lnB = (lnB + 1) % lines.length;
					
					if (lnB == lnA)
					{
						failureOccurred = true;
						console.log("Can't find next vertex because all are marked 'not included'")
					}
				
					verticesProcessed++;
				}
				else
				{
					lnA = (lnA + lines.length - 1) % lines.length;
				}
			
				previousVtxAngle = currentVtxAngle;
			}
		
			// Analyze the results to identify inverted lines.
			// The option to use this to clean-up the result is below.
			var invertedCount = 0;
		
			for (var i = 0; i < offsetPoly.length; i++)
			{
				if (offsetPoly[i].inputPtIdx != undefined)
				{
					// Find the angle between the original line and offset line. Since the angle
					// is calculated from ptA -> ptB, it will have a direction. Therefore, the
					// difference between the lines should be either near zero or near 180 degrees
					var idx = offsetPoly[i].inputPtIdx;
					var srcAngle = MathUtil.CalcAngle(ip[idx], ip[(idx + 1) % ip.length]);
					var newAngle = MathUtil.CalcAngle(offsetPoly[i], offsetPoly[(i+1) % offsetPoly.length]);
					var deltaAngle = MathUtil.CalcAngleDiff(srcAngle, newAngle);
					while (deltaAngle < -0.01)
						deltaAngle += Math.PI * 2;
		
					// If the angle is close to 180 degrees, then the line is considered inverted.
					if (deltaAngle > Math.PI * 0.9)
					{
						offsetPoly[i].inverted = true;
						invertedCount++;
					}
				}
			}

			if (options != undefined && options.cleanUpReversals)
			{
				// If no inversions, then nothing to do
				if (invertedCount == 0)
				{
				}
				// If every line if pointing in the opposite direction, then reject the poly
				else if (invertedCount == offsetPoly.length)
				{
					keepPolygon = false;
				}
				// If there are less than three segments pointing in the original direction, then reject
				// 2019.01.09: Use length of input polygon instead of output polygon, since endcaps add points
				else if (ip.length - invertedCount < 3)
				{
					keepPolygon = false;
				}
				// Else, try to remove the inverted lines and find the intersection of the non-inverted lines
				else
				{
					if (options.debugLog)
						console.log("inverted count: " + invertedCount);
					// Identify "runs" of inverted lines
			
				} // if-else-removeInverted		
			}

			done = true;
		} // while-not-done
		
		// 2017.11.16: Remove the temporary properties 
		// 2019.01.09: Provide option to retain notes
		if (options.keepCleanUpReversalsNotes == undefined || !options.keepCleanUpReversalsNotes)
		{
			for (var i = 0; i < offsetPoly.length; i++)
			{
				delete offsetPoly[i].inputPtIdx;
				delete offsetPoly[i].inverted;
			}
		}
	
		// 2017.11.16: Added 'keepPolygon'
		if (keepPolygon)
			outputPolygonList.AddPolygonPoints(offsetPoly);
			
	}

	return outputPolygonList;
}

/*-----------------------------------------------*
 *	Calc Offset Polygon (version 4)
 *		options:{endCapStyle, cleanUpReversals}
 *-----------------------------------------------*/
PolygonList.prototype.CalcOffsetPolygon_v4 = function(offsetDistance, options)
{
	var inputPolygonList = this;
	var outputPolygonList = new PolygonList();
	
	if (options != undefined && options.debugLog)
		console.log("...CalcOffsetPolygon_v4..., offsetDistance: " + offsetDistance + " " + typeof offsetDistance);
	
	// Determine the endcap style
	var endCapStyle = PolygonMiterType.BUTT;
	if (options == undefined || options.endCapStyle == undefined)
		endCapStyle = PolygonMiterType.BUTT;
	else
		endCapStyle = options.endCapStyle;

	// Calc the offset polygon for each of the polygons in the list
	for (var p = 0; p < inputPolygonList.GetPolygonCount(); p++)
	{
		var ip = inputPolygonList.GetPolygonPoints(p);
		
		// Start with a list of lines (basically the polygon), where each
		// line will be used to calculate a parallel line. The intersections
		// of consecutive parallel lines will yield the offset polygon
		var lines = [];
		for (var v = 0; v < ip.length; v++)
		{
			var start = (v + ip.length - 1) % ip.length;
			var end   = (v + 0) % ip.length;
			var s = {idxA:start, idxB:end, include:true};
			lines.push(s);
		}
		
		var offsetPoly;
		var keepPolygon = true;
		var done = false;
		var pass = 1;
		var passMax = Math.min(ip.length - 1, 20 /* arbitrary */);
		var failed = false;
		
		while (!done && pass <= passMax && !failed)
		{
			if (options != undefined && options.debugLog)
				console.log("Pass:", pass);
		
			var lnA;
			var lnB;
			
			// Initialize offset polygon
			offsetPoly = [];
			
			// Find the first included line
			lnA = 0;
			while (!lines[lnA].include && lnA < lines.length)
				lnA++;
				
		
			while (lnA < lines.length)
			{
				// Find the next included line
				lnB = lnA + 1;
				while (!lines[lnB % lines.length].include && lnB <= lines.length)
					lnB++;
					
				// Get the info for two parallel lines from the lines list
				var parAB = lines[lnA];
				var parCD = lines[lnB % lines.length];
			
				if (options != undefined && options.debugLog)
					console.log("Lines (" + parAB.idxA + " --> " + parAB.idxB + ") and (" + parCD.idxA + " --> " + parCD.idxB + ")");
		
				// Create the line objects for the _CalcOffsetIntersection
				var lineAB = {ptA:ip[parAB.idxA], ptB:ip[parAB.idxB]};
				var lineCD = {ptA:ip[parCD.idxA], ptB:ip[parCD.idxB]};
	
				// Get the offset info
				var offsetAB = (lineAB.ptA["offset"] == undefined) ? offsetDistance : lineAB.ptA["offset"];
				var offsetCD = (lineCD.ptA["offset"] == undefined) ? offsetDistance : lineCD.ptA["offset"];

				// Calculate the vertex
				var vtx = PolygonList_CalcOffsetIntersection(lineAB, lineCD, offsetAB, offsetCD);
			
				// Add the offset vertex if one could be computed
				if (vtx != undefined)
				{
					vtx.tag = lineCD.ptA.tag; // or should it be lineAB.ptB?
					vtx.inputPtIdx = parCD.idxA;
					vtx.inputLinesIdxA = lnA;
					vtx.inputLinesIdxB = (lnB % lines.length);
					offsetPoly.push(vtx);
				}
				// We won't find an offset vertex if the lines point in opposite directions.
				// In that case we create a list of points according to the "end cap" style
				else
				{
					var miterVtxList = PolygonList_CalcMiterPointList(lineAB.ptA, lineAB.ptB, endCapStyle, offsetAB, offsetCD);
					if (miterVtxList.length > 0)
					{
						for (var i = 0; i < miterVtxList.length; i++)
						{
							let vtx = miterVtxList[i];
							if (i == miterVtxList.length - 1)
							{
								vtx.inputPtIdx = parCD.idxA;
								vtx.inputLinesIdxA = lnA;
								vtx.inputLinesIdxB = (lnB % lines.length);
							}
							else
								vtx.miterPtIdx = parCD.idxA;
							vtx.tag = lineAB.ptB.tag; // or should it be lineCD.ptA?;
							offsetPoly.push(vtx);
						}
					}
					else
					{
						console.log("PolygonList_CalcMiterPointList returned zero length");
						offsetPoly.push(lineAB.ptB);
					}
				}
			
				lnA = lnB;
			}
		
			// Analyze the results to identify inverted lines.
			// The option to use this to clean-up the result is below.
			var invertedCount = 0;
		
			for (var i = 0; i < offsetPoly.length; i++)
			{
				if (offsetPoly[i].inputPtIdx != undefined)
				{
					// Find the angle between the original line and offset line. Since the angle
					// is calculated from ptA -> ptB, it will have a direction. Therefore, the
					// difference between the lines should be either near zero or near 180 degrees
					var idx = offsetPoly[i].inputPtIdx;
					var srcAngle = MathUtil.CalcAngle(ip[idx], ip[(idx + 1) % ip.length]);
					var newAngle = MathUtil.CalcAngle(offsetPoly[i], offsetPoly[(i+1) % offsetPoly.length]);
					var deltaAngle = MathUtil.CalcAngleDiff(srcAngle, newAngle);
					while (deltaAngle < -0.01)
						deltaAngle += Math.PI * 2;
		
					// If the angle is close to 180 degrees, then the line is considered inverted.
					if (deltaAngle > Math.PI * 0.9)
					{
						offsetPoly[i].inverted = true;
						invertedCount++;
					}
				}
			}

			if (options != undefined && options.debugLog && invertedCount > 0)
				console.log("inverted count: " + invertedCount);

			// By default, one pass
			done = true;
			
			if (options != undefined && options.cleanUpReversals)
			{
				// If no inversions, then nothing to do
				if (invertedCount == 0)
				{
					done = true;
				}
				// If every line if pointing in the opposite direction, then reject the poly
				else if (invertedCount == offsetPoly.length)
				{
					done = true;
					keepPolygon = false;
				}
				// If there are less than three segments pointing in the original direction, then reject
				// 2019.01.09: Use length of input polygon instead of output polygon, since endcaps add points
				else if (ip.length - invertedCount < 3)
				{
					done = true;
					keepPolygon = false;
				}
				// Else, try to remove the inverted lines and find the intersection of the non-inverted lines
				// But only do that is we are doing another pass, otherwise we are done
				else if (pass < passMax)
				{
					// Try another pass
					done = false;

					// Identify "runs" of inverted lines
					
					// First find a non-inverted line. We do this so we can identify runs that
					// wrap around the end of the offsetPoly array
					var rstart = 0;
					while (rstart < offsetPoly.length && !offsetPoly[rstart].inverted)
						rstart++;
					
					// Count the number of vertices we check.
					var rcount = 0;
					
					while (rcount < offsetPoly.length)
					{
						// If we found an inverted line, then look for a run of inverted lines and select one
						// of the original lines to exclude from the next pass
						if (offsetPoly[rstart].inverted)
						{
							var removedOffset;
							var rlen = 1;
							while (rlen < offsetPoly.length && offsetPoly[(rstart + rlen) % offsetPoly.length].inverted)
								rlen++;
							
							if (options != undefined && options.debugLog)
								console.log(" rstart:" + rstart + ", inverted run length: " + rlen + ", (offsetPoly.length: " + offsetPoly.length + ")");
							
							// If the run length is 1 or more than 2, then we remove the first line,
							// otherwise we remove the second line.
							if (rlen == 1 || rlen > 2)
								removedOffset = 0;
							else //if (rlen == 2)
								removedOffset = 1;
						
							var opIdx = (rstart + removedOffset) % offsetPoly.length;
							var lnIdx = offsetPoly[opIdx].inputLinesIdxB;
							
							// If the line is already excluded and we have some other options..
							if (!lines[lnIdx].include)
							{
								if (options != undefined && options.debugLog)
									console.log("Line already excluded (opIdx=" + opIdx + ", lnIdx=" + lnIdx + "). Checking another");
									
								if (rlen == 1)
								{
									// failed. Need to exit
									if (options != undefined && options.debugLog)
										console.log("Failed to find another line to exclude");
									failed = true;
								}
								else
								{
									if (removedOffset == 0)
										removedOffset = 1;
									else if (removedOffset == 1)
										removedOffset = 0;

									opIdx = (rstart + removedOffset) % offsetPoly.length;
									lnIdx = offsetPoly[opIdx].inputLinesIdxB;
								}
							}
							
							if (!lines[lnIdx].include)
								console.log("Trying to exclude line that is already excluded: opIdx=" + opIdx + ", lnIdx=" + lnIdx);
							
							if (options != undefined && options.debugLog)
								console.log(" excluding line:", lnIdx, "(" + lines[lnIdx].idxA + " --> " + lines[lnIdx].idxB + ")");
								
							lines[lnIdx].include = false;
							
							rstart = (rstart + rlen) % offsetPoly.length;
							rcount += rlen;
						}
						// Advance to check the next line
						else
						{
							rstart = (rstart + 1) % offsetPoly.length;
							rcount++;
						}
					}
					
					// Count the remaining lines. If there are less than three, then we 
					// cannot calc a polygon and we are done
					var lnCount = 0;
					for (var i = 0; i < lines.length; i++)
						if (lines[i].include)
							lnCount++;
							
					if (lnCount < 3)
					{
						done = true;
						keepPolygon = false;
					}
			
				} // if-else-removeInverted		
			}

			pass++;
		} // while-not-done
		
		// 2020.08.21: Create a list of "area" polygons that cover the area between the original polygon and the offset polygon
		var areaPolygonList = [];
		if (options != undefined && options.buildAreaPolygons)
		{
			let cp = pt => { return {x:pt.x, y:pt.y} };

			var color = undefined;
			var colorId = undefined; // 2020.10.15

			for (var i = 0; i < offsetPoly.length; i++)
			{
				try {
					var areaPoly = [];
					let ofPt = offsetPoly[i];
					
					if (ofPt.miterPtIdx == undefined)
					{
						let inputPtIdx = ofPt.inputPtIdx;
						let ipPt = ip[inputPtIdx];
					
						// Get the color either from the pt.color or pt.tag.color
						if (ipPt.color != undefined)
							color = ipPt.color;
						else if (ipPt.tag != undefined &&  ipPt.tag.color != undefined)
							color = ipPt.tag.color;
						else
							color = undefined;

						// Get the colorId (2020.10.15)
						colorId = (ipPt.tag != undefined) ? ipPt.tag.colorId : undefined;

						let ptA = cp(ipPt);
						let ptB = cp(ip[(inputPtIdx + 1) % ip.length]);
						let ptC = cp(offsetPoly[(i + 1) % offsetPoly.length]);
						let ptD = cp(ofPt);

						areaPoly.push(ptA, ptB, ptC, ptD);
					}
					else
					{
						let ipPt = ip[ofPt.miterPtIdx];
					
						// Get the color either from the pt.color or pt.tag.color
						if (ipPt.color != undefined)
							color = ipPt.color;
						else if (ipPt.tag != undefined && ipPt.tag.color != undefined)
							color = ipPt.tag.color;
						else
							color = undefined;

						// Get the colorId (2020.10.15)
						colorId = (ipPt.tag != undefined) ? ipPt.tag.colorId : undefined;

						let ptA = cp(ipPt);
						let ptC = cp(offsetPoly[(i + 1) % offsetPoly.length]);
						let ptD = cp(ofPt);
						areaPoly.push(ptA, ptC, ptD);
					}

					if (areaPoly.length > 2)
						areaPolygonList.push({poly:areaPoly, color, colorId});
				}
				catch (err) {
					// Ignore failures
				}
			}
		}

		// 2017.11.16: Remove the temporary properties 
		// 2019.01.09: Provide option to retain notes
		if (options != undefined && (options.keepCleanUpReversalsNotes == undefined || !options.keepCleanUpReversalsNotes))
		{
			for (var i = 0; i < offsetPoly.length; i++)
			{
				delete offsetPoly[i].inputPtIdx;
				delete offsetPoly[i].inverted;
				delete offsetPoly[i].inputLinesIdxA;
				delete offsetPoly[i].inputLinesIdxB;
			}
		}
	
		// 2017.11.16: Added 'keepPolygon'
		if (keepPolygon)
		{
			outputPolygonList.AddPolygonPoints(offsetPoly);

			// 2020.08.21: Add the area polygons, if they were built, with an 'area' tag
			for (var i = 0; i < areaPolygonList.length; i++)
			{
				let ap = areaPolygonList[i];
				// 2021.04.12: Replace "area" with "isFill"
				outputPolygonList.AddPolygonPoints(ap.poly , {isFill:true, /*tag:"area",*/ color:ap.color});
			}
		}
			
	} // for-loop-inputpolygonlist

	return outputPolygonList;
}

//----------------------------------------------------------------------------------------------
//	Get Vertex Color
//----------------------------------------------------------------------------------------------
function GetVertexColor(vtx)
{
	var color = undefined;
	
	if (vtx == undefined)
		color = undefined;
	else if (vtx.color != undefined)
		color = vtx.color;
	else if (vtx.tag != undefined && vtx.tag.color != undefined)
		color = vtx.tag.color;
	else
		color = undefined;
		
	return color;
}

//----------------------------------------------------------------------------------------------
//	Find Vertex Color
//----------------------------------------------------------------------------------------------
PolygonList.prototype.FindVertexColor = function(vertices, vtxIdx)
{
	let color = undefined;
	
	if (vtxIdx >= 0 && vtxIdx < vertices.length) 
	{
		color = GetVertexColor(vertices[vtxIdx]);
	}

	return color;
}

/*-----------------------------------------------*
 *	Calc Offset Polygon (version 5)
 *		options:{endCapStyle}
 *-----------------------------------------------*/
PolygonList.prototype.CalcOffsetPolygon_v5_Single_v1 = function(vertices, offsetDistance, options)
{
	var single = { insetPolyList: [], areaPolyList: [] };
	
	// Step 1. Vertices
	// Input: List of points forming a single, closed, non-intersecting polygon
	
	// Step 2. Edges (Lines)
	// Create a list of line segments, where each segment is one edges of the polygon
	var edges = [];
	for (var i = 0; i < vertices.length; i++)
	{
		var start = i;
		var end   = (i + 1) % vertices.length;
		var s = {idxA:start, idxB:end, ptA:vertices[start], ptB:vertices[end]};
		edges.push(s);
	}
	
	// Step 3. Parallel Edges (Lines)
	// Calculate a list of lines parallel to the edges. The intersections
	// of consecutive parallel lines will yield the offset polygon
	var parallels = [];
	for (var i = 0; i < edges.length; i++)
	{
		// Endpoints for the original line
		let ptA = vertices[edges[i].idxA];
		let ptB = vertices[edges[i].idxB];
		
		// Unit vector
		var unitAB = MathUtil.CalcUnitVector(ptA, ptB);
		
		// Offset for the parallel line
		var offset = (ptA["offset"] == undefined) ? offsetDistance : ptA["offset"];

		// Calculate points for parallel line
		let parallelPtA = {x: ptA.x + offset * unitAB.y, y: ptA.y - offset * unitAB.x };
		let parallelPtB = {x: ptB.x + offset * unitAB.y, y: ptB.y - offset * unitAB.x };

		parallels.push({ptA:parallelPtA, ptB:parallelPtB});
	}		
		
	// Step 4: Rho Array
	// Create a rho array to track the active parallel lines. Initially all parallel
	// lines are used to calculate offset vertices
	var rho = [];
	var rhoRemoved = [];
	for (var i = 0; i < parallels.length; i++)
		rho.push({idx:i});
	
	
	// Flags to indicate completion and results
	var done = false;
	var returnOffsetPolygon = false;
	var passCount = 0;
	var maxPasses = vertices.length;
	
	var offsetVertices = [];
	var offsetPoints = [];
	var simplified = [];
	
	while (!done && passCount < maxPasses)
	{
		console.log("Pass count", passCount + 1);
		
		// For the second and subsequent pass, compute the vertices of the
		// simplified polygon
		if (passCount == 0)
		{
			for (var i = 0; i < vertices.length; i++)
				simplified[i] = vertices[i];
		}
		else
		{
			simplified = [];
			for (var i = 0; i < rho.length; i++)
			{
				let thisIdx = rho[i].idx;
				let nextIdx = rho[(i + 1) % rho.length].idx
				let vtx = PolygonList_CalcIntersection(edges[thisIdx], edges[nextIdx]);
				let vtxIdx = (thisIdx + 1) % vertices.length;
				simplified[vtxIdx] = vtx;
			}
		}
		// Step 5: Offset Points and Offset Vertices
		// Calculate intersections of parallel lines and keep two copies
		// NOTE!! The index to store the computed vertex should refer ... (explain more)
		offsetPoints = [];
		for (var i = 0; i < rho.length; i++)
		{
			let lineIdxA = rho[i].idx;
			let lineIdxB = rho[(i + 1) % rho.length].idx;
			let vtx = PolygonList_CalcIntersection(parallels[lineIdxA], parallels[lineIdxB]);
			// This could be undefined if the two parallel points point in opposite directions.
			// Undefined points will be converted according to the end cap.
			let vtxIdx = (lineIdxA + 1) % vertices.length;
			offsetPoints[vtxIdx] = vtx;
			
			// Keep a copy of the original offset vertices
			if (passCount == 0)
				offsetVertices[vtxIdx] = vtx;
		}
	
		// Step 6: 
		// Calculate possible intersections of segments from input vertex to offset vertex
		var crossovers = [];
		for (var i = 0; i < rho.length; i++)
		{
			let edgeIdx = rho[i].idx;
			let ptIdxA = edges[edgeIdx].idxA;
			let ptIdxB = edges[edgeIdx].idxB;
			
			if (offsetPoints[ptIdxA] != undefined && offsetPoints[ptIdxB] != undefined)
			{
				let vtxA = simplified[ptIdxA];
				let offA = offsetPoints[ptIdxA];
				let vtxB = simplified[ptIdxB];
				let offB = offsetPoints[ptIdxB];
				let results = MathUtil.CalcSegmentIntersection(vtxA, offA, vtxB, offB, false);
			
				if (results != undefined)
				{
					crossovers.push({rhoIdx:i, pt:results.ptIntersection, c1:results.c1, c2:results.c2});
					console.log("  rhoIdx", i, results.c1.toFixed(2), results.c2.toFixed(2));
				}
			}
		}
	
		// Step 7: Examine Crossovers
		//
	
		// No crossovers? Then an offset polygon has been found
		if (crossovers.length == 0)
		{
			done = true;
			returnOffsetPolygon = true;
		}
		// Three edges processed and any crossovers found? 
		// Then no offset polygon can be found
		else if (rho.length <= 3)
		{
			done = true;
			returnOffsetPolygon = false;
		}
		// Otherwise, remove the "most crossed-over" edge and try again
		else	
		{
			var crossDistance = 1.0;
			var crossIdx = undefined;
			
			// Find the minimum c1 value (which represents the furthest
			// crossover distance).
			// Note: This needs to be done for each run of crossovers
			for (var i = 0; i < crossovers.length; i++)
			{
				if (crossIdx == undefined || crossovers[i].c1 < crossDistance)
				{
					crossIdx = i;
					crossDistance = crossovers[i].c1;
				}
			}
			
			let rhoIdx = crossovers[crossIdx].rhoIdx;
			let removed = rho.splice(rhoIdx, 1);
			rhoRemoved.push(removed);
			console.log("    removed", rhoIdx);
		}
		
		console.log("  rho length", rho.length);
		passCount++;
	} // while (!done || passCount < maxPasses)
	
	if (returnOffsetPolygon)
	{
		let poly = [];
		for (var i = 0; i < offsetPoints.length; i++)
		{
			if (offsetPoints[i] != undefined)
				poly.push(offsetPoints[i]);
		}

		let simplifiedPoly = [];
		for (var i = 0; i < simplified.length; i++)
		{
			if (simplified[i] != undefined)
				simplifiedPoly.push(simplified[i]);
		}
	
		single.offsetPoly = poly;
		single.simplifiedPoly = simplifiedPoly;
	}
	return single;
		
}

PolygonList.prototype.CalcOffsetPolygon_v5_Single_v2 = function(vertices, offsetDistance, options)
{
	var single = { insetPolyList: [], areaPolyList: [] };
	var debugLog = (options != undefined && options.debugLog != undefined && options.debugLog);
	var buildAreaPolygons = (options != undefined && options.buildAreaPolygons != undefined && options.buildAreaPolygons);
	var endCapAreaPolgons = [];

	// Determine the endcap style
	var endCapStyle = PolygonMiterType.BUTT;
	if (options != undefined && options.endCapStyle != undefined)
		endCapStyle = options.endCapStyle;
	
	// Step 1. Vertices
	// Input: List of points forming a single, closed, non-intersecting polygon
	if (debugLog) console.log ("  Vertices: " + vertices.length);
	
	// Step 2. Edges (Lines) and a copy
	// Create a list of line segments, where each segment is one edges of the polygon.
	// Edges will be removed (from the 'edges' list) if they are not used in the offset
	// polygon. The originalEdges array will contain calculated points for removed edges.
	// Note that idxA is an refers to the vertex in the vertices input array
	var edges = [];
	var originalEdges = [];
	for (var i = 0; i < vertices.length; i++)
	{
		var start = i;
		var end   = (i + 1) % vertices.length;
		var s = {idxA:start, idxB:end, ptA:vertices[start], ptB:vertices[end]};
		edges.push(s);
		originalEdges.push(s);
	}
	
	// Flags to indicate completion and results
	var done = false;
	var returnOffsetPolygon = false;
	var passCount = 0;
	var maxPasses = vertices.length;
	
	var offsetVertices = [];
	var offsetPoints = [];
	var simplified = [];
	var edgesRemoved = [];
	
	while (!done && passCount < maxPasses)
	{
		if (debugLog) console.log("  Pass count", passCount + 1);

		// Are there any pairs of disconnected edges that are nearly parallel or are
		// pointing away from each other?
		if (debugLog) console.log("    Edge intersection analysis");
		var reviewed = false;
		var idx = 0;
		let maxC = 10; // Line length multiplier
		var simplified = []; // Simplified polygon
		let epsilon = 0.00001
		do {
			let eA = edges[idx];
			let eB = edges[(idx + 1) % edges.length];

			// Is the end of one segment the start of the next?
			if (Math.abs(eA.ptB.x - eB.ptA.x) < epsilon && Math.abs(eA.ptB.y - eB.ptA.y) < epsilon)
			{
				simplified.push(eA.ptB);
				
				// Advance the index
				idx++;
			}
			// Otherwise, determine where the edges would intersect.
			else
			{
				// Note that the points of the second edge are reversed. This is so we
				// can compare c1 and c2 the same way (ie, lower c means closers to ptA)
				let si = MathUtil.CalcSegmentIntersection(eA.ptA, eA.ptB, eB.ptB, eB.ptA, true);
				
				// c1 and c2 should be greater than zero, but not enormous
				if (!si.segmentsParallel && si.c1 > 0.0 && si.c2 > 0.0 && si.c1 < maxC && si.c2 < maxC)
				{
					simplified.push(si.ptIntersection);

					idx++;
					
					if (debugLog) console.log("      " + idx + ": edges intersect, c1:" + si.c1.toFixed(2) + ", c2:" + si.c2.toFixed(2));
				}
				else
				{
					// Determine which edge to remove. The attempt is to identify which edge is still
					// needed by the polygon. We keep the one that is "further back". We do this by comparing
					// the the start of one to the end of the other (remember that they are ...)
					// Vectors for two edges; note that the second vector points in the opposite direction of the edge
					let vA = {x:eA.ptB.x - eA.ptA.x, y:eA.ptB.y - eA.ptA.y};
					//let vB = {x:eB.ptA.x - eB.ptB.x, y:eB.ptA.y - eB.ptB.y};
					
					// Vector offset between the start of the first edge and the end of the second
					let vOffset = {x:eB.ptB.x - eA.ptA.x, y:eB.ptB.y - eA.ptA.y};
					
					// Dot product. Positive value mean vectors point in same direction (angle < 90)
					let dotUnscaled = vA.x * vOffset.x + vA.y * vOffset.y;

					// Determine which edge to remove
					let removeFirst = (dotUnscaled < 0);
					let removeIdx = removeFirst ? idx : ((idx + 1) % edges.length);
					
					if (debugLog) console.log("      " + idx + ": removed " + removeIdx + " of", edges.length, si.c1.toFixed(2), si.c2.toFixed(2), si.segmentsParallel?"parallel":"");
					
					// Remove and place in 'removed' list
					let removed = edges.splice(removeIdx, 1);
					edgesRemoved = edgesRemoved.concat(removed);
					
					// If we removed the edge at idx, then backup one so we can compare the previous
					// edge to the edge we did not remove
					if (removeIdx == idx && idx > 0)
						idx--;
				}
			}
		
			if (edges.length < 3)
			{
				reviewed = true;
				done = true;
				returnOffsetPolygon = false;
			}
			else if (idx == edges.length)
			{
				reviewed = true;
				
				// Rotate the simplified polygon since the first point we examined was 
				// actually the second point in the polygon
				let last = simplified.pop();
				simplified.unshift(last);
			}
		} while (!reviewed);
		
		if (debugLog) console.log("      edges length after analysis: ", edges.length);

		// Step 3. Parallel Edges (Lines)
		// Calculate a list of lines parallel to the edges. The intersections
		// of consecutive parallel lines will yield the offset polygon
		if (!done)
		{
			var parallels = [];
			for (var i = 0; i < edges.length; i++)
			{
				// Endpoints for the original line
				let ptA = edges[i].ptA;
				let ptB = edges[i].ptB;
		
				// Unit vector
				var unitAB = MathUtil.CalcUnitVector(ptA, ptB);
		
				// Offset for the parallel line
				var offset = (ptA["offset"] == undefined) ? offsetDistance : ptA["offset"];

				// Calculate points for parallel line
				let parallelPtA = {x: ptA.x + offset * unitAB.y, y: ptA.y - offset * unitAB.x };
				let parallelPtB = {x: ptB.x + offset * unitAB.y, y: ptB.y - offset * unitAB.x };

				parallels.push({ptA:parallelPtA, ptB:parallelPtB});
			}		
		}
		
		// Step 5: Offset Points
		// Calculate intersections of parallel lines
		if (!done)
		{
			if (debugLog) console.log("    Offset polygon");
			offsetPoints = [];
			for (var thisIdx = 0; thisIdx < edges.length; thisIdx++)
			{
				let nextIdx = (thisIdx + 1) % edges.length;
				
				let vtx = PolygonList_CalcIntersection(parallels[thisIdx], parallels[nextIdx], debugLog);
				let simplifiedPtIdx = nextIdx;
			
				// This could be undefined if the two parallel points point in opposite directions.
				// Undefined points will be converted according to the end cap.
				if (vtx != undefined)
				{
					vtx.simplifiedPtIdx = simplifiedPtIdx;
					offsetPoints.push(vtx)

					// Store the offset with the originalEdge to construct to the area polygons
					if (buildAreaPolygons)
					{
						let eIdxB = edges[thisIdx].idxA;
						originalEdges[eIdxB].offsetPtB = vtx;

						let eIdxA = edges[nextIdx].idxA;
						originalEdges[eIdxA].offsetPtA = vtx;
					}
				}
				else
				{
					// Calculate end cap
					// Offset for the parallel line
					var offsetAB = (edges[thisIdx].ptA["offset"] == undefined) ? offsetDistance : edges[thisIdx].ptA["offset"];
					var offsetCD = (edges[nextIdx].ptA["offset"] == undefined) ? offsetDistance : edges[nextIdx].ptA["offset"];

					// Get the color either from the pt.color or pt.tag.color. Returns undefined if no color provided
					let color = buildAreaPolygons ? this.FindVertexColor(vertices, edges[thisIdx].idxA) : undefined;

					// Note that we expect that the parallel lines 
					var endCapVtxList = PolygonList_CalcMiterPointList(edges[thisIdx].ptA, edges[thisIdx].ptB, endCapStyle, offsetAB, offsetCD);

					if (endCapVtxList.length > 0)
					{
						for (var j = 0; j < endCapVtxList.length; j++)
						{
							let vtx = endCapVtxList[j];
							
							if (j == endCapVtxList.length - 1)
							{
								vtx.simplifiedPtIdx = simplifiedPtIdx;
							}
							else
							{
								vtx.endCapPtIdx = simplifiedPtIdx;
							}
								
							vtx.tag = edges[thisIdx].ptB.tag; // or should it be lineCD.ptA?;
							
							offsetPoints.push(vtx);
							
							// Build the area polygons for endcaps here since we have all of the points
							// readily available
							if (buildAreaPolygons)
							{
								if (j > 0)
								{
									endCapAreaPolgons.push({poly:[vtx, edges[thisIdx].ptB, endCapVtxList[j-1]], color:color})
								}
								
								if (j == 0)
								{
									let eIdxB = edges[thisIdx].idxA;
									originalEdges[eIdxB].offsetPtB = vtx;
								}
								
								if (j == endCapVtxList.length - 1)
								{
									let eIdxA = edges[nextIdx].idxA;
									originalEdges[eIdxA].offsetPtA = vtx;
								}
							}
						}
					}
					else
					{
						console.log("      PolygonList_CalcMiterPointList returned zero length");
						offsetPoly.push(lineAB.ptB);
					}
				}			
			
			}

			// Rotate the array of the simplified polygon since the first point we 
			// calculated was actually the second point in the polygon
			let last = offsetPoints.pop();
			offsetPoints.unshift(last);
		}
		
		// Step 6: 
		// Calculate possible intersections of segments from input vertex to offset vertex
		if (!done)
		{
			if (debugLog) console.log("    Crossover analysis...compute");
			var crossovers = [];
			for (var i = 0; i < offsetPoints.length; i++)
			{
				let offsetIdxA = i;
				let offsetIdxB = (i + 1) % offsetPoints.length;
			
				if (offsetPoints[offsetIdxA].simplifiedPtIdx != undefined && offsetPoints[offsetIdxB].simplifiedPtIdx != undefined)
				{
					let simplifiedIdxA = offsetPoints[offsetIdxA].simplifiedPtIdx;
					let simplifiedIdxB = offsetPoints[offsetIdxB].simplifiedPtIdx;
					
					let vtxA = simplified[simplifiedIdxA];
					let offA = offsetPoints[offsetIdxA];
					let vtxB = simplified[simplifiedIdxB];
					let offB = offsetPoints[offsetIdxB];
					
					function L(i, v) { return i + (v ? "" : " [undefined]"); }
					
					if (debugLog == 2) console.log("      crossover: indices  " +  L(simplifiedIdxA, vtxA) + "->" + L(offsetIdxA, offA) + ", " + L(simplifiedIdxB, vtxB) + "->" + L(offsetIdxB, offB));
					
					let results = MathUtil.CalcSegmentIntersection(vtxA, offA, vtxB, offB, false);
			
					if (results != undefined)
					{
						crossovers.push({edgeIdx:simplifiedIdxA, pt:results.ptIntersection, c1:results.c1, c2:results.c2});
						if (debugLog) console.log("      crossover: edgeIdx", i, results.c1.toFixed(2), results.c2.toFixed(2));
					}
				}
				else
				{
					if (debugLog == 2) console.log("      crossover: edgeIdx", i, "miter point");
				}
			}
		
			// Step 7: Examine Crossovers
			//
			if (debugLog) console.log("    ...review");
	
			// No crossovers? Then an offset polygon has been found
			if (crossovers.length == 0)
			{
				done = true;
				returnOffsetPolygon = true;
				if (debugLog) console.log("    offset polygon found");
			}
			// Three edges processed and any crossovers found? 
			// Then no offset polygon can be found
			else if (edges.length <= 3)
			{
				done = true;
				returnOffsetPolygon = false;
				if (debugLog) console.log("    not enough edges; no offset found");
			}
			// Otherwise, remove the "most crossed-over" edge and try again
			else	
			{
				var crossDistance = 1.0;
				var crossIdx = undefined;
			
				// Find the minimum c1 value (which represents the furthest
				// crossover distance).
				// Note: This needs to be done for each run of crossovers
				for (var i = 0; i < crossovers.length; i++)
				{
					if (crossIdx == undefined || crossovers[i].c1 < crossDistance)
					{
						crossIdx = i;
						crossDistance = crossovers[i].c1;
					}
				}
			
				if (crossIdx == undefined || crossIdx < 0 || crossIdx >= crossovers.length)
				{
					if (debugLog) console.log("    crossIdx (" + crossIdx + ") is out of range (" + crossovers.length + ")");
				}
				
				// Get the index of the edge to remove
				let edgeIdx = crossovers[crossIdx].edgeIdx;
			
				if (edgeIdx == undefined || edgeIdx < 0 || edgeIdx >= edges.length)
				{
					let len = edges.length;
					if (debugLog) console.log("    edgeIdx (" + edgeIdx + ") is out of range (" + len + ")");
				}

				// Remove the edge the array, get it out of the returned array, and store it
				let removed = edges.splice(edgeIdx, 1);
				let removedEdge = removed[0]; // Check for length??
				edgesRemoved.push(removedEdge);
			
				// Update the original edge with the crossover point. This will be used for
				// creating color polygons, if requested
				let originalEdgeIdx = removedEdge.idxA;
				originalEdges[originalEdgeIdx].crossoverPt = crossovers[crossIdx].pt;
			
				if (debugLog) console.log("      crossover removed: " + edgeIdx);
			
				if (edges.length < 3)
				{
					done = true;
					returnOffsetPolygon = false;
				}
			}
		}
		
		
		passCount++;
	} // while (!done || passCount < maxPasses)
	
	if (returnOffsetPolygon)
	{
		if (debugLog) console.log("  Returning polygon, vertex count: " + offsetPoints.length);
		
		let poly = [];
		for (var i = 0; i < offsetPoints.length; i++)
		{
			if (offsetPoints[i] != undefined)
			{
				poly.push(offsetPoints[i]);
			}
			else
			{
				if (debugLog) console.log("    Undefined vertex found in offsetPoints at " + i);
			}
		}

		let simplifiedPoly = [];
		for (var i = 0; i < simplified.length; i++)
		{
			if (simplified[i] != undefined)
				simplifiedPoly.push(simplified[i]);
		}
	
		single.offsetPoly = poly;
		single.simplifiedPoly = simplifiedPoly;
		single.edgesRemoved = edgesRemoved;
	}
	else
	{
		if (debugLog) console.log("  No offset polygon found");
	}
	
	if (buildAreaPolygons)
	{
		var areaPolygonList = [];
		let cp = pt => { return ((pt != undefined) ? {x:pt.x, y:pt.y} : undefined) };

		var color;
		for (var i = 0; i < originalEdges.length; i++)
		{
			var areaPoly = [];
			var e = originalEdges[i];
			
			// Get the color either from the pt.color or pt.tag.color
			color = this.FindVertexColor(vertices, e.idxA);

			if (e.crossoverPt != undefined)
			{
				let ptA = cp(e.ptA);
				let ptB = cp(e.ptB);
				let ptC = cp(e.crossoverPt);

				if (ptA && ptB && ptC)
					areaPoly.push(ptA, ptB, ptC);
			}
			else
			{
				let ptA = cp(e.ptA);
				let ptB = cp(e.ptB);
				let ptC = cp(e.offsetPtB);
				let ptD = cp(e.offsetPtA);

				if (ptA && ptB && ptC && ptD)
					areaPoly.push(ptA, ptB, ptC, ptD);
			}
			
			if (areaPoly.length > 0)
				areaPolygonList.push({poly:areaPoly, color:color});
		}
		
		// Area polygons for the endcaps were created when the endcaps were calculated
		if (endCapAreaPolgons.length > 0)
			areaPolygonList = areaPolygonList.concat(endCapAreaPolgons);
			
		single.areaPolygonList = areaPolygonList;
	}
			
	return single;
		
}

PolygonList.prototype.CalcOffsetPolygon_v5 = function(offsetDistance, options)
{
	var inputPolygonList = this;
	var outputPolygonList = new PolygonList();
	var debugLog = (options != undefined && options.debugLog != undefined && options.debugLog);
	
	if (debugLog)
		console.log("CalcOffsetPolygon_v5, offsetDistance: " + offsetDistance + " (" + typeof offsetDistance + ")");
	
	// Determine the endcap style
	var endCapStyle = PolygonMiterType.ROUND;
	if (options != undefined && options.endCapStyle != undefined)
		endCapStyle = options.endCapStyle;

	// Calc the offset polygon for each of the polygons in the list
	for (var p = 0; p < inputPolygonList.GetPolygonCount(); p++)
	{
		var ip = inputPolygonList.GetPolygonPoints(p);
		let single = this.CalcOffsetPolygon_v5_Single_v2(ip, offsetDistance, options);
		
		if (single.offsetPoly != undefined)
			outputPolygonList.AddPolygonPoints(single.offsetPoly);

		if (/*options != undefined && options.returnSimplifiedPolygon && */ single.simplifiedPoly != undefined)
			outputPolygonList.AddPolygonPoints(single.simplifiedPoly, {tag:"simplified"});
			
		if (options != undefined && options.returnRemovedEdges && single.edgesRemoved != undefined)
			outputPolygonList.edgesRemoved = single.edgesRemoved;
			
		if (single.areaPolygonList != undefined)
		{
			for (var i = 0; i < single.areaPolygonList.length; i++)
			{
				let ap = single.areaPolygonList[i];
				// 2021.04.12: Replace "area" with "isFill"
				outputPolygonList.AddPolygonPoints(ap.poly , {isFill:true, /*tag:"area",*/ color:ap.color});
			}
		}
		
	} // for-loop-inputpolygonlist

	return outputPolygonList;
}

//----------------------------------------------------------------------------------------------
//	Get Property
//		returns a property from an object if the object and the property exists
//		otherwise returns the alternative value
//----------------------------------------------------------------------------------------------
function GetProperty(obj, prop, alternative)
{
	let result = (obj != undefined && obj[prop] != undefined) ? obj[prop] : alternative;

	return result;
}

//----------------------------------------------------------------------------------------------
//	Generate Vertex List
//----------------------------------------------------------------------------------------------
/*
PolygonList.prototype.GenerateVertexList = function(polygon, defautlOffset)
{
	//	Vertex data
	//		pt: {x, y}
	//		offset
	//		prev
	//		next
	//		active
	
	var vertices = [];
	let len = polygon.length;
	
	for (var i = 0; i < len; i++)
	{
		let p = polygon[i];
		let pt = {x:p.x, y:p.y};
		let offset = (p.offset != undefined) ? p.offset : defautlOffset;
		let prev = (i + len - 1) % len;
		let next = (i + 1) % len;
		
		let vtx = {pt, offset, prev, next, active:true};
		
		vertices.push(vtx);
	}
	
	return vertices;
}
*/

//----------------------------------------------------------------------------------------------
//	Generate Edge List
//	2022.01.26: Added z values to points to support lattices
//	2022.03.03: Added options
//----------------------------------------------------------------------------------------------
PolygonList.prototype.GenerateEdgeList = function(polygon, defaultOffset, options)
{
	//	Edge data
	//		ptA: {x, y}
	//		ptB: {x, y}
	//		offset
	//		prev
	//		next
	//		active
	
	var edges = [];
	let len = polygon.length;
	let adjustWidth = (options != undefined && (options.adjustWeight != undefined || options.adjustOffset != undefined));
	let adjustWeight = GetProperty(options, "adjustWeight", 0.0);
	let adjustOffset = GetProperty(options, "adjustOffset", 0.0);
	
	for (var thisIdx = 0; thisIdx < len; thisIdx++)
	{
		let nextIdx = (thisIdx + 1) % len;
		let prevIdx = (thisIdx + len - 1) % len;
		
		// Vertex A
		let pA = polygon[thisIdx];
		let ptA = {x:pA.x, y:pA.y};
		if (pA.color != undefined)
			ptA.color = pA.color;
		if (pA.tag != undefined)
			ptA.tag = pA.tag;
		let offset = (pA.offset != undefined) ? pA.offset : defaultOffset;

		// 2022.03.03: Support adjusting the width
		if (adjustWidth)
		{
			let adjusted = offset * adjustWeight + adjustOffset;
			if (adjusted < 0)
				offset = 0;
			else if (adjusted <= offset)
				offset = adjusted;
		}
		// When a polygon is created from a segment list and the segment list has z-values
		// for the beginning and end of the segments, then two z-values need to be stored
		// for each vertex: "ptAz" is the z-value for the edge leading 'away from' this point
		// and "ptBz" is the z-value for the edge leading 'to' this point
		if (pA.ptAz != undefined)
			ptA.z = pA.ptAz;
		// 2022.02.09: Node index; used to coalesce lattice edges
		ptA.nodeIdx = pA.nodeIdx;
		// 2022.05.16: Propagate clip polygon edge index
		if (pA.clipPolyEdgeIdx != undefined)
			ptA.clipPolyEdgeIdx = pA.clipPolyEdgeIdx;

		// Vertex B
		let pB = polygon[nextIdx];
		let ptB = {x:pB.x, y:pB.y, z:pB.z, tag:pB.tag};
		if (pB.color != undefined)
			ptB.color = pB.color;
		if (pB.tag != undefined)
			ptB.tag = pB.tag;
		if (pB.ptBz != undefined)
			ptB.z = pB.ptBz;
		// 2022.02.09: Node index; used to coalesce lattice edges
		ptB.nodeIdx = pB.nodeIdx;
		
		// Edge
		let edge = {ptA, ptB, weight:offset, prev:prevIdx, next:nextIdx, active:true};
		edge.areaPolyPrevPts = [];
		edge.areaPolyNextPts = [];
		edges.push(edge);
	}
	
	//console.log("Edges\n", JSON.stringify(edges, 0, 2));
	return edges;
}

//----------------------------------------------------------------------------------------------
//	ang: convert a radian angle to a degree angle with two decimal points (for printing)
//----------------------------------------------------------------------------------------------
function ang(a) { return (a * 180.0/Math.PI).toFixed(2) };

//----------------------------------------------------------------------------------------------
//	Calc Bisector
//----------------------------------------------------------------------------------------------
function CalcBisector(edgeA, edgeB, specifiedStartPt = undefined)
{
	let epsilon = 0.000001;
	let wA = edgeA.weight;
	let wB = edgeB.weight;
	//let wSum = wA + wB;
	//let endcapThreshold = Math.PI - epsilon;
	var offsetVtx = undefined;
	
	let endPt = undefined;
	let bisectorStartPt = undefined;
	var unit = undefined;
	
	// Are we working with adjacent edges (in the original polygon), or
	// more distant edges (after an edge was removed)?
	if (MathUtil.EqualPt2(edgeA.ptB, edgeB.ptA))
	{
		// Set the bisectorStartPt to the common point
		bisectorStartPt = edgeA.ptB;

		offsetVtx = PolygonList_CalcOffsetIntersection(edgeA, edgeB, wA, wB);
	
		if (offsetVtx != undefined)
		{
			endPt = offsetVtx;
		
			unit = MathUtil.CalcUnitVector(bisectorStartPt, offsetVtx);
		}
		else
		{
		/*
					var miterVtxList = PolygonList_CalcMiterPointList(lineAB.ptA, lineAB.ptB, endCapStyle, offsetAB, offsetCD);
					if (miterVtxList.length > 0)
					{
						for (var i = 0; i < miterVtxList.length; i++)
						{
							miterVtxList[i].tag = lineAB.ptB.tag; // or should it be lineCD.ptA?;
							offsetPoly.push(miterVtxList[i]);
						}
					}
					else
					{
						console.log("PolygonList_CalcMiterPointList returned zero length");
						offsetPoly.push(lineAB.ptB);
					}
		*/
		}
	}
	else
	{
		bisectorStartPt = specifiedStartPt;
		
		offsetVtx = PolygonList_CalcOffsetIntersection(edgeA, edgeB, wA, wB);
	
		if (offsetVtx != undefined)
		{
			endPt = offsetVtx;
		
			unit = MathUtil.CalcUnitVector(bisectorStartPt, offsetVtx);

			// Calculate the angle of each edge
			let angleEdgeA = MathUtil.CalcAngle(edgeA.ptA, edgeA.ptB);
			let angleEdgeB = MathUtil.CalcAngle(edgeB.ptA, edgeB.ptB);

			// The difference is the "outside angle" of the corner
			let angle = angleEdgeB - angleEdgeA;

			// Keep the angle in range from -Pi to Pi
			if (angle > Math.PI)
				angle -= 2 * Math.PI;
			else if (angle < -Math.PI)
				angle += 2 * Math.PI;

			// The angle inside the vertex
			let angleVertex = Math.PI - angle;
	
			// This is incorrect:
			// -- Calculate the bisector angle using the weights for each edge.
			// -- If the weights are equal, then this becomes 90 deg + angle/2
			// -- let aB2 = wA * aV2/ wSum;
			// -- let aB = aVa + (Math.PI - aB2)
			//console.log("angleEdgeA", ang(aVa), "angleEdgeB", ang(aVb), "  ==> angleV", ang(aV), "  ==> angleBis", ang(aB));


			// If the edges do not share a vertex and if the edges are pointing 
			// "away" from each other, then reverse the direction of the bisector.
			// This would not be necessary if we were calculating the self-intersections 
			// (using "edge events" as per the straight skeleton algorithms)
			if (angleVertex > 0 && angleVertex < Math.PI)
			{
				unit.x = -unit.x;
				unit.y = -unit.y;
			
				endPt = undefined;
			}
		}
		else
		{
			unit = MathUtil.CalcUnitVector(edgeA.ptA, edgeA.ptB);
		}
	}
	
	let bisector = {bisectorStartPt, endPt, dir:unit};
	
	return bisector;	
}

//----------------------------------------------------------------------------------------------
//	Calc Bisectors
//
//	Calculate the bisector for two edges
//	If the two edges are pointing in opposite directors, then calculate the bisectors for 
//	an endcap
//----------------------------------------------------------------------------------------------
function CalcBisectors(edgeA, edgeB, options, specifiedStartPt = undefined)
{
	let epsilon = 0.000001;
	let wA = edgeA.weight;
	let wB = edgeB.weight;
	//let wSum = wA + wB;
	//let endcapThreshold = Math.PI - epsilon;
	var offsetVtx = undefined;
	
	let endPt = undefined;
	let bisectorStartPt = undefined;
	var unit = undefined;
	
	var bisectorList = [];
	
	// Are we working with adjacent edges (in the original polygon), or
	// more distant edges (after an edge was removed)?
	if (MathUtil.EqualPt2(edgeA.ptB, edgeB.ptA, epsilon))
	{
		// Set the bisectorStartPt to the common point
		bisectorStartPt = edgeA.ptB;

		offsetVtx = PolygonList_CalcOffsetIntersection(edgeA, edgeB, wA, wB);
	
		if (offsetVtx != undefined)
		{
			endPt = offsetVtx;
			endPt.tag = edgeB.ptA.tag;
			unit = MathUtil.CalcUnitVector(bisectorStartPt, offsetVtx);

			let bisector = {bisectorStartPt, endPt, dir:unit, bitype:"edge"};
			bisectorList.push(bisector);
		}
		else
		{
			var endCapStyle = (options.endCapStyle != undefined) ? options.endCapStyle : PolygonMiterType.BUTT;
			var endcapVtxList = PolygonList_CalcMiterPointList(edgeA.ptA, edgeA.ptB, endCapStyle, wA, wB);
			if (endcapVtxList.length > 0)
			{
				for (var i = 0; i < endcapVtxList.length; i++)
				{
					endPt = endcapVtxList[i];
					endPt.tag = edgeA.ptB.tag;
					unit = MathUtil.CalcUnitVector(bisectorStartPt, endPt);
					let bisector = {bisectorStartPt, endPt, dir:unit, bitype:"endcap"};
					bisectorList.push(bisector);
				}
			}
			else
			{
				console.log("PolygonList_CalcMiterPointList returned zero length");
				endPt = edgeA.ptB;
				unit = {x:0, y:0};
				let bisector = {bisectorStartPt, endPt, dir:unit, bitype:"edge"};
				bisectorList.push(bisector);
			}

			// 2022.02.07: Indicate that the edges generated a endcap. We use this in a specific
			// case for the area polygons to determine how to handle the curve
			edgeA.endCapPtB = true;
			edgeB.endCapPtA = true;
		}
	}
	else
	{
		// The two edges do not share a common point.
		
		// Use the specified start point as the bisector start point
		bisectorStartPt = specifiedStartPt;
		
		// Calculate the offset vertex
		offsetVtx = PolygonList_CalcOffsetIntersection(edgeA, edgeB, wA, wB);
	
		if (offsetVtx != undefined)
		{
			endPt = offsetVtx;

			// 2021.09.15: Add the tag
			endPt.tag = specifiedStartPt.tag;
		
			unit = MathUtil.CalcUnitVector(bisectorStartPt, offsetVtx);

			// Because we are calculating the bisector by finding the intersection of
			// lines parallel to to edges, it is possible to find a bisector that points
			// in the opposite direction than we expect. This happens when the edges
			// do not share a vertex are pointing "away" from each other. If this happens we 
			// have to reverse the direction of the bisector (and clear the end point).
			// We can detect this case by adding the two angles between the two edges and
			// a third "edge" that would exist by adding a line from the end of the first
			// edge to the beginning of the second edge. If the sum of these angle are less
			// than 180 degrees, then these edge meet this condition. 
			// (Something in how I calculate angles gives me results where this works when
			// the sum is more than 180 degrees.)

			//const deg = r => (r * 180.0 / Math.PI).toFixed(1); // For printing angles in degrees
			
			let angleA = MathUtil.CalcVertexAngle(edgeA.ptA, edgeA.ptB, edgeB.ptA);
			let angleB = MathUtil.CalcVertexAngle(edgeA.ptB, edgeB.ptA, edgeB.ptB);
			let checkAngle = angleA + angleB;

			if (checkAngle > Math.PI || checkAngle < -Math.PI)
			{
				// If the bisectorStartPt and the offsetVtx are the same point (because
				// the offset values are zero), then the unit vector will be undefined
				if (unit != undefined)
				{
					unit.x = -unit.x;
					unit.y = -unit.y;
				}
				
				endPt = undefined;
			}
		}
		else
		{
			// The offset vertex was undefined. This means (or should mean) that
			// the edges are parallel. Use the direction of edgeA as the
			// bisector direction.
			unit = MathUtil.CalcUnitVector(edgeA.ptB, edgeA.ptA);

			// Note that we do not specify an end point for this bisector.			
		}

		let bisector = {bisectorStartPt, endPt, dir:unit, bitype:"edge"};
		bisectorList.push(bisector);
	}

	return bisectorList;	
}

//----------------------------------------------------------------------------------------------
//	Calc And Insert Bisector
//		Calculated the bisector between two edges and inserts it into the ordered bisector list
//
//	Bisector:
//		pt: {x, y}
//		dir: {x, y} (unit vector)
//		edgeIdx (index into vertices)
//		reflex (bool)
//		type (edge, endcap, miter)
//		startPt: {x, y}
//		endPt: {x, y}
//
//----------------------------------------------------------------------------------------------
/*
PolygonList.prototype.CalcAndInsertBisector = function(edges, edgeIdx, bisectors, specifiedStartPt = undefined)
{
	let thisEdge = edges[edgeIdx];
	let prevEdge = edges[thisEdge.prev];
	var bisectorIdx;
	
	let b = CalcBisector(prevEdge, thisEdge, specifiedStartPt);
	
	b.edgeIdx = edgeIdx;
	b.id = bisectors.nextId;
	
	bisectors.nextId++;
	
	// The bisectors need to be ordered to match the order of the edges
	// so the offset polygon can be correctly constructed
	let insertIdx = bisectors.active.findIndex(ba => edgeIdx < ba.edgeIdx);
	
	if (insertIdx == -1)
	{
		bisectorIdx = bisectors.active.length;
		bisectors.active.push(b);
	}
	else
	{
		bisectorIdx = insertIdx
		bisectors.active.splice(insertIdx, 0, b);
	}
	
	return bisectorIdx;
}
*/

//----------------------------------------------------------------------------------------------
//	Calc and Insert Bisectors
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CalcAndInsertBisectors = function(edges, edgeIdx, bisectors, specifiedStartPt = undefined)
{
	let thisEdge = edges[edgeIdx];
	let prevEdge = edges[thisEdge.prev];
	var bisectorIdx;
	
	let bList = CalcBisectors(prevEdge, thisEdge, bisectors.options, specifiedStartPt);
	
	for (var i = 0; i < bList.length; i++)
	{
		bList[i].edgeIdx = edgeIdx;
		bList[i].id = bisectors.nextId;
	
		bisectors.nextId++;
	}
		
	// The bisectors need to be ordered to match the order of the edges
	// so the offset polygon can be correctly constructed
	let insertIdx = bisectors.active.findIndex(ba => edgeIdx < ba.edgeIdx);
	
	if (insertIdx == -1)
	{
		bisectorIdx = bisectors.active.length;
		for (var i = 0; i < bList.length; i++)
			bisectors.active.push(bList[i]);
	}
	else
	{
		bisectorIdx = insertIdx;
		
		for (var i = 0; i < bList.length; i++)
			bisectors.active.splice(insertIdx + i, 0, bList[i]);
	}
	
	return bisectorIdx;
}

//----------------------------------------------------------------------------------------------
//	Generate Bisector List
//
//	Returns bisector list:
//		active:[]
//		processed:[] (empty)
//		nextId
//----------------------------------------------------------------------------------------------
PolygonList.prototype.GenerateBisectorList = function(edges, options)
{
	var bisectors = {active:[], processed:[], nextId:0, options:options};

	for (var thisIdx = 0; thisIdx < edges.length; thisIdx++)
	{
		this.CalcAndInsertBisectors(edges, thisIdx, bisectors);
	}
	
	//console.log("Bisectors\n", JSON.stringify(bisectors, 0, 2));
	return bisectors;
}

//----------------------------------------------------------------------------------------------
//	Calc Bisector Intersection
//
//	Bisector:
//		startPt: {x, y}
//		dir: {x, y}
//		endPt: {x, y} (optional)
//
//	Returns:
//		undefined: no intersection
//		-or-
//		pt: {x, y}
//		distance
//----------------------------------------------------------------------------------------------
function CalcBisectorIntersection(bisectorA, bisectorB)
{
	var intersectionPt = undefined;
	var startPtA = bisectorA.bisectorStartPt;
	var startPtB = bisectorB.bisectorStartPt;
	var endPtA = bisectorA.endPt;
	var endPtB = bisectorB.endPt;
	var intersection = undefined;
	
	// If the bisectors do not have endpoints, then use the direction vectors to compute endpoints
	if (endPtA == undefined)
		endPtA = (bisectorA.dir != undefined) ? {x:startPtA.x + bisectorA.dir.x, y:startPtA.y + bisectorA.dir.y} : startPtA;

	if (endPtB == undefined)
		endPtB = (bisectorB.dir != undefined) ? {x:startPtB.x + bisectorB.dir.x, y:startPtB.y + bisectorB.dir.y} : startPtB;
	
	let bi = undefined;
	
	if ((MathUtil.DistanceSquaredBetween(startPtA, endPtA) > 0) && (MathUtil.DistanceSquaredBetween(startPtB, endPtB) > 0))
		bi = MathUtil.CalcSegmentIntersection(startPtA, endPtA, startPtB, endPtB, true);

	if (bi == undefined)
	{
	}
	// Ignore intersections that occur before the start point of either line
	else if (bi.c1 < 0 || bi.c2 < 0)
	{
		// not a valid intersection
	}
	// Ignore intersections that occur after the end points, if either was specified
	else if ((bisectorA.endPt != undefined && bi.c1 > 1.0) || (bisectorB.endPt != undefined && bi.c2 > 1.0))
	{
		// Intersection occurred after an end pt
	}
	// Ignore parallel lines
	else if (bi.segmentsParallel)
	{
	}
	// Otherwise return the intersection
	else
	{
		let distanceA = MathUtil.DistanceBetween(startPtA, bi.ptIntersection);
		let distanceB = MathUtil.DistanceBetween(startPtB, bi.ptIntersection);
		// 2021.09.15: Add a tag to the intersection point.
		let pt = Object.assign({}, bi.ptIntersection);
		pt.tag = endPtA.tag;
		intersection = {pt, distanceA, distanceB};
	}
	
	return intersection;
}

//----------------------------------------------------------------------------------------------
//	Calc Bisector Intersection And Update Event List
//----------------------------------------------------------------------------------------------
/*
PolygonList.prototype.CalcBisectorIntersectionAndUpdateEventList = function(prevBisector, thisBisector, nextBisector, events)
{
	//console.log(JSON.stringify(prevBisector), JSON.stringify(thisBisector), JSON.stringify(nextBisector));
	
	let iPrevThis = undefined;
	let iThisNext = undefined;
	
	if (thisBisector.prevTested)
	{
		iPrevThis = thisBisector.prevIntersection;
	}
	else
	{
		var pt = CalcBisectorIntersection(prevBisector, thisBisector);

		//console.log("prev -.- this", JSON.stringify(pt));
		
		thisBisector.prevTested = true;
		prevBisector.nextTested = true;
		
		if (pt != undefined)
		{
			iPrevThis = {pt:pt, distance:MathUtil.DistanceBetween(thisBisector.bisectorStartPt, pt)};
			thisBisector.prevIntersection = iPrevThis;
				
			prevBisector.nextIntersection = {pt:pt, distance:MathUtil.DistanceBetween(prevBisector.bisectorStartPt, pt)};
		}
	}

	if (thisBisector.nextTested)
	{
		iThisNext = thisBisector.nextIntersection;
	}
	else
	{
		var pt = CalcBisectorIntersection(thisBisector, nextBisector);
		//console.log("this -.- next", JSON.stringify(pt));

		thisBisector.nextTested = true;
		nextBisector.prevTested = true;

		if (pt !=  undefined)
		{
			iThisNext = {pt:pt, distance:MathUtil.DistanceBetween(thisBisector.bisectorStartPt, pt)};
			thisBisector.nextIntersection = iThisNext;
			
			nextBisector.prevIntersection = {pt:pt, distance:MathUtil.DistanceBetween(nextBisector.bisectorStartPt, pt)};
		}
	}
	
	if (iPrevThis != undefined || iThisNext != undefined)
	{
		var bI = {}

		if (iPrevThis == undefined)
			bI = {pt:iThisNext.pt, distance:iThisNext.distance};
			
		else if (iThisNext == undefined)
			bI = {pt:iPrevThis.pt, distance:iPrevThis.distance};
		
		else if (iPrevThis.distance < iThisNext.distance)
			bI = {pt:iPrevThis.pt, distance:iPrevThis.distance};

		else
			bI = {pt:iThisNext.pt, distance:iThisNext.distance};
			
		
		// Store the bisector id
		bI.thisId = thisBisector.id;
		
		// Find the sorted location in the array to add the event
		let idx = events.findIndex(e => e.distance > bI.distance);
		
		if (idx == -1)
			events.push(bI);
		else
			events.splice(idx, 0, bI);
	}
}
*/
//----------------------------------------------------------------------------------------------
//	Calc Nearest Bisector Intersection
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CalcNearestBisectorIntersection = function(prevBisector, thisBisector, nextBisector)
{
	//console.log(JSON.stringify(prevBisector), JSON.stringify(thisBisector), JSON.stringify(nextBisector));
	let epsilon = 1e-9;
	
	// Has this bisector been tested against the previous bisector?
	if (thisBisector.prevTestedId != prevBisector.id)
	{
		// Otherwise, note that the two bisectors were tested against each other
		thisBisector.prevTested = prevBisector.id;
		prevBisector.nextTested = thisBisector.id;

		// Calculate the intersection
		var bI = CalcBisectorIntersection(prevBisector /* A */, thisBisector /* B */);
		let nonZ = (bI != undefined) && (bI.distanceA > epsilon) && (bI.distanceB > epsilon);
		prevBisector.nextIntersection =  (bI != undefined && nonZ) ? {pt:bI.pt, distance:bI.distanceA} : undefined;
		thisBisector.prevIntersection =  (bI != undefined && nonZ) ? {pt:bI.pt, distance:bI.distanceB} : undefined;
	}

	// Has this bisector been tested against the next bisector?
	if (thisBisector.nextTestedId != nextBisector.id)
	{
		thisBisector.nextTested = nextBisector.id;
		nextBisector.prevTested = thisBisector.id;

		var bI = CalcBisectorIntersection(thisBisector /* A */,  nextBisector /* B */);
		let nonZ = (bI != undefined) && (bI.distanceA > epsilon) && (bI.distanceB > epsilon);

		thisBisector.nextIntersection = (bI != undefined && nonZ) ? {pt:bI.pt, distance:bI.distanceA} : undefined;
		nextBisector.prevIntersection = (bI != undefined && nonZ) ? {pt:bI.pt, distance:bI.distanceB} : undefined;
	}
	
	// Assign the bisector intersection according to which intersection exists and, if both, then
	// which is closer
	if (thisBisector.prevIntersection == undefined && thisBisector.nextIntersection == undefined)
		thisBisector.intersection = undefined;
		
	if (thisBisector.prevIntersection == undefined)
		thisBisector.intersection = thisBisector.nextIntersection;
		
	else if (thisBisector.nextIntersection == undefined)
		thisBisector.intersection = thisBisector.prevIntersection;
	
	else if (thisBisector.prevIntersection.distance < thisBisector.nextIntersection.distance)
		thisBisector.intersection = thisBisector.prevIntersection;

	else
		thisBisector.intersection = thisBisector.nextIntersection;
	
}

//----------------------------------------------------------------------------------------------
//	Calc Sorted Vertex Event List
//----------------------------------------------------------------------------------------------
/*
PolygonList.prototype.CalcSortedVertexEventList = function(bisectors)
{
	var events = [];
	let len = bisectors.active.length;
	
	for (var thisIdx = 0; thisIdx < len; thisIdx++)
	{
		let prevIdx = (thisIdx + len - 1) % len;
		let nextIdx = (thisIdx + 1) % len;
		
		let prevB = bisectors.active[prevIdx];
		let thisB = bisectors.active[thisIdx];
		let nextB = bisectors.active[nextIdx];
		
		this.CalcBisectorIntersectionAndUpdateEventList(prevB, thisB, nextB, events);
	}
	
	//console.log(JSON.stringify(events, 0, 2));
	return events;
}
*/

//----------------------------------------------------------------------------------------------
//	Calc Bisector Intersection at Idx
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CalcBisectorIntersectionAtIdx = function(bisectors, thisIdx)
{
	let len = bisectors.active.length;

	let prevIdx = (thisIdx + len - 1) % len;
	let nextIdx = (thisIdx + 1) % len;
	
	let prevB = bisectors.active[prevIdx];
	let thisB = bisectors.active[thisIdx];
	let nextB = bisectors.active[nextIdx];
	
	this.CalcNearestBisectorIntersection(prevB, thisB, nextB);
}

//----------------------------------------------------------------------------------------------
//	Calc Bisector Intersections
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CalcBisectorIntersections = function(bisectors)
{
	let len = bisectors.active.length;
	
	for (var thisIdx = 0; thisIdx < len; thisIdx++)
		this.CalcBisectorIntersectionAtIdx(bisectors, thisIdx);
}


//----------------------------------------------------------------------------------------------
//	Construct Polygons From Bisectors
//----------------------------------------------------------------------------------------------
PolygonList.prototype.ConstructPolygonsFromBisectors = function(bisectors, work)
{
	var offsetPolygons = [];
	
	var polygon = [];
	
	for (var i = 0; i < bisectors.active.length; i++)
	{
		let b = bisectors.active[i];
		if (b.endPt != undefined)
		{
			// 2022.02.01: Associate the bisector with the point. We use this when
			// constructing lattices to disable the corner curves if there is a latticeEdge
			// at this vertex
			b.offsetPolygonPtIdx = polygon.length;

			// 2022.03.04: Add the z-values to the point to use with "clip poly to lattice edges" for midline
			let prevEdge = work.edges[(b.edgeIdx + work.edges.length - 1) % work.edges.length];
			let thisEdge = work.edges[b.edgeIdx];
			b.endPt.ptBz = (prevEdge.ptB.z != undefined) ? prevEdge.ptB.z : 0;
			b.endPt.ptAz = (thisEdge.ptA.z != undefined) ? thisEdge.ptA.z : 0;

			// 2022.05.16: Add the clip polygon edge index
			if (thisEdge.ptA.clipPolyEdgeIdx != undefined)
				b.endPt.clipPolyEdgeIdx = thisEdge.ptA.clipPolyEdgeIdx;
			polygon.push(b.endPt);
		}
	}
	
	if (polygon.length > 0)
		offsetPolygons.push(polygon);
	
	return offsetPolygons;
}

//----------------------------------------------------------------------------------------------
//	Construct Lattice Edges
//		Examine the Z-values of the vertices. For each case where the Z-values are different,
//		calculate a line that represents the edge of the line with the larger Z-value.
//		IMPORTANT: This is only HALF of the edge needed to give the appearance of a weave
//		or lattice. The other half comes from the polygon on the other side of the edge.
//
//	2022.01.25: Added
//	2022.02.01: Added areaPolygons which this function can modify so that the colors
//	overlap with the lattice edges
//	2022.02.16: Added latticeOffset via new options param
//----------------------------------------------------------------------------------------------
PolygonList.prototype.ConstructLatticeEdges = function(work, offsetPolygon, areaPolygons, options)
{
	var latticeEdges = undefined;
	let len = work.edges.length;
	var latticeOffset = GetProperty(options, "latticeOffset", 0.0);

	// Start with the list of "active bisectors", which represent the vertices of the
	// offset polygon. The "lattice edge" will go from the center line of the edge to
	// the vertex of the offset polygon.
	for (var i = 0; i < work.bisectors.active.length; i++)
	{
		let bisector = work.bisectors.active[i];

		// Determine if this bisector has an end point and a corresponding edge, since
		// we need both to calculate the lattice edge.
		if (bisector.endPt != undefined && bisector.edgeIdx != undefined)
		{
			// Examine the points at each vertex. Note that we have pointB from the "previous" edge
			// which is the same (x,y) as pointA from "thisEdge". If both of these points have a z-value
			// 2022.02.01: Use var for prevIdx so we have the value when searching for color (area) polygons
			let edgeIdx = bisector.edgeIdx;
			let prevIdx = (edgeIdx + len - 1) % len;
			// Get the edges
			let prevEdge = work.edges[prevIdx];
			let thisEdge = work.edges[edgeIdx];

			// Get the z-values. Note that we use zero if the z-value is undefined. This
			// handles the case where one has a value but the other does not.
			// We may, in the future, treat undefined as different from zero.
			let prevPtZ = (prevEdge.ptB.z != undefined) ? prevEdge.ptB.z : 0;
			let thisPtZ = (thisEdge.ptA.z != undefined) ? thisEdge.ptA.z : 0;

			// If the z-values are different, we need to compute a lattice edge.
			// Note that the lattice edge will only be from the center line of the
			// edge to the offset vertex.
			if (prevPtZ != thisPtZ)
			{
				// 2022.02.01: Use a meaningful variable to describe the condition
				let prevEdgeUnderThisEdge = (prevPtZ < thisPtZ);

				// "Three point list" to describe the corner.
				let corner = [prevEdge.ptA, thisEdge.ptA, thisEdge.ptB];

				// 2022.02:09: Node index is propagated from segment graph and is used
				// to efficiently associate adjacent lattice edges
				let nodeIdx = thisEdge.ptA.nodeIdx;

				// Offset from the corner. Only one will be non-zero
				let prevWidth = prevEdgeUnderThisEdge ? prevEdge.weight : 0;
				let thisWidth = prevEdgeUnderThisEdge ? 0 : thisEdge.weight;

				// Calculate the starting point of the lattice edge.
				let latticeEdgePtA = MathUtil.CalcOffsetVertex(corner, prevWidth, thisWidth);

				let latticeEdgePtB = bisector.endPt;

				if (latticeEdgePtA != undefined)
				{
					if (latticeEdges == undefined)
						latticeEdges = [];

					// 2022.02.15: Offset the lattice edge; use new variables since we use the original
					// lattice points further down
					var latticeOffsetPtA = latticeEdgePtA;
					var latticeOffsetPtB = latticeEdgePtB;
					if (latticeOffset != 0)
					{
						let o = latticeOffset;
						let u = undefined;

						if (prevEdgeUnderThisEdge)
							u = MathUtil.CalcUnitVector(thisEdge.ptB, thisEdge.ptA);
						else
							u = MathUtil.CalcUnitVector(prevEdge.ptA, prevEdge.ptB);

						if (u != undefined)
						{
							latticeOffsetPtA = {x: latticeEdgePtA.x + u.x * o, y:latticeEdgePtA.y + u.y * o};
							latticeOffsetPtB = {x: latticeEdgePtB.x + u.x * o, y:latticeEdgePtB.y + u.y * o};
						}
					}

					// 2022.02.09: Wrap points in an object so nodeIdx can be included
					// 2022.02.16: Replace latticeEdgePtxx with latticeOffsetPtxx
					let latticeEdge = [latticeOffsetPtA, latticeOffsetPtB];
					latticeEdges.push({nodeIdx, points:latticeEdge});

					// 2022.02.01: Prevent a curve from being rendered at this point
					if (bisector.offsetPolygonPtIdx != undefined && bisector.offsetPolygonPtIdx < offsetPolygon.length)
						offsetPolygon[bisector.offsetPolygonPtIdx].omitCurve = true;

					// 2022.02.01: Modify the color (area) polygons so that the edges
					// of the two polygons that share this vertex (thisEdge.ptA) are
					// moved to match the lattice edge. The first two points of the
					// color polygons are the two points (ptA and ptB) of the edge.
					// For the previous edge color polygon, if it is on top, then
					// we increase its size by shifting the third point, otherwise we
					// decrease its size by shifting the second point (ptB).
					// For the "this edge" color polygon, if it is on top, we shift the
					// last point to increase the area, otherwise we shift the first point
					// (ptA) to decrease the area.
					// In all cases, the new point will be the calculated latticeEdgePtA
					if (areaPolygons != undefined)
					{
						// Find the color polygons.
						let thisColorIdx = areaPolygons.findIndex(p => (p.edgeIdx == edgeIdx) );
						let prevColorIdx = areaPolygons.findIndex(p => (p.edgeIdx == prevIdx) );

						if (thisColorIdx != -1)
						{
							let colorPoly = areaPolygons[thisColorIdx].poly;
							let colorPtIdx =  (prevEdgeUnderThisEdge) ? 0 : (colorPoly.length - 1);
							// Note that by replacing the point we are removing any other information
							// that might have also been stored in this list. We are NOT updating the
							// x and y values because these points are shared with the the offset polygon
							// data due to shallow copying in Javascript
							// 2022.02.02: Omit curves for moved vertices
							colorPoly[colorPtIdx] = {x:latticeEdgePtA.x, y:latticeEdgePtA.y, omitCurve:true};
							// 2022.02.02: And omit curves for adjacent vertex. I am not sure that both this
							// 'omitCurve' and the next one are both necessary.
							if (prevEdgeUnderThisEdge)
								colorPoly[colorPoly.length - 1].omitCurve = true;
						}

						if (prevColorIdx != -1)
						{
							let colorPoly = areaPolygons[prevColorIdx].poly;
							let colorPtIdx =  (prevEdgeUnderThisEdge) ? 2 : 1;
							// See note above.
							// 2022.02.02: Omit curves for moved vertices
							colorPoly[colorPtIdx] = {x:latticeEdgePtA.x, y:latticeEdgePtA.y, omitCurve:true};
							// 2022.02.02: And omit curves for adjacent vertex
							if (!prevEdgeUnderThisEdge)
								colorPoly[2].omitCurve = true;
						}
					}
				}
			}
		}
	}

	return latticeEdges;
}

//----------------------------------------------------------------------------------------------
//	Construct Area Polygons
//
//	2022.02.02: Label the vertices to indicate how they should be processed if the
//	design has corner curves. It might be difficult to identify all possible cases.
//	Note that we are not handling all of these cases listed.
//	Cases:
//		Vertex should never be curved: 
//			Vertex is on the centerline and at the intersection of two design lines
//			Vertex is "interior" (and adjacent to another area polygon)
//			??
//		Vertex should be "half curved"
//			The polygon covers half of a vertex of the design. This happens in three places:
//			a. An outside corner of the design, b. An inside corner of a design, c. On the
//			centerline of the design at a vertex that is not at the intersection of two design lines
//		Vertex should be curved normally
//			This case should not occur since an area polygon never covers a complete
//			design vertex
//----------------------------------------------------------------------------------------------
PolygonList.prototype.ConstructAreaPolygons = function(work)
{
	var areaPolygons = [];
	let bLen = work.bisectors.active.length;

	// 2022.02.07: Utility function
	function addHalfCurvePoint(pt, bisectorIdx, direction /* "prev" or "next" */, renderFullCurve = false)
	{
		// 2022.02.02: Label this point as "half curved" and provide the adjacent
		// point needed to fully describe the curve.
		let bAdjacent = work.bisectors.active[bisectorIdx];
		if (bAdjacent != undefined && bAdjacent.endPt != undefined)
			pt.halfCurvePt = {x:bAdjacent.endPt.x, y:bAdjacent.endPt.y, which:direction, renderFullCurve};
	}
	
	for (var edgeIdx = 0; edgeIdx < work.edges.length; edgeIdx++)
	{
		var pt;
		var edge = work.edges[edgeIdx];
		var offsetEdgeIdx = undefined; // 2022.05.05
		var isOuterFrame = false;
		
		if (edge.weight > 0)
		{
		var polygon = [];
		var color = GetVertexColor(edge.ptA);
		var colorId	= (edge.ptA.tag != undefined) ? edge.ptA.tag.colorId : undefined; // 2020.10.15

		// 2022.02.02: The edge points are, by definition, on the center line. Specify how 
		// curves should be handled for edge points. For now the points on the center line
		// will not be curved. Later we will have to determine when the center line goes
		// around a vertex with no other center lines -- these should be half-curved.
		pt = Object.assign({}, edge.ptA);
		pt.omitCurve = true;
		polygon.push(pt);
		pt = Object.assign({}, edge.ptB);
		pt.omitCurve = true;
		polygon.push(pt);
		
		while (edge.areaPolyNextPts.length > 0)
			polygon.push(edge.areaPolyNextPts.shift());
		
		// If there is a crossoverPt, then this edge does not have a corresponding
		// edge on the offset polygon
		if (edge.crossoverPt != undefined)
		{
			polygon.push(edge.crossoverPt);
		}
		else
		{
			// Find the bisector for the next edge
			let nextEdgeIdx = edge.next;
			if (nextEdgeIdx != undefined)
			{
				// 2022.02.02: Switch from find to findIndex so we have the index to get the adjacent bisector
				var b = undefined;
				var bIdx = work.bisectors.active.findIndex(b => b.edgeIdx == nextEdgeIdx);
				if (bIdx != -1)
					b = work.bisectors.active[bIdx];
				if (b != undefined && b.endPt != undefined)
				{
					pt = Object.assign({}, b.endPt);
					// 2022.05.05: Index of point that is the offset (design) edge
					offsetEdgeIdx = polygon.length;
					// 2022.02.02: Label this point as "half curved" and provide the adjacent point needed to fully describe the curve.
					let renderFullCurve = (edge.endCapPtB && work.endCapStyle == PolygonMiterType.BUTT);
					addHalfCurvePoint(pt, ((bIdx + 1) % bLen), "prev", renderFullCurve); 
					polygon.push(pt);
					// 2022.05.18: 
					if (pt.tag != undefined && pt.tag.seTag == 0 /* outer frame */)
						isOuterFrame = true;
				}
			}
			else
			{
				console.log("ConstructAreaPolygons: nextEdgeIdx is undefined");
			}
			
			// Find the bisector for the edge
			var bIdx = work.bisectors.active.findIndex(b => b.edgeIdx == edgeIdx);
			
			if (bIdx != -1)
			{
				// If the bisector is for an endcap, then we have to find the last endcap
				// that matches the edgeIdx.
				// (This code is unrolled because there was an issue I could not resolve
				// when bIdx == 1)
				var bMatch = (work.bisectors.active[bIdx].bitype == "endcap") && (work.bisectors.active[(bIdx + 1) % bLen].edgeIdx == edgeIdx);
						
				while (bMatch)
				{
					bIdx = ((bIdx + 1) % bLen);
					bMatch = (work.bisectors.active[bIdx].bitype == "endcap") && (work.bisectors.active[(bIdx + 1) % bLen].edgeIdx == edgeIdx);
				}
			
				b = work.bisectors.active[bIdx];
				if (b != undefined && b.endPt != undefined)
				{
					pt = Object.assign({}, b.endPt);
					// 2022.02.02: Label this point as "half curved" and provide the adjacent point needed to fully describe the curve.
					// 2022.02.07: Identify the case where it is an endcap and the endcap style is BUTT. where we need to render a full curve.
					let renderFullCurve = (edge.endCapPtA && work.endCapStyle == PolygonMiterType.BUTT);
					addHalfCurvePoint(pt, ((bIdx + bLen - 1) % bLen), "next", renderFullCurve);
					polygon.push(pt);
				}
			}
		}
		
		while (edge.areaPolyPrevPts.length > 0)
			polygon.push(edge.areaPolyPrevPts.pop());
		
		// 2022.06.02: Remove duplicate points.
		// This should not happen; it might be good to log the points deleted to be aware of the issue
		{
			let k = 0;
			while (k < polygon.length)
			{
				if (MathUtil.EqualPt2(polygon[k], polygon[(k+1) % polygon.length]))
					polygon.splice(k, 1);
				else
					k++;
			}
		}

		// 2022.05.18: Remove colinear points
		// 2022.06.01: This does not remove colinear points at the end of polygon array; that is, it does compare the points 
		// that not wrap around to the start of the polygon
		/*
		{
			let k = 0;
			while (k < polygon.length - 2)
			{
				if (MathUtil.IsPointOnLine(polygon[k], polygon[k+1], polygon[k+2]))
					polygon.splice(k, 1);
				else
					k++;
			}
		}
		*/
		

		if (polygon.length >= 3)
		{
			// 2022.02.01: Add edgeIdx so that ConstructLatticeEdges function can modify the color polygons
			// 2022.05.18: Identify polygons along outerFrame so they can be ignored for 3D models
			areaPolygons.push({poly:polygon, color, colorId, edgeIdx, offsetEdgeIdx, isOuterFrame});
		}
		
		} // (edge.weight > 0)
	}
	
	// Add end cap polygons
	// 2022.02.07: Do not add polygons for BUTT endcap style, since these are basically zero-width rectangles.
	if (work.endCapStyle != PolygonMiterType.BUTT)
	{
		for (var i = 0; i < bLen; i++)
		{
			let thisB = work.bisectors.active[i];
			let nextB = work.bisectors.active[(i + 1) % bLen];
			if (thisB.bitype == "endcap" && nextB.bitype == "endcap")
			{
				if (thisB.endPt != undefined && nextB.endPt != undefined)
				{
					let edge = work.edges[thisB.edgeIdx]
					let polygon = [];

					// 2022.02.07: Reversed order of edges to fix half curves
					pt = Object.assign({}, thisB.endPt);
					addHalfCurvePoint(pt, ((i + bLen - 1) % bLen), "next"); // 2022.02.07
					polygon.push(pt);

					pt = Object.assign({}, edge.ptA);
					pt.omitCurve = true; // 2022.02.07: Prevent curve
					polygon.push(pt);

					pt = Object.assign({}, nextB.endPt);
					addHalfCurvePoint(pt, ((i + 2) % bLen), "prev"); // 2022.02.07
					polygon.push(pt);
				
					let color = GetVertexColor(edge.ptA);
					var colorId	= (edge.ptA.tag != undefined) ? edge.ptA.tag.colorId : undefined; // 2020.10.15
					let offsetEdgeIdx = 2; // 2022.05.05: Index to point at start of offset line
				
					areaPolygons.push({poly:polygon, color, colorId, offsetEdgeIdx});
				}
			}
		}
	}
	
	return areaPolygons;
}


//----------------------------------------------------------------------------------------------
//	Construct Bisector Skeleton
//----------------------------------------------------------------------------------------------
PolygonList.prototype.ConstructBisectorSkeleton = function(bisectors)
{
	var skeleton = [];

	var add = b => {
		if (b.bisectorStartPt != undefined && b.endPt != undefined)
			skeleton.push({ptA:b.bisectorStartPt, ptB:b.endPt, id:b.id});
	}
	
	bisectors.active.forEach(add);
	bisectors.processed.forEach(add);
	
	return skeleton;
}

//----------------------------------------------------------------------------------------------
//	Process Edge Event
//----------------------------------------------------------------------------------------------
/*
PolygonList.prototype.ProcessEdgeEvent = function(edges, bisectors, event, events)
{
	let debug = false;
	
	// The edge event will refer to two bisectors
	let thisBIdx = bisectors.active.findIndex(b => b.id == event.thisId);
	
	if (thisBIdx == -1)
	{
		if (debug) console.log("!! ProcessEdgeEvent: event.thisId", event.thisId, "thisBIdx", thisBIdx);
	}
	else
	{
		if (debug) console.log("event.thisId", event.thisId, "thisBIdx", thisBIdx);
		
		// We also need to remove the edge that has collapsed to zero
		let thisEdgeIdx = bisectors.active[thisBIdx].edgeIdx;
	
		// Mark the edge as no longer active
		edges[thisEdgeIdx].active = false;
	
		// And adjust the forward and backward links
		let prevEdgeIdx = edges[thisEdgeIdx].prev;
		let nextEdgeIdx = edges[thisEdgeIdx].next;
	
		edges[prevEdgeIdx].next = nextEdgeIdx;
		edges[nextEdgeIdx].prev = prevEdgeIdx;
	
	
		// Remove the two bisectors that intersected
		let thisB = undefined;
		var removed = bisectors.active.splice(thisBIdx, 1);
		if (removed != undefined && removed.length == 1)
			thisB = removed[0];
		else
			if (debug) console.log("!! ProcessEdgeEvent: could not remove idx " + thisBIdx);
		
		if (thisB != undefined && thisB.id != event.thisId)
			if (debug) console.log("ProcessEdgeEvent: thisB.id != event.thisId", thisB.id, event.thisId);
		
		let nextBIdx = bisectors.active.findIndex(b => b.id == event.nextId);
		let nextB = undefined;
		if (nextBIdx != -1)
		{
			var removed = bisectors.active.splice(nextBIdx, 1);
			if (removed != undefined && removed.length == 1)
				nextB = removed[0];
			else
				{if (debug) console.log("!! ProcessEdgeEvent: could not remove idx " + nextBIdx);}
			if (nextB.id != event.nextId)
				{if (debug) console.logconsole.log("ProcessEdgeEvent: nextB.id != event.nextId", nextB.id, event.nextId);}
	
			if (debug) console.log("event.nextIdx", event.nextIdx, "nextBIdx", nextBIdx);
		}
		// Update the end point to be where the bisectors intersected
		// Move them to the processed list
		if (thisB != undefined)
		{
			thisB.endPt = event.pt;
			bisectors.processed.push(thisB);
		}

		if (nextB != undefined)
		{
			nextB.endPt = event.pt;
			bisectors.processed.push(nextB);
		}
		
		// Add a new bisector
		this.CalcAndInsertBisector(edges, nextEdgeIdx, bisectors, event.pt);
		
		// Remove any events still in the list for the bisectors that were removed
		var eventIdx;
		
		eventIdx = events.findIndex(e => e.thisId == event.nextId);
		if (eventIdx != -1)
		{
			console.log("removing e.thisId == event.nextId:" + eventIdx);
			events.splice(eventIdx, 1);
		}
			
		eventIdx = events.findIndex(e => e.nextId == event.thisId);
		if (eventIdx != -1)
		{
			console.log("removing e.nextId == event.thisId:" + eventIdx);
			events.splice(eventIdx, 1);
		}			
		
		// Recompute bisector 
	}
}
*/

//----------------------------------------------------------------------------------------------
//	Process Bisector Event
//----------------------------------------------------------------------------------------------
/*
PolygonList.prototype.ProcessBisectorEvents = function(edges, bisectors, events)
{
	let debug = true;
	
	var current = undefined;
	
	while (events.length > 0)
	{
		let event = events.pop();

		// If we have already reviewed an event, then we will have a current intersection. If we
		// do and this does not match the next event, then .... (do something)
		if (current != undefined && !MathUtil.EqualPt2(current.pt, event.pt))
		{
			// Add a new bisector
			console.log("New bisector", JSON.stringify(current));
			
			console.log("before",JSON.stringify(bisectors, 0, 2));
			this.CalcAndInsertBisector(edges, current.edgeIdx, bisectors, current.pt);
			console.log("after",JSON.stringify(bisectors, 0, 2));
		
		
			// Recompute bisector 
			
			
			// Clear the current intersection
			current = undefined;
		}
		
		
		// Track the current intersection
		current = {pt:event.pt};
		
		// The event will refer to one bisector
		let thisBIdx = bisectors.active.findIndex(b => b.id == event.thisId);
	
		if (thisBIdx == -1)
		{
			if (debug) console.log("!! ProcessEdgeEvent: event.thisId", event.thisId, "thisBIdx", thisBIdx);
		}
		else
		{
			//if (debug) console.log("event.thisId", event.thisId, "thisBIdx", thisBIdx);
			if (events.length > 0 && MathUtil.EqualPt2(current.pt, events[0].pt))
			{
				// We also need to remove the edge that has collapsed to zero
				let thisEdgeIdx = bisectors.active[thisBIdx].edgeIdx;
				console.log("Removed edge idx: " + thisEdgeIdx);
				// Mark the edge as no longer active
				edges[thisEdgeIdx].active = false;
	
				// And adjust the forward and backward links
				let prevEdgeIdx = edges[thisEdgeIdx].prev;
				let nextEdgeIdx = edges[thisEdgeIdx].next;
	
				edges[prevEdgeIdx].next = nextEdgeIdx;
				edges[nextEdgeIdx].prev = prevEdgeIdx;
	
				// Track the leading edge index. This will used to create a new bisector
				current.edgeIdx = nextEdgeIdx;
			}
			
			// Remove the bisector that intersected
			let thisB = undefined;
			var removed = bisectors.active.splice(thisBIdx, 1);
			if (removed != undefined && removed.length == 1)
				thisB = removed[0];
			else
				if (debug) console.log("!! ProcessEdgeEvent: could not remove idx " + thisBIdx);
		
			if (thisB != undefined && thisB.id != event.thisId)
				if (debug) console.log("!! ProcessEdgeEvent: thisB.id != event.thisId", thisB.id, event.thisId);
		

			// Update the end point to be where the bisectors intersected
			// Move them to the processed list
			if (thisB != undefined)
			{
				thisB.endPt = event.pt;
				bisectors.processed.push(thisB);
			}
		}
		
		if (current != undefined && events.length == 0)
		{
			// Add a new bisector
			console.log("New bisector", JSON.stringify(current));
			
			//console.log("before",JSON.stringify(bisectors, 0, 2));
			this.CalcAndInsertBisector(edges, current.edgeIdx, bisectors, current.pt);
			//console.log("after",JSON.stringify(bisectors, 0, 2));
		
		
			// Recompute bisector 
			
			
			// Clear the current intersection
			current = undefined;
		}
		
	} // while
}
*/

//----------------------------------------------------------------------------------------------
//	Find Closest Intersection
//----------------------------------------------------------------------------------------------
PolygonList.prototype.FindClosestIntersection = function(work, minDistance)
{
	var closestIdx = undefined;
	var active = work.bisectors.active;
	
	for (var i = 0; i < active.length; i++)
	{
		let b = active[i];

		// 2022.06.02: Ignore minDistance and check for !processed instead
		if (b.intersection != undefined && (b.intersection.processed == undefined || !b.intersection.processed)/* && b.intersection.distance > minDistance*/)
		{
			if (closestIdx == undefined)
				closestIdx = i;
			else if (b.intersection.distance < active[closestIdx].intersection.distance)
				closestIdx = i;
		}
	}
		
	return closestIdx;
}

//----------------------------------------------------------------------------------------------
//	Next Bisector Idx
//----------------------------------------------------------------------------------------------
function NextBisectorIdx(work, thisBisectorIdx)
{
	let thisBisector = work.bisectors.active[thisBisectorIdx];
	let thisEdgeIdx = thisBisector.edgeIdx;
	let nextEdgeIdx = work.edges[thisEdgeIdx].next;
	let nextBisectorIdx = work.bisectors.active.findIndex(b => b.edgeIdx == nextEdgeIdx);
	
	return nextBisectorIdx;
}

//----------------------------------------------------------------------------------------------
//	Prev Bisector Idx
//----------------------------------------------------------------------------------------------
function PrevBisectorIdx(work, thisBisectorIdx)
{
	let thisBisector = work.bisectors.active[thisBisectorIdx];
	let thisEdgeIdx = thisBisector.edgeIdx;
	let prevEdgeIdx = work.edges[thisEdgeIdx].prev;
	let prevBisectorIdx = work.bisectors.active.findIndex(b => b.edgeIdx == prevEdgeIdx);
	
	return prevBisectorIdx;
}

//----------------------------------------------------------------------------------------------
//	Find Multi Intersecting Bisectors
//
//	Identify a set of bisectors around startIdx that all intersect each other. Three or more
//	bisectors can intersect in a way where the nearest intersection point on one bisector
//	is not the same point as the nearest intersection point on the adjacent bisector. 
//
//	If you imagine the three sides of a triangle as lying on three bisectors and the vertices
//	of the triangle as the intersections of the bisectors. And the bisectors point in relatively
//	opposite directions (for a triangle ABC, the bisectors point A-B, B-C, and C-A)
//----------------------------------------------------------------------------------------------
PolygonList.prototype.FindMultiIntersectingBisectors = function(work, startIdx)
{
	let results = undefined;
	let bList = [];
	let integrityFailure = undefined;
	let debug = work.debugLog;
	let bfc = 0;
	function dbglog(str) { if (debug) console.log(str) }
	
	debug && dbglog("FindMultiIntersectingBisectors: startIdx:" + startIdx);

	let looped = false;
	let firstIdx = startIdx;
	let lastIdx = startIdx;
	let intersectionPts = [];

	// Search first forward (dir == 0) and then backward (dir == 1)
	// Using this loop allows us to re-use the logic
	for (var dir = 0; dir <= 1 && !integrityFailure && !looped; dir++)
	{
		debug && dbglog("  search " + ((dir == 0) ? "forward" : "backward"));

		let adjacentIdx = startIdx;
		let done = false;

		do {
			// Get the next or previous bisector index (in vertex order)
			let oldIdx = adjacentIdx;
			adjacentIdx = (dir == 0) ? NextBisectorIdx(work, adjacentIdx) : PrevBisectorIdx(work, adjacentIdx);

			// We never expect an invalid bisector index, but it can happen, so we mark it as an integrity failure
			// and cancel the entire algorithm
			if (adjacentIdx == -1)
			{
				integrityFailure = true;
				done = true;
				debug && dbglog("    integrityFailure (nextIdx: -1)");
			}
			// Compare the two bisectors
			else
			{
				//debug && dbglog("      nextIdx: " + adjacentIdx + ", id:" + b.id + "  " + (b.intersection ? ptstr(b.intersection.pt) : "no intersection"));

				// Compute the intersection between this bisector and the previous
				let bA = work.bisectors.active[oldIdx];
				let bB = work.bisectors.active[adjacentIdx];
				let bi = CalcBisectorIntersection(bA, bB);
				
				// If no intersection, then we are done searching
				if (bi == undefined)
				{
					done = true;
				}
				else //if (MathUtil.EqualPt2(b.intersection.pt, pt))
				{
					//safety("looking for next bisector with same intersection");
					// Add the intersection point to the list. We are going to average
					// all of these and update the endpoints of all the of the bisectors in our list
					intersectionPts.push(bi.pt);

					// If we come back to the same bisector index, then we looped around, which
					// indicates that all available bisectors intersect each other at the "same" point.
					// Note that we test here, after computing the intersection and adding it to the list so 
					// that we get all intersections added to the pts list for averaging.
					if (adjacentIdx == startIdx)
					{
						debug && dbglog("    looped");
						looped = true;
						done = true;
					}
					// Otherwise update the appropriate index
					else if (dir == 0)
						lastIdx = adjacentIdx;
					else
						firstIdx = adjacentIdx;

				}
			}
		} while (!done);
	}
	
	if (!integrityFailure && (looped || firstIdx != lastIdx))
	{
		// Bisector intersection found count
		bfc = intersectionPts.length;
		
		// Average the points
		let avgPt = {x:0, y:0};
		intersectionPts.forEach(pt => { avgPt.x += pt.x; avgPt.y += pt.y });
		avgPt.x /= bfc;
		avgPt.y /= bfc;
		debug && dbglog("  Average intersection pt: " + avgPt.x + ", " + avgPt.y + " for " + bfc + " points");
		
		let count = work.bisectors.active.length;
		let idx = firstIdx;
			let b = work.bisectors.active[idx];
			b.intersection.pt.x = avgPt.x;
			b.intersection.pt.y = avgPt.y;
		
		while (idx != lastIdx && count >= 0) {
			idx = NextBisectorIdx(work, idx);
			let b = work.bisectors.active[idx];
			b.intersection.pt.x = avgPt.x;
			b.intersection.pt.y = avgPt.y;
			count--;
		}
	}
	
	if (debug)
	{
		dbglog("--Looped? " + looped);
		dbglog("--Integrity? " + integrityFailure);
		dbglog("--firstIdx:" + firstIdx + ", lastIdx:" + lastIdx);
	}
	
	// Package results
	results = { integrityFailure, firstIdx, lastIdx, bfc, looped };
	
	return results;
}

//----------------------------------------------------------------------------------------------
//	RemoveCollapsedZeroWidthEdges
//----------------------------------------------------------------------------------------------
PolygonList.prototype.RemoveCollapsedZeroWidthEdges = function(work)
{
	let debug = work.debugLog;
	function dbglog(str) { if (debug) console.log(str) }
	
	for (var edgeIdx = 0; edgeIdx < work.edges.length; edgeIdx++)
	{
		if (work.edges[edgeIdx].weight == 0)
		{
			let thisEdgeIdx = edgeIdx;
			let nextEdgeIdx = (edgeIdx + 1) % work.edges.length;
			
			let thisEdge = work.edges[thisEdgeIdx];
			let nextEdge = work.edges[nextEdgeIdx];
			
			let thisBisectorIdx = work.bisectors.active.findIndex(b => b.edgeIdx == thisEdgeIdx);
			let nextBisectorIdx = work.bisectors.active.findIndex(b => b.edgeIdx == nextEdgeIdx);
			
			let thisBisector = work.bisectors.active[thisBisectorIdx];
			let nextBisector = work.bisectors.active[nextBisectorIdx];
			
			let thisEndPt = thisBisector.endPt;
			let nextEndPt = nextBisector.endPt;
			
			let thisDistance = MathUtil.DistanceBetween(thisEdge.ptA, thisEndPt);
			let nextDistance = MathUtil.DistanceBetween(thisEdge.ptA, nextEndPt);
			
			//let thisLineDist = MathUtil.CalcPointToLineDistance(thisEndPt, thisEdge.ptA, thisEdge.ptB);
			//let nextLineDist = MathUtil.CalcPointToLineDistance(nextEndPt, thisEdge.ptA, thisEdge.ptB);
			
			let removeEdge = (thisDistance > nextDistance);
			
			debug && dbglog("RemoveCollapsedZeroWidthEdges, idx: " + edgeIdx);
			//debug && dbglog("  thisLineDist: " + thisLineDist + ", nextLineDist: " + nextLineDist);
			debug && dbglog("  removeEdge? " + removeEdge + ", thisDistance: " + thisDistance + ", nextDistance: " + nextDistance);
			
			if (removeEdge)
			{
				// Mark the edge as no longer active
				work.edges[thisEdgeIdx].active = false;

				// Get the forward and backward links
				let prevEdgeIdx = work.edges[thisEdgeIdx].prev;
				let nextEdgeIdx = work.edges[thisEdgeIdx].next;
			
				// Store the adjacent edges to see which ones are still active after
				// this loop
				//prevEdgeIdxs.push(prevEdgeIdx);
				//nextEdgeIdxs.push(nextEdgeIdx);

				//debug && dbglog("        prevEdgeIdx: " + prevEdgeIdx + ", nextEdgeIdx: " + nextEdgeIdx);

				// Store the intersection point of the bisectors with the edge. This
				// will be used to construct an "area" polygon between the original
				// polygon and the offset.
				//work.edges[thisEdgeIdx].crossoverPt = pt;
			
				// And adjust the forward and backward links for the adjacent edges
				work.edges[prevEdgeIdx].next = nextEdgeIdx;
				work.edges[nextEdgeIdx].prev = prevEdgeIdx;

				// 
				work.edges[thisEdgeIdx].prev = undefined;
				work.edges[thisEdgeIdx].next = undefined;
	
				//idx = (idx + 1) % work.bisectors.active.length;
				
				// Remove a bisector
				let bb = work.bisectors.active.splice(thisBisectorIdx, 2);
				
				// Start of the bisector
				let specifiedStartPt = {x: (thisEndPt.x + nextEndPt.x)/2, y: (thisEndPt.y + nextEndPt.y)/2 };
				
				// Recreate the first bisector based on the previous edge
				this.CalcAndInsertBisectors(work.edges, nextEdgeIdx, work.bisectors, specifiedStartPt);
			}
		}
	}
}


//----------------------------------------------------------------------------------------------
//	Process Bisector Intersections
//----------------------------------------------------------------------------------------------
PolygonList.prototype.ProcessBisectorIntersections = function(work)
{
	// Debugging and integrity 
	let debug = work.debugLog;
	var sc = 0; // safety count 
	var bfc; // bisector found count; should always be 2 or greater
	var iteration = 1;
	function dbglog(str) { if (debug) console.log(str) }
	function ptstr(pt) { return pt ? "{x:"+pt.x.toFixed(4)+", y:"+pt.y.toFixed(4)+"}" : "undefined"; }
	function safety(desc) { sc++; if (sc > 1000) throw ("ProcessBisectorIntersections error: " + desc); }
	
	debug && dbglog("ProcessBisectorIntersections");
	debug && dbglog("  bisector count: " + work.bisectors.active.length);
	let ic = work.bisectors.active.filter(b => b.intersection != undefined).length;
	debug && dbglog("  intersection count: " + ic);

	function activeEdges() { return work.edges.filter(e => e.active).length; }
	
	var done = false;
	var recomputeAllCount = 0;
	const maxRecomputeAllCount = 10;
	
	// We have already called 'CalcBisectorIntersections' prior to entering this routine.
	// Now we are going to examine the intersections. When we find an intersection, we replace the two
	// bisectors with a new bisector.
	
	// 2022.06.09: Identify and remove zero-width edges where the bisectors endpoints cross each other,
	// which indicates that these edges will not be in the offset polygon
	this.RemoveCollapsedZeroWidthEdges(work);
	
	while (!done)
	{
		// The closest intersection of the bisectors of the polygon might not be the closest point
		// of two bisectors. When this happens, we have to find the next closest intersection 
		var minDistance = 0;

		// Find the index of the bisector with the intersection closest to its start point
		var closestIdx = this.FindClosestIntersection(work, minDistance);
	
		// If we found one, then process 
		while (closestIdx != undefined && activeEdges() > 2 && recomputeAllCount < maxRecomputeAllCount)
		{
			safety("main loop");
			bfc = 1;
		
			debug && dbglog("  iteration: " + iteration + ", closestIdx: " + closestIdx);
		
			// The bisector and its distance
			let bClosest = work.bisectors.active[closestIdx];
			let pt = bClosest.intersection.pt;
			debug && dbglog("    pt: " + ptstr(pt) + ", distance: " + bClosest.intersection.distance);
			// 2022.06.02: Mark as processed
			bClosest.intersection.processed = true;
		
			// We identify a run of bisectors all with the same intersection
			var firstIdx = closestIdx;
			var lastIdx = closestIdx;
		
			// Location (edgeIdx) of new bisector, if one is needed
			var recomputeEdgeIdx;
		
			// Flag for when we can't find the next or prev bisector intersection. 
			var recomputeAllBisectorIntersections = false;
		
			// Get the next bisector index (in vertex order)
			var nextIdx = NextBisectorIdx(work, closestIdx);
			//debug && dbglog("    nextIdx: " + nextIdx);
		
			if (nextIdx == -1)
			{
				recomputeAllBisectorIntersections = true;
			}
			else
			{
				debug && dbglog("    search forward");
				var b = work.bisectors.active[nextIdx];
				debug && dbglog("      nextIdx: " + nextIdx + ", id:" + b.id + "  " + (b.intersection ? ptstr(b.intersection.pt) : "no intersection"));
				// Is there an intersection? Is it the same point? Is it a different bisector?
				while (nextIdx != -1 && b.intersection != undefined && MathUtil.EqualPt2(b.intersection.pt, pt) && b.id != bClosest.id)
				{
					safety("looking for next bisector with same intersection");
					lastIdx = nextIdx;
					nextIdx = NextBisectorIdx(work, nextIdx);
					//debug && dbglog("      .. nextIdx: " + nextIdx);// + "  " + (nextIdx != -1 ? (ptstr(work.bisectors.active[nextIdx].intersection.pt)) : ""));
					if (nextIdx != -1)
					{
						b = work.bisectors.active[nextIdx];
						debug && dbglog("      nextIdx: " + nextIdx + ", id:" + b.id + "  " + (b.intersection ? ptstr(b.intersection.pt) : "no intersection"));
						bfc++; // (bisector found count)
					}
					else
					{
						recomputeAllBisectorIntersections = true;
					}
				}
			}

			// Determine if we looped completely around the polygon because all of the bisectors have
			// the same intersection point
			let looped = (bClosest.id == b.id);
		
			// If we did not loop, then get the previous bisector index (in vertex order)
			if (!looped && !recomputeAllBisectorIntersections)
			{
				debug && dbglog("    search backward");
				var prevIdx = PrevBisectorIdx(work, closestIdx);
				//debug && dbglog("    prevIdx: " + prevIdx);
				if (prevIdx == -1)
				{
					recomputeAllBisectorIntersections = true;
				}
				else
				{
					var b = work.bisectors.active[prevIdx];
					debug && dbglog("      prevIdx: " + prevIdx + ", id:" + b.id + "  " + (b.intersection ? ptstr(b.intersection.pt) : "no intersection"));
					// Is there an intersection? Is it the same point? Is it a different bisector?
					while (prevIdx != -1 && b.intersection != undefined && MathUtil.EqualPt2(b.intersection.pt, pt) && b.id != bClosest.id)
					{
						safety("looking for prev bisector with same intersection");
						firstIdx = prevIdx;
						//debug && dbglog("      .. prevIdx: " + prevIdx);// + "  " + (prevIdx != -1 ? (ptstr(work.bisectors.active[prevIdx].intersection.pt)) : ""));
						prevIdx = PrevBisectorIdx(work, prevIdx);
						if (prevIdx != -1)
						{
							b = work.bisectors.active[prevIdx];
							debug && dbglog("      prevIdx: " + prevIdx + ", id:" + b.id + "  " + (b.intersection ? ptstr(b.intersection.pt) : "no intersection"));
							bfc++; // (bisector found count)
						}
						else
						{
							recomputeAllBisectorIntersections = true;
						}
					}
			
					// Note the location for the bisector to recompute
					recomputeEdgeIdx = work.bisectors.active[lastIdx].edgeIdx;
				}
			}
			
			if (firstIdx == lastIdx)
			{		
				debug && dbglog("    !!! Can't find second intersecting bisector (idx: " + closestIdx + ")");
				let miResults = this.FindMultiIntersectingBisectors(work, closestIdx);
				
				if (!miResults.integrityFailure)
				{
					bfc = miResults.bfc;
					firstIdx = miResults.firstIdx;
					lastIdx = miResults.lastIdx;
					looped = miResults.looped;

					// Note the location for the bisector to recompute
					recomputeEdgeIdx = work.bisectors.active[lastIdx].edgeIdx;
				}
			}

			if (recomputeAllBisectorIntersections)
			{
				recomputeAllCount++;
				
				debug && dbglog("    re-computing all bisector intersections. #" + recomputeAllCount);
				for (var j = 0; j < work.bisectors.active.length; j++)
					this.CalcBisectorIntersectionAtIdx(work.bisectors, j);
				// Find the index of the bisector with the intersection closest to its start point
				closestIdx = this.FindClosestIntersection(work, minDistance);
		
				if (recomputeAllCount >= maxRecomputeAllCount)
				{
					debug && dbglog("      Exceeded recompute count; no polygon returned");
				}
				continue;
			}

			debug && dbglog("    firstIdx: " + firstIdx + ", lastIdx: " + lastIdx);
			debug && dbglog("      bisector found count: " + bfc);
			if (bfc < 2)
			{
				debug && dbglog("      setting minDistance to " + bClosest.intersection.distance + " and searching again");
				minDistance = bClosest.intersection.distance;
			}
			else
			{
				// Reset minDistance
				minDistance = 0;
			
				// Determine if any endcap bisectors
				let allEdgeBisectors = true;
				var idx = firstIdx;
				allEdgeBisectors = (allEdgeBisectors && work.bisectors.active[idx].bitype == "edge");
				do 
				{
					idx = (idx + 1) % work.bisectors.active.length;
					allEdgeBisectors = (allEdgeBisectors && work.bisectors.active[idx].bitype == "edge");
				}
				while (idx != lastIdx)
			
				if (allEdgeBisectors)
				{
					// Remove the edges for all of the bisector intersections. 
					var idx = firstIdx;
				
					// For building the area polygons, we will also store the crossover point
					// in the adjacent edges. We only want to do this if the adjacent edge is
					// still active after we remove the edges below. Therefore, we make a list 
					// of adjacent edges and check it after
					var prevEdgeIdxs = [];
					var nextEdgeIdxs = [];
		
					debug && dbglog("    removing edges");
					while (idx != lastIdx)
					{
						// We also need to remove the edge that has collapsed to zero
						let thisEdgeIdx = work.bisectors.active[idx].edgeIdx;
						debug && dbglog("      Removed edge idx: " + thisEdgeIdx);
			
						// Mark the edge as no longer active
						work.edges[thisEdgeIdx].active = false;

						// Get the forward and backward links
						let prevEdgeIdx = work.edges[thisEdgeIdx].prev;
						let nextEdgeIdx = work.edges[thisEdgeIdx].next;
					
						// Store the adjacent edges to see which ones are still active after
						// this loop
						prevEdgeIdxs.push(prevEdgeIdx);
						nextEdgeIdxs.push(nextEdgeIdx);

						debug && dbglog("        prevEdgeIdx: " + prevEdgeIdx + ", nextEdgeIdx: " + nextEdgeIdx);

						// Store the intersection point of the bisectors with the edge. This
						// will be used to construct an "area" polygon between the original
						// polygon and the offset.
						work.edges[thisEdgeIdx].crossoverPt = pt;
					
						// And adjust the forward and backward links for the adjacent edges
						work.edges[prevEdgeIdx].next = nextEdgeIdx;
						work.edges[nextEdgeIdx].prev = prevEdgeIdx;

						// 
						work.edges[thisEdgeIdx].prev = undefined;
						work.edges[thisEdgeIdx].next = undefined;
			
						idx = (idx + 1) % work.bisectors.active.length;
					}
				
					// Also use the same point for the previous and next edge, but as 
					// sides of the area polygon
					while (prevEdgeIdxs.length > 0)
					{
						let prevEdgeIdx = prevEdgeIdxs.pop();
						if (work.edges[prevEdgeIdx].active)
							work.edges[prevEdgeIdx].areaPolyNextPts.push(pt);
					}
				
					while (nextEdgeIdxs.length > 0)
					{
						let nextEdgeIdx = nextEdgeIdxs.pop();
						if (work.edges[nextEdgeIdx].active)
							work.edges[nextEdgeIdx].areaPolyPrevPts.push(pt);
					}
				}
			
				// "Deactivate" bisectors (set the end points for all of the bisectors,
				// and mark as 'remove')
				let deactivateB = function(bIdx)
				{
					let bt = work.bisectors.active[bIdx];
					if (allEdgeBisectors || bt.bitype != "edge")
					{
						debug && dbglog("      bisectorIdx:" + bIdx + ", edgeIdx: " + bt.edgeIdx + ", bitype:" + bt.bitype);
						bt.endPt = bt.intersection.pt;
						bt.remove = true;
					}
					else
					{
						debug && dbglog("      bisectorIdx:" + bIdx + ", edgeIdx: " + bt.edgeIdx + ", recompute");
						bt.recompute = true;
					}
				}
		
				var idx = firstIdx;
		
				debug && dbglog("    updating bisectors");
				deactivateB(idx);
				do 
				{
					idx = (idx + 1) % work.bisectors.active.length;
					deactivateB(idx);
				}
				while (idx != lastIdx)
		
		
				// Remove the bisectors
				debug && dbglog("    removing bisectors");
				var brIdx = work.bisectors.active.findIndex(b => b.remove);
				while (brIdx != -1)
				{
					let bb = work.bisectors.active.splice(brIdx, 1);
					debug && dbglog("      edgeIdx:" + bb[0].edgeIdx + ", bitype:" + bb[0].bitype);
					if (bb[0].bitype == "edge")
						work.bisectors.processed.push(bb[0]);
					brIdx = work.bisectors.active.findIndex(b => b.remove);
				}
			
				// If not allEdgeBisectors, then we need to recompute the bisectors we did not remove
				if (!allEdgeBisectors)
				{
					debug && dbglog("    recompute remaining found bisectors");
					var bIdx = work.bisectors.active.findIndex(b => b.recompute);
					while (bIdx != -1)
					{
						let b = work.bisectors.active[bIdx];
						b.recompute = undefined;
						debug && dbglog("      edgeIdx:" + b.edgeIdx + ", bitype:" + b.bitype);
					
						this.CalcBisectorIntersectionAtIdx(work.bisectors, bIdx);
						bIdx = work.bisectors.active.findIndex(b => b.recompute);
					}
				}

				if (!looped && allEdgeBisectors && activeEdges() > 2)
				{
					// Compute new bisector 
					debug && dbglog("    New bisector, startPt: " + ptstr(pt) + " at edgeIdx: " + recomputeEdgeIdx);
					let bisectorIdx = this.CalcAndInsertBisectors(work.edges, recomputeEdgeIdx, work.bisectors, pt);
					debug && dbglog("      new bisectorIdx: " + bisectorIdx + ", id:" + work.bisectors.active[bisectorIdx].id);
					debug && dbglog("        " + JSON.stringify(work.bisectors.active[bisectorIdx]));
					
					// Compute intersections for new bisector
					this.CalcBisectorIntersectionAtIdx(work.bisectors, bisectorIdx);
				
					let bLen = work.bisectors.active.length;
					let prevBIdx = (bisectorIdx + bLen - 1) % bLen;
					let nextBIdx = (bisectorIdx + 1) % bLen;
					this.CalcBisectorIntersectionAtIdx(work.bisectors, prevBIdx);
					this.CalcBisectorIntersectionAtIdx(work.bisectors, nextBIdx);
				}
			}

			// Find the index of the bisector with the intersection closest to its start point
			closestIdx = this.FindClosestIntersection(work, minDistance);
		
			iteration++; // 2022.06.02
		} // while-closestIdx

		// After processing the bisector intersections, we need to check for issues from zero-offset
		// edges. Zero-offset edges may have zero-length bisectors, which will never intersect
		// with another bisector. This prevents us from detecting crossovers.
		// 
		var zeroOffsetIdx = work.edges.findIndex(e => e.active && e.weight == 0);
	
		//console.log(JSON.stringify(work.edges, 0, 2));
		// If we have at least one edge with a zero offset then check for reversed edges
		// by comparing the direction of the edge with its offset
		if (0 && recomputeAllCount < maxRecomputeAllCount && activeEdges() > 2 && zeroOffsetIdx != -1)
		{
			var foundOne = false;

			debug && dbglog("  searching for reversed offset edges");
			
			while (zeroOffsetIdx < work.edges.length && !foundOne)
			{
				debug && dbglog("    zeroOffIdx: " + zeroOffsetIdx);
				let e = work.edges[zeroOffsetIdx];

				// Get the bisector for this edge and the next edge
				let thisB = work.bisectors.active.find(b => b.edgeIdx == zeroOffsetIdx);
				let nextB = work.bisectors.active.find(b => b.edgeIdx == e.next);
				
				if (thisB == undefined)
					console.log("  zeroOffset: this edge's bisector is undefined");
				if (nextB == undefined)
					console.log("  zeroOffset: next edge's bisector is undefined");
					
				// Are there are endpoints for both bisectors? 
				if (thisB != undefined && thisB.endPt != undefined && nextB != undefined && nextB.endPt != undefined)
				{
					// Get the vector for the edge and the offset
					let eV = MathUtil.CalcUnitVector(e.ptB, e.ptA);
					let oV = MathUtil.CalcUnitVector(nextB.endPt, thisB.endPt);

					var dot = undefined;
					
					if (eV != undefined && oV != undefined)
						dot = eV.x * oV.x + eV.y * oV.y;
					else
					{
						if (!eV)
							{debug && dbglog("      edge unit vector is undefined");}
						if (!oV)
							{debug && dbglog("      offset edge unit vector is undefined");}
					}
					
					if (dot != undefined && MathUtil.EqualWithinTolerance(dot, -1.0, NORMAL_TOLERANCE))
					{
						debug && dbglog("  reversed offset edge found; zeroOffsetIdx:" + zeroOffsetIdx);
						
						foundOne = true;
						
						// Set the edge as inactive
						e.active = false;
						
						// Get the forward and backward links
						let prevEdgeIdx = e.prev;
						let nextEdgeIdx = e.next;
					
						// And adjust the forward and backward links for the adjacent edges
						work.edges[prevEdgeIdx].next = nextEdgeIdx;
						work.edges[nextEdgeIdx].prev = prevEdgeIdx;

						// Clear the references in this edge
						e.prev = undefined;
						e.next = undefined;

						// Note that we are removing TWO bisectors and discarding them
						let thisBIdx = work.bisectors.active.findIndex(b => b.edgeIdx == zeroOffsetIdx);
						if (thisBIdx <= work.bisectors.active.length - 2)
						{
							debug && dbglog("    remove adjacent bisectors");
							work.bisectors.active.splice(thisBIdx, 2 /* <<<<<< */);
						}
						else
						{
							debug && dbglog("    remove last and first bisectors");
							work.bisectors.active.splice(thisBIdx, 1 /* <<<<<< */);
							work.bisectors.active.splice(0, 1 /* <<<<<< */);
						}

						// Compute new bisector 
						let newStartPt = e.ptB;
						let bisectorIdx = this.CalcAndInsertBisectors(work.edges, nextEdgeIdx, work.bisectors, newStartPt);
						debug && dbglog("      new bisectorIdx: " + bisectorIdx);
	
						// Compute intersections for new bisector
						this.CalcBisectorIntersectionAtIdx(work.bisectors, bisectorIdx);
				
						let prevBIdx = work.bisectors.active.findIndex(b => b.edgeIdx == prevEdgeIdx);
						if (prevBIdx != -1)
							this.CalcBisectorIntersectionAtIdx(work.bisectors, prevBIdx);

						let nextBIdx = work.bisectors.active.findIndex(b => b.edgeIdx == nextEdgeIdx);
						if (nextBIdx != -1)
							this.CalcBisectorIntersectionAtIdx(work.bisectors, nextBIdx);
					}
					else
					{
						//console.log("  not opposite");
					}
				}
				
				if (!foundOne)
				{
					zeroOffsetIdx++;
					while (zeroOffsetIdx < work.edges.length && (!work.edges[zeroOffsetIdx].active || work.edges[zeroOffsetIdx].weight > 0))
						zeroOffsetIdx++;
						
					//console.log("  next zeroOffsetIdx: " + zeroOffsetIdx);
				}
				
			}
			
			done = !foundOne;
		}
		else
		{
			done = true;
		}
		
		if (recomputeAllCount >= maxRecomputeAllCount)
		{
			debug && dbglog("  No polygon returned");
			work.bisectors.processed = work.bisectors.processed.concat(work.bisectors.active);
			work.bisectors.active = [];
		}
		
	} // while-not-done
}


//----------------------------------------------------------------------------------------------
//	Calc Offset Polygon, v6, Single
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CalcOffsetPolygon_v6_Single = function(polygon, offsetDistance, options)
{
	var debugLog = GetProperty(options, "debugLog", false);
	// Determine the endcap style
	var endCapStyle = GetProperty(options, "endCapStyle", PolygonMiterType.BUTT);
	var buildAreaPolygons = GetProperty(options, "buildAreaPolygons", false);
	var constructLattice = GetProperty(options, "constructLattice", false);
	var latticeOffset = GetProperty(options, "latticeOffset", 0.0);
	let work = {debugLog, endCapStyle, buildAreaPolygons};
	let edgeOptions = undefined; // 2022.03.03: Edge options added to support adjusting line width

	if (options != undefined && (options.adjustWeight != undefined || options.adjustOffset != undefined))
		edgeOptions = {adjustWeight:options.adjustWeight, adjustOffset:options.adjustOffset};
	
	// Convert the polygon data into a list that we can process
	work.edges = this.GenerateEdgeList(polygon, offsetDistance, edgeOptions);
	
	// Calculate the bisectors between all of the vertices
	work.bisectors = this.GenerateBisectorList(work.edges, {endCapStyle: endCapStyle});
	
	// Calculate the intersections of the bisectors
	this.CalcBisectorIntersections(work.bisectors);
	
	this.ProcessBisectorIntersections(work);
	
	let offsetPolygons = this.ConstructPolygonsFromBisectors(work.bisectors, work);
	let skeleton = this.ConstructBisectorSkeleton(work.bisectors);
	let areaPolygons = buildAreaPolygons ? this.ConstructAreaPolygons(work) : undefined;
	// 2022.01.25: Added
	// 2022.02.01: Added option to enable lattices or not; add offsetPolygon param to disable curves
	let latticeEdges = constructLattice ? this.ConstructLatticeEdges(work, offsetPolygons[0], areaPolygons, {latticeOffset}) : undefined;

	let single = {offsetPolygonList:offsetPolygons, skeleton, bisectorList:skeleton, areaPolygonList:areaPolygons, latticeEdges};
	

	return single;
}

//----------------------------------------------------------------------------------------------
//	Calc Offset Polygon, v6, Single Safe
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CalcOffsetPolygon_v6_SingleSafe = function(polygon, offsetDistance, options)
{
	
	try {
		return this.CalcOffsetPolygon_v6_Single(polygon, offsetDistance, options)
	}
	catch (err) {
		let exp = { pds:true, polygon, options };
		console.log("Issue to Resolve: ");
		console.log(JSON.stringify(exp));
		console.error(err);
		return {};
	}
}


//----------------------------------------------------------------------------------------------
//	Coalesce Lines
//		Moves points from one array to another if there are two matching end points.
//	2022.02.09: Added.
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CoalesceLines = function(lineA, lineB)
{
	let iLen = lineA.length;
	let jLen = lineB.length;

	if (iLen > 0 && jLen > 0)
	{
		if (MathUtil.EqualPt2(lineA[0], lineB[jLen-1]))
		{
			// Last point of j matches first point of i, so drop the
			// first point of i and add the remaining points of i to the end of j
			lineA.shift();
			while (lineA.length > 0)
				lineB.push(lineA.shift());
		}
		else if (MathUtil.EqualPt2(lineA[iLen-1], lineB[0]))
		{
			// Last point of i matches first point of j, so drop the
			// first point of j and add the remaining points of j to the end of i
			lineB.shift();
			while (lineB.length > 0)
				lineA.push(lineB.shift());
		}
		else if (MathUtil.EqualPt2(lineA[0], lineB[0]))
		{
			lineB.shift();
			while (lineB.length > 0)
				lineA.unshift(lineB.shift());
		}
		else if (MathUtil.EqualPt2(lineA[iLen-1], lineB[jLen-1]))
		{
			lineB.pop();
			while (lineB.length > 0)
				lineA.push(lineB.pop());
		}
	}
}

//----------------------------------------------------------------------------------------------
//	Coalesce Lattice Edges
//		Connects lattice edges together.
//	2022.02.09: Added.
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CoalesceLatticeEdges = function(latticeEdges)
{
	let nodes = [];

	// A node in the segment list was an intersection of two or more lines.
	// Use the node index that was provided by the segment list to group the lattice edges.
	latticeEdges.forEach((edge, index) => {
		if (edge.nodeIdx != undefined) {
			if (nodes[edge.nodeIdx] == undefined)
				nodes[edge.nodeIdx] = [];
			nodes[edge.nodeIdx].push(index)
		}
	});

	// For each node, examine the edges to find common points
	let keys = Object.keys(nodes);
	for (var n = 0; n < keys.length; n++)
	{
		// A node contains a list of indices into the lattice edges array
		let node = nodes[keys[n]];

		// We need to compare all pairs of edges to find any two that have a common endpoint
		// and combine them into a single edge
		let nLen = node.length;
		for (var i = 0; i < nLen; i++)
		{
			for (var j = 0; j < nLen; j++)
			{
				if (i != j)
					this.CoalesceLines(latticeEdges[node[i]].points, latticeEdges[node[j]].points);
			}
		}
	}
}

//----------------------------------------------------------------------------------------------
//	Clip Polygon to Lattice edges
//		2022.03.02: Created
//----------------------------------------------------------------------------------------------
var ClipPolygonToLatticeEdges = function(polyPoints, latticeEdges, options)
{
	// Undefined or a list (array) of open polygons (array)
	let outputList = undefined;

	// Track if we are including or excluding the next segment
	let includeSegment = true;
	let outputPoly = [];
	let epsilon = 0.0001;
	let connectStartToEnd = true;
	
	// Adds one or two (if list is empty) points to the output polygon
	var addOutputEdge = function(edgePtA, edgePtB)
	{
		if (outputPoly.length == 0)
			outputPoly.push(edgePtA);
		outputPoly.push(edgePtB);
	}

	// Adds the output polygon to the output list and resets the poly
	var addOutputPoly = function()
	{
		if (outputList == undefined)
			outputList = [];
		outputList.push(outputPoly);
		outputPoly = [];
	}
	
	// Examine each edge of the polygon
	for (var k = 0; k < polyPoints.length; k++)
	{
		let ptA = polyPoints[k];
		let ptB = polyPoints[(k + 1) % (polyPoints.length)];

		// Determine if the leading edge of the vertex will be included or clipped. Note that
		// if no lattice edges intersect the first edge, then the entire edge is included in the output
		includeSegment = true;
		let ptAz = (ptA.ptAz != undefined) ? ptA.ptAz : 0;
		let ptBz = (ptA.ptBz != undefined) ? ptA.ptBz : 0;

		if (ptA.ptAz > ptA.ptBz)
		{
			if (outputPoly.length > 0)
				addOutputPoly();
			includeSegment = false;
			if (k == 0)
				connectStartToEnd = false;
		}

		// Create a list of all of the intersections between the lattice edges and the polygon edge.
		// Note that we expect either 0, 1, or 2
		let intersections = [];
		for (var i = 0; i < latticeEdges.length; i++)
		{
			let edge = latticeEdges[i].points;
			let c = MathUtil.CalcSegmentIntersection(ptA, ptB, edge[0], edge[1], true);

			// Ignore intersections at the endpoints. It should not happen, but just in case.
			// This also excludes any intersections outside of the polygon edge.
			// Also ignore intersections not within the lattice edge.
			if ((epsilon <= c.c1 && c.c1 <= 1.0 - epsilon) && (-epsilon <= c.c2 && c.c2 <= 1.0 + epsilon))
				intersections.push(c);
		}

		// If no lattice intersections with the polygon edge, then include or exclude the entire edge
		if (intersections.length == 0)
		{
			if (includeSegment)
				addOutputEdge(ptA, ptB);
		}
		// Otherwise, subdivide the polygon edge according to the intersections
		else
		{
			let segPtA = ptA;
			let segPtB;

			// Sort by distance from the vertex
			intersections.sort((a, b) => (a.c1 - b.c1));

			for (var i = 0; i < intersections.length; i++)
			{
				segPtB = intersections[i].ptIntersection;
				// Add the segment to the output poly and, since we are at an intersection and
				// we know the next segment won't be included, add the poly to the list
				if (includeSegment)
				{
					addOutputEdge(segPtA, segPtB)
					addOutputPoly();
				}
				// As we pass intersections we toggle the 'includeSegment' flag
				includeSegment = !includeSegment;
				segPtA = segPtB;
			}
			// Add from the last intersection to the endpoint of the line, if including the segment
			if (includeSegment)
				addOutputEdge(segPtA, ptB)
		}
	}
	
	if (outputPoly.length > 0)
		addOutputPoly();

	// Prepend last polygon onto the first polygon is they were not clipped
	if (connectStartToEnd && includeSegment && outputList.length > 1)
	{
		// Get the last polygon from the output list
		let last = outputList.pop();
		// Remove the common point
		last.pop();
		// Move the remaining point to the begining of the first polygon
		while (last.length > 0)
			outputList[0].unshift(last.pop());
	}

	return outputList;
}

//----------------------------------------------------------------------------------------------
//	
//----------------------------------------------------------------------------------------------
PolygonList.prototype.CalcOffsetPolygon_v6 = function(offsetDistance, options)
{
	var inputPolygonList = this;
	var outputPolygonList = new PolygonList();
	var latticeEdges = []; // 2022.02.09
	
	var debugLog = GetProperty(options, "debugLog", false);
	var catchExceptions = GetProperty(options, "catchExceptions", true);
	var centerLineClipToLattice = GetProperty(options, "centerLineClipToLattice", true); // 2022.03.03
	var constructLattice = GetProperty(options, "constructLattice", false);
	var constructMidLine = GetProperty(options, "renderMidLine", false); // 2022.03.03
	var midLineLocScale = GetProperty(options, "midLineLocScale", 0.50); // 2022.03.03
	var midLineLocOffset = GetProperty(options, "midLineLocOffset", 0.0); // 2022.03.03
	var midLineClipToLattice = GetProperty(options, "midLineClipToLattice", true); // 2022.03.03

	if (debugLog)
		console.log("CalcOffsetPolygon_v6, offsetDistance: " + offsetDistance);
	
	// Calc the offset polygon for each of the polygons in the list
	for (var p = 0; p < inputPolygonList.GetPolygonCount(); p++)
	{
		var ip = inputPolygonList.GetPolygonPoints(p);
		var single = {};
		if (catchExceptions)
			single = this.CalcOffsetPolygon_v6_SingleSafe(ip, offsetDistance, options);
		else
			single = this.CalcOffsetPolygon_v6_Single(ip, offsetDistance, options);
		
		if (single.offsetPolygonList != undefined)
		{
			for (var i = 0; i < single.offsetPolygonList.length; i++)
			{
				let op = single.offsetPolygonList[i];
				// 2021.04.12: Add "isStroke"; 2022.01.26: Add "isClosed"; 2022.03.01: Identify as "designEdge"
				outputPolygonList.AddPolygonPoints(op, {isStroke:true, isClosed:true, component:"designEdge"});
			}
		}

		// 2022.02.09: Accumulate lattice edges to allow post-processing before adding to output polygon list
		if (single.latticeEdges != undefined)
			single.latticeEdges.forEach(edge => latticeEdges.push(edge));

		if (single.areaPolygonList != undefined)
		{
			for (var i = 0; i < single.areaPolygonList.length; i++)
			{
				let ap = single.areaPolygonList[i];
				// 2021.04.12: Replace "area" with "isFill"; 2022.01.26: Add "isClosed"; 2022.03.01: Identify as "colorFill"
				// 2022.05.05: offsetEdgeIdx: Index to pt on offset (design) edge
				// 2022.05.18: isOuterFrame: polygon lines along outer frame
				outputPolygonList.AddPolygonPoints(ap.poly , {isFill:true, isClosed:true, component:"colorFill", color:ap.color, colorId:ap.colorId, offsetEdgeIdx:ap.offsetEdgeIdx, isOuterFrame:ap.isOuterFrame});
			}
		}
		
		if (single.bisectorList != undefined)
		{
			outputPolygonList.bisectors = single.bisectorList;
		}
		
		// 2022.03.01: Add centerLine to output, optionally clip to lattice edge
		let ctr = ip;
		// debug: ctr = MathUtil.CalcOffsetPolygon(ip, 0.1);
		if (ctr != undefined)
		{
			// If we don't have a lattice or we don't want to clip to the lattice, then add the polygon as a closed poly
			if (!constructLattice || !centerLineClipToLattice || single.latticeEdges == undefined || single.latticeEdges.length == 0)
			{
				outputPolygonList.AddPolygonPoints(ctr, {isStroke:true, isClosed:true, component:"centerLine"});
			}
			// Otherwise, calculate the intersections of the centerline edges with the lattice edges
			else
			{
				let latticeCenterLines = undefined;
				if (single.latticeEdges != undefined)
					latticeCenterLines = ClipPolygonToLatticeEdges(ctr, single.latticeEdges, {});

				if (latticeCenterLines != undefined)
				{
					for (var i = 0; i < latticeCenterLines.length; i++)
						outputPolygonList.AddPolygonPoints(latticeCenterLines[i], {isStroke:true, isClosed:false, component:"centerLine"});
				}
			}
		}

		// 2022.03.03: midLineOffset
		if (constructMidLine)
		{
			var midLine = {};
			var midLineOptions = Object.assign({}, options);
			Object.assign(midLineOptions, {adjustWeight:midLineLocScale, adjustOffset:midLineLocOffset});
			midLineOptions.buildAreaPolygons = false;
			midLineOptions.constructLattice = false;
			if (catchExceptions)
				midLine = this.CalcOffsetPolygon_v6_SingleSafe(ip, offsetDistance, midLineOptions);
			else
				midLine = this.CalcOffsetPolygon_v6_Single(ip, offsetDistance, midLineOptions);

			if (midLine != undefined && midLine.offsetPolygonList != undefined)
			{
				for (var i = 0; i < midLine.offsetPolygonList.length; i++)
				{
					let op = midLine.offsetPolygonList[i];
					// 2021.04.12: Add "isStroke"; 2022.01.26: Add "isClosed"; 2022.03.01: Identify as "designEdge"
					//outputPolygonList.AddPolygonPoints(op, {isStroke:true, isClosed:true, component:"designEdge"});
					if (!constructLattice || !midLineClipToLattice || single.latticeEdges == undefined || single.latticeEdges.length == 0)
					{
						outputPolygonList.AddPolygonPoints(op, {isStroke:true, isClosed:true, component:"midLine"});
					}
					// Otherwise, calculate the intersections of the centerline edges with the lattice edges
					else
					{
						let latticeCenterLines = undefined;
						if (single.latticeEdges != undefined)
							latticeCenterLines = ClipPolygonToLatticeEdges(op, single.latticeEdges, {});

						if (latticeCenterLines != undefined)
						{
							for (var i = 0; i < latticeCenterLines.length; i++)
								outputPolygonList.AddPolygonPoints(latticeCenterLines[i], {isStroke:true, isClosed:false, component:"midLine"});
						}
					}
				}
			}
		}
	} // for-loop-inputpolygonlist

	// 2022.02.09: Add lattice edges. This does nothing if the list is empty
	this.CoalesceLatticeEdges(latticeEdges);
	for (var i = 0; i < latticeEdges.length; i++)
	{
		let edge = latticeEdges[i].points;
		// This edge might have been moved to another edge, so check for zero length
		// 2022.03.01: Identify polygons as "latticeEdge"
		if (edge.length > 0)
			outputPolygonList.AddPolygonPoints(edge, {isStroke:true, isClosed:false, component:"latticeEdge"});
	}

	return outputPolygonList;
}

/*-----------------------------------------------*
 * Combine Parallel Adjacent Lines
 *-----------------------------------------------*/
PolygonList.prototype.CombineParallelAdjacentLines = function()
{
	var bounds = undefined;
	
	for (var k = 0; k < this.GetPolygonCount(); k++)
	{
		var p = this.GetPolygonPoints(k);
		
		var i = 0;
		while (i < p.length)
		{
			var i0 = i;
			var i1 = (i + 1) % p.length;
			var i2 = (i + 2) % p.length;
			
			var vA = {x:(p[i1].x - p[i0].x), y:(p[i1].y - p[i0].y)};
			var vB = {x:(p[i2].x - p[i1].x), y:(p[i2].y - p[i1].y)};
			
			// 2018.01.04: Set near-zero values to zero so Math.sign() tests below
			// work as expected. This addresses the issue where horizontal and vertical
			// lines are not always merged
			if (MathUtil.EqualWithinTolerance(vA.x, 0, NORMAL_TOLERANCE))
				vA.x = 0;
			if (MathUtil.EqualWithinTolerance(vA.y, 0, NORMAL_TOLERANCE))
				vA.y = 0;
			if (MathUtil.EqualWithinTolerance(vB.x, 0, NORMAL_TOLERANCE))
				vB.x = 0;
			if (MathUtil.EqualWithinTolerance(vB.y, 0, NORMAL_TOLERANCE))
				vB.y = 0;
			
			// Instead of vA.y/vA.x == vB.y/vB.x, use vA.y*vB.x == vB.y*vA.x to handle vA.x == 0 or vB.x == 0
			// The sign comparison test determines if the line segments are pointing in the same direction. It is
			// commented-out because it is not accurate if the delta X is very near zero for one and zero for the other
			var equalSlope = MathUtil.EqualWithinTolerance(vA.y * vB.x, vB.y * vA.x, NORMAL_TOLERANCE);
			if (!equalSlope)
				i++;
			else if ( (Math.sign(vA.x) == Math.sign(vB.x)) && (Math.sign(vA.y) == Math.sign(vB.y)))
				p.splice(i1, 1);
			else
				i++;
		}
	}
	
	return bounds;
}
/*-----------------------------------------------*
 * Find Bounds
 * returns: {min:{x:x, y:y}, max:{x:x, y:y}}
 * or undefined if no points in polygon
 *-----------------------------------------------*/
PolygonList.prototype.FindBounds = function()
{
	var bounds = undefined;
	
	for (var i = 0; i < this.GetPolygonCount(); i++)
	{
		var p = this.GetPolygonPoints(i);
		bounds = Polygon_FindBounds(p, bounds);
	}
	
	return bounds;
}


export { BasicUnits, BasicUnitsMgr };
export { MathUtil };
export { Math3D };
export { Shapes3D };
export { Transform };
export { Projection3D };
export { PolygonMiterType };
export { PolygonList, SegmentList, Polygon_FindBounds, Polygon_CalcCentroid };
export { SegmentIntersectionHandling };
export { NORMAL_TOLERANCE };
export { VectorCornerStyle };
