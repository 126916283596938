// SystemMgr.js

// Uncomment to enable AWS Amplify debugging app launch
// window.LOG_LEVEL = 'DEBUG';

// AWS Amplify
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Analytics from '@aws-amplify/analytics';
import API from '@aws-amplify/API';
import Storage from '@aws-amplify/storage';
import aws_exports from "../../aws-exports";
Amplify.configure(aws_exports);

// 2019.02.07: Added when the "missing config" error started showing up. 
// 2019.04.10: Still needed for DynamoDB
AWS.config.update({region: aws_exports.aws_project_region});


// Main design app and support libraries
import { SystemAnalytics } from "./SystemAnalytics.js";
import { ScreenDesigner } from "./ScreenDesigner.js";
import { ScreenDesignerAccount } from "./ScreenDesignerAccount.js";
import { AuthManager } from "../../AmplifyAuthenticationMgr/src/AmplifyAuthenticationMgr.js";
import { AccountManager } from "../../AmplifyAuthenticationMgr/src/AmplifyAccountMgr.js";
import { StorageManager } from "../../AmplifyStorageMgr/src/AmplifyStorageMgr.js";
import { PaymentManager } from "../../StripePaymentMgr/src/StripePaymentMgr.js";
import { LightBox } from "./LightBox.js";
import { ColorPicker } from "./ColorPicker.js";
import { PaletteMenu } from "./PaletteMenu.js";


//------------------------------------------------------------------------------
//	Examples and Backgrounds
//------------------------------------------------------------------------------
/* These need to go into a configuration object for the BackgroundFader module */
var PolygoniaBackgroundPatternCount = 12;
var BackgroundFadeSteps = 20;
var BackgroundHoldSteps = 50;
var BackgroundFadeTime = 100; /* ms */

//------------------------------------------------------------------------------
//	Utilities
//------------------------------------------------------------------------------
function SystemGetElement(eid)
{
	var e = document.getElementById(eid);
	if (e == undefined)
		console.log("Element '" + eid + "' not found");
		
	return e;
}

//------------------------------------------------------------------------------
//	System Manager
//
//	Main entrypoint for the application
//	Manages overall system state
//		Unauthenticated vs Authenticated
//------------------------------------------------------------------------------
var SystemMgr = (function() {
	//
	var SystemState = Object.freeze({ 
		UNAUTHENTICATED: 		0, 
		AUTHENTICATED: 			1 });

	var sysState = SystemState.UNAUTHENTICATED;
	var appLoaded = false;
	var urlSearchParams = {};
	var documentHasFocus = undefined;
	
	//------------------------------------------------------------------------------
	//	Main entry point for the application
	//------------------------------------------------------------------------------
	var SystemMgr_Init = function()
	{
		// 2019.05.13: Keep track of when we lose focus of the window so we can check for
		// authentications in another window
		documentHasFocus = document.hasFocus();
		
		SystemMgr_ParseURL();
		
		SystemMgr_AppendBuildInfoToVersion();
		
		// 2018.12.28: Disable background fader because of memory leak
		//BackgroundFader.Init();
		//var e = document.getElementById("ID_Body");
		//e.addEventListener("mouseup",  SystemMgr_HoldBackgroundImage);

		SystemMgr_UpdateUI();
		
		//console.dir(aws_exports);
		if (!SystemMgr_IsSupportedBrowser())
			SystemMgr_DisplayUnsupportedBrowserMessage();
			
		// 2019.09.17: Test for explicitly not supported browsers
		let explicitlyNotSupported = SystemMgr_IsExplicitlyNotSupported();
		
		// For now, always let it run, even if it might not work on the browser.
		if (!explicitlyNotSupported)
		{
		
			SystemMgr_ConnectUI();
		
			// Find the full name of the "AccountInfo" table. Note that the name of the table
			// is not important, but having 'userId' as the key is required
			// We could determine whether or not to use the account manager based on the existence
			// of the AccountInfo table.
			let schemas = aws_exports.aws_dynamodb_table_schemas; // for readability
			var schema = (schemas != undefined) ? schemas.find(a => a.tableName.includes("AccountInfo")) : undefined; 
			var tableName = (schema != undefined) ? schema.tableName : undefined;
		
			let acctMgrConfig = 
			{
				acctTableName: tableName,
				schemaAdditions: ScreenDesignerAccount.GetSchemaAdditions()
			};
		
			AccountManager.Init(acctMgrConfig);
	
			let authMgrConfig = 
			{
				authSignOut: SystemMgr_AuthSignOut, 
				authSignIn: SystemMgr_AuthSignIn, 
				analyticsRecord: SystemAnalytics.Record, 
				accountPreDelete: SystemMgr_PreDeleteAccount,
				accountManager: AccountManager,
				accountInfo: ScreenDesignerAccount
			 };
			
			AuthManager.Init(authMgrConfig);

			var stripePaymentMgrConfig = 
			{
				use: "Elements",
				//stripeKey: "", -- Load key from server
				imageURI: "./rsrcs/logo.png",
				businessName: "Polygonia Designs LLC"
			};
		
			PaymentManager.Init(stripePaymentMgrConfig);
		
			ScreenDesignerAccount.Init();
			
			// Primarily for checking for promo codes
			SystemMgr_HandleSearchParams();
		}
		else
		{
			SystemMgr_DisplayUseAnotherBrowserMessage();
			SystemMgr_HideCTAElements();
		}
		
		//LightBox.Init();
		// Load the gallery after a moment
		//setTimeout(SystemManager_LoadGallery, 500);
		
		ColorPicker.Init();
		PaletteMenu.Init();
		
		// 2019.10.14: Start the animation gif one second after everything is loaded
		// and only if we are probably not on mobile
		if (window.screen.width >= 600)
			setTimeout(SystemMgr_LoadGIFs, 1000);
		
	}
	
	//------------------------------------------------------------------------------
	//	Load GIFs
	//------------------------------------------------------------------------------
	var SystemMgr_LoadGIFs = function()
	{
		var img = document.getElementById("ID_EditorAnimation");
		var src = img.getAttribute('data-src');
		if (src != undefined)
			img.setAttribute('src', src);
	}
	
	//------------------------------------------------------------------------------
	//	Is Supported Browser
	//------------------------------------------------------------------------------
	var SystemMgr_IsSupportedBrowser = function()
	{
		var isSupported = false;
		
  		var supported = ["Firefox", "Safari", "Chrome", "Opera"];

  		for (var idx = 0; idx < supported.length && !isSupported; idx++)
  			isSupported = (navigator.userAgent.indexOf(supported[idx]) != -1);
  	
		return isSupported;
	}

	//------------------------------------------------------------------------------
	//	Is Explicitly Not Supported
	//
	//		Explicitly test for browsers that are not supported.
	//		Add to this list as problem browsers are confirmed
	//------------------------------------------------------------------------------
	var SystemMgr_IsExplicitlyNotSupported = function()
	{
		var ua = navigator.userAgent;
		
		var explicitlyNotSupported = false;

		// IE 10 or older?
		var msie = ua.indexOf('MSIE ');
		if (msie > 0)
			explicitlyNotSupported = true;

		// IE 11?
		var trident = ua.indexOf('Trident/');
		if (trident > 0)
			explicitlyNotSupported = true;

		// Edge is OK
		//var edge = ua.indexOf('Edge/');
		//if (edge > 0)
		//{
		//}

		return explicitlyNotSupported;
	}

	//------------------------------------------------------------------------------
	//	Display Unsupported Browser Message
	//------------------------------------------------------------------------------
	var SystemMgr_DisplayUnsupportedBrowserMessage = function()
	{
		var e = SystemGetElement("ID_UnsupportedMessageArea");	
		e.classList.remove("CL_HideElement");
	}
	
	//------------------------------------------------------------------------------
	//	Display "Use Another" Browser Message
	//------------------------------------------------------------------------------
	var SystemMgr_DisplayUseAnotherBrowserMessage = function()
	{
		var e = SystemGetElement("ID_UseAnotherBrowserMessageArea");	
		e.classList.remove("CL_HideElement");
	}
	
	//------------------------------------------------------------------------------
	//	Handle Search Params
	//------------------------------------------------------------------------------
	var SystemMgr_HandleSearchParams = function()
	{
		if (urlSearchParams.hasOwnProperty("promo") && urlSearchParams["promo"] != undefined)
			ScreenDesignerAccount.HandlePromoCode(urlSearchParams["promo"]);
	}

	//------------------------------------------------------------------------------
	//	Load Gallery
	//------------------------------------------------------------------------------
	var SystemManager_LoadGallery = function()
	{
		var images = [];
		for (var i = 1; i <= 46; i++)
		{
			let N = ("000" + i).slice(-3);
			images.push({image:"photos/resized/img"+N+".JPG", thumb:"photos/thumbnails/img"+N+".png"});
		}
		LightBox.CreateGallery(images);
	}
	
	//------------------------------------------------------------------------------
	//	Parse URL
	//		Record analytics event if "redirect" is found
	//------------------------------------------------------------------------------
	var SystemMgr_ParseURL = function()
	{
		var hash = window.location.hash.substr(1); // Skip past the '#'
		var promoCode = undefined;

		if (hash.length > 0)
		{
			var hashParams = hash.split('&');
		
			for (var i = 0; i < hashParams.length; i++)
			{
				var paramPair = hashParams[i].split('=');
				var key = paramPair[0];
				var value = paramPair[1];
			
				if (key == "redirect")
					SystemAnalytics.Record("Redirect", {from:value} );
			}

			window.location.hash = "";
		}
		
		// 2019.04.30: Check for query parameters
		var search = window.location.search.substr(1); // Skip past the '?'
		if (search.length > 0)
		{
			var searchParams = search.split('&');
		
			for (var i = 0; i < searchParams.length; i++)
			{
				var paramPair = searchParams[i].split('=');
				var key = paramPair[0];
				var value = paramPair[1];
				
				urlSearchParams[key] = value;
				
				if (key == "promo")
				{
					SystemAnalytics.Record("Promo", {from:value} );
					promoCode = value;
				}

				// Track if anyone used the QR code
				if (key == "QR")
				{
					SystemAnalytics.Record("QR");
					// For the time being, if anyone uses the QR code on the business card, convert it to a "MAKERFAIRE" promo
					// 2019.09.26: Use a promo code to indicate that the business card was used
					promoCode = "WELCOME";
					urlSearchParams["promo"] = promoCode;
				}
			}

			// Remove the search options from the URL
			var url = window.location.origin + window.location.pathname;

			// But leave the promo code, until it is explicitly cleared
			// This allows people to share the URL
			if (promoCode != undefined)
				url += "?promo=" + promoCode;

			window.history.replaceState({}, document.title, url);
		}
	}

	//------------------------------------------------------------------------------
	//	Append Build Info to Version
	//		Indicate if this build is from the Dev or Test MobileHub projects
	//------------------------------------------------------------------------------
	var SystemMgr_AppendBuildInfoToVersion = function()
	{
		var suffix = undefined;
		const polygonia = "Polygonia";
		
		// Get the MobileHub project name
		var projectName = aws_exports["aws_project_name"];
		
		// If the project name does not start with "Polygonia", then
		// show the entire project name. (This is a just-in-case.)
		if (!projectName.startsWith(polygonia))
			suffix = "-" + projectName;
		// If the project name is longer then "Polygonia", then show
		// the suffix of the project name. We are expecting "Dev" or "Test"
		else if (projectName.length > polygonia.length)
			suffix = "-" + projectName.slice(polygonia.length);

		// Update the version string if we have a suffix		
		if (suffix != undefined)
		{
			var e = document.getElementById("ID_Version");
			e.innerHTML += suffix;
		}
	}

	//------------------------------------------------------------------------------
	//	Authentication Sign Out Callback
	//------------------------------------------------------------------------------
	var SystemMgr_AuthSignOut = function()
	{
		//console.log("SystemMgr_AuthSignOut");
		SystemMgr_UpdateState(SystemState.UNAUTHENTICATED);
	}

	//------------------------------------------------------------------------------
	//	Authentication Sign In Callback
	//------------------------------------------------------------------------------
	var SystemMgr_AuthSignIn = function()
	{
		//console.log("SystemMgr_AuthSignIn");
		SystemMgr_UpdateState(SystemState.AUTHENTICATED);
		
		// Perform any initialization appropriate after user has signed-in
		// (Really, this is just to get the stripe public key)
		PaymentManager.PostSignInInit();
	}

	//------------------------------------------------------------------------------
	//	Pre-Delete Account 
	//
	//		Called by authentication manager when the user deletes their account
	//		This function is responsible for deleting the user's data files
	//------------------------------------------------------------------------------
	/*
		const reflect = p => p.then(v => ({v, status: "fulfilled" }),
									e => ({e, status: "rejected" }));

		reflect(promise).then((v => { console.log(v.status); });
	*/
	
	// Converts failed promises into successful promises with a status property of 'rejected'
	const reflect = (p, desc) => p.then(v => ({v, status: "fulfilled", desc:desc }),
								e => ({e, status: "rejected", desc:desc }));

	var SystemMgr_PreDeleteAccount = function()
	{
		var storageMgrDeletePromise = StorageManager.DeleteAllFiles();
		var cancelSubscriptionPromise = ScreenDesignerAccount.CancelSubscription();
		var cancelAccountPromise = ScreenDesignerAccount.CancelAccount();
		var accountMgrDeletePromise = AccountManager.DeleteAccount();
		
		var tasks = [
			reflect(storageMgrDeletePromise, "StorageMgrDelete"),
			reflect(cancelSubscriptionPromise, "cancelSubscription"),
			reflect(cancelAccountPromise, "cancelAccount"),
			reflect(accountMgrDeletePromise, "accountMgrDelete")
		];
		
		// https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
		// Promises execute in sequence
		var orderedPromiseExecution = tasks.reduce(
				(promiseChain, currentTask) => promiseChain.then(chainResults => currentTask.then(currentResult => [ ...chainResults, currentResult ]) ),
				Promise.resolve([])
			);	

		return orderedPromiseExecution;
			
				
		// return Promise.all(tasks);
	}
	
	//------------------------------------------------------------------------------
	//	Connect UI
	//------------------------------------------------------------------------------
	var SystemMgr_ConnectUI = function()
	{
		var e = SystemGetElement("ID_Pricing");
		
		e.onclick = function() { ScreenDesignerAccount.ShowSubscriptionInfo(true) };
		e.classList.remove("CL_HideElement");

		// Connect the "call to action" buttons to "start sign up"
		var ctaElements = document.getElementsByClassName("CL_CTA-signup");
		for (var i = 0; i < ctaElements.length; i++)
			ctaElements[i].addEventListener("click", AuthManager.StartSignUp);
			
		// 2019.05.08: Get notifications when the windows changes focus
		document.onvisibilitychange = SystemMgr_DocumentFocusChanged;
		window.addEventListener("blur", SystemMgr_DocumentFocusChanged);
		window.addEventListener("focus", SystemMgr_DocumentFocusChanged);
	}
	
	//------------------------------------------------------------------------------
	//	 Hide CTA Elements
	//------------------------------------------------------------------------------
	var SystemMgr_HideCTAElements = function()
	{
		var e = SystemGetElement("ID_Pricing");
		
		// Hide the "call to action" buttons
		var ctaElements = document.getElementsByClassName("CL_CTA-signup");
		for (var i = 0; i < ctaElements.length; i++)
			ctaElements[i].classList.add("CL_HideElement");
	}
	
	//------------------------------------------------------------------------------
	//	Document Focus Changed
	//------------------------------------------------------------------------------
	var SystemMgr_DocumentFocusChanged = function(evt)
	{
		//console.log("Focus or visibility of page has changed. (" + evt.type + "), document.hasFocus(): " + document.hasFocus());
		var hasFocus = document.hasFocus();
		if (documentHasFocus != hasFocus)
		{
			documentHasFocus = hasFocus;
			AuthManager.HandleFocusChange(hasFocus);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Update UI to match system state
	//------------------------------------------------------------------------------
	var SystemMgr_UpdateUI = function()
	{
		//console.log("SystemMgr_UpdateUI");
		var e = SystemGetElement("ID_CenterNotAuthorized");
		if (sysState == SystemState.UNAUTHENTICATED)
			e.style.removeProperty("display");
		else
			e.style.display = "none";
			
		var e = SystemGetElement("ID_CenterAuthorized");
		if (sysState == SystemState.UNAUTHENTICATED)
			e.style.display = "none";
		else
			e.style.removeProperty("display");

		// Hide the logo text when not logged in, since it is displayed in
		// the center area
		/*
		var e = SystemGetElement("ID_LogoText");
		if (sysState == SystemState.UNAUTHENTICATED)
			e.style.display = "none";
		else
			e.style.removeProperty("display");
		*/
		
		// Show a background image when not logged in
		SystemMgr_ShowBackgroundImage(sysState == SystemState.UNAUTHENTICATED);
	}
	

	//------------------------------------------------------------------------------
	//	Hold background image
	//		Command-click on the body will clear the background image and stop
	//		the timer. This is to make it easier to work with the element
	//		inspector.
	//------------------------------------------------------------------------------
	var SystemMgr_HoldBackgroundImage = function(evt)
	{
		//console.log("SystemMgr_HoldBackgroundImage: this.id:" + this.id + ", evt.target.id:" + evt.target.id);
		if (this.id == "ID_Body" && (evt.target.id == "ID_Body" || evt.target.id == "ID_TopBanner") 
			&& evt.metaKey && sysState == SystemState.UNAUTHENTICATED)
		{
			SystemMgr_ShowBackgroundImage(false);
		}
	}

	//------------------------------------------------------------------------------
	//	Add background repeating pattern
	//------------------------------------------------------------------------------
	var SystemMgr_ShowBackgroundImage = function(showBackgroundImage)
	{
		//BackgroundFader.ShowBackgroundImage(showBackgroundImage);
	}
	
	
	//------------------------------------------------------------------------------
	//	Remove all private data from the application
	//------------------------------------------------------------------------------
	var SystemMgr_ClearPrivateData = function()
	{
		// 
		//
		ScreenDesigner.Close();
		
		ScreenDesignerAccount.Reset();
	}
	
	//------------------------------------------------------------------------------
	//	Load application as needed
	//------------------------------------------------------------------------------
	var SystemMgr_LoadApplication = function()
	{
		//console.log("SystemMgr_LoadApplication");
		if (!appLoaded)
		{
			// 2021.08.03: Added appConfig to pass info from aws-exports
			let appConfig = { s3_bucket: aws_exports.aws_user_files_s3_bucket };
			ScreenDesigner.Init(appConfig);
			appLoaded = true;
		}
		
		ScreenDesigner.Open();
	}
	
	//------------------------------------------------------------------------------
	//	Application has been revealed
	//------------------------------------------------------------------------------
	var SystemMgr_ApplicationRevealed = function()
	{
		//console.log("SystemMgr_ApplicationRevealed");
		ScreenDesignerAccount.Update();

		ScreenDesigner.Revealed();
	}
	
	//------------------------------------------------------------------------------
	//	Update system state
	//		Transition the system between states
	//------------------------------------------------------------------------------
	var SystemMgr_UpdateState = function(newState)
	{
		sysState = newState;
		
		if (sysState == SystemState.AUTHENTICATED)
			SystemMgr_LoadApplication();
		
		SystemMgr_UpdateUI();
		
		if (sysState == SystemState.AUTHENTICATED)
			SystemMgr_ApplicationRevealed();
		
		if (sysState == SystemState.UNAUTHENTICATED)
			SystemMgr_ClearPrivateData();
	}

	//------------------------------------------------------------------------------
	//	Test authentication toggle
	//		TEST FUNCTION
	//		Toggles the system authentication state
	//------------------------------------------------------------------------------
	var SystemMgr_AuthToggleTest = function()
	{
		var newState = undefined;
		
		if (sysState == SystemState.UNAUTHENTICATED)
		{
			newState = SystemState.AUTHENTICATED;
		}
		else if  (sysState == SystemState.AUTHENTICATED)
		{
			newState = SystemState.UNAUTHENTICATED;
		}
		else
		{
			console.log("SystemMgr_AuthToggleTest: Unknown sysState:" + sysState);
		}
		
		if (newState != undefined && newState != sysState)
		{
			SystemMgr_UpdateState(newState);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Build Examples Gallery
	//
	//	<div>
	//		<a href="xxx" target="_blank">
	//			<span>Coloring Page</span>
	//			<img src="examples/ex01_ColoringPage.jpg"/>
	//		</a>
	//	</div>
	//------------------------------------------------------------------------------
	var PolygoniaExamples =
		[
			{ img:"ColoringPage.jpg", desc:"Coloring Page", href:"https://polygoniadesigns.com/color-me-001/" },
			{ img:"PencilBoxes.JPG", desc:"Pencil Boxes", href:"https://polygoniadesigns.com/pencil-boxes-in-1-8-plywood/" },
			{ img:"PencilBag_WaterLily_Render.jpg", desc:"Pencil Bag", href:"https://artofwhere.com/artists/davidkaufman/accessories/pencil-case/1253645" },
			{ img:"MakeupBag_TriHex_Render.jpg", desc:"Makeup bag", href:"https://artofwhere.com/artists/davidkaufman/accessories/makeup-bag/1226069" }
		];

	var SystemMgr_BuildExamplesGallery = function()
	{
		var galleryDiv = SystemGetElement("ID_ExamplesGallery");
		
		for (var i = 0; i < PolygoniaExamples.length; i++)
		{
			var ex = PolygoniaExamples[i];
			
			var exampleDiv = document.createElement('div');
			//itemContainer.style.width  = (galleryItemDim.size.x + galleryItemDim.margin * 2) + "px";
			//itemContainer.style.height = (galleryItemDim.size.y + galleryItemDim.margin * 2) + "px";
		
			var exampleImage = document.createElement('img'); 
			exampleImage.src = "examples/" + ex.img;
			//designImage.style.width  = (galleryItemDim.size.x) + "px";
			//designImage.style.height = (galleryItemDim.size.y) + "px";
			
			var exampleLink = undefined;
			if (ex.href != undefined)
			{
				exampleLink = document.createElement('a'); 
				exampleLink.href = ex.href;
				exampleLink.target = "_blank";
			}
			else
			{
				exampleLink = document.createElement('anull');
			}
			
			var exampleDesc = undefined;
			if (ex.desc != undefined)
			{
				exampleDesc = document.createElement('span'); 
				exampleDesc.innerHTML = ex.desc;
			}
			
			if (exampleDesc != undefined)
				exampleLink.appendChild(exampleDesc);
			exampleLink.appendChild(exampleImage);
			exampleDiv.appendChild(exampleLink);
			galleryDiv.appendChild(exampleDiv);		
		}
	}

	//------------------------------------------------------------------------------
	//	Public API
	//------------------------------------------------------------------------------
	return {
		Init:		SystemMgr_Init
	}
	
}());

//------------------------------------------------------------------------------
//	Launch System Mgr
//
//	Main entrypoint for the application from HTML
//	Calls SystemMgr
//------------------------------------------------------------------------------
function LaunchSystemMgr()
{
	// Main entry point for the application. 
	SystemAnalytics.Record("LaunchSystemMgr");
	SystemMgr.Init();
}; 

//------------------------------------------------------------------------------
//	Google Authentication
//
//	
//------------------------------------------------------------------------------
function SystemMgr_Google_OnSuccess(googleUser) 
{
	//console.log('SystemMgr_Google_OnSuccess: Logged in as: ' + googleUser.getBasicProfile().getName());
	AuthManager.Google_SignInSuccess(googleUser);
}

function SystemMgr_Google_OnFailure(error)
{
	//console.log("SystemMgr_Google_OnFailure");	
	//console.log(error);
	AuthManager.Google_HandleError(error);

}

global.SystemMgr_Google_OnFailure = SystemMgr_Google_OnFailure;
global.SystemMgr_Google_OnSuccess = SystemMgr_Google_OnSuccess;

//------------------------------------------------------------------------------
//	Window OnLoad
//
//	Calls LaunchSystemMgr
//------------------------------------------------------------------------------
window.addEventListener("load", LaunchSystemMgr);
