
import { ScreenRenderer } from "./ScreenRenderer.js";
import { Transform } from "../../VectorUtilsJS/src/VectorUtilLib.js";
import { ScreenGenerator, DesignType } from "./ScreenDesignGenerator.js";
import { ScreenDesigner } from "./ScreenDesigner.js"; // 2019.02.19

/*-----------------------------------------------*
 * Other Utility Functions
 *-----------------------------------------------*/
function getMousePos(aCanvas, evt) {

	var rect = aCanvas.getBoundingClientRect();
	var mouseX = (evt.changedTouches ? evt.changedTouches[0].clientX : evt.clientX) - rect.left; //this.offsetLeft;
	var mouseY = (evt.changedTouches ? evt.changedTouches[0].clientY : evt.clientY) - rect.top; //this.offsetTop;
	var insetX = 1; // These match the border on the canvas. Hardcoding is less
	var insetY = 1; // expensive than computing the style
	return {
		x: mouseX - insetX,
		y: mouseY - insetY
	};
}

function getTouchPos(aCanvas, touch) {

	var rect = aCanvas.getBoundingClientRect();
	var mouseX = (touch.clientX) - rect.left;
	var mouseY = (touch.clientY) - rect.top;
	var insetX = 1; // These match the border on the canvas. Hardcoding is less
	var insetY = 1; // expensive than computing the style
	return {
		x: mouseX - insetX,
		y: mouseY - insetY
	};
}

var ignoreMetaKeyFlag = undefined;
function ignoreMetaKey()
{
	if (ignoreMetaKeyFlag == undefined)
	{
		var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
		ignoreMetaKeyFlag = (macosPlatforms.indexOf(window.navigator.platform) === -1);
	}

	return ignoreMetaKeyFlag;
}


function AnimatedZoom(startZoom, endZoom, interval, steps, updateFunc, completedFunc)
{
    this.startZoom = startZoom.Clone();
	this.endZoom   = endZoom.Clone();
	this.interval  = interval;
	this.count     = steps;
	this.step      = 0;
    this.callbackFunc  = updateFunc;
    this.completedFunc = completedFunc;
    this.timer = setTimeout(this.TimerFunc, this.interval /*ms*/, this);
}

AnimatedZoom.prototype.Stop = function(targetZoom)
{
	if (this.timer != undefined)
	{
		clearTimeout(this.timer.timer);
		this.timer = undefined;
	}
}
	
AnimatedZoom.prototype.TimerFunc = function(context)
{
	context.step++;
	var zoom = context.startZoom.CalcTransformTo(context.endZoom, context.step/context.count);

	if (context.callbackFunc != undefined)
	{
		let startMs = Date.now();
		
		context.callbackFunc(zoom);
		
		// 2021.03.16: Reduce steps according to rendering time
		let elapsed = Date.now() - startMs;
		// If rendering took longer than the interval and we are not done,
		// then skip some steps, but we must always do the last step, because
		// that is the target zoom
		if (elapsed > context.interval && context.step < context.count)
		{
			let skip = Math.floor(elapsed/context.interval);
			context.step += skip;
			if (context.step >= context.count)
				context.step = context.count - 1;
		}
	}
	
	if (context.step < context.count)
	{
		context.timer = setTimeout(context.TimerFunc, context.interval /*ms*/, context);
	}
	else
	{
		context.timer = undefined;
		if (context.completedFunc != undefined)
			context.completedFunc(context);
	}
}
	

// Edit Manager (singleton)
var ScreenDesignerCanvas = (function() {

	/* Local variables */
	var sdCanvas = undefined;
	var sdContext = undefined;	
	
	var sdZoom = undefined; //new Transform();
	var sdAnimatedZoom = undefined;
	var sdMargin = 20;
	
	var sdDesignRender = undefined;

	var sdTracking = undefined;
	
	var sdHighlight = {centerHighlight:[], offsetHighlight:[]};
	
	var sdTileHighlight = [];

	var sdProperties = { selectPolygon: false };
	
	var ZoomTo = Object.freeze({
	FRAME			: 0,
	ONE_TO_ONE		: 1,
	SMALLER_DIM		: 2	// 2021.07.15: The smaller of the width or height of the bounds
	});

	var SketchMode = Object.freeze({ // 2021.05.13
	SOLID_TILE			: 0,
	HOLLOW_TILE			: 1,
	TILE_EDGE			: 2,
	ERASE_TILE			: 3,
	ERASE_EDGE			: 4
	});

	var sdSketchMode = SketchMode.SOLID_TILE;

	var ScreenDesignerCanvas_Init = function(canvasID)
	{
		sdCanvas = document.getElementById(canvasID);
		sdContext = sdCanvas.getContext("2d");
		sdZoom = new Transform();

		if (sdCanvas == undefined || sdContext == undefined)
			console.log("ScreenDesignerCanvas_Init: the canvas or context could not be found");
		else
		{
			sdCanvas.width = sdCanvas.clientWidth;
			sdCanvas.height = sdCanvas.clientHeight;
			
			ScreenDesignerCanvas_ZoomToFrame();

			sdCanvas.addEventListener("wheel",      ScreenDesignCanvas_MouseWheel_priv,  {passive: true});

			sdCanvas.addEventListener("mousedown",  evt => ScreenDesignCanvas_HandleMouse(evt, "mousedown"),  false);
			sdCanvas.addEventListener("mousemove",  evt => ScreenDesignCanvas_HandleMouse(evt, "mousemove"),  false);
			sdCanvas.addEventListener("mouseup",    evt => ScreenDesignCanvas_HandleMouse(evt, "mouseup"),    false);
			sdCanvas.addEventListener("mouseleave", evt => ScreenDesignCanvas_HandleMouse(evt, "mouseleave"), false);

			// 2019.03.11: Added touch handling
			sdCanvas.addEventListener("touchstart",  evt => ScreenDesignCanvas_HandleTouch(evt, "touchstart"),  false);
			sdCanvas.addEventListener("touchmove",   evt => ScreenDesignCanvas_HandleTouch(evt, "touchmove"),   false);
			sdCanvas.addEventListener("touchend",    evt => ScreenDesignCanvas_HandleTouch(evt, "touchend"),    false);
			sdCanvas.addEventListener("touchcancel", evt => ScreenDesignCanvas_HandleTouch(evt, "touchcancel"), false);
			
			// 2020.10.07: Added context menu
			sdCanvas.addEventListener("contextmenu", evt => ScreenDesignCanvas_HandleContextMenu(evt, "contextmenu"), false);
		}
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Set Property
	 *		2020.10.07: Added
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignerCanvas_SetProperty = function(property, value)
	{
		if (!(property in sdProperties))
			console.log("ScreenDesignerCanvas_SetProperty: '" + property + "' not in sdProperties: " + JSON.stringify(sdProperties, 0, 2));
		
		sdProperties[property] = value;
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Handle Mouse
	 *		Dispatch to routine according to design type
	 *		2021.05.11: Created to handle different design types easily
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignCanvas_HandleMouse = function(evt, mouseAction)
	{
		if (sdDesignRender.designData.designType == DesignType.TYPE_GRID_SKETCH)
			ScreenDesignCanvas_HandleMouse_Sketch(evt, mouseAction);
		else
			ScreenDesignCanvas_HandleMouse_Generic(evt, mouseAction);
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Handle Mouse: Generic
	 *		Common routine to process all mouse events
	 *		2021.05.11: Renamed
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignCanvas_HandleMouse_Generic = function(evt, mouseAction)
	{
		var mousePt = getMousePos(sdCanvas, evt);
	
		if (mouseAction == "mousedown")
		{
			sdTracking = mousePt;
			sdCanvas.style.cursor = "grabbing";
			
			if (evt.altKey && sdProperties.selectPolygon)
				ScreenDesignCanvas_ExportSelectedPolygons();
		}
		else if (mouseAction == "mousemove")
		{
			if (sdTracking != undefined)
			{
				var delta = {x: mousePt.x - sdTracking.x, y: mousePt.y - sdTracking.y};

				sdZoom.offset.x += delta.x;
				sdZoom.offset.y += delta.y;
				
				ScreenDesignerCanvas_Render();
				
				sdTracking.x = mousePt.x;
				sdTracking.y = mousePt.y;
			}
			else
			{
				// 2020.10.06: Track the polygon (edge) under the mouse
				// Note that this routine is not very efficient.
				if (sdProperties.selectPolygon && sdDesignRender != undefined && sdDesignRender.offsetPolyList != undefined)
				{
					sdCanvas.style.cursor = "default";
					let pt = sdZoom.reverse_xfrm(mousePt);
					let d = sdZoom.reverse_scaleNum(3);
					
					// Quick comparison
					let oldStr = JSON.stringify(sdHighlight);
					
					let offsetHighlight = sdDesignRender.offsetPolyList.FindPolygonEdgeUnderPoint(pt, d);
					let centerHighlight = [];
					
					if (sdDesignRender.designData.diags.showCenterPoly)
						centerHighlight = sdDesignRender.centerPolyList.FindPolygonEdgeUnderPoint(pt, d);				
						
					let oldCount = sdHighlight.offsetHighlight.length;
					
					sdHighlight.offsetHighlight = offsetHighlight;
					sdHighlight.centerHighlight = centerHighlight;

					let newStr = JSON.stringify(sdHighlight);
					
					if (newStr != oldStr)
						ScreenDesignerCanvas_Render();
				}
				else
				{
					sdCanvas.style.cursor = "grab";
				}
			}
		}
		else if (mouseAction == "mouseup" || mouseAction == "mouseleave")
		{
			sdTracking = undefined;
			sdCanvas.style.cursor = "grab";
		}
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Handle Mouse: Sketch
	 *		Routine to process all mouse events for Sketch designs
	 *		2021.05.11: Created
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignCanvas_HandleMouse_Sketch = function(evt, mouseAction)
	{
		var mousePt = getMousePos(sdCanvas, evt);
	
		if (mouseAction == "mousedown")
		{
			if (evt.metaKey)
			{
				sdTracking = mousePt;
				sdCanvas.style.cursor = "grabbing";
			}
			else
			{
				if (sdTileHighlight.length > 0)
				{
					ScreenDesigner.UpdateSketch(sdTileHighlight, sdSketchMode);
					sdTileHighlight = [];
				}
			}
			
		}
		else if (mouseAction == "mousemove")
		{
			if (sdTracking != undefined)
			{
				var delta = {x: mousePt.x - sdTracking.x, y: mousePt.y - sdTracking.y};

				sdZoom.offset.x += delta.x;
				sdZoom.offset.y += delta.y;
				
				ScreenDesignerCanvas_Render();
				
				sdTracking.x = mousePt.x;
				sdTracking.y = mousePt.y;
			}
			else
			{
				if (sdDesignRender != undefined && sdDesignRender.tilePolylist != undefined)
				{
					sdCanvas.style.cursor = "default";
					let pt = sdZoom.reverse_xfrm(mousePt);
					let d = sdZoom.reverse_scaleNum(3);
					
					
					let tileHighlight = sdDesignRender.tilePolylist.FindPolygonsUnderPoint(pt);
					
					if (JSON.stringify(tileHighlight) != JSON.stringify(sdTileHighlight))
					{
						sdTileHighlight = tileHighlight;
						ScreenDesignerCanvas_Render();
					}
				}
				else
				{
					sdCanvas.style.cursor = "grab";
				}
			}
		}
		else if (mouseAction == "mouseup")
		{
			sdTracking = undefined;
			sdCanvas.style.cursor = "grab";
		}
		else if (mouseAction == "mouseleave")
		{
			sdTracking = undefined;
			if (sdTileHighlight.length > 0)
			{
				sdTileHighlight = [];
				ScreenDesignerCanvas_Render();
			}
			
			sdCanvas.style.cursor = "grab";
		}
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Handle Touch
	 *		Common routine to process all touch events
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignCanvas_HandleTouch = function(evt, touchAction)
	{
		function findTouch(identifier, touchList)
		{
			//var touch = touchList.find( t => t.identifier == identifier );
			var touch = undefined;
			for (var i = 0; i < touchList.length && touch == undefined; i++)
				if (touchList[i].identifier == identifier)
					touch = touchList[i];
					
			return touch;
		}

		// Prevent browser from doing things
		evt.preventDefault();
		//console.log(touchAction, "changedTouches:", evt.changedTouches.length);
		if (touchAction == "touchstart")
		{
			if (sdTracking == undefined)
			{
				var touch = evt.changedTouches[0];
				var touchPt = getTouchPos(sdCanvas, touch);
				touchPt.identifier = touch.identifier;

				sdTracking = touchPt;
				sdCanvas.style.cursor = "grabbing";
			
				//console.log(touchAction, JSON.stringify(sdTracking));
			}
		}
		else if (touchAction == "touchmove")
		{
			if (sdTracking != undefined)
			{
				// Find the touch we started with
				var trackingTouch = findTouch(sdTracking.identifier, evt.changedTouches);
				
				if (trackingTouch != undefined)
				{
					var touchPt = getTouchPos(sdCanvas, trackingTouch);
					var delta = {x: touchPt.x - sdTracking.x, y: touchPt.y - sdTracking.y};

					sdZoom.offset.x += delta.x;
					sdZoom.offset.y += delta.y;
				
					ScreenDesignerCanvas_Render();
				
					sdTracking.x = touchPt.x;
					sdTracking.y = touchPt.y;
					//console.log(touchAction, JSON.stringify(sdTracking));
				}
			}
		}
		else if (touchAction == "touchend" || touchAction == "touchcancel")
		{
			if (sdTracking != undefined)
			{
				// Find the point we are tracking
				var trackingTouch = findTouch(sdTracking.identifier, evt.changedTouches);
				
				// Cancel tracking if the tracking touch has ended or if there are no more touches
				if (trackingTouch != undefined || evt.touches.length == 0)
				{
					sdTracking = undefined;
					sdCanvas.style.cursor = "grab";
				}
			}
		}
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Handle Context Menu
	 *		Common routine to process all mouse events
	 
	 * https://www.sitepoint.com/building-custom-right-click-context-menu-javascript/
	 * https://stackoverflow.com/questions/4495626/making-custom-right-click-context-menus-for-my-web-app
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignCanvas_HandleContextMenu = function(evt, contextMenuAction)
	{
		if (contextMenuAction == "contextmenu")
		{
			// Prevent the browser's context menu
			//evt.preventDefault();
			// document.addEventListener("mousedown", evt => ScreenDesignCanvas_HandleContextMenu(evt, "mousedown"), false);
			// document.removeEventListener("mousedown", evt => ScreenDesignCanvas_HandleContextMenu(evt, "mousedown"), false);

		}
		else if (contextMenuAction == "mousedown")
		{
			
		}
		else
		{
			console.log("ScreenDesignCanvas_HandleContextMenu: unexpected action: '" + contextMenuAction + "', ignored");
		}
	}
	
	var ScreenDesignCanvas_MouseWheel_priv = function(evt)
	{
		var mousePt = getMousePos(sdCanvas, evt);
		
		// 2018.01.09: Added test for metaKey (command-key on Mac)
		// 2018.06.01: Provide option to ignore meta key
		// 2019.02.19: Show banner to let user know to hold meta key
		// 2021.07.15: Change lower scale limit from 0.50 to 0.01
		if (evt.metaKey || ignoreMetaKey())
		{
			var changePercent = 0.05;
			var lowerScaleLimit = 0.01; 
			var scaleChanged = sdZoom.AdjustScaleAroundBy(mousePt, (evt.deltaY > 0) ? changePercent : -changePercent, lowerScaleLimit);
		
			if (scaleChanged)
				ScreenDesignerCanvas_Render();
		}
		else
			ScreenDesigner.DisplayHoldCommandKeyBanner(sdCanvas.id);
	}
	

	var ScreenDesignerCanvas_Render = function()
	{
		// 2021.05.17: Change tile highlight to an object
		var tileHighlight = undefined;
		if (sdTileHighlight.length > 0)
		{
			tileHighlight = {};
			tileHighlight.tiles = sdTileHighlight;
			tileHighlight.fullTile = (sdSketchMode == SketchMode.SOLID_TILE || sdSketchMode == SketchMode.ERASE_TILE);
			tileHighlight.allEdges = (sdSketchMode == SketchMode.HOLLOW_TILE);
			tileHighlight.draw =  !(sdSketchMode == SketchMode.ERASE_TILE || sdSketchMode == SketchMode.ERASE_EDGE);

			// 2021.09.23: Ask ScreenDesigner to include adjacent tile edges to highlight
			if (sdSketchMode == SketchMode.ERASE_EDGE)
				tileHighlight.tiles = ScreenDesigner.AddAdjacentTileEdges(sdTileHighlight);
		}

		// 2022.02.08: Added so that we can pass settings without modifying the design
		let diags = ScreenDesigner.GetDiagsSettings();

		//var startTime = Date.now();
		ScreenDesignerCanvas_RenderToCanvasWithZoom(sdCanvas, sdZoom, {highlight: sdHighlight, tileHighlight, diags});
		
		//var renderTime = Date.now() - startTime;
		//console.log(renderTime);
	}
	
	var ScreenDesignerCanvas_RenderToCanvasWithZoom = function(theCanvas, theZoom, options = undefined)
	{
		if (sdDesignRender != undefined)
			ScreenRenderer.RenderToCanvas(sdDesignRender, ScreenRenderer.RenderType.ON_SCREEN, theCanvas, theZoom, options);
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Render Design
	 *		2021.03.22: Added wrapper to identify calls from outside this object.
	 *		and render to the miniviewer
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignerCanvas_RenderDesign = function()
	{
		// For image designs, only render when the design is complete to reduce flicker.
		
		if (sdDesignRender != undefined)
		{
			if (ScreenGenerator.IsComplete(sdDesignRender) || sdDesignRender.designData.designType != DesignType.TYPE_IMAGE)
			{
				ScreenDesignerCanvas_Render();
				ScreenDesignerCanvas_RenderMiniDesign();
				ScreenDesignerCanvas_RenderHistogram();
			}
		}
	}

	/*----------------------------------------------------------------------------------*
	 *	Render Mini Design
	 *		2021.03.22: Added
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignerCanvas_RenderMiniDesign = function()
	{
		// If the design is completely rendered and there is a miniviewer canvas
		let miniviewer = document.getElementById("ID_Miniviewer");
		if (sdDesignRender != undefined && ScreenGenerator.IsComplete(sdDesignRender) && miniviewer != undefined)
		{
			var canvasBounds = { min:{x:0, y:miniviewer.height}, max:{x:miniviewer.width, y:0}};
			var designBounds = ScreenDesignerCanvas_GetRenderBounds();
			var miniZoom = new Transform(designBounds, canvasBounds);

			ScreenRenderer.RenderToCanvas(sdDesignRender, ScreenRenderer.RenderType.THUMBNAIL, miniviewer, miniZoom, undefined);
		}
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Render Histogram
	 *		2021.03.30: Added
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignerCanvas_RenderHistogram = function()
	{
		// If the design is completely rendered and there is a histogram canvas
		let histogram = document.getElementById("ID_Histogram");
		if (sdDesignRender != undefined && ScreenGenerator.IsComplete(sdDesignRender) && histogram != undefined)
		{
			ScreenRenderer.RenderHistogram(sdDesignRender, histogram);
		}
	}
	
	var ScreenDesignerCanvas_SetSketchMode = function(sketchMode)
	{
		sdSketchMode = sketchMode;
	}

	var ScreenDesignerCanvas_ResizeHandler = function()
	{
		sdCanvas.width = sdCanvas.clientWidth;
		sdCanvas.height = sdCanvas.clientHeight;
	}
	
	var ScreenDesignerCanvas_GetRelativeLayout = function()
	{
		var layout = {};
		layout.width  = sdCanvas.clientWidth;
		layout.height = sdCanvas.clientHeight;
		layout.zoom = sdZoom.Clone();
		
		return layout;
	}
	
	var ScreenDesignerCanvas_SetRelativeLayout = function(layout)
	{
		sdZoom = ScreenDesigner.CalcZoomChanges(sdCanvas, layout, sdZoom, true /* means that origin is center */);
	}
	
	var ScreenDesignerCanvas_SetDesignRender = function(generation)
	{
		sdDesignRender = generation;
	}

	var ScreenDesignerCanvas_GetDimensions = function(pointList)
	{
		var dims = {x:sdCanvas.width, y: sdCanvas.height};
		
		return dims;
	}
	
	var ScreenDesignerCanvas_GetRenderBounds = function()
	{
		var bounds = undefined;
		
		if (sdDesignRender != undefined)
			bounds = sdDesignRender.bounds;
		
		if (bounds == undefined)
			bounds= {min:{x:-100, y:-100}, max:{x:100 , y:100}};
		
		
		return bounds;
		
	}

	var ScreenDesignerCanvas_ZoomToFrame = function()
	{
		var canvasBounds = {
			min:{x:sdMargin, y:sdCanvas.height - sdMargin}, 
			max:{x:sdCanvas.width - sdMargin, y:sdMargin}};

		var bounds = ScreenDesignerCanvas_GetRenderBounds();
			
		sdZoom.CalcMappingTransform(bounds, canvasBounds);
	}

	var ScreenDesignerCanvas_SetZoomTransform = function(zoomTransform)
	{
		sdZoom = zoomTransform;
		ScreenDesignerCanvas_Render();
		
	}
	
	var ScreenDesignerCanvas_AnimatedZoomCompleted = function(context)
	{
		sdAnimatedZoom = undefined;
	}
	
	var ScreenDesignerCanvas_Zoom = function(zoomTo, scale)
	{
		var targetZoom = undefined;
		
		var polybounds = ScreenDesignerCanvas_GetRenderBounds();
			
		if (zoomTo == ZoomTo.ONE_TO_ONE)
		{
			var cx = sdCanvas.width/2;
			var cy = sdCanvas.height/2;
			var renderBounds = {
				min:{x:cx + polybounds.min.x, y:cy + polybounds.max.y}, 
				max:{x:cx + polybounds.max.x, y:cy + polybounds.min.y}};
						
			var targetZoom = new Transform(polybounds, renderBounds);
			
			// We change the scale to represent DPI (for designs in inches and mm)
			if (scale != undefined)
			{
				targetZoom.scale.x *= scale;
				targetZoom.scale.y *= scale;
			}
		}
		else if (zoomTo == ZoomTo.FRAME)
		{
			var canvasBounds = {
				min:{x:sdMargin, y:sdCanvas.height - sdMargin}, 
				max:{x:sdCanvas.width - sdMargin, y:sdMargin}};
			
			var targetZoom = new Transform(polybounds, canvasBounds);
		}
		else if (zoomTo == ZoomTo.SMALLER_DIM)
		{
			// Get the aspect ratio of the canvas
			let canvasWidth = sdCanvas.width - 2 * sdMargin;
			let canvasHeight = sdCanvas.height - 2 * sdMargin;
			let canvasAspectRatio = canvasWidth/canvasHeight;

			// And the aspect ratio of the design bounds
			let polyWidth = polybounds.max.x - polybounds.min.x;
			let polyHeight = polybounds.max.y - polybounds.min.y;
			let polyAspectRatio = polyWidth/polyHeight;

			// Compute an adjustment for either the width or height of the design
			// bounds so that it matches the aspect ratio of the canvas. The net
			// effect will be a "fill"
			let xOffset = 0;
			let yOffset = 0;

			if (polyAspectRatio < canvasAspectRatio)
				yOffset = (polyHeight - polyWidth / canvasAspectRatio)/2;
			else
				xOffset = (polyWidth - polyHeight * canvasAspectRatio)/2;
		
			// Bounds to render.
			var renderBounds = {
				min:{x:polybounds.min.x + xOffset, y:polybounds.min.y + yOffset}, 
				max:{x:polybounds.max.x - xOffset, y:polybounds.max.y - yOffset}};

			var canvasBounds = {
				min:{x:sdMargin, y:sdCanvas.height - sdMargin}, 
				max:{x:sdCanvas.width - sdMargin, y:sdMargin}};

			var targetZoom = new Transform(renderBounds, canvasBounds);
		}
		
		if (targetZoom != undefined)
		{
			if (sdAnimatedZoom != undefined)
			{
				sdAnimatedZoom.Stop();
				sdAnimatedZoom = undefined;
			}
			sdAnimatedZoom = new AnimatedZoom(sdZoom, targetZoom, 15, 10, ScreenDesignerCanvas_SetZoomTransform, ScreenDesignerCanvas_AnimatedZoomCompleted);
		}
	}
	
	/*----------------------------------------------------------------------------------*
	 *	Export Selected Polygons
	 *		
	 *----------------------------------------------------------------------------------*/
	var ScreenDesignCanvas_ExportSelectedPolygons = function()
	{
		function logPoly(polygon, endCapStyle) {
			let exp = { pds:true, polygon, options:{endCapStyle} };
			console.log(JSON.stringify(exp));
		}

		if (sdDesignRender.offsetPolyList != undefined)
		{
			for (var i = 0; i < sdHighlight.offsetHighlight.length; i++)
			{
				let idx = sdHighlight.offsetHighlight[i].polyIdx;
				let poly = sdDesignRender.offsetPolyList.GetPolygonPoints(idx)
				logPoly(poly, 0);
			}
		}
		
		for (var i = 0; i < sdHighlight.centerHighlight.length; i++)
		{
			let idx = sdHighlight.centerHighlight[i].polyIdx;
			let poly = sdDesignRender.centerPolyList.GetPolygonPoints(idx)
			logPoly(poly, sdDesignRender.designData.tiling.endCaps);
		}
	}
	
	/*-----------------------------------------------*
	 * Public API
	 *-----------------------------------------------*/
	return {
		Init:					ScreenDesignerCanvas_Init,
		GetDimensions:			ScreenDesignerCanvas_GetDimensions,
		SetProperty:			ScreenDesignerCanvas_SetProperty,
		Render:					ScreenDesignerCanvas_RenderDesign, // 2021.03.22: Was _Render
		RenderMini:				ScreenDesignerCanvas_RenderMiniDesign, // 2021.03.22: Added
		RenderHistogram:		ScreenDesignerCanvas_RenderHistogram, // 2021.03.30: Added
		RenderTo:				ScreenDesignerCanvas_RenderToCanvasWithZoom,
		ResizeHandler:			ScreenDesignerCanvas_ResizeHandler,
		SetDesignRender:		ScreenDesignerCanvas_SetDesignRender,
		GetRelativeLayout:		ScreenDesignerCanvas_GetRelativeLayout,		
		SetRelativeLayout:		ScreenDesignerCanvas_SetRelativeLayout,		
		Zoom:					ScreenDesignerCanvas_Zoom,
		ZoomTo:					ZoomTo,
		ZoomToFrame:			ScreenDesignerCanvas_ZoomToFrame,
		SketchMode:				SketchMode,
		SetSketchMode:			ScreenDesignerCanvas_SetSketchMode
	};
}());

/* Canvas for gallery and workbook display */
var ScreenDesignerItemRenderer = (function() {

	var siCanvas = undefined;
	var siContext = undefined;
	
	var ScreenDesignerItemCanvas_Init = function(e)
	{
		siCanvas = e;
		siContext = siCanvas.getContext("2d");
	}
	
	var ScreenDesignerItemRenderer_CalcMirroredRotatedLines = function(tileInfo, ptA, ptB, mirror, rotate, center)
	{
		var lines = [];
		
		if (center != undefined)
		{
			var ptAc = {x:(ptA.x - center.x), y:(ptA.y - center.y)};
			var ptBc = {x:(ptB.x - center.x), y:(ptB.y - center.y)};

			if (mirror)
			{
				var ptAn = {x:(center.x - ptAc.x), y:(center.y + ptAc.y)};
				var ptBn = {x:(center.x - ptBc.x), y:(center.y + ptBc.y)};
				lines.push({ptA:ptAn, ptB:ptBn});
			}	

			var deltaAngle = Math.PI * 2.0 / tileInfo.sides;
		
			var count = (rotate ? tileInfo.sides : 1);
			for (var i = 1; i < count; i++)
			{
				var a = /*seEditTileInfo.startAngle + */ i * deltaAngle;
				var cA = Math.cos(a);
				var sA = Math.sin(a);
			
				// Original
				var ptAo = {};
				ptAo.x = cA *  ptAc.x  - sA *  ptAc.y  ;
				ptAo.y = cA *  ptAc.y  + sA *  ptAc.x  ;
				var ptBo = {};
				ptBo.x = cA *  ptBc.x  - sA *  ptBc.y  ;
				ptBo.y = cA *  ptBc.y  + sA *  ptBc.x  ;
			
				lines.push({ptA:{x:ptAo.x+ center.x, y:ptAo.y+ center.y}, ptB:{x:ptBo.x+ center.x, y:ptBo.y+ center.y}});

				if (mirror)
				{
					lines.push({ptA:{x:-ptAo.x+ center.x, y:ptAo.y+ center.y}, ptB:{x:-ptBo.x+ center.x, y:ptBo.y+ center.y}});
				}
			} 
		}
		
		return lines;
	}
	
	var ScreenDesignerItemRenderer_RenderLinesTo = function(theCanvas, designData, tileInfo, transform)
	{
		var theContext = theCanvas.getContext("2d");
		var defaultLineColor = "rgb(0, 0, 128)";

		var center = undefined; 
		
		var margin = 1;
		var canvasBounds = {
			min:{x:margin, y:theCanvas.height - margin}, 
			max:{x:theCanvas.width - margin, y:margin}};
		var unitBounds = {min:{x:0, y:0}, max:{x:1, y:1}};
		var transformLines = new Transform(unitBounds, canvasBounds);
		//seTransformTileToRelative = ScreenEditorCanvas_CalcMappingTransform(seEditTileInfo.bounds, unitBounds);

		center = transform.xfrm(tileInfo.center);

		theContext.lineWidth = 1.0;
		for (var i = 0; i < designData.elements.length; i++)
		{
			var ln = designData.elements[i];
			
			var ptA = transformLines.xfrm(ln.ptA);
			var ptB = transformLines.xfrm(ln.ptB);
		
			theContext.strokeStyle = defaultLineColor;

			theContext.lineWidth = 1.0;
			if (ln.visible)
			{
				theContext.beginPath();
				theContext.moveTo(ptA.x, ptA.y);
				theContext.lineTo(ptB.x, ptB.y);
				theContext.stroke();	
			}		
			var lines = ScreenDesignerItemRenderer_CalcMirroredRotatedLines(tileInfo, ptA, ptB, ln.mirror, ln.rotate, center);
			if (lines != undefined)
			{
				for (var j = 0; j < lines.length; j++)
				{
					if (ln.visible)
					{
						theContext.beginPath();
						theContext.moveTo(lines[j].ptA.x, lines[j].ptA.y);
						theContext.lineTo(lines[j].ptB.x, lines[j].ptB.y);
						theContext.stroke();	
					}
				}
			}
		}
	}

	var ScreenDesignerItemRenderer_RenderTileTo = function(theCanvas, tileInfo, transform)
	{
		var theContext = theCanvas.getContext("2d");
		
		// Show center
		theContext.fillStyle = "#f0f0f0";
		//var center = transform.xfrm(tileInfo.center);
		//theContext.fillRect(center.x - 2, center.y - 2, 4, 4);
		
		
		theContext.lineWidth = 1.0;
		theContext.strokeStyle = "rgba(0, 0, 0, 0.25)";
		theContext.beginPath();
		for (var i = 0; i < tileInfo.points.length; i++)
		{
			var pt = transform.xfrm(tileInfo.points[i]);
			if (i == 0)
				theContext.moveTo(pt.x, pt.y);
			else
				theContext.lineTo(pt.x, pt.y);
		}
		theContext.closePath();
		theContext.stroke();	
	}
		
	var ScreenDesignerItemRenderer_CreateCanvas = function(size /* {x, y} */)
	{
		/* Create a temporary canvas and sets it size to be only the gfx (and not the controls) */
		var aCanvas = document.createElement('canvas');
		aCanvas.width  = size.x;
		aCanvas.height = size.y;
		
		return aCanvas;
	}	
	
	var ScreenDesignerItemRenderer_RenderTo = function(theCanvas, designData, tileInfo)
	{
		var theContext = theCanvas.getContext("2d");
		
		var margin = 1;
		var canvasBounds = {
			min:{x:margin, y:theCanvas.height - margin}, 
			max:{x:theCanvas.width - margin, y:margin}};
		var transform = new Transform(tileInfo.bounds, canvasBounds);

		theContext.clearRect(0, 0, theCanvas.width, theCanvas.height);
		
		if (designData.general.renderBack)
		{
			theContext.fillStyle = designData.general.backColor;
			theContext.fillRect(0, 0, theCanvas.width, theCanvas.height);
		}
		
		ScreenDesignerItemRenderer_RenderTileTo(theCanvas, tileInfo, transform)
		ScreenDesignerItemRenderer_RenderLinesTo(theCanvas,designData,  tileInfo, transform)
		
	}

	var ScreenDesignerItemRenderer_GetPNGData = function(theCanvas)
	{
		// Get data from the canvas
		var pngData = theCanvas.toDataURL("image/png");
		
		return pngData;
	}
	
	var ScreenDesignerItemRenderer_RenderPNG = function(designData, size /* {x, y} */)
	{
		var tileInfo = ScreenGenerator.GetEditTileInfo(designData);
		
		var cnv = ScreenDesignerItemRenderer_CreateCanvas(size);
		
		ScreenDesignerItemRenderer_RenderTo(cnv, designData, tileInfo);
		
		var pngData = ScreenDesignerItemRenderer_GetPNGData(cnv);
		
		return pngData;
	}
	
	/*-----------------------------------------------*
	 * Public API
	 *-----------------------------------------------*/
	return {
		RenderPNG:				ScreenDesignerItemRenderer_RenderPNG
	};
}());


export { ScreenDesignerCanvas };
export { ScreenDesignerItemRenderer };