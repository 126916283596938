// SystemAnalytics.js

import Analytics from '@aws-amplify/analytics';

//------------------------------------------------------------------------------
//	System Analytics
//
//	Wrapper around AWS Amplify Analytics API
//------------------------------------------------------------------------------
var SystemAnalytics = (function() {
	
	//------------------------------------------------------------------------------
	//	Main entry point for the application
	//------------------------------------------------------------------------------
	var SystemAnalytics_Record = function(event, attributes, metrics)
	{
		/*
		console.log("SystemAnalytics_Record: " + event + 
						", attributes: " + ((attributes != undefined) ? JSON.stringify(attributes) : "none") +
						", metrics: " + ((metrics != undefined) ? JSON.stringify(metrics) : "none"));
		*/		
		Analytics.record(event, attributes, metrics)
	}
		
	//------------------------------------------------------------------------------
	//	Public API
	//------------------------------------------------------------------------------
	return {
		Record:		SystemAnalytics_Record
	}
	
}());


export { SystemAnalytics };
