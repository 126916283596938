//==========================================================================================
//
//	Stripe Payment Manager
//
//		This module manages the Stripe payments. It uses the API gateway to send 
//		the token and the subscription level to a lambda function, which then
//		sends the request to the Stripe server
//------------------------------------------------------------------------------------------
//
//==========================================================================================
'use strict';

import API from '@aws-amplify/API';


//	https://stripe.com/docs/billing/quickstart
//-	https://stripe.com/docs/recipes/subscription-signup
//	https://stripe.com/docs/testing#cards
//	https://stripe.com/docs/api#errors
//
//	https://stripe.com/docs/recipes/sending-emails-for-failed-payments
//	https://developers.exlibrisgroup.com/blog/Hosting-a-Webhook-Listener-in-AWS
//
//	https://stripe.com/docs/billing/subscriptions/upgrading-downgrading
//	https://stripe.com/docs/api/subscriptions/update
//	https://stripe.com/docs/billing/invoices/subscription#generating-invoices
//
//	https://groups.google.com/a/lists.stripe.com/forum/#!forum/api-discuss


var PaymentManager = (function() {

	let paymentAPIGatewayInfo = Object.freeze({
		apiName: "StripeProcessing",
		path: "/process" });
		

	//------------------------------------------------------------------------------
	//	Configuration
	//
	//		use: 'Checkout' or 'Elements'
	//		stripeKey
	//		imageURI
	//		paymentButtonId
	//		businessName
	//------------------------------------------------------------------------------
	var paymentMgrConfig = undefined;
	
	var paymentMgrStripeModule = undefined;
	
	var paymentMgrStripeElements = undefined;
	
	var paymentMgrCurrentRequest = undefined;
	
	var paymentCompletionCallback = undefined;

	var paymentMgrForm = undefined;
	var paymentMgrFormElements = undefined;


	//------------------------------------------------------------------------------
	//	Init
	//		
	//------------------------------------------------------------------------------
	var PaymentManager_Init = function(pmConfig)
	{
		paymentMgrConfig = pmConfig;
		
		//console.log("PaymentManager_Init " + JSON.stringify(paymentMgrConfig));
	}
	
	//------------------------------------------------------------------------------
	//	Post SignIn Init
	//		
	//------------------------------------------------------------------------------
	var PaymentManager_PostSignInInit = function(pmConfig)
	{
		// If we don't have a public stripe key, then request it from the server
		if (paymentMgrConfig.stripeKey == undefined)
			PaymentManager_LoadStripePublicKey();
	}
	
	//------------------------------------------------------------------------------
	//	Load Stripe Public Key
	//	
	//------------------------------------------------------------------------------
	var PaymentManager_LoadStripePublicKey = function()
	{
		let params = { body: {getPublicKey:"true"} };

		try {
		API.post(paymentAPIGatewayInfo.apiName, paymentAPIGatewayInfo.path, params)
		.then(response => 
			{
				paymentMgrConfig.stripeKey = response.key;
			})
		.catch(error => 
			{
				console.log("PaymentManager_LoadStripePublicKey: Server error response: " + error);
			});
		}
		catch (err) {
			console.error("PaymentManager_LoadStripePublicKey");
			console.error(err);
		}
	}

	//------------------------------------------------------------------------------
	//	Prepare
	//		Initialize the Stripe module. This is only done if the user
	//		brings up the payment dialog
	//------------------------------------------------------------------------------
	var PaymentManager_Prepare = function()
	{
		if (paymentMgrStripeModule == undefined)
		{
			if (paymentMgrConfig.use === 'Checkout')
				PaymentManager_PrepareStripeCheckout();
				
			else if (paymentMgrConfig.use === 'Elements')
				PaymentManager_PrepareStripeElements();
		}
	}

	//------------------------------------------------------------------------------
	//	Prepare Stripe Checkout
	//		Initialize Checkout API
	//------------------------------------------------------------------------------
	var PaymentManager_PrepareStripeCheckout = function()
	{
		// Configuration for the Stripe handler
		const stripeConfiguration = 
		{
			key: paymentMgrConfig.stripeKey,
			image: paymentMgrConfig.imageURI,
			locale: 'auto',
			token: PaymentManager_StripeTokenCallback
		};

		// Create the Stripe Checkout payment handler
		paymentMgrStripeModule = StripeCheckout.configure(stripeConfiguration);
	
		// Close Checkout on page navigation:
		window.addEventListener("popstate", () => { paymentMgrStripeModule.close(); });
	}
	
	//------------------------------------------------------------------------------
	//	Prepare Stripe Elements
	//		Initialize Elements UI and API
	//------------------------------------------------------------------------------
	var PaymentManager_PrepareStripeElements = function()
	{
		// Create the Stripe Elements payment handler
		paymentMgrStripeModule = Stripe(paymentMgrConfig.stripeKey);

		// Create an instance of Elements.
		var elementsConfig = {
				fonts: [ { cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro' } 	],
				locale: 'auto'
			};
		
		paymentMgrStripeElements = paymentMgrStripeModule.elements(elementsConfig);

		// Floating labels
		var inputs = document.querySelectorAll('.paymentinfo .input');
		Array.prototype.forEach.call(inputs, function(input) 
			{
				//console.log("  adding listeners to " + (input.id != undefined ? input.id : "another input"));
				input.addEventListener('focus', function() { input.classList.add('focused'); } );
				input.addEventListener('blur',  function() { input.classList.remove('focused'); } );
				input.addEventListener('keyup', function()  
					{
						if (input.value.length === 0)
							input.classList.add('empty');
						else
							input.classList.remove('empty');
					});
			});

		var elementStyles = {
			base: {
				color: '#32325D',
				fontWeight: 500,
				fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				'::placeholder': {
					color: '#CFD7DF',
				},
				':-webkit-autofill': {
					color: '#e39f48',
				},
			},
			invalid: {
				color: '#E25950',
				'::placeholder': {
					color: '#FFCCA5',
				},
			},
		};

		var elementClasses = { focus: 'focused', empty: 'empty', invalid: 'invalid'	};

		var cardNumber = paymentMgrStripeElements.create('cardNumber', { style: elementStyles, classes: elementClasses });
		cardNumber.mount('#paymentinfo-card-number');

		var cardExpiry = paymentMgrStripeElements.create('cardExpiry', {style: elementStyles, classes: elementClasses });
		cardExpiry.mount('#paymentinfo-card-expiry');

		var cardCvc = paymentMgrStripeElements.create('cardCvc', {style: elementStyles, classes: elementClasses	});
		cardCvc.mount('#paymentinfo-card-cvc');

		PaymentManager_Form_RegisterElements([cardNumber, cardExpiry, cardCvc]);
		
		// Close 
		var button = document.querySelector('.progress-close-button');
		button.onclick = PaymentManager_HandleProgressClose;
		
		// Modify 
		var button = document.querySelector('.modify-plan-button');
		button.onclick = PaymentManager_Form_HandleBtn_ModifyPlan;

		// Update Payment 
		var button = document.querySelector('.update-payment-button');
		button.onclick = PaymentManager_Form_HandleBtn_UpdatePaymentInfo;
	}

	//------------------------------------------------------------------------------
	//	UpdateUI (Form)
	//
	//		uiSettings
	//			reset: [true | undefined]
	//			showPage: ['Payment' | 'Modify' | 'Status' ]
	//			modifyAction: ["Cancel" | "Restore" | "Modify" | undefined]
	//			showCloseButton: [true | false | undefined]
	//			showSpinner: [true | false | undefined]
	//
	//			modifyStr: ["str" | undefined]
	//			statusStr: ["str" | undefined]
	
	//			xx errorStr: ["str" | undefined]
	//			xx showModifyPage: [true | false | undefined ]
	//			xx showProgress: [true | false | undefined]
	//			xx showError: [true | undefined]
	//------------------------------------------------------------------------------
	function PM_AddClass(element, className, addFlag)
	{
		if (addFlag)
			element.classList.add(className);
		else
			element.classList.remove(className);
	}

	function PM_ShowElement(element, showFlag)
	{
		PM_AddClass(element, "hide-element", !showFlag)
	}
	
	var PaymentManager_UpdateUI = function(uiSettings = undefined)
	{
		// Payment page
		var paymentPage = document.querySelector('.paymentinfo');
		
		// Modify Page
		var modifyPage = document.querySelector('.modifyplan');
		var modifyMsg = document.querySelector('.modifyplan > .payment-message');
		var modifyPlanButton = document.querySelector('.modifyplan .modify-plan-button');
		var updatePaymentButton = document.querySelector('.modifyplan .update-payment-button');

		// Status Page
		var statusPage = document.querySelector('.progress');
		var statusMsg = document.querySelector('.progress > .payment-message');
		var progressSpinner = document.querySelector('.progress-animation');
		var closeButton =  document.querySelector('.progress .progress-close-button');

		//console.log("PaymentManager_UpdateUI: " + JSON.stringify(uiSettings, null, 2));		
		
		// Reset (uiSettings is undefined or uiSettings.reset = true)
		if (uiSettings == undefined || uiSettings.reset)
		{
			// Payment page
			paymentPage.classList.remove("processing");
			paymentPage.classList.remove("hide-element");
			// Modify page
			modifyPage.classList.add("hide-element");
			modifyMsg.innerHTML = "";
			modifyPlanButton.innerHTML = "Modify Plan";
			modifyPlanButton.classList.add("half-width-button");
			updatePaymentButton.classList.remove("hide-element");
			// Status Page
			statusPage.classList.add("hide-element");
			statusMsg.innerHTML = "";
			progressSpinner.classList.add("hide-element");
			closeButton.classList.add("hide-element");
		}

		// Show the requested page
		if (uiSettings != undefined && uiSettings.showPage != undefined)
		{
			PM_ShowElement(paymentPage, (uiSettings.showPage == 'Payment'));
			PM_ShowElement(modifyPage,  (uiSettings.showPage == 'Modify'));
			PM_ShowElement(statusPage, (uiSettings.showPage == 'Status'));
		}

		// Update the 'Modify Plan' button to show 'Modify', 'Cancel', or 'Restore'
		// and only show the 'Update Payment Info' button for 'Modify'
		if (uiSettings != undefined && uiSettings.modifyAction != undefined)
		{
			//console.log("PaymentManager_UpdateUI: modifyAction: '" + uiSettings.modifyAction + "'");
			if (uiSettings.modifyAction == "Upgrade" || uiSettings.modifyAction == "Downgrade")
			{
				modifyPlanButton.innerHTML = "Modify Plan";
				modifyPlanButton.classList.add("half-width-button");
				updatePaymentButton.classList.remove("hide-element");
			}
			else if (uiSettings.modifyAction == "Cancel")
			{
				modifyPlanButton.innerHTML = "Cancel Plan";
				modifyPlanButton.classList.remove("half-width-button");
				updatePaymentButton.classList.add("hide-element");
			}
			else if (uiSettings.modifyAction == "Restore")
			{
				modifyPlanButton.innerHTML = "Restore Plan";
				modifyPlanButton.classList.remove("half-width-button");
				updatePaymentButton.classList.add("hide-element");
			}
			else
			{
				console.log("PaymentManager_UpdateUI: Unknown modifyAction: '" + uiSettings.modifyAction + "'");
			}
		}
		
		// Status str
		if (uiSettings != undefined && uiSettings.statusStr != undefined)
			statusMsg.innerHTML = uiSettings.statusStr;
		
		// Modify Plan str
		if (uiSettings != undefined && uiSettings.modifyStr != undefined)
			modifyMsg.innerHTML = uiSettings.modifyStr;
		
		// Show or hide Spinner
		if (progressSpinner != undefined && uiSettings != undefined && uiSettings.showSpinner != undefined)
			PM_ShowElement(progressSpinner, uiSettings.showSpinner);
		
		// Show or hide Close button
		if (uiSettings != undefined && uiSettings.showCloseButton != undefined)
			PM_ShowElement(closeButton,  uiSettings.showCloseButton);
	}

	//------------------------------------------------------------------------------
	//	Get Additional Data (Form)
	//
	//------------------------------------------------------------------------------
	function PaymentManager_Form_GetAdditionalData()
	{
		// Gather additional customer data we may have collected in our form.
		var name = paymentMgrForm.querySelector('#paymentinfo-name');
		var address1 = paymentMgrForm.querySelector('#paymentinfo-address');
		var city = paymentMgrForm.querySelector('#paymentinfo-city');
		var state = paymentMgrForm.querySelector('#paymentinfo-state');
		var zip = paymentMgrForm.querySelector('#paymentinfo-zip');
		var additionalData = {
			name: name ? name.value : undefined,
			address_line1: address1 ? address1.value : undefined,
			address_city: city ? city.value : undefined,
			address_state: state ? state.value : undefined,
			address_zip: zip ? zip.value : undefined,
		};
		
		return additionalData;
	}
	
	//------------------------------------------------------------------------------
	//	Register Elements (Form)
	//------------------------------------------------------------------------------
	function PaymentManager_Form_RegisterElements(elements)
	{
		var formClass = '.paymentinfo';
		var paymentinfo = document.querySelector(formClass);
		paymentMgrForm = paymentinfo.querySelector('form');

		var error = paymentMgrForm.querySelector('.error');
		var errorMessage = error.querySelector('.message');

		// Listen for errors from each Element, and show error messages in the UI.
		var savedErrors = {};
	
		elements.forEach(function(element, idx) {
			element.on('change', function(event) {
				if (event.error)
				{
					error.classList.add('visible');
					savedErrors[idx] = event.error.message;
					errorMessage.innerText = event.error.message;
				} 
				else
				{
					savedErrors[idx] = null;

					// Loop over the saved errors and find the first one, if any.
					var nextError = Object.keys(savedErrors)
					.sort()
					.reduce(function(maybeFoundError, key) { return maybeFoundError || savedErrors[key]; }, null);

					if (nextError)
					{
						// Now that they've fixed the current error, show another one.
						errorMessage.innerText = nextError;
					}
					else
					{
						// The user fixed the last error; no more errors.
						error.classList.remove('visible');
					}
				}
			});
		});

		// Need an element for later reference
		paymentMgrFormElements = elements;

		// Listen on the form's 'submit' handler...
		paymentMgrForm.addEventListener('submit', PaymentMgr_Form_SubmitHandler);
	}

	//------------------------------------------------------------------------------
	//	Enable Inputs (Form)
	//------------------------------------------------------------------------------
	function PaymentMgr_Form_EnableInputs()
	{
		Array.prototype.forEach.call(
			paymentMgrForm.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
			input => input.removeAttribute('disabled') );
	}

	//------------------------------------------------------------------------------
	//	Disable Inputs (Form)
	//------------------------------------------------------------------------------
	function PaymentMgr_Form_DisableInputs()
	{
		Array.prototype.forEach.call(
			paymentMgrForm.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
			input => { input.setAttribute('disabled', 'true'); } );
	}

	//------------------------------------------------------------------------------
	//	Trigger Browser Validation (Form)
	//------------------------------------------------------------------------------
	function PaymentMgr_Form_TriggerBrowserValidation()
	{
		// The only way to trigger HTML5 form validation UI is to fake a user submit event.
		var submit = document.createElement('input');
		submit.type = 'submit';
		submit.style.display = 'none';
		paymentMgrForm.appendChild(submit);
		submit.click();
		submit.remove();
	}

	//------------------------------------------------------------------------------
	//	Reset (Form)
	//------------------------------------------------------------------------------
	var PaymentManager_Form_Reset = function()
	{
		// Resetting the form (instead of setting the value to `''` for each input)
		// helps us clear webkit autofill styles.
		paymentMgrForm.reset();

		var inputs = paymentMgrForm.querySelectorAll('.paymentinfo .input');
		Array.prototype.forEach.call(inputs, function(input) 
			{
				input.classList.remove('focused');
				input.classList.add('empty');
			});

		// Clear each Element.
		paymentMgrFormElements.forEach(element => element.clear() );

		// Reset error state as well.
		var error = paymentMgrForm.querySelector('.error');
		error.classList.remove('visible');

		// Resetting the form does not un-disable inputs, so we need to do it separately:
		PaymentMgr_Form_EnableInputs();
	}

	//------------------------------------------------------------------------------
	//	Submit Handler (Form)
	//------------------------------------------------------------------------------
	var PaymentMgr_Form_SubmitHandler = function(e)
	{
		e.preventDefault();

		// Trigger HTML5 validation UI on the form if any of the inputs fail validation.
		var plainInputsValid = true;
		Array.prototype.forEach.call(
			paymentMgrForm.querySelectorAll('input'), 
			function(input) {
				if (input.checkValidity && !input.checkValidity())
				{
					plainInputsValid = false;
					return;
				}
			}
		);

		if (!plainInputsValid) 
		{
			PaymentMgr_Form_TriggerBrowserValidation();
			return;
		}

		// Show a loading screen...
		PaymentManager_UpdateUI({showPage:'Status', statusStr:"Processing..", showSpinner:true});
		
		// Disable all inputs.
		PaymentMgr_Form_DisableInputs();

		// Use Stripe.js to create a token. We only need to pass in one Element
		// from the Element group in order to create a token. We can also pass
		// in the additional customer data we collected in our form.
		var additionalData = PaymentManager_Form_GetAdditionalData();
		
		paymentMgrStripeModule.createToken(paymentMgrFormElements[0], additionalData)
		.then(result =>
			{
				// Stop loading!
				//example.classList.remove('submitting');

				if (result.token)
				{
					// If we received a token, show the token ID.
					//console.log("Token received: " + result.token.id);
					
					PaymentManager_UpdateUI({statusStr:"Processing....."});
					PaymentManager_StripeTokenCallback(result.token);
				}
				else
				{
					// Otherwise, un-disable inputs.
					PaymentMgr_Form_EnableInputs();
				}
			});
	}

	//------------------------------------------------------------------------------
	//	Process Subscription Success
	//		
	//------------------------------------------------------------------------------
	var PaymentManager_ProcessSubscriptionSuccess = function(actionParams, response)
	{
		// Update status and progress indicators
		var msg = "";
		
		if (actionParams.action == "Subscribe")
			msg = "Thank you for your subscription!"
		else if (actionParams.action == "Restore")
			msg = "Thank you for your restoring subscription!"
		else if (actionParams.action == "Cancel")
			msg = "Your subscription has been cancelled and will not renew at the end of the period."
		else if (actionParams.action == "Downgrade")
			msg = "Your subscription will convert to '" + actionParams.level + "' at the end of the period."
		else if (actionParams.action == "Upgrade")
			msg = "Your subscription is now '" + actionParams.level + "'."
		else
		{
			console.log("PaymentManager_ProcessSubscriptionSuccess: actionParams.action '" + actionParams.action + "' not recognized");
		}
			
		msg += "<br><br><br>If you have any questions, please contact us at support@Polygonia.design";
		
		if (response.displayMsg != undefined && response.displayMsg != null && response.displayMsg.length > 0)
			msg += "<br><br>" + response.displayMsg;

		PaymentManager_UpdateUI({showPage:'Status', statusStr:msg, showSpinner:false, showCloseButton:true});

		// Callback into app, which needs to process the success response as appropriate
		if (paymentCompletionCallback != undefined)
			paymentCompletionCallback({response: response});
	}

	//------------------------------------------------------------------------------
	//	Process Subscription Error
	//		
	//------------------------------------------------------------------------------
	var PaymentManager_ProcessSubscriptionError = function(actionParams, response)
	{
		console.log("PaymentManager_ProcessSubscriptionError: " + JSON.stringify(response));
		
		var msg = response.errorMsg;

		PaymentManager_UpdateUI({showPage:'Status', statusStr:msg, showSpinner:false, showCloseButton:true});
	}

	//------------------------------------------------------------------------------
	//	Post Subscription Action
	//		Used by Checkout API 
	//------------------------------------------------------------------------------
	var PaymentManager_PostSubscriptionAction = function(actionParams)
	{
		
		//console.log("PaymentManager_PostSubscriptionAction: " + JSON.stringify(actionParams, null, 2));
		
		/*
		actionParams = {
			action: ["Cancel" | "Restore" | "Upgrade" | "Downgrade" | "Subscribe"],
			userId: paymentMgrCurrentRequest.userId
			level: paymentMgrCurrentRequest.level
			stripeCustomer: { // Optional for Upgrade, Downgrade, Restore (?); Required for Subscribe
				email: paymentMgrCurrentRequest.email,
				metadata: paymentMgrCurrentRequest.meta,
			}
		}
		*/

		let params = { body: actionParams };
		
		API.post(paymentAPIGatewayInfo.apiName, paymentAPIGatewayInfo.path, params)
			.then(response => 
				{
					//console.log("PaymentManager_PostSubscriptionAction response: " + JSON.stringify(response, null, 2));
					if (response.error == undefined)
						PaymentManager_ProcessSubscriptionSuccess(actionParams, response);
					else
						PaymentManager_ProcessSubscriptionError(actionParams, response);
				})
			.catch(error => 
				{
					console.log("Server error response: " + error);
					
					let msg = "An error has occurred. Please try again later.";
					PaymentManager_UpdateUI({showPage:'Status', statusStr:msg, showSpinner:false, showCloseButton:true});

					if (paymentCompletionCallback != undefined)
						paymentCompletionCallback({err: error});
				});
	}

	//------------------------------------------------------------------------------
	//	Post Cancel Subscription Action (Promise)
	//		Used when closing an account
	//		No UI feedback or callbacks provided
	//------------------------------------------------------------------------------
	var PaymentManager_PostCancelSubscriptionAction = function(userId)
	{
		console.log("PaymentManager_PostCancelSubscriptionAction: userId:" + userId);
		
		return new Promise((resolve, reject) =>
		{
			let cancelParams = {
				action: "Cancel",
				userId: userId
			};
		
			let apiParams = { body: cancelParams };
		
			console.log("PaymentManager_PostCancelSubscriptionAction API params: " + JSON.stringify(apiParams, null, 2));
			API.post(paymentAPIGatewayInfo.apiName, paymentAPIGatewayInfo.path, apiParams)
				.then(response => 
					{
						console.log("PaymentManager_PostCancelSubscriptionAction response: " + JSON.stringify(response, null, 2));
						resolve(response);
					})
				.catch(error => 
					{
						console.log("PaymentManager_PostCancelSubscriptionAction error response: " + error);
						reject(error);
					});
		});
	}

	//------------------------------------------------------------------------------
	//	Update Payment Info button handler (Form)
	//------------------------------------------------------------------------------
	var PaymentManager_Form_HandleBtn_UpdatePaymentInfo = function()
	{
		PaymentManager_UpdateUI({showPage:'Payment'});
	}

	//------------------------------------------------------------------------------
	//	Modify Plan button handler (Form)
	//------------------------------------------------------------------------------
	var PaymentManager_Form_HandleBtn_ModifyPlan = function()
	{
		console.log("PaymentManager_Form_HandleBtn_ModifyPlan: '" + paymentMgrCurrentRequest.action + "'");

		let actionParams = 
		{ 
			action:		 paymentMgrCurrentRequest.action,	// may be "Downgrade", "Upgrade", "Restore", or "Cancel"
			userId: 	 paymentMgrCurrentRequest.userId,
		};
		
		if (paymentMgrCurrentRequest.action == "Downgrade" || paymentMgrCurrentRequest.action == "Upgrade")
			actionParams.level = paymentMgrCurrentRequest.level;

		paymentMgrCurrentRequest = undefined;

		PaymentManager_UpdateUI({showPage:'Status', statusStr:"Processing..", showSpinner:true});
		PaymentManager_PostSubscriptionAction(actionParams);
	}

	//------------------------------------------------------------------------------
	//	Stripe Token Callback
	//------------------------------------------------------------------------------
	var PaymentManager_StripeTokenCallback = function(token)
	{
		// You can access the token ID with `token.id`.
		// Get the token ID to your server-side code for use.
		
		//console.log("PaymentManager_StripeTokenCallback");
		//console.dir(token);
		
		let actionParams = 
		{ 
			action:		 paymentMgrCurrentRequest.action,	// may be "Subscribe", "Downgrade", "Upgrade"
			userId: 	 paymentMgrCurrentRequest.userId,
			level: 		 paymentMgrCurrentRequest.level,
			stripeCustomer:
			{
				email: 		 paymentMgrCurrentRequest.email,	
				metadata: 	 paymentMgrCurrentRequest.meta,	
				stripeToken: token.id
			}
		};

		paymentMgrCurrentRequest = undefined;

		PaymentManager_PostSubscriptionAction(actionParams);
	}

	
	//------------------------------------------------------------------------------
	//	Request Payment
	//
	//		paymentRequest
	//			description: "string"
	//			amount: number (in cents)
	//			meta: object (metadata)
	//------------------------------------------------------------------------------
	var PaymentManager_RequestPayment = function(paymentRequest, completionCallback)
	{
		// Make a copy of the payment request
		paymentMgrCurrentRequest = JSON.parse(JSON.stringify(paymentRequest));
		paymentCompletionCallback = completionCallback;
		
		//console.log("PaymentManager_RequestPayment :" + JSON.stringify(paymentMgrCurrentRequest, null, 2));
		
		PaymentManager_Prepare();
		
		if (paymentMgrConfig.use === 'Checkout')
			PaymentManager_RequestPaymentCheckout();
			
		else if (paymentMgrConfig.use === 'Elements')
		{
			PaymentManager_RequestPaymentElements();
		}
		
	}
	
	//------------------------------------------------------------------------------
	//	Request Payment with Checkout
	//------------------------------------------------------------------------------
	var PaymentManager_RequestPaymentCheckout = function()
	{
		var paymentParams =
		{
			name: paymentMgrConfig.businessName, 
			description: paymentRequest.description,
			amount: paymentRequest.amount,
			email: paymentRequest.email,
			image: paymentRequest.image,
			zipCode: true,
			opened: () => console.log("Stripe Opened"),
			closed: () => {console.log("Stripe Closed"); Payment_ShowProg(true); } 
		};
		
		// Display Checkout UI from Stripe
		paymentMgrStripeModule.open(paymentParams);
	}
	
	
	//------------------------------------------------------------------------------
	//	Request Payment with Elements
	//------------------------------------------------------------------------------
	var PaymentManager_RequestPaymentElements = function()
	{
		PaymentManager_Form_Reset();
		PaymentManager_UpdateUI({reset:true});
		
		if (paymentMgrCurrentRequest.action == "Subscribe")
		{
			PaymentManager_UpdateUI({showPage:'Payment'});
		}
		else
		{
			var msg = "...msg...";
			if (paymentMgrCurrentRequest.action == "Cancel")
				msg = "When you cancel your subscription, it will not renew at the end of the period.";
			else if (paymentMgrCurrentRequest.action == "Restore")
				msg = "When you restore your subscription, it will renew at the end of the period.";
			else if (paymentMgrCurrentRequest.action == "Downgrade")
				msg = "Your current subscription level will remain in effect until the end of the period. " + 
					  "The new subscription level goes into effect when your plan renews at the lower price.";
			else if (paymentMgrCurrentRequest.action == "Upgrade")
				msg = "You will be charged for the prorated difference between your current and the new subscription level. " +
					"The new subscription level goes into effect immediately.";
					
			PaymentManager_UpdateUI({showPage:'Modify', modifyStr:msg, modifyAction:paymentMgrCurrentRequest.action});
		}

		// Describe the charge
		var info = paymentMgrForm.querySelector(".subscription-info");		
		var dollars = paymentMgrCurrentRequest.amount/100;
		var str = "You will be charged $" + dollars + ".00/month";
		info.innerHTML = str;
	}

	//------------------------------------------------------------------------------
	//	Cancel and Delete (returns a promise)
	//		
	//------------------------------------------------------------------------------
	var PaymentManager_CancelAndDelete = function(userId)
	{
		console.log("PaymentManager_CancelAndDelete");
		
		//var cancelAndDeletePromise = new Promise((resolve, reject) => 
		//{
		//	PaymentManager_PostCancelSubscriptionAction({userId: userId});
  		//});

		return PaymentManager_PostCancelSubscriptionAction(userId);
	}

	//------------------------------------------------------------------------------
	//	Close Payment Request
	//		(I don't think this is used)
	//------------------------------------------------------------------------------
	var PaymentManager_ClosePaymentRequest = function()
	{
	}

	//------------------------------------------------------------------------------
	//	Handle Progress Close
	//------------------------------------------------------------------------------
	var PaymentManager_HandleProgressClose = function()
	{
		if (paymentCompletionCallback != undefined)
			paymentCompletionCallback({action: "Close"});
	}

	//------------------------------------------------------------------------------
	//	Visit Billing Portal
	//------------------------------------------------------------------------------
	var PaymentManager_VisitBillingPortal = function(userId)
	{
		let params =
		{
			body:
			{
				action: "Portal",
				userId: userId,
				returnURL: window.location.href // <<<< NEED TO FIX
			}
		};

		console.log(JSON.stringify(params, 0, 2));
		
		API.post(paymentAPIGatewayInfo.apiName, paymentAPIGatewayInfo.path, params)
		.then(response => 
		{
			//console.log("PaymentManager_StripeBillingPortal response: " + JSON.stringify(response, null, 2));
			
			let a = document.createElement("a");
			a.href = response.data.url;
			document.body.appendChild(a);
			a.click();
		})
		.catch(error => 
		{
			console.log("Server error response: " + error);
			
			//let msg = "An error has occurred. Please try again later.";
			//PaymentManager_UpdateUI({showPage:'Status', statusStr:msg, showSpinner:false, showCloseButton:true});
		});

	}

	//------------------------------------------------------------------------------
	//	Prepare Billing Portal
	//		Returns a promise that resolves into a Stripe URL for the billing
	//		portal for the customer.
	//------------------------------------------------------------------------------
	var PaymentManager_PrepareBillingPortal = function(userId)
	{
		return new Promise((resolve, reject) =>
		{
			let body =
			{
				action: "Portal",
				userId: userId,
				returnURL: window.location.href // <<<< NEED TO FIX
			};
		
			API.post(paymentAPIGatewayInfo.apiName, paymentAPIGatewayInfo.path, {body})
			.then(response => 
			{
				//console.log("PaymentManager_PrepareBillingPortal response: " + JSON.stringify(response, null, 2));
				let url = response.data.url;
				resolve(url);
			})
			.catch(error => 
			{
				console.log("PaymentManager_PrepareBillingPortal, Server error response: " + error);
				reject(error);
			});
		});
	}

	//------------------------------------------------------------------------------
	//	Public API
	//
	//------------------------------------------------------------------------------
	return {
		Init:					PaymentManager_Init,
		PostSignInInit:			PaymentManager_PostSignInInit,
		RequestPayment:			PaymentManager_RequestPayment,
		ClosePaymentRequest:	PaymentManager_ClosePaymentRequest,
		CancelAndDelete:		PaymentManager_CancelAndDelete,
		PrepareBillingPortal:	PaymentManager_PrepareBillingPortal,
		VisitBillingPortal:		PaymentManager_VisitBillingPortal
	}
	
}());

//------------------------------------------------------------------------------
//	Export for public access
//
//------------------------------------------------------------------------------
export { PaymentManager };
