//==========================================================================================
//
//	Screen Designer Account
//
//		This module coordinates AmplifyAccountMgr, AmplifyAuthenticationMgr, StripePaymentMgr. 
//------------------------------------------------------------------------------------------
//
//	Valid Account Status values ("accountStatus")
//		1. "Normal"
//			. All actions allowed
//		2. "Cancelled"
//			. No actions allowed
//			. Will be removed from accounts DB at end of period
//		3. "PaymentFailed"
//			. Needs to be resolved by customer
//			. Message displayed in settings panel
//
//	Valid Account State values ("accountState")
//		1. Unsubscribed ("Unsubscribed")
//			. Can subscribe
//		2. Recurring subscription ("Subscribed")
//			. Can cancel
//			. Can upgrade
//			. Can downgrade
//		3. Subscribed, but cancels at end of period ("PendingCancel")
//			. Can undo cancel (returns to same level)
//			. Can upgrade
//			. Can downgrade
//		4. Subscribed, but downgrades at end of period ("PendingDowngrade")
//			. Can cancel
//			. Can undo downgrade
//			. Can upgrade
//
//==========================================================================================

import API from '@aws-amplify/API';

import { AccountManager } from "../../AmplifyAuthenticationMgr/src/AmplifyAccountMgr.js";
import { AuthManager    } from "../../AmplifyAuthenticationMgr/src/AmplifyAuthenticationMgr.js";
import { StorageManager } from "../../AmplifyStorageMgr/src/AmplifyStorageMgr.js";
import { PaymentManager } from "../../StripePaymentMgr/src/StripePaymentMgr.js";

import { ScreenDesigner } from "./ScreenDesigner.js";
import { SystemAnalytics } from "./SystemAnalytics.js";



var ScreenDesignerAccount = (function() {

	let accountAPIGatewayInfo = Object.freeze({
		apiName: "AccountManager",
		refreshPath: "/process",
		cancelPath:  "/cancel",
		queryPath:   "/query" });

	var subscriptionLevels = Object.freeze([
		{ level: "Free",			rank: 0						},
		{ level: "Hobbyist",		rank: 1,	amount:   400	},
		{ level: "Artist",			rank: 2,	amount:   900	},
		{ level: "Designer",		rank: 3,	amount:  3300	},
		{ level: "Professional",	rank: 4,	amount:  7200	},
		{ level: "Enterprise",		rank: 5 					}
		]);

	var accountStates = Object.freeze([
		{ state: "Unsubscribed",		x: 0	},
		{ state: "Subscribed",			x: 0	},
		{ state: "PendingCancel",		x: 0	},
		{ state: "PendingDowngrade",	x: 0	}
		]);

	var actionTable = Object.freeze([
		{ action: "downloadDesign",		minRank: 0 },
		{ action: "createProject",		minRank: 2 },
		{ action: "uploadDesign",		minRank: 3 },
		{ action: "saveWorkbook",		minRank: 3 },
		{ action: "accessNewFeatures",	minRank: 4 }
		]);
	
	/*	
	var accountDefaultAttributes = Object.freeze({
			accountState: "Unsubscribed",
			subscriptionLevel: "Free",
			periodEndDate: 0,
			remainingDownloads: 3,
		});
	*/
	
	//	Account status
	//		Normal
	//		PaymentFailed
	//		Blocked
	//		Complimentary
	//		Cancelled
	
		
	var accountSchemaAdditions = Object.freeze([						// 	Created by....		Updated by...
			{ key: "accountStatus",			value: "Normal"			},	//	client				webhook, (later:dashboard)
			{ key: "accountState",			value: "Unsubscribed"	},	//	client				processing
			{ key: "subscriptionLevel",		value: "Free"			},	//	client				processing
			{ key: "periodEndDate",			value: 0				},	//	client				processing, refresh
			{ key: "remainingDownloads",	value: 3				},	//	client				processing, refresh, client
			{ key: "downgradePlan",			optional: true			},	//	processing			processing, refresh
			{ key: "stripeCustId",			optional: true			},	//	processing			processing
			{ key: "stripeSubscriptionId",	optional: true			},	//	processing			processing
			{ key: "stripeLastPaymentDate",	optional: true			},	//	webhook				webhook
			{ key: "complimentaryLevel",	optional: true			},	//	dashboard, client	dashboard, refresh
			{ key: "complimentaryEndDate",	optional: true			},	//	dashboard, client	dashboard, refresh
			{ key: "promoHistory",			optional: true			}	//	client				dashboard, refresh
		]);
		
	// promoHistory: {applied:{..promoinfo..}, history:[{code:"str", used:ms, accepted:1/0}]}
	var accountAttributes = undefined;
	
	var accountPromoInfoAtLaunch = undefined; // 2019.05.21: Added

	//------------------------------------------------------------------------------
	//	Init
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_Init = function()
	{
		// Populate the attributes for the account using the defaults.
		// In theory this shouldn't be necessary since all of the attributes
		// should be in the customer account record
		accountAttributes = ScreenDesignerAccount_GetDefaultAttributes();
		
		// Set-up UI, mainly the subscription buttons
		ScreenDesignerAccount_ConnectUI();
	}

	//------------------------------------------------------------------------------
	//	Query For Prior Downloads Remaining
	//
	//------------------------------------------------------------------------------
	/*
	var ScreenDesignerAccount_QueryForPriorDownloadsRemaining = function()
	{
		console.log("ScreenDesignerAccount_QueryAccountByEmail");
		return new Promise((resolve, reject) =>
		{
			AccountManager.GetCurrentUser()
			.then(userInfo =>
				{
					console.log("ScreenDesignerAccount_QueryAccountByEmail [then 1]:", JSON.stringify(userInfo, null, 2));
					let params = { body: {email:userInfo.email} };

					console.log("ScreenDesignerAccount_QueryAccountByEmail, params: " + JSON.stringify(params));

					return API.post(accountAPIGatewayInfo.apiName, accountAPIGatewayInfo.queryPath, params);
				
				})
			.then(queryResults =>
				{
					console.log("ScreenDesignerAccount_QueryAccountByEmail [then 2]:", JSON.stringify(queryResults, null, 2));

					resolve({remainingDownloads:5});
				})
			.catch(err =>
				{
					console.error("ScreenDesignerAccount_QueryAccountByEmail error: " + err + ", code: " + err.code);
					resolve(undefined);
				});
		});
	}
	*/
	
	//------------------------------------------------------------------------------
	//	Update
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_Update = function()
	{
		AccountManager.LoadAccount()
			.then(accountData =>
				{
					// If there was no data then this is a new customer, so check to see if
					// there is prior downloadsRemaining and periodEndDate that we need to migrate
					// 2020.07.29: Subscribe to Mailchimp mailing list
					if (accountData == undefined)
					{
						setTimeout(ScreenDesignerAccount_CheckForPriorAccount, 5000 /* 5 secs */);
						setTimeout(ScreenDesignerAccount_MailchimpSubscribe, 7000);
					}

					// Copy incoming 'accountData' into 'accountAttributes'
					// Note that accountAttributes already contains default values, which will be 
					// overwritten with the values in accountData
					Object.assign(accountAttributes, accountData);
					
					ScreenDesignerAccount_ValidateAccountAttributes();

					ScreenDesigner.HighlightRestrictedFeatures();
					ScreenDesigner.UpdateDownloadsRemaining(accountAttributes.remainingDownloads);
					
					// Update the settings that might be currently displayed in the settings panel
					AuthManager.UpdateAuthSettings();
					
					// If we have promo info, then update the dialog. 
					if (accountPromoInfoAtLaunch)
						ScreenDesignerAccount_UpdatePromoInfo(accountPromoInfoAtLaunch);
				})
			.catch(err =>
				{
					console.error("ScreenDesignerAccount_Update error: " + err);
				});
	}

	//------------------------------------------------------------------------------
	//	Mailchimp Subscribe
	//		For new accounts only, subscribe to Mailchimp mailing list
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_MailchimpSubscribe = function()
	{
		AccountManager.GetCurrentUser()
		.then(userInfo =>
			{
				let params = { body: {action:"MailchimpSubscribe", email:userInfo.email} };
				//console.log("ScreenDesignerAccount_MailchimpSubscribe, params: " + JSON.stringify(params));
				return API.post(accountAPIGatewayInfo.apiName, accountAPIGatewayInfo.queryPath, params);
			})
		.then(results =>
			{
				// We don't need these results.
				//console.log("ScreenDesignerAccount_MailchimpSubscribe results:", JSON.stringify(results , null, 2));
			})
		.catch(err =>
			{
				console.error("ScreenDesignerAccount_MailchimpSubscribe error: " + err + ", code: " + err.code);
			});
	}
	
	//------------------------------------------------------------------------------
	//	Check For Prior Account
	//		For new accounts only, migrate any prior downloadsRemaining and periodEndDate
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_CheckForPriorAccount = function()
	{
		AccountManager.GetCurrentUser()
		.then(userInfo =>
			{
				let params = { body: {action:"MigratePrior", email:userInfo.email, userId:userInfo.userId} };

				//console.log("ScreenDesignerAccount_CheckForPriorAccount, params: " + JSON.stringify(params));

				return API.post(accountAPIGatewayInfo.apiName, accountAPIGatewayInfo.queryPath, params);
			
			})
		.then(results =>
			{
				//console.log("ScreenDesignerAccount_CheckForPriorAccount results:", JSON.stringify(results , null, 2));

				// Reload the account data if any data was migrated
				if (results != undefined && results.dataMigrated)
					ScreenDesignerAccount_Update();
			})
		.catch(err =>
			{
				console.error("ScreenDesignerAccount_CheckForPriorAccount error: " + err + ", code: " + err.code);
			});
	}
	
	//------------------------------------------------------------------------------
	//	Validate Account Attributes
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ValidateAccountAttributes = function()
	{
		// Verify that a downgrade plan is listed
		if (accountAttributes.accountState == "PendingDowngrade" && accountAttributes.downgradePlan == undefined)
		{
			// TODO: Is this logic valid?
			console.error("ScreenDesignerAccount_ValidateAccountAttributes: state is 'PendingDowngrade' but downgradePlan is undefined, using to 'hobbyist'");
			accountAttributes.downgradePlan = "Hobbyist";
			
		}
		
		// Verify that if level is "Free", that account status is "Unsubscribed"
		if (accountAttributes.subscriptionLevel == "Free" && accountAttributes.accountState != "Unsubscribed") 
		{
			console.error("ScreenDesignerAccount_ValidateAccountAttributes: level is 'Free' but accountState is not 'Unsubscribed'");
			accountAttributes.accountState = "Unsubscribed";
		}
		
		
		let stateInfo = accountStates.find(function(e) { return (e.state == this.state) }, {state:accountAttributes.accountState});
		
		// Verify that if level is not "Free", that account status is "Unsubscribed"
		if (stateInfo == undefined)
		{
			console.error("ScreenDesignerAccount_ValidateAccountAttributes: accountState '" + accountAttributes.accountState + "' is not valid");
			accountAttributes.accountState = (accountAttributes.subscriptionLevel == "Free") ? "Unsubscribed" : "Subscribed";
		}
	}

	//------------------------------------------------------------------------------
	//	Decrement Download Count
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_DecrementDownloadCount = function()
	{
		if (accountAttributes.remainingDownloads == -1)
		{
			// Indicates unlimited downloads. 
		}
		else if (accountAttributes.remainingDownloads == 0)
		{
			console.error("ScreenDesignerAccount_DecrementDownloadCount: Attempting to decrement remainingDownloads, but value is 0.");
		}
		else
		{
			accountAttributes.remainingDownloads--;
			AccountManager.DecrementAttribute("remainingDownloads");
		}
		
		ScreenDesigner.UpdateDownloadsRemaining(accountAttributes.remainingDownloads);
	}
	
	//------------------------------------------------------------------------------
	//	Reset
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_Reset = function()
	{
		// Reset attributes
		accountAttributes = ScreenDesignerAccount_GetDefaultAttributes();
		
		ScreenDesignerAccount_ResetPromoInfo();
	}
	
	//------------------------------------------------------------------------------
	//	Connect UI
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ConnectUI = function()
	{
		
		document.getElementById("ID_HideSubscriptionInfo").onclick = ScreenDesignerAccount_SubscriptionButtonHandler;
		document.getElementById("ID_SubscriptionInfoBackground").onclick = ScreenDesignerAccount_SubscriptionButtonHandler;

		// Connect 'subscribe' buttons
		var eList = document.getElementsByClassName("CL_BuyButton");
		for (var i = 0; i < eList.length; i++)
			eList[i].addEventListener('click', ScreenDesignerAccount_SubscriptionButtonHandler);

		var eList = document.getElementsByClassName("CL_SubSignUpOrIn");
		for (var i = 0; i < eList.length; i++)
			eList[i].addEventListener('click', ScreenDesignerAccount_StartSignUp);

	}
	
	//------------------------------------------------------------------------------
	//	Calc Period End Date
	//		One month from today, at midnight
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_CalcPeriodEndDate = function()
	{
		var endDate = new Date();
		
		// Move time up to midnight
		endDate.setHours(23);
		endDate.setMinutes(59);
		endDate.setSeconds(59);

		// Set one month ahead
		endDate.setMonth(endDate.getMonth() + 1);
		
		/*		
		var now = new Date();
		now.setHours(23);
		now.setMinutes(59);
		now.setSeconds(59);
		var delta  = endDate.getTime() - now.getTime();
		var msPerDay = (1000 * 60 * 60 * 24);
		console.log("ScreenDesignerAccount_CalcPeriodEndDate: now: " + now.toLocaleDateString() + ", endDate: " + endDate.toLocaleDateString() + ", Delta: " + delta + "ms, " + delta/msPerDay);
		*/

		return endDate;
	}
	
	//------------------------------------------------------------------------------
	//	Get Default Attributes
	//		Get the attributes to add to the account manager's record
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetDefaultAttributes = function()
	{
		// Make a copy of the attributes
		//var defaults = JSON.parse(JSON.stringify(accountDefaultAttributes));
		var defaults = {};
		
		for (var i = 0; i < accountSchemaAdditions.length; i++)
		{
			if (!accountSchemaAdditions[i].optional)
				defaults[accountSchemaAdditions[i].key] = accountSchemaAdditions[i].value;
		}
		
		// Set the end date
		var endDate = ScreenDesignerAccount_CalcPeriodEndDate()
		defaults.periodEndDate = endDate.getTime();
		
		//console.log("ScreenDesignerAccount_GetDefaultAttributes: " + JSON.stringify(defaults, null, 2));
		
		return defaults;
	}
	
	//------------------------------------------------------------------------------
	//	Get Schema Additions
	//		Get the additions to the customer record schema
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetSchemaAdditions = function()
	{
		var endDate = ScreenDesignerAccount_CalcPeriodEndDate();
		var additions = JSON.parse(JSON.stringify(accountSchemaAdditions));
		
		var e = additions.find(function(e) { return (e.key == this.key) }, {key:"periodEndDate"});
		if (e != undefined)
			e.value = endDate.getTime();
		
		return additions;
	}
	
	//------------------------------------------------------------------------------
	//	Get Subscription Rank
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetSubscriptionRank = function(subscriptionStr)
	{
		var rank = undefined;
		var e = subscriptionLevels.find(function(e) { return (e.level == this.subStr) }, {subStr:subscriptionStr});
		
		if (e == undefined)
			console.error("ScreenDesignerAccount_GetSubscriptionRank: Subscription '" + subscriptionStr + "' not found");
		else
			rank = e.rank;
			
		return rank;
	}

	//------------------------------------------------------------------------------
	//	Get Subscription Price
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetSubscriptionPrice = function(subscriptionStr)
	{
		var price = undefined;
		var e = subscriptionLevels.find(function(e) { return (e.level == this.subStr) }, {subStr:subscriptionStr});
		
		if (e == undefined)
			console.error("ScreenDesignerAccount_GetSubscriptionPrice: Subscription '" + subscriptionStr + "' not found");
		else
			price = e.amount;
			
		return price;
	}

	//------------------------------------------------------------------------------
	//	Get Action Minimum Rank, and Rank Str
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetActionMinimumRank = function(actionStr)
	{
		var minRank = undefined;
		var e = actionTable.find(function(e) { return (e.action == this.action) }, {action:actionStr});
		
		if (e == undefined)
			console.error("ScreenDesignerAccount_GetActionMinimumRank: Action '" + actionStr + "' not found");
		else
			minRank = e.minRank;
		
		return minRank;
	}

	var ScreenDesignerAccount_GetActionMinimumRankStr = function(actionStr)
	{
		var subscriptionStr = "";
		var minRank = ScreenDesignerAccount_GetActionMinimumRank(actionStr);
		
		var e = subscriptionLevels.find(function(e) { return (e.rank == this.rank) }, {rank:minRank});
		
		if (e == undefined)
			console.error("ScreenDesignerAccount_GetActionMinimumRankStr: Rank '" + minRank + "' not found");
		else
			subscriptionStr = e.level;
		
		return subscriptionStr;
	}


	//------------------------------------------------------------------------------
	//	Get Remaining Downloads
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetRemainingDownloads = function()
	{
		return accountAttributes.remainingDownloads;
	}
	
	//------------------------------------------------------------------------------
	//	Get Period EndTime
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetPeriodEndTime = function()
	{
		return accountAttributes.periodEndDate;
	}
	
	//------------------------------------------------------------------------------
	//	Get Settings Info Str
	//
	//	Unsubscribed:
	//		Plan: Free
	//		[n|No] downloads remaining
	//		Until [date]
	//		(Plan Info)
	//
	//	Subscribed:
	//		Plan: [plan]
	//		[n|No] downloads remaining
	//		Renews on [date]
	//		(Plan Info) (Cancel Plan)
	//
	//	PendingCancel:
	//		Plan: [plan] cancelled
	//		[n|No] downloads remaining
	//		Cancels on [date]
	//		(Plan Info) (Restore Plan)
	//
	//	PendingDowngrade:
	//		Plan: [plan]
	//		[n|No] downloads remaining
	//		Converts to [plan] on [date]
	//		(Plan Info) (Restore Plan) (Cancel Plan)
	
	
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetSettingsInfo = function()
	{
		var br = "<br>";
		var planStr = "";
		var compStr = "";
		
		// Plan
		planStr = "Plan: " + accountAttributes.subscriptionLevel;
		if (accountAttributes.accountState == "PendingCancel")
			planStr += " (cancelled)";
		
		// Remaining Downloads	
		var remainingStr = "";
		var rdl = ScreenDesignerAccount_GetRemainingDownloads();
		if (rdl == 0)
			remainingStr = "No downloads remaining";
		else if (rdl > 1)
			remainingStr = rdl + " downloads remaining";
		else if (rdl == 1)
			remainingStr = rdl + " download remaining";
		else
			remainingStr = "Unlimited downloads";
		
		// End date
		var untilStr = "";
		var endDateTime = ScreenDesignerAccount_GetPeriodEndTime();
		var timeNow = (new Date()).getTime();
		if (timeNow < endDateTime)
		{
			var t = (new Date(endDateTime)).toLocaleDateString();
			if (accountAttributes.accountState == "Unsubscribed")
				untilStr = "Until " + t;
			else if (accountAttributes.accountState == "Subscribed")
				untilStr = "Renews on " + t;
			else if (accountAttributes.accountState == "PendingCancel")
				untilStr = "Cancels on " + t;
			else if (accountAttributes.accountState == "PendingDowngrade")
				untilStr = "Converts to '" + accountAttributes.downgradePlan + "' on " + t;
		}
		else
		{
			console.error("ScreenDesignerAccount_GetSettingsInfo: timeNow is greater than endDateTime");
		}
		
		// Complimentary 
		if (accountAttributes.complimentaryLevel != undefined)
		{
			compStr = "Complimentary plan: " + accountAttributes.complimentaryLevel;
			
			if (accountAttributes.complimentaryEndDate != undefined)
			{
				let t = (new Date(accountAttributes.complimentaryEndDate)).toLocaleDateString();
				compStr += " (until " + t + ")";
			}
			
			compStr += br;
		}
		
		var infoStr = planStr + br + compStr + remainingStr + br + untilStr + br;
		
		if (accountAttributes.accountStatus == "PaymentFailed")
		{
			let msg = "We are unable to renew your subscription. Please click on the 'Manage Billing' button to update your credit card info.";
			let msgStr = "<span class='CL_BillingError'>" + msg + "</span><br>";
			infoStr = msgStr + infoStr;
		}

		// Show "Cancel" for Subscribed and PendingDowngrade
		var showCancelButton = (accountAttributes.accountState == "Subscribed" || accountAttributes.accountState == "PendingDowngrade");
		
		// Show "Restore" for PendingCancel and PendingDowngrade states
		var showRestoreButton = (accountAttributes.accountState == "PendingCancel" || accountAttributes.accountState == "PendingDowngrade");

		// 2020.07.14: Show billing portal button
		var showPortalButton = (accountAttributes.accountState != "Unsubscribed");

		var settingsInfo = {
			summary: infoStr,
			showCancelButton: showCancelButton,
			showRestoreButton: showRestoreButton,
			showPortalButton: showPortalButton
		};
		
		return settingsInfo;
	}
	
	//------------------------------------------------------------------------------
	//	Can Perform
	//		
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_CanPerform = function(action)
	{
		var canPerformAction = false;
		var subscriptionRank = ScreenDesignerAccount_GetSubscriptionRank(accountAttributes.subscriptionLevel);
		var complimentaryRank = 0;
		
		if (accountAttributes.complimentaryLevel != undefined)
			complimentaryRank = ScreenDesignerAccount_GetSubscriptionRank(accountAttributes.complimentaryLevel);

		var minRank = ScreenDesignerAccount_GetActionMinimumRank(action);
		var rank = Math.max(subscriptionRank, complimentaryRank);
		
		if (rank >= minRank)
		{
			if (action === 'downloadDesign')
			{
				var rld = ScreenDesignerAccount_GetRemainingDownloads();
				
				canPerformAction = (rld == -1 || rld > 0);
			}
			else
				canPerformAction = true;
		}
		return canPerformAction;
	}

	//------------------------------------------------------------------------------
	//	Can Perform Or Show Blocked UI
	//		
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_CanPerformOrShowBlockedUI = function(action)
	{
		var canPerform = ScreenDesignerAccount_CanPerform(action);
		
		if (!canPerform)
			ScreenDesignerAccount_ShowBlockedDialog(true, action);
		
		return canPerform;
	}	

	//---------------------------------------------------------------------------
	//	Show Blocked Dialog
	//---------------------------------------------------------------------------
	var ScreenDesignerAccount_ShowBlockedDialog = function(show, action)
	{
		var bg = document.getElementById("ID_BlockedBackground");
		bg.style.display = show ? "flex" : "none";
		
		bg.onclick = ScreenDesignerAccount_BlockedDialogHandler;

		document.getElementById("ID_BlockedDismiss").onclick = ScreenDesignerAccount_BlockedDialogHandler;
		document.getElementById("ID_BlockedShowPlans").onclick = ScreenDesignerAccount_BlockedDialogHandler;

		if (show)
		{
			var desc = "";
			var sublevel = accountAttributes.subscriptionLevel;
			var compstr = "";
			if (accountAttributes.complimentaryLevel != undefined && 
				ScreenDesignerAccount_GetSubscriptionRank(accountAttributes.complimentaryLevel) > ScreenDesignerAccount_GetSubscriptionRank(accountAttributes.subscriptionLevel))
			{
				sublevel = accountAttributes.complimentaryLevel;
				compstr = "complimentary ";
			}
			var substr = "<span class='CL_SubscriptionStr'>" + sublevel + "</span>";
			
			if (action == undefined)
				desc = "This operation is currently unavailable.";
			else if (action == "downloadDesign")
				desc = "You do not have any more downloads available at this time.";
			else if (action == "createProject")
				desc = "Your " + compstr + "subscription level, " + substr + ", does not support creating new projects.";
			else if (action == "uploadDesign")
				desc = "Your " + compstr + "subscription level, " + substr + ", does not support opening previously downloaded Polygonia designs.";
			else if (action == "saveWorkbook")
				desc = "Your " + compstr + "subscription level, " + substr + ", does not support saving and opening Polygonia workbooks.";
			else
				desc = "Hmm..";
				
			var e = document.getElementById("ID_BlockedExplanation");
			e.innerHTML = desc;
			
			var minDesc = ""

			if (action == "downloadDesign")
			{
			}
			else if (action == "createProject" || action == "uploadDesign" || action == "saveWorkbook")
			{
				var minSubStr = ScreenDesignerAccount_GetActionMinimumRankStr(action);
				var substr = "<span class='CL_SubscriptionStr'>" + minSubStr + "</span>";
			
				minDesc = "The feature is available to the " + substr + " plan and above.";
			}
			
			var e = document.getElementById("ID_BlockedMinPlan");
			e.innerHTML = minDesc;
		}		
	}
	
	//---------------------------------------------------------------------------
	//	Blocked Dialog Handler
	//---------------------------------------------------------------------------
	var ScreenDesignerAccount_BlockedDialogHandler = function(evt)
	{
		if (this.id == "ID_BlockedBackground" && evt.target.id != "ID_BlockedBackground")
			return;

		var id = this.id;
		
		if (id == "ID_BlockedBackground" || id == "ID_BlockedDismiss")
			ScreenDesignerAccount_ShowBlockedDialog(false);
		else if (id == "ID_BlockedShowPlans")
		{
			ScreenDesignerAccount_ShowBlockedDialog(false);
			ScreenDesignerAccount_ShowSubscriptionInfo(true);
		}
	}

	/*
	var ScreenDesignerAccount_TestTables = function()
	{
		for (var i = 0; i< subscriptionLevels.length; i++)
		{
			var s = subscriptionLevels[i].level;
			
			console.log(s + " " + ScreenDesignerAccount_GetSubscriptionRank(s));
		}
		
		for (var i = 0; i< subscriptionLevels.length; i++)
		{
			var s = subscriptionLevels[i].level;
			console.log(s + ": " + ScreenDesignerAccount_GetSubscriptionRank(s));
			
			for (var j = 0; j < actionTable.length; j++)
			{
				var a = actionTable[j].action;
				
				console.log("  " + a + ": " + ScreenDesignerAccount_CanPerform(a, s));
			}
		}
	}
	*/
	//------------------------------------------------------------------------------
	//	CancelSubscription
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_CancelSubscription = function()
	{
		console.log("ScreenDesignerAccount_CancelSubscription");
		
		var cancelSubscriptionPromise;
		
		if (accountAttributes.accountState == "Subscribed" || accountAttributes.accountState == "PendingDowngrade")
		{
			cancelSubscriptionPromise = PaymentManager.CancelAndDelete(accountAttributes.userId);
		}
		else
		{
			cancelSubscriptionPromise = new Promise((resolve, reject) => 
				{
					resolve("ScreenDesignerAccount_CancelSubscription: account is not subscribed; no cancel required");
				});
		}
		
		return cancelSubscriptionPromise;
	}

	//------------------------------------------------------------------------------
	//	Cancel Account
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_CancelAccount = function()
	{
		// 2019.03.19: Use the account manager to get the current user to fix the issue where
		// a customer deletes their account the first time they log in. The problem is that this
		// module, the ScreenDesignerAccount, does not have the userId and email when the user
		// creates their account.
		//
		return new Promise((resolve, reject) =>
		{
			AccountManager.GetCurrentUser()
			.then(userInfo =>
			{
				let params = { body: {userId:userInfo.userId, email:userInfo.email} };

				console.log("ScreenDesignerAccount_CancelAccount params: " + JSON.stringify(params, null, 2));
				
				var cancelAccountPromise = API.post(accountAPIGatewayInfo.apiName, accountAPIGatewayInfo.cancelPath, params);
				resolve(cancelAccountPromise);
			})
			.catch(error => 
			{
				console.log("ScreenDesignerAccount_CancelAccount error response: " + error);
				reject(error);
			});
		});
		
		
		//let params = { body: {userId:accountAttributes.userId, email:accountAttributes.email} };
		//console.log("ScreenDesignerAccount_CancelAccount, params: " + JSON.stringify(params));

		// Returns promise		
		//var cancelAccountPromise = API.post(accountAPIGatewayInfo.apiName, accountAPIGatewayInfo.cancelPath, params);

		//return cancelAccountPromise;
	}

	//------------------------------------------------------------------------------
	//	Do Cancel or Restore
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_DoCancelOrRestore = function(doCancel)
	{
		var action = doCancel ? "Cancel" : "Restore";
		ScreenDesignerAccount_RequestSubscriptionLevel(action);
	}
	
	//------------------------------------------------------------------------------
	//	Purchase Level
	//
	//		Special values:
	//			"Free": Cancel a subscription
	//			"Cancel": Cancel a subscription
	//			"Restore": Restore current subscription
	//
	//	      <span id="ID_PaymentTitle">Enter your payment information</span>
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_RequestSubscriptionLevel = function(requestedSubscriptionLevel)
	{
		//console.log("ScreenDesignerAccount_RequestSubscriptionLevel: requested level:" + requestedSubscriptionLevel + ", accountState: " + accountAttributes.accountState + ", currentLevel: " + accountAttributes.subscriptionLevel);
		
		// Depending on the accountState and the current subscriptionLevel...
		// "Unsubscribed" --> going to "Subscribe"
		// "Subscribed" -->  "Upgrade" or "Downgrade"
		// "PendingCancel" --> "Restore", "Downgrade", or "Upgrade"
		// "PendingDowngrade" --> "Restore", "Upgrade", "Cancel"
		
		if (requestedSubscriptionLevel === "Restore")
			requestedSubscriptionLevel = accountAttributes.subscriptionLevel;
			
		var action = undefined;
		var isCancelRequest = (requestedSubscriptionLevel === "Free" || requestedSubscriptionLevel === "Cancel");
		var currentSubscriptionLevel = accountAttributes.subscriptionLevel;
		var currentPrice = ScreenDesignerAccount_GetSubscriptionPrice(currentSubscriptionLevel);
		var requestedPrice = !isCancelRequest ? ScreenDesignerAccount_GetSubscriptionPrice(requestedSubscriptionLevel) : undefined;

		if (isCancelRequest)
			requestedSubscriptionLevel = undefined;
			
		// You can't request "Free", so "Free" is converted to "cancel" and "requestedSubscriptionLevel" is cleared to undefined
		// Note that currentPrice will be undefined is the current subscription is "Free" and requestedPrice will be undefined if
		// requestedSubscriptionLevel was "Free"
		
		if (accountAttributes.accountState == "Unsubscribed")
		{
			if (!isCancelRequest)
				action = "Subscribe";
		}
		else if (accountAttributes.accountState == "Subscribed")
		{
			if (isCancelRequest)
				action = "Cancel";
			else if (requestedPrice > currentPrice)
				action = "Upgrade";
			else if (requestedPrice < currentPrice)
				action = "Downgrade";
			else
				console.log("ScreenDesignerAccount_RequestSubscriptionLevel: price of requested level is same as current level");
		}
		else if (accountAttributes.accountState == "PendingCancel" || accountAttributes.accountState == "PendingDowngrade")
		{
			if (isCancelRequest)
			{
				if (accountAttributes.accountState == "PendingDowngrade")
					action = "Cancel";
			}
			else if (requestedSubscriptionLevel === currentSubscriptionLevel)
				action = "Restore";
			else if (requestedPrice > currentPrice)
				action = "Upgrade";
			else if (requestedPrice < currentPrice)
				action = "Downgrade";
			else
				console.log("ScreenDesignerAccount_RequestSubscriptionLevel: price of requested level is same as current level");
		}
		else 
		{
			console.error("ScreenDesignerAccount_RequestSubscriptionLevel: unexpected accountState: " + accountAttributes.accountState);
		}

		
		if (action != undefined)
		{
			var subscriptionRequest =
			{
				action: action,
				level: requestedSubscriptionLevel,
				amount: requestedPrice
			};
			
			var statusStr = "";
			var titleStr = "";
			
			if (action == "Subscribe")
			{
				titleStr = "Enter your payment information";
				statusStr = "Subcription plan: '" + requestedSubscriptionLevel + "'";
			}
			else if (action == "Cancel")
			{
				titleStr = "Cancel subscription";
				statusStr = "Cancel plan: '" + currentSubscriptionLevel + "'";
			}
			else if (action == "Restore")
			{
				titleStr = "Restore subscription";
				statusStr = "Restore plan: '" + currentSubscriptionLevel + "'";
			}
			else if (action == "Downgrade")
			{
				titleStr = "Change subscription";
				statusStr = "Reduced subcription plan: '" + requestedSubscriptionLevel + "'";
			}
			else if (action == "Upgrade")
			{
				titleStr = "Change subscription";
				statusStr = "New subcription plan: '" + requestedSubscriptionLevel + "'";
			}
			
			ScreenDesignerAccount_UpdatePaymentTitleAndStatus(titleStr, statusStr);
			
			ScreenDesignerAccount_PaymentRequestHandler(subscriptionRequest);
		}
		else
		{
			console.error("ScreenDesignerAccount_RequestSubscriptionLevel: no action determined for requestedLevel: " + requestedSubscriptionLevel);
		}	
	}
	

	//------------------------------------------------------------------------------
	//	x
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_PaymentRequestHandler = async function(subscriptionRequest)
	{
		var userInfo = await AuthManager.GetCurrentUserInfo();
		var str = "'" + subscriptionRequest.level + "' Subscription";
		
		var paymentRequest =
		{
			action: subscriptionRequest.action,
			userId: userInfo.userId,
			description: str,
			level: subscriptionRequest.level,
			amount: subscriptionRequest.amount,
			meta: userInfo,
			email: userInfo.email,
			image: "./rsrcs/logo.png"
		};
		
		ScreenDesignerAccount_DisplayPaymentDialog(paymentRequest);
		PaymentManager.RequestPayment(paymentRequest, ScreenDesignerAccount_PaymentRequestCallback);
	}
		
	//------------------------------------------------------------------------------
	//	x
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_DisplayPaymentSubmit = function(event)
	{
		event.preventDefault();

		stripe.createToken(card)
		.then(function(result) 
			{
				if (result.error) 
				{
					// Inform the user if there was an error.
					var errorElement = document.getElementById('card-errors');
					errorElement.textContent = result.error.message;
				} 
				else 
				{
					// Send the token to your server.
					//stripeTokenHandler(result.token);
					console.dir(result.token);
				}
			});
	}

	//------------------------------------------------------------------------------
	//	x
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_UpdatePaymentTitleAndStatus = function(titleStr, statusStr)
	{
		var e = document.getElementById("ID_PaymentTitle");
		e.innerHTML = titleStr;

		var e = document.getElementById("ID_PaymentStatus");
		e.innerHTML = statusStr;
	}
	
	//------------------------------------------------------------------------------
	//	x
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_DisplayPaymentDialog = function(paymentRequest)
	{
		document.getElementById("ID_PaymentBackground").style.display = "flex";
		document.getElementById("ID_PaymentClose").onclick = ScreenDesignerAccount_DisplayPaymentClose;		
	}
	
	//------------------------------------------------------------------------------
	//	x
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_DisplayPaymentClose = function()
	{
		var pd = document.getElementById("ID_PaymentBackground");
		pd.style.display = "none";

		PaymentManager.ClosePaymentRequest();
	}
	

	//------------------------------------------------------------------------------
	//	x
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_PaymentRequestCallback = function(context)
	{
		console.log("ScreenDesignerAccount_PaymentRequestCallback");
		
		if (context == undefined)
		{
			console.error("ScreenDesignerAccount_PaymentRequestCallback Error: context undefined");
		}
		else if (context.err != undefined)
		{
			/* console.error("ScreenDesignerAccount_PaymentRequestCallback Error: " + context.err); */
		}
		else if  (context.response != undefined)
		{
			console.dir(context.response);
			
			ScreenDesignerAccount_Update();
		}
		else if (context.action == 'Close')
		{
			ScreenDesignerAccount_DisplayPaymentClose();
		}
		else
		{
			console.error("ScreenDesignerAccount_PaymentRequestCallback Error: context defined but 'err' and 'response' are both missing");
		}
	}

	//------------------------------------------------------------------------------
	//	Subscription Button Handler
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_SubscriptionButtonHandler = function(evt)
	{
		var id = this.id;

		if (this.id == "ID_SubscriptionInfoBackground" && evt.target.id != "ID_SubscriptionInfoBackground")
			return;

		// SUBSCRIPTION support...
		if (id == "ID_ShowPlanInfo")
			AuthManager_ShowSubscriptionInfo(true);
		else if (id == "ID_HideSubscriptionInfo" || id == "ID_SubscriptionInfoBackground")
			ScreenDesignerAccount_ShowSubscriptionInfo(false);
			
		// SUBSCRIBE buttons...
		else if (id == "ID_SubscribeHobbyist")
		{
			ScreenDesignerAccount_ShowSubscriptionInfo(false);
			ScreenDesignerAccount_RequestSubscriptionLevel("Hobbyist");
		}
		else if (id == "ID_SubscribeArtist")
		{
			ScreenDesignerAccount_ShowSubscriptionInfo(false);
			ScreenDesignerAccount_RequestSubscriptionLevel("Artist");
		}
		else if (id == "ID_SubscribeDesigner")
		{
			ScreenDesignerAccount_ShowSubscriptionInfo(false);
			ScreenDesignerAccount_RequestSubscriptionLevel("Designer");
		}
		else if (id == "ID_SubscribeProfessional")
		{
			ScreenDesignerAccount_ShowSubscriptionInfo(false);
			ScreenDesignerAccount_RequestSubscriptionLevel("Professional");
		}
		else
			console.error("ScreenDesignerAccount_SubscriptionButtonHandler: Button '" + id + "' not handled");


		evt.preventDefault();
	}
	
	
	//------------------------------------------------------------------------------
	//	Start Sign Up
	//
	//	Remove the subscription plan dialog and ask the authentication manager
	//	to start the sign-up process
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_StartSignUp = function()
	{
		ScreenDesignerAccount_ShowSubscriptionInfo(false);
		AuthManager.StartSignUp();
	}
	
	//------------------------------------------------------------------------------
	//	Show Loading Billing Portal
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ShowLoadingBillingPortal = function(show)
	{
		// Show the dialog
		var bg = document.getElementById("ID_PortalBackground");

		// Function to close the dialog if the background is clicked
		var backgroundHandler = 
			function(evt)
			{
				if (this.id == "ID_PortalBackground" && evt.target.id == "ID_PortalBackground")
					ScreenDesignerAccount_ShowLoadingBillingPortal(false);
			}		
		
		if (show)
		{
			// Add handlers to dismiss portal dialog
			bg.onclick = backgroundHandler;
			document.getElementById("ID_PortalHide").onclick = () => ScreenDesignerAccount_ShowLoadingBillingPortal(false);
			document.getElementById("ID_PortalCancel").onclick = () => ScreenDesignerAccount_ShowLoadingBillingPortal(false);
			// Configure the button with "Preparing Portal...", a spinner, and gray text
			let go = document.getElementById("ID_PortalGo");
			go.innerHTML="Preparing Portal...";
			go.classList.add("spinner");
			go.classList.add("CL_PortalLinkGray");
			go.disabled = true;
			go.href = "javascript:void(0)";
			// Hide the error message
			let em = document.getElementsByClassName("CL_PortalError")[0];
			em.classList.add("CL_HideElement");
			// Show the dialog
			bg.style.display = "flex";
		}
		else
		{
			// Dismiss the dialog with a fade-out
			bg.classList.add("CL_FadeOut");
			setTimeout(() => { bg.style.display = "none"; bg.classList.remove("CL_FadeOut"); }, 300 /* 0.3s */);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Show Billing Portal
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ShowBillingPortal = function()
	{
		ScreenDesignerAccount_ShowLoadingBillingPortal(true); 
		
		AccountManager.GetCurrentUser()
		.then(userInfo =>
		{
			return PaymentManager.PrepareBillingPortal(userInfo.userId);
		})
		.then(url =>
		{
			let go = document.getElementById("ID_PortalGo");
			go.disabled = false;
			go.innerHTML="Visit Portal";
			go.classList.remove("spinner");
			go.classList.remove("CL_PortalLinkGray");
			go.setAttribute("href", url);
		})
		.catch(err =>
		{
			console.log("ScreenDesignerAccount_ShowBillingPortal error: " + err );
			let go = document.getElementById("ID_PortalGo");
			go.disabled = true;
			go.innerHTML="Error Preparing Portal";
			go.classList.remove("spinner");
			// Show the error message
			let em = document.getElementsByClassName("CL_PortalError")[0];
			em.classList.remove("CL_HideElement");
		});
	}
	
	//------------------------------------------------------------------------------
	//	Show Subscription
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ShowSubscriptionInfo = function(show)
	{
		if (show)
			ScreenDesignerAccount_UpdateSubscriptionInfo();

		// Show the dialog
		var bg = document.getElementById("ID_SubscriptionInfoBackground");
		
		if (show)
			bg.style.display = "flex";
		else
		{
			bg.classList.add("CL_FadeOut");
			setTimeout(() => { bg.style.display = "none"; bg.classList.remove("CL_FadeOut"); }, 300 /* 0.3s */);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Update Subscription Info
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_UpdateSubscriptionInfo = function(show)
	{
		var isAuth = AuthManager.IsAuthorized();
		
		var showClass = ( isAuth) ? "CL_BuyButton" : "CL_SubInfoNotAuth";
		var hideClass = (!isAuth) ? "CL_BuyButton" : "CL_SubInfoNotAuth";
		
		// Show either "Subscribe" buttons or "Sign In" buttons
		var es = document.getElementsByClassName(hideClass);
		for (var i = 0; i < es.length; i++)
			es[i].classList.add("CL_HideElement");
		
		var es = document.getElementsByClassName(showClass);
		for (var i = 0; i < es.length; i++)
			es[i].classList.remove("CL_HideElement");

		// 2019.10.25: Remove or add the gray background for the subscription levels
		var es = document.getElementsByClassName("CL_SubName");
		for (var i = 0; es != undefined && i < es.length; i++)
		{
			if (isAuth)
				es[i].classList.remove("CL_SubGrayBackground");
			else
				es[i].classList.add("CL_SubGrayBackground");
		}
		
		// Show the current subscription with a box
		// If "PendingCancel" or "PendingDowngrade", change the outline color to red
		// Also, show the next subscription if a downgrade plan is listed
		var subscriptionLevel = accountAttributes.subscriptionLevel;
		var es = document.getElementsByClassName("CL_SubscriptionItem");
		for (var i = 0; i < es.length; i++)
		{
			if (isAuth && es[i].id.includes(subscriptionLevel))
			{
				es[i].classList.add("CL_SubscribedItem");
				
				if (accountAttributes.accountState == "PendingCancel" || accountAttributes.accountState == "PendingDowngrade")
					es[i].classList.add("CL_SubscribedPendingCancel");
				else
					es[i].classList.remove("CL_SubscribedPendingCancel");
			}
			else if  (isAuth && accountAttributes.accountState == "PendingDowngrade" && es[i].id.includes(accountAttributes.downgradePlan))
			{
				es[i].classList.add("CL_SubscribedItem");
			}
			else
			{
				es[i].classList.remove("CL_SubscribedItem");
				es[i].classList.remove("CL_SubscribedPendingCancel");
			}
		}

		// Show the "Your Plan!" text
		// If "PendingCancel" or "PendingDowngrade", change the text to "Your current plan." and use 
		// a different color (red for now).
		// Also, show "Your next plan" if a downgrade plan is listed
		var es = document.getElementsByClassName("CL_SubscriptionYourPlan");
		for (var i = 0; i < es.length; i++)
		{
			if (isAuth && es[i].id.includes(subscriptionLevel))
			{
				es[i].classList.remove("CL_HideElement");
				
				if (accountAttributes.accountState == "PendingCancel" || accountAttributes.accountState == "PendingDowngrade")
				{
					es[i].innerHTML = "Your current plan.";
					es[i].classList.add("CL_PendingCancel");
				}
				else
				{
					es[i].innerHTML = "Your Plan!";
					es[i].classList.remove("CL_PendingCancel");
				}
			}
			else if  (isAuth && accountAttributes.accountState == "PendingDowngrade" && es[i].id.includes(accountAttributes.downgradePlan))
			{
				es[i].classList.remove("CL_HideElement");
				es[i].innerHTML = "Your next plan.";
			}
			else
			{
				es[i].classList.add("CL_HideElement");
			}
		}
		
		
		// If authorized (signed in) adjust the button text. 
		//   "Unsubscribed", use "Subscribe"
		//   "Subscribed", use "Change Subscription"
		//   "PendingCancel", use "Restore Subscription"
		//   "PendingDowngrade", use "Restore" and "Subscribe"
		//
		if (isAuth)
		{
			// Set all buttons to default "Subscribe" text and enabled
			var es = document.getElementsByClassName("CL_BuyButton");
			for (var i = 0; i < es.length; i++)
			{
				es[i].innerHTML = "Subscribe";
				es[i].removeAttribute('disabled');
			}
			
			if (accountAttributes.accountState == "Unsubscribed")
			{
				// No changes from default
			}
			else if (accountAttributes.accountState == "Subscribed")
			{
				// For subscribed plan, disable button and say "Subscribed",
				// otherwise say "Change Subscription"
				var es = document.getElementsByClassName("CL_BuyButton");
				for (var i = 0; i < es.length; i++)
				{
					if (es[i].id.includes(subscriptionLevel))
					{
						es[i].setAttribute('disabled', 'true');
						es[i].innerHTML = "Subscribed";
					}
					else
					{
						es[i].innerHTML = "Change Subscription";
					}
				}
			}
			else if (accountAttributes.accountState == "PendingCancel" || accountAttributes.accountState == "PendingDowngrade")
			{
				// For subscribed plan that cancels, "Restore Subscription",
				// For downgradePlan, if pending downgrade, disable button and say "Subscribed",
				// otherwise say "Change Subscription"
				var es = document.getElementsByClassName("CL_BuyButton");
				for (var i = 0; i < es.length; i++)
				{
					if (es[i].id.includes(subscriptionLevel))
					{
						es[i].innerHTML = "Restore Subscription";
					}
					else if (accountAttributes.accountState == "PendingDowngrade" && es[i].id.includes(accountAttributes.downgradePlan))
					{
						es[i].setAttribute('disabled', 'true');
						es[i].innerHTML = "Subscribed";
					}
					else
					{
						es[i].innerHTML = "Change Subscription";
					}
				}
			}
		}
	}

	//------------------------------------------------------------------------------
	//	Dismiss Dialogs
	//
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_DismissDialogs = function()
	{
		ScreenDesignerAccount_DisplayPaymentClose();
	}
	

	
	//------------------------------------------------------------------------------
	//	Handle Promo Code
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_HandlePromoCode = function(promoCode)
	{
		accountPromoInfoAtLaunch = ScreenDesignerAccount_ParsePromoCode(promoCode);
		SystemAnalytics.Record("PromoProvided", { promoCode:promoCode } );
		
		ScreenDesignerAccount_UpdatePromoInfo(accountPromoInfoAtLaunch);
		ScreenDesignerAccount_ShowPromoInfo(true);

	}
	
	//------------------------------------------------------------------------------
	//	Reset Promo Code
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ResetPromoInfo = function()
	{
		accountPromoInfoAtLaunch = undefined;
		
		document.getElementById("ID_PromoPending").classList.add("CL_HideElement");
		document.getElementById("ID_PromoPendingCode").innerHTML = "";
		
		// Remove the search options from the URL
		var url = window.location.origin + window.location.pathname;
		window.history.replaceState({}, document.title, url);
	}
	
	//------------------------------------------------------------------------------
	//	Promo Partners and Codes
	//------------------------------------------------------------------------------
	var promoPartners = [
		{ partner: "generic",		logo: "polygonia.svg"										},
		{ partner: "Nova Labs",		logo: "novalabs.png",		url:"https://nova-labs.org/"	},
		{ partner: "SendCutSend",	logo: "sendcutsend.png",	url:"https://sendcutsend.com/"	},
		{ partner: "StepCraft",		logo: "stepcraft.jpg",		url:"https://www.stepcraft.us/"	},
		{ partner: "Ponoko",		logo: "ponoko.png",			url:"https://www.ponoko.com/"	}
	];
	
	var promoList = 
	[
		// 2019
		{ code:"MAKERFAIRE",		partner:"generic",			validDates: {to:[2019,6,30]},						level:"Hobbyist",	months:"2"	},
	  //{ code:"NOVALABS-1906",		partner:"Nova Labs",		validDates: {from:[2019,6,1], to:[2019,6,30]},		level:"Hobbyist",	months:"2"	},
		{ code:"SENDCUTSEND-1906",	partner:"SendCutSend",		validDates: { to:[2019,8,30]},						level:"Hobbyist",	months:"2"	},
		{ code:"SENDCUTSEND-1909",	partner:"SendCutSend",		validDates: { to:[2019,12,31]},						level:"Hobbyist",	months:"2"	},
	  //{ code:"STEPCRAFT-1906",	partner:"StepCraft",		validDates: { to:[2019,6,30]},						level:"Hobbyist",	months:"2"	},
		{ code:"INSTAGRAM-SMR19",	partner:"generic",			validDates: { to:[2019,9,30]}														},
		{ code:"INSTAGRAM-AUT19",	partner:"generic",			validDates: { to:[2019,12,31]}														},
		{ code:"EDU-SMR19",			partner:"generic",			validDates: { to:[2019,9,30]}														},
		{ code:"EDU-AUT19",			partner:"generic",			validDates: { to:[2019,12,31]}														},
		{ code:"TWITTER-SMR19",		partner:"generic",			validDates: { to:[2019,9,30]}														},
	  //{ code:"PONOKO-SMR19",		partner:"Ponoko",			validDates: { to:[2019,9,30]}														},
		{ code:"CARD-AUT19",		partner:"generic",			validDates: { to:[2019,12,31]}														},
		{ code:"MAIL-AUT19",		partner:"generic",			validDates: { to:[2019,12,31]}														},
		{ code:"SM-AUT19",			partner:"generic",			validDates: { to:[2019,12,31]}														},
		// 2020
		{ code:"REDDIT-2020",		partner:"generic",			validDates: { to:[2020,12,31]}														},
		{ code:"MAIL-2020",			partner:"generic",			validDates: { to:[2020,12,31]}														},
		{ code:"HOBBYIST-2020",		partner:"generic",			validDates: { to:[2020,12,31]}														},
		{ code:"CRICUT-2020",		partner:"generic",			validDates: { to:[2020,12,31]}														},
		{ code:"INSTA-2020",		partner:"generic",			validDates: { to:[2020,12,31]}														},
		{ code:"CARD-2020",			partner:"generic",			validDates: { to:[2020,12,31]}														},
		// 2021
		{ code:"REDDIT-2021",		partner:"generic",			validDates: { to:[2021,12,31]}														},
		{ code:"MAIL-2021",			partner:"generic",			validDates: { to:[2021,12,31]}														},
		{ code:"HOBBYIST-2021",		partner:"generic",			validDates: { to:[2021,12,31]}														},
		{ code:"CRICUT-2021",		partner:"generic",			validDates: { to:[2021,12,31]}														},
		{ code:"INSTA-2021",		partner:"generic",			validDates: { to:[2021,12,31]}														},
		{ code:"CARD-2021",			partner:"generic",			validDates: { to:[2021,12,31]}														},
		// 2022
		{ code:"WELCOME",			partner:"generic",			validDates: { to:[2024,12,31]},						level:"Designer",	months:"6"	},
	];
	
	var promoDefaults = Object.freeze({
			valid:    false,
			partner:  "generic",
			level:    "Hobbyist",
			months:   "2"
		});

	//------------------------------------------------------------------------------
	//	Parse Promo Code
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ParsePromoCode = function(promoCode)
	{
		var promoInfo = {};
		
		Object.assign(promoInfo, promoDefaults);
		promoInfo.code = promoCode;

		// Find an entry in the table for the promo code and combine it with the promoInfo object
		var promoEntry = promoList.find(entry => entry.code == promoCode); 
		Object.assign(promoInfo, promoEntry);

		// Look up the partner info and combine it with the promoInfo object
		var partnerEntry = promoPartners.find(entry => entry.partner == promoInfo.partner); 
		Object.assign(promoInfo, partnerEntry);
		promoInfo.logo = "./gifsimgs/companylogos/" + promoInfo.logo;
	
		if (promoInfo.validDates != undefined)
		{
			// Start date is optional.
			var startDate = undefined;
			if (promoInfo.validDates.from != undefined)
				startDate = new Date(promoInfo.validDates.from[0], promoInfo.validDates.from[1], promoInfo.validDates.from[2]);
				
			var endDate   = new Date(promoInfo.validDates.to[0],   promoInfo.validDates.to[1],   promoInfo.validDates.to[2], 23, 59, 59);
			var now       = new Date();
			
			promoInfo.valid = (startDate == undefined || startDate.getTime() <= now.getTime()) && (now.getTime() <= endDate.getTime());  
		}

		/*console.log(JSON.stringify(promoInfo));*/
		
		return promoInfo;
	}

	
	//------------------------------------------------------------------------------
	//	Update Promo Info
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_UpdatePromoInfo = function(promoInfo)
	{
		var str;
		var isAuth = AuthManager.IsAuthorized();
		var accountHasPriorPromo = 0; 
		var priorPromoCode = "";
		var priorPromoDate = "";
		
		var activateBtn = document.getElementById("ID_PromoAccept");
		var signUpBtn   = document.getElementById("ID_PromoSignIn"); 
		
		// Determine if a promo has already been applied
		if (isAuth && accountAttributes != undefined && accountAttributes.promoHistory != undefined)
		{
			let ph = accountAttributes.promoHistory;
			if (ph.applied != undefined)
			{
				// 2020.07.23: Ignore any promos applied before 7/1/2020
				let julyOne2020 = new Date(2020, 6).getTime(); // Month is zero-indexed
				let d = new Date();
				d.setFullYear(d.getFullYear() - 1);
				let oneYearAgo = d.getTime();

				if (ph.applied.appliedDate < julyOne2020 || ph.applied.appliedData < oneYearAgo)
				{
					// ignore old promos
					// ignore promos older than one year
				}
				else
				{
					// Promo was applied since 7/1/2020 and within one year
					accountHasPriorPromo = 1;
					priorPromoCode = ph.applied.promoCode;
					priorPromoDate = new Date(ph.applied.appliedDate).toLocaleDateString();
				}
			}
		}
		
		// Show the "sign in" button if not signed in
		if (isAuth)
			signUpBtn.classList.add("CL_HideElement");
		else
			signUpBtn.classList.remove("CL_HideElement");

		// Show the "use promo" button is signed in and the promo code is valid
		if (isAuth && promoInfo.valid && !accountHasPriorPromo)
			activateBtn.classList.remove("CL_HideElement");
		else
			activateBtn.classList.add("CL_HideElement");

		var img = document.getElementById("ID_PromoImg");
		if (/*promoInfo.partner != "generic" && */ promoInfo.logo != undefined)
			img.src = promoInfo.logo;
			
		var partner;
		if (promoInfo.url != undefined)
			partner = "<a href='" + promoInfo.url + "' target='_blank'>" + promoInfo.partner + "</a>";
		else
			partner = promoInfo.partner;
		
		var welcome = document.getElementById("ID_PromoWelcome");
		if (promoInfo.partner != undefined && promoInfo.partner != "generic")
			str = "Thanks for visiting from " + partner + "!";
		else
			str = "Thanks for visiting!"
		welcome.innerHTML = str;

		var desc = document.getElementById("ID_PromoDesc");
		if (promoInfo.valid && !accountHasPriorPromo)
		{
			if (isAuth)
				str = "Click '" + activateBtn.innerHTML + "'";
			else
				str = "Sign in and click the 'Promo' button ";
				
			str += " to activate your complimentary <span class='CL_SubscriptionStr'>" + promoInfo.level + 
				   "</span> subscription for " + promoInfo.months + " months. ";
			if (isAuth)
				str += "(At the end of your complimentary subscription your account will revert to its current <span class='CL_SubscriptionStr'>" +
					   accountAttributes.subscriptionLevel + "</span> account.)"
			else
				str += "(At the end of your complimentary subscription your account will convert to its original subscription level.)"
		}
		else if (accountHasPriorPromo)
		{
			
			str = "This account has already used promo '" + priorPromoCode + "' on " + priorPromoDate;
		}
		else
		{
			str = "This promo code has expired.";
		}
		desc.innerHTML = str;
		
		// "Pending Promo" area
		if (promoInfo.valid)
		{
			document.getElementById("ID_PromoPending").classList.remove("CL_HideElement");
			document.getElementById("ID_PromoPendingCode").innerHTML =  "Promo: " + promoInfo.code;
			document.getElementById("ID_PromoPendingClear").onclick = (evt) => {evt.stopPropagation(); evt.preventDefault();ScreenDesignerAccount_ResetPromoInfo();};
		}
	}

	//------------------------------------------------------------------------------
	//	Show Promo Info
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ShowPromoInfo = function(show)
	{
		// Show the dialog
		var bg = document.getElementById("ID_PromoBackground");

		// Function to close the dialog if the background is clicked
		var promoBackgroundHandler = 
			function(evt)
			{
				if (this.id == "ID_PromoBackground" && evt.target.id == "ID_PromoBackground")
					ScreenDesignerAccount_ShowPromoInfo(false);
			}		
		
		if (show)
		{
			bg.onclick = promoBackgroundHandler;
			document.getElementById("ID_HidePromoInfo").onclick = () => ScreenDesignerAccount_ShowPromoInfo(false);
			document.getElementById("ID_PromoSignIn").addEventListener("click",  () => ScreenDesignerAccount_ShowPromoInfo(false));
			document.getElementById("ID_PromoPending").onclick = () => ScreenDesignerAccount_ShowPromoInfo(true);
			document.getElementById("ID_PromoAccept").onclick = ScreenDesignerAccount_ActivatePromo;
			
			bg.style.display = "flex";
		}
		else
		{
			bg.classList.add("CL_FadeOut");
			setTimeout(() => { bg.style.display = "none"; bg.classList.remove("CL_FadeOut"); }, 300 /* 0.3s */);
		}
	}
	
	//------------------------------------------------------------------------------
	//	Activate Promo
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ActivatePromo = function()
	{
		document.getElementById("ID_PromoAccept").setAttribute("disabled", "disabled");
		document.getElementById("ID_PromoAccept").classList.add("spinner");

		ScreenDesignerAccount_ApplyPromo(accountPromoInfoAtLaunch);
	}

	//------------------------------------------------------------------------------
	//	Apply Promo
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_ApplyPromo = function(promoInfo)
	{
		AccountManager.GetCurrentUser()
		.then(userInfo =>
			{
				let params = { body: {action:"ApplyPromo", userId:userInfo.userId, promoCode:promoInfo.code, promoInfo:promoInfo} };

				//console.log("ScreenDesignerAccount_ApplyPromo, params: " + JSON.stringify(params));

				SystemAnalytics.Record("PromoAttempt", { promoCode:promoInfo.code } );
				return API.post(accountAPIGatewayInfo.apiName, accountAPIGatewayInfo.queryPath, params);
			
			})
		.then(results =>
			{
				//console.log("ScreenDesignerAccount_ApplyPromo results:", JSON.stringify(results , null, 2));

				// Reload the account data if any data was updated
				if (results != undefined && results.promoApplied)
				{
					ScreenDesignerAccount_Update();
					SystemAnalytics.Record("PromoApplied", { promoCode:promoInfo.code } );
					ScreenDesigner.DisplayMessageBanner("Promo '" + promoInfo.code + "' applied to your account.");
				}
				else
				{
					ScreenDesigner.DisplayMessageBanner("Promo '" + promoInfo.code + "' could not be applied.");
				}
					
				ScreenDesignerAccount_ShowPromoInfo(false);
				ScreenDesignerAccount_ResetPromoInfo();
			})
		.catch(err =>
			{
				console.error("ScreenDesignerAccount_ApplyPromo error: " + err + ", code: " + err.code);
			})
		.finally( () =>
			{
				document.getElementById("ID_PromoAccept").removeAttribute("disabled");
				document.getElementById("ID_PromoAccept").classList.remove("spinner");
			});
	}
	
	//------------------------------------------------------------------------------
	//	Get Current User
	//		2021.08.03: Created
	//------------------------------------------------------------------------------
	var ScreenDesignerAccount_GetCurrentUser =  async function()
	{
		let userInfo = await AccountManager.GetCurrentUser();
		/*
		.then(userInfo =>
			{
				let params = { body: {action:"MailchimpSubscribe", email:userInfo.email} };
				//console.log("ScreenDesignerAccount_MailchimpSubscribe, params: " + JSON.stringify(params));
				return API.post(accountAPIGatewayInfo.apiName, accountAPIGatewayInfo.queryPath, params);
			})
		.then(results =>
			{
				// We don't need these results.
				//console.log("ScreenDesignerAccount_MailchimpSubscribe results:", JSON.stringify(results , null, 2));
			})
		.catch(err =>
			{
				
				console.error("ScreenDesignerAccount_MailchimpSubscribe error: " + err + ", code: " + err.code);
			});
		*/
		
		return userInfo;
	}
	
	//------------------------------------------------------------------------------
	//	Public API
	//
	//------------------------------------------------------------------------------
	return {
		Init:						ScreenDesignerAccount_Init,
		Update:						ScreenDesignerAccount_Update,
		Reset:						ScreenDesignerAccount_Reset,
		GetRemainingDownloads:		ScreenDesignerAccount_GetRemainingDownloads,
		GetSettingsInfo:			ScreenDesignerAccount_GetSettingsInfo,
		CanPerform:					ScreenDesignerAccount_CanPerform,
		CanPerformOrShowBlockedUI:	ScreenDesignerAccount_CanPerformOrShowBlockedUI,
		GetSchemaAdditions:			ScreenDesignerAccount_GetSchemaAdditions,
		ShowSubscriptionInfo:		ScreenDesignerAccount_ShowSubscriptionInfo,
		DoCancelOrRestore:			ScreenDesignerAccount_DoCancelOrRestore,
		CancelSubscription:			ScreenDesignerAccount_CancelSubscription,
		CancelAccount:				ScreenDesignerAccount_CancelAccount,
		DecrementDownloadCount:		ScreenDesignerAccount_DecrementDownloadCount,
		DismissDialogs:				ScreenDesignerAccount_DismissDialogs,
		HandlePromoCode:			ScreenDesignerAccount_HandlePromoCode,
		ShowBillingPortal:			ScreenDesignerAccount_ShowBillingPortal,
		GetCurrentUser:				ScreenDesignerAccount_GetCurrentUser	// 2021.08.03
	}
	
}());

//------------------------------------------------------------------------------
//	Export for public access
//
//------------------------------------------------------------------------------
export { ScreenDesignerAccount };
